<template>
  <button class="create-group-order-button" @click="showGroupCreation" type="button">
    <div class="create-group-order-button__icon-wrapper">
      <img class="create-group-order-button__icon" src="../../assets/users.svg" alt="" />
    </div>
    <span class="create-group-order-button__text create-group-order-button__text-large">Start group order</span>
    <span class="create-group-order-button__text create-group-order-button__text-small">{{
      forceLongText ? 'Start group order' : 'Group'
    }}</span>
  </button>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';
import { useShepherd } from 'vue-shepherd';

class Props {
  forceLongText = prop<boolean>({ required: false, default: false });
  restaurant = prop<FullInfoRestaurant>({ required: true });
  wholeMenuMinHoursInAdvance = prop<number>({ required: true });
}

@Options({
  props: {
    forceLongText: {
      type: Boolean,
      default: false,
    },
    restaurant: {
      type: Object,
    },
    wholeMenuMinHoursInAdvance: {
      type: Number,
    },
  },
})
export default class CreateGroupOrderButton extends Vue.with(Props) {
  private tour = useShepherd({ useModalOverlay: true });

  private setUpProductTour() {
    // Do nothing if the step has already been presented in the past:
    if (this.$store.state.productTour['create-group-order']) return;

    // Do nothing in embedded views:
    if (this.$route.query.embedded) return;

    // Identify step's element:
    const element = document.getElementsByClassName('create-group-order-button')[0];
    if (!element) return;

    // Add step and start tour:
    this.tour.addStep({
      buttons: [{ text: '<p>Got it</p>', action: this.completeTour }],
      attachTo: { element, on: 'right' },
      text: '<p><strong>Ordering in a group?</strong> Start a group order and share a link to your basket </p>',
    });
    this.tour.start();

    // Add possibility to close pop-up by clicking overlay:
    const overlay = document.getElementsByClassName('shepherd-modal-overlay-container')[0] as HTMLElement;
    if (overlay) overlay.onclick = this.completeTour;

    if (window.innerWidth <= 1024) this.tour.steps[0].hide();
    window.addEventListener('resize', () => {
      // Don't restart tour:
      if (!this.tour.isActive()) return;

      // Show only on desktop:
      const step = this.tour.steps[0];
      if (window.innerWidth <= 1024 && step.isOpen()) step.hide();
      else if (window.innerWidth > 1024 && !step.isOpen()) step.show();
    });
  }

  private completeTour() {
    this.$store.commit('productTour/setAsCompleted', 'create-group-order');
    this.tour.complete();
  }

  private showGroupCreation() {
    document.body.classList.remove('no-scroll');

    if (!this.$store.state.service.user) {
      this.$store.commit('modals/reset');
      this.$store.commit('modals/show', 'auth');
    } else {
      this.$store.commit('modals/data', {
        restaurant: this.restaurant,
        wholeMenuMinHoursInAdvance: this.wholeMenuMinHoursInAdvance,
      });
      this.$store.commit('modals/show', 'createGroupOrder');
    }

    if (this.tour.isActive()) this.completeTour();
  }

  mounted() {
    // Set up product tour AFTER rendering the button:
    setTimeout(this.setUpProductTour, 500);
  }

  unmounted() {
    if (this.tour.isActive()) this.tour.complete();
  }
}
</script>

<style lang="scss" scoped>
.create-group-order-button {
  height: 40px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 15px;
  gap: 7px;
  border: 1px solid rgba($color: $black, $alpha: 0.2);
  background-color: $white;
  padding: 10px 15px;
  cursor: pointer;

  &__text {
    @include p2;
    color: $coal100;
  }

  &__text-large {
    display: none;
    @include for-old-mobile {
      display: block;
    }
  }

  &__text-small {
    @include for-old-mobile {
      display: none;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
  }
}
</style>
