import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/gtm/james.png'
import _imports_1 from '../../assets/gtm/dashboard/toby_demo.png'
import _imports_2 from '../../assets/gtm/dashboard/view.png'
import _imports_3 from '../../assets/gtm/dashboard/choose.png'
import _imports_4 from '../../assets/gtm/dashboard/proceed.png'


const _hoisted_1 = { class: "dashboard" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "upper_part" }
const _hoisted_4 = { class: "texts" }
const _hoisted_5 = { class: "demo_call" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Logo),
      _createVNode(_component_app_button, {
        square: "",
        white: "",
        slim: "",
        class: "add",
        onClick: _withModifiers(_ctx.addEvent, ["prevent"])
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("p", null, "+ Add another event", -1)
        ])),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_app_button, {
        square: "",
        white: "",
        slim: "",
        class: "chat_with_us",
        onClick: _withModifiers(_ctx.chatWithUs, ["prevent"])
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1),
          _createElementVNode("p", null, "Chat with us", -1)
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _cache[5] || (_cache[5] = _createElementVNode("h1", null, "Event slot booked!", -1)),
        _cache[6] || (_cache[6] = _createElementVNode("p", null, " We're busy building your custom marketplace featuring existing office favourites and a host of new exciting eateries. All of your office food orders will soon be in one place! One supplier and maximum variety... That sounds pretty good eh? ", -1)),
        _createElementVNode("p", null, [
          _createElementVNode("ins", {
            class: "custom-cursor-hover",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.bookDemoCall && _ctx.bookDemoCall(...args)))
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("strong", null, "Schedule a call here", -1)
          ])),
          _cache[4] || (_cache[4] = _createTextVNode(" so that we can show you what we've built for you! We aim to have things ready within 72 hours. "))
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "demo_call_header" }, [
          _createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }),
          _createElementVNode("div", null, [
            _createElementVNode("h1", null, "Schedule a call"),
            _createElementVNode("p", null, "with Toby")
          ])
        ], -1)),
        _createVNode(_component_app_button, {
          slim: "",
          onClick: _ctx.bookDemoCall
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("+")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"lower_part\" data-v-4bb2cbdd><div class=\"lower_part_body\" data-v-4bb2cbdd><h1 data-v-4bb2cbdd>Next steps</h1><div class=\"steps\" data-v-4bb2cbdd><div class=\"step\" data-v-4bb2cbdd><img src=\"" + _imports_2 + "\" alt=\"\" data-v-4bb2cbdd><p class=\"step_title\" data-v-4bb2cbdd>View restaurant roster</p><p class=\"step_description\" data-v-4bb2cbdd>Check out our curated list of restaurants based on your criteria</p></div><div class=\"step\" data-v-4bb2cbdd><img src=\"" + _imports_3 + "\" alt=\"\" data-v-4bb2cbdd><p class=\"step_title\" data-v-4bb2cbdd>Choose your items</p><p class=\"step_description\" data-v-4bb2cbdd>Pick a menu and add your items to the basket</p></div><div class=\"step\" data-v-4bb2cbdd><img src=\"" + _imports_4 + "\" alt=\"\" data-v-4bb2cbdd><p class=\"step_title\" data-v-4bb2cbdd>Proceed to checkout</p><p class=\"step_description\" data-v-4bb2cbdd>Pay for your order and we&#39;ll handle the rest</p></div></div></div></div>", 1))
  ]))
}