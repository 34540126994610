<template>
  <app-modal class="group-order-modal" :close="true">
    <h2 class="group-order-modal__title">{{ `${userName} group order` }}</h2>
    <p class="address_details">
      From: <strong>{{ restaurantName }}</strong>
    </p>
    <p class="address_details" v-if="type === 'delivery'">
      To: <strong>{{ userAddress }}</strong>
    </p>
    <p class="address_details" v-else>
      Pickup: <strong>{{ restaurantAddress }}</strong>
    </p>

    <p class="address_details" v-if="!restaurantAllowScheduledOrders">
      Date and time: <strong>As soon as possible</strong>
    </p>
    <div v-else>
      <p class="section_title" v-if="type === 'delivery'"><strong>Choose a delivery date and time</strong></p>
      <p class="section_title" v-else><strong>Choose a collection date and time</strong></p>
      <p class="section_subtitle" v-if="type === 'delivery'">When do you want your order delivered?</p>
      <p class="section_subtitle" v-else>When do you want to pick up your order?</p>

      <a class="date_container" @click="showDeliveryTimePicker">
        <span> {{ formattedDeliveryDate }} </span>
        <IconServices class="services-icon" icon="thickClock" color="black" />
      </a>
    </div>

    <p class="section_title"><strong>Choose a deadline (optional)</strong></p>
    <p class="section_subtitle">When do people need to add their items?</p>
    <a class="date_container" @click="showDeadlineTimePicker">
      <span> {{ formattedDeadlineDate }} </span>
      <img src="../assets/check.svg" width="22" alt="" />
    </a>

    <p class="section_title"><strong>Add some instructions (optional)</strong></p>
    <p class="section_subtitle" />
    <div class="group-order-modal__instructions">
      <app-input
        class="group-order-modal__instructions-input"
        :showErrorWithoutHighlighting="error"
        isMultiplyLine
        title="Add some instructions"
        v-model:value="instructions"
        id="instructions"
        placeholder="E.g Budget per person, recommendations"
      >
        <template v-slot:error v-if="error.message">
          <div class="group-order-modal__error">
            {{ error.message }}.
            <span
              class="group-order-modal__pending-wrapper"
              v-if="error.type === 'pendingOrder' && groupData && groupData.restaurantSlug"
            >
              <span
                class="group-order-modal__pending-link"
                @click="goToActiveGroupOrder"
                :to="`/${groupData.restaurantSlug}`"
                aria-label="homepage"
                >Click here</span
              >
              to manage it.
            </span>
          </div>
        </template>
      </app-input>
    </div>

    <p v-if="!isDeliveryLateEnough" class="section_subtitle" style="color: red; text-align: center">
      The restaurant needs {{ data.wholeMenuMinHoursInAdvance }}
      {{ data.wholeMenuMinHoursInAdvance === 1 ? 'hour' : 'hours' }} notice.<br />Please edit the delivery date.
    </p>
    <p v-else-if="!isDeadlineLateEnough" class="section_subtitle" style="color: red; text-align: center">
      The restaurant needs {{ data.wholeMenuMinHoursInAdvance }}
      {{ data.wholeMenuMinHoursInAdvance === 1 ? 'hour' : 'hours' }} notice.<br />Please edit the deadline.
    </p>
    <app-button
      class="group-order-modal__button"
      :loader="loader"
      @click.prevent="createGroupOrder"
      :disabled="!isDeliveryLateEnough || !isDeadlineLateEnough"
    >
      Create your link
    </app-button>
  </app-modal>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { v1 as uuidV1 } from 'uuid';
import { watch } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import firebaseHttp from '@/requests/firebase';
import groupOrdersHttp from '@/requests/groupOrders';

import IconServices from '@/shared/icons/IconServices.vue';

import methods from '@/utils/date';
import pipes from '@/utils/pipes';

@Options({
  components: {
    IconServices,
  },
  computed: {
    ...mapState('modals', ['data']),
    ...mapState('groupOrder', ['groupData']),
    ...mapState('order', ['type']),
  },
})
export default class ModalCreateGroupOrder extends Vue {
  private minDeadlineDate = moment(new Date()).format('YYYY-MM-DDTHH:mm');
  private loader = false;
  private error = {
    type: '',
    message: '',
  };

  private deadlineDate: Date | null = null;
  private instructions = '';

  get formattedDeliveryDate() {
    const deliveryDate = this.$store.state.order.scheduledDeliveryDate;
    return deliveryDate
      ? moment(deliveryDate).tz('Europe/London').format('dddd, Do MMM, HH:mm')
      : 'As soon as possible';
  }

  get formattedDeadlineDate() {
    const { deadlineDate } = this.$store.state.modals.data;
    return deadlineDate
      ? moment(deadlineDate).tz('Europe/London').format('dddd, Do MMM, HH:mm')
      : 'Choose a date and time';
  }

  get maxDeadlineDate() {
    const date = new Date(this.$store.state.order.scheduledDeliveryDate as unknown as string);
    const bestTime = this.$store.state.order.scheduledDeliveryInterval?.split(' - ')[0] || '';
    const [bestHour, bestMinute] = bestTime.split(':');

    return bestHour
      ? moment(new Date(date?.setHours(Number(bestHour), Number(bestMinute)))).format('YYYY-MM-DDTHH:mm')
      : null;
  }

  get userName() {
    const { user } = this.$store.state.service;
    return user ? pipes.addApostrophe(user.firstName) : null;
  }

  get userAddress() {
    return this.$store.state.address?.formattedAddress;
  }

  get restaurantName() {
    return this.$store.state.modals.data.restaurant.name;
  }

  get restaurantAddress() {
    const data = this.$store.state.modals.data.restaurant;
    return `${data.location}, ${data.nearestStation} ${data.postcode}`;
  }

  get restaurantAllowScheduledOrders() {
    return this.$store.state.modals.data.restaurant.allowScheduledOrders;
  }

  get isDeliveryLateEnough() {
    const { order, modals } = this.$store.state;
    if (!modals.data.wholeMenuMinHoursInAdvance) return true;

    return moment(order.scheduledDeliveryDate).isAfter(moment().add(modals.data.wholeMenuMinHoursInAdvance, 'hours'));
  }

  get isDeadlineLateEnough() {
    const { modals } = this.$store.state;
    if (!modals.data.wholeMenuMinHoursInAdvance || !modals.data.deadlineDate) return true;

    return moment(modals.data.deadlineDate).isAfter(moment().add(modals.data.wholeMenuMinHoursInAdvance, 'hours'));
  }

  private async createGroupOrder() {
    const groupId = uuidV1();
    this.loader = true;

    const date = new Date(this.$store.state.order.scheduledDeliveryDate as unknown as string);
    const { start, end } = methods.combineIntervalAndDayData(this.$store.state.order.scheduledDeliveryInterval, date);

    const f = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
    const bestDate = start ? moment(start.toLocaleString('en-US', { timeZone: 'UTC' })).format(f) : null;
    const worstDate = end ? moment(end.toLocaleString('en-US', { timeZone: 'UTC' })).format(f) : null;

    try {
      if (!this.$store.state.service.user) {
        this.$store.commit('modals/close');
        return;
      }

      const existingGroupOrder = await groupOrdersHttp.getMyGroupOrder();
      if (existingGroupOrder) {
        this.$store.commit('groupOrder/update', { ...existingGroupOrder });
        this.$store.commit('groupOrder/setRole', 'Owner');

        this.error.message = 'You already have a pending group order';
        this.error.type = 'pendingOrder';
        this.loader = false;
        return;
      }

      const groupLink = await firebaseHttp.generateGroupOrderLink(
        groupId,
        this.$store.state.modals.data.restaurant.slug,
        this.$store.state.modals.data.restaurant.restaurantCity,
      );

      await groupOrdersHttp.createGroupOrder({
        id: groupId,
        groupLink,
        ownerAddress: {
          id: null,
          lat: this.$store.state.address.lat,
          lng: this.$store.state.address.lng,
          cityName: this.$store.state.address.cityName,
          postcode: this.$store.state.address.postcode,
          streetName: this.$store.state.address.streetName,
          streetNumber: this.$store.state.address.streetNumber,
          formattedAddress: this.$store.state.address.formattedAddress,
          directions: '',
          flatOrBuilding: '',
          wholeCity: false,
        },
        bestDeliverToTime: bestDate,
        worstDeliverToTime: worstDate,
        instructions: this.instructions || null,
        deadline: this.deadlineDate,
        restaurantSlug: this.$store.state.modals.data.restaurant.slug,
        orderType: this.$store.state.order.type === 'collection' ? 'collect' : 'delivery',
      });

      this.$store.commit('modals/data', { ...this.$store.state.modals.data, link: groupLink });
    } catch (error) {
      console.error(error);
      return;
    }

    const groupOrder = await groupOrdersHttp.getMyGroupOrder();

    this.$store.commit('basket/clear');
    this.$store.commit('groupOrder/reset');
    this.$store.commit('groupOrder/update', { ...groupOrder });
    this.$store.commit('groupOrder/setRole', 'Owner');

    this.loader = false;
    this.$store.commit('modals/close');

    this.$store.commit('modals/show', 'shareGroupOrderLink');
  }

  private async goToActiveGroupOrder() {
    if (this.$store.state.groupOrder.groupData) {
      if (this.$route.params.slug !== this.$store.state.groupOrder.groupData.restaurantSlug) {
        const {
          ownerAddress: { cityName },
          restaurantSlug,
        } = this.$store.state.groupOrder.groupData;
        this.$router.push(`/menu/${cityName.toLowerCase()}/${restaurantSlug}`);
      } else {
        this.$router.go(0);
      }
    }
  }

  private showDeliveryTimePicker() {
    this.$store.commit('modals/data', {
      // we don't want to lose any of these states:
      deadlineDate: this.$store.state.modals.data.deadlineDate,
      restaurant: this.$store.state.modals.data.restaurant,
      wholeMenuMinHoursInAdvance: this.$store.state.modals.data.wholeMenuMinHoursInAdvance,
      withDeliveryTimeChange: true,
    });
    this.$store.commit('modals/show', 'orderSettings');
  }

  private showDeadlineTimePicker() {
    this.$store.commit('modals/data', {
      // we don't want to lose any of these states:
      deadlineDate: this.$store.state.modals.data.deadlineDate,
      restaurant: this.$store.state.modals.data.restaurant,
      wholeMenuMinHoursInAdvance: this.$store.state.modals.data.wholeMenuMinHoursInAdvance,
      withDeliveryTimeChange: true,
    });
    this.$store.commit('modals/show', 'orderSettingsDeadline');
  }

  created() {
    watch(
      () => [this.$store.state.modals.data.deadlineDate],
      () => {
        this.deadlineDate = this.$store.state.modals.data.deadlineDate;
      },
    );
  }
}
</script>

<style lang="scss" scoped>
.group-order-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    max-width: 375px;
  }

  &__title {
    margin-top: 20px;
    margin-bottom: 5px;
  }

  &__deadline-description {
    color: #212121;
    margin: 0px !important;
    opacity: 0.5;
  }

  &__instructions {
    width: 100%;
    margin-bottom: 20px;
  }

  &__instructions-input {
    width: 100%;
    max-width: unset;
    height: 74.5px;
  }

  &__pending-link {
    font-size: 13px;
    font-weight: bold;
    color: red;
    text-decoration: underline;
    cursor: pointer;
  }

  &__pending-wrapper {
    font-weight: bold;
    color: red;
  }

  &__error {
    margin-bottom: 10px;
    font-weight: bold;
  }

  &__button {
    max-width: unset;
  }
}

.date_container {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $coal20;
  background-color: $white;
  padding: 10px 12px 10px 20px;
  gap: 5px;
  height: 45px;

  .services-icon {
    $icon-size: 22.5px;
    max-width: $icon-size;
    width: $icon-size;
    min-width: $icon-size;
    max-height: $icon-size;
    height: $icon-size;
    min-height: $icon-size;
  }

  span {
    @include p2;
    font-weight: 600;
    font-size: 16px;
    color: $coal100;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
    width: 100%;
  }
}

.address_details {
  @include p2;
  font-size: 15px;
  max-width: 375px;
}

.section_title {
  @include p2;
  font-size: 15px;
  margin-top: 20px;
}

.section_subtitle {
  @include p2;
  font-size: 15px;
  font-weight: 300;
  color: #4d4d4d;
  margin-bottom: 5px;
}
</style>
