<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.2666 9.02234L11.6888 1.02234H13.911L12.5333 9.02234H10.2666Z" fill="#3C58BF" />
    <path d="M10.2666 9.02234L12.0888 1.02234H13.911L12.5333 9.02234H10.2666Z" fill="#293688" />
    <path
      d="M20.5773 1.11105C20.1329 0.933271 19.4218 0.755493 18.5329 0.755493C16.3107 0.755493 14.7107 1.8666 14.7107 3.4666C14.7107 4.6666 15.8218 5.28883 16.7107 5.68883C17.5996 6.08883 17.8662 6.35549 17.8662 6.71105C17.8662 7.24438 17.1551 7.51105 16.5329 7.51105C15.644 7.51105 15.1551 7.37772 14.3996 7.06661L14.0885 6.93327L13.7773 8.75549C14.3107 8.97772 15.2885 9.19994 16.3107 9.19994C18.6662 9.19994 20.2218 8.08883 20.2218 6.39994C20.2218 5.46661 19.644 4.75549 18.3107 4.17772C17.5107 3.77772 17.0218 3.55549 17.0218 3.15549C17.0218 2.79994 17.4218 2.44438 18.3107 2.44438C19.0662 2.44438 19.5996 2.57772 19.9996 2.75549L20.2218 2.84438L20.5773 1.11105Z"
      fill="#3C58BF"
    />
    <path
      d="M20.5773 1.11105C20.1329 0.933271 19.4218 0.755493 18.5329 0.755493C16.3107 0.755493 15.1107 1.8666 15.1107 3.4666C15.1107 4.6666 15.8218 5.28883 16.7107 5.68883C17.5996 6.08883 17.8662 6.35549 17.8662 6.71105C17.8662 7.24438 17.1551 7.51105 16.5329 7.51105C15.644 7.51105 15.1551 7.37772 14.3996 7.06661L14.0885 6.93327L13.7773 8.75549C14.3107 8.97772 15.2885 9.19994 16.3107 9.19994C18.6662 9.19994 20.2218 8.08883 20.2218 6.39994C20.2218 5.46661 19.644 4.75549 18.3107 4.17772C17.5107 3.77772 17.0218 3.55549 17.0218 3.15549C17.0218 2.79994 17.4218 2.44438 18.3107 2.44438C19.0662 2.44438 19.5996 2.57772 19.9996 2.75549L20.2218 2.84438L20.5773 1.11105Z"
      fill="#293688"
    />
    <path
      d="M24.4 1.02234C23.8667 1.02234 23.4667 1.06678 23.2445 1.60012L19.9111 9.02234H22.3111L22.7556 7.68901H25.6L25.8667 9.02234H28L26.1334 1.02234H24.4ZM23.3778 6.35567C23.5111 5.95567 24.2667 4.00012 24.2667 4.00012C24.2667 4.00012 24.4445 3.51123 24.5778 3.20012L24.7111 3.95567C24.7111 3.95567 25.1556 5.95567 25.2445 6.40012H23.3778V6.35567Z"
      fill="#3C58BF"
    />
    <path
      d="M24.9334 1.02234C24.4 1.02234 24 1.06678 23.7778 1.60012L19.9111 9.02234H22.3111L22.7556 7.68901H25.6L25.8667 9.02234H28L26.1334 1.02234H24.9334ZM23.3778 6.35567C23.5556 5.91123 24.2667 4.00012 24.2667 4.00012C24.2667 4.00012 24.4445 3.51123 24.5778 3.20012L24.7111 3.95567C24.7111 3.95567 25.1556 5.95567 25.2445 6.40012H23.3778V6.35567Z"
      fill="#293688"
    />
    <path
      d="M6.17806 6.62201L5.95584 5.46646C5.55584 4.13313 4.26695 2.66646 2.84473 1.95535L4.84473 9.06645H7.24473L10.8447 1.06647H8.44473L6.17806 6.62201Z"
      fill="#3C58BF"
    />
    <path
      d="M6.17806 6.62201L5.95584 5.46646C5.55584 4.13313 4.26695 2.66646 2.84473 1.95535L4.84473 9.06645H7.24473L10.8447 1.06647H8.88917L6.17806 6.62201Z"
      fill="#293688"
    />
    <path
      d="M0 1.02234L0.4 1.11123C3.24444 1.77789 5.2 3.46678 5.95555 5.46678L5.15556 1.68901C5.02222 1.15567 4.62222 1.02234 4.13333 1.02234H0Z"
      fill="#FFBC00"
    />
    <path
      d="M0 1.02234C2.84444 1.68901 5.2 3.42234 5.95555 5.42234L5.2 2.26678C5.06667 1.73345 4.62222 1.42234 4.13333 1.42234L0 1.02234Z"
      fill="#F7981D"
    />
    <path
      d="M0 1.02234C2.84444 1.68901 5.2 3.42234 5.95555 5.42234L5.42222 3.68901C5.28889 3.15567 5.11111 2.62234 4.48889 2.40012L0 1.02234Z"
      fill="#ED7C00"
    />
    <path
      d="M8.39978 6.35564L6.88867 4.84453L6.17756 6.53342L5.99978 5.4223C5.59978 4.08897 4.31089 2.6223 2.88867 1.91119L4.88867 9.0223H7.28867L8.39978 6.35564Z"
      fill="#051244"
    />
    <path d="M12.5333 9.02251L10.6222 7.06696L10.2666 9.02251H12.5333Z" fill="#051244" />
    <path
      d="M17.644 6.26685C17.8218 6.44462 17.9107 6.57796 17.8662 6.75574C17.8662 7.28907 17.1551 7.55574 16.5329 7.55574C15.644 7.55574 15.1551 7.4224 14.3996 7.11129L14.0885 6.97796L13.7773 8.80018C14.3107 9.0224 15.2885 9.24463 16.3107 9.24463C17.7329 9.24463 18.8885 8.84463 19.5551 8.13351L17.644 6.26685Z"
      fill="#051244"
    />
    <path
      d="M20.2217 9.02211H22.3106L22.755 7.68878H25.5995L25.8661 9.02211H27.9995L27.2439 5.77767L24.5772 3.19989L24.7106 3.911C24.7106 3.911 25.155 5.911 25.2439 6.35544H23.3772C23.555 5.911 24.2661 3.99989 24.2661 3.99989C24.2661 3.99989 24.4439 3.511 24.5772 3.19989"
      fill="#051244"
    />
  </svg>
  <!-- eslint-enable -->
</template>
