<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8582 5.29358C16.1551 5.29358 17.2065 4.24217 17.2065 2.94519C17.2065 1.64821 16.1551 0.596802 14.8582 0.596802C13.5612 0.596802 12.5098 1.64821 12.5098 2.94519C12.5098 4.24217 13.5612 5.29358 14.8582 5.29358Z"
      fill="#EF9122"
    />
    <path
      d="M12.5098 2.94519C12.5098 1.63551 13.5485 0.596802 14.8582 0.596802C16.1678 0.596802 17.2065 1.63551 17.2065 2.94519C17.2065 4.25487 16.1678 5.29358 14.8582 5.29358"
      fill="#F26E21"
    />
    <path
      d="M13.2324 1.27422C14.1356 0.370995 15.626 0.370995 16.5292 1.27422C17.4324 2.17745 17.4324 3.66777 16.5292 4.571"
      fill="#F44500"
    />
    <path
      d="M1.26452 0.7323H0V5.15811H1.26452C1.94194 5.15811 2.43871 4.97746 2.84516 4.66133C3.34194 4.25488 3.65806 3.62262 3.65806 2.9452C3.65806 1.63553 2.70968 0.7323 1.26452 0.7323ZM2.30323 4.07424C2.03226 4.30004 1.67097 4.43553 1.12903 4.43553H0.858065V1.50004H1.08387C1.62581 1.50004 1.9871 1.59036 2.25806 1.86133C2.57419 2.1323 2.75484 2.53875 2.75484 2.9452C2.8 3.35166 2.61935 3.80327 2.30323 4.07424Z"
      fill="#595A5B"
    />
    <path d="M4.92252 0.7323H4.06445V5.15811H4.92252V0.7323Z" fill="#595A5B" />
    <path
      d="M7.09064 2.44831C6.5487 2.26766 6.41321 2.13218 6.41321 1.90637C6.41321 1.6354 6.68418 1.40959 7.04547 1.40959C7.31644 1.40959 7.54225 1.49992 7.76806 1.77089L8.21967 1.18379C7.85838 0.867657 7.40676 0.687012 6.90999 0.687012C6.14225 0.687012 5.50999 1.22895 5.50999 1.95153C5.50999 2.58379 5.78096 2.85476 6.59386 3.17089C6.95515 3.30637 7.09064 3.35153 7.18096 3.44186C7.3616 3.53218 7.45193 3.71283 7.45193 3.89347C7.45193 4.25476 7.18096 4.52573 6.77451 4.52573C6.36805 4.52573 6.00676 4.29992 5.82612 3.93863L5.28418 4.48057C5.69063 5.06767 6.18741 5.33864 6.81967 5.33864C7.72289 5.33864 8.35515 4.75154 8.35515 3.89347C8.35515 3.12573 8.08419 2.8096 7.09064 2.44831Z"
      fill="#595A5B"
    />
    <path
      d="M8.62598 2.94499C8.62598 4.25467 9.66469 5.24821 10.9744 5.24821C11.3357 5.24821 11.6518 5.15789 12.0582 4.97725V3.93854C11.6969 4.29983 11.3808 4.43531 11.0195 4.43531C10.1615 4.43531 9.5292 3.80306 9.5292 2.89983C9.5292 2.04177 10.1615 1.36435 10.9744 1.36435C11.3808 1.36435 11.6969 1.49984 12.0582 1.86113V0.82242C11.6969 0.641775 11.3808 0.551453 10.9744 0.551453C9.70985 0.641775 8.62598 1.68048 8.62598 2.94499Z"
      fill="#3B3D3F"
    />
    <path
      d="M19.148 3.71295L17.9738 0.7323H17.0254L18.877 5.29359H19.3738L21.3157 0.7323H20.3673L19.148 3.71295Z"
      fill="black"
    />
    <path
      d="M21.6777 5.15811H24.1616V4.43553H22.581V3.21617H24.1164V2.44843H22.581V1.50004H24.1616V0.7323H21.6777V5.15811Z"
      fill="black"
    />
    <path
      d="M26.5996 3.30649C27.2771 3.17101 27.6383 2.7194 27.6383 2.04198C27.6383 1.22907 27.0513 0.7323 26.0577 0.7323H24.748V5.15811H25.6061V3.39682H25.7416L26.9609 5.15811H27.9996L26.5996 3.30649ZM25.8771 2.76456H25.6061V1.40972H25.8771C26.419 1.40972 26.7351 1.63553 26.7351 2.08714C26.7351 2.53875 26.419 2.76456 25.8771 2.76456Z"
      fill="#595A5B"
    />
    <path
      d="M1.26452 0.7323H0V5.15811H1.26452C1.94194 5.15811 2.43871 4.97746 2.84516 4.66133C3.34194 4.25488 3.65806 3.62262 3.65806 2.9452C3.65806 1.63553 2.70968 0.7323 1.26452 0.7323ZM2.30323 4.07424C2.03226 4.30004 1.67097 4.43553 1.12903 4.43553H0.858065V1.50004H1.08387C1.62581 1.50004 1.9871 1.59036 2.25806 1.86133C2.57419 2.1323 2.75484 2.53875 2.75484 2.9452C2.8 3.35166 2.61935 3.80327 2.30323 4.07424Z"
      fill="#595A5B"
    />
    <path d="M4.92252 0.7323H4.06445V5.15811H4.92252V0.7323Z" fill="#595A5B" />
    <path
      d="M7.09064 2.44831C6.5487 2.26766 6.41321 2.13218 6.41321 1.90637C6.41321 1.6354 6.68418 1.40959 7.04547 1.40959C7.31644 1.40959 7.54225 1.49992 7.76806 1.77089L8.21967 1.18379C7.85838 0.867657 7.40676 0.687012 6.90999 0.687012C6.14225 0.687012 5.50999 1.22895 5.50999 1.95153C5.50999 2.58379 5.78096 2.85476 6.59386 3.17089C6.95515 3.30637 7.09064 3.35153 7.18096 3.44186C7.3616 3.53218 7.45193 3.71283 7.45193 3.89347C7.45193 4.25476 7.18096 4.52573 6.77451 4.52573C6.36805 4.52573 6.00676 4.29992 5.82612 3.93863L5.28418 4.48057C5.69063 5.06767 6.18741 5.33864 6.81967 5.33864C7.72289 5.33864 8.35515 4.75154 8.35515 3.89347C8.35515 3.12573 8.08419 2.8096 7.09064 2.44831Z"
      fill="#595A5B"
    />
    <path
      d="M11.0197 5.24848C11.381 5.24848 11.6971 5.15816 12.1036 4.97751V3.9388C11.7423 4.30009 11.4262 4.43557 11.0649 4.43557C10.2068 4.43557 9.57456 3.80332 9.57456 2.90009C9.57456 2.44848 9.75521 2.08719 10.0262 1.77106L9.43908 1.18396C8.98747 1.59041 8.67134 2.22267 8.67134 2.90009C8.62618 4.25493 9.66489 5.24848 11.0197 5.24848Z"
      fill="#595A5B"
    />
    <path
      d="M26.5996 3.30649C27.2771 3.17101 27.6383 2.7194 27.6383 2.04198C27.6383 1.22907 27.0513 0.7323 26.0577 0.7323H24.748V5.15811H25.6061V3.39682H25.7416L26.9609 5.15811H27.9996L26.5996 3.30649ZM25.8771 2.76456H25.6061V1.40972H25.8771C26.419 1.40972 26.7351 1.63553 26.7351 2.08714C26.7351 2.53875 26.419 2.76456 25.8771 2.76456Z"
      fill="black"
    />
    <path
      d="M6.36816 9.26769V6.78381L8.08429 8.54511V6.9193H8.44558V9.40317L6.72945 7.64188V9.26769H6.36816Z"
      fill="#F26E21"
    />
    <path
      d="M10.2521 7.28066H9.30367V7.8226H10.2069V8.18389H9.30367V8.95163H10.2521V9.26776H8.94238V6.91937H10.2521V7.28066Z"
      fill="#F26E21"
    />
    <path d="M11.3808 7.28042V9.26752H11.0195V7.28042H10.4775V6.96429H11.9227V7.28042H11.3808Z" fill="#F26E21" />
    <path
      d="M12.4648 6.96441L13.0519 8.54505L13.6842 6.87408L14.2713 8.54505L14.9035 6.96441H15.3551L14.3164 9.44827L13.7293 7.77731L13.0971 9.44827L12.1035 6.96441H12.4648Z"
      fill="#F26E21"
    />
    <path
      d="M15.4004 8.09344C15.4004 7.77731 15.5359 7.46118 15.7617 7.23537C15.9875 7.00957 16.3036 6.87408 16.6197 6.87408C16.9359 6.87408 17.252 7.00957 17.4778 7.23537C17.7036 7.46118 17.8391 7.77731 17.8391 8.09344C17.8391 8.40957 17.7036 8.7257 17.4778 8.9515C17.252 9.17731 16.9359 9.31279 16.6197 9.31279C16.3036 9.31279 16.0326 9.22247 15.8068 8.99666C15.5359 8.77086 15.4004 8.49989 15.4004 8.09344ZM15.7617 8.09344C15.7617 8.3644 15.852 8.54505 16.0326 8.7257C16.2133 8.90634 16.3939 8.99666 16.6197 8.99666C16.8456 8.99666 17.0714 8.90634 17.252 8.7257C17.4326 8.54505 17.523 8.3644 17.523 8.09344C17.523 7.82247 17.4326 7.64183 17.252 7.46118C17.0714 7.28054 16.8907 7.19021 16.6197 7.19021C16.3939 7.19021 16.1681 7.28054 15.9875 7.46118C15.852 7.64183 15.7617 7.86763 15.7617 8.09344Z"
      fill="#F26E21"
    />
    <path
      d="M19.1039 8.27421L19.8265 9.26776H19.3749L18.6975 8.31937H18.6523V9.26776H18.291V6.91937H18.6975C19.0136 6.91937 19.2394 6.96453 19.3749 7.10002C19.5104 7.2355 19.6007 7.41615 19.6007 7.59679C19.6007 7.77744 19.5555 7.91292 19.4652 8.00324C19.3749 8.18389 19.2394 8.27421 19.1039 8.27421ZM18.6523 8.00324H18.7426C19.0588 8.00324 19.2394 7.86776 19.2394 7.64195C19.2394 7.41615 19.0588 7.28066 18.7426 7.28066H18.6071V8.00324H18.6523Z"
      fill="#F26E21"
    />
    <path
      d="M20.5041 7.86763L21.4073 6.91925H21.8589L20.8202 7.95795L21.8589 9.22247H21.4073L20.5493 8.18376L20.4589 8.27408V9.22247H20.0977V6.87408H20.4589V7.86763H20.5041Z"
      fill="#F26E21"
    />
    <path d="M23.3929 4.43538H22.58V3.62247L21.6768 2.71924V5.15796H24.1155L23.3929 4.43538Z" fill="#3B3D3F" />
    <path
      d="M20.1415 1.18391L19.148 3.71295L17.9738 0.7323H17.0254L18.877 5.29359H19.3738L20.8189 1.86133L20.1415 1.18391Z"
      fill="#3B3D3F"
    />
  </svg>
  <!-- eslint-enable -->
</template>
