import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/gtm/james.png'
import _imports_1 from '../../assets/gtm/icon_left.png'
import _imports_2 from '../../assets/gtm/icon_right.png'
import _imports_3 from '../../assets/check.svg'
import _imports_4 from '../../assets/gtm/edit.png'


const _hoisted_1 = { class: "main_steps" }
const _hoisted_2 = {
  key: 0,
  class: "main_steps_header"
}
const _hoisted_3 = {
  key: 0,
  class: "main_steps_body"
}
const _hoisted_4 = {
  key: 0,
  class: "step_three"
}
const _hoisted_5 = { class: "slot_picker" }
const _hoisted_6 = { class: "slot_picker_body" }
const _hoisted_7 = { class: "slot_picker_footer" }
const _hoisted_8 = {
  key: 1,
  class: "step_four"
}
const _hoisted_9 = { class: "input_field" }
const _hoisted_10 = {
  key: 0,
  src: _imports_3,
  alt: ""
}
const _hoisted_11 = { class: "input_field" }
const _hoisted_12 = {
  key: 0,
  src: _imports_3,
  alt: ""
}
const _hoisted_13 = { class: "input_field" }
const _hoisted_14 = {
  key: 0,
  src: _imports_3,
  alt: ""
}
const _hoisted_15 = {
  key: 0,
  class: "budget_per_head_info"
}
const _hoisted_16 = {
  key: 2,
  class: "step_five"
}
const _hoisted_17 = { class: "list_of_variants" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["src"]
const _hoisted_20 = {
  key: 3,
  class: "step_six"
}
const _hoisted_21 = { class: "list_of_variants" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = {
  key: 4,
  class: "step_seven"
}
const _hoisted_24 = { class: "list_of_variants" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = ["src"]
const _hoisted_27 = {
  key: 5,
  class: "step_eight"
}
const _hoisted_28 = { class: "summary_card" }
const _hoisted_29 = { class: "summary_card_body" }
const _hoisted_30 = { class: "summary_card_description" }
const _hoisted_31 = { class: "summary_card" }
const _hoisted_32 = { class: "summary_card_body" }
const _hoisted_33 = { class: "summary_card_description" }
const _hoisted_34 = { class: "summary_card" }
const _hoisted_35 = { class: "summary_card_body" }
const _hoisted_36 = { class: "summary_card_description" }
const _hoisted_37 = {
  key: 0,
  class: "main_steps_body"
}
const _hoisted_38 = {
  key: 0,
  class: "main_steps_footer"
}
const _hoisted_39 = { class: "progress_bar" }
const _hoisted_40 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_CalendarGTM = _resolveComponent("CalendarGTM")!
  const _component_AddressChangeInput = _resolveComponent("AddressChangeInput")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_CurrencyInput = _resolveComponent("CurrencyInput")!
  const _component_app_text_accordion = _resolveComponent("app-text-accordion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.areHeaderAndFooterLoaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Logo),
              _createVNode(_component_app_button, {
                square: "",
                white: "",
                slim: "",
                class: "chat_with_us",
                onClick: _withModifiers(_ctx.chatWithUs, ["prevent"])
              }, {
                default: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }, null, -1),
                  _createElementVNode("p", null, "Chat with us", -1)
                ])),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_app_button, {
                square: "",
                white: "",
                slim: "",
                class: "exit",
                onClick: _withModifiers(_ctx.exit, ["prevent"])
              }, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createElementVNode("p", null, "Exit", -1)
                ])),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isBodyLoaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.step === 3)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _cache[23] || (_cache[23] = _createElementVNode("p", { class: "step_number" }, "Step 3", -1)),
                    _cache[24] || (_cache[24] = _createElementVNode("h1", { class: "step_title" }, "Event slot", -1)),
                    _cache[25] || (_cache[25] = _createElementVNode("p", { class: "step_hint" }, "Don't worry, you can choose another slot later.", -1)),
                    _createVNode(_component_CalendarGTM, {
                      dateTime: _ctx.dateTime,
                      onConfirmClick: _ctx.onCalendarConfirmClick
                    }, null, 8, ["dateTime", "onConfirmClick"]),
                    _createElementVNode("div", _hoisted_5, [
                      _cache[21] || (_cache[21] = _createElementVNode("p", { class: "slot_picker_title" }, "What time works?", -1)),
                      _cache[22] || (_cache[22] = _createElementVNode("p", { class: "slot_picker_subtitle" }, "Let us know a time you'd want the order delivered", -1)),
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["navigation_button custom-cursor-hover", { disabled: _ctx.slotCarouselIndex === 1 }]),
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.slotDecrease && _ctx.slotDecrease(...args)))
                        }, _cache[17] || (_cache[17] = [
                          _createElementVNode("img", {
                            class: "left",
                            src: _imports_1,
                            alt: ""
                          }, null, -1)
                        ]), 2),
                        _createElementVNode("p", {
                          class: _normalizeClass(["custom-cursor-hover", { active: _ctx.availableSlots[_ctx.slotCarouselIndex - 1] === _ctx.selectedSlot }]),
                          onClick: _cache[1] || (_cache[1] = () => (_ctx.selectedSlot = _ctx.availableSlots[_ctx.slotCarouselIndex - 1]))
                        }, _toDisplayString(_ctx.availableSlots[_ctx.slotCarouselIndex - 1]), 3),
                        _createElementVNode("p", {
                          class: _normalizeClass(["custom-cursor-hover", { active: _ctx.availableSlots[_ctx.slotCarouselIndex] === _ctx.selectedSlot }]),
                          onClick: _cache[2] || (_cache[2] = () => (_ctx.selectedSlot = _ctx.availableSlots[_ctx.slotCarouselIndex]))
                        }, _toDisplayString(_ctx.availableSlots[_ctx.slotCarouselIndex]), 3),
                        _createElementVNode("p", {
                          class: _normalizeClass(["custom-cursor-hover", { active: _ctx.availableSlots[_ctx.slotCarouselIndex + 1] === _ctx.selectedSlot }]),
                          onClick: _cache[3] || (_cache[3] = () => (_ctx.selectedSlot = _ctx.availableSlots[_ctx.slotCarouselIndex + 1]))
                        }, _toDisplayString(_ctx.availableSlots[_ctx.slotCarouselIndex + 1]), 3),
                        _createElementVNode("div", {
                          class: _normalizeClass(["navigation_button custom-cursor-hover", { disabled: _ctx.slotCarouselIndex === _ctx.availableSlots.length - 2 }]),
                          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.slotIncrease && _ctx.slotIncrease(...args)))
                        }, _cache[18] || (_cache[18] = [
                          _createElementVNode("img", {
                            class: "right",
                            src: _imports_2,
                            alt: ""
                          }, null, -1)
                        ]), 2)
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["navigation_button custom-cursor-hover", { disabled: _ctx.slotCarouselIndex === 1 }]),
                          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.slotDecrease && _ctx.slotDecrease(...args)))
                        }, _cache[19] || (_cache[19] = [
                          _createElementVNode("img", {
                            class: "left",
                            src: _imports_1,
                            alt: ""
                          }, null, -1)
                        ]), 2),
                        _createElementVNode("div", {
                          class: _normalizeClass(["navigation_button custom-cursor-hover", { disabled: _ctx.slotCarouselIndex === _ctx.availableSlots.length - 2 }]),
                          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.slotIncrease && _ctx.slotIncrease(...args)))
                        }, _cache[20] || (_cache[20] = [
                          _createElementVNode("img", {
                            class: "right",
                            src: _imports_2,
                            alt: ""
                          }, null, -1)
                        ]), 2)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.step === 4)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _cache[29] || (_cache[29] = _createElementVNode("p", { class: "step_number" }, "Step 4", -1)),
                    _cache[30] || (_cache[30] = _createElementVNode("h1", { class: "step_title" }, "Event information", -1)),
                    _createElementVNode("div", null, [
                      _cache[26] || (_cache[26] = _createElementVNode("p", { class: "input_title" }, "Where are we delivering to?", -1)),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_AddressChangeInput, {
                          onChange: _ctx.onAddressChange,
                          placeholder: "Start typing...",
                          isAlwaysEditing: "",
                          isBasicFont: "",
                          showError: _ctx.show,
                          addErrorPlaceholderSpacing: "",
                          acceptAllCities: ""
                        }, _createSlots({ _: 2 }, [
                          (_ctx.yupErrMsg('address'))
                            ? {
                                name: "error",
                                fn: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.yupErrMsg('address')), 1)
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["onChange", "showError"]),
                        (_ctx.address?.formattedAddress)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_10))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", null, [
                      _cache[27] || (_cache[27] = _createElementVNode("p", { class: "input_title" }, "How many people?", -1)),
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_app_input, {
                          id: "headcount",
                          placeholder: "e.g. 10",
                          type: "number",
                          value: _ctx.headcount,
                          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.headcount) = $event)),
                          showError: _ctx.show,
                          addErrorPlaceholderSpacing: ""
                        }, _createSlots({ _: 2 }, [
                          (_ctx.yupErrMsg('headcount'))
                            ? {
                                name: "error",
                                fn: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.yupErrMsg('headcount')), 1)
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["value", "showError"]),
                        (_ctx.headcount)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", null, [
                      _cache[28] || (_cache[28] = _createElementVNode("p", { class: "input_title" }, "Budget?", -1)),
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_CurrencyInput, {
                          id: "budget",
                          modelValue: _ctx.budget,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.budget) = $event)),
                          options: {
                  currency: 'GBP',
                  precision: 2,
                  hideCurrencySymbolOnFocus: false,
                  locale: 'en-GB',
                },
                          showError: _ctx.show
                        }, _createSlots({ _: 2 }, [
                          (_ctx.yupErrMsg('budget'))
                            ? {
                                name: "error",
                                fn: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.yupErrMsg('budget')), 1)
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["modelValue", "showError"]),
                        (_ctx.budget)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_14))
                          : _createCommentVNode("", true)
                      ]),
                      (_ctx.headcount > 0 && _ctx.budget > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_15, " This works out as £" + _toDisplayString(_ctx.chosenBudgetPerHead) + "/head ", 1))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.step === 5)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _cache[31] || (_cache[31] = _createElementVNode("p", { class: "step_number" }, "Step 5", -1)),
                    _cache[32] || (_cache[32] = _createElementVNode("h1", { class: "step_title" }, "Preferred cuisines", -1)),
                    _cache[33] || (_cache[33] = _createElementVNode("p", { class: "step_hint" }, "Choose as many as you like - we'll curate your restaurant selection around it", -1)),
                    _createElementVNode("div", _hoisted_17, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typesOfFood, (value, key) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: key,
                          class: _normalizeClass(["variant custom-cursor-hover", { active: _ctx.typesOfFood[key] }]),
                          onClick: () => (_ctx.typesOfFood[key] = !_ctx.typesOfFood[key])
                        }, [
                          _createElementVNode("img", {
                            src: require(`../../assets/gtm/food/${key}.png`),
                            alt: ""
                          }, null, 8, _hoisted_19),
                          _createElementVNode("p", null, _toDisplayString(key), 1)
                        ], 10, _hoisted_18))
                      }), 128))
                    ]),
                    _createVNode(_component_app_text_accordion, {
                      title: "Other",
                      class: "other_variants",
                      isGTM: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_app_input, {
                          value: _ctx.otherTypesOfFood,
                          "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.otherTypesOfFood) = $event)),
                          class: "animated slideOutDown"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.step === 6)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _cache[34] || (_cache[34] = _createElementVNode("p", { class: "step_number" }, "Step 6", -1)),
                    _cache[35] || (_cache[35] = _createElementVNode("h1", { class: "step_title" }, "Preferred presentation style", -1)),
                    _createElementVNode("div", _hoisted_21, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typesOfPresentationStyles, (value, key) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: key,
                          class: _normalizeClass(["variant custom-cursor-hover", { active: _ctx.typesOfPresentationStyles[key] }]),
                          onClick: () => (_ctx.typesOfPresentationStyles[key] = !_ctx.typesOfPresentationStyles[key])
                        }, [
                          _createElementVNode("p", null, _toDisplayString(key), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.presentationStyleDescriptions[key]), 1)
                        ], 10, _hoisted_22))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.step === 7)
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    _cache[36] || (_cache[36] = _createElementVNode("p", { class: "step_number" }, "Step 7", -1)),
                    _cache[37] || (_cache[37] = _createElementVNode("h1", { class: "step_title" }, "Allergen requirements", -1)),
                    _createElementVNode("div", _hoisted_24, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typesOfAllergens, (value, key) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: key,
                          class: _normalizeClass(["variant custom-cursor-hover", { active: _ctx.typesOfAllergens[key] }]),
                          onClick: () => (_ctx.typesOfAllergens[key] = !_ctx.typesOfAllergens[key])
                        }, [
                          _createElementVNode("img", {
                            src: require(`../../assets/gtm/allergens/${key}.png`),
                            alt: ""
                          }, null, 8, _hoisted_26),
                          _createElementVNode("p", null, _toDisplayString(key), 1)
                        ], 10, _hoisted_25))
                      }), 128))
                    ]),
                    _createVNode(_component_app_text_accordion, {
                      title: "Other",
                      class: "other_variants",
                      isGTM: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_app_input, {
                          value: _ctx.otherTypesOfAllergens,
                          "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.otherTypesOfAllergens) = $event)),
                          class: "animated slideOutDown"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.step === 8)
                ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                    _cache[50] || (_cache[50] = _createElementVNode("h1", { class: "step_title" }, "Your event review", -1)),
                    _createElementVNode("div", _hoisted_28, [
                      _createElementVNode("div", _hoisted_29, [
                        _cache[43] || (_cache[43] = _createElementVNode("p", { class: "summary_card_title" }, "Delivery", -1)),
                        _createElementVNode("p", _hoisted_30, [
                          _cache[38] || (_cache[38] = _createTextVNode(" We'll deliver to ")),
                          _createElementVNode("strong", null, _toDisplayString(_ctx.chosenStreet), 1),
                          _cache[39] || (_cache[39] = _createTextVNode(" on ")),
                          _createElementVNode("strong", null, _toDisplayString(_ctx.chosenDate), 1),
                          _cache[40] || (_cache[40] = _createTextVNode(" between ")),
                          _createElementVNode("strong", null, _toDisplayString(_ctx.chosenInterval), 1),
                          _cache[41] || (_cache[41] = _createTextVNode(" for ")),
                          _createElementVNode("strong", null, _toDisplayString(_ctx.headcount) + " people", 1),
                          _cache[42] || (_cache[42] = _createTextVNode(" with a ")),
                          _createElementVNode("strong", null, "£" + _toDisplayString(_ctx.chosenBudgetPerHead) + "/head budget", 1)
                        ])
                      ]),
                      _createElementVNode("img", {
                        class: "summary_card_edit custom-cursor-hover",
                        src: _imports_4,
                        alt: "",
                        onClick: _cache[11] || (_cache[11] = () => _ctx.goBackToStep(3))
                      })
                    ]),
                    _createElementVNode("div", _hoisted_31, [
                      _createElementVNode("div", _hoisted_32, [
                        _cache[46] || (_cache[46] = _createElementVNode("p", { class: "summary_card_title" }, "Cuisines and presentation", -1)),
                        _createElementVNode("p", _hoisted_33, [
                          _cache[44] || (_cache[44] = _createTextVNode(" We'll find you a mix of ")),
                          _createElementVNode("strong", null, _toDisplayString(_ctx.chosenCuisines), 1),
                          _createTextVNode(" restaurants, in " + _toDisplayString(_ctx.chosenPresentationStyles === 'any' ? '' : 'a') + " ", 1),
                          _createElementVNode("strong", null, _toDisplayString(_ctx.chosenPresentationStyles), 1),
                          _cache[45] || (_cache[45] = _createTextVNode(" presentation style "))
                        ])
                      ]),
                      _createElementVNode("img", {
                        class: "summary_card_edit custom-cursor-hover",
                        src: _imports_4,
                        alt: "",
                        onClick: _cache[12] || (_cache[12] = () => _ctx.goBackToStep(5))
                      })
                    ]),
                    _createElementVNode("div", _hoisted_34, [
                      _createElementVNode("div", _hoisted_35, [
                        _cache[49] || (_cache[49] = _createElementVNode("p", { class: "summary_card_title" }, "Allergens and dietaries", -1)),
                        _createElementVNode("p", _hoisted_36, [
                          _cache[47] || (_cache[47] = _createTextVNode(" We'll make sure that there are ")),
                          _createElementVNode("strong", null, _toDisplayString(_ctx.chosenAllergenRequirements), 1),
                          _cache[48] || (_cache[48] = _createTextVNode(" options to choose from "))
                        ])
                      ]),
                      _createElementVNode("img", {
                        class: "summary_card_edit custom-cursor-hover",
                        src: _imports_4,
                        alt: "",
                        onClick: _cache[13] || (_cache[13] = () => _ctx.goBackToStep(7))
                      })
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!_ctx.isBodyLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_37))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.areHeaderAndFooterLoaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", {
                    style: _normalizeStyle(`width: ${_ctx.progressBarWidth1}%`)
                  }, null, 4)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", {
                    style: _normalizeStyle(`width: ${_ctx.progressBarWidth2}%`)
                  }, null, 4)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", {
                    style: _normalizeStyle(`width: ${_ctx.progressBarWidth3}%`)
                  }, null, 4)
                ]),
                _cache[51] || (_cache[51] = _createElementVNode("div", null, null, -1))
              ]),
              _createElementVNode("div", _hoisted_40, [
                (_ctx.step > 3)
                  ? (_openBlock(), _createBlock(_component_app_button, {
                      key: 0,
                      class: "back_button",
                      square: "",
                      slim: "",
                      onClick: _cache[14] || (_cache[14] = () => _ctx.goBackToStep(_ctx.step - 1))
                    }, {
                      default: _withCtx(() => _cache[52] || (_cache[52] = [
                        _createTextVNode(" Previous step ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.step === 8)
                  ? (_openBlock(), _createBlock(_component_app_button, {
                      key: 1,
                      class: "add_button",
                      square: "",
                      slim: "",
                      onClick: _ctx.bookSlot,
                      disabled: _ctx.isNextStepDisabled || _ctx.isBookingSlot,
                      loader: _ctx.isBookingSlot
                    }, {
                      default: _withCtx(() => _cache[53] || (_cache[53] = [
                        _createTextVNode(" Add a deposit ")
                      ])),
                      _: 1
                    }, 8, ["onClick", "disabled", "loader"]))
                  : (_ctx.step === 3 && _ctx.isUsingExistingDetails)
                    ? (_openBlock(), _createBlock(_component_app_button, {
                        key: 2,
                        class: "confirm_button",
                        square: "",
                        slim: "",
                        onClick: _ctx.goToReview,
                        disabled: _ctx.isNextStepDisabled
                      }, {
                        default: _withCtx(() => _cache[54] || (_cache[54] = [
                          _createTextVNode(" Confirm event ")
                        ])),
                        _: 1
                      }, 8, ["onClick", "disabled"]))
                    : (_openBlock(), _createBlock(_component_app_button, {
                        key: 3,
                        class: "next_button",
                        square: "",
                        slim: "",
                        onClick: _ctx.goToNextStep,
                        disabled: _ctx.isNextStepDisabled
                      }, {
                        default: _withCtx(() => _cache[55] || (_cache[55] = [
                          _createTextVNode(" Next step ")
                        ])),
                        _: 1
                      }, 8, ["onClick", "disabled"]))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}