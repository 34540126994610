<template>
  <app-modal class="group-order-what-next-modal" v-bind="options">
    <div class="group-order-what-next-modal-content">
      <h1>What happens next?</h1>
      <p class="subtitle">We've received your group order. Here's what happens now:</p>
      <div class="group-order-what-next-bullet-point">
        <div class="bullet-point-box">
          <img src="../../assets/order_tracking/chevron_right.png" alt="" />
        </div>
        <p>We've already sent a list of dishes and names to the restaurant</p>
      </div>
      <div class="group-order-what-next-bullet-point">
        <div class="bullet-point-box">
          <img src="../../assets/order_tracking/chevron_right.png" alt="" />
        </div>
        <p>Each dish will be individually labelled</p>
      </div>
      <div class="group-order-what-next-bullet-point">
        <div class="bullet-point-box">
          <img src="../../assets/order_tracking/chevron_right.png" alt="" />
        </div>
        <p>Tracking will kick in when the restaurant starts preparing your order</p>
      </div>
      <app-button class="custom-button" @click.prevent="close">Gotcha</app-button>
    </div>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';
import IconServices from '@/shared/icons/IconServices.vue';

@Options({
  components: { IconServices },
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class ModalAmendOrderInit extends Vue {
  private options: ModalInterface = {
    slim: false,
    close: false,
    noPadding: true,
  };

  private close() {
    this.$store.commit('modals/close');
  }
}
</script>

<style lang="scss">
.group-order-what-next-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .modal {
    animation: $modal-show-bottom;
    max-width: 380px;
  }
}
</style>

<style lang="scss" scoped>
.group-order-what-next-modal-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 30px;
}

h1 {
  font-weight: 600;
  font-size: 35px;
  line-height: 35px;
  margin-top: 20px;
}

p {
  font-family:
    Sofia Pro,
    sans-serif;
  font-weight: 500;
}

.subtitle {
  margin-bottom: 5px;
}

.group-order-what-next-bullet-point {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.bullet-point-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $ham;
  height: 24px;
  aspect-ratio: 1;
  border-radius: 4px;

  img {
    width: 16px;
    margin-left: auto;
    margin-right: auto;
  }
}

.custom-button {
  margin-top: 25px;
  margin-bottom: 15px;
  min-height: 50px;
  height: 50px;
}
</style>
