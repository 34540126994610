import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/order_tracking/chevron_right.png'


const _hoisted_1 = { class: "group-order-what-next-modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "group-order-what-next-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("h1", null, "What happens next?", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "subtitle" }, "We've received your group order. Here's what happens now:", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "group-order-what-next-bullet-point" }, [
          _createElementVNode("div", { class: "bullet-point-box" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            })
          ]),
          _createElementVNode("p", null, "We've already sent a list of dishes and names to the restaurant")
        ], -1)),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "group-order-what-next-bullet-point" }, [
          _createElementVNode("div", { class: "bullet-point-box" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            })
          ]),
          _createElementVNode("p", null, "Each dish will be individually labelled")
        ], -1)),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "group-order-what-next-bullet-point" }, [
          _createElementVNode("div", { class: "bullet-point-box" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            })
          ]),
          _createElementVNode("p", null, "Tracking will kick in when the restaurant starts preparing your order")
        ], -1)),
        _createVNode(_component_app_button, {
          class: "custom-button",
          onClick: _withModifiers(_ctx.close, ["prevent"])
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Gotcha")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 16))
}