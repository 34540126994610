import { StateExtraTime } from '@/store/modules/extraTime';
import { StateTimePicker } from '@/store/modules/timePicker';

import http from '@/utils/http';

class HttpSettings {
  public async getClosestDeliveryOpeningHour(options: string): Promise<string> {
    const res = await http.get(`/settings/closest-delivery-opening-hour${options}`);
    return res.message;
  }

  public getTimePickerConfiguration(): Promise<StateTimePicker> {
    return http.get('/settings/time-picker-data');
  }

  public getExtraTimeConfiguration(): Promise<StateExtraTime> {
    return http.get('/settings/extra-time-data');
  }
}

export default new HttpSettings();
