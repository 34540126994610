import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "restaurant-info-loader"
}
const _hoisted_2 = {
  key: 2,
  class: "restaurant-menu"
}
const _hoisted_3 = {
  key: 0,
  class: "min-cart-price-info"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "show-basket-wrapper"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoriesBar = _resolveComponent("CategoriesBar")!
  const _component_GroupOrderStepTracker = _resolveComponent("GroupOrderStepTracker")!
  const _component_About = _resolveComponent("About")!
  const _component_Basket = _resolveComponent("Basket")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { id: "scroll-measure" }, null, -1)),
    _createVNode(_component_CategoriesBar, {
      class: _normalizeClass({ transition: _ctx.transition }),
      categories: _ctx.restaurantData.menuCategories,
      onSelect: _ctx.selectCategories
    }, null, 8, ["class", "categories", "onSelect"]),
    _createElementVNode("main", {
      class: _normalizeClass(["restaurant-info", {
      transition: _ctx.transition,
      'with-step-tracker':
        _ctx.groupData && !_ctx.amendableData && (!_ctx.groupOrderIdFromUrl || _ctx.groupOrderIdFromUrl === _ctx.groupData.id),
    }])
    }, [
      (_ctx.groupData && !_ctx.amendableData && (!_ctx.groupOrderIdFromUrl || _ctx.groupOrderIdFromUrl === _ctx.groupData.id))
        ? (_openBlock(), _createBlock(_component_GroupOrderStepTracker, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_About, {
        restaurant: _ctx.restaurantData.restaurant,
        wholeMenuMinHoursInAdvance: _ctx.wholeMenuMinHoursInAdvance
      }, null, 8, ["restaurant", "wholeMenuMinHoursInAdvance"]),
      _createVNode(_component_Basket, {
        restaurant: _ctx.restaurantData.restaurant
      }, null, 8, ["restaurant"]),
      (_ctx.updating)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_Logo, { class: "logo-loader" })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (
          (_ctx.restaurantData.restaurant.minCartPrice || _ctx.restaurantData.restaurant.discountPercentage) &&
          _ctx.groupRole !== 'Participant'
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createTextVNode(_toDisplayString(_ctx.restaurantData.restaurant.minCartPrice
            ? // eslint-disable-next-line
              `A £${_ctx.restaurantData.restaurant.minCartPrice} minimum is required to order from ${_ctx.restaurantData.restaurant.name}.`
            : '') + " ", 1),
                  (
            _ctx.restaurantData.restaurant.minCartPrice &&
            _ctx.restaurantData.restaurant.discountPercentage &&
            _ctx.isDeliveryLateEnoughToGetDiscount
          )
                    ? (_openBlock(), _createElementBlock("br", _hoisted_4))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(_ctx.restaurantData.restaurant.discountPercentage && _ctx.isDeliveryLateEnoughToGetDiscount
            ? // eslint-disable-next-line
              `A ${_ctx.restaurantData.restaurant.discountPercentage}% discount will be automatically applied to orders over £${_ctx.restaurantData.restaurant.discountMinCartPrice}.`
            : '') + " " + _toDisplayString(_ctx.restaurantData.restaurant.discountPercentage && !_ctx.isDeliveryLateEnoughToGetDiscount
            ? // eslint-disable-next-line
              `Place a £${_ctx.restaurantData.restaurant.discountMinCartPrice} order ${_ctx.restaurantData.restaurant.discountMinHoursInAdvance} hours in advance and a ${_ctx.restaurantData.restaurant.discountPercentage}% discount will be automatically applied to your basket.`
            : ''), 1)
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.restaurantData.menuCategories, (menu, mIndex) => {
              return (_openBlock(), _createBlock(_component_Menu, {
                id: menu.slug,
                menu: menu,
                slug: _ctx.restaurantData.restaurant.slug,
                key: mIndex,
                restaurant: _ctx.restaurantData.restaurant
              }, null, 8, ["id", "menu", "slug", "restaurant"]))
            }), 128))
          ]))
    ], 2),
    (!_ctx.amendableData && ((_ctx.$route.params.slug === _ctx.restaurant?.slug && _ctx.cartPrice) || _ctx.groupData))
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_app_button, {
            onClick: _ctx.openBasketModal,
            class: "show-basket"
          }, {
            default: _withCtx(() => [
              (_ctx.groupRole === 'Participant' && _ctx.areItemsConfirmed)
                ? (_openBlock(), _createElementBlock("strong", _hoisted_6, "Items added"))
                : (_ctx.groupRole === 'Participant' && !_ctx.areItemsConfirmed)
                  ? (_openBlock(), _createElementBlock("strong", _hoisted_7, " Add your items - £" + _toDisplayString(_ctx.cartPrice.toFixed(2)), 1))
                  : (_openBlock(), _createElementBlock("strong", _hoisted_8, "Checkout - £" + _toDisplayString(_ctx.cartPrice.toFixed(2)), 1))
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}