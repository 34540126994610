<template>
  <div class="payment-method-wrapper">
    <div class="payment-method">
      <div class="payment-method-icon">
        <IconPaymentMethod :brand="current.card ? current?.card?.brand : current.invoice ? 'Invoice' : 'all'" />

        <small class="payment-method-info" v-if="current.card"> Ending {{ current.card.last4 }} </small>
        <small class="payment-method-info" v-if="current.invoice">Send me an invoice</small>
      </div>
      <button @click.prevent="deleteCard(current.id)" v-if="current.card">
        <span>Delete</span>
      </button>
    </div>

    <div class="payment-method-change" v-if="change && paymentMethods.length > 0">
      <hr />

      <small class="payment-method-change-title"> Select payment method </small>

      <ul class="payment-method-change-list">
        <li class="payment-method-change-list-item" v-for="(paymentMethod, pmIndex) in paymentMethods" :key="pmIndex">
          <IconPaymentMethod :brand="paymentMethod?.card?.brand" v-if="paymentMethod.card" />
          <small class="payment-method-info" v-if="current && paymentMethod.card">
            Ending {{ paymentMethod.card.last4 }}
          </small>
          <app-option
            v-if="paymentMethod.card"
            type="radio"
            :active="active(paymentMethod.id)"
            @click.prevent="
              () => {
                selected(paymentMethod);
                closeWhenPayment();
              }
            "
          />

          <IconPaymentMethod :brand="'Invoice'" v-if="paymentMethod.invoice" />
          <small class="payment-method-info" v-if="current && paymentMethod.invoice">Send me an invoice</small>
          <app-option
            v-if="paymentMethod.invoice"
            type="radio"
            :active="active(paymentMethod.id)"
            @click.prevent="
              () => {
                selected(paymentMethod);
                closeWhenPayment();
              }
            "
          />
        </li>
      </ul>
    </div>

    <Stripe @focus="focus" v-if="add" />
  </div>
</template>

<script lang="ts">
import { watch } from 'vue';
import { Options, Vue, prop } from 'vue-class-component';

import Stripe from '@/components/PaymentMethod/Stripe.vue';

import IconPaymentMethod from '@/shared/icons/IconPaymentMethod.vue';

class Props {
  paymentMethods = prop<{ id: string }[]>({ required: true });
  change = prop<boolean>({ required: true });
  add = prop<boolean>({ required: true });
  closeWhenPayment = prop<Function>({ required: true });
}

@Options({
  components: {
    Stripe,
    IconPaymentMethod,
  },
  props: {
    paymentMethods: [Array, undefined],
    change: Boolean,
    add: Boolean,
    closeWhenPayment: Function,
  },
  emits: {
    selected: Object,
  },
})
export default class PaymentMethod extends Vue.with(Props) {
  private current: any = false;

  private select: any = false;

  private newCard = false;

  private active(id: string) {
    if (this.newCard) return false;

    if (this.select) return this.select?.id === id;
    return this.current.id === id;
  }

  private deleteCard(id: string) {
    this.$store.commit('modals/data', id);
    this.$store.commit('modals/show', 'deleteCard');
  }

  private selected(paymentMethod: any) {
    this.newCard = false;
    this.select = paymentMethod;

    this.current = paymentMethod;

    this.$emit('selected', paymentMethod);
  }

  private focus() {
    this.newCard = true;
    this.$emit('selected', { id: '' });
  }

  created() {
    watch(
      () => this.paymentMethods,
      (newValue: any) => {
        if (newValue.length > 0) this.current = { ...newValue[0] };
        else {
          this.current = false;
        }
      },
    );
  }
}
</script>

<style lang="scss" scoped>
.payment-method {
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    margin-left: auto;
    span {
      color: #e58b90;
      text-decoration: underline;
      font-weight: 300;
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 15px;
    margin-bottom: 20px;
    border: 1px solid $coal20;
    background-color: white;
    border-radius: 15px;
    padding: 5px 15px;
  }

  &-info {
    margin-left: 5px;
    font-weight: 600;
    color: $coal100;
    font-size: 15px;
    b {
      color: $coal100;
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    .icon-card-brand {
      margin-right: 5px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  button {
    color: $darkHam;
  }

  &-add {
    display: grid;
  }

  &-change {
    width: 100%;
    &-title {
      display: block;
      margin-bottom: 10px;
    }
    &-list {
      &-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-right: 15px;

        .controller-option {
          margin-left: auto;
        }

        &:hover {
          button {
            span {
              opacity: 1;
              &:hover {
                color: $darkHam;
              }
            }
          }
        }
      }
    }
  }
}
</style>
