/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state: StateExtraTime = {
  prepTime: [{ minCartPrice: 0, additionalTime: 0 }],
  loadingTime: [{ minCartPrice: 0, additionalTime: 0 }],
};

const mutations = {
  set(state: StateExtraTime, payload: StateExtraTime) {
    state.prepTime = payload.prepTime;
    state.loadingTime = payload.loadingTime;
  },
};

const actions = {};

const getters = {};

export interface StateExtraTime {
  prepTime: {
    minCartPrice: number;
    additionalTime: number;
  }[];
  loadingTime: {
    minCartPrice: number;
    additionalTime: number;
  }[];
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
