import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "restaurant-header-wrapper"
}
const _hoisted_2 = {
  key: 0,
  class: "embedded-header"
}
const _hoisted_3 = {
  key: 1,
  style: {"height":"40px","width":"40px"}
}
const _hoisted_4 = {
  key: 3,
  style: {"height":"40px","width":"40px"}
}
const _hoisted_5 = {
  key: 0,
  class: "restaurant-header-back-btn"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 0,
  class: "restaurant-header-info-buttons"
}
const _hoisted_9 = {
  key: 1,
  class: "restaurant-header-description-wrapper"
}
const _hoisted_10 = { class: "restaurant-header-description" }
const _hoisted_11 = { class: "restaurant-header-actions-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_back_link = _resolveComponent("app-back-link")!
  const _component_OrderSettingsButton = _resolveComponent("OrderSettingsButton")!
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_CreateGroupOrderButton = _resolveComponent("CreateGroupOrderButton")!
  const _component_AmendableOrderInfo = _resolveComponent("AmendableOrderInfo")!

  return (_ctx.restaurant)
    ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
        (_ctx.$route.query.embedded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.isInCheckout)
                ? (_openBlock(), _createBlock(_component_app_back_link, {
                    key: 0,
                    mini: "",
                    link: `/menu/${_ctx.restaurant?.restaurantCity}/${_ctx.restaurant?.slug}`
                  }, null, 8, ["link"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_3)),
              _createVNode(_component_OrderSettingsButton, {
                disabled: _ctx.isInCheckout || (!_ctx.restaurant.allowScheduledOrders && !_ctx.restaurant.clickAndCollect),
                restaurant: _ctx.restaurant,
                basketTotal: +_ctx.totalPrice
              }, null, 8, ["disabled", "restaurant", "basketTotal"]),
              (_ctx.user)
                ? (_openBlock(), _createBlock(_component_app_button, {
                    key: 2,
                    mini: "",
                    square: "",
                    white: "",
                    onClick: _ctx.goToSettings
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_IconServices, { icon: "account" })
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_4))
            ]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (
          !(
            _ctx.groupData &&
            !_ctx.amendableData &&
            (!_ctx.groupOrderIdFromUrl || _ctx.groupOrderIdFromUrl === _ctx.groupData.id) &&
            !_ctx.isInCheckout
          )
        )
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_app_back_link, {
                      mini: "",
                      link: `${
            _ctx.isInCheckout
              ? `/menu/${_ctx.restaurant?.restaurantCity}/${_ctx.restaurant?.slug}`
              : `/restaurants/${_ctx.restaurant?.restaurantCity}`
          }`
                    }, null, 8, ["link"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("img", {
                src: _ctx.restaurant.cover,
                class: _normalizeClass(["restaurant-header-cover", {
          shrinked:
            _ctx.groupData &&
            !_ctx.amendableData &&
            (!_ctx.groupOrderIdFromUrl || _ctx.groupOrderIdFromUrl === _ctx.groupData.id) &&
            !_ctx.isInCheckout,
        }]),
                alt: ""
              }, null, 10, _hoisted_6),
              _createElementVNode("div", {
                class: _normalizeClass(["restaurant-header-info", { checkout: _ctx.isInCheckout }])
              }, [
                _createElementVNode("img", {
                  src: _ctx.restaurant.logo,
                  alt: "",
                  class: "vendor-logo extra"
                }, null, 8, _hoisted_7),
                (!_ctx.isInCheckout)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("button", {
                        class: "restaurant-header-button",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showRestaurantProfile && _ctx.showRestaurantProfile(...args)))
                      }, "Restaurant profile"),
                      _createElementVNode("button", {
                        class: "restaurant-header-button",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showOpeningHours && _ctx.showOpeningHours(...args)))
                      }, "Opening hours")
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("h2", null, _toDisplayString(_ctx.restaurant.name), 1),
                (!_ctx.isInCheckout)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.truncate(_ctx.restaurant.description)), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_11, [
                  (!(_ctx.groupData && !_ctx.isInCheckout) && !_ctx.amendableData)
                    ? (_openBlock(), _createBlock(_component_OrderSettingsButton, {
                        key: 0,
                        disabled: _ctx.isInCheckout || (!_ctx.restaurant.allowScheduledOrders && !_ctx.restaurant.clickAndCollect),
                        restaurant: _ctx.restaurant,
                        basketTotal: +_ctx.totalPrice
                      }, null, 8, ["disabled", "restaurant", "basketTotal"]))
                    : _createCommentVNode("", true),
                  (
              _ctx.cityName &&
              !_ctx.groupData &&
              !_ctx.amendableData &&
              !_ctx.restaurant?.isTemporarilyClose &&
              !_ctx.restaurant?.isClosed &&
              !_ctx.isInCheckout
            )
                    ? (_openBlock(), _createBlock(_component_CreateGroupOrderButton, {
                        key: 1,
                        restaurant: _ctx.restaurant,
                        wholeMenuMinHoursInAdvance: _ctx.wholeMenuMinHoursInAdvance
                      }, null, 8, ["restaurant", "wholeMenuMinHoursInAdvance"]))
                    : _createCommentVNode("", true),
                  (_ctx.amendableData && _ctx.cityName && _ctx.$route.params.slug === _ctx.amendableData.restaurantSlug)
                    ? (_openBlock(), _createBlock(_component_AmendableOrderInfo, {
                        key: 2,
                        class: "restaurant-header-group-order-info",
                        restaurant: _ctx.restaurant,
                        isMobile: true
                      }, null, 8, ["restaurant"]))
                    : _createCommentVNode("", true)
                ])
              ], 2)
            ], 64))
      ]))
    : _createCommentVNode("", true)
}