/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state: StateModals = {
  data: null,
  modals: [],
};

const mutations = {
  show(state: StateModals, payload: string) {
    state[payload] = true;
    state.modals.push(payload);
  },
  close(state: StateModals) {
    const length = state.modals.length - 1;

    if (length >= 0) {
      const last: string = state.modals.pop() as string;
      delete state[last];
    }
  },
  data(state: StateModals, payload: any) {
    state.data = payload;
  },
  put(state: StateModals, payload: any) {
    state.data = { ...state.data, ...payload };
  },
  reset(state: StateModals) {
    state.data = null;
    Object.keys(state).forEach((i: string) => {
      if (i !== 'data' && i !== 'modals' && i !== 'activeOrderWarning' && i !== 'groupOrderNotAvailable') {
        delete state[i];
      }
    });
  },
};

const actions = {
  //
};

const getters = {
  //
};

export interface StateModals {
  [x: string]: any;
  data: any;
  modals: string[];
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
