<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 4.43965C7 5.48796 7.64251 6.36718 8.55555 6.70535V2.17395C7.64251 2.51211 7 3.39134 7 4.43965Z"
      fill="#006AC9"
    />
    <path
      d="M10.2803 2.17395V6.70535C11.1933 6.36718 11.8358 5.45414 11.8358 4.43965C11.8358 3.39134 11.1933 2.51211 10.2803 2.17395Z"
      fill="#004A97"
    />
    <path d="M10.2803 6.7054C11.1933 6.36723 11.8358 5.45419 11.8358 4.4397Z" fill="#023563" />
    <path
      d="M11.3964 0.24646H9.43506C7.10173 0.24646 5.20801 1.9711 5.20801 4.47352C5.20801 6.77304 7.10173 8.63294 9.43506 8.63294H11.3964C13.6959 8.63294 15.7925 6.77304 15.7925 4.47352C15.7587 1.9711 13.6621 0.24646 11.3964 0.24646ZM9.43506 8.26096C7.30462 8.26096 5.6138 6.53632 5.6138 4.4397C5.6138 2.30926 7.33844 0.584625 9.43506 0.584625C11.5655 0.584625 13.2901 2.30926 13.2901 4.4397C13.2563 6.57014 11.5317 8.26096 9.43506 8.26096Z"
      fill="#023563"
    />
    <path
      d="M0.879227 11.5072C0.879227 11.8453 1.11594 11.8792 1.35266 11.8792C2.29952 11.8792 2.63768 11.169 2.63768 10.4927C2.63768 9.64728 2.09662 9.0724 1.25121 9.0724C1.08213 9.0724 0.980676 9.0724 0.913044 9.10622V11.5072H0.879227ZM0.439614 9.41056C0.439614 9.00477 0.236715 9.03858 0.0338164 9.03858V8.93713C0.202899 8.93713 0.371981 8.93713 0.541063 8.93713C0.743961 8.93713 0.980676 8.93713 1.31884 8.93713C2.4686 8.93713 3.07729 9.71491 3.07729 10.4927C3.07729 10.9323 2.80676 12.0482 1.25121 12.0482C1.01449 12.0482 0.811594 12.0482 0.608696 12.0482C0.405797 12.0482 0.202899 12.0482 0 12.0482V11.9468C0.270531 11.913 0.405797 11.913 0.405797 11.6086V9.41056H0.439614V9.41056Z"
      fill="#004A97"
    />
    <path
      d="M3.7537 9.30912C3.65225 9.30912 3.55081 9.20767 3.55081 9.07241C3.55081 8.97096 3.65225 8.86951 3.7537 8.86951C3.85515 8.86951 3.9566 8.97096 3.9566 9.07241C3.9566 9.20767 3.85515 9.30912 3.7537 9.30912ZM3.28027 11.913H3.38172C3.51699 11.913 3.58462 11.913 3.58462 11.7777V10.5603C3.58462 10.3574 3.51699 10.3236 3.34791 10.256V10.1883C3.55081 10.1207 3.78752 10.0531 3.82134 10.0193C3.85515 9.98545 3.88897 9.98545 3.88897 9.98545C3.92279 9.98545 3.92279 10.0193 3.92279 10.0531V11.7439C3.92279 11.8792 4.02424 11.8792 4.1595 11.8792H4.22713V11.9806C4.09187 11.9806 3.92279 11.9806 3.7537 11.9806C3.58462 11.9806 3.44936 11.9806 3.28027 11.9806C3.28027 11.9806 3.28027 11.913 3.28027 11.913Z"
      fill="#004A97"
    />
    <path
      d="M4.63247 10.594C4.63247 10.425 4.59866 10.3911 4.36194 10.2897V10.2221C4.56484 10.1544 4.73392 10.0868 4.93682 10.0192C4.93682 10.0192 4.97064 10.0192 4.97064 10.053V10.3573C5.20735 10.1882 5.44407 10.0192 5.74842 10.0192C6.1204 10.0192 6.25567 10.2897 6.25567 10.6279V11.7776C6.25567 11.9129 6.35712 11.9129 6.49238 11.9129H6.56001V12.0143C6.39093 12.0143 6.25567 12.0143 6.08658 12.0143C5.9175 12.0143 5.78223 12.0143 5.61315 12.0143V11.9129H5.68078C5.81605 11.9129 5.88368 11.9129 5.88368 11.7776V10.6279C5.88368 10.3911 5.7146 10.2559 5.47789 10.2559C5.34262 10.2559 5.1059 10.3573 4.97064 10.4588V11.7776C4.97064 11.9129 5.07209 11.9129 5.20735 11.9129H5.27499V12.0143C5.1059 12.0143 4.97064 12.0143 4.80156 12.0143C4.63247 12.0143 4.49721 12.0143 4.32812 12.0143V11.9129H4.42957C4.56484 11.9129 4.63247 11.9129 4.63247 11.7776V10.594Z"
      fill="#004A97"
    />
    <path
      d="M7.67649 10.6617C7.77794 10.6617 7.81175 10.594 7.81175 10.5602C7.81175 10.3235 7.67649 10.1544 7.43977 10.1544C7.16924 10.1544 6.96634 10.3573 6.93253 10.6617H7.67649ZM6.89871 10.7969C6.89871 10.8308 6.89871 10.8984 6.89871 11.0675C6.93253 11.4733 7.20306 11.8453 7.54122 11.8453C7.77794 11.8453 7.98084 11.71 8.14992 11.5409L8.21755 11.6085C8.01465 11.8791 7.74412 12.1158 7.40596 12.1158C6.69581 12.1158 6.56055 11.4395 6.56055 11.1351C6.56055 10.2559 7.13543 10.0192 7.43977 10.0192C7.81175 10.0192 8.18374 10.2559 8.18374 10.7293C8.18374 10.7631 8.18374 10.7969 8.18374 10.7969H8.14992H6.89871Z"
      fill="#004A97"
    />
    <path
      d="M8.28541 11.9129H8.42067C8.55594 11.9129 8.62357 11.9129 8.62357 11.7776V10.5264C8.62357 10.3911 8.45449 10.3573 8.38686 10.3235V10.2559C8.72502 10.1206 8.8941 10.0192 8.92792 10.0192C8.96173 10.0192 8.96173 10.0192 8.96173 10.0868V10.4926C9.06318 10.3235 9.26608 10.053 9.53661 10.053C9.63806 10.053 9.77333 10.1206 9.77333 10.2897C9.77333 10.425 9.70569 10.5264 9.57043 10.5264C9.43516 10.5264 9.43516 10.425 9.26608 10.425C9.19845 10.425 8.92792 10.5264 8.92792 10.7969V11.8453C8.92792 11.9805 9.02937 11.9805 9.13082 11.9805H9.36753V12.082C9.13082 12.082 8.92792 12.082 8.75884 12.082C8.58975 12.082 8.38686 12.082 8.21777 12.082V11.9129H8.28541Z"
      fill="#004A97"
    />
    <path
      d="M10.01 11.4057C10.0776 11.7101 10.2467 11.9468 10.5849 11.9468C10.8554 11.9468 10.9569 11.7777 10.9569 11.6424C10.9569 11.1352 9.9762 11.3043 9.9762 10.5603C9.9762 10.3236 10.1791 9.98541 10.6525 9.98541C10.7878 9.98541 10.9907 10.0192 11.1598 10.1207L11.1936 10.5603H11.0921C11.0583 10.2898 10.8892 10.1207 10.6187 10.1207C10.4496 10.1207 10.2805 10.2221 10.2805 10.425C10.2805 10.9323 11.295 10.797 11.295 11.4733C11.295 11.7777 11.0583 12.082 10.5173 12.082C10.3482 12.082 10.1453 12.0144 9.9762 11.9468L9.94238 11.4395L10.01 11.4057Z"
      fill="#004A97"
    />
    <path
      d="M15.2847 9.71492H15.1832C15.1156 9.20767 14.7436 9.00477 14.2364 9.00477C13.7291 9.00477 12.9851 9.34294 12.9851 10.4251C12.9851 11.3043 13.6277 11.9468 14.304 11.9468C14.7436 11.9468 15.1156 11.6425 15.1832 11.169L15.2847 11.2028L15.1832 11.8454C14.9803 11.9468 14.5069 12.0821 14.2025 12.0821C13.1542 12.0821 12.5117 11.4057 12.5117 10.4251C12.5117 9.51202 13.3233 8.86951 14.2025 8.86951C14.5745 8.86951 14.9127 8.97096 15.2508 9.10622L15.2847 9.71492Z"
      fill="#004A97"
    />
    <path
      d="M15.4541 11.913H15.5217C15.657 11.913 15.7246 11.913 15.7246 11.7777V9.30914C15.7246 9.00479 15.657 9.00479 15.4879 8.97097V8.90334C15.657 8.83571 15.8599 8.76807 15.9613 8.70044C15.9952 8.66663 16.029 8.66663 16.0628 8.66663C16.0966 8.66663 16.0966 8.70044 16.0966 8.73426V11.8116C16.0966 11.9468 16.1981 11.9468 16.3333 11.9468H16.401V12.0483C16.2657 12.0483 16.0966 12.0483 15.9275 12.0483C15.7584 12.0483 15.6232 12.0483 15.4541 12.0483V11.913Z"
      fill="#004A97"
    />
    <path
      d="M18.2951 11.7779C18.2951 11.8455 18.3289 11.8455 18.4304 11.8455C18.498 11.8455 18.5656 11.8455 18.5994 11.8455V11.9469C18.3965 11.9808 18.0584 12.0484 17.9569 12.0822H17.9231V11.7102C17.6526 11.9131 17.4497 12.0822 17.1453 12.0822C16.9086 12.0822 16.6719 11.9131 16.6719 11.5411V10.3914C16.6719 10.2899 16.6381 10.1547 16.4014 10.1547V10.087C16.5704 10.087 16.9086 10.0532 16.9762 10.0532C17.0101 10.0532 17.0101 10.087 17.0101 10.1885V11.3382C17.0101 11.4735 17.0101 11.8455 17.382 11.8455C17.5173 11.8455 17.7202 11.744 17.9231 11.575V10.3914C17.9231 10.2899 17.7202 10.2561 17.5511 10.2223V10.1547C17.9569 10.1209 18.1936 10.087 18.2275 10.087C18.2613 10.087 18.2613 10.1209 18.2613 10.1547V11.7779H18.2951V11.7779Z"
      fill="#004A97"
    />
    <path
      d="M19.1735 11.5072C19.1735 11.6763 19.3426 11.9468 19.6469 11.9468C20.1204 11.9468 20.3233 11.4734 20.3233 11.1014C20.3233 10.628 19.9513 10.2222 19.6131 10.2222C19.444 10.2222 19.3088 10.3236 19.2073 10.4251V11.5072H19.1735ZM19.1735 10.3236C19.3426 10.1884 19.5793 10.0193 19.8498 10.0193C20.3571 10.0193 20.6614 10.4589 20.6614 10.9661C20.6614 11.541 20.2218 12.1159 19.6131 12.1159C19.275 12.1159 19.1059 12.0144 19.0044 11.9468L18.8692 12.0483L18.7677 12.0145C18.8015 11.7439 18.8353 11.5072 18.8353 11.2367V9.34295C18.8353 9.0386 18.7677 9.0386 18.5986 9.00479V8.93715C18.7677 8.86952 18.9706 8.80189 19.0721 8.73426C19.1059 8.70044 19.1397 8.70044 19.1735 8.70044C19.2073 8.70044 19.2073 8.73426 19.2073 8.76807V10.3236H19.1735Z"
      fill="#004A97"
    />
    <path
      d="M0.0341797 14.5844H0.0679961C0.169445 14.5844 0.237078 14.5844 0.237078 14.4492V13.198C0.237078 13.0627 0.135629 13.0627 0.0679961 13.0627H0.0341797V12.9951C0.135629 12.9951 0.270895 12.9951 0.40616 12.9951C0.541426 12.9951 0.676692 12.9951 0.778141 12.9951V13.0627H0.744325C0.642875 13.0627 0.575242 13.0627 0.575242 13.198V14.4492C0.575242 14.5844 0.642875 14.5844 0.744325 14.5844H0.778141V14.6521C0.676692 14.6521 0.50761 14.6521 0.40616 14.6521C0.304711 14.6521 0.135629 14.6521 0.0679961 14.6521V14.5844H0.0341797Z"
      fill="#006AC9"
    />
    <path
      d="M0.778141 14.6521V14.5844H0.744325C0.642875 14.5844 0.541426 14.5844 0.541426 14.4492V13.198C0.541426 13.0627 0.642875 13.0627 0.744325 13.0627H0.778141V12.9951C0.676692 12.9951 0.541426 12.9951 0.40616 12.9951C0.304711 12.9951 0.169445 12.9951 0.0679961 12.9951V13.0627H0.101813C0.203262 13.0627 0.304711 13.0627 0.304711 13.198V14.4492C0.304711 14.5844 0.203262 14.5844 0.101813 14.5844H0.0679961V14.6521C0.169445 14.6521 0.304711 14.6521 0.40616 14.6521C0.50761 14.6521 0.642875 14.6521 0.778141 14.6521ZM0.778141 14.6859C0.676692 14.6859 0.50761 14.6859 0.40616 14.6859C0.304711 14.6859 0.135629 14.6859 0.0679961 14.6859V14.5844H0.101813C0.203262 14.5844 0.270895 14.5844 0.270895 14.4492V13.198C0.270895 13.0627 0.203262 13.0627 0.101813 13.0627H0.0341797V12.9951C0.135629 12.9951 0.270895 12.9951 0.40616 12.9951C0.541426 12.9951 0.676692 12.9951 0.778141 12.9951V13.0627H0.744325C0.642875 13.0627 0.575242 13.0627 0.575242 13.198V14.4492C0.575242 14.5844 0.642875 14.5844 0.744325 14.5844H0.778141V14.6859V14.6859Z"
      fill="#006AC9"
    />
    <path
      d="M2.43508 14.1786V13.2656C2.43508 13.0627 2.29981 13.0289 2.23218 13.0289H2.16454V12.9612C2.26599 12.9612 2.40126 12.9612 2.50271 12.9612C2.60416 12.9612 2.70561 12.9612 2.80706 12.9612V13.0289H2.77324C2.67179 13.0289 2.57034 13.0627 2.57034 13.3332V14.4153C2.57034 14.483 2.57034 14.5844 2.57034 14.6521H2.46889L1.21768 13.2656V14.2463C1.21768 14.4492 1.2515 14.5168 1.4544 14.5168H1.48822V14.5844C1.38677 14.5844 1.28532 14.5844 1.18387 14.5844C1.08242 14.5844 0.980969 14.5844 0.845703 14.5844H0.87952C1.0486 14.5844 1.08242 14.483 1.08242 14.2801V13.2656C1.08242 13.1303 0.980969 13.0627 0.87952 13.0627V12.9951C0.980969 12.9951 1.08242 12.9951 1.15005 12.9951C1.21768 12.9951 1.28532 12.9951 1.35295 12.9951L2.43508 14.1786Z"
      fill="#006AC9"
    />
    <path
      d="M2.46889 14.6859H2.53652C2.53652 14.6182 2.53652 14.5168 2.53652 14.4492V13.367C2.53652 13.0627 2.67179 13.0627 2.77324 13.0627H2.80706V12.9951C2.70561 12.9951 2.60416 12.9951 2.53652 12.9951C2.43508 12.9951 2.33363 12.9951 2.19836 12.9951V13.0627H2.23218C2.29981 13.0627 2.46889 13.0965 2.46889 13.2994V14.2124L1.38677 12.9951C1.31913 12.9951 1.2515 12.9951 1.18387 12.9951C1.08242 12.9951 1.01479 12.9951 0.913336 12.9951V13.0627H0.947152C1.0486 13.0627 1.18387 13.1641 1.18387 13.2994V14.3139C1.18387 14.5168 1.11623 14.6182 0.947152 14.6182H0.913336V14.6859C1.01479 14.6859 1.11623 14.6859 1.21768 14.6859C1.31913 14.6859 1.42058 14.6859 1.52203 14.6859V14.6182H1.4544C1.2515 14.6182 1.21768 14.5506 1.21768 14.3139V13.2994L2.46889 14.6859ZM2.57034 14.7197H2.46889L1.2515 13.367V14.3477C1.2515 14.5506 1.28532 14.6182 1.4544 14.6182H1.52203V14.6859C1.42058 14.6859 1.31913 14.6859 1.21768 14.6859C1.11623 14.6859 1.01479 14.6859 0.87952 14.6859V14.5844H0.913336C1.08242 14.5844 1.11623 14.483 1.11623 14.2801V13.2656C1.11623 13.1303 1.01479 13.0627 0.913336 13.0627H0.845703V12.9951C0.947152 12.9951 1.0486 12.9951 1.11623 12.9951C1.18387 12.9951 1.2515 12.9951 1.35295 12.9951L2.40126 14.1786V13.2994C2.40126 13.0965 2.26599 13.0965 2.19836 13.0965H2.16454V13.0289C2.26599 13.0289 2.40126 13.0289 2.50271 13.0289C2.60416 13.0289 2.70561 13.0289 2.80706 13.0289V13.0965H2.77324C2.67179 13.0965 2.57034 13.0965 2.57034 13.4009V14.483C2.57034 14.5506 2.57034 14.6182 2.57034 14.7197Z"
      fill="#006AC9"
    />
    <path
      d="M3.17935 13.0965C3.01027 13.0965 2.97645 13.1303 2.94263 13.2994H2.875C2.875 13.2318 2.90882 13.1641 2.90882 13.0965C2.90882 13.0289 2.90882 12.9612 2.90882 12.8936H2.97645C3.01027 12.9612 3.04408 12.9612 3.11171 12.9612H4.36292C4.43056 12.9612 4.49819 12.9612 4.49819 12.8936H4.56582C4.56582 12.9612 4.532 13.0289 4.532 13.0965C4.532 13.1641 4.532 13.2318 4.532 13.2994L4.46437 13.3332C4.46437 13.2318 4.46437 13.0965 4.29529 13.0965H3.88949V14.3815C3.88949 14.5506 3.95713 14.5844 4.09239 14.5844H4.12621V14.6521C4.02476 14.6521 3.85568 14.6521 3.72041 14.6521C3.58514 14.6521 3.41606 14.6521 3.31461 14.6521V14.5844H3.34843C3.4837 14.5844 3.55133 14.5844 3.55133 14.3815V13.0965H3.17935Z"
      fill="#006AC9"
    />
    <path
      d="M4.12621 14.6859C4.02476 14.6859 3.85568 14.6859 3.72041 14.6859C3.58514 14.6859 3.41606 14.6859 3.31461 14.6859V14.5844H3.34843C3.4837 14.5844 3.55133 14.5844 3.55133 14.3815V13.0965H3.17935H3.58515V14.3815C3.58515 14.5844 3.51751 14.5844 3.38225 14.5844H3.34843V14.6521C3.44988 14.6521 3.61896 14.6521 3.75423 14.6521C3.85568 14.6521 4.02476 14.6521 4.12621 14.6859V14.6182H4.09239C3.99094 14.6182 3.88949 14.5844 3.88949 14.4153V13.1303H4.29529C4.46437 13.1303 4.46437 13.2656 4.49819 13.367L4.56582 13.3332C4.56582 13.2656 4.56582 13.198 4.56582 13.1303C4.56582 13.0627 4.56582 12.9951 4.59964 12.9612H4.56582C4.56582 13.0289 4.49819 13.0289 4.43056 13.0289H3.17935C3.11171 13.0289 3.04408 13.0289 3.04408 12.9612H2.94263C2.94263 13.0289 2.94263 13.0965 2.94263 13.1641C2.94263 13.2318 2.90882 13.2994 2.90882 13.367H2.97645C3.01027 13.198 3.04408 13.1303 3.21316 13.1641C3.04408 13.1641 3.04408 13.198 3.01027 13.367H2.875C2.875 13.2994 2.90882 13.2318 2.90882 13.1641C2.90882 13.0965 2.90882 13.0289 2.90882 12.9612H2.97645C2.97645 13.0289 3.04408 13.0289 3.11171 13.0289H4.36292C4.43056 13.0289 4.46437 13.0289 4.49819 12.9612H4.56582C4.56582 13.0289 4.532 13.0965 4.532 13.1641C4.532 13.2318 4.532 13.2994 4.532 13.367H4.43056C4.43056 13.2656 4.43056 13.1303 4.26147 13.1303H3.88949V14.4153C3.88949 14.5844 3.95713 14.6182 4.09239 14.6182H4.16002L4.12621 14.6859Z"
      fill="#006AC9"
    />
    <path
      d="M4.63281 14.5844H4.66663C4.76808 14.5844 4.83571 14.5844 4.83571 14.4492V13.198C4.83571 13.0627 4.73426 13.0627 4.66663 13.0627H4.63281V12.9951C4.76808 12.9951 5.03861 12.9951 5.24151 12.9951C5.4444 12.9951 5.68112 12.9951 5.8502 12.9951C5.8502 13.0965 5.8502 13.2656 5.8502 13.367H5.78257C5.78257 13.198 5.74875 13.0965 5.47822 13.0965H5.14006V13.739H5.4444C5.57967 13.739 5.61349 13.6714 5.6473 13.5361H5.71494C5.71494 13.6376 5.71494 13.739 5.71494 13.8067C5.71494 13.9081 5.71494 13.9757 5.71494 14.0772H5.6473C5.6473 13.9419 5.61349 13.8405 5.4444 13.8405H5.14006V14.4153C5.14006 14.5844 5.27532 14.5844 5.4444 14.5844C5.74875 14.5844 5.8502 14.5506 5.95165 14.2801H6.01928C5.98547 14.4153 5.95165 14.5506 5.95165 14.6521C5.78257 14.6521 5.51204 14.6521 5.30914 14.6521C5.10624 14.6521 4.80189 14.6521 4.66663 14.6521V14.5844H4.63281Z"
      fill="#006AC9"
    />
    <path
      d="M5.91783 14.6521C5.95165 14.5168 5.95165 14.4154 5.98547 14.2801H5.95165C5.88402 14.5506 5.74875 14.5844 5.4444 14.5844C5.30914 14.5844 5.14006 14.5844 5.14006 14.4154V13.8405H5.4444C5.61349 13.8405 5.61349 13.9419 5.6473 14.0772H5.71494C5.71494 13.9757 5.71494 13.9081 5.71494 13.8067C5.71494 13.7052 5.71494 13.6376 5.71494 13.5361H5.6473C5.6473 13.6714 5.57967 13.739 5.4444 13.739H5.14006V13.0965H5.47822C5.71494 13.0965 5.78257 13.2318 5.78257 13.367H5.8502C5.8502 13.2994 5.8502 13.198 5.8502 13.1303C5.8502 13.0965 5.8502 13.0627 5.8502 13.0289C5.68112 13.0289 5.4444 13.0289 5.24151 13.0289C5.03861 13.0289 4.80189 13.0289 4.66663 13.0289V13.0965H4.70045C4.80189 13.0965 4.90334 13.0965 4.90334 13.2318V14.483C4.90334 14.6183 4.80189 14.6182 4.70045 14.6182H4.66663V14.6859C4.80189 14.6859 5.07242 14.6859 5.30914 14.6859C5.47822 14.6521 5.74875 14.6521 5.91783 14.6521ZM5.91783 14.6859C5.74875 14.6859 5.47822 14.6859 5.27532 14.6859C5.07243 14.6859 4.76808 14.6859 4.63281 14.6859V14.5844H4.66663C4.76808 14.5844 4.83571 14.5844 4.83571 14.4492V13.198C4.83571 13.0965 4.76808 13.0627 4.66663 13.0627H4.63281V12.9951C4.76808 12.9951 5.03861 12.9951 5.24151 12.9951C5.4444 12.9951 5.68112 12.9951 5.8502 12.9951C5.8502 13.0289 5.8502 13.0627 5.8502 13.1303C5.8502 13.198 5.8502 13.2994 5.8502 13.367L5.78257 13.4009C5.78257 13.2318 5.74875 13.1303 5.51204 13.1303H5.17387V13.739H5.4444C5.57967 13.739 5.61349 13.6714 5.61349 13.5361H5.68112C5.68112 13.6376 5.68112 13.739 5.68112 13.8067C5.68112 13.9081 5.68112 13.9757 5.68112 14.0772H5.61349C5.61349 13.9419 5.57967 13.8405 5.4444 13.8405H5.17387V14.3815C5.17387 14.5168 5.30914 14.5168 5.4444 14.5168C5.74875 14.5168 5.8502 14.5168 5.91783 14.2463L5.98547 14.2801C5.98547 14.4154 5.95165 14.5506 5.91783 14.6859Z"
      fill="#006AC9"
    />
    <path
      d="M6.62798 13.8067H6.72943C6.96614 13.8067 7.10141 13.7052 7.10141 13.4347C7.10141 13.2318 6.96614 13.0965 6.76324 13.0965C6.69561 13.0965 6.66179 13.0965 6.62798 13.0965V13.8067V13.8067ZM6.32363 13.2318C6.32363 13.0627 6.22218 13.0627 6.15455 13.0627H6.12073V12.9951C6.18836 12.9951 6.35745 12.9951 6.49271 12.9951C6.62798 12.9951 6.76324 12.9951 6.86469 12.9951C7.16904 12.9951 7.43957 13.0627 7.43957 13.4347C7.43957 13.6376 7.30431 13.7728 7.10141 13.8743L7.5072 14.483C7.57484 14.5844 7.60865 14.6182 7.74392 14.6182V14.6859C7.67629 14.6859 7.57484 14.6859 7.5072 14.6859C7.43957 14.6859 7.33812 14.6859 7.27049 14.6859C7.10141 14.4492 6.93232 14.1786 6.76324 13.9081H6.59416V14.4154C6.59416 14.5844 6.69561 14.6182 6.79706 14.6182H6.83088V14.6859C6.69561 14.6859 6.56034 14.6859 6.42508 14.6859C6.32363 14.6859 6.18836 14.6859 6.08691 14.6859V14.5844H6.12073C6.22218 14.5844 6.28981 14.5506 6.28981 14.4492V13.2318H6.32363Z"
      fill="#006AC9"
    />
    <path
      d="M6.76361 13.8067C7.00032 13.8067 7.13559 13.739 7.13559 13.4347C7.13559 13.2318 7.00032 13.0965 6.79742 13.0965C6.72979 13.0965 6.69597 13.0965 6.66216 13.0965V13.8067H6.76361ZM6.62834 13.8067V13.0627C6.66216 13.0627 6.69597 13.0627 6.76361 13.0627C6.9665 13.0627 7.13559 13.198 7.13559 13.4347C7.13559 13.739 7.00032 13.8067 6.76361 13.8067H6.62834ZM6.62834 13.8743H6.79742C6.9665 14.1448 7.10177 14.4154 7.30467 14.6521C7.3723 14.6521 7.43993 14.6521 7.54138 14.6521C7.60902 14.6521 7.71047 14.6521 7.7781 14.6521V14.5844C7.67665 14.5844 7.60902 14.5506 7.54138 14.4492L7.13559 13.8067C7.33849 13.739 7.47375 13.6038 7.47375 13.4009C7.47375 13.0627 7.20322 12.9951 6.89887 12.9951C6.76361 12.9951 6.66216 12.9951 6.52689 12.9951C6.39162 12.9951 6.25636 12.9951 6.15491 12.9951V13.0627H6.18873C6.25636 13.0627 6.35781 13.0627 6.35781 13.2656V14.483C6.35781 14.5844 6.25636 14.6182 6.18873 14.6182H6.12109V14.6859C6.22254 14.6859 6.35781 14.6859 6.45926 14.6859C6.59452 14.6859 6.72979 14.6859 6.86506 14.6859V14.6182H6.83124C6.72979 14.6182 6.62834 14.6182 6.62834 14.4154V13.8743ZM7.7781 14.6859C7.71047 14.6859 7.60902 14.6859 7.54138 14.6859C7.47375 14.6859 7.40612 14.6859 7.30467 14.6859C7.13559 14.4492 6.9665 14.1786 6.79742 13.9081H6.66216V14.4154C6.66216 14.5844 6.72979 14.5844 6.86506 14.5844H6.93269V14.6521C6.79742 14.6521 6.66216 14.6521 6.52689 14.6521C6.42544 14.6521 6.29018 14.6521 6.18873 14.6521V14.5844H6.22254C6.32399 14.5844 6.39163 14.5506 6.39163 14.4492V13.2318C6.39163 13.0627 6.29018 13.0627 6.22254 13.0627H6.12109V12.9951C6.18873 12.9951 6.35781 12.9951 6.49307 12.9951C6.62834 12.9951 6.76361 12.9951 6.86506 12.9951C7.1694 12.9951 7.43994 13.0965 7.43994 13.4347C7.43994 13.6376 7.30467 13.7728 7.10177 13.8743L7.50757 14.483C7.5752 14.5844 7.60902 14.6182 7.74428 14.6182L7.7781 14.6859Z"
      fill="#006AC9"
    />
    <path
      d="M9.4009 14.1786V13.2656C9.4009 13.0627 9.26563 13.0289 9.198 13.0289H9.13036V12.9612C9.23181 12.9612 9.36708 12.9612 9.46853 12.9612C9.56998 12.9612 9.67143 12.9612 9.77288 12.9612V13.0289H9.73906C9.63761 13.0289 9.53616 13.0627 9.53616 13.3332V14.4153C9.53616 14.483 9.53616 14.5844 9.53616 14.6521H9.43471L8.1835 13.2656V14.2463C8.1835 14.4492 8.21732 14.5168 8.42022 14.5168H8.48785V14.5844C8.3864 14.5844 8.28495 14.5844 8.1835 14.5844C8.08206 14.5844 7.98061 14.5844 7.84534 14.5844H7.87916C8.04824 14.5844 8.08205 14.483 8.08205 14.2801V13.2656C8.08205 13.1303 7.98061 13.0627 7.84534 13.0627H7.81152V12.9951C7.91297 12.9951 7.98061 12.9951 8.08205 12.9951C8.14969 12.9951 8.21732 12.9951 8.28495 12.9951L9.4009 14.1786Z"
      fill="#006AC9"
    />
    <path
      d="M9.43471 14.6859H9.50235C9.50235 14.6182 9.50235 14.5168 9.50235 14.4492V13.367C9.50235 13.0627 9.63761 13.0627 9.73906 13.0627H9.77288V12.9951C9.67143 12.9951 9.56998 12.9951 9.50235 12.9951C9.4009 12.9951 9.29945 12.9951 9.16418 12.9951V13.0627H9.198C9.26563 13.0627 9.43471 13.0965 9.43471 13.2994V14.2124L8.35259 12.9951C8.28495 12.9951 8.21732 12.9951 8.14969 12.9951C8.04824 12.9951 7.98061 12.9951 7.87916 12.9951V13.0627H7.91297C8.01442 13.0627 8.14969 13.1641 8.14969 13.2994V14.3139C8.14969 14.5168 8.08205 14.6182 7.91297 14.6182H7.87916V14.6859C7.98061 14.6859 8.08206 14.6859 8.1835 14.6859C8.28495 14.6859 8.3864 14.6859 8.48785 14.6859V14.6182H8.45404C8.25114 14.6182 8.21732 14.5506 8.21732 14.3139V13.2994L9.43471 14.6859ZM9.53616 14.7197H9.43471L8.21732 13.367V14.3477C8.21732 14.5506 8.25114 14.6182 8.42022 14.6182H8.48785V14.6859C8.3864 14.6859 8.28495 14.6859 8.1835 14.6859C8.08206 14.6859 7.98061 14.6859 7.84534 14.6859V14.5844H7.87916C8.04824 14.5844 8.08205 14.483 8.08205 14.2801V13.2656C8.08205 13.1303 7.98061 13.0627 7.87916 13.0627H7.81152V12.9951C7.91297 12.9951 8.01442 12.9951 8.08205 12.9951C8.14969 12.9951 8.21732 12.9951 8.31877 12.9951L9.36708 14.1786V13.2994C9.36708 13.0965 9.23181 13.0965 9.16418 13.0965H9.09655V13.0289C9.198 13.0289 9.33326 13.0289 9.43471 13.0289C9.53616 13.0289 9.63761 13.0289 9.73906 13.0289V13.0965H9.70524C9.60379 13.0965 9.50235 13.0965 9.50235 13.4009V14.483C9.53616 14.5506 9.53616 14.6182 9.53616 14.7197Z"
      fill="#006AC9"
    />
    <path
      d="M10.517 13.2656L10.2803 13.9419H10.7199L10.517 13.2656ZM10.1789 14.3139C10.1451 14.3815 10.1112 14.4492 10.1112 14.5168C10.1112 14.5844 10.2127 14.5844 10.2803 14.5844H10.3141V14.6521C10.2127 14.6521 10.1112 14.6521 10.0098 14.6521C9.90834 14.6521 9.84071 14.6521 9.73926 14.6521V14.5844C9.84071 14.5844 9.90834 14.5168 9.94216 14.4154L10.3141 13.2994C10.348 13.198 10.3818 13.0965 10.4156 12.9951C10.4832 12.9612 10.5847 12.9274 10.6523 12.8936C10.6523 12.8936 10.6523 12.8936 10.6861 12.8936C10.6861 12.9274 10.6861 12.9274 10.7199 12.9612L11.1934 14.2463C11.2272 14.3139 11.261 14.4154 11.2948 14.483C11.3286 14.5506 11.3624 14.5844 11.4639 14.5844V14.6521C11.3624 14.6521 11.261 14.6521 11.1257 14.6521C10.9905 14.6521 10.889 14.6521 10.7538 14.6521V14.5844H10.7876C10.8552 14.5844 10.9228 14.5844 10.9228 14.5168C10.9228 14.483 10.889 14.4154 10.889 14.3477L10.7876 14.0772H10.2465L10.1789 14.3139Z"
      fill="#006AC9"
    />
    <path
      d="M10.3141 13.9419H10.7538L10.517 13.2656L10.3141 13.9419ZM10.2803 13.9419L10.517 13.2318L10.7538 13.9419H10.2803ZM10.7538 14.6521C10.889 14.6521 10.9905 14.6521 11.0919 14.6521C11.1934 14.6521 11.2948 14.6521 11.4301 14.6521V14.5844C11.3624 14.5844 11.2948 14.5506 11.261 14.483C11.2272 14.4492 11.1934 14.3477 11.1934 14.2463L10.7538 12.9951C10.7538 12.9612 10.7538 12.9612 10.7199 12.9274C10.6861 12.9612 10.5847 12.9951 10.517 13.0289C10.517 13.1303 10.4494 13.2318 10.4156 13.3332L10.0436 14.4492C10.0098 14.5506 9.90834 14.6183 9.80689 14.6183V14.6859C9.87452 14.6859 9.97597 14.6859 10.0436 14.6859C10.1451 14.6859 10.2465 14.6859 10.3141 14.6859V14.6183C10.2465 14.6183 10.1112 14.6183 10.1112 14.5168C10.1112 14.483 10.1451 14.4154 10.1789 14.3139L10.2465 14.0434H10.8214L10.9228 14.3139C10.9566 14.3815 10.9567 14.4492 10.9567 14.483C10.9567 14.5506 10.8552 14.5506 10.7876 14.5506V14.6521H10.7538V14.6521ZM11.4639 14.6859C11.3624 14.6859 11.261 14.6859 11.1257 14.6859C10.9905 14.6859 10.889 14.6859 10.7538 14.6859V14.5844H10.7876C10.8552 14.5844 10.9228 14.5844 10.9228 14.5168C10.9228 14.483 10.889 14.4154 10.889 14.3477L10.7876 14.0772H10.2465L10.1789 14.3139C10.1451 14.3815 10.1112 14.4492 10.1112 14.483C10.1112 14.5506 10.2127 14.5506 10.2803 14.5506H10.3141V14.6183C10.2127 14.6183 10.1112 14.6183 10.0098 14.6183C9.90834 14.6183 9.84071 14.6183 9.73926 14.6183V14.5844C9.84071 14.5844 9.90834 14.5168 9.94216 14.4492L10.3141 13.3332C10.348 13.2318 10.3818 13.1303 10.4156 13.0289C10.4832 12.9951 10.5847 12.9612 10.6185 12.9274C10.6185 12.9274 10.6185 12.9274 10.6523 12.9274H10.6861C10.6861 12.9612 10.6861 12.9612 10.7199 12.9951L11.1934 14.2463C11.2272 14.3477 11.261 14.4154 11.2948 14.483C11.3286 14.5506 11.3624 14.5844 11.4301 14.5844H11.4639V14.6859Z"
      fill="#006AC9"
    />
    <path
      d="M11.5661 13.0965C11.397 13.0965 11.3632 13.1303 11.3294 13.2994H11.2617C11.2617 13.2318 11.2955 13.1641 11.2955 13.0965C11.2955 13.0289 11.2955 12.9612 11.2955 12.8936H11.3632C11.397 12.9612 11.4308 12.9612 11.4984 12.9612H12.7496C12.8173 12.9612 12.8849 12.9612 12.8849 12.8936H12.9525C12.9525 12.9612 12.9187 13.0289 12.9187 13.0965C12.9187 13.1641 12.9187 13.2318 12.9187 13.2994L12.8511 13.3332C12.8511 13.2318 12.8173 13.0965 12.682 13.0965H12.2762V14.3815C12.2762 14.5506 12.3438 14.5844 12.4791 14.5844H12.5129V14.6521C12.4115 14.6521 12.2424 14.6521 12.1071 14.6521C11.9719 14.6521 11.8028 14.6521 11.7013 14.6521V14.5844H11.7351C11.8704 14.5844 11.938 14.5844 11.938 14.3815V13.0965H11.5661Z"
      fill="#006AC9"
    />
    <path
      d="M12.5121 14.6859C12.4106 14.6859 12.2415 14.6859 12.1063 14.6859C11.971 14.6859 11.8019 14.6859 11.7005 14.6859V14.5844H11.7343C11.8696 14.5844 11.9034 14.5844 11.9372 14.3815V13.0965H11.5652H11.971V14.3815C11.971 14.5844 11.9034 14.5844 11.7681 14.5844H11.7343V14.6521C11.8357 14.6521 12.0048 14.6521 12.1401 14.6521C12.2415 14.6521 12.4106 14.6521 12.5121 14.6859V14.6182H12.4782C12.3768 14.6182 12.2753 14.5844 12.2753 14.4153V13.1303H12.6811C12.8502 13.1303 12.8502 13.2656 12.884 13.367L12.9517 13.3332C12.9517 13.2656 12.9517 13.198 12.9517 13.1303C12.9517 13.0627 12.9517 12.9951 12.9855 12.9612H12.9517C12.9517 13.0289 12.884 13.0289 12.8164 13.0289H11.5652C11.4976 13.0289 11.4638 13.0289 11.4299 12.9612H11.3961C11.3961 13.0289 11.3961 13.0965 11.3961 13.1641C11.3961 13.2318 11.3623 13.2994 11.3623 13.367H11.4299C11.4638 13.198 11.4976 13.1303 11.6667 13.1641C11.4976 13.1641 11.4976 13.198 11.4638 13.367H11.3623C11.3623 13.2994 11.3961 13.2318 11.3961 13.1641C11.3961 13.0965 11.3961 13.0289 11.3961 12.9612H11.4638C11.4638 13.0289 11.5314 13.0289 11.599 13.0289H12.8502C12.9179 13.0289 12.9517 13.0289 12.9855 12.9612H13.0531C13.0531 13.0289 13.0193 13.0965 13.0193 13.1641C13.0193 13.2318 13.0193 13.2994 13.0193 13.367L12.9179 13.4009C12.9179 13.2994 12.9179 13.1641 12.7488 13.1641H12.3768V14.4492C12.3768 14.6182 12.4444 14.6521 12.5797 14.6521H12.6473L12.5121 14.6859Z"
      fill="#006AC9"
    />
    <path
      d="M13.0195 14.5844C13.1548 14.5844 13.2224 14.5844 13.2224 14.4492V13.198C13.2224 13.0627 13.121 13.0627 13.0533 13.0627H13.0195V12.9951C13.121 12.9951 13.2562 12.9951 13.3915 12.9951C13.5268 12.9951 13.662 12.9951 13.7635 12.9951V13.0627H13.7297C13.6282 13.0627 13.5606 13.0627 13.5606 13.198V14.4492C13.5606 14.5844 13.662 14.5844 13.7297 14.5844H13.7635V14.6521C13.662 14.6521 13.493 14.6521 13.3915 14.6521C13.2901 14.6521 13.121 14.6521 13.0533 14.6521V14.5844H13.0195Z"
      fill="#006AC9"
    />
    <path
      d="M13.7639 14.6521V14.5844H13.73C13.6286 14.5844 13.5271 14.5844 13.5271 14.4492V13.198C13.5271 13.0627 13.6286 13.0627 13.73 13.0627H13.7639V12.9951C13.6624 12.9951 13.5271 12.9951 13.3919 12.9951C13.2904 12.9951 13.1552 12.9951 13.0537 12.9951V13.0627H13.0875C13.189 13.0627 13.2904 13.0627 13.2904 13.198V14.4492C13.2904 14.5844 13.189 14.5844 13.0875 14.5844H13.0537V14.6521C13.1552 14.6521 13.2904 14.6521 13.3919 14.6521C13.4933 14.6521 13.6286 14.6521 13.7639 14.6521ZM13.7639 14.6859C13.6624 14.6859 13.4933 14.6859 13.3919 14.6859C13.2904 14.6859 13.1213 14.6859 13.0537 14.6859V14.5844H13.0875C13.189 14.5844 13.2566 14.5844 13.2566 14.4492V13.198C13.2566 13.0965 13.189 13.0627 13.0875 13.0627H13.0537V12.9951C13.1552 12.9951 13.2904 12.9951 13.4257 12.9951C13.561 12.9951 13.6962 12.9951 13.7977 12.9951V13.0627H13.7639C13.6624 13.0627 13.5948 13.0627 13.5948 13.198V14.4492C13.5948 14.5506 13.6624 14.5844 13.7639 14.5844H13.7977L13.7639 14.6859Z"
      fill="#006AC9"
    />
    <path
      d="M14.7779 14.5844C15.2513 14.5844 15.319 14.1786 15.319 13.8067C15.319 13.4685 15.1161 13.0289 14.7441 13.0289C14.3045 13.0289 14.203 13.4009 14.203 13.739C14.1692 14.1786 14.3721 14.5844 14.7779 14.5844ZM14.7441 12.9612C15.2513 12.9612 15.6571 13.2656 15.6571 13.8067C15.6571 14.3477 15.2513 14.7197 14.7441 14.7197C14.2369 14.7197 13.8311 14.3815 13.8311 13.8405C13.8311 13.3332 14.2369 12.9612 14.7441 12.9612Z"
      fill="#006AC9"
    />
    <path
      d="M14.1692 13.739C14.1692 13.4009 14.3045 13.0289 14.7441 13.0289C15.1499 13.0289 15.3528 13.4685 15.3528 13.8067C15.3528 14.1448 15.2852 14.5844 14.7779 14.5844C15.2513 14.5844 15.319 14.1786 15.319 13.8405C15.319 13.5023 15.1161 13.0627 14.7441 13.0627C14.3045 13.0627 14.203 13.4347 14.1692 13.739C14.203 14.1786 14.3721 14.5844 14.7779 14.5844C14.3721 14.6183 14.1692 14.1786 14.1692 13.739ZM13.8311 13.8405C13.8311 13.3332 14.2369 12.9274 14.7441 12.9274C14.2369 12.9612 13.8649 13.3332 13.8311 13.8405C13.8649 14.3477 14.2369 14.6859 14.7441 14.6859C15.2513 14.6859 15.6571 14.3139 15.6571 13.7728C15.6571 13.2656 15.2513 12.9612 14.7441 12.9612C15.2513 12.9612 15.691 13.2994 15.691 13.8067C15.691 14.3815 15.2852 14.7535 14.7779 14.7535C14.2369 14.7197 13.8311 14.3815 13.8311 13.8405Z"
      fill="#006AC9"
    />
    <path
      d="M17.2808 14.1786V13.2656C17.2808 13.0627 17.1455 13.0289 17.0779 13.0289H17.0102V12.9612C17.1117 12.9612 17.247 12.9612 17.3484 12.9612C17.4499 12.9612 17.5513 12.9612 17.6528 12.9612V13.0289H17.6189C17.5175 13.0289 17.416 13.0627 17.416 13.3332V14.4153C17.416 14.483 17.416 14.5844 17.416 14.6521H17.3146L16.0634 13.2656V14.2463C16.0634 14.4492 16.0972 14.5168 16.3001 14.5168H16.3339V14.5844C16.2325 14.5844 16.131 14.5844 16.0296 14.5844C15.9281 14.5844 15.8267 14.5844 15.6914 14.5844H15.7252C15.8943 14.5844 15.9281 14.483 15.9281 14.2801V13.2656C15.9281 13.1303 15.8267 13.0627 15.7252 13.0627H15.6914V12.9951C15.7929 12.9951 15.8605 12.9951 15.9619 12.9951C16.0296 12.9951 16.0972 12.9951 16.1648 12.9951L17.2808 14.1786Z"
      fill="#006AC9"
    />
    <path
      d="M17.314 14.6859H17.3816C17.3816 14.6182 17.3816 14.5168 17.3816 14.4492V13.367C17.3816 13.0627 17.5169 13.0627 17.6183 13.0627H17.6521V12.9951C17.5507 12.9951 17.4492 12.9951 17.3816 12.9951C17.2802 12.9951 17.1787 12.9951 17.0435 12.9951V13.0627H17.0773C17.1449 13.0627 17.314 13.0965 17.314 13.2994V14.2124L16.2319 12.9951C16.1642 12.9951 16.0966 12.9951 16.029 12.9951C15.9275 12.9951 15.8599 12.9951 15.7584 12.9951V13.0627H15.7922C15.8937 13.0627 16.029 13.1641 16.029 13.2994V14.3139C16.029 14.5168 15.9613 14.6182 15.7922 14.6182H15.7584V14.6859C15.8599 14.6859 15.9613 14.6859 16.0628 14.6859C16.1642 14.6859 16.2657 14.6859 16.3671 14.6859V14.6182H16.3333C16.1304 14.6182 16.0966 14.5506 16.0966 14.3139V13.2994L17.314 14.6859ZM17.4154 14.7197H17.314L16.0966 13.367V14.3477C16.0966 14.5506 16.1304 14.6182 16.2995 14.6182H16.3671V14.6859C16.2657 14.6859 16.1642 14.6859 16.0628 14.6859C15.9613 14.6859 15.8599 14.6859 15.7246 14.6859V14.5844H15.7584C15.9275 14.5844 15.9613 14.483 15.9613 14.2801V13.2656C15.9613 13.1303 15.8599 13.0627 15.7584 13.0627H15.7246V12.9951C15.8261 12.9951 15.9275 12.9951 15.9951 12.9951C16.0628 12.9951 16.1304 12.9951 16.2319 12.9951L17.2802 14.1786V13.2994C17.2802 13.0965 17.1449 13.0965 17.0773 13.0965H17.0096V13.0289C17.1111 13.0289 17.2463 13.0289 17.3478 13.0289C17.4492 13.0289 17.5507 13.0289 17.6521 13.0289V13.0965H17.6183C17.5169 13.0965 17.4154 13.0965 17.4154 13.4009V14.483C17.4154 14.5506 17.4154 14.6182 17.4154 14.7197Z"
      fill="#006AC9"
    />
    <path
      d="M18.3969 13.2656L18.1602 13.9419H18.6336L18.3969 13.2656ZM18.0588 14.3139C18.0249 14.3815 17.9911 14.4492 17.9911 14.5168C17.9911 14.5844 18.0926 14.5844 18.1602 14.5844H18.194V14.6521C18.0926 14.6521 17.9911 14.6521 17.8897 14.6521C17.7882 14.6521 17.7206 14.6521 17.6191 14.6521V14.5844C17.7206 14.5844 17.7882 14.5168 17.822 14.4154L18.194 13.2994C18.2278 13.198 18.2617 13.0965 18.2955 12.9951C18.3631 12.9612 18.4645 12.9274 18.5322 12.8936C18.5322 12.8936 18.5322 12.8936 18.566 12.8936C18.566 12.9274 18.566 12.9274 18.5998 12.9612L19.0394 14.2125C19.0732 14.2801 19.1071 14.3815 19.1409 14.4492C19.1747 14.5844 19.2423 14.5844 19.31 14.5844V14.6521C19.2085 14.6521 19.1071 14.6521 18.9718 14.6521C18.8703 14.6521 18.7351 14.6521 18.5998 14.6521V14.5844H18.6336C18.7013 14.5844 18.7689 14.5844 18.7689 14.5168C18.7689 14.483 18.7351 14.4154 18.7351 14.3477L18.6336 14.0772H18.0926L18.0588 14.3139Z"
      fill="#006AC9"
    />
    <path
      d="M18.194 13.9419H18.5998L18.3969 13.2994L18.194 13.9419ZM18.1602 13.9419L18.3969 13.2318L18.6336 13.9419H18.1602ZM18.6336 14.6521C18.7689 14.6521 18.8703 14.6521 18.9718 14.6521C19.0732 14.6521 19.1747 14.6521 19.31 14.6521V14.5844C19.2423 14.5844 19.1747 14.5506 19.1409 14.483C19.1071 14.4154 19.0732 14.3139 19.0394 14.2463L18.5998 12.9951C18.5998 12.9612 18.5998 12.9612 18.566 12.9274C18.5322 12.9612 18.4307 12.9951 18.3631 13.0289C18.3631 13.1303 18.2955 13.2318 18.2617 13.3332L17.8897 14.4492C17.8559 14.5506 17.7544 14.6183 17.6868 14.6183V14.6859C17.7544 14.6859 17.8559 14.6859 17.9235 14.6859C18.0249 14.6859 18.1264 14.6859 18.194 14.6859V14.6183C18.1264 14.6183 17.9911 14.6183 17.9911 14.5168C17.9911 14.483 18.0249 14.4154 18.0588 14.3139L18.1264 14.0434H18.7013L18.8027 14.3139C18.8365 14.3815 18.8365 14.4492 18.8365 14.483C18.8365 14.5506 18.7351 14.5506 18.6674 14.5506V14.6521H18.6336V14.6521ZM19.3438 14.6859C19.2423 14.6859 19.1409 14.6859 19.0056 14.6859C18.9042 14.6859 18.7689 14.6859 18.6336 14.6859V14.5844H18.6674C18.7351 14.5844 18.8027 14.5844 18.8027 14.5168C18.8027 14.483 18.7689 14.4154 18.7689 14.3477L18.6674 14.0772H18.1264L18.0588 14.3139C18.0249 14.3815 17.9911 14.4492 17.9911 14.483C17.9911 14.5506 18.0926 14.5506 18.1602 14.5506H18.194V14.6183C18.0926 14.6183 17.9911 14.6183 17.8897 14.6183C17.7882 14.6183 17.7206 14.6183 17.6191 14.6183V14.5844C17.7206 14.5844 17.7882 14.5168 17.822 14.4492L18.194 13.3332C18.2278 13.2318 18.2617 13.1303 18.2955 13.0289C18.3631 12.9951 18.4645 12.9612 18.5322 12.9274C18.5322 12.9274 18.5322 12.9274 18.566 12.9274H18.5998C18.5998 12.9612 18.5998 12.9612 18.6336 12.9951L19.0732 14.2463C19.1071 14.3477 19.1409 14.4154 19.1747 14.483C19.2085 14.5506 19.2423 14.5844 19.31 14.5844H19.3438V14.6859Z"
      fill="#006AC9"
    />
    <path
      d="M19.9518 14.4492C19.9518 14.5506 20.0195 14.5844 20.0871 14.5844C20.1885 14.5844 20.29 14.5844 20.3914 14.5844C20.4929 14.5844 20.5943 14.5168 20.6282 14.4492C20.662 14.3815 20.6958 14.3139 20.6958 14.2801H20.7634C20.7296 14.4153 20.6958 14.5506 20.662 14.6859C20.4591 14.6859 20.2224 14.6859 20.0195 14.6859C19.8166 14.6859 19.5799 14.6859 19.377 14.6859V14.5844H19.4108C19.5122 14.5844 19.5799 14.5844 19.5799 14.4153V13.1641C19.5799 13.0289 19.4784 13.0289 19.4108 13.0289H19.377V12.9612C19.5122 12.9612 19.6137 12.9612 19.7489 12.9612C19.8842 12.9612 19.9856 12.9612 20.1209 12.9612V13.0289H20.0533C19.9518 13.0289 19.8842 13.0289 19.8842 13.1641V14.4492H19.9518V14.4492Z"
      fill="#006AC9"
    />
    <path
      d="M20.6623 14.6521C20.6962 14.5168 20.73 14.3815 20.7638 14.2801H20.73C20.73 14.3477 20.6962 14.3815 20.6623 14.4492C20.6285 14.5168 20.5271 14.5844 20.4256 14.5844C20.358 14.5844 20.2904 14.5844 20.2565 14.5844C20.2227 14.5844 20.1551 14.5844 20.1213 14.5844C20.0536 14.5844 19.9522 14.5506 19.9522 14.4492V13.198C19.9522 13.0627 20.0198 13.0627 20.1213 13.0627H20.1551V12.9951C20.0198 12.9951 19.9184 12.9951 19.7831 12.9951C19.6478 12.9951 19.5464 12.9951 19.4111 12.9951V13.0627H19.4449C19.5464 13.0627 19.6478 13.0627 19.6478 13.198V14.4492C19.6478 14.6182 19.5464 14.6182 19.4449 14.6182H19.4111V14.6859C19.614 14.6859 19.8169 14.6859 20.0536 14.6859C20.2565 14.6521 20.4594 14.6521 20.6623 14.6521ZM20.6962 14.6859C20.4933 14.6859 20.2565 14.6859 20.0536 14.6859C19.8507 14.6859 19.614 14.6859 19.4111 14.6859V14.5844H19.4449C19.5464 14.5844 19.614 14.5844 19.614 14.4492V13.198C19.614 13.0965 19.5464 13.0627 19.4449 13.0627H19.4111V12.9951C19.5464 12.9951 19.6817 12.9951 19.7831 12.9951C19.9184 12.9951 20.0198 12.9951 20.1551 12.9951V13.0627H20.0875C19.986 13.0627 19.9184 13.0627 19.9184 13.198V14.4492C19.9184 14.5506 19.986 14.5506 20.0536 14.5844C20.0875 14.5844 20.1551 14.5844 20.1889 14.5844C20.2565 14.5844 20.3242 14.5844 20.358 14.5844C20.4594 14.5844 20.5271 14.5168 20.5947 14.4492C20.6285 14.3815 20.6623 14.3477 20.6623 14.2801H20.73C20.7638 14.4154 20.73 14.5506 20.6962 14.6859Z"
      fill="#006AC9"
    />
    <path
      d="M20.6957 13.1641C20.7633 13.1641 20.7633 13.1303 20.7633 13.0965C20.7633 13.0627 20.7295 13.0289 20.7295 13.0289H20.6957V13.1641ZM20.6281 13.2994C20.6619 13.2994 20.6619 13.2994 20.6619 13.2656V13.0627C20.6619 13.0289 20.6619 13.0289 20.6281 13.0289H20.7633C20.7971 13.0289 20.8648 13.0627 20.8648 13.0965C20.8648 13.1303 20.831 13.1641 20.7971 13.1641L20.831 13.2318C20.8648 13.2656 20.8648 13.2994 20.8986 13.2994H20.8648C20.831 13.2994 20.831 13.2656 20.7633 13.198H20.7295V13.2656C20.7295 13.2994 20.7295 13.2656 20.7633 13.2994H20.6281ZM20.7295 13.367C20.831 13.367 20.9324 13.2656 20.9324 13.1641C20.9324 13.0627 20.831 12.9612 20.7295 12.9612C20.6281 12.9612 20.5266 13.0627 20.5266 13.1641C20.5604 13.2656 20.6281 13.367 20.7295 13.367ZM20.7295 12.9274C20.8648 12.9274 21 13.0289 21 13.198C21 13.367 20.8986 13.4347 20.7295 13.4347C20.5604 13.4347 20.459 13.3332 20.459 13.198C20.459 13.0627 20.5942 12.9274 20.7295 12.9274Z"
      fill="#006AC9"
    />
    <path
      d="M15.0148 3.79718C15.0148 2.71506 14.5752 1.73438 13.8312 0.956605C13.1211 0.516991 12.2756 0.24646 11.3964 0.24646H9.43506C7.10173 0.24646 5.20801 1.9711 5.20801 4.47351C5.20801 5.52182 5.61381 6.46868 6.25632 7.21264C6.93265 7.68607 7.74424 8.02424 8.62347 8.1595C6.89883 7.78752 5.61381 6.26578 5.61381 4.40588C5.61381 2.27545 7.33844 0.550808 9.43506 0.550808C11.5655 0.550808 13.2901 2.27545 13.2901 4.40588C13.2901 6.5025 11.6331 8.19332 9.53651 8.22714C9.60415 8.22714 9.63796 8.22714 9.7056 8.22714C12.6138 8.26095 15.0148 6.26578 15.0148 3.79718Z"
      fill="#004A97"
    />
    <path
      d="M9.40088 0.24646C7.06755 0.24646 5.17383 1.9711 5.17383 4.47352C5.17383 5.48801 5.54581 6.43487 6.18832 7.14502C6.22214 7.17883 6.25595 7.21265 6.28977 7.24646C6.9661 7.7199 7.74387 8.02424 8.6231 8.19333C6.89846 7.82134 5.61344 6.2996 5.61344 4.4397C5.61344 2.30926 7.33808 0.584624 9.43469 0.584624C11.5651 0.584624 13.2898 2.30926 13.2898 4.4397C13.2898 6.53632 11.6328 8.22714 9.53614 8.26096C9.60378 8.26096 9.63759 8.26096 9.70523 8.26096C9.87431 8.26096 10.0434 8.26096 10.2125 8.22714C12.4444 7.68608 13.8646 5.42038 13.4588 3.01941C13.2559 1.90347 12.7149 0.956605 11.9371 0.280276C11.768 0.24646 11.5989 0.24646 11.4299 0.24646H9.40088V0.24646Z"
      fill="#006AC9"
    />
  </svg>
  <!-- eslint-enable -->
</template>
