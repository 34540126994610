<template>
  <div class="oops-wrapper">
    <div class="empty" />
    <div class="oops-info">
      <p><b>ERROR 404</b></p>
      <h1>
        Dang. Something’s <br />
        gone very wrong...
      </h1>
      <div class="oops-gif">
        <img src="../assets/oops.gif" alt="" />
      </div>
    </div>
    <app-button @click.prevent="$router.push('/')"> Get me outta here </app-button>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class Oops extends Vue {}
</script>

<style lang="scss">
.oops {
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: 100vh;
    padding: 30px;
    background: $coal100;
    overflow: hidden;
    p {
      color: $ham;
      margin-bottom: 15px;
    }
    h1 {
      color: $vanilla;
      margin-bottom: 40px;
    }
  }
  &-gif {
    width: 346px;
    min-width: 346px;
    height: 239px;
    min-height: 239px;
    margin-bottom: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
