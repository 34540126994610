<template>
  <div class="checkout-wrapper" :class="{ animation: transition }">
    <div class="checkout-info-wrapper" :class="{ login: !user }">
      <checkout-info />
    </div>

    <Basket :restaurant="restaurantData.restaurant" checkout />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import Basket from '@/components/Basket.vue';
import CheckoutInfo from '@/components/Checkout/checkout-info.vue';

@Options({
  components: {
    CheckoutInfo,
    Basket,
  },
  props: {
    restaurantData: Object,
  },
  computed: {
    ...mapState('service', ['transition', 'user']),
  },
})
export default class CheckoutWrapper extends Vue {
  created() {
    const { restaurant } = this.$store.state.basket;
    const { cityName } = this.$store.state.address;
    const slugFromUrl = this.$route.params.slug as string;

    if (restaurant?.slug !== slugFromUrl) {
      this.$router.push(`/menu/${cityName.toLowerCase()}/${slugFromUrl}/checkout`);
    }

    if (!this.$store.state.service.user) {
      this.$router.push(`/menu/${cityName.toLowerCase()}/${slugFromUrl}`);
      this.$store.commit('modals/data', 'checkout'); // for change title
    }
  }
}
</script>

<style lang="scss" scoped>
.checkout {
  &-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    max-width: 1212px;
    &.animation {
      animation: swap 0.5s linear;
    }

    @include for-desktop {
      min-height: calc(100vh - 55px - 45px - 35px);
    }

    .restaurant-basket {
      @include for-smartphone {
        display: none;
      }
    }
  }

  &-info-wrapper {
    background-color: $white;
    width: 100%;
    border-radius: 15px;
    padding: 40px;
    border: 1px solid $coal20;
    margin-right: 20px;
    height: fit-content;
    @include for-smartphone {
      max-width: none;
      margin-right: 0;
      padding: 0;
      border: none;
      background-color: transparent;
    }
    &.login {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}

@keyframes swap {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
