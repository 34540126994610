import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox } from "vue"
import _imports_0 from '../assets/vendor/why_foodstuff.png'
import _imports_1 from '../assets/vendor/swipe_right.png'
import _imports_2 from '../assets/vendor/restaurants_logos.png'
import _imports_3 from '../assets/vendor/restaurants_logos_mobile.png'
import _imports_4 from '../assets/vendor/line.png'
import _imports_5 from '../assets/vendor/quote.png'
import _imports_6 from '../assets/vendor/reviews/amber_aurora_kusina.png'
import _imports_7 from '../assets/vendor/reviews/kins_big_naths.png'


const _hoisted_1 = { class: "vendor" }
const _hoisted_2 = { class: "form-container" }
const _hoisted_3 = { class: "column form-container--info" }
const _hoisted_4 = {
  src: _imports_0,
  alt: "",
  id: "why_foodstuff"
}
const _hoisted_5 = {
  class: "sign-up-form column",
  ref: "signUpForm"
}
const _hoisted_6 = {
  key: 0,
  class: "title"
}
const _hoisted_7 = {
  key: 2,
  class: "row items-end w-100 form-row"
}
const _hoisted_8 = {
  key: 3,
  class: "row items-end w-100 form-row"
}
const _hoisted_9 = {
  key: 4,
  class: "accept-terms"
}
const _hoisted_10 = { class: "container" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 5,
  class: "form-thank-you"
}
const _hoisted_13 = { class: "cards-container column" }
const _hoisted_14 = { class: "deals" }
const _hoisted_15 = { class: "box black-box" }
const _hoisted_16 = { class: "box-content column" }
const _hoisted_17 = { class: "box white-box" }
const _hoisted_18 = { class: "box-content column" }
const _hoisted_19 = { class: "reviews column" }
const _hoisted_20 = { class: "review" }
const _hoisted_21 = { class: "column" }
const _hoisted_22 = { class: "review" }
const _hoisted_23 = { class: "column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_Slide = _resolveComponent("Slide")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_TickerTape = _resolveComponent("TickerTape")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[46] || (_cache[46] = _createElementVNode("div", { class: "banner-img" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[11] || (_cache[11] = _createElementVNode("h1", null, "We champion the best independent restaurants.", -1)),
        _cache[12] || (_cache[12] = _createElementVNode("p", null, " Foodstuff is tackling the issues independent food brands face in the industry. We aim to level the playing field and give delivery a fresh look. ", -1)),
        _withDirectives(_createElementVNode("img", _hoisted_4, null, 512), [
          [_vShow, _ctx.isScrollHintVisible]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.formStep === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[13] || (_cache[13] = [
              _createElementVNode("h2", null, "Sign up today!", -1),
              _createElementVNode("p", null, "One of our city managers will reach out to discuss next steps.", -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.formStep === 0)
          ? (_openBlock(), _createBlock(_component_app_input, {
              key: 1,
              type: "text",
              value: _ctx.form.restaurantName,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.restaurantName) = $event)),
              id: "restaurantName",
              placeholder: "",
              showError: _ctx.show,
              fullWidth: true,
              darkFocus: true
            }, _createSlots({
              label: _withCtx(() => [
                _cache[14] || (_cache[14] = _createElementVNode("p", { class: "app-input-label" }, [
                  _createTextVNode("What is your restaurant called?"),
                  _createElementVNode("sup", null, "*")
                ], -1))
              ]),
              _: 2
            }, [
              (_ctx.yupErrMsg('restaurantName'))
                ? {
                    name: "error",
                    fn: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.yupErrMsg('restaurantName')), 1)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["value", "showError"]))
          : _createCommentVNode("", true),
        (_ctx.formStep === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_app_input, {
                class: "mr-50",
                type: "text",
                value: _ctx.form.city,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.city) = $event)),
                id: "city",
                placeholder: "",
                showError: _ctx.show,
                darkFocus: true
              }, _createSlots({
                label: _withCtx(() => [
                  _cache[15] || (_cache[15] = _createElementVNode("p", { class: "app-input-label" }, [
                    _createTextVNode("What city are you based in?"),
                    _createElementVNode("sup", null, "*")
                  ], -1))
                ]),
                _: 2
              }, [
                (_ctx.yupErrMsg('city'))
                  ? {
                      name: "error",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.yupErrMsg('city')), 1)
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["value", "showError"]),
              _createVNode(_component_app_input, {
                type: "text",
                value: _ctx.form.userName,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.userName) = $event)),
                id: "userName",
                placeholder: "",
                showError: _ctx.show,
                darkFocus: true
              }, _createSlots({
                label: _withCtx(() => [
                  _cache[16] || (_cache[16] = _createElementVNode("p", { class: "app-input-label" }, [
                    _createTextVNode("Whats your first name?"),
                    _createElementVNode("sup", null, "*")
                  ], -1))
                ]),
                _: 2
              }, [
                (_ctx.yupErrMsg('userName'))
                  ? {
                      name: "error",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.yupErrMsg('userName')), 1)
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["value", "showError"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.formStep === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_app_input, {
                class: "mr-50",
                type: "text",
                value: _ctx.form.phone,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.phone) = $event)),
                id: "phone",
                placeholder: "",
                showError: _ctx.show,
                darkFocus: true
              }, _createSlots({
                label: _withCtx(() => [
                  _cache[17] || (_cache[17] = _createElementVNode("p", { class: "app-input-label" }, [
                    _createTextVNode("What is your contact number?"),
                    _createElementVNode("sup", null, "*")
                  ], -1))
                ]),
                _: 2
              }, [
                (_ctx.yupErrMsg('phone'))
                  ? {
                      name: "error",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.yupErrMsg('phone')), 1)
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["value", "showError"]),
              _createVNode(_component_app_input, {
                type: "text",
                value: _ctx.form.email,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.email) = $event)),
                id: "email",
                placeholder: "",
                showError: _ctx.show,
                darkFocus: true
              }, _createSlots({
                label: _withCtx(() => [
                  _cache[18] || (_cache[18] = _createElementVNode("p", { class: "app-input-label" }, [
                    _createTextVNode("What is your email?"),
                    _createElementVNode("sup", null, "*")
                  ], -1))
                ]),
                _: 2
              }, [
                (_ctx.yupErrMsg('email'))
                  ? {
                      name: "error",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.yupErrMsg('email')), 1)
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["value", "showError"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.formStep === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, [
                _cache[19] || (_cache[19] = _createElementVNode("p", null, [
                  _createTextVNode(" I accept the "),
                  _createElementVNode("a", { href: "/boring-stuff/terms-of-service" }, "Terms and Conditions"),
                  _createTextVNode(" of Foodstufff LTD and confirm receipt of the "),
                  _createElementVNode("a", { href: "/boring-stuff/privacy-policy" }, "Privacy Policy"),
                  _createTextVNode(".* ")
                ], -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.acceptedForm) = $event))
                }, null, 512), [
                  [_vModelCheckbox, _ctx.form.acceptedForm]
                ]),
                _cache[20] || (_cache[20] = _createElementVNode("span", { class: "checkmark" }, null, -1))
              ]),
              _createElementVNode("small", null, [
                (_ctx.yupErrMsg('acceptedForm'))
                  ? (_openBlock(), _createElementBlock("b", _hoisted_11, _toDisplayString(_ctx.yupErrMsg('acceptedForm')), 1))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.formStep === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[21] || (_cache[21] = [
              _createElementVNode("h2", null, "Thank You!", -1),
              _createElementVNode("p", null, "One of our city managers will reach out to discuss next steps.", -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.formStep === 0)
          ? (_openBlock(), _createElementBlock("button", {
              key: 6,
              class: "submit",
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)))
            }, "Submit details"))
          : _createCommentVNode("", true)
      ], 512)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_Carousel, {
        settings: _ctx.settings,
        breakpoints: _ctx.breakpoints
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Slide, { key: "transparent_pricing" }, {
            default: _withCtx(() => _cache[22] || (_cache[22] = [
              _createElementVNode("div", {
                class: "card",
                id: "transparent_pricing"
              }, [
                _createElementVNode("h3", null, "Fair, transparent pricing"),
                _createElementVNode("p", null, " We believe in pricing that doesn't break the bank, helping restaurants to focus on what they do best. No hidden fees. ")
              ], -1)
            ])),
            _: 1
          }),
          _createVNode(_component_Slide, { key: "emission_free_delivery" }, {
            default: _withCtx(() => _cache[23] || (_cache[23] = [
              _createElementVNode("div", {
                class: "card",
                id: "emission_free_delivery"
              }, [
                _createElementVNode("h3", null, "Emission-free deliveries"),
                _createElementVNode("p", null, " All of our riders are on bicycles or e-bikes, meaning every delivery is 100% emission free. We save 4kg CO₂ with each delivery. ")
              ], -1)
            ])),
            _: 1
          }),
          _createVNode(_component_Slide, { key: "for_indies" }, {
            default: _withCtx(() => _cache[24] || (_cache[24] = [
              _createElementVNode("div", {
                class: "card",
                id: "for_indies"
              }, [
                _createElementVNode("h3", null, "A community of foodies"),
                _createElementVNode("p", null, " No large chains or big corporates here. Just a community of like-minded foodies sharing our love of all things tasty. ")
              ], -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["settings", "breakpoints"]),
      _cache[25] || (_cache[25] = _createElementVNode("img", {
        src: _imports_1,
        alt: "",
        id: "swipe_right"
      }, null, -1))
    ]),
    _createVNode(_component_TickerTape),
    _cache[47] || (_cache[47] = _createElementVNode("div", { class: "brands-container column" }, [
      _createElementVNode("img", {
        class: "brands",
        src: _imports_2,
        alt: "restaurants logos"
      }),
      _createElementVNode("img", {
        class: "brands-mobile",
        src: _imports_3,
        alt: "restaurants logos"
      }),
      _createElementVNode("p", null, [
        _createTextVNode("Trusted by "),
        _createElementVNode("b", null, "250+"),
        _createTextVNode(" of the UK’s best independent restaurants")
      ]),
      _createElementVNode("img", {
        id: "line",
        src: _imports_4,
        alt: "dotted line"
      })
    ], -1)),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _cache[28] || (_cache[28] = _createElementVNode("h3", null, "Exclusive", -1)),
        _cache[29] || (_cache[29] = _createElementVNode("div", { class: "line-divider" }, [
          _createElementVNode("div", { class: "half-circle half-circle-start" }),
          _createElementVNode("div", { class: "half-circle half-circle-end" })
        ], -1)),
        _createElementVNode("div", _hoisted_16, [
          _cache[27] || (_cache[27] = _createElementVNode("div", { class: "column" }, [
            _createElementVNode("h2", null, "20%"),
            _createElementVNode("p", null, "Commission/order*")
          ], -1)),
          _createVNode(_component_app_button, {
            class: "anchor-btn",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.goto('signUpForm')))
          }, {
            default: _withCtx(() => _cache[26] || (_cache[26] = [
              _createElementVNode("span", { class: "btn-text" }, "Sign up!", -1)
            ])),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _cache[32] || (_cache[32] = _createElementVNode("h3", null, "Multi-platform?", -1)),
        _cache[33] || (_cache[33] = _createElementVNode("div", { class: "line-divider" }, [
          _createElementVNode("div", { class: "half-circle half-circle-start" }),
          _createElementVNode("div", { class: "half-circle half-circle-end" })
        ], -1)),
        _createElementVNode("div", _hoisted_18, [
          _cache[31] || (_cache[31] = _createElementVNode("div", { class: "column" }, [
            _createElementVNode("h2", null, "25%"),
            _createElementVNode("p", null, "Commission/order*")
          ], -1)),
          _createVNode(_component_app_button, {
            class: "anchor-btn",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.goto('signUpForm')))
          }, {
            default: _withCtx(() => _cache[30] || (_cache[30] = [
              _createElementVNode("span", { class: "btn-text" }, "Sign up!", -1)
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _cache[48] || (_cache[48] = _createElementVNode("div", { class: "footnote desktop-only" }, [
      _createElementVNode("p", null, [
        _createElementVNode("sup", null, "*"),
        _createTextVNode("Taxes apply")
      ]),
      _createElementVNode("p")
    ], -1)),
    _cache[49] || (_cache[49] = _createElementVNode("img", {
      id: "line",
      class: "desktop-only",
      src: _imports_4,
      alt: "dotted line"
    }, null, -1)),
    _createElementVNode("div", _hoisted_19, [
      _cache[44] || (_cache[44] = _createElementVNode("img", {
        src: _imports_5,
        alt: "quote",
        class: "quote"
      }, null, -1)),
      _cache[45] || (_cache[45] = _createElementVNode("h2", null, "Ask our restaurants", -1)),
      _createVNode(_component_Carousel, {
        itemsToShow: 1,
        wrapAround: true,
        autoplay: 10000,
        class: "review-carousel"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Slide, { key: "review1" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _cache[35] || (_cache[35] = _createElementVNode("div", { class: "row" }, [
                    _createElementVNode("img", {
                      src: _imports_5,
                      alt: "quote",
                      class: "mobile-quote",
                      width: "55"
                    }),
                    _createElementVNode("h3", null, "Amber, Aurora Kusina")
                  ], -1)),
                  _cache[36] || (_cache[36] = _createElementVNode("p", null, " Foodstuff put the little guys (like us) first, we love that they support independents across the city, give vendors an eco-conscious option to deliver and offer their riders a living wage. ", -1)),
                  _cache[37] || (_cache[37] = _createElementVNode("p", null, "The riders are super friendly and the team are just a great bunch to work with.", -1)),
                  _createVNode(_component_app_button, {
                    class: "anchor-btn",
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.goto('signUpForm')))
                  }, {
                    default: _withCtx(() => _cache[34] || (_cache[34] = [
                      _createElementVNode("span", { class: "btn-text" }, "Sign up today!", -1)
                    ])),
                    _: 1
                  })
                ]),
                _cache[38] || (_cache[38] = _createElementVNode("img", {
                  src: _imports_6,
                  alt: "restaurant owner"
                }, null, -1))
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Slide, { key: "review2" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _cache[40] || (_cache[40] = _createElementVNode("div", { class: "row" }, [
                    _createElementVNode("img", {
                      src: _imports_5,
                      alt: "quote",
                      class: "mobile-quote",
                      width: "55"
                    }),
                    _createElementVNode("h3", null, "Kins, Big Nath's")
                  ], -1)),
                  _cache[41] || (_cache[41] = _createElementVNode("p", null, " Foodstuff don't just talk about ethics, they do it! With other delivery businesses, you're just a number and the commissions are ridiculous. ", -1)),
                  _cache[42] || (_cache[42] = _createElementVNode("p", null, " It's nice to actually know the person you're talking to and Foodstuff care about your small business. ", -1)),
                  _createVNode(_component_app_button, {
                    class: "anchor-btn",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.goto('signUpForm')))
                  }, {
                    default: _withCtx(() => _cache[39] || (_cache[39] = [
                      _createElementVNode("span", { class: "btn-text" }, "Sign up today!", -1)
                    ])),
                    _: 1
                  })
                ]),
                _cache[43] || (_cache[43] = _createElementVNode("img", {
                  src: _imports_7,
                  alt: "restaurant owner"
                }, null, -1))
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}