<!-- eslint-disable -->
<template>
  <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.59998 9.04177L11.3714 1.37059L21.1428 9.04177V21.0965C21.1428 21.6778 20.9141 22.2352 20.5068 22.6463C20.0996 23.0573 19.5473 23.2882 18.9714 23.2882H3.7714C3.19551 23.2882 2.64319 23.0573 2.23597 22.6463C1.82875 22.2352 1.59998 21.6778 1.59998 21.0965V9.04177Z"
      stroke="black"
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.77136 23.2882V12.3294H14.9714V23.2882"
      stroke="black"
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<!-- eslint-enable -->
