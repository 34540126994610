import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "delivery-details-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Our fees", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "divider ham full-width" }, null, -1)),
      _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Delivery fee", -1)),
      _cache[4] || (_cache[4] = _createElementVNode("p", null, "We calculate our delivery fee based on the delivery distance and vehicle type required for delivery.", -1)),
      _cache[5] || (_cache[5] = _createElementVNode("h3", null, "Service fee", -1)),
      _createElementVNode("p", null, " The service fee is " + _toDisplayString(_ctx.serviceFeePercentBelow) + "% of your order (up to " + _toDisplayString(_ctx.serviceFeePriceBreakpoint) + ") then " + _toDisplayString(_ctx.serviceFeePercentAbove) + "% over " + _toDisplayString(_ctx.serviceFeePriceBreakpoint) + ". ", 1),
      _cache[6] || (_cache[6] = _createElementVNode("h3", null, "Small order fee", -1)),
      _createElementVNode("p", null, " Orders up to " + _toDisplayString(_ctx.minSOF) + " basket price will incur a small order fee. We add " + _toDisplayString(_ctx.sofFactor) + " for each £1 under the threshold. To avoid the fee, please add more items to your basket. ", 1),
      _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
      _createVNode(_component_app_button, {
        onClick: _withModifiers(_ctx.close, ["prevent"])
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Got it")
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 16))
}