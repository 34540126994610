<template>
  <!-- Loader -->
  <div v-if="loading || !order" class="loader">
    <Logo class="logo-loader" />
  </div>

  <!-- Loaded content -->
  <div class="order-tracker" v-else>
    <!-- Header -->
    <div class="order-tracker-header">
      <div class="left-part">
        <app-back-link v-if="$route.query.embedded" mini :link="initialPath" />
        <app-back-link v-else mini link="/restaurants" />
        <div v-if="order.paymentMethod === 'invoice' && progressLevel < 3" class="invoice-reminder hide-desktop">
          <img src="../assets/order_tracking/arrow_in_circle.png" alt="" />
          <p>It looks like you paid by invoice. You'll receive this after your delivery has been completed.</p>
        </div>
      </div>
      <div class="right-part hide-mobile">
        <div v-if="order.paymentMethod === 'invoice' && progressLevel < 3" class="invoice-reminder">
          <img src="../assets/order_tracking/arrow_in_circle.png" alt="" />
          <p>It looks like you paid by invoice. You'll receive this after your delivery has been completed.</p>
        </div>
      </div>
    </div>

    <!-- Main content -->
    <div class="order-tracker-body">
      <div class="left-part">
        <!-- Title, description and progress bar -->
        <div class="status-info">
          <h1 class="title">{{ title }}</h1>
          <p v-if="order.status === 'delivered'" class="description">
            Something not right with your order? Please contact our live support below.
          </p>
          <p v-else-if="order.status === 'declined'" class="description isWarning">
            {{
              order.paymentMethod === 'invoice'
                ? 'Unfortunately your order has been declined. You will not be sent an invoice.'
                : 'Unfortunately your order has been declined. A full refund has been processed.'
            }}
          </p>
          <p v-else-if="order.isLate" class="description isWarning">
            We're very sorry your order is running late. Please contact our live support for an update.
          </p>
          <p v-else-if="progressLevel === 3" class="description">
            Please reach out to our live support if you have any questions.
          </p>
          <p v-else-if="progressLevel === 2 && order.type === 'delivery'" class="description">
            We'll aim to deliver your order <b>today</b> between <b>{{ orderTime }}</b
            >.
          </p>
          <p v-else-if="progressLevel === 2 && order.type === 'collect'" class="description">
            Please head over to the restaurant shortly to pick up your order.
          </p>
          <p v-else-if="order.type === 'delivery'" class="description">
            We'll aim to deliver your order on the <b>{{ orderDate }}</b> between <b>{{ orderTime }}</b
            >.
          </p>
          <p v-else class="description">
            Your order will be ready for collection on the <b>{{ orderDate }}</b> between <b>{{ orderTime }}</b
            >.
          </p>
          <div v-if="order.status !== 'delivered'">
            <p class="what-next"><b>What happens next?</b></p>
            <div class="progress-bar">
              <div class="what-next-bullet-point">
                <div
                  class="bullet-point-box"
                  :class="{ active: progressLevel > 0, declined: order.status === 'declined' }"
                >
                  <IconServices v-if="order.status === 'declined'" class="bigger-icon" icon="add" close />
                  <img v-else class="bigger-icon" src="../assets/check.svg" alt="" />
                </div>
                <p :class="{ declined: order.status === 'declined' }">Place your order with Foodstuff</p>
              </div>
              <div class="bar" :class="{ active: progressLevel > 1, declined: order.status === 'declined' }">
                <div v-if="progressLevel === 1" class="animated-content" />
              </div>
              <div class="what-next-bullet-point">
                <div
                  class="bullet-point-box"
                  :class="{ active: progressLevel > 1, declined: order.status === 'declined' }"
                >
                  <IconServices v-if="order.status === 'declined'" class="bigger-icon" icon="add" close />
                  <img v-else-if="progressLevel > 1" class="bigger-icon" src="../assets/check.svg" alt="" />
                  <img v-else src="../assets/order_tracking/text.png" alt="" />
                </div>
                <p :class="{ declined: order.status === 'declined' }">
                  You'll receive a text when your order <br />is preparing
                </p>
              </div>
              <div class="bar" :class="{ active: progressLevel > 2, declined: order.status === 'declined' }">
                <div v-if="progressLevel === 2" class="animated-content" />
              </div>
              <div class="what-next-bullet-point">
                <div
                  class="bullet-point-box"
                  :class="{ active: progressLevel > 2, declined: order.status === 'declined' }"
                >
                  <IconServices v-if="order.status === 'declined'" class="bigger-icon" icon="add" close />
                  <img v-else-if="progressLevel > 2" class="bigger-icon" src="../assets/check.svg" alt="" />
                  <img v-else src="../assets/order_tracking/tracking.png" alt="" />
                </div>
                <p v-if="order.type === 'delivery'" :class="{ declined: order.status === 'declined' }">
                  You'll receive a tracking link via text and <br />email when your order is on the way
                </p>
                <p v-else :class="{ declined: order.status === 'declined' }">
                  Head to pick up your food at {{ orderTime }}
                  <b class="pick-up-button custom-cursor-hover" @click="redirectToGoogleMaps"><br />Pick up address</b>
                </p>
              </div>
            </div>
          </div>
          <div v-else style="margin-top: 20px; display: flex; flex-direction: row; gap: 15px">
            <app-button class="support-or-edit-button" slim @click="openFreshchat">
              <img src="../assets/order_tracking/message.png" alt="" />
              <p>Live support</p>
            </app-button>
          </div>

          <div
            class="view-receipt custom-cursor-hover hide-desktop"
            style="margin-top: 10px"
            @click="openTrackedOrderDetailsDialog"
          >
            <img src="../assets/order_tracking/receipt.png" alt="" />
            <p>View receipt</p>
          </div>
        </div>

        <!-- Footer -->
        <div class="spacer" :class="{ delivered: order.status === 'delivered' }" />
        <p class="footer hide-mobile">With ❤️ from {{ orderCity }}'s independents</p>
      </div>

      <div class="right-part">
        <!-- Order id and basket data -->
        <div class="basket-info hide-mobile">
          <ZigZag />
          <h2 class="basket-info-header">Order #{{ order.friendlyId }}</h2>
          <div class="separator" />
          <div class="view-receipt custom-cursor-hover" @click="openTrackedOrderDetailsDialog">
            <img src="../assets/order_tracking/receipt.png" alt="" />
            <p>View receipt</p>
          </div>
          <div class="items-section">
            <h2 class="items-section-header">Items</h2>
            <div class="divider ham full-width" />
            <TrackedOrderItems :cart="order.cart" :groupBaskets="order.groupBaskets || []" />
          </div>
          <ZigZag />
        </div>

        <!-- Action buttons -->
        <div v-if="order.status !== 'delivered'" class="buttons-row">
          <app-button class="support-or-edit-button" slim @click="openFreshchat">
            <img src="../assets/order_tracking/message.png" alt="" />
            <p>Live support</p>
          </app-button>
          <app-button
            v-if="order.status === 'scheduled'"
            class="support-or-edit-button"
            slim
            white
            @click="openAmendYourOrderDialog"
          >
            <IconServices icon="settings" />
            <p>Edit your basket</p>
          </app-button>
        </div>

        <!-- Footer -->
        <p class="footer hide-desktop">With ❤️ from {{ orderCity }}'s independents</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { watch } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { useShepherd } from 'vue-shepherd';
import { mapState } from 'vuex';

import ZigZag from '@/components/Basket/ZigZag.vue';
import TrackedOrderItems from '@/components/TrackedOrderItems.vue';

import orders from '@/requests/orders';

import IconServices from '@/shared/icons/IconServices.vue';
import Logo from '@/shared/icons/Logo.vue';

@Options({
  components: { IconServices, Logo, TrackedOrderItems, ZigZag },
  computed: {
    ...mapState('embeddedPage', ['initialPath']),
  },
})
export default class OrdersTracker extends Vue {
  private tour = useShepherd({ useModalOverlay: true });
  private interval = -1; // needs to be changed to sockets;
  private loading = true;

  private order: Order | undefined = undefined;
  private progressLevel = 1;

  // GETTERS: START
  get title() {
    const status = this.order?.status;
    const type = this.order?.type;

    if (this.progressLevel === 4) {
      if (status === 'delivered') {
        return type === 'delivery' ? 'Order delivered!' : 'Order collected!';
      }
      return 'Order declined';
    }

    if (this.progressLevel === 3) {
      return type === 'delivery' ? "It's on the way!" : 'Your order is ready';
    }

    if (this.progressLevel === 2) {
      return 'Your order is preparing';
    }

    return 'Thanks for your order!';
  }

  get orderDate() {
    return moment(this.order?.deliverToTime).tz('Europe/London').format('Do MMMM');
  }

  get orderTime() {
    const startTime = moment(this.order?.deliverToTime).tz('Europe/London').format('HH:mm');
    const endTime = moment(this.order?.worstDeliverToTime).tz('Europe/London').format('HH:mm');
    return `${startTime} - ${endTime}`;
  }

  get orderCity() {
    return this.order?.address?.city
      ? this.order!.address.city.charAt(0).toUpperCase() + this.order!.address.city.slice(1)
      : 'Foodstuff';
  }
  // GETTERS: STOP

  // METHODS: START
  private async openFreshchat() {
    const { email, firstName, phone, totalOrders } = this.$store.state.service.user!;

    window.fcWidget?.setTags(['client-bot']);
    window.fcWidget?.user.setProperties({
      firstName,
      email,
      externalId: email,
      phone,
      totalOrders,
    });
    window.fcWidget?.open();
  }

  private redirectToGoogleMaps() {
    if (!this.order) return;
    const { lat, lng } = this.order.restaurantLocation;
    const url = `https://maps.google.com/?q=${lat},${lng}`;
    window.open(url, '_blank');
  }

  private openTrackedOrderDetailsDialog() {
    this.$store.commit('modals/data', { ...this.order });
    this.$store.commit('modals/show', 'trackedOrderDetails');
  }

  private openAmendYourOrderDialog() {
    const canAmend = moment(this.order!.deliverToTime).diff(moment(), 'minutes') > 2 * 60;
    if (canAmend) {
      this.$store.commit('modals/data', { ...this.order });
      this.$store.commit('modals/show', 'amendOrderInit');
    } else {
      this.$store.commit('modals/show', 'amendOrderTooLate');
    }

    if (this.tour.isActive()) this.completeTour();
  }

  private async getOrder() {
    const order = await orders.getOrder(this.$route.query?.orderId as string);
    this.order = order;

    if (['delivered', 'declined'].includes(order.status)) {
      this.progressLevel = 4;
    } else if (['delivering', 'ready_for_collect'].includes(order.status)) {
      this.progressLevel = 3;
    } else if (['preparing', 'ready_for_delivery'].includes(order.status)) {
      this.progressLevel = 2;
    } else {
      this.progressLevel = 1;
    }
  }
  // METHODS: STOP

  // GENERAL STUFF: START
  private setUpProductTour() {
    // Do nothing if the step has already been presented in the past (or it is not a scheduled order):
    const { productTour } = this.$store.state;
    if (this.order?.status !== 'scheduled' || productTour['amend-order']) return;

    // Do nothing in embedded views:
    if (this.$route.query.embedded) return;

    // Identify step's element:
    const element = document.getElementsByClassName('support-or-edit-button')[1];
    if (!element) return;

    // Add step and start tour:
    this.tour.addStep({
      buttons: [{ text: '<p>Got it</p>', action: this.completeTour }],
      attachTo: { element, on: 'right' },
      text: '<p><strong>Amend your scheduled order</strong> up to 2 hours before the delivery time.</p>',
      classes: 'left-side',
    });
    this.tour.start();

    // Add possibility to close pop-up by clicking overlay:
    const overlay = document.getElementsByClassName('shepherd-modal-overlay-container')[0] as HTMLElement;
    if (overlay) overlay.onclick = this.completeTour;

    if (window.innerWidth <= 1024) this.tour.steps[0].hide();
    window.addEventListener('resize', () => {
      // Don't restart tour:
      if (!this.tour.isActive()) return;

      // Show only on desktop:
      const step = this.tour.steps[0];
      if (window.innerWidth <= 1024 && step.isOpen()) step.hide();
      else if (window.innerWidth > 1024 && !step.isOpen()) step.show();
    });
  }

  private completeTour() {
    this.$store.commit('productTour/setAsCompleted', 'amend-order');
    this.tour.complete();
  }

  created() {
    if (!this.$store.state.service.user) {
      this.$router.push('/');
      setTimeout(() => {
        this.$store.commit('modals/show', this.$route.query.embedded ? 'login' : 'auth');
      }, 1000);
      return;
    }

    (async () => {
      for (let i = 0; i < 7; i += 1) {
        if (this.order?.status) {
          this.loading = false;
          break;
        }

        // eslint-disable-next-line no-await-in-loop
        await this.getOrder().catch((err: any) => console.error(err));
        // eslint-disable-next-line no-await-in-loop
        await new Promise((_) => setTimeout(_, 2000));
      }

      if (!this.order?.status) {
        this.$router.push('/oops');
      }
    })();

    // Fetch order every 30 sec:
    this.interval = setInterval(this.getOrder, 30000);
  }

  mounted() {
    // Set up product tour AFTER rendering Order Details:
    watch(
      () => this.loading,
      () => setTimeout(this.setUpProductTour, 500),
    );
  }

  unmounted() {
    clearInterval(this.interval);
    if (this.tour.isActive()) this.tour.complete();
  }
  // GENERATL STUFF: STOP
}
</script>

<style lang="scss" scoped>
// GENERAL STYLES: START
.loader {
  margin-top: auto;
  margin-bottom: auto;
  height: calc(100vh - 55px);
}

.order-tracker {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 55px);
  width: 100%;

  @include for-smartphone {
    height: unset;
    min-height: calc(100vh - 55px);
    justify-content: center;
  }
}

.order-tracker-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-height: 80px;

  @include for-smartphone {
    min-height: unset;
    .left-part {
      gap: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.order-tracker-body {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @include for-smartphone {
    flex-direction: column;
  }
}

.left-part {
  display: flex;
  flex-direction: column;
  width: 540px;

  @include for-smartphone {
    width: 100%;
  }
}

.right-part {
  display: flex;
  flex-direction: column;
  width: 400px;

  @include for-smartphone {
    width: 100%;
  }
}
// GENERAL STYLES: START

// CORE STYLES: STRAT
.status-info {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border: 1px solid $coal20;
  border-radius: 15px;
  padding: 50px;

  @include for-smartphone {
    padding: 30px;
    padding-bottom: 15px;
  }

  .title {
    @include h1;
    margin-bottom: 15px;

    @include for-smartphone {
      font-size: 28px;
      line-height: 28px;
    }
  }

  .description {
    @include p1;
    font-size: 20px;
    line-height: 25px;

    b {
      font-size: 20px;
      line-height: 25px;
    }

    &.isWarning {
      color: #df6c19;
    }

    @include for-smartphone {
      font-size: 15px;
      line-height: 20px;

      b {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }

  .what-next {
    @include p1;
    margin-top: 20px;
    margin-bottom: 10px;
    text-decoration: underline;

    b {
      font-size: 18px;
      @include for-smartphone {
        font-size: 14px;
      }
    }
  }

  .progress-bar {
    display: flex;
    flex-direction: column;
    width: 440px;
    max-width: 100%;

    .what-next-bullet-point {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;
      gap: 20px;

      .bullet-point-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        aspect-ratio: 1;
        border-radius: 20px;

        background-color: $hamOp20;
        &.active {
          background-color: $ham;
        }
        &.declined {
          background-color: rgba(159, 159, 159, 0.2);
        }

        img,
        svg {
          width: 20px;
          margin-left: auto;
          margin-right: auto;

          &.bigger-icon {
            width: 22px;
          }
        }
      }

      p {
        @include p1;
        font-size: 18px;

        &.declined {
          color: rgba(0, 0, 0, 0.5);
        }
      }

      @include for-smartphone {
        height: 30px;
        gap: 15px;

        .bullet-point-box {
          height: 30px;
          img,
          svg {
            width: 15px;
            margin-left: auto;
            margin-right: auto;

            &.bigger-icon {
              width: 17px;
            }
          }
        }

        p {
          font-size: 14px;
          br {
            display: none;
          }
        }
      }
    }

    .bar {
      height: 40px;
      width: 4px;
      background-color: $hamOp20;
      margin-left: 18px;

      &.active {
        background-color: $ham;
      }

      &.declined {
        background-color: rgba(159, 159, 159, 0.2);
      }

      .animated-content {
        height: 0;
        width: 100%;
        background-color: $ham;
        animation: pulse 1.5s infinite;

        @keyframes pulse {
          0% {
            height: 0;
          }
          100% {
            height: 100%;
          }
        }
      }

      @include for-smartphone {
        height: 30px;
        margin-left: 13px;
      }
    }

    .pick-up-button {
      font-size: 18px;
      text-decoration: underline;

      @include for-smartphone {
        font-size: 14px;
        br {
          display: unset !important;
        }
      }
    }
  }
}

.basket-info {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-left: 1px solid $coal20;
  border-right: 1px solid $coal20;

  .zigzag:first-of-type {
    position: absolute;
    top: -8px;
  }

  .basket-info-header {
    padding: 15px;
    font-size: 30px;
    line-height: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .separator {
    border: 0.5px solid $coal20;
    height: 0;
    width: 100%;
  }

  .items-section {
    padding: 0px 15px;
    margin-bottom: 15px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;

    .items-section-header {
      font-size: 25px;
    }

    .divider {
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }

  .zigzag:last-of-type {
    position: absolute;
    bottom: -8px;
    transform: scaleY(-1);
  }
}
// CORE STYLES: STOP

// DUPLICATED ELEMENTS: START
.invoice-reminder {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    @include p1;
  }

  img {
    width: 22px;
    min-width: 22px;
    height: 22px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 15px;
    margin-left: 5px;
  }

  @include for-smartphone {
    padding: 5px 10px;

    p {
      font-size: 13px;
      line-height: 15px;
      margin-top: 3px;
    }

    img {
      width: 18px;
      min-width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }
}

.view-receipt {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;

  p {
    @include p1;
    text-decoration: underline;
  }

  img {
    width: 20px;
    height: 20px;
    margin-bottom: 2px;
  }

  @include for-smartphone {
    gap: 5px;
    p {
      font-size: 14px;
    }
    img {
      width: 15px;
      height: 15px;
    }
  }
}

.buttons-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;

  @include for-smartphone {
    gap: 15px;
    margin-top: 20px;
    margin-bottom: 5px;
  }
}

.support-or-edit-button {
  width: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 7.5px;
  gap: 7.5px;
  padding: 12.5px 15px;

  p {
    @include p1;
    font-size: 16px;
    height: 18px;
  }

  svg,
  img {
    height: 20px;
  }

  @include for-smartphone {
    gap: 5px;
    padding: 10px 7.5px;

    p {
      font-size: 14px;
      height: 18px;
    }

    svg,
    img {
      height: 16px;
    }
  }
}

.footer {
  @include p1;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 10px;

  @include for-smartphone {
    font-size: 14px;
  }
}
// DUPLICATED ELEMENTS: STOP

// HELPER STYLES: START
.spacer {
  height: 0;

  // DESKTOP: sum of heights of elements is 650px:
  @media (min-height: 650px) and (min-width: 1025px) {
    height: calc(100vh - 650px);
  }

  &.delivered {
    // DESKTOP: sum of heights of elements is 650px:
    @media (min-height: 465px) and (min-width: 1025px) {
      height: calc(100vh - 465px);
    }
  }
}

.hide-mobile {
  @include for-smartphone {
    display: none !important;
  }
}

.hide-desktop {
  @include for-desktop {
    display: none !important;
  }
}
// HELPER STYLES: STOP
</style>
