<template>
  <div class="the_perfect_food_section">
    <div class="the_perfect_food_texts">
      <h1 class="section_title">{{ title }}</h1>
      <h2 class="sub_title">{{ subtitle }}</h2>
      <div class="order_buttons hide_mobile">
        <app-button slim @click="orderNow">
          <IconServices icon="upload" />
          <p>Show me food</p>
        </app-button>
        <app-button slim white @click="onPlanSomethingBiggerClick">
          <IconServices icon="gift" />
          <p>Plan something bigger</p>
        </app-button>
      </div>
    </div>
    <img src="../../assets/landing/the_perfect_food_logos.png" alt="" />
    <div class="order_buttons hide_desktop">
      <app-button slim @click="orderNow">
        <IconServices icon="upload" />
        <p>Show me food</p>
      </app-button>
      <app-button slim white @click="onOrderNowClick">
        <IconServices icon="gift" />
        <p>Plan something bigger</p>
      </app-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import IconServices from '@/shared/icons/IconServices.vue';

@Options({
  components: {
    IconServices,
  },
  props: {
    title: String,
    subtitle: String,
    onOrderNowClick: Function,
    onPlanSomethingBiggerClick: Function,
  },
})
export default class ThePerfectFood extends Vue {}
</script>

<style lang="scss" scoped>
.section_title {
  color: $coal100;
  font-family: Recoleta, sans-serif;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 10px;

  @include for-smartphone {
    font-size: 38px;
    line-height: 40px;
  }
}

.sub_title {
  font-family:
    Sofia Pro,
    sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 300;
  text-align: center;
  color: black;
  padding: 0px 30px;
}

.order_buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 30px;
  z-index: 1;

  @include for-smartphone {
    flex-direction: column;
    align-items: center;
  }

  button {
    width: fit-content;
    border: 1px solid black;
    border-radius: 7.5px;
    gap: 7.5px;
    padding: 15px;
  }

  p,
  b {
    font-size: 15px;
    height: 17px;
  }
}

.the_perfect_food_section {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
  padding: 75px 125px;
  border-bottom: 2px solid $coal100;

  @include for-smartphone {
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    gap: 20px;

    .order_buttons {
      margin-top: 0;
    }
  }

  .the_perfect_food_texts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include for-smartphone {
      align-items: center;
    }
  }

  h1,
  h2 {
    text-align: start;
    @include for-smartphone {
      text-align: center;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  h2 {
    margin-top: 15px;
    padding: 0;
  }

  img {
    width: 30vw;
    max-width: 400px;

    @include for-smartphone {
      width: 75vw;
      max-width: 300px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .hide_mobile {
    @include for-smartphone {
      display: none;
    }
  }

  .hide_desktop {
    @include for-desktop {
      display: none;
    }
  }
}
</style>
