import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "check-your-mail-modal" }, _ctx.options), {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("h1", null, "Great!", -1),
      _createElementVNode("p", null, "Please, check your mail", -1)
    ])),
    _: 1
  }, 16))
}