<template>
  <app-modal class="amendable-group-order-warning" :close="true">
    <h2 class="amendable-group-order-warning__title">You’re amending an order</h2>
    <p class="amendable-group-order-warning__description">
      Whilst amending an order, you are unable to change the delivery time and address. You are also unable to view
      other restaurants.
    </p>

    <div class="amendable-group-order-warning__buttons-wrapper">
      <app-button class="amendable-group-order-warning__menu-button" @click.prevent="goToAmendableOrderRestaurant">
        Amend order
      </app-button>
      <app-button class="amendable-group-order-warning__cancel-button" @click.prevent="revertAmendedChanges">
        Revert changes
      </app-button>
    </div>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class ActiveGroupOrderWarning extends Vue {
  private async goToAmendableOrderRestaurant() {
    const amendableData = this.$store.state.amendableOrder.data;
    if (amendableData) {
      const {
        address: { city },
        restaurantSlug,
      } = amendableData;
      this.$router.push(`/menu/${city.toLowerCase()}/${restaurantSlug}`);
    } else {
      this.$router.push('/');
    }
    this.$store.commit('modals/close');
  }

  private revertAmendedChanges() {
    this.$store.commit('basket/clear');
    this.$store.commit('groupOrder/reset');
    this.$store.commit('amendableOrder/clear');
    this.$store.commit('order/scheduledDeliveryInterval', null);
    this.$store.commit('order/scheduledDeliveryDate', null);
    this.$store.commit('modals/close');
  }
}
</script>

<style lang="scss">
.amendable-group-order-warning {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    max-width: 325px;
    width: 325px;
  }

  &__title {
    text-align: center;
    margin: 0 50px;
    margin-top: 20px;
  }

  &__description {
    @include p2;
    text-align: center;
    margin-top: 15px;
  }

  &__buttons-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__menu-button {
    margin-top: 30px;
    width: 100%;
    max-width: unset;
  }

  &__cancel-button {
    width: unset;
    background-color: rgba(34, 17, 34, 0.05);
    margin: 0 auto;
    margin-top: 10px;
    min-height: unset;
    padding: 13px 18px;
  }
}
</style>
