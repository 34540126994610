<template>
  <div class="container">
    <div class="additional_part">
      <Logo text />
      <div class="tracker">
        <b class="step_number">Step {{ step }}</b>
        <p class="step_name">{{ stepName }}</p>
        <div class="progress_bar">
          <div class="progress_bar_part" :style="{ backgroundColor: step > 0 ? 'black' : 'white' }" />
          <div class="progress_bar_part" :style="{ backgroundColor: step > 1 ? 'black' : 'white' }" />
          <div class="progress_bar_part" :style="{ backgroundColor: step > 2 ? 'black' : 'white' }" />
        </div>
      </div>
    </div>
    <div class="main_part_wrapper">
      <div v-if="!submitted" class="action_row no_padding_bottom hide_mobile">
        <div class="expander" />
        <app-button class="cancel_button" slim white @click="goBack" :disabled="loader">
          <p>Cancel</p>
        </app-button>
      </div>
      <div class="mobile_nav_bar hide_desktop">
        <Logo />
        <app-button mini square white @click="goBack" :disabled="loader">
          <IconServices icon="close" />
        </app-button>
      </div>

      <div v-if="step === 1" class="main_part_body">
        <p class="section_subtitle hide_desktop">STEP 1/3</p>
        <h1 class="section_title">Lets get started</h1>
        <p class="section_subtitle">Tell us about your catering needs so we can help craft your perfect event</p>
        <div class="main_part_body_content small_gaps">
          <div id="time_and_date">
            <p class="input_title">Time and date</p>
            <div class="time_and_date_button_wrapper">
              <app-button slim white @click="showCalendar" :class="{ error_border: yupErrMsg('dateTime') }">
                <p>{{ didSetDateTime ? formattedDate : 'Enter a date and time' }}</p>
                <img src="../assets/simple_arrow_bottom.svg" alt="" />
              </app-button>
              <small v-if="yupErrMsg('dateTime')">
                <b>{{ yupErrMsg('dateTime') }}</b>
              </small>
              <div v-else style="height: 18px" />
              <Calendar
                v-if="isCalendarVisible"
                :dateTime="dateTime"
                :onConfirmClick="onCalendarConfirmClick"
                :onClickOutside="closeCalendar"
              />
            </div>
          </div>
          <div id="address">
            <p class="input_title">Where is the event?</p>
            <AddressChangeInput
              :onChange="onAddressChange"
              placeholder="Start typing an address..."
              isAlwaysEditing
              isBasicFont
              :showError="show"
              addErrorPlaceholderSpacing
            >
              <template v-slot:error v-if="yupErrMsg('address')">
                {{ yupErrMsg('address') }}
              </template>
            </AddressChangeInput>
          </div>
          <div>
            <p class="input_title">How many people?</p>
            <app-input
              id="headcount"
              placeholder="e.g. 10"
              type="number"
              v-model:value="headcount"
              :showError="show"
              addErrorPlaceholderSpacing
            >
              <template v-slot:error v-if="yupErrMsg('headcount')">
                {{ yupErrMsg('headcount') }}
              </template>
            </app-input>
          </div>
          <div>
            <p class="input_title">Budget?</p>
            <CurrencyInput
              id="budget"
              v-model="budget"
              :options="{
                currency: 'GBP',
                precision: 2,
                hideCurrencySymbolOnFocus: false,
                locale: 'en-GB',
              }"
              :showError="show"
            >
              <template v-slot:error v-if="yupErrMsg('budget')">
                {{ yupErrMsg('budget') }}
              </template>
            </CurrencyInput>
            <div v-if="headcount > 0 && budget > 0" class="budget_per_head_info">
              <img src="../assets/check.svg" alt="check" />
              This works out as £{{ budgetPerHead }}/head
            </div>
          </div>
        </div>
      </div>

      <div v-if="step === 2" class="main_part_body">
        <p class="section_subtitle hide_desktop">STEP 2/3</p>
        <h1 class="section_title">Now for the food</h1>
        <p class="section_subtitle">This will help us find the perfect catering experience, crafted for you</p>
        <div class="main_part_body_content">
          <div class="list_of_variants_with_title">
            <p class="input_title">Type of food?</p>
            <div class="list_of_variants">
              <div
                v-for="(value, key) in typesOfFood"
                :key="key"
                class="variant custom-cursor-hover"
                :class="{ active: typesOfFood[key] }"
                @click="() => (typesOfFood[key] = !typesOfFood[key])"
              >
                {{ key }}
              </div>
            </div>
            <app-text-accordion title="Other" class="other_types_of_food">
              <app-input v-model:value="otherTypesOfFood" class="animated slideOutDown" />
            </app-text-accordion>
          </div>
          <div class="list_of_variants_with_title">
            <p class="input_title">Service style?</p>
            <div class="list_of_variants">
              <div
                v-for="(value, key) in serviceStyles"
                :key="key"
                class="variant custom-cursor-hover"
                :class="{ active: serviceStyles[key] }"
                @click="() => (serviceStyles[key] = !serviceStyles[key])"
              >
                {{ key }}
              </div>
            </div>
          </div>
          <div class="list_of_variants_with_title">
            <p class="input_title">Dietaries?</p>
            <div class="list_of_variants">
              <div
                v-for="(value, key) in dietaries"
                :key="key"
                class="variant custom-cursor-hover"
                :class="{ active: dietaries[key] }"
                @click="() => (dietaries[key] = !dietaries[key])"
              >
                {{ key }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="step === 3 && !submitted" class="main_part_body">
        <p class="section_subtitle hide_desktop">STEP 3/3</p>
        <h1 class="section_title">Your information</h1>
        <p class="section_subtitle">
          We’ll need some contact details to set up your account and reach out with catering options
        </p>
        <div class="main_part_body_content small_gaps no_margin_top">
          <app-button v-if="!user" class="login_button" slim @click="showAuthDialog">
            <p>Got an account? Log in <ins>here</ins></p>
          </app-button>
          <div v-else style="height: 20px" />
          <app-input
            id="firstName"
            v-model:value="firstName"
            :showError="show"
            :disabled="!!user?.firstName"
            addErrorPlaceholderSpacing
          >
            <template v-slot:label>Full name:</template>
            <template v-slot:error v-if="yupErrMsg('firstName')">
              {{ yupErrMsg('firstName') }}
            </template>
          </app-input>
          <app-input
            id="email"
            v-model:value="email"
            :showError="show"
            :disabled="!!user?.email"
            addErrorPlaceholderSpacing
          >
            <template v-slot:label>Email address:</template>
            <template v-slot:error v-if="yupErrMsg('email')">
              {{ yupErrMsg('email') }}
            </template>
          </app-input>
          <app-input
            id="phone"
            v-model:value="phone"
            :showError="show"
            :disabled="!!user?.phone"
            addErrorPlaceholderSpacing
          >
            <template v-slot:label>Mobile number:</template>
            <template v-slot:error v-if="yupErrMsg('phone')">
              {{ yupErrMsg('phone') }}
            </template>
          </app-input>
          <app-input
            id="companyName"
            v-model:value="companyName"
            :showError="show"
            :disabled="!!user?.companyName"
            addErrorPlaceholderSpacing
          >
            <template v-slot:label>Company name:</template>
            <template v-slot:error v-if="yupErrMsg('companyName')">
              {{ yupErrMsg('companyName') }}
            </template>
          </app-input>
          <app-input
            v-if="!user"
            id="password"
            type="password"
            v-model:value="password"
            :showError="show"
            addErrorPlaceholderSpacing
          >
            <template v-slot:label>Create a password:</template>
            <template v-slot:error v-if="yupErrMsg('password')">
              {{ yupErrMsg('password') }}
            </template>
          </app-input>
        </div>
      </div>

      <div v-if="step === 3 && submitted" class="main_part_body_confirmation">
        <IconServices icon="sunInSunglasses" class="sun" />
        <h1 class="section_title">We're looking <br class="hide_desktop" />over your criteria</h1>
        <p class="what_happens_next_question">What happens next?</p>
        <div class="what_happens_next_bullets">
          <div class="what_happens_next_bullet">
            <div class="img_wrapper">
              <img src="../assets/chevron_right.png" alt="" />
            </div>
            <p>Our team are cooking up some options for you to choose from</p>
          </div>
          <div class="what_happens_next_bullet">
            <div class="img_wrapper">
              <img src="../assets/chevron_right.png" alt="" />
            </div>
            <p>We'll send you options via email within 24 hours</p>
          </div>
          <div class="what_happens_next_bullet">
            <div class="img_wrapper">
              <img src="../assets/chevron_right.png" alt="" />
            </div>
            <p>Sit back and relax - we've got this</p>
          </div>
        </div>
      </div>

      <div v-if="!submitted" class="action_row">
        <app-button v-if="step > 1" class="back_button" slim white @click="goToPreviousStep" :disabled="loader">
          <img src="../assets/chevron_left.png" alt="" />
          <p>Back</p>
        </app-button>
        <div class="expander" />
        <app-button v-if="step < 3" class="next_button" slim @click="goToNextStep" :disabled="loader">
          <p>Next step</p>
          <img src="../assets/chevron_right.png" alt="" />
        </app-button>
        <app-button v-if="step === 3" class="submit_button" slim @click="submit" :loader="loader" :disabled="loader">
          <p>Submit</p>
          <img src="../assets/chevron_right.png" alt="" />
        </app-button>
      </div>
      <div v-else class="action_row centered">
        <app-button class="back_to_homepage_button" slim @click="planNewEvent" :disabled="loader">
          <p>Plan a new event</p>
        </app-button>
        <app-button class="back_to_homepage_button" slim white @click="goBack" :disabled="loader">
          <p>Back to homepage</p>
        </app-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useSeoMeta } from '@unhead/vue';
import axios from 'axios';
import moment from 'moment-timezone';
import { watch } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import * as yup from 'yup';

import AddressChangeInput from '@/components/AddressChangeInput.vue';
import Calendar from '@/components/Calendar.vue';
import CurrencyInput from '@/components/CurrencyInput.vue';

import { SignUp } from '@/requests/account';

import IconServices from '@/shared/icons/IconServices.vue';
import Logo from '@/shared/icons/Logo.vue';

import http from '@/utils/http';
import methods from '@/utils/methods';

@Options({
  components: {
    Logo,
    IconServices,
    CurrencyInput,
    AddressChangeInput,
    Calendar,
  },
  computed: {
    ...mapState('service', ['user']),
    ...mapState('yup', ['show']),
  },
})
export default class Enquiry extends Vue {
  // General: START
  private step = 1;
  private loader = false;
  private submitted = false;

  get stepName() {
    switch (this.step) {
      case 1:
        return 'Your event';
      case 2:
        return 'About the food';
      default:
        return 'Some final details';
    }
  }

  private goBack() {
    this.$router.back();
  }

  private goToNextStep() {
    if (this.step === 1) {
      const isError = this.validateEventData();
      if (isError) {
        this.$store.commit('yup/toggleShowError', true);
        return;
      }
    }

    document.getElementsByClassName('main_part_wrapper')[0].scrollTop = 0;
    this.step += 1;
  }

  private goToPreviousStep() {
    document.getElementsByClassName('main_part_wrapper')[0].scrollTop = 0;
    this.step -= 1;
  }

  private yupErrMsg(path: string) {
    return methods.yupErrMsg(path);
  }
  // General: STOP

  // Event details (step 1): START
  private isCalendarVisible = false;
  private didSetDateTime = false;
  private dateTime = moment().tz('Europe/London').set({
    hours: 9,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  private address = '';
  private headcount = 0;
  private budget = 0;

  get formattedDate() {
    return `${this.dateTime.format('Do MMMM YYYY, HH:mm')}`;
  }

  get budgetPerHead() {
    return (this.budget / this.headcount).toFixed(2);
  }

  private showCalendar() {
    this.isCalendarVisible = true;
  }

  private closeCalendar() {
    this.isCalendarVisible = false;
  }

  private onCalendarConfirmClick(value: moment.Moment) {
    this.dateTime = value;
    this.didSetDateTime = true;
    this.$store.commit('yup/resetPath', 'dateTime');
    this.closeCalendar();
  }

  private onAddressChange(newAddress: google.maps.places.PlaceResult) {
    this.address = newAddress.formatted_address || '';
  }

  private validateEventData() {
    let isError = false;

    if (!this.didSetDateTime) {
      this.$store.commit('yup/errors', { path: 'dateTime', msg: 'No date and time provided.' });
      isError = true;
    }

    if (!this.address) {
      this.$store.commit('yup/errors', { path: 'address', msg: 'No address provided.' });
      isError = true;
    }

    if (this.headcount <= 0) {
      this.$store.commit('yup/errors', { path: 'headcount', msg: 'Number of people has to be a positive number' });
      isError = true;
    }

    if (!this.budget) {
      this.$store.commit('yup/errors', { path: 'budget', msg: 'Budget has to be a positive number' });
      isError = true;
    }

    return isError;
  }
  // Event details (step 1): STOP

  // Food details (step 2): START
  private otherTypesOfFood = '';

  private typesOfFood = {
    Pizza: false,
    Salads: false,
    Asian: false,
    Jamaican: false,
    Breakfast: false,
    'Baked goods': false,
    Mexican: false,
    'Modern british': false,
    Indian: false,
    Sandwiches: false,
    Wraps: false,
    'Street food': false,
  };

  private serviceStyles = {
    'Sit down': false,
    Buffet: false,
    'Grazing platters': false,
    'Pre-packaged': false,
    Delivery: false,
    'Individual portions': false,
  };

  private dietaries = {
    'Gluten-free': false,
    Vegan: false,
    'Nut free': false,
    'Dairy free': false,
    Vegetarian: false,
    Halal: false,
  };
  // Food details (step 2): STOP

  // Account details (step 3): START
  private firstName = '';
  private phone = '+44';
  private email = '';
  private companyName = '';
  private password = '';

  private newUserSchema = yup.object({
    firstName: yup
      .string()
      .matches(/[a-zA-Z]/, 'First name can only contain Latin letters.')
      .required('No name provided.'),
    phone: yup
      .string()
      .length(13, 'Phone number must be exactly 13 characters')
      .matches(/^[+]*[0-9]*$/, 'Phone can only contain numbers.')
      .matches(/^\+44/, 'Phone number must start with +44')
      .min(4, 'No phone number provided.'),
    email: yup.string().email('Provided value is not e-mail.').required('No email provided.'),
    password: yup
      .string()
      .min(8, 'Password should be at least 8 characters long.')
      .matches(/[0-9]/, 'Password should contain at least one number.')
      .matches(/[A-Z]/, 'Password should contain at least one capital letter.')
      .required('No password provided.'),
    companyName: yup.string().required('No company name provided.'),
  });

  private existingUserSchema = yup.object({
    companyName: yup.string().required('No company name provided.'),
  });

  private prefillAccountDetails() {
    if (this.$store.state.service.user) {
      const { firstName, email, phone, companyName } = this.$store.state.service.user;
      this.firstName = firstName;
      this.email = email;
      this.phone = phone;
      if (companyName) this.companyName = companyName;
    }
  }

  private showAuthDialog() {
    this.$store.commit('modals/show', 'auth');
  }

  private planNewEvent() {
    window.location.reload();
  }

  private async submit() {
    this.$store.commit('yup/clear');
    this.loader = true;

    if (this.$store.state.service.user?.email) {
      try {
        await this.existingUserSchema.validate({ companyName: this.companyName }, { abortEarly: false });
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          methods.yupValidCatch(err);
          this.loader = false;
          return;
        }
      }

      this.saveData();
      return;
    }

    const signUpData: SignUp = {
      firstName: this.firstName.trim(),
      phone: this.phone.trim(),
      email: this.email.trim(),
      password: this.password,
      companyName: this.companyName.trim(),
    };

    // Frontend validation:
    try {
      await this.newUserSchema.validate(signUpData, { abortEarly: false });
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        methods.yupValidCatch(err);
        this.loader = false;
        return;
      }
    }

    // Backend validation:
    try {
      await http.post('/accounts/sign-up/pre-check', { phone: this.phone, email: this.email });
    } catch (err) {
      this.$store.commit('yup/errors', { path: 'email', msg: err });
      this.$store.commit('yup/toggleShowError', true);
      this.loader = false;
      return;
    }

    // Account creation with phone verification:
    this.$store.commit('modals/data', { signUpData, phone: signUpData.phone });
    this.$store.commit('modals/show', 'verifyPhone');
  }

  private async saveData() {
    const googleFormURL =
      'https://docs.google.com/forms/d/e/1FAIpQLSeJpu5Mf4ifhl6E1EP4GD1PGqzpqdWDIy_xVKN2-UEhZCZWlg/formResponse';

    try {
      const chosenTypesOfFood = Object.keys(this.typesOfFood)
        .filter((v) => this.typesOfFood[v])
        .concat(this.otherTypesOfFood.split(','))
        .map((v) => v.trim())
        .join(',');
      const chosenServiceStyles = Object.keys(this.serviceStyles)
        .filter((v) => this.serviceStyles[v])
        .join(',');
      const chosenDietaries = Object.keys(this.dietaries)
        .filter((v) => this.dietaries[v])
        .join(',');

      const params = new URLSearchParams();
      params.append('entry.1781500597', this.dateTime.format('YYYY-MM-DD'));
      params.append('entry.1646814287', this.dateTime.format('HH:mm'));
      params.append('entry.630523773', this.address);
      params.append('entry.1640447617', this.headcount.toString());
      params.append('entry.297979220', this.budget.toString());
      params.append('entry.1259027705', chosenTypesOfFood);
      params.append('entry.1609909907', chosenServiceStyles);
      params.append('entry.1021008767', chosenDietaries);
      params.append('entry.1041828629', this.firstName);
      params.append('entry.949814848', this.email);
      params.append('entry.1830022298', this.phone);
      params.append('entry.1376155054', this.companyName);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      // TODO: Move this request to API (you shouldn't send request
      // from web app straight to Google Forms due to CORS policy):
      await axios.post(googleFormURL, params, config);
    } catch (e) {
      console.log(e);
    }

    this.submitted = true;
    this.loader = false;
  }
  // Account details (step 3): STOP

  created() {
    useSeoMeta({ robots: 'noindex' });

    this.address = this.$store.state.address.formattedAddress;
    this.prefillAccountDetails();

    watch(
      () => this.$store.state.service.user,
      () => {
        this.prefillAccountDetails();
      },
    );

    watch(
      () => this.$store.state.modals.verifyPhone,
      () => {
        if (!this.$store.state.modals.verifyPhone) {
          if (this.$store.state.service.user?.email) this.saveData();
          else this.loader = false;
        }
      },
    );
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
}

// Left part: START
.additional_part {
  width: 40vw;
  max-width: 500px;
  background-color: $ham;
  border-right: 2px solid black;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include for-smartphone {
    display: none;
  }

  .tracker {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .step_number {
    font-family:
      Sofia Pro,
      sans-serif;
    color: black;
    font-size: 20px;
    line-height: 20px;
  }

  .step_name {
    font-family: Recoleta, sans-serif;
    font-weight: 600;
    color: black;
    font-size: 30px;
    line-height: 30px;
  }

  .progress_bar {
    margin-top: 10px;
    width: 100%;
    height: 8px;
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  .progress_bar_part {
    height: 100%;
    width: 25%;
    border-radius: 15px;
  }
}
// Left part: STOP

// Right part's container: START
.main_part_wrapper {
  background-color: $vanilla;
  width: calc(100vw - 500px);
  min-width: 60vw;
  display: flex;
  flex-direction: column;

  @include for-desktop {
    height: 100%;
    overflow: scroll;
  }

  @include for-smartphone {
    width: 100%;
    min-width: 100%;
  }
}
// Right part's container: STOP

// Right part's top bar or bottom bar: START
.action_row {
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  @include for-smartphone {
    border-top: 2px solid black;
    justify-content: center;
    padding: 20px;
    gap: 5px;
  }

  &.no_padding_bottom {
    padding-bottom: 10px;
  }

  &.centered {
    justify-content: center;
  }

  .expander {
    width: 100%;

    @include for-smartphone {
      display: none;
    }
  }

  .cancel_button,
  .back_button,
  .next_button,
  .submit_button,
  .back_to_homepage_button {
    border: 1px solid #00000033;
    border-radius: 7.5px;
    width: 120px;
    min-width: 120px;
    height: 40px;
    display: flex;
    flex-direction: row;
    gap: 10px;

    p {
      font-size: 15px;
      height: 18px;
    }
  }

  .back_to_homepage_button {
    width: 160px;
    min-width: 160px;
  }
}

.mobile_nav_bar {
  background-color: $ham;
  border-bottom: 2px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;

  svg {
    width: 24px;
  }
}
// Right part's top bar or bottom bar: STOP

// Common typography: START
.section_title {
  font-family: Recoleta, sans-serif;
  font-size: 50px;
  line-height: 50px;
  color: $coal100;
  margin-bottom: 5px;

  @include for-smartphone {
    font-size: 38px;
    line-height: 38px;
  }
}

.section_subtitle {
  font-family:
    Sofia Pro,
    sans-serif;
  font-size: 20px;
  line-height: 25px;
  color: black;
  font-weight: 300;
}

.input_title {
  font-family:
    Sofia Pro,
    sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: $coal80;
  margin-bottom: 6px;
}
// Common typography: STOP

// Right part's main content: START
.main_part_body {
  padding-left: 40px;
  padding-right: 160px;

  @include for-smartphone {
    height: 100%;
    overflow: scroll;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .main_part_body_content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    margin-top: 50px;

    &.no_margin_top {
      margin-top: 0px;
    }

    &.small_gaps {
      gap: 10px;
    }

    @include for-smartphone {
      margin-top: 30px;
      gap: 30px;
    }
  }

  .time_and_date_button_wrapper {
    max-width: 315px;
    position: relative;

    button {
      width: 100%;
      border: 1px solid #00000033;
      border-radius: 10px;
      height: 45px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      padding-left: 20px;
      padding-right: 12px;
      margin-bottom: 10px;

      p {
        font-size: 15px;
        font-weight: 300;
        height: 18px;
      }
    }

    .error_border {
      border: 3px solid $error;
      padding-left: 18px;
      padding-right: 10px;
    }

    small {
      color: $error;
    }
  }

  .search-input-wrapper {
    max-width: 315px;
  }

  .budget_per_head_info {
    font-family:
      Sofia Pro,
      sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    background-color: $hamOp40;
    padding: 12.5px 15px;
    margin-top: 10px;
    border-radius: 10px;
    justify-content: center;
    display: flex;
    text-align: center;
    width: fit-content;

    img {
      width: 16px;
      margin-right: 8px;
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2px;
    }
  }

  .list_of_variants_with_title {
    display: flex;
    flex-direction: column;

    .list_of_variants {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;

      .variant {
        border-radius: 10px;
        padding: 10px 20px;
        background-color: white;
        border: 1px solid #21212133;
        width: fit-content;

        font-family:
          Sofia Pro,
          sans-serif;
        font-size: 16px;
        line-height: 21px;
        color: $coal100;

        // Prevent selecting text:
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */

        &.active {
          background-color: black;
          color: white;
        }
      }
    }

    .other_types_of_food {
      margin-top: 20px;
    }
  }

  .login_button {
    margin-top: 30px;
    margin-bottom: 20px;
    width: fit-content;
    height: 40px;
    border: 1px solid #00000033;
  }
}

.main_part_body_confirmation {
  height: 100%;
  overflow: scroll;
  width: fit-content;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  .sun {
    margin-left: auto;
    margin-right: auto;
  }

  .section_title {
    margin-top: 50px;
    @include for-smartphone {
      text-align: center;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .what_happens_next_question {
    font-family:
      Sofia Pro,
      sans-serif;
    color: black;
    font-size: 20px;
    line-height: 20px;
    text-decoration: underline;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .what_happens_next_bullets {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .what_happens_next_bullet {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;

      .img_wrapper {
        background-color: $ham;
        border-radius: 7.5px;
        width: 30px;
        min-width: 30px;
        height: 30px;
        min-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 7px;
          height: 12px;
        }
      }

      p {
        font-family:
          Sofia Pro,
          sans-serif;
        color: black;
        font-size: 18px;
        line-height: 18px;
      }
    }
  }
}
// Right part's main content: STOP

.hide_mobile {
  @include for-smartphone {
    display: none;
  }
}

.hide_desktop {
  @include for-desktop {
    display: none;
  }
}
</style>
