import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/gtm/check.png'
import _imports_1 from '../../assets/gtm/calendar.png'
import _imports_2 from '../../assets/gtm/loader.png'
import _imports_3 from '../../assets/gtm/like.png'
import _imports_4 from '../../assets/gtm/file.png'
import _imports_5 from '../../assets/gtm/footer_with_logos.png'


const _hoisted_1 = { class: "step_two_plus" }
const _hoisted_2 = { class: "left_part" }
const _hoisted_3 = { class: "left_part_body" }
const _hoisted_4 = { class: "right_part" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Logo, { text: "" }),
        _createElementVNode("h1", null, "Let's book in your " + _toDisplayString(_ctx.eventSlotNumber) + " Foodstuff experience", 1),
        _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"rows\" data-v-2ba979a4><div class=\"step_two_plus_row\" data-v-2ba979a4><div class=\"circle\" data-v-2ba979a4><img src=\"" + _imports_0 + "\" alt=\"\" data-v-2ba979a4></div><p data-v-2ba979a4>Add your criteria</p></div><div class=\"step_two_plus_row\" data-v-2ba979a4><div class=\"circle\" data-v-2ba979a4><img src=\"" + _imports_1 + "\" alt=\"\" data-v-2ba979a4></div><p data-v-2ba979a4>Select your delivery time</p></div><div class=\"step_two_plus_row\" data-v-2ba979a4><div class=\"circle\" data-v-2ba979a4><img src=\"" + _imports_2 + "\" alt=\"\" data-v-2ba979a4></div><p data-v-2ba979a4>Pay a deposit - refunded after delivery</p></div><div class=\"step_two_plus_row\" data-v-2ba979a4><div class=\"circle\" data-v-2ba979a4><img src=\"" + _imports_3 + "\" alt=\"\" data-v-2ba979a4></div><p data-v-2ba979a4>Receive menus that match your needs</p></div><div class=\"step_two_plus_row\" data-v-2ba979a4><div class=\"circle\" data-v-2ba979a4><img src=\"" + _imports_4 + "\" alt=\"\" data-v-2ba979a4></div><p data-v-2ba979a4>Order with seamless invoicing and delivery</p></div></div><div class=\"stats\" data-v-2ba979a4><div class=\"column\" data-v-2ba979a4><b data-v-2ba979a4>500k</b><p data-v-2ba979a4>Office orders delivered</p></div><div class=\"column\" data-v-2ba979a4><b data-v-2ba979a4>60k</b><p data-v-2ba979a4>Foodstuff customers</p></div></div><img class=\"logos\" src=\"" + _imports_5 + "\" alt=\"\" data-v-2ba979a4>", 3)),
        _createVNode(_component_app_button, {
          square: "",
          white: "",
          onClick: _ctx.initFlow
        }, {
          default: _withCtx(() => [
            _createTextVNode("Book your " + _toDisplayString(_ctx.eventSlotNumber) + " event slot", 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_app_button, {
        square: "",
        white: "",
        onClick: _ctx.initFlow
      }, {
        default: _withCtx(() => [
          _createTextVNode("Book your " + _toDisplayString(_ctx.eventSlotNumber) + " event slot", 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}