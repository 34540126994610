import {
  PaymentMethod,
  Stripe,
  StripeCardCvcElement,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElement,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
  StripeElements,
  StripeError,
  loadStripe,
} from '@stripe/stripe-js';

import Environment from '@/environment';

import store from '@/store';
import { StripeChangeEvent } from '@/store/modules/stripe';

class StripeCustomElements {
  private cardNumber: StripeCardNumberElement | null = null;

  private cvc: StripeCardCvcElement | null = null;

  private expiry: StripeCardExpiryElement | null = null;

  public stripe: Stripe | null = null;

  public elements: StripeElements | null = null;

  public confirmCardPayment = null;

  private update(event: StripeChangeEvent) {
    store.commit('stripe/update', event);
  }

  public async create(): Promise<true> {
    return new Promise((resolve) => {
      loadStripe(Environment.stripeKey).then((res: Stripe | null) => {
        this.stripe = res;

        const style = { base: { textAlign: 'left' } };

        this.elements = this.stripe?.elements() as StripeElements;

        this.cardNumber = this.elements.create('cardNumber', { style });
        this.cardNumber.mount('#card-number');
        this.cardNumber.on('change', (event: StripeCardNumberElementChangeEvent) => {
          this.update(event);
        });

        this.cvc = this.elements.create('cardCvc', { style });
        this.cvc.mount('#card-cvc');
        this.cvc.on('change', (event: StripeCardCvcElementChangeEvent) => {
          this.update(event);
        });

        this.expiry = this.elements.create('cardExpiry', { style });
        this.expiry.mount('#card-expiry');
        this.expiry.on('change', (event: StripeCardExpiryElementChangeEvent) => {
          this.update(event);
        });

        resolve(true);
      });
    });
  }
}

export interface PaymentMethodThen {
  paymentMethod?: PaymentMethod | undefined;
  error?: StripeError | undefined;
}

export default new StripeCustomElements();
