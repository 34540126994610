import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/landing/everybody_wins_clock.png'
import _imports_1 from '../../assets/landing/everybody_wins_hero.png'
import _imports_2 from '../../assets/landing/everybody_wins_smile.png'
import _imports_3 from '../../assets/foodstuff-city.png'


const _hoisted_1 = { class: "everybody_wins_section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<h1 class=\"section_title\" data-v-85bf5aae>Everybody wins</h1><h2 class=\"sub_title\" data-v-85bf5aae>We believe if everyone feels a positive impact, its a win-win</h2><div class=\"everybody_wins_steps\" data-v-85bf5aae><div class=\"everybody_wins_step\" data-v-85bf5aae><img src=\"" + _imports_0 + "\" alt=\"\" data-v-85bf5aae><b data-v-85bf5aae>Win your time back</b><p data-v-85bf5aae>We take everything off your plate - less stress and more smiles</p></div><div class=\"everybody_wins_step\" data-v-85bf5aae><img src=\"" + _imports_1 + "\" alt=\"\" data-v-85bf5aae><b data-v-85bf5aae>Heroic food that wins the room</b><p data-v-85bf5aae>Food that gets you office-hero status every time</p></div><div class=\"everybody_wins_step\" data-v-85bf5aae><img src=\"" + _imports_2 + "\" alt=\"\" data-v-85bf5aae><b data-v-85bf5aae>Your local favourites win</b><p data-v-85bf5aae>Every order makes a proper impact</p></div></div><img class=\"everybody_wins_city\" src=\"" + _imports_3 + "\" alt=\"\" data-v-85bf5aae>", 4)
  ])))
}