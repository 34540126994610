<template>
  <app-button v-if="show && $route.name !== 'orders-tracker'" class="order-tracker" @click.prevent="goTo" white>
    <p>Track order</p>
  </app-button>
</template>

<script lang="ts">
import { getAuth } from 'firebase/auth';
import { watch } from 'vue';
import { Vue } from 'vue-class-component';

import orders from '@/requests/orders';

export default class ButtonOrderTracker extends Vue {
  get show() {
    switch (this.orderStatus) {
      case 'scheduled':
      case 'new':
      case 'preparing':
      case 'ready_for_delivery':
      case 'delivering':
        return true;
      default:
        return false;
    }
  }

  private orderStatus: string = '';
  private friendlyId: number | null = null;

  private goTo() {
    this.$router.push(`/orders-tracker?orderId=${this.friendlyId}`);
  }

  private getOrder() {
    if (!getAuth().currentUser) return;

    orders
      .getOrder()
      .then((order: any) => {
        this.orderStatus = order.status;
        this.friendlyId = order.friendlyId;
      })
      .catch((e) => {
        this.orderStatus = '';
        this.friendlyId = null;
        console.error('Cannot get last order: ', e);
      });
  }

  created() {
    if (this.$store.state.service.user) {
      this.getOrder();
    }

    watch(
      () => this.$store.state.service.user,
      (value: any) => {
        if (value) {
          this.getOrder();
        } else {
          this.orderStatus = '';
          this.friendlyId = null;
        }
      },
    );
  }
}
</script>

<style lang="scss" scoped>
.order-tracker {
  height: 40px;
  min-height: 0;
  border-radius: 5px;

  p {
    font-family:
      Sofia Pro,
      sans-serif;
    font-weight: 500;
    font-size: 14px;
  }
}

.unix .order-tracker p {
  height: 16px;
}
</style>
