<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 13 13" fill="none">
    <path d="M6.5 10.5H11" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M8.75 2.24999C8.94891 2.05108 9.2187 1.93933 9.5 1.93933C9.63929 1.93933 9.77721 1.96677 9.9059 2.02007C10.0346 2.07337 10.1515 2.1515 10.25 2.24999C10.3485 2.34848 10.4266 2.46541 10.4799 2.59409C10.5332 2.72278 10.5607 2.8607 10.5607 2.99999C10.5607 3.13928 10.5332 3.2772 10.4799 3.40589C10.4266 3.53457 10.3485 3.6515 10.25 3.74999L4 9.99999L2 10.5L2.5 8.49999L8.75 2.24999Z"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <!-- eslint-enable -->
</template>
