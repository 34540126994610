<template>
  <app-modal class="invalid-address-modal" v-bind="options">
    <h1 class="medium">Almost there!</h1>
    <div class="divider ham full-width" />
    <h4>Please enter the street</h4>
    <app-button @click.prevent="close">Gotcha</app-button>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

@Options({
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class AppModalInvalidAddress extends Vue {
  private options: ModalInterface = {
    slim: false,
    close: false,
  };

  private close() {
    this.$store.commit('modals/close');
  }
}
</script>

<style lang="scss">
.invalid-address-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
  }
  h1 {
    margin-bottom: 10px;
  }
  h4 {
    margin: 15px 0;
  }
  .divider {
    max-width: 275px;
  }
  .controller-button {
    max-width: 161px;
    min-height: 50px;
  }
}
</style>
