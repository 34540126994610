<template>
  <div class="main_steps">
    <Transition name="fade">
      <div v-if="areHeaderAndFooterLoaded" class="main_steps_header">
        <Logo />
        <app-button square white slim class="chat_with_us" @click.prevent="chatWithUs">
          <img src="../../assets/gtm/james.png" alt="" />
          <p>Chat with us</p>
        </app-button>
        <app-button square white slim class="exit" @click.prevent="exit">
          <p>Exit</p>
        </app-button>
      </div>
    </Transition>

    <Transition name="fade">
      <div v-if="isBodyLoaded" class="main_steps_body">
        <div v-if="step === 3" class="step_three">
          <p class="step_number">Step 3</p>
          <h1 class="step_title">Event slot</h1>
          <p class="step_hint">Don't worry, you can choose another slot later.</p>
          <CalendarGTM :dateTime="dateTime" :onConfirmClick="onCalendarConfirmClick" />
          <div class="slot_picker">
            <p class="slot_picker_title">What time works?</p>
            <p class="slot_picker_subtitle">Let us know a time you'd want the order delivered</p>
            <div class="slot_picker_body">
              <div
                class="navigation_button custom-cursor-hover"
                :class="{ disabled: slotCarouselIndex === 1 }"
                @click="slotDecrease"
              >
                <img class="left" src="../../assets/gtm/icon_left.png" alt="" />
              </div>
              <p
                class="custom-cursor-hover"
                :class="{ active: availableSlots[slotCarouselIndex - 1] === selectedSlot }"
                @click="() => (selectedSlot = availableSlots[slotCarouselIndex - 1])"
              >
                {{ availableSlots[slotCarouselIndex - 1] }}
              </p>
              <p
                class="custom-cursor-hover"
                :class="{ active: availableSlots[slotCarouselIndex] === selectedSlot }"
                @click="() => (selectedSlot = availableSlots[slotCarouselIndex])"
              >
                {{ availableSlots[slotCarouselIndex] }}
              </p>
              <p
                class="custom-cursor-hover"
                :class="{ active: availableSlots[slotCarouselIndex + 1] === selectedSlot }"
                @click="() => (selectedSlot = availableSlots[slotCarouselIndex + 1])"
              >
                {{ availableSlots[slotCarouselIndex + 1] }}
              </p>
              <div
                class="navigation_button custom-cursor-hover"
                :class="{ disabled: slotCarouselIndex === availableSlots.length - 2 }"
                @click="slotIncrease"
              >
                <img class="right" src="../../assets/gtm/icon_right.png" alt="" />
              </div>
            </div>
            <div class="slot_picker_footer">
              <div
                class="navigation_button custom-cursor-hover"
                :class="{ disabled: slotCarouselIndex === 1 }"
                @click="slotDecrease"
              >
                <img class="left" src="../../assets/gtm/icon_left.png" alt="" />
              </div>
              <div
                class="navigation_button custom-cursor-hover"
                :class="{ disabled: slotCarouselIndex === availableSlots.length - 2 }"
                @click="slotIncrease"
              >
                <img class="right" src="../../assets/gtm/icon_right.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="step === 4" class="step_four">
          <p class="step_number">Step 4</p>
          <h1 class="step_title">Event information</h1>
          <div>
            <p class="input_title">Where are we delivering to?</p>
            <div class="input_field">
              <AddressChangeInput
                :onChange="onAddressChange"
                placeholder="Start typing..."
                isAlwaysEditing
                isBasicFont
                :showError="show"
                addErrorPlaceholderSpacing
                acceptAllCities
              >
                <template v-slot:error v-if="yupErrMsg('address')">
                  {{ yupErrMsg('address') }}
                </template>
              </AddressChangeInput>
              <img v-if="address?.formattedAddress" src="../../assets/check.svg" alt="" />
            </div>
          </div>
          <div>
            <p class="input_title">How many people?</p>
            <div class="input_field">
              <app-input
                id="headcount"
                placeholder="e.g. 10"
                type="number"
                v-model:value="headcount"
                :showError="show"
                addErrorPlaceholderSpacing
              >
                <template v-slot:error v-if="yupErrMsg('headcount')">
                  {{ yupErrMsg('headcount') }}
                </template>
              </app-input>
              <img v-if="headcount" src="../../assets/check.svg" alt="" />
            </div>
          </div>
          <div>
            <p class="input_title">Budget?</p>
            <div class="input_field">
              <CurrencyInput
                id="budget"
                v-model="budget"
                :options="{
                  currency: 'GBP',
                  precision: 2,
                  hideCurrencySymbolOnFocus: false,
                  locale: 'en-GB',
                }"
                :showError="show"
              >
                <template v-slot:error v-if="yupErrMsg('budget')">
                  {{ yupErrMsg('budget') }}
                </template>
              </CurrencyInput>
              <img v-if="budget" src="../../assets/check.svg" alt="" />
            </div>
            <p v-if="headcount > 0 && budget > 0" class="budget_per_head_info">
              This works out as £{{ chosenBudgetPerHead }}/head
            </p>
          </div>
        </div>
        <div v-if="step === 5" class="step_five">
          <p class="step_number">Step 5</p>
          <h1 class="step_title">Preferred cuisines</h1>
          <p class="step_hint">Choose as many as you like - we'll curate your restaurant selection around it</p>
          <div class="list_of_variants">
            <div
              v-for="(value, key) in typesOfFood"
              :key="key"
              class="variant custom-cursor-hover"
              :class="{ active: typesOfFood[key] }"
              @click="() => (typesOfFood[key] = !typesOfFood[key])"
            >
              <img :src="require(`../../assets/gtm/food/${key}.png`)" alt="" />
              <p>{{ key }}</p>
            </div>
          </div>
          <app-text-accordion title="Other" class="other_variants" isGTM>
            <app-input v-model:value="otherTypesOfFood" class="animated slideOutDown" />
          </app-text-accordion>
        </div>
        <div v-if="step === 6" class="step_six">
          <p class="step_number">Step 6</p>
          <h1 class="step_title">Preferred presentation style</h1>
          <div class="list_of_variants">
            <div
              v-for="(value, key) in typesOfPresentationStyles"
              :key="key"
              class="variant custom-cursor-hover"
              :class="{ active: typesOfPresentationStyles[key] }"
              @click="() => (typesOfPresentationStyles[key] = !typesOfPresentationStyles[key])"
            >
              <p>{{ key }}</p>
              <p>{{ presentationStyleDescriptions[key] }}</p>
            </div>
          </div>
        </div>
        <div v-if="step === 7" class="step_seven">
          <p class="step_number">Step 7</p>
          <h1 class="step_title">Allergen requirements</h1>
          <div class="list_of_variants">
            <div
              v-for="(value, key) in typesOfAllergens"
              :key="key"
              class="variant custom-cursor-hover"
              :class="{ active: typesOfAllergens[key] }"
              @click="() => (typesOfAllergens[key] = !typesOfAllergens[key])"
            >
              <img :src="require(`../../assets/gtm/allergens/${key}.png`)" alt="" />
              <p>{{ key }}</p>
            </div>
          </div>
          <app-text-accordion title="Other" class="other_variants" isGTM>
            <app-input v-model:value="otherTypesOfAllergens" class="animated slideOutDown" />
          </app-text-accordion>
        </div>
        <div v-if="step === 8" class="step_eight">
          <h1 class="step_title">Your event review</h1>
          <div class="summary_card">
            <div class="summary_card_body">
              <p class="summary_card_title">Delivery</p>
              <p class="summary_card_description">
                We'll deliver to <strong>{{ chosenStreet }}</strong> on <strong>{{ chosenDate }}</strong> between
                <strong>{{ chosenInterval }}</strong> for <strong>{{ headcount }} people</strong> with a
                <strong>£{{ chosenBudgetPerHead }}/head budget</strong>
              </p>
            </div>
            <img
              class="summary_card_edit custom-cursor-hover"
              src="../../assets/gtm/edit.png"
              alt=""
              @click="() => goBackToStep(3)"
            />
          </div>
          <div class="summary_card">
            <div class="summary_card_body">
              <p class="summary_card_title">Cuisines and presentation</p>
              <p class="summary_card_description">
                We'll find you a mix of <strong>{{ chosenCuisines }}</strong> restaurants, in
                {{ chosenPresentationStyles === 'any' ? '' : 'a' }}
                <strong>{{ chosenPresentationStyles }}</strong> presentation style
              </p>
            </div>
            <img
              class="summary_card_edit custom-cursor-hover"
              src="../../assets/gtm/edit.png"
              alt=""
              @click="() => goBackToStep(5)"
            />
          </div>
          <div class="summary_card">
            <div class="summary_card_body">
              <p class="summary_card_title">Allergens and dietaries</p>
              <p class="summary_card_description">
                We'll make sure that there are <strong>{{ chosenAllergenRequirements }}</strong> options to choose from
              </p>
            </div>
            <img
              class="summary_card_edit custom-cursor-hover"
              src="../../assets/gtm/edit.png"
              alt=""
              @click="() => goBackToStep(7)"
            />
          </div>
        </div>
      </div>
    </Transition>

    <!-- Placeholder for body -->
    <div v-if="!isBodyLoaded" class="main_steps_body" />

    <Transition name="fade">
      <div v-if="areHeaderAndFooterLoaded" class="main_steps_footer">
        <div class="progress_bar">
          <div><div :style="`width: ${progressBarWidth1}%`" /></div>
          <div><div :style="`width: ${progressBarWidth2}%`" /></div>
          <div><div :style="`width: ${progressBarWidth3}%`" /></div>
          <div />
        </div>
        <div class="buttons">
          <app-button v-if="step > 3" class="back_button" square slim @click="() => goBackToStep(step - 1)">
            Previous step
          </app-button>
          <app-button
            v-if="step === 8"
            class="add_button"
            square
            slim
            @click="bookSlot"
            :disabled="isNextStepDisabled || isBookingSlot"
            :loader="isBookingSlot"
          >
            Add a deposit
          </app-button>
          <app-button
            v-else-if="step === 3 && isUsingExistingDetails"
            class="confirm_button"
            square
            slim
            @click="goToReview"
            :disabled="isNextStepDisabled"
          >
            Confirm event
          </app-button>
          <app-button v-else class="next_button" square slim @click="goToNextStep" :disabled="isNextStepDisabled">
            Next step
          </app-button>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { watch } from 'vue';
import { Options, Vue, prop } from 'vue-class-component';
import { mapState } from 'vuex';

import AddressChangeInput from '@/components/AddressChangeInput.vue';
import CurrencyInput from '@/components/CurrencyInput.vue';
import CalendarGTM from '@/components/GTM/CalendarGTM.vue';

import Logo from '@/shared/icons/Logo.vue';

import google from '@/utils/google';
import methods from '@/utils/methods';

class Props {
  bookSlot = prop<Function>({ required: true });
  transitionToNextStep = prop<Function>({ required: true });
  isTransitioning = prop<Boolean>({ required: true });
  isBodyLoaded = prop<Boolean>({ required: true });
  areHeaderAndFooterLoaded = prop<Boolean>({ required: true });
}

@Options({
  components: {
    AddressChangeInput,
    CurrencyInput,
    CalendarGTM,
    Logo,
  },
  props: {
    // Core methods: START
    exit: Function,
    chatWithUs: Function,
    bookSlot: Function,
    transitionToNextStep: Function,
    // Core methods: STOP

    // Loader states: START
    isBookingSlot: Boolean,
    areHeaderAndFooterLoaded: Boolean,
    isBodyLoaded: Boolean,
    isTransitioning: Boolean,
    // Loader states: STOP
  },
  computed: {
    ...mapState('yup', ['show']),
    ...mapState('gtm', ['step', 'isUsingExistingDetails']),
  },
})
export default class GtmStep3To8 extends Vue.with(Props) {
  // STATES: START
  private progressBarWidth1 = 100;
  private progressBarWidth2 = 0;
  private progressBarWidth3 = 0;

  private dateTime: moment.Moment | null = null;
  private availableSlots: string[] = [];
  private slotCarouselIndex = 0;
  private selectedSlot = '';

  private address: any = null;
  private headcount = 0;
  private budget = 0;

  private otherTypesOfFood = '';
  private typesOfFood = {
    Indian: false,
    British: false,
    'Street food': false,
    Pizza: false,
    Salads: false,
    Asian: false,
    Sushi: false,
    Seafood: false,
    Sandwiches: false,
    Wraps: false,
    Bakery: false,
    Jamaican: false,
    Breakfast: false,
    Gelato: false,
    Mexican: false,
  };

  private typesOfPresentationStyles = {
    'Sit down': false,
    Buffets: false,
    'Grazing platters': false,
    'Pre-packaged': false,
    Delivery: false,
    'Individual portions': false,
  };
  private readonly presentationStyleDescriptions = {
    'Sit down': 'Corporate dinners, CEO lunches',
    Buffets: 'Big spreads for big teams',
    'Grazing platters': 'A focus on presentation',
    'Pre-packaged': 'DIY set up at the office',
    Delivery: 'To your office door or desk',
    'Individual portions': 'Team and dish names added',
  };

  private otherTypesOfAllergens = '';
  private typesOfAllergens = {
    'Nut-free': false,
    Vegan: false,
    Vegetarian: false,
    Halal: false,
    'Dairy-free': false,
  };
  // STATES: STOP

  // GETTERS (DISPLAYING INFO): START
  get isNextStepDisabled() {
    // Disable button during transitions:
    if (this.isTransitioning || !this.isBodyLoaded || !this.areHeaderAndFooterLoaded) {
      return true;
    }

    // Make sure all data is provided (NOTE: providing data in steps 5-7 is optional):
    const { step } = this.$store.state.gtm;
    if (step === 3) return !this.dateTime || !this.selectedSlot;
    if (step === 4) return !this.address?.formattedAddress || !this.budget || !this.headcount;
    return false;
  }

  get chosenBudgetPerHead() {
    return (this.budget / this.headcount).toFixed(2);
  }

  get chosenStreet() {
    const { streetNumber, streetName } = this.address;
    return `${streetNumber || ''} ${streetName || ''}`.trim();
  }

  get chosenInterval() {
    if (!this.selectedSlot) return '';

    const slotStart = moment(this.selectedSlot, 'h:mm A');
    const slotEnd = moment(slotStart).add(20, 'minutes');
    return `${slotStart.format('h.mmA')}-${slotEnd.format('h.mmA')}`;
  }

  get chosenDate() {
    return this.dateTime?.format('MMMM Do');
  }

  get chosenCuisines() {
    const elements = Object.keys(this.typesOfFood).filter((key) => this.typesOfFood[key]);
    if (this.otherTypesOfFood) elements.push(this.otherTypesOfFood);

    if (elements.length === 0) return 'some';

    if (elements.length === 1) return elements[0];

    const elementsWithCommas = elements
      .slice(0, elements.length - 2)
      .map((e) => `${e}, `)
      .join('');
    return `${elementsWithCommas}${elements[elements.length - 2]} and ${elements[elements.length - 1]}`;
  }

  get chosenPresentationStyles() {
    const elements = Object.keys(this.typesOfPresentationStyles).filter((key) => this.typesOfPresentationStyles[key]);

    if (elements.length === 0) return 'any';

    if (elements.length === 1) return elements[0];

    const elementsWithCommas = elements
      .slice(0, elements.length - 2)
      .map((e) => `${e}, `)
      .join('');
    return `${elementsWithCommas}${elements[elements.length - 2]} or ${elements[elements.length - 1]}`;
  }

  get chosenAllergenRequirements() {
    const elements = Object.keys(this.typesOfAllergens).filter((key) => this.typesOfAllergens[key]);
    if (this.otherTypesOfAllergens) elements.push(this.otherTypesOfAllergens);

    if (elements.length === 0) return 'several';

    if (elements.length === 1) return elements[0];

    const elementsWithCommas = elements
      .slice(0, elements.length - 2)
      .map((e) => `${e}, `)
      .join('');
    return `${elementsWithCommas}${elements[elements.length - 2]} and ${elements[elements.length - 1]}`;
  }

  private yupErrMsg(path: string) {
    return methods.yupErrMsg(path);
  }
  // GETTERS (DISPLAYING INFO): STOP

  // NAVIGATION: START
  private async goToNextStep() {
    const { step, areTransitionsDisabled } = this.$store.state.gtm;

    // Move to 'Step 4: Event information':
    if (step === 3) {
      this.$store.commit('gtm/setStep', 4);
      this.$store.commit('gtm/setDateTime', this.dateTime!.toISOString());
      this.$store.commit('gtm/setSlot', this.selectedSlot);
      this.updateProgressBar(4);
    }

    // Move to 'Step 5: Cuisines':
    else if (step === 4) {
      if (!areTransitionsDisabled) {
        await this.transitionToNextStep(5, "Thanks! Let's chat\ndietaries and cuisines.");
      } else {
        this.$store.commit('gtm/setStep', 5);
      }
      this.$store.commit('gtm/setHeadCount', this.headcount);
      this.$store.commit('gtm/setBudget', this.budget);
      this.$store.commit('gtm/setAddress', this.address);
      this.$store.commit('address/set', this.address);
      this.updateProgressBar(5);
    }

    // Move to 'Step 6: Presentation styles':
    else if (step === 5) {
      const selectedTypesOfFood = Object.keys(this.typesOfFood).filter((key) => this.typesOfFood[key]);
      this.$store.commit('gtm/setStep', 6);
      this.$store.commit('gtm/setTypesOfFood', selectedTypesOfFood);
      this.$store.commit('gtm/setOtherTypesOfFood', this.otherTypesOfFood);
      this.updateProgressBar(6);
    }

    // Move to 'Step 7: Allergen requirements':
    else if (step === 6) {
      const selectedPresentationStyles = Object.keys(this.typesOfPresentationStyles).filter(
        (key) => this.typesOfPresentationStyles[key],
      );
      this.$store.commit('gtm/setStep', 7);
      this.$store.commit('gtm/setTypesOfPresentationStyles', selectedPresentationStyles);
      this.updateProgressBar(7);
    }

    // Move to review (step 8):
    else if (step === 7) {
      this.progressBarWidth3 = 100; // ensure

      const selectedAllergens = Object.keys(this.typesOfAllergens).filter((key) => this.typesOfAllergens[key]);
      if (!areTransitionsDisabled) {
        await this.transitionToNextStep(8, "Perfect. Here's\na quick review.");
      } else {
        this.$store.commit('gtm/setStep', 8);
      }
      this.$store.commit('gtm/setTypesOfAllergens', selectedAllergens);
      this.$store.commit('gtm/setOtherTypesOfAllergens', this.otherTypesOfAllergens);

      this.$store.commit('gtm/disableTransitions');
    }
  }

  private async goToReview() {
    this.$store.commit('gtm/setStep', 8);
    this.$store.commit('gtm/setDateTime', this.dateTime!.toISOString());
    this.$store.commit('gtm/setSlot', this.selectedSlot);
    this.$store.commit('gtm/setIsUsingExistingDetails', false);
    this.progressBarWidth2 = 100;
    await this.wait(250);
    this.updateProgressBar(8);
  }

  private async goBackToStep(step: number) {
    switch (step) {
      case 3:
        if (this.$store.state.gtm.step === 8) {
          this.$store.commit('gtm/setStep', 3);
          this.progressBarWidth3 = 0;
          await this.wait(250);
        } else {
          this.$store.commit('gtm/setStep', 3);
        }
        this.updateProgressBar(step);
        break;
      case 4:
        this.$store.commit('gtm/setStep', 4);
        this.progressBarWidth3 = 0;
        await this.wait(250);
        this.updateProgressBar(step);
        break;
      default:
        this.$store.commit('gtm/setStep', step);
        this.updateProgressBar(step);
        break;
    }
  }

  private updateProgressBar(step: number) {
    let providedInfo = 0;

    switch (step) {
      case 1:
      case 2:
      case 2.5:
        this.progressBarWidth1 = 100;
        this.progressBarWidth2 = 0;
        this.progressBarWidth3 = 0;
        break;
      case 3:
        if (this.dateTime) providedInfo += 1;
        if (this.selectedSlot) providedInfo += 1;
        this.progressBarWidth2 = 9.5 * providedInfo;
        break;
      case 4:
        if (this.address?.formattedAddress) providedInfo += 1;
        if (this.budget) providedInfo += 1;
        if (this.headcount) providedInfo += 1;
        this.progressBarWidth2 = 19 + 27 * providedInfo;
        break;
      case 5:
        if (Object.values(this.typesOfFood).some((e) => e) || this.otherTypesOfFood) providedInfo += 1;
        this.progressBarWidth2 = 100;
        this.progressBarWidth3 = 20 + 15 * providedInfo;
        break;
      case 6:
        if (Object.values(this.typesOfPresentationStyles).some((e) => e)) providedInfo += 1;
        this.progressBarWidth2 = 100;
        this.progressBarWidth3 = 50 + 15 * providedInfo;
        break;
      case 7:
        if (Object.values(this.typesOfAllergens).some((e) => e) || this.otherTypesOfAllergens) providedInfo += 1;
        this.progressBarWidth2 = 100;
        this.progressBarWidth3 = 80 + 20 * providedInfo;
        break;
      default:
        this.progressBarWidth2 = 100;
        this.progressBarWidth3 = 100;
        break;
    }
  }

  private wait = (ms: number) => new Promise((_) => setTimeout(_, ms));
  // NAVIGATION: STOP

  // STEP 3 METHODS: START
  private onCalendarConfirmClick(value: moment.Moment) {
    this.dateTime = value;
  }

  private onAddressChange(newAddress: google.maps.places.PlaceResult) {
    const components = newAddress?.address_components;
    if (!components) return;

    const location = newAddress?.geometry?.location;
    const lat: number | undefined = typeof location?.lat === 'number' ? (location?.lat as any) : location?.lat();
    const lng: number | undefined = typeof location?.lng === 'number' ? (location?.lng as any) : location?.lng();

    this.address = {
      lat,
      lng,
      cityName: google.getCityName(components),
      postcode: google.getPostalCode(components),
      streetName: google.getStreetName(components),
      streetNumber: google.getStreetNumber(components),
      formattedAddress: newAddress.formatted_address,
    };
  }

  private slotDecrease() {
    if (this.slotCarouselIndex === 1) return;
    this.slotCarouselIndex -= 1;
  }

  private slotIncrease() {
    if (this.slotCarouselIndex === this.availableSlots.length - 2) return;
    this.slotCarouselIndex += 1;
  }
  // STEP 3 METHODS: STOP

  created() {
    // Build list of avaialable slots (for slot picker):
    for (let i = 7; i < 22; i += 1) {
      const time1 = moment().set({ hours: i, minutes: 0 });
      const time2 = moment().set({ hours: i, minutes: 30 });
      this.availableSlots.push(time1.format('h:mm A'));
      this.availableSlots.push(time2.format('h:mm A'));
    }
    this.slotCarouselIndex = this.availableSlots.length / 2;

    // Set initial address from local storage:
    const { address, gtm } = this.$store.state;
    if (address) this.address = address;

    // Load state from previous session:
    if (gtm.dateTime) this.dateTime = moment(gtm.dateTime).tz('Europe/London');
    if (gtm.selectedSlot) this.selectedSlot = gtm.selectedSlot;
    if (gtm.address) this.address = gtm.address;
    this.headcount = gtm.headcount;
    this.budget = gtm.budget;
    this.otherTypesOfFood = gtm.otherTypesOfFood;
    this.otherTypesOfAllergens = gtm.otherTypesOfAllergens;

    for (let i = 0; i < gtm.typesOfFood.length; i += 1) {
      const el = gtm.typesOfFood[i];
      if (Object.keys(this.typesOfFood).includes(el)) {
        this.typesOfFood[el] = true;
      }
    }

    for (let i = 0; i < gtm.typesOfPresentationStyles.length; i += 1) {
      const el = gtm.typesOfPresentationStyles[i];
      if (Object.keys(this.typesOfPresentationStyles).includes(el)) {
        this.typesOfPresentationStyles[el] = true;
      }
    }

    for (let i = 0; i < gtm.typesOfAllergens.length; i += 1) {
      const el = gtm.typesOfAllergens[i];
      if (Object.keys(this.typesOfAllergens).includes(el)) {
        this.typesOfAllergens[el] = true;
      }
    }

    this.progressBarWidth1 = 100;
    this.updateProgressBar(gtm.step);

    // Animate progress bar while providing data in steps 3-7:
    watch(
      () => [
        this.dateTime,
        this.selectedSlot,
        this.address,
        this.budget,
        this.headcount,
        Object.values(this.typesOfFood),
        Object.values(this.typesOfPresentationStyles),
        Object.values(this.typesOfAllergens),
        this.otherTypesOfFood,
        this.otherTypesOfAllergens,
      ],
      () => {
        const { step } = this.$store.state.gtm;
        if (step <= 7) this.updateProgressBar(step);
      },
    );
  }
}
</script>

<style lang="scss" scoped>
.main_steps {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100vh;
  width: 100vw;

  .main_steps_header {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 10px 20px;

    .logo {
      margin-right: auto;

      @include for-desktop {
        transform: scale(1.4);
      }
    }

    button {
      border: 1px solid rgba(0, 0, 0, 0.2);
      height: 40px;
      padding: 0;

      img {
        height: 30px;
        margin-right: 10px;
      }

      p {
        font-size: 17px;
        height: 17px;
      }

      @include for-smartphone {
        height: 35px;
        min-height: unset;

        img {
          height: 25px;
        }

        p {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }

    .chat_with_us {
      width: 165px;
      padding-right: 5px;

      @include for-smartphone {
        width: 140px;
      }
    }

    .exit {
      width: 70px;

      @include for-smartphone {
        width: 60px;
      }
    }
  }

  .main_steps_body {
    flex: 1;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
  }

  .main_steps_footer {
    display: flex;
    flex-direction: column;
    padding: 22px 20px 10px 20px;

    .progress_bar {
      display: flex;
      flex-direction: row;
      gap: 15px;
      margin-bottom: 15px;

      div {
        height: 8px;
        flex: 2;
        background-color: #ebebeb;

        div {
          background-color: black;
          transition-property: all;
          transition-timing-function: linear;
          transition-duration: 0.25s;
        }
      }

      div:nth-child(1) {
        flex: 1;
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;

      button {
        border: 1px solid rgba(0, 0, 0, 0.2);
        height: 40px;
      }

      .back_button {
        width: 120px;
      }

      .next_button {
        width: 100px;
        margin-left: auto;
      }

      .confirm_button {
        width: 130px;
        margin-left: auto;
      }

      .proceed_button {
        width: 160px;
        margin-left: auto;
      }

      .add_button {
        width: 125px;
        margin-left: auto;
      }
    }
  }
}

.step_three,
.step_four,
.step_five,
.step_six,
.step_seven,
.step_eight {
  display: flex;
  flex-direction: column;
  width: 700px;
  padding: 20px;

  @include for-smartphone {
    width: 500px;
    max-width: 100vw;
  }

  .step_number {
    @include p2;
    font-size: 20px;
    line-height: 20px;

    @include for-smartphone {
      font-size: 14px;
      line-height: 14px;
    }
  }

  .step_title {
    font-size: 40px;
    line-height: 40px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px;

    @include for-smartphone {
      font-size: 25px;
      line-height: 25px;
      margin-top: 2.5px;
      margin-bottom: 5px;
    }
  }

  .step_hint {
    @include p2;
    opacity: 0.5;

    @include for-smartphone {
      font-size: 14px;
      line-height: 14px;
    }
  }
}

.step_three {
  // [Mobile] Header + footer = 155px, step's content = about 460px
  @media (min-height: 615px) {
    padding-top: calc(20px + ((100vh - 615px) / 2));
  }

  // [Desktop] Header + footer = 155px, step's content = about 435px
  @media (min-height: 590px) and (min-width: 1025px) {
    padding-top: calc(20px + ((100vh - 590px) / 2));
  }

  .step_hint {
    margin-bottom: 20px;
  }

  .slot_picker {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

    .slot_picker_title {
      @include p1;
      font-size: 17px;
      font-weight: bold;

      @include for-desktop {
        font-size: 20px;
      }
    }

    .slot_picker_subtitle {
      @include p3;
      opacity: 0.5;
      text-align: center;

      @include for-desktop {
        font-size: 17px;
        margin-top: 5px;
      }
    }

    .slot_picker_body {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 20px;
      gap: 25px;

      @include for-smartphone {
        margin-top: 10px;
        gap: 15px;

        .navigation_button {
          display: none;
        }
      }

      p {
        @include p1;
        font-weight: bold;
        width: 125px;
        height: 50px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 15px;
        text-align: center;
        line-height: 50px;

        @include for-smartphone {
          height: 40px;
          line-height: 40px;
          font-size: 15px;
          border-radius: 10px;
          width: 90px;
        }

        &:hover {
          background-color: $snow;
        }

        &.active {
          background-color: $ham;
          border: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
    }

    .slot_picker_footer {
      display: flex;
      flex-direction: row;
      gap: 30px;
      margin-top: 10px;

      @include for-desktop {
        display: none;
      }
    }

    .navigation_button {
      width: 35px;
      height: 35px;
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: white;
      &:hover {
        background-color: $snow;
      }

      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;

        &:hover {
          background-color: $white;
        }
      }

      img {
        height: 15px;
      }

      .left {
        margin-right: 2px;
      }

      .right {
        margin-left: 2px;
      }
    }
  }
}

.step_four {
  // Header + footer = 155px, step's content = about 425px
  @media (min-height: 580px) {
    padding-top: calc(20px + ((100vh - 580px) / 2));
  }

  .step_title {
    margin-bottom: 20px;
  }

  div {
    width: 360px;
  }

  .input_title {
    font-family:
      Sofia Pro,
      sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: $coal80;
    margin-bottom: 6px;
  }

  .input_field {
    display: flex;
    flex-direction: row;

    div:first-of-type {
      width: 315px;
    }

    img {
      width: 24px;
      height: 24px;
      padding: 6px 4px;
      margin-left: 10px;
      margin-top: 10px;
    }
  }

  .budget_per_head_info {
    font-family:
      Sofia Pro,
      sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    margin-top: 10px;
    margin-left: 15px;
    opacity: 0.5;
  }
}

.step_five {
  width: fit-content;

  // Header + footer = 155px, step's content = about 575px
  @media (min-height: 730px) and (min-width: 770px) {
    padding-top: calc(20px + ((100vh - 730px) / 2));
  }

  .step_title,
  .step_number,
  .step_hint {
    margin-left: 15px;
  }

  .list_of_variants {
    display: grid;
    margin-left: auto;
    margin-right: auto;
    gap: 20px;
    margin-top: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 730px;

    @include for-custom(770) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      width: 580px;
    }

    @include for-custom(620) {
      grid-template-columns: 1fr 1fr 1fr;
      width: 430px;
    }

    @include for-custom(470) {
      grid-template-columns: 1fr 1fr;
      width: 280px;
    }

    .variant {
      border-radius: 15px;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.2);
      width: 130px;
      height: 100px;
      display: flex;
      flex-direction: column;
      gap: 7.5px;
      align-items: center;
      justify-content: center;

      &.active {
        background-color: $ham;
        border: 1px solid rgba(0, 0, 0, 0.2);
      }

      img {
        height: 30px;
        width: 30px;
      }

      p {
        @include p1;
      }
    }
  }

  .other_variants {
    margin-top: 10px;
    margin-left: auto;
    width: fit-content;
  }
}

.step_six {
  width: fit-content;

  // Header + footer = 155px, step's content = about 485px
  @media (min-height: 640px) and (min-width: 660px) {
    padding-top: calc(20px + ((100vh - 640px) / 2));
  }

  .list_of_variants {
    display: grid;
    gap: 20px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 1fr 1fr;
    width: 620px;

    @include for-custom(660) {
      grid-template-columns: 1fr;
      width: calc(100vw - 40px);
    }

    .variant {
      border-radius: 15px;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;

      &.active {
        background-color: $ham;
        border: 1px solid rgba(0, 0, 0, 0.2);
      }

      p:first-of-type {
        @include p1;
        font-size: 20px;
        line-height: 20px;
      }

      p:last-of-type {
        @include p1;
        opacity: 0.5;
      }
    }
  }
}

.step_seven {
  width: fit-content;

  // [2 columns] Header + footer = 155px, step's content = about 560px
  @media (min-height: 715px) {
    padding-top: calc(20px + ((100vh - 715px) / 2));
  }

  // [3 or 4 columns] Header + footer = 155px, step's content = about 440px
  @media (min-height: 595px) and (min-width: 471px) {
    padding-top: calc(20px + ((100vh - 595px) / 2));
  }

  // [5 columns] Header + footer = 155px, step's content = about 320px
  @media (min-height: 475px) and (min-width: 771px) {
    padding-top: calc(20px + ((100vh - 475px) / 2));
  }

  .step_title,
  .step_number,
  .step_hint {
    margin-left: 15px;
  }

  .list_of_variants {
    display: grid;
    margin-left: auto;
    margin-right: auto;
    gap: 20px;
    margin-top: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 730px;

    @include for-custom(770) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      width: 580px;
    }

    @include for-custom(620) {
      grid-template-columns: 1fr 1fr 1fr;
      width: 430px;
    }

    @include for-custom(470) {
      grid-template-columns: 1fr 1fr;
      width: 280px;
    }

    .variant {
      border-radius: 15px;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.2);
      width: 130px;
      height: 100px;
      display: flex;
      flex-direction: column;
      gap: 7.5px;
      align-items: center;
      justify-content: center;

      &.active {
        background-color: $ham;
        border: 1px solid rgba(0, 0, 0, 0.2);
      }

      img {
        height: 30px;
        width: 30px;
      }

      p {
        @include p1;
      }
    }
  }

  .other_variants {
    margin-top: 10px;
    margin-left: auto;
    width: fit-content;
  }
}

.step_eight {
  gap: 25px;

  // [Mobile-thiner] Header + footer = 155px, step's content = about 560px
  @media (min-height: 715px) {
    padding-top: calc(20px + ((100vh - 715px) / 2));
  }

  // [Mobile-wider] Header + footer = 155px, step's content = about 515px
  @media (min-height: 670px) and (min-width: 426px) {
    padding-top: calc(20px + ((100vh - 670px) / 2));
  }

  // [Desktop] Header + footer = 155px, step's content = about 485px
  @media (min-height: 640px) and (min-width: 1025px) {
    padding-top: calc(20px + ((100vh - 640px) / 2));
  }

  .summary_card {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 15px;

    .summary_card_body {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;
    }

    .summary_card_title {
      @include p1;
      font-size: 20px;
      color: black;
    }

    .summary_card_description {
      @include p1;
      color: black;
      opacity: 0.5;
    }

    .summary_card_edit {
      width: 25px;
      height: 25px;
      margin-left: 20px;
    }
  }
}

// Prevent selecting text: START
p,
h1,
h2,
h3 {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
// Prevent selecting text: STOP

// ANIMATIONS: START
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
// ANIMATIONS: STOP
</style>
