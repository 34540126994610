<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
    <path
      d="M10.75 5.83337C10.75 9.91671 6.25 13.4167 6.25 13.4167C6.25 13.4167 1.75 9.91671 1.75 5.83337C1.75 4.44099 2.22411 3.10563 3.06802 2.12106C3.91193 1.1365 5.05653 0.583374 6.25 0.583374C7.44347 0.583374 8.58807 1.1365 9.43198 2.12106C10.2759 3.10563 10.75 4.44099 10.75 5.83337Z"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.25 7.58337C7.07843 7.58337 7.75 6.79987 7.75 5.83337C7.75 4.86688 7.07843 4.08337 6.25 4.08337C5.42157 4.08337 4.75 4.86688 4.75 5.83337C4.75 6.79987 5.42157 7.58337 6.25 7.58337Z"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <!-- eslint-enable -->
</template>
