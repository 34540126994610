import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createTextVNode as _createTextVNode, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../assets/simple_arrow_bottom.svg'
import _imports_1 from '../assets/check.svg'
import _imports_2 from '../assets/chevron_right.png'
import _imports_3 from '../assets/chevron_left.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "additional_part" }
const _hoisted_3 = { class: "tracker" }
const _hoisted_4 = { class: "step_number" }
const _hoisted_5 = { class: "step_name" }
const _hoisted_6 = { class: "progress_bar" }
const _hoisted_7 = { class: "main_part_wrapper" }
const _hoisted_8 = {
  key: 0,
  class: "action_row no_padding_bottom hide_mobile"
}
const _hoisted_9 = { class: "mobile_nav_bar hide_desktop" }
const _hoisted_10 = {
  key: 1,
  class: "main_part_body"
}
const _hoisted_11 = { class: "main_part_body_content small_gaps" }
const _hoisted_12 = { id: "time_and_date" }
const _hoisted_13 = { class: "time_and_date_button_wrapper" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 1,
  style: {"height":"18px"}
}
const _hoisted_16 = { id: "address" }
const _hoisted_17 = {
  key: 0,
  class: "budget_per_head_info"
}
const _hoisted_18 = {
  key: 2,
  class: "main_part_body"
}
const _hoisted_19 = { class: "main_part_body_content" }
const _hoisted_20 = { class: "list_of_variants_with_title" }
const _hoisted_21 = { class: "list_of_variants" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "list_of_variants_with_title" }
const _hoisted_24 = { class: "list_of_variants" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { class: "list_of_variants_with_title" }
const _hoisted_27 = { class: "list_of_variants" }
const _hoisted_28 = ["onClick"]
const _hoisted_29 = {
  key: 3,
  class: "main_part_body"
}
const _hoisted_30 = { class: "main_part_body_content small_gaps no_margin_top" }
const _hoisted_31 = {
  key: 1,
  style: {"height":"20px"}
}
const _hoisted_32 = {
  key: 4,
  class: "main_part_body_confirmation"
}
const _hoisted_33 = {
  key: 5,
  class: "action_row"
}
const _hoisted_34 = {
  key: 6,
  class: "action_row centered"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_AddressChangeInput = _resolveComponent("AddressChangeInput")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_CurrencyInput = _resolveComponent("CurrencyInput")!
  const _component_app_text_accordion = _resolveComponent("app-text-accordion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Logo, { text: "" }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("b", _hoisted_4, "Step " + _toDisplayString(_ctx.step), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.stepName), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            class: "progress_bar_part",
            style: _normalizeStyle({ backgroundColor: _ctx.step > 0 ? 'black' : 'white' })
          }, null, 4),
          _createElementVNode("div", {
            class: "progress_bar_part",
            style: _normalizeStyle({ backgroundColor: _ctx.step > 1 ? 'black' : 'white' })
          }, null, 4),
          _createElementVNode("div", {
            class: "progress_bar_part",
            style: _normalizeStyle({ backgroundColor: _ctx.step > 2 ? 'black' : 'white' })
          }, null, 4)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (!_ctx.submitted)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "expander" }, null, -1)),
            _createVNode(_component_app_button, {
              class: "cancel_button",
              slim: "",
              white: "",
              onClick: _ctx.goBack,
              disabled: _ctx.loader
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createElementVNode("p", null, "Cancel", -1)
              ])),
              _: 1
            }, 8, ["onClick", "disabled"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_Logo),
        _createVNode(_component_app_button, {
          mini: "",
          square: "",
          white: "",
          onClick: _ctx.goBack,
          disabled: _ctx.loader
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconServices, { icon: "close" })
          ]),
          _: 1
        }, 8, ["onClick", "disabled"])
      ]),
      (_ctx.step === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _cache[16] || (_cache[16] = _createElementVNode("p", { class: "section_subtitle hide_desktop" }, "STEP 1/3", -1)),
            _cache[17] || (_cache[17] = _createElementVNode("h1", { class: "section_title" }, "Lets get started", -1)),
            _cache[18] || (_cache[18] = _createElementVNode("p", { class: "section_subtitle" }, "Tell us about your catering needs so we can help craft your perfect event", -1)),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _cache[11] || (_cache[11] = _createElementVNode("p", { class: "input_title" }, "Time and date", -1)),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_app_button, {
                    slim: "",
                    white: "",
                    onClick: _ctx.showCalendar,
                    class: _normalizeClass({ error_border: _ctx.yupErrMsg('dateTime') })
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(_ctx.didSetDateTime ? _ctx.formattedDate : 'Enter a date and time'), 1),
                      _cache[10] || (_cache[10] = _createElementVNode("img", {
                        src: _imports_0,
                        alt: ""
                      }, null, -1))
                    ]),
                    _: 1
                  }, 8, ["onClick", "class"]),
                  (_ctx.yupErrMsg('dateTime'))
                    ? (_openBlock(), _createElementBlock("small", _hoisted_14, [
                        _createElementVNode("b", null, _toDisplayString(_ctx.yupErrMsg('dateTime')), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_15)),
                  (_ctx.isCalendarVisible)
                    ? (_openBlock(), _createBlock(_component_Calendar, {
                        key: 2,
                        dateTime: _ctx.dateTime,
                        onConfirmClick: _ctx.onCalendarConfirmClick,
                        onClickOutside: _ctx.closeCalendar
                      }, null, 8, ["dateTime", "onConfirmClick", "onClickOutside"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _cache[12] || (_cache[12] = _createElementVNode("p", { class: "input_title" }, "Where is the event?", -1)),
                _createVNode(_component_AddressChangeInput, {
                  onChange: _ctx.onAddressChange,
                  placeholder: "Start typing an address...",
                  isAlwaysEditing: "",
                  isBasicFont: "",
                  showError: _ctx.show,
                  addErrorPlaceholderSpacing: ""
                }, _createSlots({ _: 2 }, [
                  (_ctx.yupErrMsg('address'))
                    ? {
                        name: "error",
                        fn: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.yupErrMsg('address')), 1)
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["onChange", "showError"])
              ]),
              _createElementVNode("div", null, [
                _cache[13] || (_cache[13] = _createElementVNode("p", { class: "input_title" }, "How many people?", -1)),
                _createVNode(_component_app_input, {
                  id: "headcount",
                  placeholder: "e.g. 10",
                  type: "number",
                  value: _ctx.headcount,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.headcount) = $event)),
                  showError: _ctx.show,
                  addErrorPlaceholderSpacing: ""
                }, _createSlots({ _: 2 }, [
                  (_ctx.yupErrMsg('headcount'))
                    ? {
                        name: "error",
                        fn: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.yupErrMsg('headcount')), 1)
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["value", "showError"])
              ]),
              _createElementVNode("div", null, [
                _cache[15] || (_cache[15] = _createElementVNode("p", { class: "input_title" }, "Budget?", -1)),
                _createVNode(_component_CurrencyInput, {
                  id: "budget",
                  modelValue: _ctx.budget,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.budget) = $event)),
                  options: {
                currency: 'GBP',
                precision: 2,
                hideCurrencySymbolOnFocus: false,
                locale: 'en-GB',
              },
                  showError: _ctx.show
                }, _createSlots({ _: 2 }, [
                  (_ctx.yupErrMsg('budget'))
                    ? {
                        name: "error",
                        fn: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.yupErrMsg('budget')), 1)
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["modelValue", "showError"]),
                (_ctx.headcount > 0 && _ctx.budget > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _cache[14] || (_cache[14] = _createElementVNode("img", {
                        src: _imports_1,
                        alt: "check"
                      }, null, -1)),
                      _createTextVNode(" This works out as £" + _toDisplayString(_ctx.budgetPerHead) + "/head ", 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.step === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            _cache[22] || (_cache[22] = _createElementVNode("p", { class: "section_subtitle hide_desktop" }, "STEP 2/3", -1)),
            _cache[23] || (_cache[23] = _createElementVNode("h1", { class: "section_title" }, "Now for the food", -1)),
            _cache[24] || (_cache[24] = _createElementVNode("p", { class: "section_subtitle" }, "This will help us find the perfect catering experience, crafted for you", -1)),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _cache[19] || (_cache[19] = _createElementVNode("p", { class: "input_title" }, "Type of food?", -1)),
                _createElementVNode("div", _hoisted_21, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typesOfFood, (value, key) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: key,
                      class: _normalizeClass(["variant custom-cursor-hover", { active: _ctx.typesOfFood[key] }]),
                      onClick: () => (_ctx.typesOfFood[key] = !_ctx.typesOfFood[key])
                    }, _toDisplayString(key), 11, _hoisted_22))
                  }), 128))
                ]),
                _createVNode(_component_app_text_accordion, {
                  title: "Other",
                  class: "other_types_of_food"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_app_input, {
                      value: _ctx.otherTypesOfFood,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.otherTypesOfFood) = $event)),
                      class: "animated slideOutDown"
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_23, [
                _cache[20] || (_cache[20] = _createElementVNode("p", { class: "input_title" }, "Service style?", -1)),
                _createElementVNode("div", _hoisted_24, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceStyles, (value, key) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: key,
                      class: _normalizeClass(["variant custom-cursor-hover", { active: _ctx.serviceStyles[key] }]),
                      onClick: () => (_ctx.serviceStyles[key] = !_ctx.serviceStyles[key])
                    }, _toDisplayString(key), 11, _hoisted_25))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_26, [
                _cache[21] || (_cache[21] = _createElementVNode("p", { class: "input_title" }, "Dietaries?", -1)),
                _createElementVNode("div", _hoisted_27, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dietaries, (value, key) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: key,
                      class: _normalizeClass(["variant custom-cursor-hover", { active: _ctx.dietaries[key] }]),
                      onClick: () => (_ctx.dietaries[key] = !_ctx.dietaries[key])
                    }, _toDisplayString(key), 11, _hoisted_28))
                  }), 128))
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.step === 3 && !_ctx.submitted)
        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
            _cache[31] || (_cache[31] = _createElementVNode("p", { class: "section_subtitle hide_desktop" }, "STEP 3/3", -1)),
            _cache[32] || (_cache[32] = _createElementVNode("h1", { class: "section_title" }, "Your information", -1)),
            _cache[33] || (_cache[33] = _createElementVNode("p", { class: "section_subtitle" }, " We’ll need some contact details to set up your account and reach out with catering options ", -1)),
            _createElementVNode("div", _hoisted_30, [
              (!_ctx.user)
                ? (_openBlock(), _createBlock(_component_app_button, {
                    key: 0,
                    class: "login_button",
                    slim: "",
                    onClick: _ctx.showAuthDialog
                  }, {
                    default: _withCtx(() => _cache[25] || (_cache[25] = [
                      _createElementVNode("p", null, [
                        _createTextVNode("Got an account? Log in "),
                        _createElementVNode("ins", null, "here")
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_31)),
              _createVNode(_component_app_input, {
                id: "firstName",
                value: _ctx.firstName,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.firstName) = $event)),
                showError: _ctx.show,
                disabled: !!_ctx.user?.firstName,
                addErrorPlaceholderSpacing: ""
              }, _createSlots({
                label: _withCtx(() => [
                  _cache[26] || (_cache[26] = _createTextVNode("Full name:"))
                ]),
                _: 2
              }, [
                (_ctx.yupErrMsg('firstName'))
                  ? {
                      name: "error",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.yupErrMsg('firstName')), 1)
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["value", "showError", "disabled"]),
              _createVNode(_component_app_input, {
                id: "email",
                value: _ctx.email,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.email) = $event)),
                showError: _ctx.show,
                disabled: !!_ctx.user?.email,
                addErrorPlaceholderSpacing: ""
              }, _createSlots({
                label: _withCtx(() => [
                  _cache[27] || (_cache[27] = _createTextVNode("Email address:"))
                ]),
                _: 2
              }, [
                (_ctx.yupErrMsg('email'))
                  ? {
                      name: "error",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.yupErrMsg('email')), 1)
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["value", "showError", "disabled"]),
              _createVNode(_component_app_input, {
                id: "phone",
                value: _ctx.phone,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.phone) = $event)),
                showError: _ctx.show,
                disabled: !!_ctx.user?.phone,
                addErrorPlaceholderSpacing: ""
              }, _createSlots({
                label: _withCtx(() => [
                  _cache[28] || (_cache[28] = _createTextVNode("Mobile number:"))
                ]),
                _: 2
              }, [
                (_ctx.yupErrMsg('phone'))
                  ? {
                      name: "error",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.yupErrMsg('phone')), 1)
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["value", "showError", "disabled"]),
              _createVNode(_component_app_input, {
                id: "companyName",
                value: _ctx.companyName,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.companyName) = $event)),
                showError: _ctx.show,
                disabled: !!_ctx.user?.companyName,
                addErrorPlaceholderSpacing: ""
              }, _createSlots({
                label: _withCtx(() => [
                  _cache[29] || (_cache[29] = _createTextVNode("Company name:"))
                ]),
                _: 2
              }, [
                (_ctx.yupErrMsg('companyName'))
                  ? {
                      name: "error",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.yupErrMsg('companyName')), 1)
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["value", "showError", "disabled"]),
              (!_ctx.user)
                ? (_openBlock(), _createBlock(_component_app_input, {
                    key: 2,
                    id: "password",
                    type: "password",
                    value: _ctx.password,
                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.password) = $event)),
                    showError: _ctx.show,
                    addErrorPlaceholderSpacing: ""
                  }, _createSlots({
                    label: _withCtx(() => [
                      _cache[30] || (_cache[30] = _createTextVNode("Create a password:"))
                    ]),
                    _: 2
                  }, [
                    (_ctx.yupErrMsg('password'))
                      ? {
                          name: "error",
                          fn: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.yupErrMsg('password')), 1)
                          ]),
                          key: "0"
                        }
                      : undefined
                  ]), 1032, ["value", "showError"]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.step === 3 && _ctx.submitted)
        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
            _createVNode(_component_IconServices, {
              icon: "sunInSunglasses",
              class: "sun"
            }),
            _cache[34] || (_cache[34] = _createStaticVNode("<h1 class=\"section_title\" data-v-177da31c>We&#39;re looking <br class=\"hide_desktop\" data-v-177da31c>over your criteria</h1><p class=\"what_happens_next_question\" data-v-177da31c>What happens next?</p><div class=\"what_happens_next_bullets\" data-v-177da31c><div class=\"what_happens_next_bullet\" data-v-177da31c><div class=\"img_wrapper\" data-v-177da31c><img src=\"" + _imports_2 + "\" alt=\"\" data-v-177da31c></div><p data-v-177da31c>Our team are cooking up some options for you to choose from</p></div><div class=\"what_happens_next_bullet\" data-v-177da31c><div class=\"img_wrapper\" data-v-177da31c><img src=\"" + _imports_2 + "\" alt=\"\" data-v-177da31c></div><p data-v-177da31c>We&#39;ll send you options via email within 24 hours</p></div><div class=\"what_happens_next_bullet\" data-v-177da31c><div class=\"img_wrapper\" data-v-177da31c><img src=\"" + _imports_2 + "\" alt=\"\" data-v-177da31c></div><p data-v-177da31c>Sit back and relax - we&#39;ve got this</p></div></div>", 3))
          ]))
        : _createCommentVNode("", true),
      (!_ctx.submitted)
        ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
            (_ctx.step > 1)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 0,
                  class: "back_button",
                  slim: "",
                  white: "",
                  onClick: _ctx.goToPreviousStep,
                  disabled: _ctx.loader
                }, {
                  default: _withCtx(() => _cache[35] || (_cache[35] = [
                    _createElementVNode("img", {
                      src: _imports_3,
                      alt: ""
                    }, null, -1),
                    _createElementVNode("p", null, "Back", -1)
                  ])),
                  _: 1
                }, 8, ["onClick", "disabled"]))
              : _createCommentVNode("", true),
            _cache[38] || (_cache[38] = _createElementVNode("div", { class: "expander" }, null, -1)),
            (_ctx.step < 3)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 1,
                  class: "next_button",
                  slim: "",
                  onClick: _ctx.goToNextStep,
                  disabled: _ctx.loader
                }, {
                  default: _withCtx(() => _cache[36] || (_cache[36] = [
                    _createElementVNode("p", null, "Next step", -1),
                    _createElementVNode("img", {
                      src: _imports_2,
                      alt: ""
                    }, null, -1)
                  ])),
                  _: 1
                }, 8, ["onClick", "disabled"]))
              : _createCommentVNode("", true),
            (_ctx.step === 3)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 2,
                  class: "submit_button",
                  slim: "",
                  onClick: _ctx.submit,
                  loader: _ctx.loader,
                  disabled: _ctx.loader
                }, {
                  default: _withCtx(() => _cache[37] || (_cache[37] = [
                    _createElementVNode("p", null, "Submit", -1),
                    _createElementVNode("img", {
                      src: _imports_2,
                      alt: ""
                    }, null, -1)
                  ])),
                  _: 1
                }, 8, ["onClick", "loader", "disabled"]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_34, [
            _createVNode(_component_app_button, {
              class: "back_to_homepage_button",
              slim: "",
              onClick: _ctx.planNewEvent,
              disabled: _ctx.loader
            }, {
              default: _withCtx(() => _cache[39] || (_cache[39] = [
                _createElementVNode("p", null, "Plan a new event", -1)
              ])),
              _: 1
            }, 8, ["onClick", "disabled"]),
            _createVNode(_component_app_button, {
              class: "back_to_homepage_button",
              slim: "",
              white: "",
              onClick: _ctx.goBack,
              disabled: _ctx.loader
            }, {
              default: _withCtx(() => _cache[40] || (_cache[40] = [
                _createElementVNode("p", null, "Back to homepage", -1)
              ])),
              _: 1
            }, 8, ["onClick", "disabled"])
          ]))
    ])
  ]))
}