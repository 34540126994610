<template>
  <div class="card custom-cursor-hover">
    <img class="card-logo" :src="restaurant.logo" alt="restaurant.logo" />
    <div class="card-image">
      <img :src="restaurant.cover" :alt="restaurant.name" />
      <div class="shadow" />
      <div class="time-label" v-if="restaurant.deliveryLabel && orderType === 'delivery'">
        <p>
          <b>{{ restaurant.deliveryLabel.split(' ')[0] }}</b> {{ restaurant.deliveryLabel.split(' ')[1] }}
        </p>
      </div>
      <div class="time-label" v-if="restaurant.collectionLabel && orderType === 'collection'">
        <p>
          <b>{{ restaurant.collectionLabel.split(' ')[0] }}</b> {{ restaurant.collectionLabel.split(' ')[1] }}
        </p>
      </div>
    </div>
    <hr />
    <div class="card-content">
      <h3 class="restaurant-name">{{ restaurant.name }}</h3>
      <div class="card-info">
        <h4 :class="openingInfoLabelColor">{{ restaurant.openingLabel }}</h4>
        <span>|</span>
        <h4>{{ feeOrDistanceInfo }}</h4>
      </div>
      <h4 v-if="restaurant.shortDescription" class="info-short-description">'{{ restaurant.shortDescription }}'</h4>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  restaurant = prop<FullInfoRestaurant>({ required: true });
}

@Options({
  props: {
    restaurant: Object,
  },
})
export default class RestaurantCard extends Vue.with(Props) {
  get openingInfoLabelColor() {
    if (this.restaurant.isTemporarilyClose || this.restaurant.willBeOpenLaterToday) {
      return 'restaurantOpeningSoon';
    }

    if (this.restaurant.isClosed) {
      return 'restaurantClosed';
    }

    return 'restaurantOpen';
  }

  get feeOrDistanceInfo() {
    if (this.$store.state.order.type === 'delivery') {
      return this.restaurant.deliveryInfo.fee === 0
        ? 'Free delivery'
        : `£${this.restaurant.deliveryInfo.fee.toFixed(2)} delivery`;
    }

    return this.restaurant.walkDistanceInMinutes > 1
      ? `${this.restaurant.walkDistanceInMinutes} mins away`
      : `${this.restaurant.walkDistanceInMinutes} min away`;
  }

  get orderType() {
    return this.$store.state.order.type;
  }
}
</script>

<style lang="scss" scoped>
hr {
  border: 0.5px solid black;
  margin: 0;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px black solid;
  border-radius: 10px;
  width: 100%;

  max-width: 380px;
  @include for-custom(1216) {
    max-width: 580px;
  }
  @include for-custom(816) {
    max-width: unset;
  }

  &:hover {
    transform: scale(1.01);
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &-logo {
    z-index: 10;
    position: absolute;
    top: 1rem;
    left: 1rem;
    aspect-ratio: 1;
    width: 15%;
    border-radius: 50%;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: $white;
  }

  &-image {
    position: relative;
    width: 100%;
    height: 280px;

    img {
      object-fit: cover;
      width: 100%;
      height: 280px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .shadow {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(37, 36, 36, 0.53) 0%, rgba(37, 36, 36, 0) 31.77%);
      border-radius: 10px;
    }

    .time-label {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0.75rem;
      right: 0.75rem;
      height: 30px;
      padding: 0 10px;
      border-radius: 20px;
      background-color: white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

      p {
        font-family:
          Sofia Pro,
          sans-serif;
        font-size: 12px;
        line-height: 1;

        b {
          font-size: 16px;
        }
      }
    }
  }

  &-content {
    width: 100%;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.restaurant-name {
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  font-size: 20px;

  @include for-custom(1216) {
    font-size: 22px;
  }

  @include for-smartphone {
    font-size: 18px;
    line-height: 20px;
  }
}

.card-info {
  display: flex;
  align-items: center;
  gap: 5px;

  h4 {
    font-family:
      Sofia Pro,
      sans-serif;
    font-weight: 600;
    font-size: 18px;
    white-space: nowrap;

    @include for-smartphone {
      font-size: 16px;
    }
  }

  span {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.info-short-description {
  font-family:
    Sofia Pro,
    sans-serif;
  font-weight: 300;
  font-size: 16px;
  white-space: nowrap;
  font-style: italic;

  @include for-smartphone {
    font-size: 14px;
    line-height: 16px;
  }
}

.restaurantClosed {
  color: $restaurantClosed;
}

.restaurantOpeningSoon {
  color: $restaurantOpeningSoon;
}

.restaurantOpen {
  color: $restaurantOpen;
}
</style>
