import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "restaurant-about-wrapper" }
const _hoisted_2 = { class: "restaurant-about" }
const _hoisted_3 = { class: "restaurant-about-title" }
const _hoisted_4 = { class: "restaurant-about-text" }
const _hoisted_5 = { class: "restaurant-about-dialog-buttons" }
const _hoisted_6 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateGroupOrderButton = _resolveComponent("CreateGroupOrderButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.restaurant.name), 1),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "divider grape full-width" }, null, -1))
      ]),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.restaurant.description), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: "restaurant-about-button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showRestaurantProfile && _ctx.showRestaurantProfile(...args)))
        }, "Restaurant profile"),
        _createElementVNode("button", {
          class: "restaurant-about-button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showOpeningHours && _ctx.showOpeningHours(...args)))
        }, "Opening hours")
      ]),
      (_ctx.cityName && !_ctx.groupData && !_ctx.amendableData && !_ctx.restaurant?.isTemporarilyClose && !_ctx.restaurant?.isClosed)
        ? (_openBlock(), _createBlock(_component_CreateGroupOrderButton, {
            key: 0,
            restaurant: _ctx.restaurant,
            wholeMenuMinHoursInAdvance: _ctx.wholeMenuMinHoursInAdvance,
            forceLongText: true
          }, null, 8, ["restaurant", "wholeMenuMinHoursInAdvance"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("img", {
      class: "restaurant-about-cover-img",
      src: _ctx.restaurant.cover,
      alt: `${_ctx.restaurant.name}_cover`
    }, null, 8, _hoisted_6)
  ]))
}