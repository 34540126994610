import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../../assets/landing/built_for_ease_bullet_point.png'
import _imports_1 from '../../assets/landing/built_for_ease_apple_logo.png'
import _imports_2 from '../../assets/landing/built_for_ease_bango_logo.png'
import _imports_3 from '../../assets/landing/built_for_ease_adder_logo.png'


const _hoisted_1 = { class: "built_for_ease_section" }
const _hoisted_2 = { class: "built_for_ease_card_body" }
const _hoisted_3 = { class: "crew_buttons" }
const _hoisted_4 = {
  key: 0,
  class: "bullet_points"
}
const _hoisted_5 = {
  key: 1,
  class: "bullet_points"
}
const _hoisted_6 = {
  key: 2,
  class: "bullet_points"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Slide = _resolveComponent("Slide")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, { title: "The bottom line" }, {
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[11] || (_cache[11] = _createElementVNode("h1", { class: "section_title" }, "Built for ease", -1)),
          _cache[12] || (_cache[12] = _createElementVNode("h2", { class: "sub_title" }, "Foodstuff make it easy for the whole crew", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_button, {
              slim: "",
              onClick: _cache[0] || (_cache[0] = () => (_ctx.activeBuiltForEaseOption = 'office_managers')),
              white: _ctx.activeBuiltForEaseOption !== 'office_managers'
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createElementVNode("p", null, "Office managers", -1)
              ])),
              _: 1
            }, 8, ["white"]),
            _createVNode(_component_app_button, {
              slim: "",
              onClick: _cache[1] || (_cache[1] = () => (_ctx.activeBuiltForEaseOption = 'finance_teams')),
              white: _ctx.activeBuiltForEaseOption !== 'finance_teams'
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("p", null, "Finance teams", -1)
              ])),
              _: 1
            }, 8, ["white"]),
            _createVNode(_component_app_button, {
              slim: "",
              onClick: _cache[2] || (_cache[2] = () => (_ctx.activeBuiltForEaseOption = 'directors')),
              white: _ctx.activeBuiltForEaseOption !== 'directors'
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createElementVNode("p", null, "CEO/directors", -1)
              ])),
              _: 1
            }, 8, ["white"])
          ]),
          (_ctx.activeBuiltForEaseOption === 'office_managers')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[8] || (_cache[8] = [
                _createElementVNode("div", { class: "bullet_point_row" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }),
                  _createElementVNode("div", { class: "bullet_point_texts" }, [
                    _createElementVNode("b", null, "Variety and knock-out flavours"),
                    _createElementVNode("p", null, "One place to cover all dietary needs and preferences")
                  ])
                ], -1),
                _createElementVNode("div", { class: "bullet_point_row" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }),
                  _createElementVNode("div", { class: "bullet_point_texts" }, [
                    _createElementVNode("b", null, "Dependable and flexible"),
                    _createElementVNode("p", null, "Whether you’re booking ahead or spinning up last minute plans we’ll hit the mark")
                  ])
                ], -1),
                _createElementVNode("div", { class: "bullet_point_row" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }),
                  _createElementVNode("div", { class: "bullet_point_texts" }, [
                    _createElementVNode("b", null, "We’ll take the load off"),
                    _createElementVNode("p", null, "Our team are always on hand to take things off your desk")
                  ])
                ], -1)
              ])))
            : _createCommentVNode("", true),
          (_ctx.activeBuiltForEaseOption === 'finance_teams')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[9] || (_cache[9] = [
                _createElementVNode("div", { class: "bullet_point_row" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }),
                  _createElementVNode("div", { class: "bullet_point_texts" }, [
                    _createElementVNode("b", null, "Flexible payment methods"),
                    _createElementVNode("p", null, "We’ll fit with how you like teams to run food through the books")
                  ])
                ], -1),
                _createElementVNode("div", { class: "bullet_point_row" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }),
                  _createElementVNode("div", { class: "bullet_point_texts" }, [
                    _createElementVNode("b", null, "Speedy support from humans"),
                    _createElementVNode("p", null, "Our finance team is waiting to help")
                  ])
                ], -1),
                _createElementVNode("div", { class: "bullet_point_row" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }),
                  _createElementVNode("div", { class: "bullet_point_texts" }, [
                    _createElementVNode("b", null, "We’ll follow PO and procurement asks"),
                    _createElementVNode("p", null, "Let us know if you need us on your system")
                  ])
                ], -1)
              ])))
            : _createCommentVNode("", true),
          (_ctx.activeBuiltForEaseOption === 'directors')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[10] || (_cache[10] = [
                _createElementVNode("div", { class: "bullet_point_row" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }),
                  _createElementVNode("div", { class: "bullet_point_texts" }, [
                    _createElementVNode("b", null, "Get teams back in the office"),
                    _createElementVNode("p", null, "Creativity sparks when food brings you together")
                  ])
                ], -1),
                _createElementVNode("div", { class: "bullet_point_row" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }),
                  _createElementVNode("div", { class: "bullet_point_texts" }, [
                    _createElementVNode("b", null, "Advanced perks are important"),
                    _createElementVNode("p", null, "Retains teams and keeps them happy")
                  ])
                ], -1),
                _createElementVNode("div", { class: "bullet_point_row" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }),
                  _createElementVNode("div", { class: "bullet_point_texts" }, [
                    _createElementVNode("b", null, "Clients are bored of sandwiches"),
                    _createElementVNode("p", null, "Food will get them coming back to see you again and again")
                  ])
                ], -1)
              ])))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: "hide_mobile want_to_learn_more custom-cursor-hover",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onHereClick && _ctx.onHereClick(...args)))
        }, _cache[13] || (_cache[13] = [
          _createElementVNode("p", null, [
            _createTextVNode("Want to learn more? Chat to one of our team "),
            _createElementVNode("ins", null, "here")
          ], -1)
        ])),
        _createElementVNode("div", {
          class: "hide_desktop want_to_learn_more custom-cursor-hover",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onHereClick && _ctx.onHereClick(...args)))
        }, _cache[14] || (_cache[14] = [
          _createElementVNode("p", null, [
            _createTextVNode("Chat to one of our team "),
            _createElementVNode("ins", null, "here")
          ], -1)
        ]))
      ]),
      _: 1
    }),
    _createVNode(_component_Carousel, {
      class: "quotes_carousel",
      "wrap-around": true
    }, {
      addons: _withCtx(() => [
        _createVNode(_component_Pagination)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Slide, { key: "spotify" }, {
          default: _withCtx(() => _cache[15] || (_cache[15] = [
            _createElementVNode("div", { class: "quotes_carousel_slide" }, [
              _createElementVNode("p", null, "“Food was amazing, great variety and spot on recommendations - you guys are professionals!”"),
              _createElementVNode("p", null, "-Rosche"),
              _createElementVNode("img", {
                src: _imports_1,
                alt: "",
                style: {"height":"55px"}
              })
            ], -1)
          ])),
          _: 1
        }),
        _createVNode(_component_Slide, { key: "bango" }, {
          default: _withCtx(() => _cache[16] || (_cache[16] = [
            _createElementVNode("div", { class: "quotes_carousel_slide" }, [
              _createElementVNode("p", null, " “It arrived perfectly in time and it was delicious. We really enjoyed it! I am sure we will be ordering again!!” "),
              _createElementVNode("p", null, "-Ellis"),
              _createElementVNode("img", {
                src: _imports_2,
                alt: "",
                style: {"height":"44px"}
              })
            ], -1)
          ])),
          _: 1
        }),
        _createVNode(_component_Slide, { key: "adder" }, {
          default: _withCtx(() => _cache[17] || (_cache[17] = [
            _createElementVNode("div", { class: "quotes_carousel_slide" }, [
              _createElementVNode("p", null, " “I had a number of people declare the food the best lunch they’ve ever had here so that’s a huge box tick for me!” "),
              _createElementVNode("p", null, "-Janice"),
              _createElementVNode("img", {
                src: _imports_3,
                alt: "",
                style: {"height":"55px"}
              })
            ], -1)
          ])),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}