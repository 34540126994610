<template>
  <div class="how_it_works_section">
    <h1 class="section_title">{{ title }}</h1>
    <h2 class="sub_title">The easiest way to feed your team</h2>
    <div class="how_it_works_steps">
      <div class="how_it_works_step">
        <img src="../../assets/landing/how_it_works_slot.png" alt="" />
        <p>Choose your city and delivery date - on the day or <b>up to 90 days in advance</b></p>
      </div>
      <div class="how_it_works_step">
        <img src="../../assets/landing/how_it_works_pear.png" alt="" />
        <slot name="pick-from" />
      </div>
      <div class="how_it_works_step">
        <img src="../../assets/landing/how_it_works_globe.png" alt="" />
        <p>Place your order and we’ll do the rest. <b>Invoicing, order tracking and emission-free delivery</b></p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({ props: { title: String } })
export default class HowItWorks extends Vue {}
</script>

<style lang="scss" scoped>
.section_title {
  color: $coal100;
  font-family: Recoleta, sans-serif;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 10px;

  @include for-smartphone {
    font-size: 38px;
    line-height: 40px;
  }
}

.sub_title {
  font-family:
    Sofia Pro,
    sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 300;
  text-align: center;
  color: black;
  padding: 0px 30px;
}

.how_it_works_section {
  padding: 50px 70px 100px 70px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-top: 2px solid $coal100;

  @include for-smartphone {
    padding: 50px 30px 100px 30px;
  }

  .how_it_works_steps {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;

    @include for-smartphone {
      flex-direction: column;
      gap: 50px;
    }
  }

  .how_it_works_step {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 25vw;

    @include for-smartphone {
      width: 100%;
      gap: 25px;
    }

    p,
    b {
      font-family:
        Sofia Pro,
        sans-serif;
      font-size: 19px;
      line-height: 25px;
      color: black;
      text-align: center;
    }

    p {
      font-weight: 300;
    }

    img {
      height: 220px;
      object-fit: contain;
    }
  }
}
</style>
