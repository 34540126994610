<template>
  <div class="order-type">
    <button @click="handleOrderTypeClick('delivery')" class="order-type-btn" :class="{ active: type === 'delivery' }">
      Delivery
    </button>
    <button
      @click="handleOrderTypeClick('collection')"
      class="order-type-btn"
      :class="{ active: type === 'collection' }"
    >
      Collection
    </button>
  </div>
</template>

<script lang="ts">
import { watch } from 'vue';
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  onChange = prop<Function>({ required: false, default: undefined });
}

@Options({
  props: {
    onChange: Function,
  },
})
export default class OrderTypeSwitch extends Vue.with(Props) {
  private type: 'collection' | 'delivery' = 'delivery';

  private handleOrderTypeClick(type: 'delivery' | 'collection') {
    if (this.onChange) {
      this.onChange(type);
    } else {
      this.$store.commit('order/type', type);
    }

    this.type = type;
    this.$store.commit('basket/coupon', null);
  }

  mounted() {
    this.type = this.$store.state.order.type;

    watch(
      () => this.$store.state.order.type,
      (type: 'delivery' | 'collection') => {
        this.type = type;
      },
    );
  }
}
</script>

<style lang="scss" scoped>
.order-type {
  border-radius: 5px;
  border: 1px solid $coal20;
  background-color: white;
  padding: 4px;

  &-btn {
    @include p1;
    padding: 10px;
    width: 90px;
    color: $coal100;

    &.active {
      background-color: $ham;
      border-radius: 5px;
      border: 0.5px solid #a88486;
    }
  }
}
</style>
