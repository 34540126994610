<template>
  <nav id="categories" class="categories-bar" v-if="categories.length > 0">
    <app-button
      anchor
      :id="`anchor-${category.slug}`"
      v-for="(category, cIndex) in categories"
      @click.prevent="selectCategories(category.slug)"
      :key="cIndex"
    >
      {{ category.name }}
    </app-button>
  </nav>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  categories = prop<MenuCategory[]>({ required: true });
}

@Options({
  props: {
    categories: {
      type: Array,
      default: [],
    },
  },
})
export default class CategoriesBar extends Vue.with(Props) {
  private selectedCategorySlug: string | undefined;

  private selectCategories(slug: string) {
    const el: HTMLDivElement = document.getElementById(slug) as HTMLDivElement;
    window.scrollTo({ top: el.offsetTop - 65, behavior: 'smooth' });
    this.selectedCategorySlug = slug;
  }

  private activateOrDeactivate(slug: string) {
    const diff = 65;

    const menu: HTMLElement = document.getElementById(slug) as HTMLElement;
    const categories: HTMLElement = document.getElementById('categories') as HTMLElement;
    const anchor: HTMLElement = document.getElementById(`anchor-${slug}`) as HTMLElement;
    if (menu && categories && anchor) {
      const { top } = menu.getBoundingClientRect();
      const { bottom } = menu.getBoundingClientRect();

      if (top <= diff + 25 && bottom > diff) {
        if (anchor.classList.contains('active')) return;

        anchor.classList.add('active');

        // Enforce scrolling if browsing menu, but not if clicked category in the navbar:
        if (!this.selectedCategorySlug) {
          categories.scrollTo({ left: anchor.offsetLeft - 10, behavior: 'smooth' });
        }

        // If reached slug that is selected, selected slug's value can be reset:
        if (slug === this.selectedCategorySlug) this.selectedCategorySlug = undefined;
      } else {
        anchor.classList.remove('active');
      }
    }
  }

  private offset() {
    const navbar: HTMLElement = document.getElementById('categories') as HTMLElement;

    const scrollMeasure: HTMLElement | null = document.getElementById('scroll-measure');
    const scrollHeight = scrollMeasure?.getBoundingClientRect().top;

    if (navbar && scrollHeight) {
      if (scrollHeight < 0) navbar.classList.add('is-pinned');
      else navbar.classList.remove('is-pinned');
    }
  }

  mounted() {
    document.addEventListener('scroll', this.offset);
    this.categories.forEach((element) => {
      document.addEventListener('scroll', () => this.activateOrDeactivate(element.slug));
    });
  }

  unmounted() {
    document.removeEventListener('scroll', this.offset);
    this.categories.forEach((element) => {
      document.removeEventListener('scroll', () => this.activateOrDeactivate(element.slug));
    });
  }
}
</script>

<style lang="scss" scoped>
nav.categories-bar {
  display: none;
  position: sticky;
  z-index: 10;
  top: -1px;
  background-color: $vanilla;
  justify-content: flex-start;
  padding: 0 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid $coal20;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: all 1s ease-in-out;
  gap: 5px;

  &::-webkit-scrollbar {
    display: none;
  }

  .controller-button {
    @include p1;
    max-width: unset;
  }

  &.is-pinned {
    display: flex;
    flex-wrap: nowrap;
  }
}
</style>
