<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      class="fillYolk"
      d="M8.53978 4.83518L11.2114 1.69132L9.888 5.55514L13.8497 4.60811L10.6235 7.07179L14.3341 8.59439L10.3233 8.61608L12.4132 11.9986L9.09467 9.63648L8.87431 13.7147L7.55347 9.90928L4.90327 12.9428L6.11639 9.05757L2.15465 10.0046L5.46979 7.67266L1.64885 6.12863L5.76997 6.12837L3.59123 2.61406L6.90972 4.97622L7.24039 0.919424L8.53978 4.83518Z"
    />
  </svg>
  <!-- eslint-enable -->
</template>

<style lang="scss" scoped>
.fillYolk {
  fill: $yolk;
}
</style>
