<template>
  <app-modal class="new-basket-modal" v-bind="options">
    <h2>Create new basket?</h2>
    <p>
      This will clear your existing basket with
      <b class="ws-nw">{{ data.oldRestaurantName }}</b>
      and start a new basket with
      <b class="ws-nw">{{ data.newRestaurant.name }}</b
      >.
    </p>
    <div class="new-basket-modal-button">
      <app-button @click.prevent="close">No</app-button>
      <app-button @click.prevent="yes">Yes</app-button>
    </div>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

import methods from '@/utils/methods';

@Options({
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class ModalNewBasket extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: true,
  };

  private yes() {
    const { newRestaurant } = this.$store.state.modals.data;

    this.$store.commit('basket/clear');

    this.$store.commit('basket/restaurant', methods.filterRestaurantInfo(newRestaurant));

    if (!newRestaurant.allowScheduledOrders) {
      this.$store.commit('order/scheduledDeliveryInterval', null);
      this.$store.commit('order/scheduledDeliveryDate', null);
    }
    if (!newRestaurant.clickAndCollect) {
      this.$store.commit('order/type', 'delivery');
    }

    this.$store.commit('basket/coupon', null);
    this.$store.commit('basket/add', this.$store.state.modals.data.dish);
    this.close();
  }

  private close() {
    this.$store.commit('modals/close');
  }
}
</script>

<style lang="scss">
.new-basket-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .modal {
    max-width: 425px;
    width: 425px;
  }
  &-button {
    display: flex;
    .controller-button {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
</style>
