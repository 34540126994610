import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "reset-password" }
const _hoisted_2 = { class: "reset-password-button" }
const _hoisted_3 = {
  key: 0,
  class: "successful"
}
const _hoisted_4 = {
  key: 1,
  class: "invalid-code"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("h1", null, "Reset password", -1)),
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "divider ham full-width" }, null, -1)),
    _createVNode(_component_app_input, {
      type: "password",
      value: _ctx.password.newPassword,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password.newPassword) = $event)),
      id: "new-password",
      placeholder: "",
      showError: _ctx.show
    }, _createSlots({
      label: _withCtx(() => [
        _cache[2] || (_cache[2] = _createTextVNode("New password"))
      ]),
      _: 2
    }, [
      (_ctx.yupErrMsg('newPassword'))
        ? {
            name: "error",
            fn: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.yupErrMsg('newPassword')), 1)
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["value", "showError"]),
    _createVNode(_component_app_input, {
      type: "password",
      value: _ctx.password.newPasswordConfirm,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password.newPasswordConfirm) = $event)),
      id: "repeat-new-password",
      placeholder: "",
      showError: _ctx.show
    }, _createSlots({
      label: _withCtx(() => [
        _cache[3] || (_cache[3] = _createTextVNode("Repeat new password"))
      ]),
      _: 2
    }, [
      (_ctx.yupErrMsg('newPasswordConfirm'))
        ? {
            name: "error",
            fn: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.yupErrMsg('newPasswordConfirm')), 1)
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["value", "showError"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_button, {
        loader: _ctx.loader,
        slim: "",
        onClick: _ctx.update
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("b", null, "Reset", -1)
        ])),
        _: 1
      }, 8, ["loader", "onClick"]),
      (_ctx.successful)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Successful!"))
        : _createCommentVNode("", true),
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}