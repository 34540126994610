/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state: StateBasket = {
  dishes: [],
  tip: 0,
  coupon: null,
  restaurant: null,
  isTooFar: false,
};

const mutations = {
  // Items stuff: START
  add(state: StateBasket, upcomingDish: Product) {
    const upcomingDishModifiers = upcomingDish.modificators
      .flatMap((modCategory) => modCategory.options)
      .filter((o) => o.amount > 0)
      .sort((a, b) => {
        if (a.id > b.id) return 1;
        if (a.id === b.id) return 0;
        return -1;
      });

    // Look for matching dish:
    const dishes = JSON.parse(JSON.stringify(state.dishes)) as OrderDish[];
    const sameDish = dishes.find((dish) => {
      // Ignore products with not-matching ids:
      if (dish.id !== upcomingDish.id) return false;

      // Ignore products with different notes:
      if ((upcomingDish.note || dish.note) && dish.note !== upcomingDish.note) return false;

      // Ignore products with different number of modifiers:
      const options = dish.modificators.filter((e) => e.amount > 0);
      if (options.length !== upcomingDishModifiers.length) return false;

      // Ignore products with different modifiers:
      options.sort((a, b) => {
        if (a.id > b.id) return 1;
        if (a.id === b.id) return 0;
        return -1;
      });
      for (let i = 0; i < upcomingDishModifiers.length; i += 1) {
        if (upcomingDishModifiers[i].id !== options[i].id) {
          return false;
        }
      }

      // If we got here that means that we already have same product with
      // same options in the basket and we just need to update the amount:
      return true;
    });

    if (sameDish) {
      sameDish.amount += upcomingDish.amount;
      state.dishes = dishes;
    } else {
      state.dishes.push({
        id: upcomingDish.id,
        name: upcomingDish.name,
        amount: upcomingDish.amount,
        price: upcomingDish.discountedPrice ?? upcomingDish.price,
        isAlcohol: upcomingDish.isAlcohol,
        note: upcomingDish.note,
        modificators: upcomingDish.modificators
          .flatMap((m) => m.options)
          .map((o) => ({
            id: o.id,
            name: o.name,
            price: o.price,
            amount: o.amount,
          }))
          .filter((o) => o.amount > 0),
      });
    }
  },
  remove(state: StateBasket, index: number) {
    state.dishes.splice(index, 1);
  },
  clear(state: StateBasket) {
    state.coupon = null;
    state.dishes = [];
    state.tip = 0;
    state.restaurant = null;
    state.isTooFar = false;
  },
  changeAmount(state: StateBasket, payload: { amount: number; index: number }) {
    const dish: OrderDish = state.dishes[payload.index];
    dish.amount = payload.amount;
    state.dishes.splice(payload.index, 1, dish);
  },
  setDishes(state: StateBasket, payload: OrderDish[]) {
    state.dishes = payload;
  },
  // Items stuff: STOP

  // Active restaurant stuff: START
  restaurant(state: StateBasket, payload: ShortInfoRestaurant) {
    state.restaurant = payload;
  },
  isTooFar(state: StateBasket, payload: boolean) {
    state.isTooFar = payload;
  },
  // Active restaurant stuff: STOP

  // Prices stuff: START
  tip(state: StateBasket, payload: number) {
    state.tip = payload;
  },
  coupon(state: StateBasket, payload: any) {
    state.coupon = payload;
  },
  // Prices stuff: STOP
};

const actions = {
  //
};

const getters = {
  //
};

export interface StateBasket {
  dishes: OrderDish[];
  tip: number;
  coupon: Coupon | null;
  restaurant: ShortInfoRestaurant | null;
  isTooFar: boolean;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
