import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_option = _resolveComponent("app-option")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "set-filters-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _createVNode(_component_app_button, {
        class: "set-filters-modal-close",
        mini: "",
        white: "",
        onClick: _withModifiers(_ctx.close, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconServices, {
            class: "services-icon",
            icon: "add",
            close: ""
          })
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createElementVNode("h2", null, _toDisplayString(_ctx.data.title), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.variants, (variant) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "set-filters-row",
          key: variant.id
        }, [
          _createVNode(_component_app_option, {
            active: _ctx.isActive(variant),
            type: "checkbox",
            onClick: _withModifiers(($event: any) => (_ctx.select(variant)), ["prevent"])
          }, null, 8, ["active", "onClick"]),
          _createElementVNode("p", null, _toDisplayString(variant.name), 1)
        ]))
      }), 128)),
      _createVNode(_component_app_button, {
        class: "apply",
        slim: "",
        square: "",
        onClick: _ctx.apply
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("p", null, "Apply", -1)
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 16))
}