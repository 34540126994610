import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modification-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "modification-required"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "modification" }
const _hoisted_5 = { class: "modification-title us-n" }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_option = _resolveComponent("app-option")!
  const _component_app_count = _resolveComponent("app-count")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, [
      _createTextVNode(_toDisplayString(_ctx.modification.name) + " ", 1),
      (_ctx.modification.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "required"))
        : _createCommentVNode("", true)
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "divider ham full-width" }, null, -1)),
    (_ctx.modification.limitMax > 1 && _ctx.modification.required)
      ? (_openBlock(), _createElementBlock("small", _hoisted_3, " select " + _toDisplayString(_ctx.modification.limitMin === _ctx.modification.limitMax ? _ctx.modification.limitMin : `at least ${_ctx.modification.limitMin}`) + " " + _toDisplayString(_ctx.modification.limitMin === 1 ? 'modification' : 'modifications'), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAvailableOptions(_ctx.modification.options), (option, oIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "modification-item",
          key: oIndex
        }, [
          _createVNode(_component_app_option, {
            disabled: !_ctx.isEnabled,
            active: option.amount > 0,
            type: _ctx.isRadioButton ? 'radio' : 'checkbox',
            onClick: _withModifiers(($event: any) => (_ctx.select(option)), ["prevent"])
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_5, [
                _createTextVNode(_toDisplayString(option.name) + " ", 1),
                (option.price > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, " +" + _toDisplayString(option.price.toFixed(2)), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1032, ["disabled", "active", "type", "onClick"]),
          (option.isMultiSelect && _ctx.active(option))
            ? (_openBlock(), _createBlock(_component_app_count, {
                key: 0,
                plusDisabled: !_ctx.isEnabled,
                amount: option.amount,
                setAmount: (newAmount) => (newAmount === 0 ? _ctx.select(option) : _ctx.count(newAmount, option)),
                mini: ""
              }, null, 8, ["plusDisabled", "amount", "setAmount"]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}