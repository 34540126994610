<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.667 0.333374H2.33366C1.41449 0.333374 0.666992 1.08087 0.666992 2.00004V12C0.666992 12.9192 1.41449 13.6667 2.33366 13.6667H15.667C16.5862 13.6667 17.3337 12.9192 17.3337 12V2.00004C17.3337 1.08087 16.5862 0.333374 15.667 0.333374ZM2.33366 2.00004H15.667V3.66671H2.33366V2.00004ZM2.33366 12V7.00004H15.6678L15.6687 12H2.33366Z"
      fill="#67718B"
    />
    <path d="M4 8.66663H9V10.3333H4V8.66663Z" fill="#67718B" />
  </svg>
  <!-- eslint-enable -->
</template>
