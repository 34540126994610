class Scrolling {
  public toListOfRestaurants() {
    const cityHeros = document.getElementsByClassName('city-hero');
    if (cityHeros.length > 0) {
      const hero = cityHeros[0] as HTMLElement;
      const bottomEdge = hero.offsetTop + hero.offsetHeight;
      window.scrollTo({
        top: window.innerWidth > 1024 ? bottomEdge - 50 : bottomEdge - 110,
        behavior: 'smooth',
      });
    }
  }
}

export default new Scrolling();
