<template>
  <div class="search-bar-wrapper">
    <div id="search" class="search-bar">
      <a class="order-settings" @click="openOrderSettingsModal">
        <IconServices icon="locationLight" />
        <span>{{ orderDetailsTitle }}</span>
        <IconServices icon="pencil" />
      </a>
      <hr class="hide-desktop" />
      <div class="search-restaurants">
        <app-input v-model:value="search" placeholder="Search restaurants" vanilla>
          <template v-slot:icon>
            <IconServices class="services-icon" icon="search" />
          </template>
        </app-input>
      </div>
    </div>
    <hr />
  </div>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { watch } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import IconServices from '@/shared/icons/IconServices.vue';
import Logo from '@/shared/icons/Logo.vue';

import scrolling from '@/utils/scrolling';

@Options({
  components: {
    Logo,
    IconServices,
  },
  computed: {
    ...mapState('service', ['user']),
  },
  emits: {
    searching: [String, undefined],
  },
})
export default class SearchBar extends Vue {
  private search = '';

  get selectedTime() {
    const interval = this.$store.state.order.scheduledDeliveryInterval;
    const deliveryDate = this.$store.state.order.scheduledDeliveryDate;
    const now = moment().tz('Europe/London');

    if (!interval || !deliveryDate) {
      return 'Now';
    }

    const intervalEnd = interval.split('-')[1];
    const intervalEndHours = intervalEnd.split(':')[0];
    const intervalEndMinutes = intervalEnd.split(':')[1];

    const momentDeliveryDate = moment(deliveryDate).tz('Europe/London').set({
      hour: +intervalEndHours,
      minute: +intervalEndMinutes,
      seconds: 0,
    });

    const diffInMinutes = moment.duration(momentDeliveryDate.diff(now)).asMinutes();
    if (diffInMinutes < 0) {
      this.$store.commit('order/scheduledDeliveryDate', null);
      this.$store.commit('order/scheduledDeliveryInterval', null);
      this.$store.commit('basket/coupon', null);
      return 'Now';
    }

    const intervalStart = interval.split('-')[0];
    const intervalHours = intervalStart.split(':')[0];
    const intervalMinutes = intervalStart.split(':')[1];

    return moment(deliveryDate)
      .tz('Europe/London')
      .set({ hour: +intervalHours, minute: +intervalMinutes })
      .format('ddd, Do MMM, HH:mm');
  }

  get selectedAddress() {
    return this.$store.state.address.formattedAddress || 'No address selected';
  }

  get selectedMode() {
    return this.$store.state.order.type === 'delivery' ? 'Delivery' : 'Collect';
  }

  get orderDetailsTitle() {
    return `${this.selectedMode} - ${this.selectedTime} - ${this.selectedAddress}`;
  }

  private offset() {
    const navbar: HTMLElement = document.getElementById('search') as HTMLElement;

    const page = Number(window.pageYOffset.toFixed(0));

    if (page >= 55) {
      navbar.classList.add('show');
    } else {
      navbar.classList.remove('show');
    }
  }

  private handleOrderTypeClick(type: 'delivery' | 'collection') {
    this.$store.commit('order/type', type);
    this.$store.commit('basket/coupon', null);
  }

  private openOrderSettingsModal() {
    // Prevent changing time, mode and address while amending the order:
    if (this.$store.state.amendableOrder.data) {
      this.$store.commit('modals/show', 'activeAmendableOrderWarning');
      return;
    }

    this.$store.commit('modals/data', {
      withOrderTypeSwitch: true,
      withAddressChange: true,
      withDeliveryTimeChange: true,
    });
    this.$store.commit('modals/show', 'orderSettings');
  }

  private scrollToRestaurants() {
    scrolling.toListOfRestaurants();
  }

  mounted() {
    document.addEventListener('scroll', this.offset);
    watch(
      () => this.search,
      (newValue) => {
        setTimeout(() => {
          if (this.search === newValue) {
            this.$emit('searching', this.search);
            this.scrollToRestaurants();
          }
        }, 400);
      },
    );
  }

  unmounted() {
    document.removeEventListener('scroll', this.offset);
  }
}
</script>

<style lang="scss" scoped>
hr {
  margin: 0;
  border: 0.5px black solid;
}

.search-bar-wrapper {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $white;

  @include for-desktop {
    align-items: center;
    justify-content: center;
  }
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .controller-input-wrapper {
    input {
      border-radius: 10px;
    }
  }

  @include for-smartphone {
    flex-direction: column;

    .controller-input-wrapper {
      display: flex;
      justify-content: center;
      max-width: none;
      padding: 0 16px;
    }
  }

  @include for-desktop {
    @include content-grid;
  }
}

.order-settings {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $coal20;
  background-color: $vanilla;
  padding: 10px 15px;
  height: 45px;
  max-height: 45px;
  max-width: 400px;
  margin: 10px 16px;
  gap: 10px;

  button {
    height: 18px;
  }

  svg {
    height: 18px;
    width: 18px;
  }

  span {
    @include p2;
    display: block;
    color: $coal100;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
  }

  @include for-smartphone {
    width: calc(100% - 32px);
    max-width: calc(100% - 32px);
  }
}

.search-restaurants {
  width: 280px;

  @include for-smartphone {
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 0;
    width: 100%;
  }
}

.hide-mobile {
  @include for-smartphone {
    display: none;
  }
}

.hide-desktop {
  @include for-desktop {
    display: none;
  }
}

.unix .order-settings span {
  height: 18px;
}

.unix .work-button p {
  height: 16px;
}

.unix .home-button p {
  height: 16px;
}
</style>
