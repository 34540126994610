import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/landing/main_list_of_logos.png'
import _imports_1 from '../assets/landing/variants/bespoke-events-header.png'
import _imports_2 from '../assets/landing/restaurant_cards/pizzarova_logo.png'
import _imports_3 from '../assets/landing/restaurant_cards/al_chile_logo.png'
import _imports_4 from '../assets/landing/restaurant_cards/chin_chin_logo.png'
import _imports_5 from '../assets/landing/restaurant_cards/bosh_logo.png'
import _imports_6 from '../assets/landing/carousel_left.png'
import _imports_7 from '../assets/landing/carousel_right.png'
import _imports_8 from '../assets/landing/variants/bespoke-events-divider.png'
import _imports_9 from '../assets/landing/divider_list_of_logos.png'
import _imports_10 from '../assets/landing/divider_list_of_logos_mobile.png'
import _imports_11 from '../assets/order_tracking/message.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "header_section" }
const _hoisted_3 = { class: "header_section_body" }
const _hoisted_4 = { class: "order_buttons" }
const _hoisted_5 = { class: "pop_ups_and_experiences_section" }
const _hoisted_6 = { class: "sub_title" }
const _hoisted_7 = { class: "divider_contact" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_TickerTape = _resolveComponent("TickerTape")!
  const _component_WeSupportLocal = _resolveComponent("WeSupportLocal")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Slide = _resolveComponent("Slide")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_ThePerfectFood = _resolveComponent("ThePerfectFood")!
  const _component_BuiltForEase = _resolveComponent("BuiltForEase")!
  const _component_SustainableAndEthical = _resolveComponent("SustainableAndEthical")!
  const _component_HowItWorks = _resolveComponent("HowItWorks")!
  const _component_EverybodyWins = _resolveComponent("EverybodyWins")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "title" }, [
          _createTextVNode(" Bespoke events in"),
          _createElementVNode("br"),
          _createTextVNode(" Bristol and Cambridge ")
        ], -1)),
        _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "sub_title" }, "• Corporate events with the best food in Bristol and Cambridge", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "sub_title" }, "• Work with experts to create an amazing food experience for your team", -1)),
        _cache[6] || (_cache[6] = _createElementVNode("h2", { class: "sub_title" }, "• We fit all sizes, diets, budgets and preferences for your business", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_app_button, {
            slim: "",
            onClick: _ctx.orderNow
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconServices, { icon: "upload" }),
              _cache[1] || (_cache[1] = _createElementVNode("p", null, "Show me food", -1))
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_app_button, {
            slim: "",
            white: "",
            onClick: _ctx.goToEnquiryPage
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconServices, { icon: "gift" }),
              _cache[2] || (_cache[2] = _createElementVNode("p", null, "Plan something bigger", -1))
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _createElementVNode("p", {
          class: "how-does-it-work-question custom-cursor-hover",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollToHowItWorks && _ctx.scrollToHowItWorks(...args)))
        }, "How does it work?"),
        _cache[7] || (_cache[7] = _createElementVNode("p", { class: "quote" }, [
          _createTextVNode("“"),
          _createElementVNode("b", null, "It went down an absolute storm, I'd say the best lunch yet!"),
          _createTextVNode("” - Hargreaves Lansdown")
        ], -1)),
        _cache[8] || (_cache[8] = _createElementVNode("img", {
          id: "main_list_of_logos",
          src: _imports_0,
          alt: ""
        }, null, -1))
      ]),
      _cache[9] || (_cache[9] = _createElementVNode("img", {
        class: "header_image",
        src: _imports_1,
        alt: ""
      }, null, -1))
    ]),
    _createVNode(_component_TickerTape),
    _createElementVNode("div", _hoisted_5, [
      _cache[17] || (_cache[17] = _createElementVNode("h1", { class: "section_title" }, "Pop ups and experiences", -1)),
      _createElementVNode("h2", _hoisted_6, [
        _cache[10] || (_cache[10] = _createTextVNode(" Take your next team event to the next level ")),
        _createVNode(_component_WeSupportLocal, {
          color: _ctx.ham,
          width: 100,
          height: 100
        }, null, 8, ["color"])
      ]),
      _createVNode(_component_Carousel, {
        class: "occasions_carousel",
        breakpoints: _ctx.breakpoints,
        "wrap-around": true
      }, {
        addons: _withCtx(() => [
          _createVNode(_component_Navigation, null, {
            prev: _withCtx(() => _cache[15] || (_cache[15] = [
              _createElementVNode("img", {
                class: "carousel_nav_arrow",
                src: _imports_6,
                alt: ""
              }, null, -1)
            ])),
            next: _withCtx(() => _cache[16] || (_cache[16] = [
              _createElementVNode("img", {
                class: "carousel_nav_arrow",
                src: _imports_7,
                alt: ""
              }, null, -1)
            ])),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Slide, { key: "pizzarova" }, {
            default: _withCtx(() => [
              _createVNode(_component_Card, {
                title: "Bespoke",
                class: "custom-cursor-hover",
                onClick: _ctx.scrollToTop
              }, {
                body: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createElementVNode("div", { class: "card_image pizzarova" }, [
                    _createElementVNode("img", {
                      class: "card_logo",
                      src: _imports_2,
                      alt: ""
                    })
                  ], -1),
                  _createElementVNode("div", { class: "card_content" }, [
                    _createElementVNode("h3", null, "Pizzarova"),
                    _createElementVNode("p", null, "Handmade sourdough pizza"),
                    _createElementVNode("div", { class: "card_food_containers" }, [
                      _createElementVNode("p", null, "Sourdough pizza"),
                      _createElementVNode("p", null, "Great for sharing")
                    ])
                  ], -1)
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_Slide, { key: "al_chile" }, {
            default: _withCtx(() => [
              _createVNode(_component_Card, {
                title: "Bespoke",
                class: "custom-cursor-hover",
                onClick: _ctx.scrollToTop
              }, {
                body: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createElementVNode("div", { class: "card_image al_chile" }, [
                    _createElementVNode("img", {
                      class: "card_logo",
                      src: _imports_3,
                      alt: ""
                    })
                  ], -1),
                  _createElementVNode("div", { class: "card_content" }, [
                    _createElementVNode("h3", null, "Al Chile"),
                    _createElementVNode("p", null, "Gourmet Mexican street food"),
                    _createElementVNode("div", { class: "card_food_containers" }, [
                      _createElementVNode("p", null, "Burritos & Tacos"),
                      _createElementVNode("p", null, "Buffets available")
                    ])
                  ], -1)
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_Slide, { key: "chin_chin" }, {
            default: _withCtx(() => [
              _createVNode(_component_Card, {
                title: "Bespoke",
                class: "custom-cursor-hover",
                onClick: _ctx.scrollToTop
              }, {
                body: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createElementVNode("div", { class: "card_image chin_chin" }, [
                    _createElementVNode("img", {
                      class: "card_logo",
                      src: _imports_4,
                      alt: ""
                    })
                  ], -1),
                  _createElementVNode("div", { class: "card_content" }, [
                    _createElementVNode("h3", null, "Chin Chin"),
                    _createElementVNode("p", null, "Handmade and small batch"),
                    _createElementVNode("div", { class: "card_food_containers" }, [
                      _createElementVNode("p", null, "Doughnuts")
                    ])
                  ], -1)
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_Slide, { key: "bosh" }, {
            default: _withCtx(() => [
              _createVNode(_component_Card, {
                title: "Bespoke",
                class: "custom-cursor-hover",
                onClick: _ctx.scrollToTop
              }, {
                body: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createElementVNode("div", { class: "card_image bosh" }, [
                    _createElementVNode("img", {
                      class: "card_logo",
                      src: _imports_5,
                      alt: ""
                    })
                  ], -1),
                  _createElementVNode("div", { class: "card_content" }, [
                    _createElementVNode("h3", null, "BOSH"),
                    _createElementVNode("p", null, "Perfect for any occasion"),
                    _createElementVNode("div", { class: "card_food_containers" }, [
                      _createElementVNode("p", null, "Flexible menus and dietaries")
                    ])
                  ], -1)
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["breakpoints"])
    ]),
    _cache[20] || (_cache[20] = _createElementVNode("img", {
      class: "divider_image",
      src: _imports_8,
      alt: ""
    }, null, -1)),
    _createVNode(_component_ThePerfectFood, {
      title: "The perfect food for your office",
      subtitle: "Improve your office eating experiences with Foodstuff. We're partnered with 100+ top-notch restaurants and caterers in Cambridge and Bristol, ensuring a diverse offering adaptable to every office occasion, diet, and budget. Order now and get the best office meals for your team.",
      onOrderNowClick: _ctx.orderNow,
      onPlanSomethingBiggerClick: _ctx.goToEnquiryPage
    }, null, 8, ["onOrderNowClick", "onPlanSomethingBiggerClick"]),
    _createVNode(_component_BuiltForEase, { onHereClick: _ctx.openFreshchat }, null, 8, ["onHereClick"]),
    _cache[21] || (_cache[21] = _createElementVNode("div", { class: "divider_list_of_logos" }, [
      _createElementVNode("img", {
        class: "hide_mobile",
        src: _imports_9,
        alt: ""
      }),
      _createElementVNode("img", {
        class: "hide_desktop",
        src: _imports_10,
        alt: ""
      })
    ], -1)),
    _createVNode(_component_SustainableAndEthical),
    _createVNode(_component_HowItWorks, { title: "How it works" }),
    _createElementVNode("div", _hoisted_7, [
      _cache[19] || (_cache[19] = _createElementVNode("p", null, "Got a few questions? Give our team a shout", -1)),
      _createVNode(_component_app_button, {
        slim: "",
        white: "",
        onClick: _ctx.openFreshchat
      }, {
        default: _withCtx(() => _cache[18] || (_cache[18] = [
          _createElementVNode("img", {
            src: _imports_11,
            alt: ""
          }, null, -1),
          _createElementVNode("p", null, "Chat to the team", -1)
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_EverybodyWins)
  ]))
}