import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled", "type", "name", "id"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["controller-option", [{ fullWidth: _ctx.fullWidth }]])
  }, [
    _createElementVNode("label", {
      class: _normalizeClass(["custom-cursor-hover", [
        {
          disabled: _ctx.disabled,
          active: _ctx.active,
          reversed: _ctx.reversed,
          small: _ctx.small,
          error: _ctx.$slots.error,
        },
        _ctx.type,
      ]])
    }, [
      _createElementVNode("input", {
        disabled: _ctx.disabled,
        type: _ctx.getType(),
        name: _ctx.name,
        id: _ctx.id
      }, null, 8, _hoisted_1),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "option" }, null, -1)),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    (_ctx.$slots.error)
      ? (_openBlock(), _createElementBlock("small", _hoisted_2, [
          _renderSlot(_ctx.$slots, "error", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}