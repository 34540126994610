<template>
  <label class="controllers-textarea">
    <p v-if="title">{{ title }}</p>
    <textarea
      @blur="blur"
      @input="$emit('update:value', $event.target.value)"
      :value="value"
      :placeholder="placeholder"
      ref="textarea"
      maxlength="500"
      :class="{ 'full-width': fullWidth }"
    />
  </label>
</template>

<script lang="ts">
import autosize from 'autosize';
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    title: String,
    fullWidth: Boolean,
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  emits: {
    'update:value': [String, Number],
    blur: [String, Number],
  },
})
export default class AppControllerTextarea extends Vue {
  private blur() {
    this.$emit('blur');
  }

  mounted() {
    autosize(this.$refs.textarea);
  }
}
</script>

<style lang="scss" scoped>
.controllers-textarea {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  p {
    color: $coal80;
    margin-bottom: 10px;
  }
  textarea {
    border: 1px solid $coal20;
    border-radius: 15px;
    padding: 20px;
    resize: none;
    &.full-width {
      width: 100%;
    }
  }
}
</style>
