<template>
  <!-- eslint-disable -->
  <svg enable-background="new 0 0 512.007 512.007" viewBox="0 0 512.007 512.007" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m380.125 59.036c-59.77 0-109.664 42.249-121.469 98.51-.608 2.899-4.703 2.901-5.312 0-11.805-56.261-61.699-98.51-121.469-98.51-114.106 0-167.756 141.01-82.508 216.858l193.339 172.02c7.58 6.744 19.009 6.744 26.589 0l193.339-172.02c85.248-75.848 31.598-216.858-82.509-216.858z"
      fill="#fe646f"
    />
    <path
      d="m380.125 59.036c-6.912 0-13.689.572-20.293 1.658 99.376 15.991 141.363 144.168 61.527 215.2l-185.996 165.487 7.343 6.533c7.58 6.744 19.009 6.744 26.589 0l193.339-172.02c85.248-75.848 31.598-216.858-82.509-216.858z"
      fill="#fd4755"
    />
    <path
      d="m380.125 59.036c-59.77 0-109.664 42.249-121.469 98.51-.608 2.899-4.703 2.901-5.312 0-11.805-56.261-61.699-98.51-121.469-98.51-114.106 0-167.756 141.01-82.508 216.858l193.339 172.02c7.58 6.744 19.009 6.744 26.589 0l193.339-172.02c85.248-75.848 31.598-216.858-82.509-216.858z"
      fill="#fe646f"
    />
    <path
      d="m380.125 59.036c-6.912 0-13.689.572-20.293 1.658 99.376 15.991 141.363 144.168 61.527 215.2l-185.996 165.487 7.343 6.533c7.58 6.744 19.009 6.744 26.589 0l193.339-172.02c85.248-75.848 31.598-216.858-82.509-216.858z"
      fill="#fd4755"
    />
    <path
      d="m237.72 453.517c-204.315-181.786-197.402-175.776-197.402-175.776-25.999-24.984-40.318-58.201-40.318-93.533 0-46.48 24.63-91.702 65.906-115.47 3.589-2.067 8.174-.833 10.242 2.757 2.067 3.589.833 8.175-2.757 10.242-36.017 20.74-58.391 60.004-58.391 102.471 0 31.212 12.683 60.588 35.711 82.717 0 0-6.881-5.996 196.979 175.386 2.292 2.039 5.242 3.161 8.309 3.161 3.066 0 6.018-1.123 8.31-3.162l61.917-55.089c3.095-2.753 7.835-2.477 10.588.618s2.477 7.835-.618 10.588l-61.917 55.09c-10.431 9.281-26.148 9.263-36.559 0zm119.643-76.458c-2.067 0-4.124-.849-5.606-2.515-2.753-3.095-2.477-7.835.618-10.588l105.273-93.665c21.815-19.409 35.132-44.369 38.513-72.181.001-.006.001-.012.002-.018 7.637-62.927-37.915-131.557-116.038-131.557-54.879 0-102.877 38.923-114.129 92.55-1.005 4.79-5.116 8.135-9.997 8.135s-8.991-3.346-9.996-8.136c-11.252-53.626-59.25-92.549-114.128-92.549-9.633 0-19.082 1.076-28.084 3.198-4.033.952-8.07-1.548-9.021-5.579-.951-4.032 1.547-8.07 5.579-9.021 10.128-2.388 20.735-3.598 31.525-3.598 55.699 0 105.463 35.109 124.125 87.792 18.71-52.817 68.567-87.792 124.125-87.792 84.905 0 139.884 74.56 130.929 148.362 0 .007-.001.015-.002.022-3.829 31.494-18.847 59.703-43.433 81.578l-105.273 93.665c-1.429 1.272-3.209 1.897-4.982 1.897z"
    />
  </svg>
  <!-- eslint-enable -->
</template>
