class Pipes {
  /* eslint-disable class-methods-use-this */

  public truncate(text: string, length: number, suffix?: string) {
    if (text.length > length) {
      return text.substring(0, length) + (suffix || '...');
    }

    return text;
  }

  public addApostrophe(text: string) {
    return `${text}'${text.trim().substring(text.length - 1) === 's' ? '' : 's'}`;
  }
}

export default new Pipes();
