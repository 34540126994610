<template>
  <div class="form-scoring-wrapper">
    <slot name="body" />

    <div v-if="$slots.body" class="form-scoring-stripe" />

    <slot name="footer" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class BasketScore extends Vue {}
</script>

<style lang="scss">
.form-scoring {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-wrapper {
    background-color: $coal100;
    border-radius: 15px;

    padding: {
      top: 10px;
      left: 15px;
      right: 15px;
      bottom: 15px;
    }
  }

  &-stripe {
    position: relative;
    border-top: 1px dashed rgba($color: $vanilla, $alpha: 0.3);
    margin: 10px -15px;
    padding: {
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 0;
    }
    &::before,
    &::after {
      content: '';
      background-color: $vanilla;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      position: absolute;
      top: -7px;
      left: -6px;
    }
    &::after {
      left: auto;
      right: -6px;
    }
  }

  &-price {
    display: flex;
    align-items: center;
    p {
      font-weight: bold;
      &.td-lt {
        opacity: 0.5;
        margin-left: 10px;
      }
    }
  }
}
</style>
