<template>
  <!-- eslint-disable -->
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_216_3618)">
      <path
        d="M9.50016 17.4166C13.8724 17.4166 17.4168 13.8722 17.4168 9.49992C17.4168 5.12766 13.8724 1.58325 9.50016 1.58325C5.12791 1.58325 1.5835 5.12766 1.5835 9.49992C1.5835 13.8722 5.12791 17.4166 9.50016 17.4166Z"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.19629 7.12496C7.38241 6.59587 7.74978 6.14971 8.23334 5.86553C8.71689 5.58134 9.28541 5.47746 9.83822 5.57228C10.391 5.6671 10.8924 5.9545 11.2536 6.38359C11.6148 6.81268 11.8125 7.35575 11.8117 7.91663C11.8117 9.49996 9.43671 10.2916 9.43671 10.2916"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M9.5 13.4583H9.50875" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_216_3618">
        <rect width="19" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
  <!-- eslint-enable -->
</template>
