import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "no-orders"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Order = _resolveComponent("Order")!

  return (_ctx.orders.length > 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["orders-list", { 'scroll-padding': _ctx.orders.length > 10 }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order) => {
          return (_openBlock(), _createBlock(_component_Order, {
            order: order,
            key: order.id
          }, null, 8, ["order"]))
        }), 128))
      ], 2))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("h1", null, "No orders", -1)
      ])))
}