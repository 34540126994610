import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../assets/landing/card_header_lines.png'
import _imports_1 from '../assets/landing/card_header_circle.png'


const _hoisted_1 = { class: "card_header" }
const _hoisted_2 = { class: "card_header_label" }
const _hoisted_3 = {
  key: 1,
  class: "card_content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", { const_dimensions: !_ctx.$slots.body }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("img", {
        class: "card_header_lines",
        src: _imports_0,
        alt: ""
      }, null, -1)),
      _cache[2] || (_cache[2] = _createElementVNode("img", {
        class: "card_header_circle",
        src: _imports_1,
        alt: ""
      }, null, -1)),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ]),
    _renderSlot(_ctx.$slots, "body", {}, undefined, true),
    (!_ctx.$slots.body)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["card_image", [_ctx.imageName]])
        }, null, 2))
      : _createCommentVNode("", true),
    (!_ctx.$slots.body)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.$slots.body)
      ? (_openBlock(), _createElementBlock("p", {
          key: 2,
          class: "find_out_more custom-cursor-hover",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFindOutMoreClick && _ctx.onFindOutMoreClick(...args)))
        }, "Find out more"))
      : _createCommentVNode("", true)
  ], 2))
}