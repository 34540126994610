<template>
  <div class="basket-item-wrapper">
    <div class="basket-item-info">
      <p class="basket-item-title">
        {{ dish.name }}
      </p>

      <p class="basket-item-extras" v-if="dish.modificators.length > 0">
        {{ dishExtras }}
      </p>

      <div class="basket-item-price">
        <p>£{{ dishPrice }}</p>
      </div>

      <div class="basket-item-price" v-if="dish.note">
        <p>"{{ dish.note }}"</p>
      </div>
    </div>

    <div class="basket-item-edit-count-wrapper">
      <p>£{{ dishTotalPrice }}</p>

      <app-count v-if="edit" :amount="dish.amount" :setAmount="(newAmount) => setCount(newAmount, indexDish)" />
      <div class="empty" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

import methods from '@/utils/methods';
import pipes from '@/utils/pipes';

class Props {
  edit = prop<boolean>({ required: true });
  dish = prop<OrderDish>({ required: true });
  indexDish = prop<number>({ required: true });
  isGroupOrder = prop<boolean>({ required: false, default: false });
}

@Options({
  props: {
    edit: Boolean,
    dish: Object,
    indexDish: Number,
    isGroupOrder: {
      type: Boolean,
      default: false,
    },
  },
})
export default class BasketItem extends Vue.with(Props) {
  get dishExtras() {
    const modifiers = this.dish.modificators.filter((m) => m.amount > 0);
    if (!modifiers.length) return '';

    let extras = '';
    for (let i = 0; i < modifiers.length; i += 1) {
      if (extras !== '') {
        extras += `, ${modifiers[i].amount * this.dish.amount}x ${modifiers[i].name}`;
      } else {
        extras += `${modifiers[i].amount * this.dish.amount}x ${modifiers[i].name}`;
      }
    }

    return extras;
  }

  get dishTotalPrice() {
    return methods.getItemPrice(this.dish).toFixed(2);
  }

  get dishPrice() {
    const dishPrice = methods.getItemPrice(this.dish) / this.dish.amount;
    return dishPrice.toFixed(2);
  }

  private truncate(text: string) {
    return pipes.truncate(text, 61);
  }

  private setCount(amount: number, index: number) {
    if (this.isGroupOrder) {
      if (amount <= 0) this.$emit('removeFromGroupBasket', index);
      else this.$emit('editGroupBasket', amount, index);
      return;
    }

    if (amount <= 0) this.$store.commit('basket/remove', index);
    else this.$store.commit('basket/changeAmount', { amount, index });

    const { basket, groupOrder } = this.$store.state;
    if (!basket.dishes.length && !groupOrder.groupData?.baskets.length) {
      this.$store.commit('basket/restaurant', null);
    }
  }
}
</script>

<style lang="scss">
.basket-item {
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $coal10;
    width: 345px;
    min-height: 105px;
    padding-bottom: 9px;
    margin-top: 10px;
    &.remove-border {
      border-bottom: none;
    }
  }

  &-extras {
    @include p3;
    color: $coal60;
    margin-bottom: 5px;
  }

  &-info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    margin-right: 15px;
  }

  &-title {
    @include p1Bold;
    margin-bottom: 5px;
  }

  &-price {
    display: flex;
    align-items: center;

    p {
      @include p2;
      color: $coal60;

      &.discounted {
        margin-left: 5px;
        color: $darkHam;
      }
    }
  }

  &-photo {
    position: relative;
    min-width: 109px;
    min-height: 109px;
    height: 109px;
    width: 109px;
    max-width: 109px;
    max-height: 109px;
    &-wrapper {
      padding-top: 5px;
      height: 100%;
    }
  }

  &-count {
    position: absolute;
    right: 4px;
    top: 4px;
    padding: 2px 9px;
    background-color: $ham;
    border-radius: 100%;
  }

  &-edit-count-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
    .empty {
      height: 20px;
      min-height: 20px;
    }

    p {
      @include p1Bold;
      margin-bottom: 12px;
    }
  }
}
</style>
