import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("li", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goTo('/my-account'))),
      class: "custom-cursor-hover"
    }, [
      _createVNode(_component_IconServices, { icon: "accountNoBg" }),
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "nav-text" }, "Account", -1))
    ]),
    _createElementVNode("li", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goTo('/my-account/orders'))),
      class: "custom-cursor-hover"
    }, [
      _createVNode(_component_IconServices, { icon: "orders" }),
      _cache[4] || (_cache[4] = _createElementVNode("p", { class: "nav-text" }, "Orders", -1))
    ]),
    _createElementVNode("li", {
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.logOut && _ctx.logOut(...args))),
      class: "custom-cursor-hover"
    }, [
      _createVNode(_component_IconServices, { icon: "logOut" }),
      _cache[5] || (_cache[5] = _createElementVNode("p", { class: "nav-text" }, "Log out", -1))
    ])
  ], 64))
}