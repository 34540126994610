import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "invalid-address-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "medium" }, "Almost there!", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "divider ham full-width" }, null, -1)),
      _cache[3] || (_cache[3] = _createElementVNode("h4", null, "Please enter the street", -1)),
      _createVNode(_component_app_button, {
        onClick: _withModifiers(_ctx.close, ["prevent"])
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Gotcha")
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 16))
}