import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state: StateStripe = {
  cardNumber: null,
  cardCvc: null,
  cardExpiry: null,
  showError: false,
};

const mutations = {
  update(state: StateStripe, payload: StripeData | StripeChangeEvent) {
    if (payload instanceof Object) {
      if (payload.empty) state[payload.elementType] = null;
      else state[payload.elementType] = payload as any;
    } else state[payload] = null;
  },
  clear(state: StateStripe) {
    state.cardNumber = null;
    state.cardCvc = null;
    state.cardExpiry = null;
  },
  error(state: StateStripe, payload: boolean) {
    state.showError = payload;
  },
};

const actions = {
  //
};

const getters = {
  //
};

export type StripeData = 'cardNumber' | 'cardCvc' | 'cardExpiry';

export type StripeChangeEvent =
  | StripeCardNumberElementChangeEvent
  | StripeCardCvcElementChangeEvent
  | StripeCardExpiryElementChangeEvent;

export interface StateStripe {
  [x: string]: any;
  cardNumber: null | StripeCardNumberElementChangeEvent;
  cardCvc: null | StripeCardCvcElementChangeEvent;
  cardExpiry: null | StripeCardExpiryElementChangeEvent;
  showError: boolean;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
