import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "group-baskets-list__basket" }
const _hoisted_2 = {
  key: 0,
  class: "group-baskets-list__no-items"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "group-baskets-list__header" }
const _hoisted_7 = {
  key: 1,
  class: "group-baskets-list__no-items"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasketItem = _resolveComponent("BasketItem")!
  const _component_IconServices = _resolveComponent("IconServices")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "group-baskets-list__header" }, [
        _createElementVNode("span", null, "Your items")
      ], -1)),
      (_ctx.userBasket.length < 1)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, "No items added"))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.userBasket, (i, index) => {
            return (_openBlock(), _createBlock(_component_BasketItem, {
              edit: !_ctx.checkout && !_ctx.areItemsConfirmed,
              class: _normalizeClass({ 'remove-border': index === _ctx.userBasket.length - 1 && _ctx.checkout }),
              key: index,
              dish: i,
              indexDish: index
            }, null, 8, ["edit", "class", "dish", "indexDish"]))
          }), 128))
    ]),
    (!_ctx.amendableData)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(`group-baskets-list__view-more ${_ctx.showRestOfGroupOrder ? 'group-baskets-list__view-more-expanded' : ''} `),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showGroupOrderBaskets()))
        }, [
          _createVNode(_component_IconServices, {
            class: "group-baskets-list__view-more-icon",
            icon: "add",
            color: "dark-ham"
          }),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "group-baskets-list__view-more-text" }, "Show other people’s items", -1))
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.showRestOfGroupOrder || _ctx.amendableData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.groupData && _ctx.groupData.baskets.length && _ctx.$route.params.slug === _ctx.groupData.restaurantSlug)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupData.baskets, (basket, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "group-baskets-list__basket",
                    key: index
                  }, [
                    (_ctx.participantBasketId !== basket.id)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("span", null, _toDisplayString(`${_ctx.addApostrophe(basket.userName)} items`), 1),
                            _cache[3] || (_cache[3] = _createTextVNode(" - confirmed "))
                          ]),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(basket.cart.dishes, (dish, index) => {
                            return (_openBlock(), _createBlock(_component_BasketItem, {
                              edit: !_ctx.checkout && _ctx.groupRole === 'Owner',
                              class: _normalizeClass({ 'remove-border': index === basket.length - 1 && _ctx.checkout }),
                              key: index,
                              dish: dish,
                              indexDish: index,
                              isGroupOrder: true,
                              groupBasketId: basket.id,
                              onEditGroupBasket: (count, index) => _ctx.editItemFromGroupBasket(count, index, basket.id),
                              onRemoveFromGroupBasket: (index) => _ctx.editItemFromGroupBasket(0, index, basket.id)
                            }, null, 8, ["edit", "class", "dish", "indexDish", "groupBasketId", "onEditGroupBasket", "onRemoveFromGroupBasket"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.noItemsToShow && !_ctx.amendableData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, "No items added"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}