<template>
  <app-modal class="set-filters-modal" v-bind="options">
    <app-button class="set-filters-modal-close" mini white @click.prevent="close">
      <IconServices class="services-icon" icon="add" close />
    </app-button>
    <h2>{{ data.title }}</h2>
    <div class="set-filters-row" v-for="variant in data.variants" :key="variant.id">
      <app-option :active="isActive(variant)" type="checkbox" @click.prevent="select(variant)" />
      <p>{{ variant.name }}</p>
    </div>
    <app-button class="apply" slim square @click="apply"><p>Apply</p></app-button>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';
import IconServices from '@/shared/icons/IconServices.vue';

@Options({
  components: {
    IconServices,
  },
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class ModalSetFilters extends Vue {
  private selected: Filter[] = [];

  private options: ModalInterface = {
    slim: true,
    close: false,
  };

  private isActive(variant: Filter) {
    return this.selected.findIndex((e) => e.id === variant.id) >= 0;
  }

  private select(variant: Filter) {
    const index = this.selected.findIndex((e) => e.id === variant.id);

    if (index >= 0) this.selected.splice(index, 1);
    else this.selected.push(variant);
  }

  private close() {
    this.$store.commit('modals/close');
  }

  private apply() {
    const { eventType } = this.$store.state.modals.data;
    this.$store.commit('service/update', { type: eventType, selected: this.selected });
    this.$store.commit('modals/close');
  }

  created() {
    const { variants, selectedVariantIds } = this.$store.state.modals.data;
    selectedVariantIds.forEach((id: number) => {
      const matchingVariant = (variants as Filter[]).find((v) => v.id === id);
      if (matchingVariant) this.selected.push(matchingVariant);
    });
  }
}
</script>

<style lang="scss">
.set-filters-modal {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .modal.slim {
    max-width: 300px;
    width: 300px;
  }

  h2 {
    font-weight: 600;
    margin-bottom: 10px;
  }

  &-close {
    position: absolute;
    top: 10px;
    right: 10px;
    .close.services-icon {
      top: 9px;
      left: 9px;
      width: 21px;
      height: 21px;
    }
  }

  .set-filters-row {
    display: flex;
    flex-direction: row;
    gap: 5px;
    height: 45px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    p {
      @include p1;
      height: 16px;
    }

    .option {
      background-color: white;
    }
  }

  .apply {
    margin-top: 10px;
    float: right;
  }
}
</style>
