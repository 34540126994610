/* eslint-disable */
import shuffle from 'lodash/shuffle';

export default class Banners {
  public static getAllBannersInRandomOrder() {
    const banners = shuffle(this.messages);
    return [...banners, ...banners];
  }

  static messages = [
    'Our riders are all paid living wage alongside benefits like sick and holiday pay',
    'Did you know when you order with Foodstuff, you save independent restaurants 40% compared to other delivery platforms?',
    'Since we started, our riders have cycled over 390,000 miles - 100% planet friendly delivery',
    `“I don’t think I’ll ever use another food delivery service. Keep up the great work” - James, customer`,
    '“Fast delivery, all the riders are really friendly and a great range of food to pick from!” - Carol, customer',
    '“I love riding for Foodstuff. Everyone is super friendly from other riders; kitchen staff and customers” - Anthony, rider',
    'We only work with the best independent restaurants, cafes and caterers in your city',
  ];
}
