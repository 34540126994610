import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card custom-cursor-hover" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "card-image" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = {
  key: 0,
  class: "time-label"
}
const _hoisted_6 = {
  key: 1,
  class: "time-label"
}
const _hoisted_7 = { class: "card-content" }
const _hoisted_8 = { class: "restaurant-name" }
const _hoisted_9 = { class: "card-info" }
const _hoisted_10 = {
  key: 0,
  class: "info-short-description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "card-logo",
      src: _ctx.restaurant.logo,
      alt: "restaurant.logo"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        src: _ctx.restaurant.cover,
        alt: _ctx.restaurant.name
      }, null, 8, _hoisted_4),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "shadow" }, null, -1)),
      (_ctx.restaurant.deliveryLabel && _ctx.orderType === 'delivery')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("p", null, [
              _createElementVNode("b", null, _toDisplayString(_ctx.restaurant.deliveryLabel.split(' ')[0]), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.restaurant.deliveryLabel.split(' ')[1]), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.restaurant.collectionLabel && _ctx.orderType === 'collection')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("p", null, [
              _createElementVNode("b", null, _toDisplayString(_ctx.restaurant.collectionLabel.split(' ')[0]), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.restaurant.collectionLabel.split(' ')[1]), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.restaurant.name), 1),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.openingInfoLabelColor)
        }, _toDisplayString(_ctx.restaurant.openingLabel), 3),
        _cache[1] || (_cache[1] = _createElementVNode("span", null, "|", -1)),
        _createElementVNode("h4", null, _toDisplayString(_ctx.feeOrDistanceInfo), 1)
      ]),
      (_ctx.restaurant.shortDescription)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_10, "'" + _toDisplayString(_ctx.restaurant.shortDescription) + "'", 1))
        : _createCommentVNode("", true)
    ])
  ]))
}