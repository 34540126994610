import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createSlots as _createSlots, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/gtm/james.png'
import _imports_1 from '../../assets/check.svg'
import _imports_2 from '../../assets/gtm/award.png'
import _imports_3 from '../../assets/simple_arrow_bottom.svg'
import _imports_4 from '../../assets/gtm/activity.png'


const _hoisted_1 = { class: "main_steps" }
const _hoisted_2 = {
  key: 0,
  class: "main_steps_header"
}
const _hoisted_3 = {
  key: 0,
  class: "main_steps_body"
}
const _hoisted_4 = {
  key: 0,
  class: "step_one"
}
const _hoisted_5 = { class: "input_field" }
const _hoisted_6 = {
  key: 0,
  src: _imports_1,
  alt: ""
}
const _hoisted_7 = { class: "input_field" }
const _hoisted_8 = {
  key: 0,
  src: _imports_1,
  alt: ""
}
const _hoisted_9 = {
  key: 1,
  class: "step_two"
}
const _hoisted_10 = { class: "input_field" }
const _hoisted_11 = {
  key: 0,
  src: _imports_1,
  alt: ""
}
const _hoisted_12 = { class: "happy_for_us_row" }
const _hoisted_13 = { class: "supplier_gaps_row" }
const _hoisted_14 = {
  key: 0,
  class: "dropdown_list_cuisines"
}
const _hoisted_15 = { class: "dropdown_list_body" }
const _hoisted_16 = {
  key: 1,
  class: "dropdown_list_moments"
}
const _hoisted_17 = { class: "dropdown_list_body" }
const _hoisted_18 = {
  key: 0,
  class: "main_steps_body"
}
const _hoisted_19 = {
  key: 0,
  class: "main_steps_footer"
}
const _hoisted_20 = { class: "progress_bar" }
const _hoisted_21 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_option = _resolveComponent("app-option")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.areHeaderAndFooterLoaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Logo),
              _createVNode(_component_app_button, {
                square: "",
                white: "",
                slim: "",
                class: "chat_with_us",
                onClick: _withModifiers(_ctx.chatWithUs, ["prevent"])
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }, null, -1),
                  _createElementVNode("p", null, "Chat with us", -1)
                ])),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_app_button, {
                square: "",
                white: "",
                slim: "",
                class: "exit",
                onClick: _withModifiers(_ctx.exit, ["prevent"])
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createElementVNode("p", null, "Exit", -1)
                ])),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isBodyLoaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.step === 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _cache[11] || (_cache[11] = _createElementVNode("p", { class: "step_number" }, "Step 1", -1)),
                    _cache[12] || (_cache[12] = _createElementVNode("h1", { class: "step_title" }, "Company details", -1)),
                    _cache[13] || (_cache[13] = _createElementVNode("p", { class: "step_hint" }, "We'll use this information to contact you about your marketplace", -1)),
                    _createElementVNode("div", null, [
                      _cache[9] || (_cache[9] = _createElementVNode("p", { class: "input_title" }, "Company email address?", -1)),
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_app_input, {
                          id: "emailAddress",
                          placeholder: "e.g. info@acme.com",
                          value: _ctx.emailAddress,
                          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailAddress) = $event)),
                          showError: _ctx.show,
                          addErrorPlaceholderSpacing: ""
                        }, _createSlots({ _: 2 }, [
                          (_ctx.yupErrMsg('emailAddress'))
                            ? {
                                name: "error",
                                fn: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.yupErrMsg('emailAddress')), 1)
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["value", "showError"]),
                        (_ctx.emailAddress)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", null, [
                      _cache[10] || (_cache[10] = _createElementVNode("p", { class: "input_title" }, "Phone number?", -1)),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_app_input, {
                          id: "phoneNumber",
                          value: _ctx.phoneNumber,
                          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phoneNumber) = $event)),
                          showError: _ctx.show,
                          addErrorPlaceholderSpacing: "",
                          isPhoneNumber: ""
                        }, _createSlots({ _: 2 }, [
                          (_ctx.yupErrMsg('phoneNumber'))
                            ? {
                                name: "error",
                                fn: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.yupErrMsg('phoneNumber')), 1)
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["value", "showError"]),
                        (_ctx.phoneNumber)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.step === 2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _cache[21] || (_cache[21] = _createElementVNode("p", { class: "step_number" }, "Step 2", -1)),
                    _cache[22] || (_cache[22] = _createElementVNode("h1", { class: "step_title" }, "Supplier information", -1)),
                    _cache[23] || (_cache[23] = _createElementVNode("p", { class: "step_hint" }, "This gives us an idea of your current supplier numbers and gap to fill", -1)),
                    _createElementVNode("div", null, [
                      _cache[14] || (_cache[14] = _createElementVNode("p", { class: "input_title" }, "Name the suppliers you already order from", -1)),
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_app_input, {
                          id: "foodSuppliers",
                          placeholder: "Separate suppliers with a comma",
                          value: _ctx.foodSuppliers,
                          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.foodSuppliers) = $event)),
                          showError: _ctx.show,
                          addErrorPlaceholderSpacing: ""
                        }, null, 8, ["value", "showError"]),
                        (_ctx.numberOfChosenSuppliers)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_11))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", null, [
                      _cache[15] || (_cache[15] = _createElementVNode("p", { class: "input_title" }, "Happy for us to add them to your custom marketplace?", -1)),
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("p", {
                          class: _normalizeClass(["custom-cursor-hover", { active: _ctx.happyToContactSupplier }]),
                          onClick: _cache[3] || (_cache[3] = () => (_ctx.happyToContactSupplier = true))
                        }, " Yes ", 2),
                        _createElementVNode("p", {
                          class: _normalizeClass(["custom-cursor-hover", { active: !_ctx.happyToContactSupplier }]),
                          onClick: _cache[4] || (_cache[4] = () => (_ctx.happyToContactSupplier = false))
                        }, " No ", 2)
                      ])
                    ]),
                    _createElementVNode("div", null, [
                      _cache[20] || (_cache[20] = _createElementVNode("p", { class: "input_title" }, "What are the gaps in your supplier list?", -1)),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["supplier_gaps_button custom-cursor-hover", { active: _ctx.isCuisinesDropdownShown }]),
                          onClick: _cache[5] || (_cache[5] = _withModifiers(() => (_ctx.isCuisinesDropdownShown = !_ctx.isCuisinesDropdownShown), ["prevent"]))
                        }, [
                          _cache[16] || (_cache[16] = _createElementVNode("img", {
                            src: _imports_2,
                            alt: ""
                          }, null, -1)),
                          _createElementVNode("p", null, "Cuisines (" + _toDisplayString(_ctx.numberOfChosenCuisines) + ")", 1),
                          _cache[17] || (_cache[17] = _createElementVNode("img", {
                            src: _imports_3,
                            alt: ""
                          }, null, -1))
                        ], 2),
                        (_ctx.isCuisinesDropdownShown)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                              _createElementVNode("div", _hoisted_15, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supplierCuisines, (value, key) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "dropdown_list_item",
                                    key: key
                                  }, [
                                    _createElementVNode("p", null, _toDisplayString(key), 1),
                                    _createVNode(_component_app_option, {
                                      type: "checkbox",
                                      active: _ctx.supplierCuisines[key],
                                      onClick: _withModifiers(() => (_ctx.supplierCuisines[key] = !_ctx.supplierCuisines[key]), ["prevent"])
                                    }, null, 8, ["active", "onClick"])
                                  ]))
                                }), 128))
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", {
                          class: _normalizeClass(["supplier_gaps_button custom-cursor-hover", { active: _ctx.isMomentsDropdownShown }]),
                          onClick: _cache[6] || (_cache[6] = _withModifiers(() => (_ctx.isMomentsDropdownShown = !_ctx.isMomentsDropdownShown), ["prevent"]))
                        }, [
                          _cache[18] || (_cache[18] = _createElementVNode("img", {
                            src: _imports_4,
                            alt: ""
                          }, null, -1)),
                          _createElementVNode("p", null, "Moments (" + _toDisplayString(_ctx.numberOfChosenMoments) + ")", 1),
                          _cache[19] || (_cache[19] = _createElementVNode("img", {
                            src: _imports_3,
                            alt: ""
                          }, null, -1))
                        ], 2),
                        (_ctx.isMomentsDropdownShown)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                              _createElementVNode("div", _hoisted_17, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supplierMoments, (value, key) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "dropdown_list_item",
                                    key: key
                                  }, [
                                    _createElementVNode("p", null, _toDisplayString(key), 1),
                                    _createVNode(_component_app_option, {
                                      type: "checkbox",
                                      active: _ctx.supplierMoments[key],
                                      onClick: _withModifiers(() => (_ctx.supplierMoments[key] = !_ctx.supplierMoments[key]), ["prevent"])
                                    }, null, 8, ["active", "onClick"])
                                  ]))
                                }), 128))
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!_ctx.isBodyLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.areHeaderAndFooterLoaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", {
                    style: _normalizeStyle(`width: ${_ctx.progressBarWidth}%`)
                  }, null, 4)
                ]),
                _cache[24] || (_cache[24] = _createElementVNode("div", null, null, -1)),
                _cache[25] || (_cache[25] = _createElementVNode("div", null, null, -1)),
                _cache[26] || (_cache[26] = _createElementVNode("div", null, null, -1))
              ]),
              _createElementVNode("div", _hoisted_21, [
                (_ctx.step === 2)
                  ? (_openBlock(), _createBlock(_component_app_button, {
                      key: 0,
                      class: "back_button",
                      square: "",
                      slim: "",
                      onClick: _ctx.goToStep1
                    }, {
                      default: _withCtx(() => _cache[27] || (_cache[27] = [
                        _createTextVNode("Previous step")
                      ])),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_app_button, {
                  class: "next_button",
                  square: "",
                  slim: "",
                  onClick: _ctx.goToNextStep,
                  loader: _ctx.isSendingRequest,
                  disabled: _ctx.isNextStepDisabled || _ctx.isSendingRequest
                }, {
                  default: _withCtx(() => _cache[28] || (_cache[28] = [
                    _createTextVNode(" Next step ")
                  ])),
                  _: 1
                }, 8, ["onClick", "loader", "disabled"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}