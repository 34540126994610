<template>
  <div class="restaurant-basket">
    <div class="basket">
      <ZigZag />
      <ZigZag class="rotate" />
      <div class="basket-title">
        <h2>{{ checkout ? 'Checkout' : 'Basket' }}</h2>
        <img
          v-if="restaurant?.logo && $route.params.slug === restaurant?.slug"
          width="33"
          height="33"
          :src="restaurant?.logo"
          alt="restaurant logo"
        />
      </div>

      <!-- Basket with items -->
      <div
        class="basket-wrapper"
        v-if="
          (!groupData && dishes.length > 0 && $route.params.slug === restaurant?.slug) ||
          (groupData && $route.params.slug === groupData.restaurantSlug)
        "
      >
        <div class="scrollable-part">
          <!-- HEADER -->
          <UnavailableDeliveryBanner
            v-if="restaurant && !restaurant.isClosed && !restaurant.isTemporarilyClose"
            :restaurant="restaurant"
          />
          <OrderSettingsButton
            v-if="restaurant && !amendableData && (!groupData || checkout)"
            :restaurant="restaurant"
            :basketTotal="+cartPrice"
            :disabled="
              orderSettingsDisabled ||
              checkout ||
              (restaurant && !restaurant.allowScheduledOrders && !restaurant.clickAndCollect)
            "
          />
          <AmendableOrderInfo
            :isMobile="false"
            :restaurant="restaurant"
            class="basket-group-order-info"
            v-if="!isMobile && amendableData && $route.params.slug === amendableData.restaurantSlug"
          />

          <!-- TITLE -->
          <div class="basket-title-items">
            <div class="basket-title-group" v-if="!groupData">
              <h2 class="basket-title-group-header">Items</h2>
              <p
                v-if="isSuperAdmin && !checkout && !amendableData"
                class="basket-title-link custom-cursor-hover"
                @click="shareBasket()"
              >
                Share basket
              </p>
            </div>
            <div class="basket-title-group" v-if="groupData && $route.params.slug === groupData.restaurantSlug">
              <h2 class="basket-title-group-header">
                {{ `${groupRole === 'Owner' ? 'Your' : groupOwnerWithApostrophe} group order` }}
              </h2>
            </div>
            <div class="divider ham full-width" />
          </div>

          <!-- ITEMS -->
          <div class="basket-list" v-bind:style="{ maxHeight: checkout ? '67vh' : undefined }">
            <GroupOrderBaskets
              :checkout="checkout"
              v-if="groupData && $route.params.slug === groupData.restaurantSlug"
            />
            <BasketItem
              v-else
              :edit="checkout ? false : true"
              :class="{ 'remove-border': index === dishes.length - 1 }"
              v-for="(i, index) in dishes"
              :key="index"
              :dish="i"
              :indexDish="index"
            />
          </div>

          <div v-if="!checkout" class="divider ham full-width" style="margin-bottom: 15px" />

          <!-- AMENDABLE ORDER'S PRICES -->
          <div v-if="amendableData && !checkout">
            <div class="form-scoring">
              <h3 class="c-black">Updated subtotal</h3>
              <div class="form-scoring-price">
                <p class="c-black">£{{ discountedCartPrice.toFixed(2) }}</p>
                <p
                  class="c-black previous-price"
                  v-if="hasBigOrderDiscount || (coupon && coupon.id && coupon.type !== 'free_delivery')"
                >
                  £{{ cartPrice.toFixed(2) }}
                </p>
              </div>
            </div>

            <div class="form-scoring">
              <h3 class="c-black">Updated service fee</h3>
              <div class="form-scoring-price">
                <p class="c-black">£{{ serviceFee.toFixed(2) }}</p>
              </div>
            </div>

            <div class="form-scoring" v-if="smallOrderFee > 0">
              <h3 class="c-black">Updated small order fee</h3>
              <div class="form-scoring-price">
                <p class="c-black">£{{ smallOrderFee.toFixed(2) }}</p>
              </div>
            </div>

            <div class="form-scoring" v-if="type === 'delivery'">
              <h3 class="c-black">Updated delivery fee</h3>
              <div class="form-scoring-price">
                <p class="c-black">£{{ deliveryFee.toFixed(2) }}</p>
              </div>
            </div>

            <div class="form-scoring" v-if="tip > 0">
              <h3 class="c-black">Tips</h3>
              <div class="form-scoring-price">
                <p class="c-black">£{{ tip.toFixed(2) }}</p>
              </div>
            </div>

            <p class="c-black td-u fee-info custom-cursor-hover" @click.prevent="showFeeDetails">Our fees</p>
          </div>

          <!-- GROUP ORDER'S PARTICIPANT PRICES -->
          <div v-else-if="groupData && groupRole === 'Participant' && !checkout">
            <div class="form-scoring">
              <h3 class="c-black" style="font-size: 20px">Your Items</h3>
              <div class="form-scoring-price">
                <p class="c-black">£{{ getParticipantCartPrice.toFixed(2) }}</p>
              </div>
            </div>

            <div class="form-scoring">
              <h3 class="c-black">Order subtotal</h3>
              <div class="form-scoring-price">
                <p class="c-black">£{{ cartPrice.toFixed(2) }}</p>
                <p class="c-black previous-price" v-if="coupon && coupon.id && coupon.type !== 'free_delivery'">
                  £{{ cartPrice.toFixed(2) }}
                </p>
              </div>
            </div>
          </div>

          <div v-else-if="!checkout">
            <div class="form-scoring">
              <h3 class="c-black">Subtotal</h3>
              <div class="form-scoring-price">
                <p class="c-black">£{{ discountedCartPrice.toFixed(2) }}</p>
                <p
                  class="c-black previous-price"
                  v-if="hasBigOrderDiscount || (coupon && coupon.id && coupon.type !== 'free_delivery')"
                >
                  £{{ cartPrice.toFixed(2) }}
                </p>
              </div>
            </div>

            <div class="form-scoring">
              <h3 class="c-black">Service fee</h3>
              <div class="form-scoring-price">
                <p class="c-black">£{{ serviceFee.toFixed(2) }}</p>
              </div>
            </div>

            <div class="form-scoring" v-if="smallOrderFee > 0">
              <h3 class="c-black">Small order fee</h3>
              <div class="form-scoring-price">
                <p class="c-black">£{{ smallOrderFee.toFixed(2) }}</p>
              </div>
            </div>

            <div class="delivery-fee-info" v-if="type === 'delivery'">
              <div class="form-scoring">
                <h3 class="c-black">Delivery fee</h3>
                <div class="form-scoring-price">
                  <p class="c-black">£{{ deliveryFee.toFixed(2) }}</p>
                </div>
              </div>
              <p class="vehicle-type" v-if="isLargeVehicleType">This delivery requires a large vehicle type</p>
            </div>

            <p class="c-black td-u fee-info custom-cursor-hover" @click.prevent="showFeeDetails">Our fees</p>
          </div>
        </div>
        <div class="unscrollable-part">
          <CouponField v-if="!checkout && groupRole !== 'Participant'" class="coupon-field" />

          <div class="min-cart-price-info-basket" v-if="remainingCartPrice">
            {{ remainingCartPrice }}
          </div>

          <!-- AMENDABLE ORDER'S CONTAINER -->
          <FormScoring v-if="amendableData && !checkout">
            <template v-slot:footer>
              <div class="form-scoring total" style="margin-bottom: 5px; margin-top: 5px">
                <h3 class="c-ham">Updated total</h3>
                <div class="form-scoring-price">
                  <h3 class="c-ham">£{{ total }}</h3>
                </div>
              </div>

              <div class="form-scoring total" style="margin-bottom: 15px">
                <h3 class="c-ham">{{ totalDiff >= 0 ? 'Amount to pay' : "We'll refund" }}</h3>
                <div class="form-scoring-price">
                  <h3 class="c-ham">£{{ (totalDiff > 0 ? totalDiff : -totalDiff).toFixed(2) }}</h3>
                </div>
              </div>

              <div class="items-buttons-wrapper">
                <app-button
                  @click.prevent="goTo"
                  :disabled="
                    (groupData && groupData.baskets.length === 0 && dishes.length === 0) || !!remainingCartPrice
                  "
                  id="proceed_to_checkout"
                >
                  <strong v-if="groupData && groupData.baskets.length === 0 && dishes.length === 0"
                    >No items added</strong
                  >
                  <strong v-else>Check out to update order</strong>
                </app-button>

                <app-button @click.prevent="revertAmendedChanges" class="revert-changes-button">
                  <strong>Revert changes</strong>
                </app-button>
              </div>
            </template>
          </FormScoring>

          <!-- GROUP ORDER'S PARTICIPANT CONTAINER -->
          <FormScoring v-else-if="groupData && groupRole === 'Participant' && !checkout" :disabled="areItemsConfirmed">
            <template v-slot:footer>
              <div v-if="areItemsConfirmed" class="items-confirmed-info">
                <p class="items-confirmed-info-header">What happens now?</p>
                <p class="items-confirmed-info-description">
                  {{ `Your items have been added to ${groupOwnerWithApostrophe} order.` }}
                  We’ll let you know when the order has been placed.
                </p>
              </div>
              <div class="items-buttons-wrapper">
                <app-button
                  @click.prevent="confirmBasketItems"
                  :disabled="areItemsConfirmed || !dishes.length || hasDeadlinePassed || isOrderNotActive"
                  id="proceed_to_checkout"
                  class="items-confirmed-button"
                  :loader="isConfirming"
                >
                  <strong>{{ confirmButtonText }}</strong>
                </app-button>
              </div>
            </template>
          </FormScoring>

          <!-- GROUP ORDER'S OWNER OR STANDARD ORDER'S USER CONTAINER -->
          <FormScoring v-else-if="!checkout">
            <template v-slot:footer>
              <div class="form-scoring total" style="margin-bottom: 15px; margin-top: 5px">
                <h3 class="c-ham">Total</h3>
                <div class="form-scoring-price">
                  <h3 class="c-ham">£{{ total }}</h3>
                </div>
              </div>
              <div class="items-buttons-wrapper">
                <app-button v-if="isTooFar" class="restaurant-too-far-button" @click.prevent="showSetAddressModal">
                  Restaurant is too far
                </app-button>

                <app-button
                  v-else
                  @click.prevent="goTo"
                  :disabled="
                    (groupData && groupData.baskets.length === 0 && dishes.length === 0) ||
                    restaurant.isClosed ||
                    restaurant.isTemporarilyClose ||
                    (restaurant.deliveryLabel && type === 'delivery') ||
                    !!remainingCartPrice
                  "
                  id="proceed_to_checkout"
                >
                  <strong v-if="groupData && groupData.baskets.length === 0 && dishes.length === 0">
                    No items added
                  </strong>
                  <strong v-else-if="restaurant.isTemporarilyClose">Back soon</strong>
                  <strong v-else-if="restaurant.isClosed">Restaurant closed</strong>
                  <strong v-else-if="restaurant.deliveryLabel && type === 'delivery'">{{
                    restaurant.deliveryLabel
                  }}</strong>
                  <strong v-else>Proceed to checkout</strong>
                </app-button>
              </div>
            </template>
          </FormScoring>
        </div>
      </div>

      <!-- Empty basket -->
      <div v-else>
        <!-- HEADER -->
        <UnavailableDeliveryBanner
          v-if="restaurant && !restaurant.isClosed && !restaurant.isTemporarilyClose"
          :restaurant="restaurant"
        />
        <OrderSettingsButton
          v-if="restaurant && !amendableData && (!groupData || checkout)"
          :restaurant="restaurant"
          :basketTotal="+cartPrice"
          :disabled="
            orderSettingsDisabled ||
            checkout ||
            (restaurant && !restaurant.allowScheduledOrders && !restaurant.clickAndCollect)
          "
        />

        <!-- BODY -->
        <div class="basket-wrapper empty">
          <IconEmptyBasket />
          <h2>Empty basket.<br />Empty belly.</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { watch } from 'vue';
import { Options, Vue, prop } from 'vue-class-component';
import { mapState } from 'vuex';

import CouponField from '@/components/Basket/CouponField.vue';
import GroupOrderBaskets from '@/components/Basket/GroupOrderBaskets.vue';
import BasketItem from '@/components/Basket/Item.vue';
import ZigZag from '@/components/Basket/ZigZag.vue';
import FormScoring from '@/components/FormScoring.vue';
import AmendableOrderInfo from '@/components/Restaurant/AmendableOrderInfo.vue';
import OrderSettingsButton from '@/components/Restaurant/OrderSettingsButton.vue';
import UnavailableDeliveryBanner from '@/components/UnavailableDeliveryBanner.vue';

import groupOrdersHttp from '@/requests/groupOrders';

import IconEmptyBasket from '@/shared/icons/IconEmptyBasket.vue';

import methods from '@/utils/methods';
import pipes from '@/utils/pipes';

class Props {
  restaurant = prop<FullInfoRestaurant>({ required: true });
  orderSettingsDisabled = prop<boolean>({ required: true });
  checkout = prop<boolean>({ required: true });
  isMobile = prop<boolean>({ required: true });
}

@Options({
  components: {
    ZigZag,
    FormScoring,
    BasketItem,
    AmendableOrderInfo,
    GroupOrderBaskets,
    CouponField,
    IconEmptyBasket,
    OrderSettingsButton,
    UnavailableDeliveryBanner,
  },
  props: {
    checkout: Boolean,
    restaurant: Object,
    orderSettingsDisabled: Boolean,
    isMobile: Boolean,
  },
  computed: {
    ...mapState('yup', ['show']),
    ...mapState('basket', ['dishes', 'tip', 'coupon', 'isTooFar']),
    ...mapState('order', ['type']),
    ...mapState('groupOrder', ['groupData', 'groupRole', 'areItemsConfirmed']),
    ...mapState('amendableOrder', { amendableData: 'data' }),
    ...mapState('service', ['isSuperAdmin']),
  },
})
export default class BasketWrapper extends Vue.with(Props) {
  private transition = false;

  private initialDeliveryFee = 0;
  private deliveryFee = 0;
  private minTotalPrice = 0;

  private hasDeadlinePassed = false;
  private isOrderNotActive = false;
  private isConfirming = false;

  get estimatedDeliveryTime() {
    if (!this.$store.state.basket.restaurant) return { best: 0, worst: 0 };

    const calculatedDeliveryTime = this.$store.state.basket.restaurant?.deliveryInfo.time;

    return { best: calculatedDeliveryTime.best, worst: calculatedDeliveryTime.worst };
  }

  get restaurantLogo() {
    return this.$store.state.basket.restaurant?.logo;
  }

  get discountedCartPrice() {
    return methods.getDiscountedCartPrice();
  }

  get cartPrice() {
    return methods.getCartPrice();
  }

  get total() {
    return methods.getTotalPrice();
  }

  get totalDiff() {
    return methods.getTotalPriceDifference();
  }

  get serviceFee() {
    return methods.getServiceFee(this.$store.state.basket.restaurant?.deliveryInfo.percentServiceFee);
  }

  get smallOrderFee() {
    const deliveryInfo = this.$store.state.basket.restaurant?.deliveryInfo;
    if (!deliveryInfo) return 0;
    return methods.getSmallOrderFee(deliveryInfo.minSOFBasketPrice, deliveryInfo.perPoundSOFFactor);
  }

  get remainingCartPrice() {
    const { groupOrder, basket } = this.$store.state;
    if (groupOrder.groupData && groupOrder.groupRole === 'Participant') return null;

    const minCartPrice = basket.restaurant?.minCartPrice || 0;
    const moneyLeftToPlaceAnOrder = minCartPrice - this.cartPrice;

    if (moneyLeftToPlaceAnOrder <= 0) return null;
    return `You need to spend £${moneyLeftToPlaceAnOrder.toFixed(2)} more to check out`;
  }

  get hasBigOrderDiscount() {
    const { groupOrder, basket } = this.$store.state;
    if (groupOrder.groupData && groupOrder.groupRole === 'Participant') return false;

    return (
      basket.restaurant &&
      basket.restaurant.discountPercentage &&
      basket.restaurant.discountMinCartPrice <= this.cartPrice &&
      methods.isDeliveryTimeLateEnough(basket.restaurant.discountMinHoursInAdvance)
    );
  }

  get isLargeVehicleType() {
    const deliveryInfo = this.$store.state.basket.restaurant?.deliveryInfo;
    return deliveryInfo && this.cartPrice > deliveryInfo.deliveryFeeBreakpoint;
  }

  get getParticipantCartPrice() {
    return methods.getParticipantCartPrice();
  }

  get groupOwnerWithApostrophe() {
    if (!this.$store.state.groupOrder.groupData) return '';
    return pipes.addApostrophe(this.$store.state.groupOrder.groupData.ownerName);
  }

  get confirmButtonText() {
    if (this.hasDeadlinePassed) return 'The deadline has passed';

    if (this.isOrderNotActive) return 'This order is not active anymore';

    return this.$store.state.groupOrder.areItemsConfirmed ? 'Items added' : 'Add your items';
  }

  private async confirmBasketItems() {
    const { groupOrder, basket } = this.$store.state;
    if (this.isConfirming || !groupOrder.groupData) return;

    if (moment(groupOrder.groupData.deadline).isBefore()) {
      this.hasDeadlinePassed = true;
      return;
    }

    try {
      this.isConfirming = true;

      const { id } = await groupOrdersHttp.addBasketToGroupOrder(groupOrder.groupData.id, {
        userName: groupOrder.participantName,
        cart: {
          dishes: basket.dishes,
          restaurantSlug: this.$store.state.basket.restaurant?.slug,
          restaurantId: this.$store.state.basket.restaurant?.id,
        },
      });

      const updatedGroupOrder = await groupOrdersHttp.getGroupOrder(groupOrder.groupData.id);

      this.$store.commit('groupOrder/update', { ...updatedGroupOrder });
      this.$store.commit('groupOrder/setParticipantBasketId', id);
      this.$store.commit('groupOrder/addParticipantBasketIdToHistory', id);
      this.$store.commit('groupOrder/itemsConfirmed');
      this.isConfirming = false;
    } catch (error) {
      if ((error as { message: string }).message.includes('not active')) {
        this.isOrderNotActive = true;
        this.isConfirming = false;
      }
    }
  }

  private revertAmendedChanges() {
    const friendlyId = this.$store.state.amendableOrder.data?.friendlyId;
    if (!friendlyId) return;

    this.$store.commit('basket/clear');
    this.$store.commit('groupOrder/reset');
    this.$store.commit('amendableOrder/clear');
    this.$store.commit('order/scheduledDeliveryInterval', null);
    this.$store.commit('order/scheduledDeliveryDate', null);
    this.$router.push(`/orders-tracker?orderId=${friendlyId}`);
  }

  private async goTo() {
    const isAmendingOrder = !!this.$store.state.amendableOrder.data;
    if (this.$store.state.groupOrder.groupData && !isAmendingOrder) {
      const groupOrder = await groupOrdersHttp.getGroupOrder(this.$store.state.groupOrder.groupData.id);

      if (!groupOrder) {
        // the order doesn't exists or is not active anymore
        this.$store.commit('groupOrder/reset');
        this.$store.commit('basket/clear');
        return;
      }

      this.$store.commit('groupOrder/update', { ...groupOrder });

      if (groupOrder.baskets.length < 1 && this.$store.state.basket.dishes.length < 1) {
        return;
      }
    }

    document.body.classList.remove('no-scroll');
    if (this.$store.state.service.user) {
      this.$router.push(`${this.$route.path}/checkout`);
    } else {
      this.$store.commit('modals/reset');
      this.$store.commit('modals/show', this.$route.query.embedded ? 'login' : 'auth');
    }
  }

  private showFeeDetails() {
    this.$store.commit('modals/show', 'feeDetails');
  }

  private showSetAddressModal() {
    this.$store.commit('modals/data', {
      withOrderTypeSwitch: false,
      withAddressChange: true,
      withDeliveryTimeChange: true,
      restaurant: this.restaurant,
    });
    this.$store.commit('modals/show', 'orderSettings');
  }

  private async shareBasket() {
    this.$store.commit('modals/show', 'sharePrefilledBasketLink');
  }

  private updateDeliveryFees() {
    const deliveryInfo = this.$store.state.basket.restaurant?.deliveryInfo;
    if (this.$store.state.order.type === 'delivery' && deliveryInfo) {
      // NOTE: Delivering big orders takes place with usage of vans instead of bikes, so user needs to pay more:
      this.deliveryFee =
        this.cartPrice > deliveryInfo.deliveryFeeBreakpoint ? deliveryInfo.bigOrderFee : deliveryInfo.fee;
      this.initialDeliveryFee =
        this.cartPrice > deliveryInfo.deliveryFeeBreakpoint ? deliveryInfo.bigOrderInitialFee : deliveryInfo.initialFee;
    } else {
      this.deliveryFee = 0;
      this.initialDeliveryFee = 0;
      return;
    }

    if (this.$store.state.order.type === 'delivery' && this.$store.state.basket.coupon?.type === 'free_delivery') {
      this.deliveryFee = 0;
    }
  }

  mounted() {
    const isAmendingOrder = !!this.$store.state.amendableOrder.data;
    if (!this.checkout && !isAmendingOrder) this.$store.commit('basket/coupon', null);

    watch(this.$store.state.groupOrder, () => {
      const { groupData } = this.$store.state.groupOrder;
      if (!groupData) return;

      if (groupData.deadline && moment(groupData.deadline).isBefore()) this.hasDeadlinePassed = true;
      if (groupData?.baskets.length > 0) {
        this.$store.commit('basket/restaurant', methods.filterRestaurantInfo(this.restaurant));
      }
    });

    watch(this.$store.state.basket, () => this.updateDeliveryFees());

    watch(
      () => [this.$store.state.order.type, this.$store.state.address.formattedAddress],
      () => {
        this.updateDeliveryFees();
      },
    );

    this.updateDeliveryFees();
  }
}
</script>

<style lang="scss" scoped>
h3 {
  @include h3;
  margin-bottom: 5px;
}
.zigzag {
  position: absolute;
  left: 0;
  top: -7px;
  &.rotate {
    top: auto;
    bottom: -7px;
    transform: scale(1, -1);
  }
}
.basket-address {
  width: 100%;
  padding: 1rem;
  margin: 2rem auto 0 auto;
}
.basket {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  width: 374px;
  box-sizing: content-box;
  border-left: 1px solid $coal20;
  border-right: 1px solid $coal20;
  padding-bottom: 10px;
  background-color: $white;

  @include for-smartphone {
    padding-bottom: 25px;
    width: 100%;
  }

  &-wrapper {
    max-width: 375px;

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-height: 450px;
      text-align: center;
      gap: 50px;
    }

    .scrollable-part {
      padding: 0 15px;
      max-height: 45vh;
      overflow-y: scroll;
      overflow-x: hidden;

      @include for-smartphone {
        max-height: unset;
      }
    }

    .unscrollable-part {
      padding: 15px 15px 0px 15px;
    }
  }

  &-title {
    @include row-centered;
    position: relative;
    border-bottom: 1px solid $coal20;
    padding: 12px 15px;

    img {
      position: absolute;
      right: 15px;
      top: 8px;
      border-radius: 50px;
      aspect-ratio: 1;
    }

    &-items {
      margin-top: 15px;
      margin-bottom: 10px;
    }

    &-group {
      display: flex;
      justify-content: space-between;
    }

    &-link {
      color: $darkHam;
      font-weight: 600;
      align-self: center;
      text-decoration: underline;
    }
  }

  &-estimated {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    .estimated {
      display: flex;
      align-items: center;
      border-radius: 15px;
      padding: 4px 10px;
      p {
        font-weight: 600;
      }
      svg {
        width: 19px;
        margin-right: 10px;
      }
    }
  }

  .normal-theme {
    background-color: rgba(84, 164, 72, 0.2);
  }
  .busy-theme {
    background-color: rgba(209, 165, 99, 0.2);
  }
  .chaos-theme {
    background-color: rgba(215, 112, 118, 0.2);
  }

  &-list {
    display: grid;
    grid-template-columns: 345px;
    gap: 10px 0;
    margin-bottom: 10px;
  }
}

.vehicle-type {
  @include p1;
  font-size: 14px;
  color: $coal60;
}
.fee-info {
  @include p1;
  font-size: 15px;
  margin-top: 10px;
}
.previous-price {
  text-decoration: line-through;
  margin-left: 5px;
}
.form-scoring-wrapper {
  margin-top: 10px;
}

.items-confirmed-info {
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding-bottom: 25px;
  &-header {
    @include p2;
  }

  &-description {
    font-weight: 300;
  }

  p {
    color: white;
  }
}

.items-buttons-wrapper {
  display: flex;
  flex-direction: column;
  gap: 17px;
  align-items: center;
}

.items-confirmed-button {
  width: 100%;
  max-width: unset;
}

.restaurant-too-far-button {
  color: $coal60;
  background-color: $snow;
  border: 1px solid $coal20;
  display: flex;
  flex-direction: column;
}

.revert-changes-button {
  width: unset;
  max-width: unset;
  min-height: unset;
  padding: 10px 20px;
  padding-bottom: 8px;
  margin: 0 auto;
  background-color: $white;
}

.min-cart-price-info-basket {
  font-family:
    Sofia Pro,
    sans-serif;
  font-size: 15px;
  font-weight: 600;
  background-color: $hamOp50;
  padding: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
  border-radius: 10px;
  justify-content: center;
  display: flex;

  @include for-smartphone {
    font-size: 14px;
  }
}
</style>
