<template>
  <div class="cookies">
    <h1 class="title_cookies">
      We use cookies to provide a tastier web experience. You can change your settings or proceed.
    </h1>
    <div class="actions">
      <app-button @click="accessCookies" slim class="mr_10"><span>Ok</span></app-button>
      <a href="/boring-stuff/cookie-policy">Tell me more</a>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  emits: {
    accessCookies: String,
  },
})
export default class Cookies extends Vue {
  public accessCookies() {
    this.$emit('accessCookies', '');
  }
}
</script>

<style scoped lang="scss">
.mr_10 {
  margin-right: 10px;
}
.cookies {
  position: fixed;
  background-image: url('../assets/cookies.svg');
  width: 344px;
  height: 294px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  padding-top: 20px;
  z-index: 100000;
  left: 0;
  bottom: 0;
  @include for-smartphone {
    transform: unset;
    position: fixed;
    bottom: 20px;
    top: unset;
    background: $vanilla;
    border-radius: 15px;
    border: 1px solid $coal100;
    width: 345px;
    height: 143px;
    padding: 14px 16px;
    left: 0;
    right: 0;
    margin: 0 auto;
    box-shadow: 10px 10px 0 $coal100;
  }
}
.title_cookies {
  margin-bottom: 20px;
  font-size: 22px;
  max-width: 216px;
  @include for-smartphone {
    font-family:
      Sofia Pro,
      sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    max-width: unset;
    line-height: 19px;
  }
}
.actions {
  display: flex;
  align-items: center;
}
</style>
