import * as auth from 'firebase/auth';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state: StateService = {
  firebaseUser: null,
  user: null,
  update: '',
  isSuperAdmin: false, // for sharing baskets
};

const mutations = {
  update(state: StateService, payload: string) {
    state.update = payload;
  },
  user(state: StateService, user: User | null) {
    state.user = user;
  },
  firebaseUser(state: StateService, firebaseUser: auth.User | null) {
    state.firebaseUser = firebaseUser;
  },
  isSuperAdmin(state: StateService, isSuperAdmin: boolean) {
    state.isSuperAdmin = isSuperAdmin;
  },
};

const actions = {
  //
};

const getters = {
  //
};

export interface StateService {
  // User profile from Firebase Authentication:
  firebaseUser: auth.User | null;
  // User profile from Firebase Firestore:
  user: User | null;
  // Re-fetching restaurants or payment methods:
  update: string;
  // Whether has super-admin role and can share basket:
  isSuperAdmin: boolean;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
