import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    href: _ctx.href,
    class: _normalizeClass(["controller-button", {
      active: _ctx.active,
      anchor: _ctx.anchor,
      mini: _ctx.mini,
      slim: _ctx.slim,
      white: _ctx.white,
      square: _ctx.square,
    }]),
    disabled: _ctx.disabled || _ctx.loader
  }, {
    default: _withCtx(() => [
      (!_ctx.loader)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 })
        : _createCommentVNode("", true),
      (_ctx.loader)
        ? (_openBlock(), _createBlock(_component_Logo, {
            key: 1,
            class: "logo-loader"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["href", "class", "disabled"]))
}