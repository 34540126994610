import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '../../assets/check.svg'


const _hoisted_1 = {
  key: 0,
  class: "group-order-step-tracker hide-mobile"
}
const _hoisted_2 = { class: "group-order-step" }
const _hoisted_3 = {
  key: 0,
  class: "check"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "group-order-step" }
const _hoisted_8 = {
  key: 0,
  class: "check"
}
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "group-order-step" }
const _hoisted_11 = {
  key: 0,
  class: "check"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = {
  key: 1,
  class: "group-order-step-tracker hide-mobile"
}
const _hoisted_16 = { class: "group-order-step-tracker hide-desktop" }
const _hoisted_17 = {
  key: 0,
  class: "group-order-step-mobile"
}
const _hoisted_18 = {
  key: 0,
  class: "texts"
}
const _hoisted_19 = {
  key: 1,
  class: "texts"
}
const _hoisted_20 = {
  key: 1,
  class: "group-order-step-mobile"
}
const _hoisted_21 = {
  key: 2,
  class: "group-order-step-mobile"
}
const _hoisted_22 = {
  key: 0,
  class: "texts"
}
const _hoisted_23 = {
  key: 1,
  class: "texts"
}
const _hoisted_24 = {
  key: 3,
  class: "group-order-step-mobile"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.step < 4)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass(["circle", { inactive: _ctx.step < 1 }])
            }, [
              (_ctx.step > 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: ""
                    }, null, -1)
                  ])))
                : (_openBlock(), _createElementBlock("p", _hoisted_4, "1"))
            ], 2),
            (_ctx.groupRole === 'Owner')
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _cache[1] || (_cache[1] = [
                  _createTextVNode("Create and share"),
                  _createElementVNode("br", null, null, -1),
                  _createTextVNode("your group link")
                ])))
              : (_openBlock(), _createElementBlock("p", _hoisted_6, _cache[2] || (_cache[2] = [
                  _createTextVNode("Add your name and"),
                  _createElementVNode("br", null, null, -1),
                  _createTextVNode("click 'View menu'")
                ])))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: _normalizeClass(["circle", { inactive: _ctx.step < 2 }])
            }, [
              (_ctx.step > 2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[3] || (_cache[3] = [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: ""
                    }, null, -1)
                  ])))
                : (_openBlock(), _createElementBlock("p", _hoisted_9, "2"))
            ], 2),
            _cache[4] || (_cache[4] = _createElementVNode("p", null, [
              _createTextVNode("Choose your items"),
              _createElementVNode("br"),
              _createTextVNode("and add to basket")
            ], -1)),
            _cache[5] || (_cache[5] = _createElementVNode("hr", { class: "divider_left" }, null, -1)),
            _cache[6] || (_cache[6] = _createElementVNode("hr", { class: "divider_right" }, null, -1))
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", {
              class: _normalizeClass(["circle", { inactive: _ctx.step < 3 }])
            }, [
              (_ctx.step > 3)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[7] || (_cache[7] = [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: ""
                    }, null, -1)
                  ])))
                : (_openBlock(), _createElementBlock("p", _hoisted_12, "3"))
            ], 2),
            (_ctx.groupRole === 'Owner')
              ? (_openBlock(), _createElementBlock("p", _hoisted_13, _cache[8] || (_cache[8] = [
                  _createTextVNode("Checkout when all users"),
                  _createElementVNode("br", null, null, -1),
                  _createTextVNode("have added their items")
                ])))
              : (_openBlock(), _createElementBlock("p", _hoisted_14, _cache[9] || (_cache[9] = [
                  _createTextVNode("Click 'Add your items'"),
                  _createElementVNode("br", null, null, -1),
                  _createTextVNode("then close tab")
                ])))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_15, _cache[10] || (_cache[10] = [
          _createElementVNode("div", { class: "group-order-step finished" }, [
            _createElementVNode("div", { class: "circle" }, [
              _createElementVNode("div", { class: "check" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                })
              ])
            ]),
            _createElementVNode("p", null, "You're all set! You can now close this tab.")
          ], -1)
        ]))),
    _createElementVNode("div", _hoisted_16, [
      (_ctx.step === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "circle with-step-word" }, [
              _createElementVNode("p", null, "STEP"),
              _createElementVNode("p", null, "1/3")
            ], -1)),
            (_ctx.groupRole === 'Owner')
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[11] || (_cache[11] = [
                  _createElementVNode("b", null, "Create and share", -1),
                  _createElementVNode("p", null, "Create and share your group link", -1)
                ])))
              : (_openBlock(), _createElementBlock("div", _hoisted_19, _cache[12] || (_cache[12] = [
                  _createElementVNode("b", null, "Add your name", -1),
                  _createElementVNode("p", null, "Add your name and click 'View menu'", -1)
                ])))
          ]))
        : _createCommentVNode("", true),
      (_ctx.step === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, _cache[14] || (_cache[14] = [
            _createElementVNode("div", { class: "circle with-step-word" }, [
              _createElementVNode("p", null, "STEP"),
              _createElementVNode("p", null, "2/3")
            ], -1),
            _createElementVNode("div", { class: "texts" }, [
              _createElementVNode("b", null, "Choose your items"),
              _createElementVNode("p", null, "Choose a dish and click 'Add to basket'")
            ], -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.step === 3)
        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "circle with-step-word" }, [
              _createElementVNode("p", null, "STEP"),
              _createElementVNode("p", null, "3/3")
            ], -1)),
            (_ctx.groupRole === 'Owner')
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, _cache[15] || (_cache[15] = [
                  _createElementVNode("b", null, "Head to checkout", -1),
                  _createElementVNode("p", null, "When all users have added their items", -1)
                ])))
              : (_openBlock(), _createElementBlock("div", _hoisted_23, _cache[16] || (_cache[16] = [
                  _createElementVNode("b", null, "Add your items", -1),
                  _createElementVNode("p", null, "Once you're done, click 'Add your items'", -1)
                ])))
          ]))
        : _createCommentVNode("", true),
      (_ctx.step === 4)
        ? (_openBlock(), _createElementBlock("div", _hoisted_24, _cache[18] || (_cache[18] = [
            _createElementVNode("div", { class: "circle" }, [
              _createElementVNode("div", { class: "check" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                })
              ])
            ], -1),
            _createElementVNode("div", { class: "texts" }, [
              _createElementVNode("b", null, "You're all set!"),
              _createElementVNode("p", null, "You can now close this tab")
            ], -1)
          ])))
        : _createCommentVNode("", true)
    ])
  ], 64))
}