import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, withKeys as _withKeys, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "no-account" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "login-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _cache[8] || (_cache[8] = _createElementVNode("h2", null, "Log in", -1)),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_app_input, {
          id: "email",
          value: _ctx.email,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
          showError: _ctx.show
        }, _createSlots({
          label: _withCtx(() => [
            _cache[4] || (_cache[4] = _createTextVNode("Email address"))
          ]),
          _: 2
        }, [
          (_ctx.yupErrMsg('email'))
            ? {
                name: "error",
                fn: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.yupErrMsg('email')), 1)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["value", "showError"]),
        _createVNode(_component_app_input, {
          id: "password",
          type: "password",
          onKeypress: _withKeys(_ctx.login, ["enter"]),
          value: _ctx.password,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
          showError: _ctx.show
        }, _createSlots({
          label: _withCtx(() => [
            _cache[5] || (_cache[5] = _createTextVNode("Password"))
          ]),
          _: 2
        }, [
          (_ctx.yupErrMsg('password'))
            ? {
                name: "error",
                fn: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.yupErrMsg('password')), 1)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["onKeypress", "value", "showError"]),
        _createElementVNode("u", {
          class: "forgot-password custom-cursor-hover",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.forgotPassword && _ctx.forgotPassword(...args)))
        }, "Forgot password?"),
        _createVNode(_component_app_button, {
          class: "login-button",
          loader: _ctx.loader,
          onClick: _withModifiers(_ctx.login, ["prevent"])
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("Log in")
          ])),
          _: 1
        }, 8, ["loader", "onClick"]),
        _createElementVNode("p", _hoisted_2, [
          _cache[7] || (_cache[7] = _createTextVNode(" Don't have an account? ")),
          _createElementVNode("u", {
            class: "custom-cursor-hover",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.register && _ctx.register(...args)))
          }, "Register")
        ])
      ])
    ]),
    _: 1
  }, 16))
}