<template>
  <!-- eslint-disable -->
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6724 12.3769L13.0075 12.1245L12.6876 21.5618C12.6876 21.7741 13.7978 22.0001 14.3193 22.0001H14.5519C15.0738 22.0001 16.184 21.7741 16.184 21.5618L15.8423 11.4905L13.6724 12.3769ZM14.4693 20.5733C14.1796 20.5733 13.9448 20.3381 13.9448 20.0488C13.9448 19.759 14.1796 19.5243 14.4693 19.5243C14.759 19.5243 14.9938 19.759 14.9938 20.0488C14.9938 20.3381 14.759 20.5733 14.4693 20.5733ZM14.4693 14.7617C14.1796 14.7617 13.9448 14.5269 13.9448 14.2368C13.9448 13.9475 14.1796 13.7127 14.4693 13.7127C14.759 13.7127 14.9938 13.9475 14.9938 14.2368C14.9938 14.5273 14.759 14.7617 14.4693 14.7617Z"
      fill="#010002"
    />
    <path
      d="M10.2545 4.60738C10.2545 1.65056 10.0791 0.777367 9.39422 0.520829L9.3135 4.34015C9.3135 4.52371 9.17822 4.67631 9.01199 4.67631H8.90547C8.73923 4.67631 8.60396 4.52371 8.60396 4.3361L8.29287 0.414307C8.2092 0.414307 8.12332 0.414675 8.03265 0.414675C7.87563 0.414675 7.73077 0.415781 7.59513 0.419098L7.28441 4.35047C7.28478 4.52334 7.14988 4.67594 6.98364 4.67594H6.87712C6.71089 4.67594 6.57561 4.52334 6.57561 4.33573L6.49637 0.612608C5.8119 0.98046 5.81079 2.02615 5.81079 4.98187C5.81079 5.78687 5.95528 6.4249 6.29659 6.86536C6.40569 7.10494 6.53544 7.38839 6.67292 7.6851C6.88486 8.14363 7.11339 8.63054 7.31316 9.04188L6.99912 21.1077C6.99912 21.3918 7.60435 21.6318 7.8889 21.6318H8.35738C8.64193 21.6318 9.24715 21.3671 9.24715 21.0826L8.92869 8.88007L9.65739 6.97704L9.78087 6.65416C10.113 6.12265 10.2545 5.40243 10.2545 4.60738Z"
      fill="#010002"
    />
    <path
      d="M13.6673 11.9806L16.1888 10.9504L15.1531 0C13.79 1.18317 13.1608 2.55654 13.0716 2.76147L12.5162 11.5435L13.6673 11.9806Z"
      fill="#010002"
    />
  </svg>
  <!-- eslint-enable -->
</template>
