import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tracked-order-details-section" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  key: 0,
  class: "additional-info"
}
const _hoisted_5 = { class: "tracked-order-details-section" }
const _hoisted_6 = { class: "section-title" }
const _hoisted_7 = { class: "tracked-order-details-section" }
const _hoisted_8 = { class: "price-row" }
const _hoisted_9 = {
  key: 0,
  class: "price-row"
}
const _hoisted_10 = {
  key: 1,
  class: "price-row"
}
const _hoisted_11 = {
  key: 2,
  class: "price-row"
}
const _hoisted_12 = {
  key: 3,
  class: "price-row"
}
const _hoisted_13 = { class: "tracked-order-details-section additional-padding" }
const _hoisted_14 = { class: "section-title" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = {
  key: 0,
  class: "additional-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_TrackedOrderItems = _resolveComponent("TrackedOrderItems")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "tracked-order-details-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _createVNode(_component_app_button, {
        class: "tracked-order-details-modal-close",
        mini: "",
        white: "",
        onClick: _withModifiers(_ctx.close, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconServices, {
            class: "services-icon",
            icon: "add",
            close: ""
          })
        ]),
        _: 1
      }, 8, ["onClick"]),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "tracked-order-details-section additional-padding" }, [
        _createElementVNode("h2", null, "Order details")
      ], -1)),
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "separator" }, null, -1)),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_IconServices, { icon: "hash" }),
          _createElementVNode("p", null, [
            _cache[0] || (_cache[0] = _createTextVNode(" Your order ID is ")),
            _createElementVNode("b", null, _toDisplayString(_ctx.data.friendlyId), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_IconServices, { icon: "forkAndKnife" }),
          _createElementVNode("p", null, [
            _createElementVNode("b", null, _toDisplayString(_ctx.data.restaurantName), 1)
          ])
        ]),
        (_ctx.data.kitchenNotes)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, "“" + _toDisplayString(_ctx.data.kitchenNotes) + "”", 1))
          : _createCommentVNode("", true)
      ]),
      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "separator" }, null, -1)),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, "Your items (" + _toDisplayString(_ctx.numberOfItems) + ")", 1),
        _createVNode(_component_TrackedOrderItems, {
          cart: _ctx.data.cart,
          groupBaskets: _ctx.data.groupBaskets || []
        }, null, 8, ["cart", "groupBaskets"])
      ]),
      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "separator" }, null, -1)),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _cache[1] || (_cache[1] = _createElementVNode("b", null, "Total", -1)),
          _createElementVNode("b", null, "£" + _toDisplayString(_ctx.data.totalPrice.toFixed(2)), 1)
        ]),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "coal-border" }, null, -1)),
        (_ctx.data.type === 'delivery')
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _cache[2] || (_cache[2] = _createElementVNode("p", null, "Delivery fee & tips", -1)),
              _createElementVNode("b", null, "£" + _toDisplayString((_ctx.data.deliveryPrice + (_ctx.data.tipAmount ?? 0)).toFixed(2)), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.data.serviceFee > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _cache[3] || (_cache[3] = _createElementVNode("p", null, "Service fee", -1)),
              _createElementVNode("b", null, "£" + _toDisplayString(_ctx.data.serviceFee.toFixed(2)), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.data.smallOrderFee > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _cache[4] || (_cache[4] = _createElementVNode("p", null, "Small order fee", -1)),
              _createElementVNode("b", null, "£" + _toDisplayString(_ctx.data.smallOrderFee.toFixed(2)), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.discount > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _cache[5] || (_cache[5] = _createElementVNode("p", null, "Discount", -1)),
              _createElementVNode("b", null, "-£" + _toDisplayString(_ctx.discount.toFixed(2)), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "separator" }, null, -1)),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.data.type === 'delivery' ? 'Delivery details' : 'Collection details '), 1),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_IconServices, { icon: "house" }),
          _createElementVNode("b", null, _toDisplayString(_ctx.address), 1)
        ]),
        (_ctx.data.deliveryNotes)
          ? (_openBlock(), _createElementBlock("p", _hoisted_16, "“" + _toDisplayString(_ctx.data.deliveryNotes) + "”", 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 16))
}