import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "prefilled-basket-share__link"
}
const _hoisted_2 = { class: "prefilled-basket-share__link-value" }
const _hoisted_3 = {
  key: 2,
  class: "prefilled-basket-loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, {
    class: "prefilled-basket-share",
    close: true
  }, {
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "prefilled-basket-share__title" }, "Share your basket", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "prefilled-basket-share__description" }, " Share this link, so that other people can use the items you have selected. ", -1)),
      (_ctx.link)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.link), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.link)
        ? (_openBlock(), _createBlock(_component_app_button, {
            key: 1,
            class: "prefilled-basket-share__share-button",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.copyToClipboard()), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.copyText), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.link)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_Logo, { class: "logo-loader" })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}