<template>
  <app-modal class="no-restaurants-gtm" v-bind="options">
    <h1>Sign up to unlock<br />deliveries to your office!</h1>
    <p>Please fill out your details and in no<br />time, you'll be ordering for the team.</p>
    <app-button square @click.prevent="goToCustomMarketplace">Get started here</app-button>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

@Options({})
export default class MissingAreaGtmModal extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: true,
    doesNotClose: false,
  };

  private goToCustomMarketplace() {
    this.$router.push('/custom-marketplace');
  }
}
</script>

<style lang="scss">
.no-restaurants-gtm {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    display: flex;
    flex-direction: column;
  }

  h1 {
    font-size: 25px;
    text-align: center;
  }

  p {
    font-weight: 600;
    text-align: center;
    margin: 1em auto;
  }

  .controller-button {
    margin: 15px auto;
    min-height: 45px;
    max-width: 180px;
  }
}
</style>
