<template>
  <ul class="navigation-dropdown">
    <slot />
  </ul>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class NavigationDropdown extends Vue {}
</script>

<style lang="scss">
.navigation-dropdown {
  position: absolute;
  z-index: 999;
  top: calc(100% + 6px);
  right: 16px;
  min-width: 270px;
  background-color: white;
  border-radius: 15px;
  border: 1px solid $coal100;
  overflow: hidden;
  li {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid $coal100;
    &:hover {
      background-color: $coal20;
    }
    &:last-child {
      border-bottom: none;
    }
    svg,
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
}
</style>
