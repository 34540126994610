<template>
  <div class="amendable-order-info">
    <IconServices icon="suitcase" width="25" height="25" />
    <div class="amendable-order-info__text-wrapper">
      <p class="amendable-order-info__name">Order #{{ data.friendlyId }}</p>
      <p v-if="itemsText" class="amendable-order-info__details">{{ itemsText }}</p>
    </div>
    <app-button
      v-if="isMobile && !$route.path.endsWith('checkout')"
      class="amendable-order-info__button"
      @click="showBasket"
    >
      Edit
    </app-button>
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';
import { mapState } from 'vuex';

import IconServices from '@/shared/icons/IconServices.vue';

import pipes from '@/utils/pipes';

class Props {
  isMobile = prop<boolean>({ required: true });
  restaurant = prop<FullInfoRestaurant>({ required: true });
}

@Options({
  components: { IconServices },
  props: {
    isMobile: Boolean,
    restaurant: Object,
  },
  computed: {
    ...mapState('amendableOrder', ['data']),
  },
})
export default class AmendableOrderInfo extends Vue.with(Props) {
  get itemsAmount() {
    const basketCount = this.$store.state.basket.dishes.reduce((sum, item) => sum + item.amount, 0);
    if (!this.$store.state.groupOrder.groupData) return basketCount;

    return (
      basketCount +
      this.$store.state.groupOrder.groupData.baskets.reduce(
        (sum, basket) =>
          sum + basket.cart.dishes.reduce((sum2: number, dish: { amount: number }) => sum2 + dish.amount, 0),
        0,
      )
    );
  }

  get itemsText() {
    const count = this.itemsAmount;
    if (count === 1) return '1 item in your basket';
    if (count > 1) return `${count} items in your basket`;
    return 'No items in your basket';
  }

  private showBasket() {
    this.$store.commit('modals/data', { ...this.restaurant });
    this.$store.commit('modals/show', 'basket');
  }

  private addApostrophe(name: string) {
    return pipes.addApostrophe(name);
  }
}
</script>

<style lang="scss" scoped>
.amendable-order-info {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 15px;
  gap: 15px;
  background-color: $ham;
  padding: 10px 20px;
  margin: 0 auto;
  margin-bottom: 15px;
  margin-top: 10px;

  &__button {
    @include p1;
    background-color: $white;
    width: unset;
    min-height: unset;
    align-self: center;
    padding: 12px 10px;
  }

  &__name {
    @include p2;
    font-size: 16px;
    font-weight: 300;
  }

  &__details {
    @include p2;
    font-weight: 300;
    font-size: 14px;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3px;
    flex-grow: 1;
  }
}
</style>
