<template>
  <div class="container">
    <!-- First section: Corporate Breakfast Delivery -->
    <div class="header_section">
      <div class="header_section_body">
        <h1 class="title">Corporate Breakfast Delivery in {{ cityName }}</h1>
        <h2 class="sub_title">• Amazing corporate breakfasts, delivered to the office</h2>
        <h2 class="sub_title">• Breakfast bundles and buffets from independent restaurants</h2>
        <h2 class="sub_title">• A curated list of top rated breakfast and brunch spots</h2>
        <div class="order_buttons">
          <app-button slim @click="orderNow">
            <IconServices icon="upload" />
            <p>Show me food</p>
          </app-button>
          <app-button slim white @click="goToEnquiryPage">
            <IconServices icon="gift" />
            <p>Plan something bigger</p>
          </app-button>
        </div>
        <p class="how-does-it-work-question custom-cursor-hover" @click="scrollToHowItWorks">How does it work?</p>
        <p class="quote">
          “<b
            >The toasties were incredible and their portions were huge. Everyone was happy with the breakfast, thank you
            so much!</b
          >” - Ultraleap
        </p>
        <img id="main_list_of_logos" src="../assets/landing/main_list_of_logos.png" alt="" />
      </div>
      <img class="header_image" src="../assets/landing/variants/corporate-breakfast-delivery-header.png" alt="" />
    </div>

    <!-- Divider: Values -->
    <TickerTape />

    <!-- Second section: Popular corporate breakfast options -->
    <div class="breakfast_options_section">
      <h1 class="section_title">Popular corporate breakfast options</h1>
      <h2 class="sub_title">
        Corporate breakfasts and brunches, delivered to your office
        <WeSupportLocal :color="ham" :width="100" :height="100" />
      </h2>
      <Carousel class="occasions_carousel" :breakpoints="breakpoints" :wrap-around="true">
        <!-- eslint-disable -->
        <Slide v-if="cityName === 'Bristol'" key="emmeline">
          <Card title="Breakfasts" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image emmeline">
                <img class="card_logo" src="../assets/landing/restaurant_cards/emmeline_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Emmeline</h3>
                <p>BANG tidy grilled cheese!</p>
                <div class="card_food_containers">
                  <p>Grilled Cheese</p>
                  <p>Fresh juices</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide v-if="cityName === 'Bristol'" key="eden_cafe">
          <Card title="Breakfasts" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image eden_cafe">
                <img class="card_logo" src="../assets/landing/restaurant_cards/eden_cafe_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Eden Cafe</h3>
                <p>Delicious plant-based food</p>
                <div class="card_food_containers">
                  <p>Breakfasts & Nourish bowls</p>
                  <p>Vegan</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide v-if="cityName === 'Bristol'" key="fed">
          <Card title="Breakfasts" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image fed">
                <img class="card_logo" src="../assets/landing/restaurant_cards/fed_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Fed</h3>
                <p>A plethora of flavour</p>
                <div class="card_food_containers">
                  <p>Seasonal salads</p>
                  <p>Healthy</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide v-if="cityName === 'Bristol'" key="hobbs_bakery">
          <Card title="Breakfasts" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image hobbs_bakery">
                <img class="card_logo" src="../assets/landing/restaurant_cards/hobbs_bakery_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Hobbs Bakery</h3>
                <p>B-Corp Bakery Baby</p>
                <div class="card_food_containers">
                  <p>Baked goods - sweet & savoury</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide v-if="cityName === 'Bristol'" key="cafe_dream">
          <Card title="Breakfasts" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image cafe_dream">
                <img class="card_logo" src="../assets/landing/restaurant_cards/cafe_dream_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Cafe Dream</h3>
                <p>Quality, independent hearty food</p>
                <div class="card_food_containers">
                  <p>Omelettes</p>
                  <p>Paninis & Wraps</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide v-if="cityName === 'Cambridge'" key="vg_coffee">
          <Card title="Breakfasts" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image vg_coffee">
                <img class="card_logo" src="../assets/landing/restaurant_cards/vg_coffee_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>VG Coffee</h3>
                <p>Vegan cakes and treats</p>
                <div class="card_food_containers">
                  <p>Cakes, cookies & loafs</p>
                  <p>Vegan</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide v-if="cityName === 'Cambridge'" key="fitzbillies">
          <Card title="Breakfasts" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image fitzbillies">
                <img class="card_logo" src="../assets/landing/restaurant_cards/fitzbillies_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Fitzbillies</h3>
                <p>Delicious baked goods in Cambridge</p>
                <div class="card_food_containers">
                  <p>Baked goods</p>
                  <p>Perfect for sharing</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide v-if="cityName === 'Cambridge'" key="bridges">
          <Card title="Breakfasts" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image bridges">
                <img class="card_logo" src="../assets/landing/restaurant_cards/bridges_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Bridges</h3>
                <p>Fresh, locally-sourced, home-cooked</p>
                <div class="card_food_containers">
                  <p>Seasonal salads</p>
                  <p>Sandwiches</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide v-if="cityName === 'Cambridge'" key="scotts_all_day_brunch">
          <Card title="Breakfasts" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image scotts_all_day_brunch">
                <img class="card_logo" src="../assets/landing/restaurant_cards/scotts_all_day_brunch_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Scotts All Day Brunch</h3>
                <p>Cafe by day and a pizzeria by night</p>
                <div class="card_food_containers">
                  <p>Brunch</p>
                  <p>Salads and sandwiches</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide v-if="cityName === 'Cambridge'" key="hot_numbers">
          <Card title="Breakfasts" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image hot_numbers">
                <img class="card_logo" src="../assets/landing/restaurant_cards/hot_numbers_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Hot Numbers</h3>
                <p>Cambridge’s finest roastery</p>
                <div class="card_food_containers">
                  <p>Fresh baguettes</p>
                  <p>Bundles</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <!-- eslint-enable -->
        <template #addons>
          <Navigation>
            <template #prev>
              <img class="carousel_nav_arrow" src="../assets/landing/carousel_left.png" alt="" />
            </template>
            <template #next>
              <img class="carousel_nav_arrow" src="../assets/landing/carousel_right.png" alt="" />
            </template>
          </Navigation>
        </template>
      </Carousel>
    </div>

    <!-- Divider: Food image -->
    <img class="divider_image" src="../assets/landing/variants/corporate-breakfast-delivery-divider.png" alt="" />

    <!-- Third section: The perfect breakfasts for your office -->
    <!-- eslint-disable -->
    <ThePerfectFood
      title="The perfect breakfasts for your office"
      :subtitle="`Breakfast deliveries made easy and delicious for your office. We've partnered with the best independent breakfast spots and corporate caterers to ensure we deliver a great variety of breakfasts fit for any diet, budget and preference in your office. Breakfast in the office has never been made simpler in ${cityName}.`"
      :onOrderNowClick="orderNow"
      :onPlanSomethingBiggerClick="goToEnquiryPage"
    />
    <!-- eslint-enable -->

    <!-- Fourth section: Built for ease -->
    <BuiltForEase :onHereClick="openFreshchat" />

    <!-- Divider: Logos -->
    <div class="divider_list_of_logos">
      <img class="hide_mobile" src="../assets/landing/divider_list_of_logos.png" alt="" />
      <img class="hide_desktop" src="../assets/landing/divider_list_of_logos_mobile.png" alt="" />
    </div>

    <!-- Fifth section: Sustainable and ethical -->
    <SustainableAndEthical />

    <!-- Sixth section: How it works -->
    <HowItWorks title="Ordering breakfast to the office">
      <template v-slot:pick-from>
        <p>Pick from our <b>curated list</b> of corporate breakfast restaurants in Cambs/Bristol</p>
      </template>
    </HowItWorks>

    <!-- Divider: Contact -->
    <div class="divider_contact">
      <p>Got a few questions? Give our team a shout</p>
      <app-button slim white @click="openFreshchat">
        <img src="../assets/order_tracking/message.png" alt="" />
        <p>Chat to the team</p>
      </app-button>
    </div>

    <!-- Seventh section: Everybody wins -->
    <EverybodyWins />
  </div>
</template>

<script lang="ts">
import { useSeoMeta } from '@unhead/vue';
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import { Options, Vue } from 'vue-class-component';

import TickerTape from '@/components/TickerTape.vue';

import Card from '@/landing/Card.vue';

import IconServices from '@/shared/icons/IconServices.vue';
import WeSupportLocal from '@/shared/icons/WeSupportLocal.vue';

import BuiltForEase from './sections/BuiltForEase.vue';
import EverybodyWins from './sections/EverybodyWins.vue';
import HowItWorks from './sections/HowItWorks.vue';
import SustainableAndEthical from './sections/SustainableAndEthical.vue';
import ThePerfectFood from './sections/ThePerfectFood.vue';

@Options({
  components: {
    TickerTape,
    Card,
    IconServices,
    WeSupportLocal,
    Slide,
    Carousel,
    Navigation,
    EverybodyWins,
    HowItWorks,
    SustainableAndEthical,
    BuiltForEase,
    ThePerfectFood,
  },
  data: () => ({
    // note: card's width (with margins) equals 380
    breakpoints: {
      380: { itemsToShow: 1 },
      570: { itemsToShow: 1.5 },
      665: { itemsToShow: 1.75 },
      760: { itemsToShow: 2 },
      855: { itemsToShow: 2.25 },
      950: { itemsToShow: 2.5 },
      1045: { itemsToShow: 2.75 },
      1140: { itemsToShow: 3 },
      1235: { itemsToShow: 3.25 },
      1330: { itemsToShow: 3.5 },
      1425: { itemsToShow: 3.75 },
      1520: { itemsToShow: 4 },
      1615: { itemsToShow: 4.25 },
      1710: { itemsToShow: 4.5 },
      1805: { itemsToShow: 4.75 },
      1900: { itemsToShow: 5 },
    },
  }),
})
export default class CorporateBreakfastDelivery extends Vue {
  private isMounted = false;

  private orderNow() {
    this.$store.commit('modals/data', {
      withOrderTypeSwitch: false,
      withAddressChange: true,
      withDeliveryTimeChange: true,
      isLanding: true,
    });
    this.$store.commit('modals/show', 'orderSettings');
  }

  private goToEnquiryPage() {
    this.$router.push('/enquiry');
  }

  private scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  private scrollToHowItWorks() {
    const elements = document.getElementsByClassName('how_it_works_section');
    if (elements.length) {
      window.scrollTo({
        top: (elements[0] as HTMLElement).offsetTop + 5,
        behavior: 'smooth',
      });
    }
  }

  // FRESHCHAT STUFF: STARt
  mounted() {
    this.isMounted = true;
    setTimeout(() => {
      if (!this.isMounted) return;
      this.enableFreshchat();
    }, 1000);
  }

  unmounted() {
    this.isMounted = false;
    this.disableFreshchat();
  }

  private enableFreshchat() {
    window.fcWidget?.setTags(['client-bot']);
    window.fcWidget?.setConfig({ headerProperty: { hideChatButton: false } });
  }

  private disableFreshchat() {
    window.fcWidget?.setConfig({ headerProperty: { hideChatButton: true } });
  }

  private openFreshchat() {
    window.fcWidget?.setTags(['client-bot']);
    window.fcWidget?.open();
  }
  // FRESHCHAT STUFF: STOP

  private get cityName() {
    const cityFromUrl = this.$route.params.city?.toString().toLowerCase();
    return `${cityFromUrl.slice(0, 1).toUpperCase()}${cityFromUrl.slice(1)}`;
  }

  created() {
    useSeoMeta({
      title: `Foodstuff | Corporate Breakfast Delivery in ${this.cityName}`,
      ogTitle: `Foodstuff | Corporate Breakfast Delivery in ${this.cityName}`,
      twitterTitle: `Foodstuff | Corporate Breakfast Delivery in ${this.cityName}`,
      description:
        `Order a corporate breakfast delivery from Foodstuff today. ` +
        `Pick from our curated selection of top rated ${this.cityName} restaurants. ` +
        `Whether it’s healthy snacks, pastries, cakes or cookies, we’ve got you covered.`,
      ogDescription:
        `Order a corporate breakfast delivery from Foodstuff today. ` +
        `Pick from our curated selection of top rated ${this.cityName} restaurants. ` +
        `Whether it’s healthy snacks, pastries, cakes or cookies, we’ve got you covered.`,
      twitterDescription:
        `Order a corporate breakfast delivery from Foodstuff today. ` +
        `Pick from our curated selection of top rated ${this.cityName} restaurants. ` +
        `Whether it’s healthy snacks, pastries, cakes or cookies, we’ve got you covered.`,
    });
  }
}
</script>

<style lang="scss">
.breakfast_options_section {
  .carousel__prev,
  .carousel__next {
    width: 80px;

    @include for-custom(570) {
      width: 57px;
      top: 40%;
      margin-left: calc(50vw - 170px + 10px);
      margin-right: calc(50vw - 170px + 10px);
    }
  }

  .card_image {
    height: 301px;
    width: 100%;
    border-bottom: 2px solid $coal100;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
  }

  .card_content {
    padding: 25px;

    h3 {
      font-family: Recoleta, sans-serif;
      font-weight: 600;
      font-size: 28px;
      margin-bottom: 10px;
    }

    p {
      font-family:
        Sofia Pro,
        sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      font-style: italic;
    }
  }

  .card_food_containers {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;

    p {
      border: 1px solid black;
      border-radius: 5px;
      font-family:
        Sofia Pro,
        sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 15px;
      padding: 10px;
      background-color: white;
      font-style: normal;
    }
  }

  .card_logo {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 66px;
    border-radius: 33px;
  }

  .emmeline {
    background-image: url('../assets/landing/restaurant_cards/emmeline_cover2.png');
  }
  .eden_cafe {
    background-image: url('../assets/landing/restaurant_cards/eden_cafe_cover.png');
  }
  .fed {
    background-image: url('../assets/landing/restaurant_cards/fed_cover.png');
  }
  .hobbs_bakery {
    background-image: url('../assets/landing/restaurant_cards/hobbs_bakery_cover.png');
  }
  .cafe_dream {
    background-image: url('../assets/landing/restaurant_cards/cafe_dream_cover.png');
  }
  .vg_coffee {
    background-image: url('../assets/landing/restaurant_cards/vg_coffee_cover.png');
  }
  .fitzbillies {
    background-image: url('../assets/landing/restaurant_cards/fitzbillies_cover.png');
  }
  .bridges {
    background-image: url('../assets/landing/restaurant_cards/bridges_cover.png');
  }
  .scotts_all_day_brunch {
    background-image: url('../assets/landing/restaurant_cards/scotts_all_day_brunch_cover.png');
  }
  .hot_numbers {
    background-image: url('../assets/landing/restaurant_cards/hot_numbers_cover2.png');
  }
}
</style>

<style lang="scss" scoped>
$bg-color: $vanilla;

// Common styles: START
.container {
  width: 100%;
  overflow-x: hidden;
}

.section_title {
  color: $coal100;
  font-family: Recoleta, sans-serif;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 10px;

  @include for-smartphone {
    font-size: 38px;
    line-height: 40px;
  }
}

.sub_title {
  font-family:
    Sofia Pro,
    sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 300;
  text-align: center;
  color: black;
  padding: 0px 30px;
}
// Common styles: STOP

.header_section {
  display: flex;
  flex-direction: row;
}

.header_image {
  border-left: 1px solid black;
  @include for-smartphone {
    display: none;
  }
}

.header_section_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  position: relative;
  padding: 60px 80px 40px 80px;
  width: 100%;

  @include for-smartphone {
    padding: 20px;
    padding-top: 40px;
    align-items: center;
    width: 100%;
  }

  .title {
    color: $coal100;
    font-family: Recoleta, sans-serif;
    font-size: 60px;
    line-height: 60px;
    margin-bottom: 25px;

    @include for-smartphone {
      font-size: 38px;
      line-height: 40px;
      text-align: center;
    }
  }

  .sub_title {
    max-width: 750px;
    margin-top: 5px;
    text-align: start;
    z-index: 1;
    padding: 0;

    @include for-smartphone {
      text-align: center;
    }
  }

  .order_buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 30px;
    z-index: 1;

    @include for-smartphone {
      flex-direction: column;
      align-items: center;
    }

    button {
      width: fit-content;
      border: 1px solid black;
      border-radius: 7.5px;
      gap: 7.5px;
      padding: 15px;
    }

    p,
    b {
      font-size: 15px;
      height: 17px;
    }
  }

  .how-does-it-work-question {
    font-family:
      Sofia Pro,
      sans-serif;
    color: black;
    font-size: 16px;
    line-height: 16px;
    text-decoration: underline;
    margin-top: 45px;

    @include for-smartphone {
      margin-top: 20px;
    }
  }

  .quote {
    font-family:
      Sofia Pro,
      sans-serif;
    font-size: 18px;
    font-weight: 300;
    margin-top: 100px;

    @include for-smartphone {
      margin: 0;
      margin-top: 60px;
      text-align: center;
    }
  }

  #main_list_of_logos {
    width: 80vw;
    max-width: 540px;
    margin-top: 10px;
  }
}

.breakfast_options_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 80px;
  width: 100%;

  @include for-smartphone {
    padding-top: 40px;
  }

  .sub_title {
    position: relative;
  }

  .carousel {
    margin-top: 60px;
    width: 100vw;
  }

  .sticker {
    position: absolute;
    bottom: -125px;
    left: 245px;
    right: 0;
    transform: rotate(11deg);
    z-index: 1;
  }
}

.divider_image {
  width: 100%;
  border-top: 2px solid $coal100;
  border-bottom: 2px solid $coal100;
}

.divider_list_of_logos {
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid $coal100;
  border-bottom: 2px solid $coal100;

  img {
    width: 90vw;
    max-width: 1000px;
    padding-top: 15px;
    padding-bottom: 15px;

    @include for-smartphone {
      max-width: 500px;
    }
  }

  .hide_mobile {
    @include for-smartphone {
      display: none;
    }
  }

  .hide_desktop {
    @include for-desktop {
      display: none;
    }
  }
}

.divider_contact {
  background-color: black;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @include for-smartphone {
    flex-direction: column;
    padding: 40px 30px;
  }

  & > p {
    font-family:
      Sofia Pro,
      sans-serif;
    font-size: 19px;
    line-height: 25px;
    font-weight: 300;
    color: white;
    text-align: center;
  }

  button {
    width: fit-content;
    border: 1px solid black;
    border-radius: 7.5px;
    gap: 7.5px;
    padding: 10px 20px;

    p,
    b {
      font-size: 15px;
    }
  }
}

// NOTE: Used by slot
.how_it_works_step {
  p,
  b {
    @include p1;
    font-size: 19px;
    line-height: 25px;
    color: black;
    text-align: center;
  }

  p {
    font-weight: 300;
  }
}
</style>
