<template>
  <app-modal class="amend-order-init-modal" v-bind="options">
    <app-button class="amend-order-init-modal-close" mini white @click.prevent="close">
      <IconServices class="services-icon" icon="add" close />
    </app-button>

    <div class="amend-order-init-modal-content">
      <h1>Amending your basket?</h1>
      <u><p>A couple of bits to know</p></u>
      <div class="amend-order-init-bullet-point">
        <div class="bullet-point-box">
          <img src="../../assets/order_tracking/chevron_right.png" alt="" />
        </div>
        <p>You can edit your basket up to 2 hours from your delivery time</p>
      </div>
      <div class="amend-order-init-bullet-point">
        <div class="bullet-point-box">
          <img src="../../assets/order_tracking/chevron_right.png" alt="" />
        </div>
        <p>For any additional items, you’ll need to proceed to checkout and pay</p>
      </div>
      <div class="amend-order-init-bullet-point">
        <div class="bullet-point-box">
          <img src="../../assets/order_tracking/chevron_right.png" alt="" />
        </div>
        <p>We will refund any items you’ve removed within 3-5 days</p>
      </div>
      <app-button class="custom-button" @click.prevent="initAmendingOrder">Edit my basket</app-button>
    </div>
  </app-modal>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import restaurant from '@/requests/restaurant';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';
import IconServices from '@/shared/icons/IconServices.vue';

import otherMethods from '@/utils';
import methods from '@/utils/methods';

@Options({
  components: { IconServices },
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class ModalAmendOrderInit extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: false,
    noPadding: true,
  };

  private async initAmendingOrder() {
    const { address, modals } = this.$store.state;
    const order = modals.data;

    // Override time:
    const bTime = order.deliverToTime;
    const wTime = order.worstDeliverToTime;
    const leftEnd = moment(bTime).tz('Europe/London').format('HH:mm');
    const rightEnd = moment(wTime).tz('Europe/London').format('HH:mm');
    this.$store.commit('order/scheduledDeliveryDate', bTime);
    this.$store.commit('order/scheduledDeliveryInterval', `${leftEnd} - ${rightEnd}`);

    // Override service type:
    const type = order.type === 'delivery' ? 'delivery' : 'collection';
    this.$store.commit('order/type', type);

    // Override address:
    if (
      address.cityName?.toLowerCase() !== order.address.city.toLowerCase() ||
      address.formattedAddress !== order.address.street
    ) {
      const firstElementInAddress = order.address.street.split(' ')[0];
      const streetNumber = this.isStreetNumber(firstElementInAddress) ? firstElementInAddress : undefined;
      const streetName = streetNumber
        ? (order.address.street as string).replaceAll(streetNumber, '').trim()
        : order.address.street;

      this.$store.commit('address/set', {
        lat: order.address.lat,
        lng: order.address.lng,
        postcode: order.address.postcode,
        formattedAddress: order.address.street,
        cityName: otherMethods.capitalizeFirstLetter(order.address.city),
        streetName,
        streetNumber,
      });
    }

    // Get restaurant:
    const restaurantSlug = String(this.$route.params.slug);
    const userId = this.$store.state.service.user?.customId;
    const userName = this.$store.state.service.user?.firstName;
    let query = `lng=${order.address.lng}&lat=${order.address.lat}`;
    query += `&collect=${type === 'collection'}`;
    query += `&deliverToTime=${bTime}`;
    query += `&restaurantId=${order.cart.restaurantId}`;
    query += `&userId=${userId}`;
    const mainRes = await restaurant.getRestaurant(restaurantSlug, `?${query}`);

    // Make sure restaurant is treated as open if amending an order:
    mainRes.restaurant.isClosed = false;
    mainRes.restaurant.isTemporarilyClose = false;

    // Fix fees:
    const { deliveryInfo } = mainRes.restaurant;
    deliveryInfo.fee = +deliveryInfo.fee.toFixed(2);
    deliveryInfo.initialFee = +deliveryInfo.initialFee.toFixed(2);
    deliveryInfo.bigOrderFee = +deliveryInfo.bigOrderFee.toFixed(2);
    deliveryInfo.bigOrderInitialFee = +deliveryInfo.bigOrderInitialFee.toFixed(2);
    deliveryInfo.deliveryFeeBreakpoint = +deliveryInfo.deliveryFeeBreakpoint.toFixed(2);

    // Override cart:
    const ownerDishes = (order.cart.dishes as OrderDish[]).filter((d) => !d.basketId);
    this.$store.commit('basket/setDishes', ownerDishes);
    this.$store.commit('basket/restaurant', methods.filterRestaurantInfo(mainRes.restaurant));

    // Override coupon and tips:
    this.$store.commit('basket/tip', order.tipAmount);
    this.$store.commit('basket/coupon', order.coupon);

    // Override group order:
    this.$store.commit('groupOrder/reset');
    if (order.groupId) {
      this.$store.commit('groupOrder/setRole', 'Owner');
      this.$store.commit('groupOrder/update', {
        ownerAddress: this.$store.state.address,
        ownerId: userId,
        ownerName: userName,
        bestDeliverToTime: bTime,
        worstDeliverToTime: wTime,
        baskets: (order.groupBaskets as GroupOrderBasket[]).filter((b) => b.id),
        orderType: type,
        restaurantLogo: mainRes.restaurant.logo,
        restaurantName: mainRes.restaurant.name,
        restaurantSlug: mainRes.restaurant.slug,
        groupLink: '',
        id: '',
      });
    }

    // Set amendable order:
    this.$store.commit('amendableOrder/set', {
      address: order.address,
      cart: order.cart,
      coupon: order.coupon,
      createdAt: order.createdAt,
      deliverToTime: order.deliverToTime,
      deliveryNotes: order.deliveryNotes,
      deliveryPrice: order.deliveryPrice,
      friendlyId: order.friendlyId,
      id: order.id,
      invoiceNote: order.invoiceNote,
      kitchenNotes: order.kitchenNotes,
      paymentMethod: order.paymentMethod,
      restaurantSlug: mainRes.restaurant.slug,
      tipAmount: order.tipAmount,
      totalPrice: order.totalPrice,
      type,
    });

    // Redirect to appropriate page:
    this.$router.push(`/menu/${order.address.city.toLowerCase()}/${order.cart.restaurantSlug}`);
  }

  private isStreetNumber(value: string) {
    return /\d/.test(value);
  }

  private close() {
    this.$store.commit('modals/close');
  }
}
</script>

<style lang="scss">
.amend-order-init-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .modal {
    animation: $modal-show-bottom;
  }
}
</style>

<style lang="scss" scoped>
.amend-order-init-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  .close.services-icon {
    top: 9px;
    left: 9px;
    width: 21px;
    height: 21px;
  }
}

.amend-order-init-modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 30px;
}

h1 {
  font-weight: 600;
  font-size: 35px;
  line-height: 35px;
  margin-top: 20px;
}

u {
  padding-top: 10px;
  padding-bottom: 5px;
}

p {
  font-family:
    Sofia Pro,
    sans-serif;
  font-weight: 500;
}

.amend-order-init-bullet-point {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.bullet-point-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $ham;
  height: 24px;
  aspect-ratio: 1;
  border-radius: 4px;

  img {
    width: 16px;
    margin-left: auto;
    margin-right: auto;
  }
}

.custom-button {
  margin-top: 15px;
}
</style>
