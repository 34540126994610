<template>
  <section class="boring-stuff-content">
    <router-view />
  </section>
</template>

<style lang="scss" scoped>
.boring-stuff-content {
  margin-top: 50px;
  h2 {
    margin: 40px 0;
  }
  h3 {
    margin: 15px 0 20px;
  }
  h4 {
    font-weight: 600;
    margin: 10px 0 15px;
  }
}
</style>
