<template>
  <app-modal class="hours-modal" v-bind="options">
    <app-button class="hours-modal-close" mini white @click.prevent="close">
      <IconServices class="services-icon" icon="add" close />
    </app-button>
    <div class="hours-modal-wrapper">
      <h1>Opening hours</h1>
      <div class="divider ham full-width" />
      <p class="hours-modal-day-hours">
        <b>{{ currentDayOpeningHours[0] }}</b>
        <b>-</b>
        <b>{{ currentDayOpeningHours[1] }}</b>
      </p>
      <p
        class="hours-modal-day-hours"
        v-for="(_, index) in data.restaurant.weekWorkingHours"
        :key="data.restaurant.weekWorkingHours[index].day"
      >
        <span class="hours-modal-day-hours--text">{{ data.restaurant.weekWorkingHours[index].weekDay }}</span>
        <span class="hours-modal-day-hours--text">{{
          specificDayOpeningHours(data.restaurant.weekWorkingHours[index])
        }}</span>
      </p>
    </div>
  </app-modal>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';
import IconServices from '@/shared/icons/IconServices.vue';

@Options({
  components: {
    IconServices,
  },
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class ModalHours extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: false,
  };

  get currentDayOpeningHours() {
    // Retrieve opening and closing hours:
    const { restaurant } = this.$store.state.modals.data;
    const todayWorkHours = restaurant.todayWorkHours.replaceAll('Today ', '').split(' - ');
    const openingHour = todayWorkHours[0];
    const closingHour = todayWorkHours[1];

    // If closed today, we don't need to calculate anything:
    if (openingHour === closingHour) return ['Closed today', 'Closed'];

    // Check if it is too early or too late:
    const now = moment().tz('Europe/London');
    const currentTime = `${now.hour}:${now.minute}`;
    const isTooEarly = this.hourToMinutes(openingHour) > this.hourToMinutes(currentTime);
    const isTooLate = this.hourToMinutes(closingHour) < this.hourToMinutes(currentTime);

    // Return appropriate message:
    if (restaurant.isClosed && isTooEarly) {
      return ['Open today', `Opens at ${openingHour}`];
    }

    if (restaurant.isClosed && isTooLate) return ['Closed today', 'Closed'];

    return ['Open now', `Closes at ${closingHour}`];
  }

  private specificDayOpeningHours(hours: WorkingHours) {
    // Retrieve opening and closing hours:
    const openingHour = `${hours.open.split(':')[0]}:${hours.open.split(':')[1]}`;
    const closingHour = `${hours.close.split(':')[0]}:${hours.close.split(':')[1]}`;

    // Return appropriate message:
    if (openingHour === closingHour) return 'Closed';
    return `${openingHour} - ${closingHour}`;
  }

  private hourToMinutes(hour: string): number {
    return 60 * parseInt(hour.split(':')[0], 10) + parseInt(hour.split(':')[1], 10);
  }

  private close() {
    document.body.classList.remove('no-scroll');
    this.$store.commit('modals/close');
  }

  created() {
    document.body.classList.add('no-scroll');
  }
}
</script>

<style lang="scss">
.hours-modal {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &.modal-wrapper {
    padding-bottom: 0;
    @include for-custom-min(501) {
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
    @include for-old-mobile {
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      padding: 0 !important;
    }
    > .modal {
      @include for-old-mobile {
        min-height: 100% !important;
      }
    }
  }

  .modal {
    max-height: 85%;
    padding: 0;
    padding-bottom: 60px;
    animation: $modal-show-bottom;
    &.slim {
      max-width: 375px;
      width: 375px;
      @include for-old-mobile {
        width: 100%;
        min-width: 100%;
      }
    }
  }

  &-wrapper {
    padding: 60px 15px 0;
  }

  &-close {
    position: absolute;
    top: 10px;
    left: 12px;
    .close.services-icon {
      top: 9px;
      left: 9;
      width: 21px;
      height: 21px;
    }
  }

  .divider {
    margin: 5px 0;
  }

  &-day-hours {
    display: flex;
    justify-content: space-between;

    p {
      @include p3;
    }

    &--text {
      color: $coal100;
    }
  }
}
</style>
