import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/simple_arrow_bottom.svg'


const _hoisted_1 = {
  key: 0,
  class: "modal-close"
}
const _hoisted_2 = {
  key: 1,
  class: "calendar-title-wrapper"
}
const _hoisted_3 = {
  key: 0,
  class: "order-modal-subtitle"
}
const _hoisted_4 = {
  key: 1,
  class: "order-modal-subtitle"
}
const _hoisted_5 = {
  key: 2,
  class: "order-modal-loader"
}
const _hoisted_6 = {
  key: 3,
  class: "order-modal"
}
const _hoisted_7 = {
  key: 0,
  class: "order-modal-order-type-switch"
}
const _hoisted_8 = {
  key: 1,
  style: {"height":"15px"}
}
const _hoisted_9 = {
  key: 2,
  class: "order-modal-subtitle"
}
const _hoisted_10 = {
  key: 3,
  class: "order-modal-address-input"
}
const _hoisted_11 = {
  key: 4,
  class: "order-modal-subtitle"
}
const _hoisted_12 = {
  key: 5,
  class: "order-modal-subtitle"
}
const _hoisted_13 = {
  key: 6,
  class: "order-modal-delivery-time"
}
const _hoisted_14 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_OrderTypeSwitch = _resolveComponent("OrderTypeSwitch")!
  const _component_AddressChangeInput = _resolveComponent("AddressChangeInput")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "error-modal" }, _ctx.options), {
    default: _withCtx(() => [
      (!_ctx.isCalendarVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_IconServices, {
              close: "",
              icon: "add",
              class: "custom-cursor-hover",
              onClick: _ctx.closeModal
            }, null, 8, ["onClick"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.data.withDeliveryTimeChange && _ctx.isDelivery)
              ? (_openBlock(), _createElementBlock("h4", _hoisted_3, "Delivery time"))
              : _createCommentVNode("", true),
            (_ctx.data.withDeliveryTimeChange && !_ctx.isDelivery)
              ? (_openBlock(), _createElementBlock("h4", _hoisted_4, "Collection time"))
              : _createCommentVNode("", true)
          ])),
      (_ctx.data.withDeliveryTimeChange && !_ctx.deliveryIntervalsByDays[0].length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_Logo, { class: "logo-loader" })
          ]))
        : (!_ctx.isCalendarVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "order-modal-title" }, "Order details", -1)),
              (_ctx.data.withOrderTypeSwitch)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_OrderTypeSwitch, { onChange: _ctx.onOrderTypeChange }, null, 8, ["onChange"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_8)),
              (_ctx.data.withAddressChange)
                ? (_openBlock(), _createElementBlock("h4", _hoisted_9, "Your address"))
                : _createCommentVNode("", true),
              (_ctx.data.withAddressChange)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_AddressChangeInput, {
                      initialAddress: _ctx.address,
                      onChange: _ctx.onAddressChange,
                      placeholder: "Enter your address here"
                    }, null, 8, ["initialAddress", "onChange"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.data.withDeliveryTimeChange && _ctx.isDelivery)
                ? (_openBlock(), _createElementBlock("h4", _hoisted_11, "Delivery time"))
                : _createCommentVNode("", true),
              (_ctx.data.withDeliveryTimeChange && !_ctx.isDelivery)
                ? (_openBlock(), _createElementBlock("h4", _hoisted_12, "Collection time"))
                : _createCommentVNode("", true),
              (_ctx.data.withDeliveryTimeChange)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_component_app_button, {
                      slim: "",
                      white: "",
                      onClick: _ctx.showCalendar
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", null, _toDisplayString(_ctx.getFormattedDate), 1),
                        _cache[0] || (_cache[0] = _createElementVNode("img", {
                          src: _imports_0,
                          alt: ""
                        }, null, -1))
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_app_button, {
                class: "order-modal-apply-btn square slim",
                onClick: _ctx.handleSubmitClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.data.isLanding ? 'Lets eat' : 'Apply'), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createVNode(_component_Calendar, {
                dateTime: _ctx.dateTime,
                interval: _ctx.interval,
                intervalsByDays: _ctx.deliveryIntervalsByDays,
                onConfirmClick: _ctx.onCalendarConfirmClick
              }, null, 8, ["dateTime", "interval", "intervalsByDays", "onConfirmClick"])
            ]))
    ]),
    _: 1
  }, 16))
}