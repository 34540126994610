<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.20001 9.45001H21" stroke="black" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4.20001 15.75H21" stroke="black" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M10.5 3.14996L8.40002 22.05"
      stroke="black"
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.8 3.14996L14.7 22.05"
      stroke="black"
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
