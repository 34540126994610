import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/calendar/calendar_left.png'
import _imports_1 from '../assets/calendar/calendar_right.png'


const _hoisted_1 = { class: "month_year_header" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "time_picker_footer" }
const _hoisted_5 = {
  key: 0,
  class: "time_picker_hour_selector"
}
const _hoisted_6 = { class: "time_picker_core" }
const _hoisted_7 = {
  key: 1,
  class: "time_picker_interval_selector"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createBlock(_component_VueDatePicker, {
    class: _normalizeClass(["calendarV1", { absolute_position: !_ctx.intervalsByDays }]),
    "model-value": _ctx.dateTime,
    "onUpdate:modelValue": _ctx.setDateTime,
    onInternalModelChange: _cache[3] || (_cache[3] = (d) => (_ctx.selectedDate = d)),
    "enable-time-picker": false,
    "min-date": _ctx.minDateTime,
    "max-date": _ctx.maxDateTime,
    "month-change-on-scroll": false,
    config: { onClickOutside: _ctx.onClickOutside },
    inline: ""
  }, {
    "month-year": _withCtx(({ month, year, handleMonthYearChange }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "arrow custom-cursor-hover",
          onClick: ($event: any) => (handleMonthYearChange(false))
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1)
        ]), 8, _hoisted_2),
        _createElementVNode("h3", null, _toDisplayString(_ctx.monthsList[month]) + " " + _toDisplayString(year), 1),
        _createElementVNode("div", {
          class: "arrow custom-cursor-hover",
          onClick: ($event: any) => (handleMonthYearChange(true))
        }, _cache[5] || (_cache[5] = [
          _createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }, null, -1)
        ]), 8, _hoisted_3)
      ])
    ]),
    "action-row": _withCtx(({ selectDate }) => [
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.intervalsByDays)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "arrow custom-cursor-hover",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleTimeChange(false)))
              }, _cache[6] || (_cache[6] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                }, null, -1)
              ])),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", null, _toDisplayString(_ctx.formattedTime), 1)
              ]),
              _createElementVNode("div", {
                class: "arrow custom-cursor-hover",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleTimeChange(true)))
              }, _cache[7] || (_cache[7] = [
                _createElementVNode("img", {
                  src: _imports_1,
                  alt: ""
                }, null, -1)
              ]))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_app_select, {
                options: _ctx.selectedDateIntervals,
                modelValue: _ctx.selectedInterval,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedInterval) = $event))
              }, null, 8, ["options", "modelValue"])
            ])),
        _createVNode(_component_app_button, {
          disabled: _ctx.selectedInterval === 'Unavailable',
          class: "confirm_button",
          slim: "",
          onClick: selectDate
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode(" Confirm ")
          ])),
          _: 2
        }, 1032, ["disabled", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["class", "model-value", "onUpdate:modelValue", "min-date", "max-date", "config"]))
}