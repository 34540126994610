import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/order_tracking/chevron_right.png'


const _hoisted_1 = { class: "amend-order-too-late-modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "amend-order-too-late-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _createVNode(_component_app_button, {
        class: "amend-order-too-late-modal-close",
        mini: "",
        white: "",
        onClick: _withModifiers(_ctx.close, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconServices, {
            class: "services-icon",
            icon: "add",
            close: ""
          })
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("h1", null, "You’ve missed the cut off!", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "amend-order-too-late-bullet-point" }, [
          _createElementVNode("div", { class: "bullet-point-box" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            })
          ]),
          _createElementVNode("p", null, "We only allow order edits up to 2 hours before your delivery time")
        ], -1)),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "amend-order-too-late-bullet-point" }, [
          _createElementVNode("div", { class: "bullet-point-box" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            })
          ]),
          _createElementVNode("p", null, "This deadline protects restaurants from potential revenue loss")
        ], -1)),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "amend-order-too-late-bullet-point" }, [
          _createElementVNode("div", { class: "bullet-point-box" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            })
          ]),
          _createElementVNode("p", null, "Please contact our support team to discuss any changes")
        ], -1)),
        _createVNode(_component_app_button, {
          class: "custom-button",
          onClick: _withModifiers(_ctx.close, ["prevent"])
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Okay")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 16))
}