import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '../../assets/order_tracking/message.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("li", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goTo('/vendor'))),
      class: "custom-cursor-hover"
    }, [
      _createVNode(_component_IconServices, { icon: "forkAndKnife" }),
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "nav-text" }, "List your food business", -1))
    ]),
    (_ctx.isMobileView)
      ? (_openBlock(), _createElementBlock("li", {
          key: 0,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openFreshchat && _ctx.openFreshchat(...args))),
          class: "custom-cursor-hover"
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1),
          _createElementVNode("p", { class: "nav-text" }, "Chat to live support", -1)
        ])))
      : _createCommentVNode("", true)
  ], 64))
}