<template>
  <div class="my-account">
    <!-- Embedded view's back button -->
    <app-back-link v-if="$route.query.embedded" class="back-button" mini :link="initialPath" />

    <div class="my-account-personal">
      <h1>Personal details</h1>
      <div class="divider ham full-width" />

      <div class="my-account-inputs">
        <app-input
          v-model:value="user.firstName"
          id="first-name"
          :value="user.firstName"
          :showError="show"
          placeholder=""
        >
          <template v-slot:label>Full name</template>
          <template v-slot:error v-if="yupErrMsg('name')">
            {{ yupErrMsg('name') }}
          </template>
        </app-input>

        <app-input
          v-if="!thirdParty"
          v-model:value="user.email"
          id="email"
          :value="user.email"
          :showError="show"
          placeholder=""
        >
          <template v-slot:label>Email</template>
          <template v-slot:error v-if="yupErrMsg('email')">
            {{ yupErrMsg('email') }}
          </template>
        </app-input>
        <app-input
          v-if="thirdParty"
          v-model:value="user.email"
          id="email"
          :value="user.email"
          :disabled="true"
          placeholder=""
        >
          <template v-slot:label
            >Email (account linked with <b>{{ thirdParty }}</b
            >)</template
          >
        </app-input>

        <app-input
          v-model:value="user.phone"
          id="telephone-number"
          :value="user.phone"
          :showError="show"
          placeholder=""
        >
          <template v-slot:label>Telephone number</template>
          <template v-slot:error v-if="yupErrMsg('phone')">
            {{ yupErrMsg('phone') }}
          </template>
        </app-input>
      </div>
    </div>

    <div class="my-account-password">
      <h1>Change password</h1>
      <div class="divider grape full-width" />

      <div class="my-account-inputs">
        <app-input
          type="password"
          v-model:value="password.oldPassword"
          id="current-password"
          placeholder=""
          :showError="show"
        >
          <template v-slot:label>Current password</template>
          <template v-slot:error v-if="yupErrMsg('oldPassword')">
            {{ yupErrMsg('oldPassword') }}
          </template>
        </app-input>

        <app-input
          type="password"
          v-model:value="password.newPassword"
          id="new-password"
          placeholder=""
          :showError="show"
        >
          <template v-slot:label>New password</template>
          <template v-slot:error v-if="yupErrMsg('newPassword')">
            {{ yupErrMsg('newPassword') }}
          </template>
        </app-input>

        <app-input
          type="password"
          v-model:value="password.newPasswordConfirm"
          id="repeat-new-password"
          placeholder=""
          :showError="show"
        >
          <template v-slot:label>Repeat new password</template>
          <template v-slot:error v-if="yupErrMsg('newPasswordConfirm')">
            {{ yupErrMsg('newPasswordConfirm') }}
          </template>
        </app-input>

        <div>
          <app-button slim @click="update" :loader="loader"><b>Update</b></app-button>
          <div class="success">{{ successMsg }}</div>
          <div class="error">{{ errorMsg }}</div>
        </div>
      </div>
    </div>

    <div class="my-account-delete">
      <app-button slim @click="openDeleteAccountModal"><b>Delete account</b></app-button>
    </div>
  </div>
</template>

<script lang="ts">
import { FirebaseError, deepCopy } from '@firebase/util';
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import * as yup from 'yup';

import account, { Password } from '@/requests/account';

import methods from '@/utils/methods';

@Options({
  computed: {
    ...mapState('yup', ['show']),
    ...mapState('embeddedPage', ['initialPath']),
  },
})
export default class MyAccount extends Vue {
  private errorMsg = '';
  private successMsg = '';
  private originalProfile?: User;
  private user?: User;
  private loader = false;
  private thirdParty = '';

  private password: Password = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  };

  private schema = yup.object({
    oldPassword: yup.string().required('No password provided.'),
    newPassword: yup.string().required('No password provided.'),
    newPasswordConfirm: yup.string().oneOf([yup.ref('newPassword'), undefined], 'Passwords must match.'),
  });

  private nameSchema = yup
    .string()
    .required()
    .matches(/[a-zA-Z]/, 'First name can only contain Latin letters.');

  private phoneSchema = yup
    .string()
    .required()
    .length(13, 'Phone number must be exactly 13 characters')
    .matches(/^\+44/, 'Phone number must start with +44')
    .matches(/^[+]*[0-9]*$/, 'Phone can only contain numbers.');

  private emailSchema = yup.string().required().email();

  private async update() {
    if (!this.user) return;

    this.loader = true;
    this.errorMsg = '';
    this.successMsg = '';

    // NAME AND EMAIL: START
    if (this.user!.firstName !== this.originalProfile?.firstName || this.user!.email !== this.originalProfile?.email) {
      // Frontend name validation:
      try {
        await this.nameSchema.validate(this.user?.firstName, { abortEarly: false });
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          this.$store.commit('yup/clear');
          this.$store.commit('yup/errors', { path: 'name', msg: err.message });
          this.$store.commit('yup/toggleShowError', true);
        }
        this.loader = false;
        return;
      }

      // Frontend email validation:
      try {
        await this.emailSchema.validate(this.user!.email, { abortEarly: false });
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          this.$store.commit('yup/clear');
          this.$store.commit('yup/errors', { path: 'email', msg: err.message });
          this.$store.commit('yup/toggleShowError', true);
        }
        this.loader = false;
        return;
      }

      // Backend validation + update:
      try {
        if (this.user!.email !== this.originalProfile?.email) {
          await account.verifyEmailAvailable(this.user!.email);
        }
        await account.updateNameAndEmail(this.user!.email, this.user!.firstName);
      } catch (err) {
        if (err instanceof Error) {
          console.error(err.message);
          this.errorMsg = 'Something went wrong.';
          this.loader = false;
          return;
        }
      }
    }
    // NAME AND EMAIL: STOP

    // PHONE NUMBER: START
    if (this.user?.phone !== this.originalProfile?.phone) {
      // Frontend validation:
      try {
        await this.phoneSchema.validate(this.user?.phone, { abortEarly: false });
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          this.$store.commit('yup/clear');
          this.$store.commit('yup/errors', { path: 'phone', msg: err.message });
          this.$store.commit('yup/toggleShowError', true);
        }
        this.loader = false;
        return;
      }

      // Redirecting to modal:
      this.$store.commit('modals/data', { updatePhone: true, phone: this.user?.phone });
      this.$store.commit('modals/show', 'verifyPhone');
    }
    // PHONE NUMBER: STOP

    // PASSWORD: START
    if (this.password.oldPassword || this.password.newPassword || this.password.newPasswordConfirm) {
      // Frontend validation:
      try {
        await this.schema.validate(this.password, { abortEarly: false });
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          methods.yupValidCatch(err);
          this.loader = false;
          return;
        }
      }

      // Check user's status:
      const auth = getAuth();
      if (!auth.currentUser || !this.user || !this.user.email) {
        this.loader = false;
        this.errorMsg = 'User not logged in.';
        return;
      }

      try {
        await reauthenticateWithCredential(
          auth.currentUser,
          EmailAuthProvider.credential(this.user.email, this.password.oldPassword),
        );
        await updatePassword(auth.currentUser, this.password.newPassword);
      } catch (err) {
        if (err instanceof Error) {
          console.error(err.message);
          if (err instanceof FirebaseError && err.code === 'auth/wrong-password') this.errorMsg = 'Invalid password.';
          else this.errorMsg = 'Something went wrong.';
          this.loader = false;
          return;
        }
      }
    }
    // PASSWORD: STOP

    this.loader = false;
    this.successMsg = 'Success!';
  }

  private openDeleteAccountModal() {
    this.$store.commit('modals/data', { user: this.user });
    this.$store.commit('modals/show', 'deleteAccount');
  }

  private yupErrMsg(path: string) {
    return methods.yupErrMsg(path);
  }

  async created() {
    this.user = {
      id: '',
      isBusinessUser: false,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      totalOrders: 0,
      types: [],
      hasFreeServiceFee: false,
      hasPlacedAtWorkOrder: false,
    };

    try {
      this.originalProfile = this.$store.state.service.user!;
      this.user = deepCopy(this.originalProfile);

      if (this.user.photo) {
        delete this.user.photo;
      }

      if (this.user.types.includes('google')) {
        this.thirdParty = 'Google';
      } else if (this.user.types.includes('facebook')) {
        this.thirdParty = 'Facebook';
      }
    } catch (err) {
      console.error(err);
      this.$router.push('/oops');
    }

    this.$store.commit('yup/toggleShowError', false);
    this.$store.commit('yup/clear');
  }
}
</script>

<style lang="scss" scoped>
.my-account {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 10%;
  column-gap: 50px;
  row-gap: 10px;
  @include for-smartphone {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 5%;
  }
  .divider {
    margin-top: 5px;
  }
  &-inputs {
    display: grid;
    gap: 25px;
    margin-top: 25px;
    .controller-input-wrapper {
      @include for-smartphone {
        max-width: none;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 10px;

      justify-self: flex-end;
      align-self: flex-end;
    }

    .controller-button {
      width: 100px;
      height: 40px;
    }
  }

  &-delete {
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row-start: 2;
    grid-row-end: 2;
    display: flex;
    justify-content: center;

    @include for-smartphone {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 3;
      grid-row-end: 3;
    }
  }
}

.success {
  color: green;
  font-weight: bold;
}

.error {
  color: red;
  font-weight: bold;
}
</style>
