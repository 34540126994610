<template>
  <app-modal class="check-your-mail-modal" v-bind="options">
    <h1>Great!</h1>
    <p>Please, check your mail</p>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

@Options({})
export default class AppModalCheckYourMail extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: true,
  };
}
</script>

<style lang="scss">
.check-your-mail-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-top: 15px;
  }
}
</style>
