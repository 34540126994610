<template>
  <app-modal class="forgot-password-modal" v-bind="options">
    <h2>Forgot password</h2>
    <app-input v-model:value="emailAddress" :showError="show" id="Email address">
      <template v-slot:label> Email address </template>
      <template v-slot:error v-if="yupErrMsg('email')">
        {{ yupErrMsg('email') }}
      </template>
    </app-input>

    <app-button :loader="loader" @click="recover">Recover</app-button>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import * as yup from 'yup';

import account from '@/requests/account';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

import methods from '@/utils/methods';

@Options({
  computed: {
    ...mapState('yup', ['show']),
  },
})
export default class ModalForgotPassword extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: true,
  };

  private loader = false;

  private emailAddress = '';

  private schema = yup.object({
    email: yup.string().required().email(),
  });

  private recover() {
    this.schema
      .validate({ email: this.emailAddress }, { abortEarly: false })
      .then(() => {
        this.loader = true;
        account
          .forgotPassword(this.emailAddress)
          .then((res: any) => {
            if (res === 'User with this email not found') {
              const err = { path: 'email', msg: 'User with this email not found' };

              this.$store.commit('yup/clear');

              this.$store.commit('yup/errors', err);

              this.$store.commit('yup/toggleShowError', true);

              return false;
            }

            this.$store.commit('modals/close');
            this.$store.commit('modals/show', 'checkYourMail');
            return false;
          })
          .finally(() => {
            this.loader = false;
          });
      })
      .catch((err: { inner: yup.ValidationError[] }) => {
        methods.yupValidCatch(err);
      });
  }

  private yupErrMsg(path: string) {
    return methods.yupErrMsg(path);
  }

  private close() {
    this.$store.commit('modals/close');
  }
}
</script>

<style lang="scss">
.forgot-password-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: 15px;
  }

  .controller-input-wrapper {
    margin-bottom: 20px;
  }

  .controller-button {
    margin-top: 10px;
  }
}
</style>
