<template>
  <p class="item-added">Item added</p>
</template>

<style lang="scss" scoped>
.item-added {
  z-index: 900;
  bottom: 30px;
  border-radius: 24px;
  padding: 3px 10px;
  color: $ham;
  background-color: $coal100;
  animation: $modal-show-bottom;
}
</style>
