import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal-close"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal-wrapper", { 'does-not-close': _ctx.doesNotClose }]),
    ref: "modalWrapper"
  }, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["modal", { slim: _ctx.slim, noPadding: _ctx.noPadding }])
    }, [
      (_ctx.close && !_ctx.doesNotClose)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_IconServices, {
              close: "",
              icon: "add",
              class: "custom-cursor-hover",
              onClick: _withModifiers(_ctx.closeModal, ["prevent"])
            }, null, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)), [
      [_directive_click_outside, _ctx.closeModalIfClickedOutside]
    ])
  ], 2))
}