import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import address from './modules/address';
import amendableOrder from './modules/amendableOrder';
import basket from './modules/basket';
import embeddedPage from './modules/embeddedPage';
import extraTime from './modules/extraTime';
import groupOrder from './modules/groupOrder';
import gtm from './modules/gtm';
import modals from './modules/modals';
import order from './modules/order';
import productTour from './modules/productTour';
import service from './modules/service';
import stripe from './modules/stripe';
import timePicker from './modules/timePicker';
import yup from './modules/yup';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    address, // user's location details
    amendableOrder, // data used while editing order that is already placed
    basket, // dishes, prices and tips
    embeddedPage, // state used by iframe
    extraTime, // cart price thresholds to calculate additional preparation and delivery time
    groupOrder, // group order data, role, participant info
    gtm, // Go To Market flow's progress
    modals, // conditions of displaying dialogs and their data
    order, // time and mode
    productTour, // list of seen product tour modals
    service, // user data
    stripe, // card details
    timePicker, // list of intervals, number of scheduled days
    yup, // errors
  },
  plugins: [
    createPersistedState({
      paths: [
        'address',
        'amendableOrder',
        'basket',
        'embeddedPage',
        'groupOrder',
        'gtm',
        'order',
        'productTour',
        'service',
        'stripe',
      ],
      key: 'session',
    }),
  ],
});
