<template>
  <div class="navigation-buttons-auth">
    <button v-if="!user" class="ws-nw login" @click.prevent="showAuth('register')" aria-label="Register">
      <p>Create account</p>
    </button>

    <button v-if="!user" class="ws-nw login" @click.prevent="showAuth('login')" aria-label="Log in">
      <p>Log in</p>
    </button>

    <div v-if="user" class="nav-account">
      <app-button mini square white @click="toggleDropdown" aria-label="account">
        <IconServices icon="account" />
      </app-button>

      <NavigationDropdown v-click-outside="() => (dropdown = false)" v-if="dropdown">
        <UserDropdown @push="push" :isMobileView="isMobileView" />
      </NavigationDropdown>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import NavigationDropdown from '@/components/Navigation/dropdown.vue';
import UserDropdown from '@/components/Navigation/user-dropdown.vue';

import IconServices from '@/shared/icons/IconServices.vue';

@Options({
  components: {
    UserDropdown,
    IconServices,
    NavigationDropdown,
  },
  props: {
    isMobileView: Boolean,
  },
  computed: {
    ...mapState('service', ['user']),
  },
})
export default class NavigationAuthButtons extends Vue {
  private dropdown = false;

  private push() {
    this.dropdown = false;
  }

  private showAuth(variant: string) {
    this.$store.commit('modals/data', { variant });
    this.$store.commit('modals/show', 'auth');
  }

  private toggleDropdown() {
    this.dropdown = !this.dropdown;
  }
}
</script>

<style lang="scss" scoped>
.navigation-buttons-auth {
  display: flex;
  flex-direction: row;
  height: 40px;
  gap: 10px;

  button {
    height: 40px;
    min-height: 0;
    border-radius: 5px;
    padding: 0 16px;

    background-color: $white;
    &:hover {
      background-color: $snow;
    }

    p {
      font-family:
        Sofia Pro,
        sans-serif;
      font-weight: 500;
      font-size: 14px;
    }
  }
}

.unix button p {
  height: 18px;
}
</style>
