import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"
import _imports_0 from '../assets/order_tracking/arrow_in_circle.png'
import _imports_1 from '../assets/check.svg'
import _imports_2 from '../assets/order_tracking/text.png'
import _imports_3 from '../assets/order_tracking/tracking.png'
import _imports_4 from '../assets/order_tracking/message.png'
import _imports_5 from '../assets/order_tracking/receipt.png'


const _hoisted_1 = {
  key: 0,
  class: "loader"
}
const _hoisted_2 = {
  key: 1,
  class: "order-tracker"
}
const _hoisted_3 = { class: "order-tracker-header" }
const _hoisted_4 = { class: "left-part" }
const _hoisted_5 = {
  key: 2,
  class: "invoice-reminder hide-desktop"
}
const _hoisted_6 = { class: "right-part hide-mobile" }
const _hoisted_7 = {
  key: 0,
  class: "invoice-reminder"
}
const _hoisted_8 = { class: "order-tracker-body" }
const _hoisted_9 = { class: "left-part" }
const _hoisted_10 = { class: "status-info" }
const _hoisted_11 = { class: "title" }
const _hoisted_12 = {
  key: 0,
  class: "description"
}
const _hoisted_13 = {
  key: 1,
  class: "description isWarning"
}
const _hoisted_14 = {
  key: 2,
  class: "description isWarning"
}
const _hoisted_15 = {
  key: 3,
  class: "description"
}
const _hoisted_16 = {
  key: 4,
  class: "description"
}
const _hoisted_17 = {
  key: 5,
  class: "description"
}
const _hoisted_18 = {
  key: 6,
  class: "description"
}
const _hoisted_19 = {
  key: 7,
  class: "description"
}
const _hoisted_20 = { key: 8 }
const _hoisted_21 = { class: "progress-bar" }
const _hoisted_22 = { class: "what-next-bullet-point" }
const _hoisted_23 = {
  key: 1,
  class: "bigger-icon",
  src: _imports_1,
  alt: ""
}
const _hoisted_24 = {
  key: 0,
  class: "animated-content"
}
const _hoisted_25 = { class: "what-next-bullet-point" }
const _hoisted_26 = {
  key: 1,
  class: "bigger-icon",
  src: _imports_1,
  alt: ""
}
const _hoisted_27 = {
  key: 2,
  src: _imports_2,
  alt: ""
}
const _hoisted_28 = {
  key: 0,
  class: "animated-content"
}
const _hoisted_29 = { class: "what-next-bullet-point" }
const _hoisted_30 = {
  key: 1,
  class: "bigger-icon",
  src: _imports_1,
  alt: ""
}
const _hoisted_31 = {
  key: 2,
  src: _imports_3,
  alt: ""
}
const _hoisted_32 = {
  key: 9,
  style: {"margin-top":"20px","display":"flex","flex-direction":"row","gap":"15px"}
}
const _hoisted_33 = { class: "footer hide-mobile" }
const _hoisted_34 = { class: "right-part" }
const _hoisted_35 = { class: "basket-info hide-mobile" }
const _hoisted_36 = { class: "basket-info-header" }
const _hoisted_37 = { class: "items-section" }
const _hoisted_38 = {
  key: 0,
  class: "buttons-row"
}
const _hoisted_39 = { class: "footer hide-desktop" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_app_back_link = _resolveComponent("app-back-link")!
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_ZigZag = _resolveComponent("ZigZag")!
  const _component_TrackedOrderItems = _resolveComponent("TrackedOrderItems")!

  return (_ctx.loading || !_ctx.order)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Logo, { class: "logo-loader" })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.$route.query.embedded)
              ? (_openBlock(), _createBlock(_component_app_back_link, {
                  key: 0,
                  mini: "",
                  link: _ctx.initialPath
                }, null, 8, ["link"]))
              : (_openBlock(), _createBlock(_component_app_back_link, {
                  key: 1,
                  mini: "",
                  link: "/restaurants"
                })),
            (_ctx.order.paymentMethod === 'invoice' && _ctx.progressLevel < 3)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[3] || (_cache[3] = [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }, null, -1),
                  _createElementVNode("p", null, "It looks like you paid by invoice. You'll receive this after your delivery has been completed.", -1)
                ])))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.order.paymentMethod === 'invoice' && _ctx.progressLevel < 3)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[4] || (_cache[4] = [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }, null, -1),
                  _createElementVNode("p", null, "It looks like you paid by invoice. You'll receive this after your delivery has been completed.", -1)
                ])))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("h1", _hoisted_11, _toDisplayString(_ctx.title), 1),
              (_ctx.order.status === 'delivered')
                ? (_openBlock(), _createElementBlock("p", _hoisted_12, " Something not right with your order? Please contact our live support below. "))
                : (_ctx.order.status === 'declined')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.order.paymentMethod === 'invoice'
                ? 'Unfortunately your order has been declined. You will not be sent an invoice.'
                : 'Unfortunately your order has been declined. A full refund has been processed.'), 1))
                  : (_ctx.order.isLate)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_14, " We're very sorry your order is running late. Please contact our live support for an update. "))
                    : (_ctx.progressLevel === 3)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_15, " Please reach out to our live support if you have any questions. "))
                      : (_ctx.progressLevel === 2 && _ctx.order.type === 'delivery')
                        ? (_openBlock(), _createElementBlock("p", _hoisted_16, [
                            _cache[5] || (_cache[5] = _createTextVNode(" We'll aim to deliver your order ")),
                            _cache[6] || (_cache[6] = _createElementVNode("b", null, "today", -1)),
                            _cache[7] || (_cache[7] = _createTextVNode(" between ")),
                            _createElementVNode("b", null, _toDisplayString(_ctx.orderTime), 1),
                            _cache[8] || (_cache[8] = _createTextVNode(". "))
                          ]))
                        : (_ctx.progressLevel === 2 && _ctx.order.type === 'collect')
                          ? (_openBlock(), _createElementBlock("p", _hoisted_17, " Please head over to the restaurant shortly to pick up your order. "))
                          : (_ctx.order.type === 'delivery')
                            ? (_openBlock(), _createElementBlock("p", _hoisted_18, [
                                _cache[9] || (_cache[9] = _createTextVNode(" We'll aim to deliver your order on the ")),
                                _createElementVNode("b", null, _toDisplayString(_ctx.orderDate), 1),
                                _cache[10] || (_cache[10] = _createTextVNode(" between ")),
                                _createElementVNode("b", null, _toDisplayString(_ctx.orderTime), 1),
                                _cache[11] || (_cache[11] = _createTextVNode(". "))
                              ]))
                            : (_openBlock(), _createElementBlock("p", _hoisted_19, [
                                _cache[12] || (_cache[12] = _createTextVNode(" Your order will be ready for collection on the ")),
                                _createElementVNode("b", null, _toDisplayString(_ctx.orderDate), 1),
                                _cache[13] || (_cache[13] = _createTextVNode(" between ")),
                                _createElementVNode("b", null, _toDisplayString(_ctx.orderTime), 1),
                                _cache[14] || (_cache[14] = _createTextVNode(". "))
                              ])),
              (_ctx.order.status !== 'delivered')
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _cache[18] || (_cache[18] = _createElementVNode("p", { class: "what-next" }, [
                      _createElementVNode("b", null, "What happens next?")
                    ], -1)),
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["bullet-point-box", { active: _ctx.progressLevel > 0, declined: _ctx.order.status === 'declined' }])
                        }, [
                          (_ctx.order.status === 'declined')
                            ? (_openBlock(), _createBlock(_component_IconServices, {
                                key: 0,
                                class: "bigger-icon",
                                icon: "add",
                                close: ""
                              }))
                            : (_openBlock(), _createElementBlock("img", _hoisted_23))
                        ], 2),
                        _createElementVNode("p", {
                          class: _normalizeClass({ declined: _ctx.order.status === 'declined' })
                        }, "Place your order with Foodstuff", 2)
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["bar", { active: _ctx.progressLevel > 1, declined: _ctx.order.status === 'declined' }])
                      }, [
                        (_ctx.progressLevel === 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_24))
                          : _createCommentVNode("", true)
                      ], 2),
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["bullet-point-box", { active: _ctx.progressLevel > 1, declined: _ctx.order.status === 'declined' }])
                        }, [
                          (_ctx.order.status === 'declined')
                            ? (_openBlock(), _createBlock(_component_IconServices, {
                                key: 0,
                                class: "bigger-icon",
                                icon: "add",
                                close: ""
                              }))
                            : (_ctx.progressLevel > 1)
                              ? (_openBlock(), _createElementBlock("img", _hoisted_26))
                              : (_openBlock(), _createElementBlock("img", _hoisted_27))
                        ], 2),
                        _createElementVNode("p", {
                          class: _normalizeClass({ declined: _ctx.order.status === 'declined' })
                        }, _cache[15] || (_cache[15] = [
                          _createTextVNode(" You'll receive a text when your order "),
                          _createElementVNode("br", null, null, -1),
                          _createTextVNode("is preparing ")
                        ]), 2)
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["bar", { active: _ctx.progressLevel > 2, declined: _ctx.order.status === 'declined' }])
                      }, [
                        (_ctx.progressLevel === 2)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_28))
                          : _createCommentVNode("", true)
                      ], 2),
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["bullet-point-box", { active: _ctx.progressLevel > 2, declined: _ctx.order.status === 'declined' }])
                        }, [
                          (_ctx.order.status === 'declined')
                            ? (_openBlock(), _createBlock(_component_IconServices, {
                                key: 0,
                                class: "bigger-icon",
                                icon: "add",
                                close: ""
                              }))
                            : (_ctx.progressLevel > 2)
                              ? (_openBlock(), _createElementBlock("img", _hoisted_30))
                              : (_openBlock(), _createElementBlock("img", _hoisted_31))
                        ], 2),
                        (_ctx.order.type === 'delivery')
                          ? (_openBlock(), _createElementBlock("p", {
                              key: 0,
                              class: _normalizeClass({ declined: _ctx.order.status === 'declined' })
                            }, _cache[16] || (_cache[16] = [
                              _createTextVNode(" You'll receive a tracking link via text and "),
                              _createElementVNode("br", null, null, -1),
                              _createTextVNode("email when your order is on the way ")
                            ]), 2))
                          : (_openBlock(), _createElementBlock("p", {
                              key: 1,
                              class: _normalizeClass({ declined: _ctx.order.status === 'declined' })
                            }, [
                              _createTextVNode(" Head to pick up your food at " + _toDisplayString(_ctx.orderTime) + " ", 1),
                              _createElementVNode("b", {
                                class: "pick-up-button custom-cursor-hover",
                                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.redirectToGoogleMaps && _ctx.redirectToGoogleMaps(...args)))
                              }, _cache[17] || (_cache[17] = [
                                _createElementVNode("br", null, null, -1),
                                _createTextVNode("Pick up address")
                              ]))
                            ], 2))
                      ])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_32, [
                    _createVNode(_component_app_button, {
                      class: "support-or-edit-button",
                      slim: "",
                      onClick: _ctx.openFreshchat
                    }, {
                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                        _createElementVNode("img", {
                          src: _imports_4,
                          alt: ""
                        }, null, -1),
                        _createElementVNode("p", null, "Live support", -1)
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ])),
              _createElementVNode("div", {
                class: "view-receipt custom-cursor-hover hide-desktop",
                style: {"margin-top":"10px"},
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openTrackedOrderDetailsDialog && _ctx.openTrackedOrderDetailsDialog(...args)))
              }, _cache[20] || (_cache[20] = [
                _createElementVNode("img", {
                  src: _imports_5,
                  alt: ""
                }, null, -1),
                _createElementVNode("p", null, "View receipt", -1)
              ]))
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["spacer", { delivered: _ctx.order.status === 'delivered' }])
            }, null, 2),
            _createElementVNode("p", _hoisted_33, "With ❤️ from " + _toDisplayString(_ctx.orderCity) + "'s independents", 1)
          ]),
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, [
              _createVNode(_component_ZigZag),
              _createElementVNode("h2", _hoisted_36, "Order #" + _toDisplayString(_ctx.order.friendlyId), 1),
              _cache[24] || (_cache[24] = _createElementVNode("div", { class: "separator" }, null, -1)),
              _createElementVNode("div", {
                class: "view-receipt custom-cursor-hover",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openTrackedOrderDetailsDialog && _ctx.openTrackedOrderDetailsDialog(...args)))
              }, _cache[21] || (_cache[21] = [
                _createElementVNode("img", {
                  src: _imports_5,
                  alt: ""
                }, null, -1),
                _createElementVNode("p", null, "View receipt", -1)
              ])),
              _createElementVNode("div", _hoisted_37, [
                _cache[22] || (_cache[22] = _createElementVNode("h2", { class: "items-section-header" }, "Items", -1)),
                _cache[23] || (_cache[23] = _createElementVNode("div", { class: "divider ham full-width" }, null, -1)),
                _createVNode(_component_TrackedOrderItems, {
                  cart: _ctx.order.cart,
                  groupBaskets: _ctx.order.groupBaskets || []
                }, null, 8, ["cart", "groupBaskets"])
              ]),
              _createVNode(_component_ZigZag)
            ]),
            (_ctx.order.status !== 'delivered')
              ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                  _createVNode(_component_app_button, {
                    class: "support-or-edit-button",
                    slim: "",
                    onClick: _ctx.openFreshchat
                  }, {
                    default: _withCtx(() => _cache[25] || (_cache[25] = [
                      _createElementVNode("img", {
                        src: _imports_4,
                        alt: ""
                      }, null, -1),
                      _createElementVNode("p", null, "Live support", -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"]),
                  (_ctx.order.status === 'scheduled')
                    ? (_openBlock(), _createBlock(_component_app_button, {
                        key: 0,
                        class: "support-or-edit-button",
                        slim: "",
                        white: "",
                        onClick: _ctx.openAmendYourOrderDialog
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_IconServices, { icon: "settings" }),
                          _cache[26] || (_cache[26] = _createElementVNode("p", null, "Edit your basket", -1))
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("p", _hoisted_39, "With ❤️ from " + _toDisplayString(_ctx.orderCity) + "'s independents", 1)
          ])
        ])
      ]))
}