<template>
  <div id="white-space" v-if="!restaurant && message && type === 'delivery'" />
  <div v-if="message && type === 'delivery'" class="delivery-banner" :class="{ withMargin: !restaurant }">
    <p>{{ message }}</p>
  </div>
</template>

<script lang="ts">
import { watch } from 'vue';
import { Options, Vue, prop } from 'vue-class-component';
import { mapState } from 'vuex';

import settings from '@/requests/settings';

class Props {
  restaurant = prop<FullInfoRestaurant | undefined>({ default: undefined });
}

@Options({
  computed: {
    ...mapState('order', ['type']),
  },
})
export default class UnavailableDeliveryBanner extends Vue.with(Props) {
  private systemMessage = '';

  get message() {
    if (this.restaurant) {
      if (this.$store.state.amendableOrder.data) return '';
      if (this.restaurant.deliveryLabel) return this.restaurant.deliveryLabel;
    }

    return this.systemMessage;
  }

  private async fetchDeliveryMessage() {
    const cityName = this.$store.state.address.cityName || (this.$route.params.city as string);
    let queryParams = `?cityName=${cityName}`;

    const deliverToTimestamp = this.$store.getters['order/scheduledDeliveryTimestamp'];
    if (deliverToTimestamp) {
      queryParams += `&time=${deliverToTimestamp}`;
    }

    const response = await settings.getClosestDeliveryOpeningHour(queryParams);

    this.systemMessage = response;
  }

  created() {
    this.fetchDeliveryMessage();

    watch(
      () => [
        this.$store.state.order.type,
        this.$store.state.order.scheduledDeliveryInterval,
        this.$store.state.order.scheduledDeliveryDate,
      ],
      () => {
        this.fetchDeliveryMessage();
      },
    );
  }
}
</script>

<style lang="scss" scoped>
.delivery-banner {
  @include row-centered;
  background-color: $vendor80;
  padding: 10px;
  width: 100%;

  &.withMargin {
    width: calc(100% - 32px);
    max-width: 1212px;
    margin: 0 16px;
  }

  p {
    @include p2;
  }
}

#white-space {
  height: 15px;
}
</style>
