import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal"
}
const _hoisted_2 = { class: "modal-info" }
const _hoisted_3 = { class: "modal-info" }
const _hoisted_4 = { class: "modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CurrencyInput = _resolveComponent("CurrencyInput")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "error-modal" }, _ctx.options), {
    default: _withCtx(() => [
      (_ctx.data)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "modal-title" }, "Enter amount", -1)),
            _createVNode(_component_CurrencyInput, {
              id: "tip",
              modelValue: _ctx.tipAmount,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tipAmount) = $event)),
              options: {
          currency: 'GBP',
          precision: 2,
          hideCurrencySymbolOnFocus: false,
          locale: 'en-GB',
        },
              showError: ""
            }, _createSlots({ _: 2 }, [
              (_ctx.yupErrMsg('tip'))
                ? {
                    name: "error",
                    fn: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.yupErrMsg('tip')), 1)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["modelValue"]),
            _createElementVNode("p", _hoisted_2, "Basket value: £" + _toDisplayString(_ctx.data.basketValue), 1),
            _createElementVNode("p", _hoisted_3, "(" + _toDisplayString(((_ctx.tipAmount / _ctx.data.basketValue) * 100).toFixed(2)) + "% of your basket)", 1),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_app_button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submit()))
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("strong", null, "Submit", -1)
                ])),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16))
}