<template>
  <div class="currency-input-wrapper">
    <input
      :id="id"
      class="currency-input"
      ref="inputRef"
      type="text"
      aria-label="currency-input-field"
      @focus="focus"
      :class="{
        error: $slots.error && showError,
      }"
    />
    <small v-if="$slots.error && showError">
      <b><slot name="error" /></b>
    </small>
    <div v-else-if="addErrorPlaceholderSpacing" style="height: 28px" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useCurrencyInput } from 'vue-currency-input';

export default defineComponent({
  name: 'CurrencyInput',
  props: {
    id: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Number,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    addErrorPlaceholderSpacing: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { inputRef } = useCurrencyInput(props.options);

    return { inputRef };
  },
  methods: {
    focus() {
      if (!this.id) return;

      const isError = this.$store.state.yup.errors.some((e) => e.path === this.id);
      if (isError) this.$store.commit('yup/resetPath', this.id);
    },
  },
});
</script>

<style lang="scss" scoped>
.currency-input-wrapper {
  display: flex;
  flex-direction: column;

  .currency-input {
    background-color: $white;
    padding: {
      top: 12px;
      bottom: 12px;
      left: 20px;
      right: 12px;
    }
    width: 100%;
    max-width: 315px;
    height: 45px;
    border: 1px solid $coal20;
    border-radius: 10px;

    &:focus,
    &.error {
      padding: {
        top: 10px;
        bottom: 10px;
        left: 18px;
        right: 10px;
      }
    }

    &:focus {
      border: 3px solid $ham;
    }

    &.error {
      border: 3px solid $error;
    }
  }

  small {
    color: $error;
    margin-top: 10px;
  }
}
</style>
