<template>
  <app-modal class="create-account-modal" v-bind="options">
    <div v-if="step === 1">
      <h2 class="ta-c">Create account</h2>

      <app-input v-model:value="firstName" :showError="show" id="first_name">
        <template v-slot:label>First name</template>
        <template v-slot:error v-if="yupErrMsg('firstName')">
          {{ yupErrMsg('firstName') }}
        </template>
      </app-input>

      <app-input v-model:value="phone" :showError="show" id="phone_number">
        <template v-slot:label>Telephone number</template>
        <template v-slot:error v-if="yupErrMsg('phone')">
          {{ yupErrMsg('phone') }}
        </template>
      </app-input>

      <app-input v-model:value="email" :showError="show" id="email_signup">
        <template v-slot:label>Email address</template>
        <template v-slot:error v-if="yupErrMsg('email')">
          {{ yupErrMsg('email') }}
        </template>
      </app-input>

      <app-input v-model:value="password" :showError="show" type="password" id="password">
        <template v-slot:label>Password</template>
        <template v-slot:error v-if="yupErrMsg('password')">
          {{ yupErrMsg('password') }}
        </template>
      </app-input>

      <app-input v-model:value="reEnterPassword" :showError="show" type="password" id="password_repeat">
        <template v-slot:label>Re-enter password</template>
        <template v-slot:error v-if="yupErrMsg('reEnterPassword')">
          {{ yupErrMsg('reEnterPassword') }}
        </template>
      </app-input>

      <app-input v-model:value="companyName" :showError="show" id="company_name">
        <template v-slot:label>Company name (optional)</template>
      </app-input>

      <app-button
        class="g-recaptcha"
        data-sitekey="6Lf7Qm4eAAAAAHsyhY_nPcKoSi1dUCQDAhblLx_j"
        data-callback="signUp"
        data-action="submit"
        :loader="loader"
        @click="signUp"
        id="create_account"
      >
        Create account
      </app-button>

      <p class="already-account">
        Already have an account?
        <u class="custom-cursor-hover" @click="login">Log in</u>
      </p>

      <div class="error">{{ errorMessage }}</div>
    </div>

    <!-- SECOND STEP - PHONE NUMBER VERIFICATION -->
    <div v-else-if="step === 2" class="verification">
      <h2 class="ta-c">Submit verification</h2>
      <p>We've sent a code to {{ phone }}.</p>
      <p>Please enter the code below to verify your account.</p>
      <div class="verification__inputs">
        <app-input
          v-model:value="code[0]"
          :showError="show"
          id="code"
          class="verification__input"
          maxlength="1"
          v-on:input="(e) => focusOnInput(e, 'code-input-2')"
        >
          <template v-slot:error v-if="yupErrMsg('code')">
            {{ yupErrMsg('code') }}
          </template>
        </app-input>
        <app-input
          v-model:value="code[1]"
          :showError="show"
          id="code-input-2"
          class="verification__input"
          maxlength="1"
          v-on:input="(e) => focusOnInput(e, 'code-input-3')"
        >
          <template v-slot:error v-if="yupErrMsg('code')">
            {{ yupErrMsg('code') }}
          </template>
        </app-input>
        <app-input
          v-model:value="code[2]"
          :showError="show"
          id="code-input-3"
          class="verification__input"
          maxlength="1"
          v-on:input="(e) => focusOnInput(e, 'code-input-4')"
        >
          <template v-slot:error v-if="yupErrMsg('code')">
            {{ yupErrMsg('code') }}
          </template>
        </app-input>
        <app-input
          v-model:value="code[3]"
          :showError="show"
          id="code-input-4"
          class="verification__input"
          maxlength="1"
          v-on:input="(e) => focusOnInput(e, 'code-input-5')"
        >
          <template v-slot:error v-if="yupErrMsg('code')">
            {{ yupErrMsg('code') }}
          </template>
        </app-input>
        <app-input
          v-model:value="code[4]"
          :showError="show"
          id="code-input-5"
          class="verification__input"
          maxlength="1"
          v-on:input="(e) => focusOnInput(e, 'code-input-6')"
        >
          <template v-slot:error v-if="yupErrMsg('code')">
            {{ yupErrMsg('code') }}
          </template>
        </app-input>
        <app-input
          v-model:value="code[5]"
          :showError="show"
          id="code-input-6"
          class="verification__input"
          maxlength="1"
        >
          <template v-slot:error v-if="yupErrMsg('code')">
            {{ yupErrMsg('code') }}
          </template>
        </app-input>
      </div>
      <button @click="goBack">Go back</button>
      <app-button @click="checkCodeAndCreateUser" :loader="loader" :disabled="loader">Create account</app-button>
      <div class="error">{{ codeErrorMessage }}</div>
    </div>
    <div class="recaptcha-container" id="recaptcha-container" />
  </app-modal>
</template>

<script lang="ts">
import { FirebaseError } from 'firebase/app';
import {
  ConfirmationResult,
  EmailAuthCredential,
  EmailAuthProvider,
  RecaptchaVerifier,
  getAuth,
  linkWithCredential,
  signInWithPhoneNumber,
  updateProfile,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import * as yup from 'yup';

import account, { SignUp } from '@/requests/account';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

import http from '@/utils/http';
import methods from '@/utils/methods';

@Options({
  computed: {
    ...mapState('yup', ['show']),
  },
})
export default class ModalCreateAccount extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: true,
    doesNotClose: false,
  };

  private auth = getAuth();
  private db = getFirestore();

  private loader = false;
  private firstName = '';
  private phone = '+44';
  private email = '';
  private password = '';
  private reEnterPassword = '';
  private companyName = '';
  private errorMessage = '';
  private codeErrorMessage = '';
  private step = 1;
  private code = [];
  private appVerifier: any = {};
  private confirmationResult?: ConfirmationResult;
  private emailCredential?: EmailAuthCredential;

  private schema = yup.object({
    firstName: yup
      .string()
      .required()
      .matches(/[a-zA-Z]/, 'First name can only contain Latin letters.'),
    phone: yup
      .string()
      .required()
      .length(13, 'Phone number must be exactly 13 characters')
      .matches(/^\+44/, 'Phone number must start with +44')
      .matches(/^[+]*[0-9]*$/, 'Phone can only contain numbers.'),
    email: yup.string().required().email(),
    password: yup
      .string()
      .required('No password provided.')
      .matches(/[A-Z]/, 'Password should contain at least one capital letter.')
      .matches(/[0-9]/, 'Password should contain at least one number.')
      .min(8, 'Password should be at least 8 characters long.'),
    reEnterPassword: yup.string().oneOf([yup.ref('password'), undefined], 'Passwords must match'),
  });

  private async signUp() {
    this.phone = this.phone.replaceAll(' ', '');

    const user: SignUp = {
      firstName: this.firstName,
      phone: this.phone,
      email: this.email,
      password: this.password,
      reEnterPassword: this.reEnterPassword,
      companyName: this.companyName,
    };

    this.loader = true;

    try {
      await this.schema.validate(user, { abortEarly: false });
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        methods.yupValidCatch(err);
        this.loader = false;
        return;
      }
    }

    try {
      this.$store.commit('yup/clear');
      this.errorMessage = '';

      await http.post('/accounts/sign-up/pre-check', { phone: this.phone, email: this.email });

      this.emailCredential = EmailAuthProvider.credential(this.email, this.password);

      await this.beginPhoneVerification();
    } catch (err) {
      if (err instanceof FirebaseError) {
        console.error(err.message);
        switch (err.code) {
          case 'auth/email-already-exists':
            this.errorMessage = 'Account with this email address already exists';
            break;
          case 'auth/invalid-password':
            this.errorMessage = 'Password must contain at least six characters';
            break;
          default:
            this.errorMessage = 'Something went wrong';
            break;
        }
        return;
      }

      this.loader = false;
      this.errorMessage = (err as string) || 'Something went wrong';
    }
  }

  private async beginPhoneVerification() {
    try {
      this.confirmationResult = await signInWithPhoneNumber(this.auth, this.phone, this.appVerifier);
      this.errorMessage = '';
      this.codeErrorMessage = '';
      this.step = 2;
    } catch (err) {
      if (err instanceof FirebaseError) {
        // FIREBASE ERRORS CUSTOM HANDLING - to refactor
        if (err.code === 'auth/too-many-requests') {
          this.errorMessage = 'Too many attempts. Please try again later.';
        } else if (/Invalid format/i.test(err.message)) {
          this.errorMessage = 'Invalid phone format. Try +44 and your number.';
        } else if (/too_long/i.test(err.message) || /too_long/i.test(err.message)) {
          this.errorMessage = 'Phone number is too long.';
        } else if (/too_short/i.test(err.message) || /too_short/i.test(err.message)) {
          this.errorMessage = 'Phone number is too short.';
        } else if (
          /invalid-phone-number/i.test(err.message) ||
          err.code === 'auth/invalid-phone-number' ||
          err.message === 'INVALID_PHONE_NUMBER'
        ) {
          this.errorMessage = 'Invalid phone number.';
        } else {
          console.error(err);
          this.errorMessage = 'Something went wrong. Please reload the page and try again.';
        }
      }
    } finally {
      this.loader = false;
    }
  }

  private async checkCodeAndCreateUser() {
    if (!this.emailCredential) {
      console.error('No email credential');
      this.codeErrorMessage = 'Something went wrong.';
      return;
    }

    this.loader = true;

    if (!this.confirmationResult) {
      throw new Error('No confirmation result');
    }

    let isBusinessUser = false;
    try {
      await this.confirmationResult.confirm(this.code.join(''));

      if (!this.auth.currentUser) {
        console.error('User not logged in to firestore');
        this.codeErrorMessage = 'Something went wrong.';
        return;
      }

      await linkWithCredential(this.auth.currentUser, this.emailCredential);
      await updateProfile(this.auth.currentUser, { displayName: this.firstName });

      if (this.companyName) {
        try {
          isBusinessUser = (await account.verifyEmailDomain(this.email)).success;
        } catch {}
        await account.saveUserToFirestore(this.companyName, isBusinessUser);
      } else {
        await account.saveUserToFirestore();
      }
      // NOTE: Firebase listeners defined in App.vue will automatically update local storage after signing up.
    } catch (err) {
      if (err instanceof Error) {
        console.error(err.message);
        if (err.message === 'Firebase: Error (auth/invalid-verification-code).') {
          this.codeErrorMessage = 'Invalid Code';
        } else {
          this.codeErrorMessage = 'Something went wrong. Please contact support.';
          throw new Error(err.message);
        }
      }
      this.loader = false;
      return;
    }

    this.close();
    if (isBusinessUser) this.$store.commit('modals/show', 'buBenefits');
  }

  private login() {
    this.close();
    this.$store.commit('modals/show', 'login');
  }

  private yupErrMsg(path: string) {
    return methods.yupErrMsg(path);
  }

  private close() {
    if (this.appVerifier.clear && !this.appVerifier.destroyed) this.appVerifier.clear();
    this.$store.commit('modals/close');
  }

  private focusOnInput(e: Event & { target: HTMLInputElement }, inputId: string) {
    const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    const input = document.getElementById(inputId);
    const inputValue = parseInt(e.target?.value, 10);

    if (numbers.includes(inputValue) && input) {
      input.focus();
    }
  }

  private goBack() {
    this.step = 1;
    this.code = [];
    this.codeErrorMessage = '';
  }

  mounted() {
    if (this.appVerifier.clear && !this.appVerifier.destroyed) this.appVerifier.clear();

    // For RecaptchaV3 - needs binded function(?)
    (window as any).signUp = this.signUp;

    this.appVerifier = new RecaptchaVerifier(this.auth, 'recaptcha-container', { size: 'invisible' });

    this.$store.commit('yup/toggleShowError', false);
    this.$store.commit('yup/clear');
  }

  unmounted() {
    (window as any).signUp = undefined;
  }
}
</script>

<style lang="scss" scoped>
.create-account-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: 15px;
  }

  .controller-input-wrapper {
    margin-bottom: 20px;
  }

  .controller-button {
    margin-top: 10px;
  }

  .already-account {
    text-align: center;
    margin: 15px 0;
    @include p2;
    color: $coal100;

    u {
      @include p2;
      margin-left: 5px;
    }
  }

  .error {
    color: red;
    font-weight: bold;
    text-align: center;
  }

  .close-btn {
    font-size: 1.2em;
  }
}

.verification {
  text-align: center;

  h2 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    max-width: 170px;
  }

  p {
    margin-left: auto;
    margin-right: auto;
    max-width: 270px;
  }

  &__inputs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3em;
    margin-bottom: 138px;
  }

  &__input {
    margin: 0 2px;
    width: 50px;
    text-align: center;
  }

  input[type='text'] {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    padding: 0;
  }

  .error {
    margin-top: 1em;
    text-align: center;
  }
}
</style>
