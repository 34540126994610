<template>
  <!-- DESKTOP: Active tracker -->
  <div v-if="step < 4" class="group-order-step-tracker hide-mobile">
    <div class="group-order-step">
      <div class="circle" :class="{ inactive: step < 1 }">
        <div class="check" v-if="step > 1">
          <img src="../../assets/check.svg" alt="" />
        </div>
        <p v-else>1</p>
      </div>
      <p v-if="groupRole === 'Owner'">Create and share<br />your group link</p>
      <p v-else>Add your name and<br />click 'View menu'</p>
    </div>
    <div class="group-order-step">
      <div class="circle" :class="{ inactive: step < 2 }">
        <div class="check" v-if="step > 2">
          <img src="../../assets/check.svg" alt="" />
        </div>
        <p v-else>2</p>
      </div>
      <p>Choose your items<br />and add to basket</p>
      <hr class="divider_left" />
      <hr class="divider_right" />
    </div>
    <div class="group-order-step">
      <div class="circle" :class="{ inactive: step < 3 }">
        <div class="check" v-if="step > 3">
          <img src="../../assets/check.svg" alt="" />
        </div>
        <p v-else>3</p>
      </div>
      <p v-if="groupRole === 'Owner'">Checkout when all users<br />have added their items</p>
      <p v-else>Click 'Add your items'<br />then close tab</p>
    </div>
  </div>

  <!-- DESKTOP: Participant's finished flow -->
  <div v-else class="group-order-step-tracker hide-mobile">
    <div class="group-order-step finished">
      <div class="circle">
        <div class="check">
          <img src="../../assets/check.svg" alt="" />
        </div>
      </div>
      <p>You're all set! You can now close this tab.</p>
    </div>
  </div>

  <!-- MOBILE: Active or finished tracker -->
  <div class="group-order-step-tracker hide-desktop">
    <div v-if="step === 1" class="group-order-step-mobile">
      <div class="circle with-step-word">
        <p>STEP</p>
        <p>1/3</p>
      </div>
      <div v-if="groupRole === 'Owner'" class="texts">
        <b>Create and share</b>
        <p>Create and share your group link</p>
      </div>
      <div v-else class="texts">
        <b>Add your name</b>
        <p>Add your name and click 'View menu'</p>
      </div>
    </div>
    <div v-if="step === 2" class="group-order-step-mobile">
      <div class="circle with-step-word">
        <p>STEP</p>
        <p>2/3</p>
      </div>
      <div class="texts">
        <b>Choose your items</b>
        <p>Choose a dish and click 'Add to basket'</p>
      </div>
    </div>
    <div v-if="step === 3" class="group-order-step-mobile">
      <div class="circle with-step-word">
        <p>STEP</p>
        <p>3/3</p>
      </div>
      <div v-if="groupRole === 'Owner'" class="texts">
        <b>Head to checkout</b>
        <p>When all users have added their items</p>
      </div>
      <div v-else class="texts">
        <b>Add your items</b>
        <p>Once you're done, click 'Add your items'</p>
      </div>
    </div>
    <div v-if="step === 4" class="group-order-step-mobile">
      <div class="circle">
        <div class="check">
          <img src="../../assets/check.svg" alt="" />
        </div>
      </div>
      <div class="texts">
        <b>You're all set!</b>
        <p>You can now close this tab</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

@Options({
  computed: {
    ...mapState('groupOrder', ['groupData', 'groupRole']),
  },
})
export default class GroupOrderStepTracker extends Vue {
  get step() {
    const { areItemsConfirmed } = this.$store.state.groupOrder;
    if (areItemsConfirmed) return 4; // Participant flow: finished

    const { dishes } = this.$store.state.basket;
    return dishes.length === 0 ? 2 : 3; // Step 2: Add items / Step 3: Confirm
  }
}
</script>

<style lang="scss" scoped>
.group-order-step-tracker {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid $coal20;
  border-radius: 15px;
  background-color: $white;
  gap: 20px;

  height: 140px;
  @include for-smartphone {
    height: unset;
    padding: 15px;
  }
}

.group-order-step {
  display: flex;
  flex-direction: column;
  gap: 7.5px;
  width: 180px;
  align-items: center;
  position: relative;

  &.finished {
    width: unset;
  }

  p {
    @include p2;
    text-align: center;
    line-height: 18px;
  }

  hr {
    position: absolute;
    margin: 0;
    top: 24px;
    width: 120px;
    background-color: #00000033;

    &.divider_left {
      left: -70px;
    }

    &.divider_right {
      right: -70px;
    }
  }
}

.group-order-step-mobile {
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  align-items: center;

  .texts {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  b {
    @include p2;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
  }

  p {
    @include p3;
    line-height: 18px;
  }
}

.circle {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border: 1px solid #00000033;
  background-color: $ham;
  display: flex;
  justify-content: center;

  p {
    @include h1;
    font-size: 28px;
    height: 28px;
  }

  .check {
    display: flex;
    margin: auto;
    height: 16px;
  }

  &.inactive {
    background-color: #ffcacd33;
    p {
      color: #21212133;
    }
  }

  &.with-step-word {
    display: flex;
    flex-direction: column;
    align-items: center;

    p:first-of-type {
      @include p1;
      font-size: 8px;
      line-height: 8px;
      height: 8px;
      margin-bottom: 3px;
    }

    p:last-of-type {
      font-size: 20px;
      line-height: 20px;
      height: 20px;
    }
  }
}

.hide-mobile {
  @include for-smartphone {
    display: none;
  }
}

.hide-desktop {
  @include for-desktop {
    display: none;
  }
}
</style>
