<template>
  <app-modal class="prefilled-basket-share" :close="true">
    <h2 class="prefilled-basket-share__title">Share your basket</h2>
    <p class="prefilled-basket-share__description">
      Share this link, so that other people can use the items you have selected.
    </p>

    <div v-if="link" class="prefilled-basket-share__link">
      <p class="prefilled-basket-share__link-value">{{ link }}</p>
    </div>

    <app-button v-if="link" class="prefilled-basket-share__share-button" @click.prevent="copyToClipboard()">
      {{ copyText }}
    </app-button>

    <div v-if="!link" class="prefilled-basket-loader">
      <Logo class="logo-loader" />
    </div>
  </app-modal>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { v1 as uuidV1 } from 'uuid';
import { Options, Vue } from 'vue-class-component';

import firebaseHttp from '@/requests/firebase';
import ordersHttp from '@/requests/orders';

import Logo from '@/shared/icons/Logo.vue';

import methods from '@/utils/date';

@Options({
  components: { Logo },
})
export default class ModalShareGroupOrderLink extends Vue {
  private copyText = 'Copy';
  private link = '';

  private async copyToClipboard() {
    await navigator.clipboard.writeText(this.link);
    this.copyText = 'Copied!';
    setTimeout(() => {
      this.copyText = 'Copy';
    }, 1000);
  }

  private async createPrefilledBasketLink() {
    const { address, basket, order, service } = this.$store.state;
    if (!basket.dishes.length || !basket.restaurant || !service.user) {
      this.$store.commit('modals/close');
      return;
    }

    const prefilledBasketId = uuidV1();

    const date = new Date(order.scheduledDeliveryDate as unknown as string);
    const { start, end } = methods.combineIntervalAndDayData(order.scheduledDeliveryInterval, date);

    const f = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
    const bestDate = start ? moment(start.toLocaleString('en-US', { timeZone: 'UTC' })).format(f) : null;
    const worstDate = end ? moment(end.toLocaleString('en-US', { timeZone: 'UTC' })).format(f) : null;

    try {
      const prefilledBasketLink = await firebaseHttp.generatePrefilledBasketLink(
        prefilledBasketId,
        basket.restaurant.slug,
        basket.restaurant.restaurantCity,
      );

      await ordersHttp.createPrefilledBasket({
        id: prefilledBasketId,
        link: prefilledBasketLink,
        address,
        bestDeliverToTime: bestDate,
        worstDeliverToTime: worstDate,
        restaurantSlug: basket.restaurant.slug,
        orderType: order.type === 'collection' ? 'collect' : 'delivery',
        cart: {
          dishes: basket.dishes,
          restaurantSlug: basket.restaurant.slug,
          restaurantId: basket.restaurant.id,
        },
      });

      this.link = prefilledBasketLink;
    } catch (error) {
      console.error(error);
      this.$store.commit('modals/close');
    }
  }

  mounted() {
    this.createPrefilledBasketLink();
  }
}
</script>

<style lang="scss">
.prefilled-basket-share {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    max-width: 375px;
    width: 375px;
  }

  &__share-button {
    margin-top: 30px;
    max-width: unset;
  }

  &__title {
    text-align: center;
    margin: 0 50px;
    margin-top: 20px;
  }

  &__description {
    @include p2;
    text-align: center;
    margin: 15px 20px 30px 20px;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    padding-left: 20px;
    padding-right: 20px;
    max-height: 48px;
    min-height: 48px;
    border: 1px solid $coal20;
    border-radius: 10px;
    gap: 10px;
  }

  &__link-value {
    @include p2;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.prefilled-basket-loader {
  display: flex;
  justify-content: center;
  height: 118px;
  margin-bottom: 20px;
}
</style>
