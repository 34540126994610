<template>
  <app-modal class="error-modal" v-bind="options">
    <div v-if="!isCalendarVisible" class="modal-close">
      <IconServices close icon="add" class="custom-cursor-hover" @click="closeDialog" />
    </div>
    <div class="calendar-title-wrapper" v-else>
      <h2 class="order-modal-title">Set deadline</h2>
    </div>

    <div v-if="!isCalendarVisible" class="order-modal">
      <h2 class="order-modal-title">Set deadline</h2>
      <div class="order-modal-delivery-time">
        <app-button slim white @click="showCalendar">
          <p>{{ getFormattedDate }}</p>
          <img src="../assets/simple_arrow_bottom.svg" alt="" />
        </app-button>
      </div>
      <div id="buttons">
        <app-button class="order-modal-clear-btn square slim" @click="clear">Clear</app-button>
        <app-button class="order-modal-apply-btn square slim" @click="apply">Apply</app-button>
      </div>
    </div>
    <div v-else>
      <Calendar
        :dateTime="dateTime"
        :interval="interval"
        :intervalsByDays="timeIntervalsByDays"
        :onConfirmClick="onCalendarConfirmClick"
      />
    </div>
  </app-modal>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { Options, Vue } from 'vue-class-component';

import Calendar from '@/components/Calendar.vue';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';
import IconServices from '@/shared/icons/IconServices.vue';

@Options({ components: { Calendar, IconServices } })
export default class AppModalOrderSettingsDeadline extends Vue {
  private options: ModalInterface = {
    slim: false,
    close: false,
    noPadding: true,
  };
  private isCalendarVisible = false;
  private timeIntervalsByDays: Array<string[]> = [[], []];
  private dateTime = moment().tz('Europe/London').set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  private interval = '';

  get getFormattedDate() {
    if (!this.interval) return 'Choose a date and a time';

    const beginningOfToday = moment().tz('Europe/London').set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const diffInDays = moment(this.dateTime).diff(beginningOfToday, 'days');

    if (diffInDays === 0) return `Today - ${this.interval}`;
    if (diffInDays === 1) return `Tomorrow - ${this.interval}`;
    return `${this.dateTime.format('ddd, Do MMM')} - ${this.interval}`;
  }

  private onCalendarConfirmClick(dateTime: moment.Moment, interval: string) {
    this.dateTime = dateTime;
    this.interval = interval;
    this.closeCalendar();
  }

  private showCalendar() {
    this.isCalendarVisible = true;
  }

  private closeCalendar() {
    this.isCalendarVisible = false;
  }

  private buildListOfIntervalsForAllDays() {
    const daysToChooseFrom = this.$store.state.timePicker.scheduledDays;
    const newTimeVariantsByDays: string[][] = [];

    // Add time variants:
    for (let i = 0; i < daysToChooseFrom; i += 1) {
      const ithDayVariants: string[] = [];
      for (let j = 0; j < 24; j += 1) {
        for (let k = 0; k < 60; k += 5) {
          ithDayVariants.push(`${j.toString().padStart(2, '0')}:${k.toString().padStart(2, '0')}`);
        }
      }
      newTimeVariantsByDays[i] = ithDayVariants;
    }

    // Remove time variants from the past:
    const now = moment().tz('Europe/London');
    const nowHour = now.hour();
    const nowMinute = now.minute();
    for (let i = 0; i < newTimeVariantsByDays[0].length; i += 1) {
      const [variantHour, variantMinute] = newTimeVariantsByDays[0][i].split(':');
      if (+variantHour > nowHour || (+variantHour === nowHour && +variantMinute > nowMinute)) {
        newTimeVariantsByDays[0].splice(0, i);
        break;
      }
    }

    this.timeIntervalsByDays = newTimeVariantsByDays;
  }

  private apply() {
    const [selectedHour, selectedMinute] = this.interval.split(':').map((e) => parseInt(e, 10));
    const date = moment(this.dateTime)
      .tz('Europe/London')
      .set({
        hour: selectedHour,
        minute: selectedMinute,
        second: 0,
        millisecond: 0,
      })
      .toISOString();

    this.$store.commit('modals/put', { deadlineDate: date });
    this.closeDialog();
  }

  private clear() {
    this.$store.commit('modals/put', { deadlineDate: null });
    this.closeDialog();
  }

  private closeDialog() {
    this.$store.commit('modals/close');
  }

  mounted() {
    this.buildListOfIntervalsForAllDays();

    if (this.$store.state.modals.data.deadlineDate) {
      this.dateTime = moment(this.$store.state.modals.data.deadlineDate).tz('Europe/London');
      this.interval = this.dateTime.format('HH:mm');
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-close {
  margin: 12px 12px 0px 0;
  height: 12px;
  text-align: right;
  svg {
    width: 12px;
    height: 12px;
  }
}

.calendar-title-wrapper {
  margin-top: 15px;
  margin-bottom: 10px;
}

.order-modal {
  display: flex;
  flex-direction: column;
  padding: 10px 0 30px;
  width: 350px;
  max-width: calc(100vw - 40px);

  &-title {
    @include h2;
    font-size: 30px;
    text-align: center;
    margin-bottom: 15px;
  }

  &-delivery-time {
    width: 100%;
    background-color: $white;
    padding: 18px 30px;
    border-top: 1px solid $coal20;
    border-bottom: 1px solid $coal20;
    margin-bottom: 20px;

    button {
      border: 1px solid $coal20;
      border-radius: 5px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: auto;
      margin-right: auto;
      gap: 5px;
      width: 100%;
    }

    p {
      margin-top: 0;
    }
  }
}

#buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  .order-modal-apply-btn {
    width: 40%;
  }

  .order-modal-clear-btn {
    width: 40%;
    background-color: $coal20;
    &:hover {
      background-color: $coal40;
    }
  }
}
</style>
