import http from '@/utils/http';

class HttpRestaurants {
  public getRestaurants(options: string): Promise<RequestGetRestaurants> {
    return http.get(`/restaurants/search${options}`);
  }

  public getFilters(): Promise<{
    categories: Filter[];
    dietaries: Filter[];
    budgets: Filter[];
    perfectFors: Filter[];
  }> {
    return http.get('/restaurants/filter-options');
  }

  public checkAddressAvailability(
    city: string,
    location: google.maps.LatLng | undefined | { lat: number; lng: number },
  ) {
    return http.get(
      `/restaurants/check-available?city=${city}&lat=${
        typeof location?.lat === 'number' ? location?.lat : location?.lat()
      }&lng=${typeof location?.lng === 'number' ? location?.lng : location?.lng()}`,
    );
  }

  public checkInRange(
    cityName: string,
    location: { lng: number; lat: number },
    restaurantSlug: string,
    isCollection: boolean,
  ): Promise<{ success: boolean }> {
    return http.get(
      // eslint-disable-next-line
      `/restaurants/check-in-range?cityName=${cityName}&lat=${location.lat}&lng=${location.lng}&collect=${isCollection}&restaurantSlug=${restaurantSlug}`,
    );
  }
}

export default new HttpRestaurants();
