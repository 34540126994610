<template>
  <component v-if="icon" :is="icon" />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import account from '@/shared/icons/services/IconAccount.vue';
import accountNoBg from '@/shared/icons/services/IconAccountNoBg.vue';
import add from '@/shared/icons/services/IconAdd.vue';
import bigStar from '@/shared/icons/services/IconBigStar.vue';
import clock from '@/shared/icons/services/IconClock.vue';
import close from '@/shared/icons/services/IconClose.vue';
import done from '@/shared/icons/services/IconDone.vue';
import filters from '@/shared/icons/services/IconFilters.vue';
import forkAndKnife from '@/shared/icons/services/IconForkAndKnife.vue';
import gift from '@/shared/icons/services/IconGift.vue';
import hamburger from '@/shared/icons/services/IconHamburger.vue';
import hash from '@/shared/icons/services/IconHash.vue';
import house from '@/shared/icons/services/IconHouse.vue';
import list from '@/shared/icons/services/IconList.vue';
import location from '@/shared/icons/services/IconLocation.vue';
import locationLight from '@/shared/icons/services/IconLocationLight.vue';
import logOut from '@/shared/icons/services/IconLogOut.vue';
import love from '@/shared/icons/services/IconLove.vue';
import minus from '@/shared/icons/services/IconMinus.vue';
import orders from '@/shared/icons/services/IconOrders.vue';
import pencil from '@/shared/icons/services/IconPencil.vue';
import phone from '@/shared/icons/services/IconPhone.vue';
import pin from '@/shared/icons/services/IconPin.vue';
import questionMark from '@/shared/icons/services/IconQuestionMark.vue';
import search from '@/shared/icons/services/IconSearch.vue';
import settings from '@/shared/icons/services/IconSettings.vue';
import square from '@/shared/icons/services/IconSquare.vue';
import star from '@/shared/icons/services/IconStar.vue';
import starAlt from '@/shared/icons/services/IconStarAlt.vue';
import suitcase from '@/shared/icons/services/IconSuitcase.vue';
import sunInSunglasses from '@/shared/icons/services/IconSunInSunglasses.vue';
import thickClock from '@/shared/icons/services/IconThickClock.vue';
import trashCan from '@/shared/icons/services/IconTrashCan.vue';
import upload from '@/shared/icons/services/IconUpload.vue';

@Options({
  components: {
    account,
    accountNoBg,
    add,
    clock,
    close,
    thickClock,
    minus,
    filters,
    list,
    location,
    locationLight,
    starAlt,
    search,
    square,
    star,
    love,
    bigStar,
    orders,
    logOut,
    sunInSunglasses,
    questionMark,
    trashCan,
    forkAndKnife,
    phone,
    suitcase,
    settings,
    hamburger,
    done,
    pin,
    pencil,
    hash,
    house,
    upload,
    gift,
  },
  props: {
    icon: String,
  },
})
export default class IconServices extends Vue {}
</script>
