<template>
  <div class="sustainable_and_ethical_section">
    <img class="sustainable_image" src="../../assets/landing/sustainable_and_ethical.png" alt="" />
    <div class="sustainable_and_ethical_texts">
      <h1 class="section_title">Sustainable and ethical</h1>
      <h2 class="sub_title">We believe food tastes better when it's at the heart of doing good</h2>
      <div class="sustainable_rows">
        <div class="sustainable_row">
          <img src="../../assets/landing/sustainable_delivery.png" alt="" />
          <div class="sustainable_row_texts">
            <b>Planet-friendly delivery</b>
            <p>100% of our deliveries are emission-free, by riders on bikes or in e-vans</p>
          </div>
        </div>
        <div class="sustainable_row">
          <img src="../../assets/landing/sustainable_local.png" alt="" />
          <div class="sustainable_row_texts">
            <b>Supporting local</b>
            <p>Only the best independent restaurants and caterers in each city</p>
          </div>
        </div>
        <div class="sustainable_row">
          <img src="../../assets/landing/sustainable_welfare.png" alt="" />
          <div class="sustainable_row_texts">
            <b>Rider welfare</b>
            <p>Our riders are paid a guaranteed living wage so they deliver with a smile</p>
          </div>
        </div>
      </div>
    </div>
    <img class="sustainable_corner_logo" src="../../assets/landing/sustainable_foodstuff.png" alt="" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class SustainableAndEthical extends Vue {}
</script>

<style lang="scss" scoped>
.section_title {
  color: $coal100;
  font-family: Recoleta, sans-serif;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 10px;

  @include for-smartphone {
    font-size: 38px;
    line-height: 40px;
  }
}

.sub_title {
  font-family:
    Sofia Pro,
    sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 300;
  text-align: center;
  color: black;
  padding: 0px 30px;
}

.sustainable_and_ethical_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  @include for-smartphone {
    flex-direction: column;
  }

  .sustainable_image {
    width: 40vw;
    max-width: 425px;
    height: 607px;
    object-fit: cover;
    object-position: center;
    border-right: 2px solid black;

    @include for-custom-min(1161) {
      height: 547px;
    }

    @include for-custom-min(1220) {
      height: 513px;
    }

    @include for-smartphone {
      width: 100vw;
      min-width: 100vw;
      border-right: none;
      border-bottom: 2px solid black;
    }
  }

  .sustainable_and_ethical_texts {
    display: flex;
    flex-direction: column;
    padding: 50px 100px;

    @include for-smartphone {
      align-items: center;
      padding-bottom: 220px;
    }
  }

  h1 {
    text-align: start;
    margin: 0;

    @include for-smartphone {
      text-align: center;
      margin-bottom: 10px;
      width: 250px;
    }
  }

  h2 {
    text-align: start;
    padding: 0;

    @include for-smartphone {
      text-align: center;
    }
  }

  .sustainable_rows {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 50px;
  }

  .sustainable_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;

    img {
      width: 60px;
      height: 60px;
      border: none;
    }

    @include for-smartphone {
      flex-direction: column;
      gap: 15px;
    }
  }

  .sustainable_row_texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7.5px;

    @include for-smartphone {
      align-items: center;
      text-align: center;
      gap: 10px;
    }

    b {
      font-family: Sofia Pro;
      font-size: 19px;
    }

    p {
      font-family:
        Sofia Pro,
        sans-serif;
      font-size: 17px;
      font-weight: 300;
      color: black;
    }
  }

  .sustainable_corner_logo {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
