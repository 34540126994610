<template>
  <app-modal class="auth-modal" v-bind="options">
    <!-- Logging in or registering -->
    <div v-if="!connectAccount" class="auth-wrapper">
      <!-- Header -->
      <h2 v-if="data?.variant === 'register'">Create account below</h2>
      <h2 v-else-if="data?.variant === 'login'">Log in below</h2>
      <h2 v-else>Create account<br />or log in</h2>

      <!-- SSO buttons -->
      <div class="auth-buttons">
        <app-button
          class="continue-with-btn"
          @click="data?.variant === 'register' ? openRegisterDialog() : openLoginDialog()"
        >
          <span>Continue with Email</span>
          <img class="email-icon" src="../../assets/auth/email.png" alt="email-logo" />
        </app-button>
        <app-button class="continue-with-btn" :loader="googleLoader" @click="loginWithGoogle">
          <span>Continue with Google</span>
          <img class="google-icon" src="../../assets/auth/google-logo.png" alt="google-logo" />
        </app-button>
      </div>

      <!-- Divider -->
      <hr />

      <!-- Footer -->
      <p class="terms">
        By continuing you agree to our <a @click="goTo('boring-stuff/terms-of-service')">T&Cs</a> and our
        <a @click="goTo('boring-stuff/privacy-policy')">Privacy Policy.</a> We use your data to offer you a personalised
        experience and to better understand and improve our services.
      </p>
      <div class="error" v-if="loginError">{{ loginError }}</div>
    </div>

    <!-- Connecting accounts -->
    <div v-else class="connect-account-wrapper">
      <h2>Connect your account</h2>
      <p>
        It looks like this email already has an account with us. Enter your password to link the two accounts together.
      </p>
      <div class="connect-account-inputs">
        <app-input class="email-input" :disabled="true" v-model:value="email" id="email_signup">
          <template v-slot:label>Email address</template>
        </app-input>
        <app-input
          class="password-input"
          v-model:value="password"
          :showError="passwordError"
          type="password"
          id="password"
        >
          <template v-slot:label>Password</template>
          <template v-slot:error v-if="passwordError">
            {{ passwordError }}
          </template>
        </app-input>
        <div class="forgot-password">
          <button @click="forgotPassword">Forgot password?</button>
        </div>
      </div>
      <app-button class="connect-account-button" :loader="linkLoader" @click="linkAccount">Link accounts</app-button>
      <a class="connect-account-dont-link" @click="registerWithThirdParty">Don't link accounts</a>
    </div>
  </app-modal>
</template>

<script lang="ts">
import { FirebaseError } from 'firebase/app';
import {
  AuthCredential,
  GoogleAuthProvider,
  deleteUser,
  getAuth,
  linkWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import account from '@/requests/account';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

import methods from '@/utils/methods';

@Options({
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class ModalAuth extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: true,
  };

  private connectAccount = false;
  private email = '';
  private password = '';
  private phoneNumber = null;
  private type = '';
  private accessToken = '';
  private auth = getAuth();
  private authCredential?: AuthCredential | null;
  private googleLoader = false;
  private linkLoader = false;
  private loginError = '';
  private passwordError = '';

  private async loginWithGoogle() {
    this.googleLoader = true;

    await this.auth.signOut();

    const provider = new GoogleAuthProvider();
    provider.addScope('email');

    let result;

    try {
      result = await signInWithPopup(this.auth, provider);
      this.authCredential = GoogleAuthProvider.credentialFromResult(result);
    } catch (e) {
      if (e instanceof Error) {
        console.error(e.message);
      }
      this.googleLoader = false;
      return;
    }

    const { user } = result;
    this.accessToken = await user.getIdToken();

    const email = user?.providerData.find((p) => p.providerId === 'google.com')?.email;
    if (!email) {
      throw new Error('No email provided');
    }

    this.email = email;
    this.type = 'google';

    let preCheckResponse;

    try {
      preCheckResponse = await account.signInPreCheck(this.email, this.type);
    } catch (err) {
      console.error(err);
      this.loginError = 'Something went wrong';
      this.googleLoader = false;
      return;
    }

    if (preCheckResponse.success) {
      // NOTE: Firebase listeners defined in App.vue will automatically update local storage after signing in.
      this.close();
    } else if (!preCheckResponse.success && preCheckResponse.isLinkingAccountsPossible) {
      this.connectAccount = true;
    } else if (!preCheckResponse.success) {
      this.registerWithThirdParty();
    }

    this.googleLoader = false;
  }

  private async linkAccount() {
    this.linkLoader = true;

    try {
      if (this.auth.currentUser) {
        await deleteUser(this.auth.currentUser);
      }
      try {
        await signInWithEmailAndPassword(this.auth, this.email, this.password);
      } catch (err) {
        if (
          err instanceof FirebaseError &&
          (err.code === 'auth/wrong-password' || err.code === 'auth/user-not-found')
        ) {
          this.passwordError = 'Incorrect password.';
        }
      }

      if (!this.auth.currentUser || !this.authCredential) {
        console.error('Current user: ', this.auth.currentUser, 'Auth credential: ', this.authCredential);
        this.passwordError = 'Something went wrong.';
        return;
      }

      await linkWithCredential(this.auth.currentUser, this.authCredential);
      await account.saveUserToFirestore();

      // NOTE: Firebase listeners defined in App.vue will automatically update local storage after signing in.

      this.close();
    } catch (err) {
      console.error(err);
      if (err instanceof FirebaseError) {
        if (err.code === 'auth/wrong-password') {
          this.passwordError = 'Incorrect password.';
        }
      }
    }

    this.linkLoader = false;
  }

  private registerWithThirdParty() {
    this.close();
    this.$store.commit('modals/show', 'verifyPhone');
  }

  private openLoginDialog() {
    this.close();
    this.$store.commit('modals/show', 'login');
  }

  private openRegisterDialog() {
    this.close();
    this.$store.commit('modals/show', 'createAccount');
  }

  private close() {
    this.$store.commit('modals/close');
  }

  private goTo(url: string) {
    this.$router.push(`/${url}`);
  }

  private yupErrMsg(path: string) {
    return methods.yupErrMsg(path);
  }

  private forgotPassword() {
    this.$store.commit('modals/close');
    this.$store.commit('modals/show', 'forgotPassword');
  }
}
</script>

<style lang="scss" scoped>
.auth {
  &-modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;

    h2 {
      font-family: 'Recoleta';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      max-width: 250px;
      text-align: center;
    }

    .forgot-password {
      display: flex;
      margin-bottom: 30px;

      button {
        @include p3;
        text-decoration: underline;
        color: $coal80;
        margin-left: auto;
      }
    }
  }

  &-buttons {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    margin-top: 28px;

    .controller-button {
      span {
        color: $coal100;
      }
    }
  }
}

.continue-with-btn {
  background-color: white;
  border: solid;
  border-width: 1px;
  border-color: #21212140;
  border-radius: 220px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px;
  width: 275px;
  min-height: 50px;

  &:hover {
    background-color: $coal20;
  }

  span {
    color: $coal100;
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    height: 17.5px;
  }

  .google-icon {
    max-width: 25px;
    margin-right: 5px;
  }

  .email-icon {
    max-width: 25px;
    margin-right: 5px;
  }
}

hr {
  margin-top: 25px;
  margin-bottom: 10px;
  width: 150px;
  color: #21212140;
}

.terms {
  @include p3;
  font-size: 14.5px;
  color: $coal100;
  max-width: 420px;
  margin-top: 8px;
  margin-bottom: 15px;
  text-align: justify;

  a {
    @include p2;
    font-size: 14.5px;
    text-decoration: underline;
  }
}

.connect-account {
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 42px;

    h2 {
      max-width: 160px;
      text-align: center;
    }

    p {
      max-width: 280px;
      text-align: center;
    }

    .forgot-password {
      align-self: flex-start;

      button {
        text-decoration: underline;
      }
    }

    @include for-smartphone {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }

  &-inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .email-input {
      margin-bottom: 20px;
    }

    .password-input {
      margin-bottom: 10px;
    }
  }

  &-dont-link {
    color: $coal100;
    font-family:
      Sofia Pro,
      sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    font-weight: 600;
    margin-bottom: 30px;
  }

  &-button {
    max-width: 315px;
  }
}

.error {
  color: red;
  font-weight: bold;
}
</style>
