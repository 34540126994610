<template>
  <div class="ticker-tape-wrapper">
    <div class="ticker-tape first">
      <div class="ticker-tape-item" v-for="(fText, fIndex) in tickerTapeItems" :key="fIndex">
        <h3>{{ fText }}</h3>
        <IconServices class="ticker-tape-delimiter" icon="starAlt" />
      </div>
      <div class="ticker-tape-item" v-for="(sText, sIndex) in tickerTapeItems" :key="sIndex">
        <h3>{{ sText }}</h3>
        <IconServices class="ticker-tape-delimiter" icon="starAlt" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import IconServices from '@/shared/icons/IconServices.vue';

@Options({
  components: {
    IconServices,
  },
})
export default class TickerTape extends Vue {
  private tickerTapeItems: string[] = [
    'WE SUPPORT LOCAL',
    'INDIES ONLY',
    '100% EMISSION-FREE DELIVERY',
    'TRIED AND TASTED',
    'WE SUPPORT LOCAL',
    'INDIES ONLY',
    '100% EMISSION-FREE DELIVERY',
    'TRIED AND TASTED',
    'RIDERS ON LIVING WAGE',
  ];
}
</script>

<style lang="scss" scoped>
.ticker-tape {
  display: flex;
  left: 0;
  top: 0;
  height: 100%;
  animation: ticker-kf 70s linear infinite;
  &-wrapper {
    display: flex;
    overflow: hidden;
    position: relative;
    z-index: 10;
    background-color: $coal100;
    height: 35px;
    width: 100%;
    margin-left: 0;
  }
  &-item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    h3 {
      color: $ham;
      white-space: nowrap;
    }
    svg {
      width: 21px;
      height: 21px;
    }
  }
  &-delimiter {
    margin: 0 9px;
  }
}

@keyframes ticker-kf {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-108rem, 0, 0);
  }
}
</style>
