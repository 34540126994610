<template>
  <div class="step_zero">
    <div class="left_part">
      <video
        id="introduction_video"
        class="custom-cursor-hover"
        @click.prevent="pauseOrResumeVideo"
        @pause="onPause"
        @play="onPlay"
      >
        <source src="../../assets/gtm/introduction.mp4" type="video/mp4" />
        <track kind="captions" label="No captions available" srclang="en" />
        Your browser does not support the video tag or captions.
      </video>
      <img
        id="play_button"
        class="custom-cursor-hover"
        src="../../assets/gtm/play_button.png"
        alt=""
        @click.prevent="pauseOrResumeVideo"
      />
      <Logo class="logo-with-text" text whiteText />
      <app-button square slim white @click="goToNextStep">Continue</app-button>
    </div>
    <div class="right_part">
      <Logo class="logo-with-text" text />
      <h1>Streamlining ordering food to the workplace</h1>
      <div class="step_container">
        <div><img src="../../assets/gtm/video_unlimited.png" alt="" /></div>
        <p>Invite an unlimited number of restaurants, existing or new</p>
      </div>
      <div class="step_container">
        <div><img src="../../assets/gtm/video_calendar.png" alt="" /></div>
        <p>Organise all office catering in one place</p>
      </div>
      <div class="step_container">
        <div><img src="../../assets/gtm/video_forward.png" alt="" /></div>
        <p>Streamline invoices and card payments</p>
      </div>
      <app-button square slim @click="goToNextStep">Continue</app-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import Logo from '@/shared/icons/Logo.vue';

@Options({ components: { Logo } })
export default class GtmStep0 extends Vue {
  private goToNextStep() {
    this.$store.commit('gtm/setStep', 0.5);
  }

  private pauseOrResumeVideo() {
    const el = document.getElementById('introduction_video') as HTMLMediaElement;
    if (el.paused) el.play();
    else el.pause();
  }

  private onPause() {
    const el = document.getElementById('play_button');
    if (el) el.style.display = 'block';
  }

  private onPlay() {
    const el = document.getElementById('play_button');
    if (el) el.style.display = 'none';
  }

  // Prevent scrolling: START
  mounted() {
    document.documentElement.classList.add('no-scroll');
    document.body.classList.add('no-scroll');
  }

  unmounted() {
    document.documentElement.classList.remove('no-scroll');
    document.body.classList.remove('no-scroll');
  }
  // Prevent scrolling: STOP
}
</script>

<style lang="scss" scoped>
.step_zero {
  display: flex;
  flex-direction: row;

  .left_part {
    position: relative;
    background-color: #0c0c0c;
    height: 100vh; // old browsers
    height: 100dvh; // new browsers
    width: 100vw;
    overflow: hidden;

    video {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    .logo-with-text {
      position: absolute;
      z-index: 2;
      left: 20px;
      top: 20px;
    }

    #play_button {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80px;
    }

    button {
      position: absolute;
      z-index: 2;
      height: 50px;
      width: 300px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: calc(max(0px, (50% - 100vw * 9 / 16 / 2) / 2 - 25px));
    }

    @include for-desktop {
      width: 50vw;
      .logo-with-text,
      button {
        display: none;
      }
    }
  }

  .right_part {
    background-color: $ham;
    height: 100vh;
    width: 50vw;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .logo-with-text {
      margin-left: calc(25vw - 200px);
      margin-right: auto;
      margin-bottom: 15px;
      margin-top: 15px;
      transform: translateX(10px) scale(1.2);
    }

    h1 {
      font-size: 37px;
      line-height: 40px;
      width: 400px;
      max-width: 40vw;
      margin-bottom: 30px;
      margin-left: calc(25vw - 200px);
    }

    .step_container {
      background-color: white;
      padding: 10px 15px;
      width: 400px;
      max-width: 40vw;
      min-height: 80px;
      margin-bottom: 30px;
      margin-left: calc(25vw - 200px);
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      border-radius: 20px;
      border: 1px solid rgba(0, 0, 0, 0.2);

      div {
        width: 50px;
        min-width: 50px;
        height: 50px;
        background-color: $ham;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 25px;
        }
      }

      p {
        @include p1;
        font-size: 18px;
        line-height: 22px;
      }
    }

    button {
      width: 300px;
      min-height: 50px;
      height: 50px;
      margin-top: 10px;
      margin-bottom: 15px;
      margin-left: calc(25vw - 150px);
      background-color: black;
      color: white;
    }

    @media (min-height: 575px) {
      justify-content: center;
    }

    @include for-smartphone {
      display: none;
    }
  }
}
</style>
