<template>
  <!-- TODO: Remove if-statement after adding London's map --->
  <div v-if="cityFromUrl === 'cambridge' || cityFromUrl === 'bristol'" class="iframe-wrapper hide-mobile">
    <Logo class="logo-loader" />
    <iframe
      v-if="cityFromUrl === 'cambridge'"
      title="Our independent restaurant partners"
      src="https://my.atlist.com/map/5fad434a-5bcc-4755-aa89-de0b9100e25f?share=true"
      allow="geolocation 'self' https://my.atlist.com"
      width="100%"
      height="350px"
      loading="lazy"
      frameborder="0"
      scrolling="no"
    ></iframe>
    <iframe
      v-else
      title="Our independent restaurant partners"
      src="https://my.atlist.com/map/b89317e8-2fd1-415b-9c66-075930d163cf?share=true"
      allow="geolocation 'self' https://my.atlist.com"
      width="100%"
      height="350px"
      loading="lazy"
      frameborder="0"
      scrolling="no"
    ></iframe>
  </div>
  <!-- TODO: Remove if-statement after adding London's map --->
  <hr v-if="cityFromUrl === 'cambridge' || cityFromUrl === 'bristol'" class="hide-mobile" />
  <RestaurantCards />
</template>

<script lang="ts">
import { useSeoMeta } from '@unhead/vue';
import { Options, Vue } from 'vue-class-component';

import RestaurantCards from '@/components/RestaurantCards.vue';

import Logo from '@/shared/icons/Logo.vue';

@Options({ components: { RestaurantCards, Logo } })
export default class Restaurants extends Vue {
  private isMounted = false;

  created() {
    const cityName = `${this.cityFromUrl.slice(0, 1).toUpperCase()}${this.cityFromUrl.slice(1)}`;
    useSeoMeta({
      title: `Foodstuff | Office Food Delivery in ${cityName}`,
      ogTitle: `Foodstuff | Office Food Delivery in ${cityName}`,
      twitterTitle: `Foodstuff | Office Food Delivery in ${cityName}`,
      description:
        `We are proud to be partnered with 40 of ${cityName}’s coolest independent restaurants. ` +
        'Our restaurant line up ranges from authentic street food to sourdough pizza. ' +
        'Whether you are looking for a light bite or a special treat, ' +
        'Foodstuff has got you covered with its sustainable ' +
        'and ethical delivery service. Order on website or download our app today!',
      ogDescription:
        `We are proud to be partnered with 40 of ${cityName}’s coolest independent restaurants. ` +
        'Our restaurant line up ranges from authentic street food to sourdough pizza. ' +
        'Whether you are looking for a light bite or a special treat, ' +
        'Foodstuff has got you covered with its sustainable ' +
        'and ethical delivery service. Order on website or download our app today!',
      twitterDescription:
        `We are proud to be partnered with 40 of ${cityName}’s coolest independent restaurants. ` +
        'Our restaurant line up ranges from authentic street food to sourdough pizza. ' +
        'Whether you are looking for a light bite or a special treat, ' +
        'Foodstuff has got you covered with its sustainable ' +
        'and ethical delivery service. Order on website or download our app today!',
    });
  }

  private get cityFromUrl() {
    return this.$route.params.city?.toString().toLowerCase();
  }

  mounted() {
    this.isMounted = true;
    try {
      this.enableFreshchat();
    } catch (_) {
      setTimeout(() => {
        if (!this.isMounted) return;
        this.enableFreshchat();
      }, 1000);
    }
  }

  unmounted() {
    this.isMounted = false;
    this.disableFreshchat();
  }

  private enableFreshchat() {
    window.fcWidget?.setTags(['client-bot']);
    window.fcWidget?.setConfig({ headerProperty: { hideChatButton: false } });
  }

  private disableFreshchat() {
    window.fcWidget?.setConfig({ headerProperty: { hideChatButton: true } });
  }
}
</script>

<style lang="scss" scoped>
.iframe-wrapper {
  width: 100%;
  height: 350px;
  position: relative;

  .logo-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2);
  }

  iframe {
    z-index: 1;
    position: absolute;
  }
}

hr {
  margin: 0;
  border: 0.5px black solid;
}

.hide-mobile {
  @include for-smartphone {
    display: none;
  }
}
</style>
