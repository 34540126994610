/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state: StateGroupOrder = {
  groupData: null,
  groupRole: 'Unset',
  participantName: null,
  participantBasketId: null,
  areItemsConfirmed: false,
  participantBasketHistory: [],
};

const mutations = {
  setRole(state: StateGroupOrder, payload: GroupOrderRole) {
    state.groupRole = payload;
  },
  setParticipantName(state: StateGroupOrder, payload: string) {
    state.participantName = payload;
  },
  setParticipantBasketId(state: StateGroupOrder, payload: string) {
    state.participantBasketId = payload;
  },
  addParticipantBasketIdToHistory(state: StateGroupOrder, payload: string) {
    state.participantBasketHistory = [
      ...state.participantBasketHistory.filter((rel) => rel.groupOrderId !== state.groupData!.id),
      { groupOrderId: state.groupData!.id, groupBasketId: payload },
    ];
  },
  itemsConfirmed(state: StateGroupOrder) {
    state.areItemsConfirmed = true;
  },
  update(state: StateGroupOrder, payload: GroupData) {
    state.groupData = payload;
  },
  reset(state: StateGroupOrder) {
    state.groupData = null;
    state.groupRole = 'Unset';
    state.participantName = null;
    state.participantBasketId = null;
    state.areItemsConfirmed = false;
    // NOTE: participantBasketHistory is not erased
  },
};

const actions = {};

const getters = {};

export type GroupOrderRole = 'Unset' | 'Owner' | 'Participant';

export interface GroupData {
  id: string;
  ownerId: number;
  ownerAddress: {
    id: string;
    lat: number;
    lng: number;
    cityName: string;
    postcode: string;
    wholeCity: boolean;
    directions: string;
    streetName: string;
    streetNumber: string;
    flatOrBuilding: string;
    formattedAddress: string;
  };
  groupLink: string;
  restaurantSlug: string;
  orderType: string;
  bestDeliverToTime: string | null;
  worstDeliverToTime: string | null;
  deadline: null | string;
  instructions: null | string;
  active: boolean;
  baskets: GroupOrderBasket[];
  ownerName: string;
  restaurantName: string;
  restaurantLogo: string;
}

export interface GroupOrderToGroupBasketRelation {
  groupOrderId: string;
  groupBasketId: string;
}

export interface StateGroupOrder {
  groupRole: GroupOrderRole;
  participantName: string | null;
  areItemsConfirmed: boolean;
  participantBasketId: string | null;
  groupData: GroupData | null;
  participantBasketHistory: GroupOrderToGroupBasketRelation[];
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
