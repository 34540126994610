<template>
  <div class="main-list-of-filters">
    <div
      v-for="cuisine in cuisines"
      :key="cuisine.id"
      class="cuisine custom-cursor-hover"
      :class="{ active: selectedCuisineIds.includes(cuisine.id) }"
      @click="selectCuisine(cuisine.id)"
    >
      <img :src="cuisine.iconPath" alt="" />
      <p>{{ cuisine.name }}</p>
    </div>
  </div>
  <div class="other-filters">
    <app-button class="filter-button" :class="{ active: selectedDietaryIds.length }" @click="showDietariesDialog">
      <img src="../assets/dietaries.png" alt="" />
      <p v-if="selectedDietaryIds.length">Dietaries [{{ selectedDietaryIds.length }}]</p>
      <p v-else>Dietaries</p>
      <img src="../assets/simple_arrow_bottom.svg" alt="" />
    </app-button>
    <app-button class="filter-button" :class="{ active: selectedPerfectForIds.length }" @click="showPerfectForDialog">
      <img src="../assets/perfect_for.png" alt="" />
      <p v-if="selectedPerfectForIds.length">Perfect for [{{ selectedPerfectForIds.length }}]</p>
      <p v-else>Perfect for</p>
      <img src="../assets/simple_arrow_bottom.svg" alt="" />
    </app-button>
    <app-button class="filter-button" :class="{ active: selectedBudgetIds.length }" @click="showBudgetDialog">
      <img src="../assets/budget.png" alt="" />
      <p v-if="selectedBudgetIds.length">Budget [{{ selectedBudgetIds.length }}]</p>
      <p v-else>Budget</p>
      <img src="../assets/simple_arrow_bottom.svg" alt="" />
    </app-button>
  </div>

  <UnavailableDeliveryBanner />

  <div v-if="bannerMessage" class="info-banner">
    <p>{{ bannerMessage }}</p>
  </div>

  <div id="restaurants" v-if="restaurants.length > 0">
    <div class="restaurants-section" v-for="(restaurantGroup, rGIndex) in groupedRestaurants" :key="rGIndex">
      <div class="banner" v-if="rGIndex !== 0">
        <p>{{ banners[rGIndex - 1] }}</p>
      </div>
      <div class="restaurant-cards">
        <RestaurantCard
          v-for="(restaurant, rIndex) in restaurantGroup"
          :key="rIndex"
          :restaurant="restaurant"
          @click.prevent="goTo(`/menu/${selectedCity.city.toLowerCase()}/${restaurant.slug}`)"
        />
      </div>
    </div>
  </div>

  <div class="no-restaurants" v-if="restaurants.length === 0 && !loading">
    <img src="../assets/glasses-empty.png" alt="glasses" />
    <p>No restaurants.</p>
  </div>

  <div class="loader" v-if="loading">
    <Logo class="logo-loader" />
  </div>
</template>

<script lang="ts">
import { watch } from 'vue';
import { Options, Vue } from 'vue-class-component';

import RestaurantCard from '@/components/Restaurants/RestaurantCard.vue';
import UnavailableDeliveryBanner from '@/components/UnavailableDeliveryBanner.vue';

import account, { AccessibleCities } from '@/requests/account';
import restaurants from '@/requests/restaurants';

import Logo from '@/shared/icons/Logo.vue';

import Banners from '@/utils/banners';

@Options({
  components: {
    RestaurantCard,
    Logo,
    UnavailableDeliveryBanner,
  },
})
export default class RestaurantCards extends Vue {
  declare $refs: {
    restaurants: HTMLDivElement | undefined;
  };
  private unfilteredRestaurants: FullInfoRestaurant[] = [];
  private restaurants = [...this.unfilteredRestaurants];

  private groupedRestaurants: FullInfoRestaurant[][] = [];
  private banners: string[] = [];

  private loading = true;
  private complete = false;

  private page = 0;
  private search = '';
  private bannerMessage = '';

  private cities: AccessibleCities[] = [];
  private selectedCity: AccessibleCities | null = null;

  private cuisines: Filter[] = [];
  private selectedCuisineIds: number[] = [];

  private dietaries: Filter[] = [];
  private selectedDietaryIds: number[] = [];

  private budgets: Filter[] = [];
  private selectedBudgetIds: number[] = [];

  private perfectFors: Filter[] = [];
  private selectedPerfectForIds: number[] = [];

  private selectCuisine(cuisineId: number) {
    if (this.selectedCuisineIds.includes(cuisineId)) {
      this.selectedCuisineIds = this.selectedCuisineIds.filter((n) => n !== cuisineId);
    } else {
      this.selectedCuisineIds.push(cuisineId);
    }
  }

  private goTo(path: string) {
    this.$router.push({ path });
  }

  private filterRestaurants() {
    this.restaurants = this.unfilteredRestaurants.slice(0, (this.page + 1) * 6);
  }

  private groupRestaurants() {
    const groupSize = 6;
    const groupedRestaurants: FullInfoRestaurant[][] = [];
    for (let i = 0; i < this.restaurants.length; i += groupSize) {
      const chunk = this.restaurants.slice(i, i + groupSize);
      groupedRestaurants.push(chunk);
    }
    this.groupedRestaurants = groupedRestaurants;
  }

  private handleScroll() {
    const element: HTMLDivElement | undefined = document.getElementById('restaurants') as HTMLDivElement;
    if (element && element.getBoundingClientRect().bottom < window.innerHeight) {
      if (this.restaurants.length !== this.unfilteredRestaurants.length) {
        this.page += 1;
        this.filterRestaurants();
        this.groupRestaurants();
      }
    }
  }

  get queryParamString() {
    const { lat, lng } = this.$store.state.address;
    let url = `?sortBy=all&cityName=${this.selectedCity?.city}`;

    // Set coordinates, basing on delivery address or - if not provided - chosen city:
    const locationLat: number = lat || this.selectedCity?.lat || 0;
    const locationLng: number = lng || this.selectedCity?.lng || 0;
    url += `&lat=${locationLat}&lng=${locationLng}`;

    // Use wholeCity param if browsing cities:
    if (!lat && !lng) url += `&wholeCity=true`;

    if (this.search) {
      let keyword = this.search;
      if (this.search.indexOf("'") >= 0) {
        keyword = keyword.replace(/'/g, "''");
      }
      url += `&keyword=${keyword}`;
    }

    if (this.$store.state.order.type === 'collection') {
      url += '&collect=true';
    }

    const deliverToTimestamp = this.$store.getters['order/scheduledDeliveryTimestamp'];
    if (deliverToTimestamp) {
      url += `&deliverToTime=${deliverToTimestamp}`;
    }

    const { user } = this.$store.state.service;
    if (user) {
      if (user.customId) url += `&userId=${user.customId}`;
      if (user.id) url += `&firebaseId=${user.id}`;
    }

    const basketRestaurantId = this.$store.state.basket.restaurant?.id;
    if (basketRestaurantId) {
      url += `&basketRestaurantId=${basketRestaurantId}`;
    }

    if (this.selectedCuisineIds.length > 0) {
      url += `&categories=${this.selectedCuisineIds}`;
    }

    if (this.selectedDietaryIds.length > 0) {
      url += `&dietaries=${this.selectedDietaryIds.join(',')}`;
    }

    if (this.selectedPerfectForIds.length > 0) {
      url += `&perfectFors=${this.selectedPerfectForIds.join(',')}`;
    }

    if (this.selectedBudgetIds.length > 0) {
      url += `&budgets=${this.selectedBudgetIds.join(',')}`;
    }

    return url;
  }

  private getRestaurants() {
    if (!this.selectedCity) this.$router.push('/oops');
    if (this.complete) return;

    this.loading = true;
    restaurants
      .getRestaurants(this.queryParamString)
      .then((res: RequestGetRestaurants) => {
        if (res.restaurants.length > 0) {
          this.unfilteredRestaurants = [];
          res.restaurants.forEach((restaurant) => this.unfilteredRestaurants.push(restaurant));
        } else {
          this.complete = true;
        }

        // The following logic is added to load more pages on super big screens
        if (this.restaurants.length !== this.unfilteredRestaurants.length) {
          this.page += 1;
        }
        if (this.restaurants.length !== this.unfilteredRestaurants.length && window.innerHeight > 1500) {
          this.page += 1;
        }
        if (this.restaurants.length !== this.unfilteredRestaurants.length && window.innerHeight > 2000) {
          this.page += 1;
        }
        if (this.restaurants.length !== this.unfilteredRestaurants.length && window.innerHeight > 3000) {
          this.page += 1;
        }

        this.filterRestaurants();
        this.groupRestaurants();
        this.getBanners();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private resetRestaurants() {
    this.complete = false;
    this.unfilteredRestaurants = [];
    this.restaurants = [];
    this.groupedRestaurants = [];
    this.banners = [];
  }

  private getBanners() {
    this.banners = Banners.getAllBannersInRandomOrder();
    while (this.restaurants.length - 6 > this.banners.length * 6) {
      this.banners = [...this.banners, ...this.banners];
    }
  }

  private showDietariesDialog() {
    this.$store.commit('modals/data', {
      title: 'Dietaries',
      eventType: 'select-dietaries',
      variants: this.dietaries,
      selectedVariantIds: this.selectedDietaryIds,
    });
    this.$store.commit('modals/show', 'setFilters');
  }

  private showPerfectForDialog() {
    this.$store.commit('modals/data', {
      title: 'Perfect for',
      eventType: 'select-perfectFors',
      variants: this.perfectFors,
      selectedVariantIds: this.selectedPerfectForIds,
    });
    this.$store.commit('modals/show', 'setFilters');
  }

  private showBudgetDialog() {
    this.$store.commit('modals/data', {
      title: 'Budget',
      eventType: 'select-budgets',
      variants: this.budgets,
      selectedVariantIds: this.selectedBudgetIds,
    });
    this.$store.commit('modals/show', 'setFilters');
  }

  created() {
    watch(
      () => this.$store.state.address.formattedAddress,
      () => {
        const cityFromState = this.$store.state.address.cityName.toLowerCase();
        if (!cityFromState) return;

        const cityFromUrl = this.$route.params.city?.toString().toLowerCase();
        if (cityFromUrl !== cityFromState) {
          this.$router.push(`/restaurants/${cityFromState}`);
        }

        this.selectedCity = this.cities.find((c) => c.city.toLowerCase() === cityFromState) || null;
        this.bannerMessage = this.selectedCity?.msg as string;
        this.resetRestaurants();

        const { cityName, lat, lng } = this.$store.state.address;
        const restaurantsFetched = restaurants.checkAddressAvailability(
          cityName,
          lat && lng ? { lat: lat!, lng: lng! } : undefined,
        );

        restaurantsFetched.then((res) => {
          if (!res.available && res.availableForCollection) {
            this.$store.commit('order/type', 'collection');
            this.getRestaurants();
          } else if (!res.available && !this.bannerMessage) {
            this.$store.commit('modals/show', 'missingAreaGtm');
          } else {
            this.getRestaurants();
          }
        });
      },
    );

    watch(
      () => this.$store.state.service.update,
      (value: any) => {
        switch (value?.type) {
          case 'searching':
            this.search = value?.data;
            this.resetRestaurants();
            this.getRestaurants();
            this.$store.commit('service/update', '');
            break;
          case 'select-dietaries':
            this.selectedDietaryIds = (value.selected as Filter[]).map((v) => v.id);
            break;
          case 'select-perfectFors':
            this.selectedPerfectForIds = (value.selected as Filter[]).map((v) => v.id);
            break;
          case 'select-budgets':
            this.selectedBudgetIds = (value.selected as Filter[]).map((v) => v.id);
            break;
          default:
            break;
        }
      },
    );

    watch(
      () => [
        this.$store.state.order.type,
        this.$store.state.order.scheduledDeliveryInterval,
        this.$store.state.order.scheduledDeliveryDate,
        this.selectedCuisineIds.length,
        this.selectedDietaryIds.length,
        this.selectedBudgetIds.length,
        this.selectedPerfectForIds.length,
      ],
      () => {
        this.resetRestaurants();
        this.getRestaurants();
      },
    );

    if (!this.$store.state.address.cityName && !this.$route.params.city) {
      this.$router.push('/oops');
    } else {
      (async () => {
        try {
          const cityFromUrl = this.$route.params.city?.toString().toLowerCase();
          const cityFromState = this.$store.state.address.cityName?.toLowerCase();

          this.cities = await account.accessibleCities();
          this.selectedCity =
            this.cities.find((c) => c.city.toLowerCase() === cityFromState) ||
            this.cities.find((c) => c.city.toLowerCase() === cityFromUrl) ||
            null;
          if (!this.selectedCity) {
            this.$router.push('/');
            return;
          }

          this.bannerMessage = this.selectedCity?.msg as string;

          const filters = await restaurants.getFilters();
          this.cuisines = filters.categories;
          this.dietaries = filters.dietaries;
          this.budgets = filters.budgets;
          this.perfectFors = filters.perfectFors;

          // Check if there are available restaurants near user's address or near center of city:
          const res =
            this.$store.state.address.lat && this.$store.state.address.lng
              ? await restaurants.checkAddressAvailability(this.$store.state.address.cityName, {
                  lat: this.$store.state.address.lat,
                  lng: this.$store.state.address.lng,
                })
              : await restaurants.checkAddressAvailability(this.selectedCity.city, {
                  lat: this.selectedCity.lat,
                  lng: this.selectedCity.lng,
                });

          if (res) {
            if (!res.available && res.availableForCollection) {
              this.$store.commit('order/type', 'collection');
              this.getRestaurants();
            } else if (!res.available && !this.bannerMessage) {
              this.$store.commit('modals/show', 'missingAreaGtm');
            } else {
              this.getRestaurants();
            }
          }
        } catch (err) {
          this.$store.commit('modals/data', err);
          this.$store.commit('modals/show', 'error');
        }
      })();
    }
  }

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  }

  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style lang="scss" scoped>
.main-list-of-filters {
  display: flex;
  flex-direction: row;
  gap: 15px;
  overflow: scroll;

  width: 1355px;
  max-width: 100%;

  .cuisine {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    min-width: 90px;
    height: 100px;
    border-radius: 20px;
    margin-top: 15px;
    margin-bottom: 15px;

    &.active {
      background-color: white;
      border: 1px solid black;
    }

    p {
      @include p3;
    }

    img {
      height: 37px;
      width: 37px;
    }
  }
}

.other-filters {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 5px;
  overflow-x: scroll;
  width: calc(100% - 32px);
  max-width: 1212px;

  .filter-button {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7.5px;
    min-height: 40px;
    height: 40px;
    width: unset;

    min-width: 140px;
    background-color: $white;
    &:hover {
      background-color: $snow;
    }

    &.active {
      min-width: 160px;
      background-color: $ham;
      &:hover {
        background-color: $roastHam;
      }
    }

    p {
      height: 18px;
    }

    img:first-of-type {
      height: 18px;
    }
  }

  @include for-custom(816) {
    gap: 10px;

    .filter-button {
      min-width: unset;
      min-height: 35px;
      height: 35px;
      gap: 5px;

      &.active {
        min-width: unset;
      }

      p {
        font-size: 14px;
        line-height: 14px;
        height: 14px;
      }

      img:first-of-type {
        display: none;
      }
    }
  }
}

#restaurants {
  padding-bottom: 25px;
  width: 100%;
}

.restaurants-section {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.restaurant-cards {
  display: grid;
  gap: 25px 20px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  justify-items: center;

  width: calc(100% - 32px);
  max-width: 1212px;
  margin-left: auto;
  margin-right: auto;

  @include for-custom(1216) {
    grid-template-columns: 1fr 1fr;
  }

  @include for-custom(816) {
    grid-template-columns: 1fr;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-restaurants {
  margin: 4rem 0;
  display: flex;
  flex-direction: column;
  p {
    margin-top: 2rem;
    font-size: 2rem;
    font-family: Recoleta, sans-serif;
    font-weight: 600;
  }

  img {
    width: 125px;
    margin-left: auto;
    margin-right: auto;
  }
}

.info-banner {
  background-color: $beetroot80;
  border-style: dashed;
  border-radius: 10px;
  border-width: 1px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  padding: 10px;

  width: calc(100% - 32px);
  max-width: 1212px;

  p {
    font-size: 16px;
    font-family:
      Sofia Pro,
      sans-serif;
    font-weight: 500;
    color: $coal100;
  }
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(255, 202, 205, 0.2);
  border-radius: 10px;
  padding: 20px;

  p {
    text-align: center;
    font-family:
      Sofia Pro,
      sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  @include for-smartphone {
    padding: 20px;
    p {
      font-size: 14px;
    }
  }
}

.round {
  border-radius: 50%;
}
</style>
