import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconArrow = _resolveComponent("IconArrow")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_BasketComponent = _resolveComponent("BasketComponent")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "basket-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _createVNode(_component_app_button, {
        class: "basket-modal-close",
        mini: "",
        white: "",
        onClick: _withModifiers(_ctx.close, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconArrow, { class: "services-icon" })
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_BasketComponent, {
        restaurant: _ctx.data,
        orderSettingsDisabled: "",
        isMobile: ""
      }, null, 8, ["restaurant"])
    ]),
    _: 1
  }, 16))
}