<template>
  <!-- eslint-disable -->
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <path
      d="M469.333,85.333h-42.667h-64v-64C362.667,9.557,353.131,0,341.333,0H170.667c-11.797,0-21.333,9.557-21.333,21.333v64h-64
			H42.667c-11.797,0-21.333,9.557-21.333,21.333S30.869,128,42.667,128H64v320c0,35.285,28.715,64,64,64h256
			c35.285,0,64-28.715,64-64V128h21.333c11.797,0,21.333-9.557,21.333-21.333S481.131,85.333,469.333,85.333z M192,42.667h128
			v42.667H192V42.667z M405.333,448c0,11.755-9.557,21.333-21.333,21.333H128c-11.776,0-21.333-9.579-21.333-21.333V128h64h170.667
			h64V448z"
    />

    <path
      d="M192,170.667c-11.797,0-21.333,9.557-21.333,21.333v192c0,11.776,9.536,21.333,21.333,21.333s21.333-9.557,21.333-21.333
			V192C213.333,180.224,203.797,170.667,192,170.667z"
    />

    <path
      d="M320,170.667c-11.797,0-21.333,9.557-21.333,21.333v192c0,11.776,9.536,21.333,21.333,21.333s21.333-9.557,21.333-21.333
			V192C341.333,180.224,331.797,170.667,320,170.667z"
    />
  </svg>

  <!-- eslint-enable -->
</template>
