import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "checkout-info" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "address-disabled" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "address-disabled" }
const _hoisted_6 = {
  key: 3,
  class: "payment-header"
}
const _hoisted_7 = { class: "tips-wrapper" }
const _hoisted_8 = { class: "tip-buttons" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "tip-percent" }
const _hoisted_11 = { class: "tip-price" }
const _hoisted_12 = {
  key: 0,
  class: "tip-percent"
}
const _hoisted_13 = {
  class: "form-scoring total",
  style: {"margin-bottom":"5px"}
}
const _hoisted_14 = { class: "form-scoring-price" }
const _hoisted_15 = { class: "c-ham" }
const _hoisted_16 = { class: "form-scoring total" }
const _hoisted_17 = { class: "c-ham" }
const _hoisted_18 = { class: "form-scoring-price" }
const _hoisted_19 = { class: "c-ham" }
const _hoisted_20 = {
  key: 0,
  class: "invoice-subtitle"
}
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { key: 2 }
const _hoisted_24 = { key: 3 }
const _hoisted_25 = { key: 4 }
const _hoisted_26 = { key: 5 }
const _hoisted_27 = { key: 6 }
const _hoisted_28 = { class: "form-scoring total" }
const _hoisted_29 = { class: "form-scoring-price" }
const _hoisted_30 = {
  key: 0,
  class: "c-ham"
}
const _hoisted_31 = {
  key: 1,
  class: "c-ham"
}
const _hoisted_32 = {
  key: 0,
  class: "invoice-subtitle"
}
const _hoisted_33 = { key: 0 }
const _hoisted_34 = { key: 1 }
const _hoisted_35 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_textarea = _resolveComponent("app-textarea")!
  const _component_app_text_accordion = _resolveComponent("app-text-accordion")!
  const _component_PaymentMethod = _resolveComponent("PaymentMethod")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_FormScoring = _resolveComponent("FormScoring")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.type === 'delivery')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[9] || (_cache[9] = _createElementVNode("h2", null, "Deliver to", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_input, {
              id: "address",
              value: _ctx.formattedAddress
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_IconServices, {
                  class: "services-icon",
                  icon: "location"
                })
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _cache[10] || (_cache[10] = _createElementVNode("h2", null, "Delivery options", -1)),
          _createVNode(_component_app_input, {
            value: _ctx.additionalAddressDetails,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.additionalAddressDetails) = $event)),
            placeholder: "i.e Flat 310, Bailey House",
            id: "additionalDetails",
            maxlength: "99",
            disabled: _ctx.amendableData
          }, {
            label: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode(" Flat number/building name ")
            ])),
            _: 1
          }, 8, ["value", "disabled"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _cache[11] || (_cache[11] = _createElementVNode("h2", null, "Collection address", -1)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_app_input, {
              id: "address",
              value: _ctx.getRestaurantAddress
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_IconServices, {
                  class: "services-icon",
                  icon: "location"
                })
              ]),
              _: 1
            }, 8, ["value"])
          ])
        ])),
    (_ctx.type === 'delivery')
      ? (_openBlock(), _createBlock(_component_app_text_accordion, {
          key: 2,
          title: "Add instructions for your rider"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_app_textarea, {
              value: _ctx.notesDelivery,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.notesDelivery) = $event)),
              fullWidth: "",
              style: {"margin-top":"10px"}
            }, null, 8, ["value"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_app_text_accordion, { title: "Add kitchen notes" }, {
      default: _withCtx(() => [
        _createVNode(_component_app_textarea, {
          value: _ctx.notesKitchen,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.notesKitchen) = $event)),
          fullWidth: "",
          style: {"margin-top":"10px"}
        }, null, 8, ["value"])
      ]),
      _: 1
    }),
    (!_ctx.amendableData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _cache[12] || (_cache[12] = _createElementVNode("h2", { class: "title-payment-method" }, "Payment method", -1)),
          (_ctx.paymentMethods.length)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "td-u change-or-cancel-button",
                onClick: _cache[3] || (_cache[3] = 
          () => {
            _ctx.change = !_ctx.change;
            _ctx.add = !_ctx.add;
            if (_ctx.tour.isActive()) _ctx.completeTour();
          }
        )
              }, _toDisplayString(_ctx.change ? 'Cancel' : 'Change'), 1))
            : (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "td-u add-or-cancel-button",
                onClick: _cache[4] || (_cache[4] = 
          () => {
            _ctx.add = !_ctx.add;
          }
        )
              }, _toDisplayString(_ctx.add ? 'Cancel' : 'Add credit card'), 1))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.amendableData)
      ? (_openBlock(), _createBlock(_component_PaymentMethod, {
          key: 4,
          onSelected: _ctx.selectedPaymentMethod,
          paymentMethods: _ctx.paymentMethods,
          change: _ctx.change,
          add: _ctx.add,
          closeWhenPayment: _ctx.closeWhenPayment
        }, null, 8, ["onSelected", "paymentMethods", "change", "add", "closeWhenPayment"]))
      : _createCommentVNode("", true),
    (_ctx.paymentMethod === 'invoice')
      ? (_openBlock(), _createBlock(_component_app_text_accordion, {
          key: 5,
          title: "Add an invoice note",
          class: "invoice-note"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              value: _ctx.invoiceNote,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.invoiceNote) = $event)),
              class: "animated slideOutDown",
              onBlur: _cache[6] || (_cache[6] = () => this.$emit('blur'))
            }, null, 8, ["value"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_FormScoring, null, _createSlots({ _: 2 }, [
      (_ctx.type === 'delivery' && !_ctx.amendableData)
        ? {
            name: "body",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _cache[14] || (_cache[14] = _createElementVNode("h3", { class: "ta-c c-white" }, "Tip the Foodstuff team?", -1)),
                _createElementVNode("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tips, (t) => {
                    return (_openBlock(), _createElementBlock("button", {
                      class: _normalizeClass(["tip-btn", t === _ctx.selectedTipPercentage ? 'tip-btn-active' : '']),
                      onClick: () => _ctx.handleTipClick(t),
                      key: t
                    }, [
                      _createElementVNode("p", _hoisted_10, _toDisplayString(t) + "%", 1),
                      _createElementVNode("p", _hoisted_11, "£" + _toDisplayString(((_ctx.getCartPrice * t) / 100).toFixed(2)), 1)
                    ], 10, _hoisted_9))
                  }), 128)),
                  _createElementVNode("button", {
                    class: _normalizeClass(["tip-btn tip-btn-custom", _ctx.isCustomTipSelected ? 'tip-btn-active' : '']),
                    onClick: _cache[7] || (_cache[7] = () => _ctx.handleCustomTipClick())
                  }, [
                    (_ctx.isCustomTipSelected)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_12, "£" + _toDisplayString(_ctx.tip), 1))
                      : _createCommentVNode("", true),
                    _cache[13] || (_cache[13] = _createElementVNode("p", { class: "tip-price" }, "Custom", -1))
                  ], 2)
                ])
              ])
            ]),
            key: "0"
          }
        : undefined,
      (_ctx.amendableData)
        ? {
            name: "footer",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, [
                _cache[15] || (_cache[15] = _createElementVNode("h3", { class: "c-ham" }, "Updated total", -1)),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("h3", _hoisted_15, "£" + _toDisplayString(_ctx.getTotalPrice), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("h3", _hoisted_17, _toDisplayString(_ctx.getTotalPriceDifference >= 0 ? 'Amount to pay' : "We'll refund"), 1),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("h3", _hoisted_19, _toDisplayString((_ctx.getTotalPriceDifference > 0 ? _ctx.getTotalPriceDifference : -_ctx.getTotalPriceDifference).toFixed(2)), 1)
                ])
              ]),
              (_ctx.paymentMethod === 'invoice')
                ? (_openBlock(), _createElementBlock("h3", _hoisted_20, " You’ll receive updated invoice after delivery "))
                : _createCommentVNode("", true),
              _cache[16] || (_cache[16] = _createElementVNode("div", { style: {"height":"15px"} }, null, -1)),
              _createVNode(_component_app_button, {
                onClick: _ctx.amendOrder,
                style: {"max-width":"none"},
                loader: _ctx.loading,
                disabled: _ctx.getTotalPriceDifference > 0 && !_ctx.paymentMethod
              }, {
                default: _withCtx(() => [
                  (_ctx.amendableData.paymentMethod === 'invoice')
                    ? (_openBlock(), _createElementBlock("strong", _hoisted_21, "Update order via invoice"))
                    : (_ctx.getTotalPriceDifference === 0)
                      ? (_openBlock(), _createElementBlock("strong", _hoisted_22, "Update order"))
                      : (_ctx.getTotalPriceDifference < 0)
                        ? (_openBlock(), _createElementBlock("strong", _hoisted_23, "Request refund and update order"))
                        : (_ctx.amendableData.paymentMethod === 'androidpay')
                          ? (_openBlock(), _createElementBlock("strong", _hoisted_24, "You can't use Google Pay here"))
                          : (_ctx.amendableData.paymentMethod === 'applepay')
                            ? (_openBlock(), _createElementBlock("strong", _hoisted_25, "You can't use Apple Pay here"))
                            : (!_ctx.paymentMethod)
                              ? (_openBlock(), _createElementBlock("strong", _hoisted_26, "No payment method"))
                              : (_openBlock(), _createElementBlock("strong", _hoisted_27, "Pay and update order"))
                ]),
                _: 1
              }, 8, ["onClick", "loader", "disabled"])
            ]),
            key: "1"
          }
        : {
            name: "footer",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_28, [
                _cache[17] || (_cache[17] = _createElementVNode("h3", { class: "c-ham" }, "Total", -1)),
                _createElementVNode("div", _hoisted_29, [
                  (this.paymentMethod !== 'invoice')
                    ? (_openBlock(), _createElementBlock("h3", _hoisted_30, "£" + _toDisplayString(_ctx.getTotalPrice), 1))
                    : _createCommentVNode("", true),
                  (this.paymentMethod === 'invoice')
                    ? (_openBlock(), _createElementBlock("h3", _hoisted_31, "£0"))
                    : _createCommentVNode("", true)
                ])
              ]),
              (_ctx.paymentMethod === 'invoice')
                ? (_openBlock(), _createElementBlock("h3", _hoisted_32, "You’ll receive your invoice after delivery"))
                : _createCommentVNode("", true),
              _cache[18] || (_cache[18] = _createElementVNode("div", { style: {"height":"15px"} }, null, -1)),
              _createVNode(_component_app_button, {
                id: "confirm_and_pay",
                onClick: _ctx.payForOrder,
                style: {"max-width":"none"},
                disabled: _ctx.getTotalPrice === '0.00' || _ctx.restaurant.isClosed || _ctx.restaurant.isTemporarilyClose,
                loader: _ctx.loading
              }, {
                default: _withCtx(() => [
                  (_ctx.restaurant.isTemporarilyClosed)
                    ? (_openBlock(), _createElementBlock("strong", _hoisted_33, "Back soon!"))
                    : (_ctx.restaurant.isClosed)
                      ? (_openBlock(), _createElementBlock("strong", _hoisted_34, "Restaurant closed"))
                      : (_openBlock(), _createElementBlock("strong", _hoisted_35, "Place your order"))
                ]),
                _: 1
              }, 8, ["onClick", "disabled", "loader"])
            ]),
            key: "2"
          }
    ]), 1024)
  ]))
}