import { SCHEDULED_DELIVERY_INTERVALS } from '@/constants/delivery-intervals';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state: StateTimePicker = {
  intervals: SCHEDULED_DELIVERY_INTERVALS,
  scheduledDays: 2,
};

const mutations = {
  set(state: StateTimePicker, payload: StateTimePicker) {
    state.intervals = payload.intervals;
    state.scheduledDays = payload.scheduledDays;
  },
};

const actions = {};

const getters = {};

export interface StateTimePicker {
  intervals: string[];
  scheduledDays: number;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
