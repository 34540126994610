import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/landing/main_list_of_logos.png'
import _imports_1 from '../assets/landing/variants/corporate-event-catering-header.png'
import _imports_2 from '../assets/landing/restaurant_cards/emmeline_logo.png'
import _imports_3 from '../assets/landing/restaurant_cards/ceylon_and_beyond_logo.png'
import _imports_4 from '../assets/landing/restaurant_cards/fennel_and_twine_logo.png'
import _imports_5 from '../assets/landing/restaurant_cards/lauras_pizzas_logo.png'
import _imports_6 from '../assets/landing/restaurant_cards/oscars_ld_pizza_logo.png'
import _imports_7 from '../assets/landing/restaurant_cards/tawa_bites_logo.png'
import _imports_8 from '../assets/landing/restaurant_cards/cambridge_dining_co_logo.png'
import _imports_9 from '../assets/landing/restaurant_cards/boxed_events_logo.png'
import _imports_10 from '../assets/landing/carousel_left.png'
import _imports_11 from '../assets/landing/carousel_right.png'
import _imports_12 from '../assets/landing/variants/corporate-event-catering-divider.png'
import _imports_13 from '../assets/landing/divider_list_of_logos.png'
import _imports_14 from '../assets/landing/divider_list_of_logos_mobile.png'
import _imports_15 from '../assets/order_tracking/message.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "header_section" }
const _hoisted_3 = { class: "header_section_body" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "order_buttons" }
const _hoisted_6 = { class: "catering_options_section" }
const _hoisted_7 = { class: "sub_title" }
const _hoisted_8 = { class: "divider_contact" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_TickerTape = _resolveComponent("TickerTape")!
  const _component_WeSupportLocal = _resolveComponent("WeSupportLocal")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Slide = _resolveComponent("Slide")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_ThePerfectFood = _resolveComponent("ThePerfectFood")!
  const _component_BuiltForEase = _resolveComponent("BuiltForEase")!
  const _component_SustainableAndEthical = _resolveComponent("SustainableAndEthical")!
  const _component_HowItWorks = _resolveComponent("HowItWorks")!
  const _component_EverybodyWins = _resolveComponent("EverybodyWins")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, "Corporate Event Catering in " + _toDisplayString(_ctx.cityName), 1),
        _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "sub_title" }, "• Transform your corporate events into truly memorable experiences", -1)),
        _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "sub_title" }, "• Impress clients and engage employees with our specialised corporate catering", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "sub_title" }, "• Whatever the event, we’ll help make it happen", -1)),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_app_button, {
            slim: "",
            onClick: _ctx.orderNow
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconServices, { icon: "upload" }),
              _cache[1] || (_cache[1] = _createElementVNode("p", null, "Show me food", -1))
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_app_button, {
            slim: "",
            white: "",
            onClick: _ctx.goToEnquiryPage
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconServices, { icon: "gift" }),
              _cache[2] || (_cache[2] = _createElementVNode("p", null, "Plan something bigger", -1))
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _createElementVNode("p", {
          class: "how-does-it-work-question custom-cursor-hover",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollToHowItWorks && _ctx.scrollToHowItWorks(...args)))
        }, "How does it work?"),
        _cache[6] || (_cache[6] = _createElementVNode("p", { class: "quote" }, [
          _createTextVNode(" “"),
          _createElementVNode("b", null, "Thank you so much for your help in delivering another successful event with us. The desserts in particular went down very well!"),
          _createTextVNode("” - Adder Technology ")
        ], -1)),
        _cache[7] || (_cache[7] = _createElementVNode("img", {
          id: "main_list_of_logos",
          src: _imports_0,
          alt: ""
        }, null, -1))
      ]),
      _cache[8] || (_cache[8] = _createElementVNode("img", {
        class: "header_image",
        src: _imports_1,
        alt: ""
      }, null, -1))
    ]),
    _createVNode(_component_TickerTape),
    _createElementVNode("div", _hoisted_6, [
      _cache[20] || (_cache[20] = _createElementVNode("h1", { class: "section_title" }, "Popular event catering options", -1)),
      _createElementVNode("h2", _hoisted_7, [
        _cache[9] || (_cache[9] = _createTextVNode(" Flexible, customisable menus to fit your prefences ")),
        _createVNode(_component_WeSupportLocal, {
          color: _ctx.ham,
          width: 100,
          height: 100
        }, null, 8, ["color"])
      ]),
      _createVNode(_component_Carousel, {
        class: "occasions_carousel",
        breakpoints: _ctx.breakpoints,
        "wrap-around": true
      }, {
        addons: _withCtx(() => [
          _createVNode(_component_Navigation, null, {
            prev: _withCtx(() => _cache[18] || (_cache[18] = [
              _createElementVNode("img", {
                class: "carousel_nav_arrow",
                src: _imports_10,
                alt: ""
              }, null, -1)
            ])),
            next: _withCtx(() => _cache[19] || (_cache[19] = [
              _createElementVNode("img", {
                class: "carousel_nav_arrow",
                src: _imports_11,
                alt: ""
              }, null, -1)
            ])),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          (_ctx.cityName === 'Bristol')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "emmeline" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Corporate catering",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createElementVNode("div", { class: "card_image emmeline" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_2,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Emmeline"),
                        _createElementVNode("p", null, "BANG tidy grilled cheese!"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Grilled Cheese"),
                          _createElementVNode("p", null, "Fresh juices")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Bristol')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "ceylon_and_beyond" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Corporate catering",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createElementVNode("div", { class: "card_image ceylon_and_beyond" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_3,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Ceylon & Beyond"),
                        _createElementVNode("p", null, "Sri-Lankan Street food"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Sri-Lankan"),
                          _createElementVNode("p", null, "Finger food")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Bristol')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "fennel_and_twine" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Corporate catering",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createElementVNode("div", { class: "card_image fennel_and_twine" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_4,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Fennel & Twine"),
                        _createElementVNode("p", null, "High-quality, seasonal menus"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Perfect for any event"),
                          _createElementVNode("p", null, "Flexible menus")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Bristol')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "lauras_pizzas" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Corporate catering",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createElementVNode("div", { class: "card_image lauras_pizzas" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_5,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Laura's Pizzas"),
                        _createElementVNode("p", null, "True neapolitan pizza"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Pizzas"),
                          _createElementVNode("p", null, "Perfect for big groups")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Cambridge')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "oscars_ld_pizza" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Corporate catering",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createElementVNode("div", { class: "card_image oscars_ld_pizza" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_6,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Oscars LD Pizza"),
                        _createElementVNode("p", null, "\"Now that's local!\" - My Dad"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Sourdough Pizza"),
                          _createElementVNode("p", null, "Vegan options")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Cambridge')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "tawa_bites" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Corporate catering",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createElementVNode("div", { class: "card_image tawa_bites" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_7,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Tawa Bites"),
                        _createElementVNode("p", null, "Indian street food"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Indian"),
                          _createElementVNode("p", null, "Rice bowls, wraps & snacks")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Cambridge')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "cambridge_dining_co" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Corporate catering",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[16] || (_cache[16] = [
                      _createElementVNode("div", { class: "card_image cambridge_dining_co" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_8,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Cambridge Dining Co"),
                        _createElementVNode("p", null, "Innovative, creative catering"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Great for big groups"),
                          _createElementVNode("p", null, "Flexible menus")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Cambridge')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "boxed_events" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Corporate catering",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[17] || (_cache[17] = [
                      _createElementVNode("div", { class: "card_image boxed_events" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_9,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Boxed Events"),
                        _createElementVNode("p", null, "Artisan catering fit for any occasion"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Flexible, customisable menus")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["breakpoints"])
    ]),
    _cache[24] || (_cache[24] = _createElementVNode("img", {
      class: "divider_image",
      src: _imports_12,
      alt: ""
    }, null, -1)),
    _createVNode(_component_ThePerfectFood, {
      title: "Corporate catering for all office occasions in one place",
      subtitle: `With Foodstuff all of your corporate catering can be done in one place. Whether you're celebrating or you're looking for something healthy, we deliver for best eateries in ${_ctx.cityName} with a variety big enough to keep the whole office happy. Corporate catering has never been made easier.`,
      onOrderNowClick: _ctx.orderNow,
      onPlanSomethingBiggerClick: _ctx.goToEnquiryPage
    }, null, 8, ["subtitle", "onOrderNowClick", "onPlanSomethingBiggerClick"]),
    _createVNode(_component_BuiltForEase, { onHereClick: _ctx.openFreshchat }, null, 8, ["onHereClick"]),
    _cache[25] || (_cache[25] = _createElementVNode("div", { class: "divider_list_of_logos" }, [
      _createElementVNode("img", {
        class: "hide_mobile",
        src: _imports_13,
        alt: ""
      }),
      _createElementVNode("img", {
        class: "hide_desktop",
        src: _imports_14,
        alt: ""
      })
    ], -1)),
    _createVNode(_component_SustainableAndEthical),
    _createVNode(_component_HowItWorks, { title: "How our Corporate Catering works" }, {
      "pick-from": _withCtx(() => _cache[21] || (_cache[21] = [
        _createElementVNode("p", null, [
          _createTextVNode("Browse our list of "),
          _createElementVNode("b", null, "handpicked"),
          _createTextVNode(" corporate catering suppliers in Cambs/Bristol")
        ], -1)
      ])),
      _: 1
    }),
    _createElementVNode("div", _hoisted_8, [
      _cache[23] || (_cache[23] = _createElementVNode("p", null, "Got a few questions? Give our team a shout", -1)),
      _createVNode(_component_app_button, {
        slim: "",
        white: "",
        onClick: _ctx.openFreshchat
      }, {
        default: _withCtx(() => _cache[22] || (_cache[22] = [
          _createElementVNode("img", {
            src: _imports_15,
            alt: ""
          }, null, -1),
          _createElementVNode("p", null, "Chat to the team", -1)
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_EverybodyWins)
  ]))
}