<template>
  <app-modal class="error-modal" v-bind="options">
    <div v-if="data" class="modal">
      <h2 class="modal-title">Enter amount</h2>
      <CurrencyInput
        id="tip"
        v-model="tipAmount"
        :options="{
          currency: 'GBP',
          precision: 2,
          hideCurrencySymbolOnFocus: false,
          locale: 'en-GB',
        }"
        showError
      >
        <template v-slot:error v-if="yupErrMsg('tip')">
          {{ yupErrMsg('tip') }}
        </template>
      </CurrencyInput>

      <p class="modal-info">Basket value: £{{ data.basketValue }}</p>
      <p class="modal-info">({{ ((tipAmount / data.basketValue) * 100).toFixed(2) }}% of your basket)</p>

      <div class="modal-actions">
        <app-button @click="submit()">
          <strong>Submit</strong>
        </app-button>
      </div>
    </div>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import CurrencyInput from '@/components/CurrencyInput.vue';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

import methods from '@/utils/methods';

@Options({
  components: {
    CurrencyInput,
  },
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class CustomTip extends Vue {
  public tipAmount = 0;

  private options: ModalInterface = {
    slim: true,
    close: true,
  };

  created() {
    this.tipAmount = this.$store.state.modals.data.tipAmount ?? 0;
  }

  private yupErrMsg(path: string) {
    return methods.yupErrMsg(path);
  }

  private submit() {
    this.$store.commit('yup/clear');
    if (this.tipAmount < 0) {
      this.$store.commit('yup/errors', { path: 'tip', msg: 'Value cannot be negative' });
      this.$store.commit('yup/toggleShowError', true);
      return;
    }
    this.$store.commit('basket/tip', this.tipAmount);
    this.$store.commit('modals/close');
  }

  unmounted() {
    this.$store.commit('yup/clear');
  }
}
</script>

<style lang="scss" scoped>
.modal {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-title {
  text-align: center;
}

.modal-info {
  text-align: center;
  margin-top: 0 !important;
}

.modal-actions {
  margin-top: 15px;
  width: 100%;
}

.currency-input-wrapper {
  width: 180px !important;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
