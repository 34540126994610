import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "basket-item-wrapper" }
const _hoisted_2 = { class: "basket-item-info" }
const _hoisted_3 = { class: "basket-item-title" }
const _hoisted_4 = {
  key: 0,
  class: "basket-item-extras"
}
const _hoisted_5 = { class: "basket-item-price" }
const _hoisted_6 = {
  key: 1,
  class: "basket-item-price"
}
const _hoisted_7 = { class: "basket-item-edit-count-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_count = _resolveComponent("app-count")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.dish.name), 1),
      (_ctx.dish.modificators.length > 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.dishExtras), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", null, "£" + _toDisplayString(_ctx.dishPrice), 1)
      ]),
      (_ctx.dish.note)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("p", null, "\"" + _toDisplayString(_ctx.dish.note) + "\"", 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("p", null, "£" + _toDisplayString(_ctx.dishTotalPrice), 1),
      (_ctx.edit)
        ? (_openBlock(), _createBlock(_component_app_count, {
            key: 0,
            amount: _ctx.dish.amount,
            setAmount: (newAmount) => _ctx.setCount(newAmount, _ctx.indexDish)
          }, null, 8, ["amount", "setAmount"]))
        : _createCommentVNode("", true),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "empty" }, null, -1))
    ])
  ]))
}