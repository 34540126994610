<template>
  <div class="controller-count">
    <app-button
      @click="() => setAmount(amount - 1)"
      :class="{ 'count-mini': mini }"
      :disabled="minusDisabled"
      color="ham"
      mini
    >
      <IconServices class="services-icon" icon="minus" />
    </app-button>

    <h3 v-if="mini">{{ amount }}</h3>
    <h2 v-else>{{ amount }}</h2>

    <app-button
      @click="() => setAmount(amount + 1)"
      :class="{ 'count-mini': mini }"
      :disabled="plusDisabled"
      color="ham"
      mini
    >
      <IconServices class="services-icon" icon="add" />
    </app-button>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import IconServices from '@/shared/icons/IconServices.vue';

@Options({
  components: {
    IconServices,
  },
  props: {
    amount: Number,
    setAmount: Function,
    minusDisabled: Boolean,
    plusDisabled: Boolean,
    mini: Boolean,
  },
})
export default class AppControllerCount extends Vue {}
</script>

<style lang="scss">
.controller-count {
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    margin: 0 15px;
  }
  h3 {
    margin: 0 7px;
  }
  .controller-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    &.count-mini {
      min-height: 25px;
      max-height: 25px;
      max-width: 25px;
      min-width: 25px;
      padding: 7px;
    }
    .services-icon {
      position: relative;
    }
  }
}
</style>
