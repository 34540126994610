<template>
  <app-modal class="delete-card-modal" v-bind="options">
    <h2>Delete payment method?</h2>
    <p>Are you sure you want to delete this payment method?</p>
    <div class="delete-card-modal-button">
      <app-button @click.prevent="close">No</app-button>
      <app-button @click.prevent="yes">Yes</app-button>
    </div>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import orders from '@/requests/orders';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

@Options({})
export default class AppModalDeleteCard extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: true,
  };

  private yes() {
    orders.deleteCard(this.$store.state.modals.data).then(() => {
      this.$store.commit('service/update', 'Payment Method');
      this.close();
    });
  }

  private close() {
    this.$store.commit('modals/close');
  }
}
</script>

<style lang="scss" scoped>
.delete-card-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-top: 15px;
    margin-bottom: 20px;
  }
  &-button {
    display: flex;
    .controller-button {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
</style>
