import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-account" }
const _hoisted_2 = { class: "my-account-personal" }
const _hoisted_3 = { class: "my-account-inputs" }
const _hoisted_4 = { class: "my-account-password" }
const _hoisted_5 = { class: "my-account-inputs" }
const _hoisted_6 = { class: "success" }
const _hoisted_7 = { class: "error" }
const _hoisted_8 = { class: "my-account-delete" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_back_link = _resolveComponent("app-back-link")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.query.embedded)
      ? (_openBlock(), _createBlock(_component_app_back_link, {
          key: 0,
          class: "back-button",
          mini: "",
          link: _ctx.initialPath
        }, null, 8, ["link"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _cache[12] || (_cache[12] = _createElementVNode("h1", null, "Personal details", -1)),
      _cache[13] || (_cache[13] = _createElementVNode("div", { class: "divider ham full-width" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_app_input, {
          value: _ctx.user.firstName,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.firstName) = $event)),
          id: "first-name",
          showError: _ctx.show,
          placeholder: ""
        }, _createSlots({
          label: _withCtx(() => [
            _cache[7] || (_cache[7] = _createTextVNode("Full name"))
          ]),
          _: 2
        }, [
          (_ctx.yupErrMsg('name'))
            ? {
                name: "error",
                fn: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.yupErrMsg('name')), 1)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["value", "showError"]),
        (!_ctx.thirdParty)
          ? (_openBlock(), _createBlock(_component_app_input, {
              key: 0,
              value: _ctx.user.email,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.email) = $event)),
              id: "email",
              showError: _ctx.show,
              placeholder: ""
            }, _createSlots({
              label: _withCtx(() => [
                _cache[8] || (_cache[8] = _createTextVNode("Email"))
              ]),
              _: 2
            }, [
              (_ctx.yupErrMsg('email'))
                ? {
                    name: "error",
                    fn: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.yupErrMsg('email')), 1)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["value", "showError"]))
          : _createCommentVNode("", true),
        (_ctx.thirdParty)
          ? (_openBlock(), _createBlock(_component_app_input, {
              key: 1,
              value: _ctx.user.email,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.email) = $event)),
              id: "email",
              disabled: true,
              placeholder: ""
            }, {
              label: _withCtx(() => [
                _cache[9] || (_cache[9] = _createTextVNode("Email (account linked with ")),
                _createElementVNode("b", null, _toDisplayString(_ctx.thirdParty), 1),
                _cache[10] || (_cache[10] = _createTextVNode(")"))
              ]),
              _: 1
            }, 8, ["value"]))
          : _createCommentVNode("", true),
        _createVNode(_component_app_input, {
          value: _ctx.user.phone,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.phone) = $event)),
          id: "telephone-number",
          showError: _ctx.show,
          placeholder: ""
        }, _createSlots({
          label: _withCtx(() => [
            _cache[11] || (_cache[11] = _createTextVNode("Telephone number"))
          ]),
          _: 2
        }, [
          (_ctx.yupErrMsg('phone'))
            ? {
                name: "error",
                fn: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.yupErrMsg('phone')), 1)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["value", "showError"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[18] || (_cache[18] = _createElementVNode("h1", null, "Change password", -1)),
      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "divider grape full-width" }, null, -1)),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_app_input, {
          type: "password",
          value: _ctx.password.oldPassword,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.password.oldPassword) = $event)),
          id: "current-password",
          placeholder: "",
          showError: _ctx.show
        }, _createSlots({
          label: _withCtx(() => [
            _cache[14] || (_cache[14] = _createTextVNode("Current password"))
          ]),
          _: 2
        }, [
          (_ctx.yupErrMsg('oldPassword'))
            ? {
                name: "error",
                fn: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.yupErrMsg('oldPassword')), 1)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["value", "showError"]),
        _createVNode(_component_app_input, {
          type: "password",
          value: _ctx.password.newPassword,
          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.password.newPassword) = $event)),
          id: "new-password",
          placeholder: "",
          showError: _ctx.show
        }, _createSlots({
          label: _withCtx(() => [
            _cache[15] || (_cache[15] = _createTextVNode("New password"))
          ]),
          _: 2
        }, [
          (_ctx.yupErrMsg('newPassword'))
            ? {
                name: "error",
                fn: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.yupErrMsg('newPassword')), 1)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["value", "showError"]),
        _createVNode(_component_app_input, {
          type: "password",
          value: _ctx.password.newPasswordConfirm,
          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.password.newPasswordConfirm) = $event)),
          id: "repeat-new-password",
          placeholder: "",
          showError: _ctx.show
        }, _createSlots({
          label: _withCtx(() => [
            _cache[16] || (_cache[16] = _createTextVNode("Repeat new password"))
          ]),
          _: 2
        }, [
          (_ctx.yupErrMsg('newPasswordConfirm'))
            ? {
                name: "error",
                fn: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.yupErrMsg('newPasswordConfirm')), 1)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["value", "showError"]),
        _createElementVNode("div", null, [
          _createVNode(_component_app_button, {
            slim: "",
            onClick: _ctx.update,
            loader: _ctx.loader
          }, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [
              _createElementVNode("b", null, "Update", -1)
            ])),
            _: 1
          }, 8, ["onClick", "loader"]),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.successMsg), 1),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.errorMsg), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_app_button, {
        slim: "",
        onClick: _ctx.openDeleteAccountModal
      }, {
        default: _withCtx(() => _cache[20] || (_cache[20] = [
          _createElementVNode("b", null, "Delete account", -1)
        ])),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}