<template>
  <div class="order-item">
    <img :src="order.restaurant.vendor.logo" alt="" class="vendor-logo" />
    <h4 class="vendor-name">
      <b>{{ order.restaurant.name }}</b>
    </h4>
    <p class="order-item-info-vendor">
      <span>{{ getDate('date') }}</span>
      <span>{{ getDate('time') }}</span>
      <span>{{ order.friendlyId }}</span>
    </p>
    <app-button class="track-and-amend" slim @click="goTo">TRACK AND AMEND</app-button>
    <status
      :status="order.status?.replaceAll('ready_for_delivery', 'ready')?.replaceAll('ready_for_collect', 'ready')"
    />
    <h4 class="order-item-price">
      <b>£{{ order.totalPrice.toFixed(2) }}</b>
    </h4>
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

import status from '@/components/Account/Status.vue';

import methods from '@/utils/date';

class Props {
  order = prop<{ createdAt: string; friendlyId: number }>({ required: true });
}

@Options({
  components: { status },
  props: {
    order: Object,
  },
})
export default class OrderItem extends Vue.with(Props) {
  private getDate(view: 'date' | 'time') {
    const d = methods.getDate(this.order.createdAt);

    if (view === 'date') return `${d.monthSmallText} ${d.day}, ${d.year}`;

    return `${d.hoursMinutes}`;
  }

  private goTo() {
    this.$router.push(`/orders-tracker?orderId=${this.order.friendlyId}`);
  }
}
</script>

<style lang="scss" scoped>
.order-item {
  display: grid;
  gap: 0 15px;
  grid-template-columns: 45px 4fr 1fr 1fr 1fr;
  grid-template-areas:
    'logo name track status price'
    'logo info track status price';
  align-items: center;
  padding-top: 10px;
  @include for-smartphone {
    grid-template-columns: 45px 4fr 1fr;
    grid-template-areas:
      'logo name status'
      'logo info price'
      'logo track price';
  }

  &-info-vendor {
    grid-area: info;
    width: 100%;
    span {
      display: inline-flex;
      align-items: flex-end;
      &::after {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        background-color: $darkHam;
        border-radius: 100%;
        margin: 0 5px;
        margin-bottom: 6px;
      }
      &:last-child {
        &::after {
          content: none;
        }
      }
    }
  }

  &-price {
    text-align: right;
    grid-area: price;
    @include for-smartphone {
      align-items: flex-start;
    }
  }

  .vendor-name {
    align-self: flex-end;
    grid-area: name;
  }

  .vendor-logo {
    align-self: center;
    grid-area: logo;
  }

  .status {
    align-self: center;
    justify-self: center;
    text-align: center;
    grid-area: status;
    @include for-smartphone {
      justify-self: flex-end;
      align-items: flex-end;
    }
  }

  .track-and-amend {
    align-self: center;
    justify-self: center;
    text-align: center;
    grid-area: track;

    width: fit-content;
    height: 25px;
    min-height: 25px;
    border: 0.4px solid #464646;
    border-radius: 5px;
    gap: 7.5px;
    padding: 6px 5px 4px 5px;
    font-size: 11px;

    @include for-smartphone {
      align-self: flex-start;
      justify-self: flex-start;
      margin-top: 5px;
    }
  }
}
</style>
