import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/check.svg'


const _hoisted_1 = { class: "group-order-modal__title" }
const _hoisted_2 = { class: "address_details" }
const _hoisted_3 = {
  key: 0,
  class: "address_details"
}
const _hoisted_4 = {
  key: 1,
  class: "address_details"
}
const _hoisted_5 = {
  key: 2,
  class: "address_details"
}
const _hoisted_6 = { key: 3 }
const _hoisted_7 = {
  key: 0,
  class: "section_title"
}
const _hoisted_8 = {
  key: 1,
  class: "section_title"
}
const _hoisted_9 = {
  key: 2,
  class: "section_subtitle"
}
const _hoisted_10 = {
  key: 3,
  class: "section_subtitle"
}
const _hoisted_11 = { class: "group-order-modal__instructions" }
const _hoisted_12 = { class: "group-order-modal__error" }
const _hoisted_13 = {
  key: 0,
  class: "group-order-modal__pending-wrapper"
}
const _hoisted_14 = ["to"]
const _hoisted_15 = {
  key: 4,
  class: "section_subtitle",
  style: {"color":"red","text-align":"center"}
}
const _hoisted_16 = {
  key: 5,
  class: "section_subtitle",
  style: {"color":"red","text-align":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, {
    class: "group-order-modal",
    close: true
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(`${_ctx.userName} group order`), 1),
      _createElementVNode("p", _hoisted_2, [
        _cache[4] || (_cache[4] = _createTextVNode(" From: ")),
        _createElementVNode("strong", null, _toDisplayString(_ctx.restaurantName), 1)
      ]),
      (_ctx.type === 'delivery')
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
            _cache[5] || (_cache[5] = _createTextVNode(" To: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.userAddress), 1)
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_4, [
            _cache[6] || (_cache[6] = _createTextVNode(" Pickup: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.restaurantAddress), 1)
          ])),
      (!_ctx.restaurantAllowScheduledOrders)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _cache[7] || (_cache[7] = [
            _createTextVNode(" Date and time: "),
            _createElementVNode("strong", null, "As soon as possible", -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_ctx.type === 'delivery')
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, _cache[8] || (_cache[8] = [
                  _createElementVNode("strong", null, "Choose a delivery date and time", -1)
                ])))
              : (_openBlock(), _createElementBlock("p", _hoisted_8, _cache[9] || (_cache[9] = [
                  _createElementVNode("strong", null, "Choose a collection date and time", -1)
                ]))),
            (_ctx.type === 'delivery')
              ? (_openBlock(), _createElementBlock("p", _hoisted_9, "When do you want your order delivered?"))
              : (_openBlock(), _createElementBlock("p", _hoisted_10, "When do you want to pick up your order?")),
            _createElementVNode("a", {
              class: "date_container",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showDeliveryTimePicker && _ctx.showDeliveryTimePicker(...args)))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.formattedDeliveryDate), 1),
              _createVNode(_component_IconServices, {
                class: "services-icon",
                icon: "thickClock",
                color: "black"
              })
            ])
          ])),
      _cache[17] || (_cache[17] = _createElementVNode("p", { class: "section_title" }, [
        _createElementVNode("strong", null, "Choose a deadline (optional)")
      ], -1)),
      _cache[18] || (_cache[18] = _createElementVNode("p", { class: "section_subtitle" }, "When do people need to add their items?", -1)),
      _createElementVNode("a", {
        class: "date_container",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showDeadlineTimePicker && _ctx.showDeadlineTimePicker(...args)))
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.formattedDeadlineDate), 1),
        _cache[10] || (_cache[10] = _createElementVNode("img", {
          src: _imports_0,
          width: "22",
          alt: ""
        }, null, -1))
      ]),
      _cache[19] || (_cache[19] = _createElementVNode("p", { class: "section_title" }, [
        _createElementVNode("strong", null, "Add some instructions (optional)")
      ], -1)),
      _cache[20] || (_cache[20] = _createElementVNode("p", { class: "section_subtitle" }, null, -1)),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_app_input, {
          class: "group-order-modal__instructions-input",
          showErrorWithoutHighlighting: _ctx.error,
          isMultiplyLine: "",
          title: "Add some instructions",
          value: _ctx.instructions,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.instructions) = $event)),
          id: "instructions",
          placeholder: "E.g Budget per person, recommendations"
        }, _createSlots({ _: 2 }, [
          (_ctx.error.message)
            ? {
                name: "error",
                fn: _withCtx(() => [
                  _createElementVNode("div", _hoisted_12, [
                    _createTextVNode(_toDisplayString(_ctx.error.message) + ". ", 1),
                    (_ctx.error.type === 'pendingOrder' && _ctx.groupData && _ctx.groupData.restaurantSlug)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                          _createElementVNode("span", {
                            class: "group-order-modal__pending-link",
                            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goToActiveGroupOrder && _ctx.goToActiveGroupOrder(...args))),
                            to: `/${_ctx.groupData.restaurantSlug}`,
                            "aria-label": "homepage"
                          }, "Click here", 8, _hoisted_14),
                          _cache[11] || (_cache[11] = _createTextVNode(" to manage it. "))
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["showErrorWithoutHighlighting", "value"])
      ]),
      (!_ctx.isDeliveryLateEnough)
        ? (_openBlock(), _createElementBlock("p", _hoisted_15, [
            _createTextVNode(" The restaurant needs " + _toDisplayString(_ctx.data.wholeMenuMinHoursInAdvance) + " " + _toDisplayString(_ctx.data.wholeMenuMinHoursInAdvance === 1 ? 'hour' : 'hours') + " notice.", 1),
            _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
            _cache[13] || (_cache[13] = _createTextVNode("Please edit the delivery date. "))
          ]))
        : (!_ctx.isDeadlineLateEnough)
          ? (_openBlock(), _createElementBlock("p", _hoisted_16, [
              _createTextVNode(" The restaurant needs " + _toDisplayString(_ctx.data.wholeMenuMinHoursInAdvance) + " " + _toDisplayString(_ctx.data.wholeMenuMinHoursInAdvance === 1 ? 'hour' : 'hours') + " notice.", 1),
              _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
              _cache[15] || (_cache[15] = _createTextVNode("Please edit the deadline. "))
            ]))
          : _createCommentVNode("", true),
      _createVNode(_component_app_button, {
        class: "group-order-modal__button",
        loader: _ctx.loader,
        onClick: _withModifiers(_ctx.createGroupOrder, ["prevent"]),
        disabled: !_ctx.isDeliveryLateEnough || !_ctx.isDeadlineLateEnough
      }, {
        default: _withCtx(() => _cache[16] || (_cache[16] = [
          _createTextVNode(" Create your link ")
        ])),
        _: 1
      }, 8, ["loader", "onClick", "disabled"])
    ]),
    _: 1
  }))
}