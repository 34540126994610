<template>
  <app-text-accordion title="Add coupon code" :initialCloseValue="coupon">
    <!-- Input field -->
    <div class="coupon-row">
      <app-input v-model:value="inputFieldValue" class="animated slideOutDown" :disabled="coupon" />
      <span @click="applyOrRevert" class="coupon-row-apply">{{ coupon ? 'Revert' : 'Apply' }}</span>
    </div>

    <!-- Coupon info -->
    <div v-if="verifyingCoupon" class="loader">
      <Logo class="logo-loader" />
    </div>
    <span v-else-if="errorMessage" class="coupon-result invalid">
      {{ errorMessage }}
    </span>
    <span v-else class="coupon-result">
      {{ title }}
    </span>
  </app-text-accordion>
</template>

<script lang="ts">
import { watch } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import restaurantService from '@/requests/restaurant';

import Logo from '@/shared/icons/Logo.vue';

import methods from '@/utils/methods';

@Options({
  components: { Logo },
  computed: {
    ...mapState('basket', ['coupon']),
  },
})
export default class CouponField extends Vue {
  private inputFieldValue = '';
  private verifyingCoupon = false;
  private errorMessage = '';

  get title() {
    const { coupon } = this.$store.state.basket;
    if (!coupon) return '';

    if (coupon.type === 'free_delivery') {
      return "Lucky you! We've removed the delivery fee!";
    }

    let discount = '';
    if (coupon.type === 'percent') discount = `${coupon.discount}% off the total`;
    if (coupon.type === 'specific_amount') discount = `-${coupon.discount}`;
    return `Lucky you! Here is ${discount}!`;
  }

  private async applyOrRevert() {
    const { coupon, restaurant } = this.$store.state.basket;
    if (coupon) {
      this.$store.commit('basket/coupon', null);
      return;
    }

    this.verifyingCoupon = true;
    this.errorMessage = '';
    try {
      const orderType = this.getCouponOrderType();
      const cartPrice = methods.getCartPrice();

      const response = await restaurantService.validateCoupon(
        this.inputFieldValue,
        cartPrice,
        +restaurant!.id,
        orderType,
      );

      this.$store.commit('basket/coupon', response);
    } catch (error) {
      this.errorMessage = error as string;
      this.$store.commit('basket/coupon', null);
    } finally {
      this.verifyingCoupon = false;
    }
  }

  private getCouponOrderType() {
    const { scheduledDeliveryInterval, scheduledDeliveryDate } = this.$store.state.order;
    const isScheduled = scheduledDeliveryInterval && scheduledDeliveryDate;
    const isCollection = this.$store.state.order.type === 'collection';

    if (!isScheduled) {
      return isCollection ? 'collect_asap' : 'delivery_asap';
    }

    return isCollection ? 'collect_scheduled' : 'delivery_scheduled';
  }

  mounted() {
    if (this.$store.state.amendableOrder.data) {
      this.inputFieldValue = this.$store.state.basket.coupon?.name || '';
    } else {
      this.$store.commit('basket/coupon', null);
    }

    watch(this.$store.state.basket, () => {
      const { coupon } = this.$store.state.basket;
      const cartPrice = methods.getCartPrice();
      if (coupon && coupon.minimumCartPrice && cartPrice < coupon.minimumCartPrice) {
        this.$store.commit('basket/coupon', null);
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.coupon-row {
  display: flex;
  align-items: center;
  margin-top: 10px;

  &-apply {
    color: $darkHam;
    text-decoration: underline;
    margin: 5px 0 0 15px;
    cursor: pointer;
  }
}

.coupon-result {
  display: flex;
  align-items: center;
  margin: 5px 0 0 5px;
  max-width: 340px;
  color: $lime100;
  &.invalid {
    color: $darkHam;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;

  .logo {
    transform: scale(0.8);
  }
}
</style>
