<template>
  <app-modal class="group-order-share" :close="true">
    <h2 class="group-order-share__title">Share your link</h2>
    <p class="group-order-share__description">
      Share this link to add people to your order. Once participants have added to your order, proceed to checkout to
      place your order.
    </p>

    <div class="group-order-share__link">
      <p class="group-order-share__link-value">{{ data.link }}</p>
    </div>

    <app-button class="group-order-share__share-button" @click.prevent="copyToClipboard()"> {{ copyText }}</app-button>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

@Options({
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class ModalShareGroupOrderLink extends Vue {
  private copyText = 'Copy';

  private async copyToClipboard() {
    await navigator.clipboard.writeText(this.$store.state.modals.data.link);
    this.copyText = 'Copied!';
    setTimeout(() => {
      this.copyText = 'Copy';
    }, 1000);
  }
}
</script>

<style lang="scss">
.group-order-share {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    max-width: 375px;
    width: 375px;
  }

  &__share-button {
    margin-top: 30px;
    max-width: unset;
  }

  &__title {
    text-align: center;
    margin: 0 50px;
    margin-top: 20px;
  }

  &__description {
    @include p2;
    text-align: center;
    margin: 15px 20px 50px 20px;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    padding-left: 20px;
    padding-right: 20px;
    max-height: 48px;
    min-height: 48px;
    border: 1px solid $coal20;
    border-radius: 10px;
    gap: 10px;
  }

  &__link-value {
    @include p2;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
