<template>
  <div class="group-baskets-list__basket">
    <div class="group-baskets-list__header"><span>Your items</span></div>
    <p class="group-baskets-list__no-items" v-if="userBasket.length < 1">No items added</p>
    <BasketItem
      v-else
      :edit="!checkout && !areItemsConfirmed"
      :class="{ 'remove-border': index === userBasket.length - 1 && checkout }"
      v-for="(i, index) in userBasket"
      :key="index"
      :dish="i"
      :indexDish="index"
    />
  </div>
  <div
    v-if="!amendableData"
    :class="`group-baskets-list__view-more ${showRestOfGroupOrder ? 'group-baskets-list__view-more-expanded' : ''} `"
    @click="showGroupOrderBaskets()"
  >
    <IconServices class="group-baskets-list__view-more-icon" icon="add" color="dark-ham" />
    <div class="group-baskets-list__view-more-text">Show other people’s items</div>
  </div>
  <div v-if="showRestOfGroupOrder || amendableData">
    <div v-if="groupData && groupData.baskets.length && $route.params.slug === groupData.restaurantSlug">
      <div class="group-baskets-list__basket" v-for="(basket, index) in groupData.baskets" :key="index">
        <div v-if="participantBasketId !== basket.id">
          <div class="group-baskets-list__header">
            <span>{{ `${addApostrophe(basket.userName)} items` }}</span> - confirmed
          </div>
          <BasketItem
            :edit="!checkout && groupRole === 'Owner'"
            :class="{ 'remove-border': index === basket.length - 1 && checkout }"
            v-for="(dish, index) in basket.cart.dishes"
            :key="index"
            :dish="dish"
            :indexDish="index"
            :isGroupOrder="true"
            :groupBasketId="basket.id"
            @editGroupBasket="(count, index) => editItemFromGroupBasket(count, index, basket.id)"
            @removeFromGroupBasket="(index) => editItemFromGroupBasket(0, index, basket.id)"
          />
        </div>
      </div>
    </div>
    <div v-if="noItemsToShow && !amendableData" class="group-baskets-list__no-items">No items added</div>
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';
import { mapState } from 'vuex';

import BasketItem from '@/components/Basket/Item.vue';

import groupOrdersHttp from '@/requests/groupOrders';

import IconServices from '@/shared/icons/IconServices.vue';

import pipes from '@/utils/pipes';

class Props {
  checkout = prop<boolean>({ required: true });
}

@Options({
  props: {
    checkout: Boolean,
  },
  components: {
    IconServices,
    BasketItem,
  },
  computed: {
    ...mapState('basket', ['restaurant']),
    ...mapState('groupOrder', ['groupData', 'groupRole', 'areItemsConfirmed', 'participantBasketId']),
    ...mapState('amendableOrder', { amendableData: 'data' }),
  },
})
export default class GroupOrderBaskets extends Vue.with(Props) {
  private showRestOfGroupOrder = false;

  get userBasket() {
    const { participantBasketId, groupData } = this.$store.state.groupOrder;

    if (participantBasketId) {
      const foundParticipantBasket = groupData?.baskets.find((b) => b.id === participantBasketId)?.cart.dishes;
      return foundParticipantBasket;
    }

    return this.$store.state.basket.dishes;
  }

  get noItemsToShow() {
    const { participantBasketId, groupData } = this.$store.state.groupOrder;

    if (groupData?.baskets.length === 0) return true;
    if (groupData?.baskets.length === 1 && participantBasketId) return true;
    return false;
  }

  private async editItemFromGroupBasket(count: number, index: number, basketId: string) {
    const { groupData } = this.$store.state.groupOrder;
    if (!groupData) return;

    const foundBasket = groupData.baskets.find((basket) => basket.id === basketId);
    if (!foundBasket) return;

    // Prepare deep copy to prevent updating view too early:
    const clonedListOfDishes = (JSON.parse(JSON.stringify(foundBasket)) as GroupOrderBasket).cart.dishes;

    // Update copy:
    if (count < 1) clonedListOfDishes.splice(index, 1);
    else clonedListOfDishes[index].amount = count;

    const { data: amendableData } = this.$store.state.amendableOrder;
    if (!amendableData) {
      // Send request to API and update local state (and view) AFTER receiving the response:
      const updatedGroupOrder = await groupOrdersHttp.editBasketInGroupOrder(groupData.id, {
        basketId: foundBasket.id,
        cart: { ...foundBasket.cart, dishes: clonedListOfDishes },
      });
      this.$store.commit('groupOrder/update', { ...updatedGroupOrder });
    } else {
      // If amending the order, update local state (and view) as soon as possible:
      foundBasket.cart.dishes = clonedListOfDishes;
      if (!foundBasket.cart.dishes.length) {
        groupData.baskets = groupData.baskets.filter((b) => b.id !== foundBasket.id);
      }
      this.$store.commit('groupOrder/update', { ...groupData });
    }
  }

  created() {
    // show the rest of order immediately if in checkout
    if (this.checkout) this.showRestOfGroupOrder = true;
  }

  private async showGroupOrderBaskets() {
    this.showRestOfGroupOrder = !this.showRestOfGroupOrder;
  }

  private addApostrophe(name: string) {
    return pipes.addApostrophe(name);
  }
}
</script>

<style lang="scss" scoped>
.group-baskets-list {
  &__view-more {
    display: flex;
    color: $darkHam;
    gap: 7px;
    font-weight: 600;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.25s all;

    &-expanded {
      svg {
        transform: rotate(45deg);
      }
    }
  }

  &__view-more-text {
    text-decoration: underline;
  }

  &__view-more-icon {
    height: 15px;
    width: 15px;
  }

  &__header {
    color: $darkHam;
    font-weight: 600;
    span {
      color: $darkHam;
      font-weight: 600;
      text-decoration: underline;
    }
  }

  &__basket {
    margin-top: 10px;
    width: 100%;
  }

  &__no-items {
    @include p2;
    margin: 15px 0;
  }
}
</style>
