<template>
  <div class="order-acknowledgement-wrapper">
    <p>
      <b>{{ title }}</b>
    </p>
    <h1>{{ subtitle }}</h1>
    <div v-if="state === 0" class="order-acknowledgement-button custom-cursor-hover" @click.prevent="acknowledge">
      Acknowledge
    </div>
    <div v-if="state === 1" class="order-acknowledgement-button" disabled>
      <Logo class="logo-loader" />
    </div>
  </div>
</template>

<script lang="ts">
import { useSeoMeta } from '@unhead/vue';
import moment from 'moment-timezone';
import { Options, Vue } from 'vue-class-component';

import orders from '@/requests/orders';

import Logo from '@/shared/icons/Logo.vue';

@Options({ components: { Logo } })
export default class OrderAcknowledge extends Vue {
  private title = 'Order Acknowledgement';
  private subtitle = "Please click the button below to acknowledge and confirm you've seen this order.";
  private state = 0;

  created() {
    useSeoMeta({ robots: 'noindex' });
  }

  private async acknowledge() {
    this.state = 1;
    try {
      const { foodDueTime, friendlyId } = await orders.acknowledgeOrder({ orderId: this.$route.params.orderId });
      const formattedFDT = moment(foodDueTime).tz('Europe/London').format('Do MMMM YYYY, HH:mm');
      this.title = `Order ${friendlyId} acknowledged`;
      this.subtitle = `Thanks! A friendly reminder that the rider pick up time for this order is ${formattedFDT}.`;
    } catch (e: any) {
      this.title = `Something went wrong!`;
      this.subtitle = e;
    } finally {
      this.state = 2;
    }
  }
}
</script>

<style lang="scss" scoped>
.order-acknowledgement {
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: 100vh;
    padding: 30px;
    background: $coal100;
    p,
    b {
      color: $ham;
      margin-bottom: 15px;
      font-size: 24px;
    }
    h1 {
      color: $vanilla;
      margin-bottom: 40px;
      font-size: 20px;
      max-width: 500px;
    }
  }

  &-button {
    padding: 10px;
    background: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Recoleta, sans-serif;
    font-weight: 600;
    height: 50px;
    width: 150px;
  }
}
</style>
