import http from '@/utils/http';

class HttpRestaurant {
  public getRestaurant(slug: string, query?: string): Promise<RequestGetRestaurant> {
    return http
      .get(`/restaurants/${slug}/menu${query || ''}`)
      .then((res: RequestGetRestaurant) => res)
      .catch((err: any) => err);
  }

  public getRestaurantCapacityLimits(id: number | string, query?: string): Promise<RequestGetRestaurant> {
    return http
      .get(`/restaurants/${id}/capacity-limits${query || ''}`)
      .then((res: RequestGetRestaurant) => res)
      .catch((err: any) => err);
  }

  public getRestaurantNameAndCity(slug: string): Promise<{ name: string; city: string }> {
    return http.get(`/restaurants/${slug}/name-and-city?restaurantSlug=${slug}`).catch((err: any) => err);
  }

  public getCategory(id: number | string, categoryId: string, deliverToTime?: string): Promise<{ dishes: Product[] }> {
    let path = `/restaurants/${id}/category/${categoryId}`;
    if (deliverToTime) path += `?deliverToTime=${deliverToTime}`;

    return http
      .get(path)
      .then((res: { dishes: Product[] }) => res)
      .catch((err: any) => err);
  }

  public validateCoupon(coupon: string, cartPrice: number, restaurantId: number, orderType: string): Promise<Coupon> {
    return http
      .get(
        `/admin/coupon-by-name?name=${coupon}&cartprice=${cartPrice}` +
          `&restaurantId=${restaurantId}&orderType=${orderType}`,
      )
      .then((res: Coupon) => res);
  }
}

export default new HttpRestaurant();
