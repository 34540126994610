<template>
  <app-modal class="basket-modal" v-bind="options">
    <app-button class="basket-modal-close" mini white @click.prevent="close">
      <IconArrow class="services-icon" />
    </app-button>

    <BasketComponent :restaurant="data" orderSettingsDisabled isMobile />
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import BasketComponent from '@/components/Basket.vue';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';
import IconArrow from '@/shared/icons/IconArrow.vue';

@Options({
  components: {
    BasketComponent,
    IconArrow,
  },
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class ModalBasket extends Vue {
  private options: ModalInterface = {
    slim: false,
    close: false,
  };

  private close() {
    document.body.classList.remove('no-scroll');
    this.$store.commit('modals/close');
  }

  private updateWidth() {
    if (window.innerWidth >= 1025) {
      this.close();
    }
  }

  created() {
    document.body.classList.add('no-scroll');
  }

  mounted() {
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
  }
}
</script>

<style lang="scss">
.basket-modal {
  display: flex;
  align-self: center;
  justify-content: center;
  &.modal-wrapper {
    padding: 0;
    min-height: -webkit-fill-available;
  }
  .modal {
    background: none;
    max-height: 100vh;
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 0;
    overscroll-behavior: contain;
    min-height: -webkit-fill-available;
  }
  .basket {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: -webkit-fill-available;
    max-height: none;
    &-wrapper {
      min-height: 100%;
      &.empty {
        margin-top: 20px;
      }
    }
    &-title {
      width: 100%;
    }
    .form-scoring-wrapper {
      align-self: flex-end;
    }
    .zigzag {
      display: none;
    }
  }
  &-close {
    position: absolute;
    top: 5px;
    left: 10px;
    z-index: 1;
    svg.services-icon {
      width: 25px;
    }
  }
}
</style>
