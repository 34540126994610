<template>
  <app-modal class="amend-order-too-late-modal" v-bind="options">
    <app-button class="amend-order-too-late-modal-close" mini white @click.prevent="close">
      <IconServices class="services-icon" icon="add" close />
    </app-button>

    <div class="amend-order-too-late-modal-content">
      <h1>You’ve missed the cut off!</h1>
      <div class="amend-order-too-late-bullet-point">
        <div class="bullet-point-box">
          <img src="../../assets/order_tracking/chevron_right.png" alt="" />
        </div>
        <p>We only allow order edits up to 2 hours before your delivery time</p>
      </div>
      <div class="amend-order-too-late-bullet-point">
        <div class="bullet-point-box">
          <img src="../../assets/order_tracking/chevron_right.png" alt="" />
        </div>
        <p>This deadline protects restaurants from potential revenue loss</p>
      </div>
      <div class="amend-order-too-late-bullet-point">
        <div class="bullet-point-box">
          <img src="../../assets/order_tracking/chevron_right.png" alt="" />
        </div>
        <p>Please contact our support team to discuss any changes</p>
      </div>
      <app-button class="custom-button" @click.prevent="close">Okay</app-button>
    </div>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';
import IconServices from '@/shared/icons/IconServices.vue';

@Options({
  components: { IconServices },
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class ModalAmendOrderTooLate extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: false,
    noPadding: true,
  };

  private close() {
    this.$store.commit('modals/close');
  }
}
</script>

<style lang="scss">
.amend-order-too-late-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .modal {
    animation: $modal-show-bottom;
  }
}
</style>

<style lang="scss" scoped>
.amend-order-too-late-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  .close.services-icon {
    top: 9px;
    left: 9px;
    width: 21px;
    height: 21px;
  }
}

.amend-order-too-late-modal-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 30px;
}

h1 {
  font-weight: 600;
  font-size: 35px;
  line-height: 35px;
  margin-top: 20px;
  margin-bottom: 10px;
}

p {
  font-family:
    Sofia Pro,
    sans-serif;
  font-weight: 500;
}

.amend-order-too-late-bullet-point {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.bullet-point-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $ham;
  height: 24px;
  aspect-ratio: 1;
  border-radius: 4px;

  img {
    width: 16px;
    margin-left: auto;
    margin-right: auto;
  }
}

.custom-button {
  margin-top: 15px;
}
</style>
