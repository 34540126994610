<template>
  <app-modal class="error-modal" v-bind="options">
    <div v-if="!isCalendarVisible" class="modal-close">
      <IconServices close icon="add" class="custom-cursor-hover" @click="closeModal" />
    </div>
    <div class="calendar-title-wrapper" v-else>
      <h4 class="order-modal-subtitle" v-if="data.withDeliveryTimeChange && isDelivery">Delivery time</h4>
      <h4 class="order-modal-subtitle" v-if="data.withDeliveryTimeChange && !isDelivery">Collection time</h4>
    </div>

    <div v-if="data.withDeliveryTimeChange && !deliveryIntervalsByDays[0].length" class="order-modal-loader">
      <Logo class="logo-loader" />
    </div>
    <div v-else-if="!isCalendarVisible" class="order-modal">
      <h2 class="order-modal-title">Order details</h2>
      <div class="order-modal-order-type-switch" v-if="data.withOrderTypeSwitch">
        <OrderTypeSwitch :onChange="onOrderTypeChange" />
      </div>
      <div v-else style="height: 15px" />
      <h4 class="order-modal-subtitle" v-if="data.withAddressChange">Your address</h4>
      <div class="order-modal-address-input" v-if="data.withAddressChange">
        <AddressChangeInput
          :initialAddress="address"
          :onChange="onAddressChange"
          placeholder="Enter your address here"
        />
      </div>
      <h4 class="order-modal-subtitle" v-if="data.withDeliveryTimeChange && isDelivery">Delivery time</h4>
      <h4 class="order-modal-subtitle" v-if="data.withDeliveryTimeChange && !isDelivery">Collection time</h4>
      <div class="order-modal-delivery-time" v-if="data.withDeliveryTimeChange">
        <app-button slim white @click="showCalendar">
          <p>{{ getFormattedDate }}</p>
          <img src="../assets/simple_arrow_bottom.svg" alt="" />
        </app-button>
      </div>
      <app-button class="order-modal-apply-btn square slim" @click="handleSubmitClick">{{
        data.isLanding ? 'Lets eat' : 'Apply'
      }}</app-button>
    </div>
    <div v-else>
      <Calendar
        :dateTime="dateTime"
        :interval="interval"
        :intervalsByDays="deliveryIntervalsByDays"
        :onConfirmClick="onCalendarConfirmClick"
      />
    </div>
  </app-modal>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { watch } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { useShepherd } from 'vue-shepherd';
import { mapState } from 'vuex';

import AddressChangeInput from '@/components/AddressChangeInput.vue';
import Calendar from '@/components/Calendar.vue';
import OrderTypeSwitch from '@/components/Restaurants/OrderTypeSwitch.vue';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';
import IconServices from '@/shared/icons/IconServices.vue';
import Logo from '@/shared/icons/Logo.vue';

import google from '@/utils/google';
import scrolling from '@/utils/scrolling';

@Options({
  components: { OrderTypeSwitch, AddressChangeInput, Calendar, IconServices, Logo },
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class AppModalOrderSettings extends Vue {
  private tour = useShepherd({ useModalOverlay: true });
  private emptyPickerLabel = 'Unavailable';

  private options: ModalInterface = {
    slim: false,
    close: false,
    noPadding: true,
  };

  private orderType?: string;
  private address?: google.maps.places.PlaceResult;
  private isDelivery = true;

  private isCalendarVisible = false;
  private deliveryIntervalsByDays: Array<string[]> = [[], []];
  private dateTime = moment().tz('Europe/London').set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  private interval = 'Now';

  get getFormattedDate() {
    const beginningOfToday = moment().tz('Europe/London').set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const diffInDays = moment(this.dateTime).diff(beginningOfToday, 'days');

    if (diffInDays === 0) return `Today - ${this.interval}`;
    if (diffInDays === 1) return `Tomorrow - ${this.interval}`;
    return `${this.dateTime.format('ddd, Do MMM')} - ${this.interval}`;
  }

  private onOrderTypeChange(type: 'delivery' | 'collection') {
    this.orderType = type;
    this.isDelivery = type === 'delivery';
  }

  private onAddressChange(newAddress: google.maps.places.PlaceResult) {
    this.address = newAddress;
  }

  private onCalendarConfirmClick(dateTime: moment.Moment, interval: string) {
    this.dateTime = moment(dateTime);
    this.interval = interval;
    this.closeCalendar();
  }

  private showCalendar() {
    this.isCalendarVisible = true;
    if (this.tour.isActive()) this.completeTour();
  }

  private closeCalendar() {
    this.isCalendarVisible = false;
  }

  private buildListOfIntervalsForAllDays() {
    const now = moment().tz('Europe/London');
    const daysToChooseFrom = this.$store.state.timePicker.scheduledDays;
    const newDeliveryIntervalsByDays: string[][] = [];

    const { restaurant } = this.$store.state.modals.data;
    if (restaurant) {
      for (let i = 0; i < daysToChooseFrom; i += 1) {
        const hours = restaurant.weekCapacityLimits[i].map(
          (capacityLimit: { start: string; end: string }) => `${capacityLimit.start} - ${capacityLimit.end}`,
        );
        newDeliveryIntervalsByDays.push(hours.length > 0 ? hours : [this.emptyPickerLabel]);
      }

      if (newDeliveryIntervalsByDays[0][0] !== this.emptyPickerLabel && restaurant.allowOnDemandOrders) {
        newDeliveryIntervalsByDays[0].unshift('Now');
      }
    } else {
      for (let i = 0; i < daysToChooseFrom; i += 1) {
        newDeliveryIntervalsByDays[i] = [...this.$store.state.timePicker.intervals];
      }
      for (let i = 0; i < newDeliveryIntervalsByDays[0].length; i += 1) {
        const endHour = newDeliveryIntervalsByDays[0][i].split('-')[1].split(':')[0].trim();
        if (+endHour > +now.hour()) {
          newDeliveryIntervalsByDays[0].splice(0, i);
          break;
        }
      }
      newDeliveryIntervalsByDays[0].unshift('Now');
    }

    this.deliveryIntervalsByDays = newDeliveryIntervalsByDays;
  }

  private handleSubmitClick() {
    if (this.interval === this.emptyPickerLabel) {
      this.$store.commit('modals/close');
      return;
    }

    if (this.interval === 'Now') {
      if (this.$store.state.order.scheduledDeliveryInterval) this.$store.commit('basket/coupon', null);

      this.$store.commit('order/scheduledDeliveryInterval', null);
      this.$store.commit('order/scheduledDeliveryDate', null);
    } else {
      if (!this.$store.state.order.scheduledDeliveryInterval) this.$store.commit('basket/coupon', null);

      this.$store.commit('order/scheduledDeliveryInterval', this.interval);

      const [selectedHour, selectedMinute] = this.interval.includes(' - ')
        ? this.interval
            .split(' - ')[0]
            .split(':')
            .map((e) => parseInt(e, 10))
        : [undefined, undefined];
      const date = this.dateTime
        .set({
          hour: selectedHour,
          minute: selectedMinute,
          second: 0,
          millisecond: 0,
        })
        .toISOString();
      this.$store.commit('order/scheduledDeliveryDate', date);
    }

    if (this.orderType) this.$store.commit('order/type', this.orderType);

    if (this.address) google.setAddress(this.address);

    const { address, modals } = this.$store.state;
    if (modals.data.isLanding) this.$router.push(`/restaurants/${address.cityName.toLowerCase()}`);

    setTimeout(scrolling.toListOfRestaurants, 100);
    this.closeModal();
  }

  private closeModal() {
    this.$store.commit('modals/close');
  }

  private setUpProductTour() {
    // Do nothing if the step has already been presented in the past (or there is no way to change time):
    const { modals, productTour } = this.$store.state;
    if (!modals.data.withDeliveryTimeChange || productTour['change-delivery-time']) return;

    // Do nothing in embedded views:
    if (this.$route.query.embedded) return;

    // Identify step's element:
    const element = document.getElementsByClassName('order-modal-delivery-time')[0];
    if (!element) return;

    // Add step and start tour:
    this.tour.addStep({
      buttons: [{ text: '<p>Got it</p>', action: this.completeTour }],
      attachTo: { element, on: 'right' },
      text: '<p>Order now or schedule your order up to <strong>3 months in advance.</strong></p>',
    });
    this.tour.start();

    // Add possibility to close pop-up by clicking overlay:
    const overlay = document.getElementsByClassName('shepherd-modal-overlay-container')[0] as HTMLElement;
    if (overlay) overlay.onclick = this.completeTour;

    if (window.innerWidth <= 1024) this.tour.steps[0].hide();
    window.addEventListener('resize', () => {
      // Don't restart tour:
      if (!this.tour.isActive()) return;

      // Show only on desktop:
      const step = this.tour.steps[0];
      if (window.innerWidth <= 1024 && step.isOpen()) step.hide();
      else if (window.innerWidth > 1024 && !step.isOpen()) step.show();
    });
  }

  private completeTour() {
    this.$store.commit('productTour/setAsCompleted', 'change-delivery-time');
    this.tour.complete();
  }

  mounted() {
    // Set up product tour AFTER rendering delivery time picker:
    watch(
      () => this.deliveryIntervalsByDays[0].length,
      () => setTimeout(this.setUpProductTour, 500),
    );

    // Build delivery time picker's content:
    if (this.$store.state.timePicker.scheduledDays > 2) {
      this.buildListOfIntervalsForAllDays();
    } else {
      watch(
        () => this.$store.state.timePicker.scheduledDays,
        () => this.buildListOfIntervalsForAllDays(),
      );
    }

    // Choose initial delivery time picker's selected options:
    const { scheduledDeliveryDate, scheduledDeliveryInterval } = this.$store.state.order;
    if (scheduledDeliveryDate && scheduledDeliveryInterval) {
      this.dateTime = moment(scheduledDeliveryDate).tz('Europe/London');
      this.interval = scheduledDeliveryInterval.toString();
    }

    if (this.$store.state.order.type !== 'delivery') {
      this.isDelivery = false;
    }
  }

  unmounted() {
    if (this.tour.isActive()) this.tour.complete();
  }
}
</script>

<style lang="scss" scoped>
.modal-close {
  margin: 12px 12px 0px 0;
  height: 12px;
  text-align: right;
  svg {
    width: 12px;
    height: 12px;
  }
}

.calendar-title-wrapper {
  margin-top: 15px;
  margin-bottom: 10px;
}

.order-modal {
  display: flex;
  flex-direction: column;
  padding: 10px 0 30px;
  width: 350px;
  max-width: calc(100vw - 40px);

  &-title {
    @include h2;
    font-size: 30px;
    text-align: center;
    margin-bottom: 15px;
  }

  &-order-type-switch {
    margin-bottom: 30px;
    width: fit-content;
    align-self: center;
  }

  &-subtitle {
    @include h4;
    margin-bottom: 5px;
    padding-left: 18px;
  }

  &-delivery-time {
    width: 100%;
    background-color: $white;
    padding: 18px 30px;
    border-top: 1px solid $coal20;
    border-bottom: 1px solid $coal20;
    margin-bottom: 20px;

    button {
      border: 1px solid $coal20;
      border-radius: 5px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: auto;
      margin-right: auto;
      gap: 5px;
      width: 100%;
    }

    p {
      margin-top: 0;
    }
  }

  &-address-input {
    margin: 0 0 30px;
    padding: 0 18px;
  }

  &-apply-btn {
    margin: 0 auto;
    width: 40%;
  }
}

.order-modal-loader {
  display: flex;
  justify-content: center;
  width: 350px;
  max-width: calc(100vw - 40px);
  height: 442px;
}
</style>
