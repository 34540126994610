import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/gtm/introduction.mp4'
import _imports_1 from '../../assets/gtm/play_button.png'
import _imports_2 from '../../assets/gtm/video_unlimited.png'
import _imports_3 from '../../assets/gtm/video_calendar.png'
import _imports_4 from '../../assets/gtm/video_forward.png'


const _hoisted_1 = { class: "step_zero" }
const _hoisted_2 = { class: "left_part" }
const _hoisted_3 = { class: "right_part" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("video", {
        id: "introduction_video",
        class: "custom-cursor-hover",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.pauseOrResumeVideo && _ctx.pauseOrResumeVideo(...args)), ["prevent"])),
        onPause: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onPause && _ctx.onPause(...args))),
        onPlay: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onPlay && _ctx.onPlay(...args)))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("source", {
          src: _imports_0,
          type: "video/mp4"
        }, null, -1),
        _createElementVNode("track", {
          kind: "captions",
          label: "No captions available",
          srclang: "en"
        }, null, -1),
        _createTextVNode(" Your browser does not support the video tag or captions. ")
      ]), 32),
      _createElementVNode("img", {
        id: "play_button",
        class: "custom-cursor-hover",
        src: _imports_1,
        alt: "",
        onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.pauseOrResumeVideo && _ctx.pauseOrResumeVideo(...args)), ["prevent"]))
      }),
      _createVNode(_component_Logo, {
        class: "logo-with-text",
        text: "",
        whiteText: ""
      }),
      _createVNode(_component_app_button, {
        square: "",
        slim: "",
        white: "",
        onClick: _ctx.goToNextStep
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("Continue")
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Logo, {
        class: "logo-with-text",
        text: ""
      }),
      _cache[7] || (_cache[7] = _createStaticVNode("<h1 data-v-aafcd204>Streamlining ordering food to the workplace</h1><div class=\"step_container\" data-v-aafcd204><div data-v-aafcd204><img src=\"" + _imports_2 + "\" alt=\"\" data-v-aafcd204></div><p data-v-aafcd204>Invite an unlimited number of restaurants, existing or new</p></div><div class=\"step_container\" data-v-aafcd204><div data-v-aafcd204><img src=\"" + _imports_3 + "\" alt=\"\" data-v-aafcd204></div><p data-v-aafcd204>Organise all office catering in one place</p></div><div class=\"step_container\" data-v-aafcd204><div data-v-aafcd204><img src=\"" + _imports_4 + "\" alt=\"\" data-v-aafcd204></div><p data-v-aafcd204>Streamline invoices and card payments</p></div>", 4)),
      _createVNode(_component_app_button, {
        square: "",
        slim: "",
        onClick: _ctx.goToNextStep
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode("Continue")
        ])),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}