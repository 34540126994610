import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "controller-count" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_button, {
      onClick: _cache[0] || (_cache[0] = () => _ctx.setAmount(_ctx.amount - 1)),
      class: _normalizeClass({ 'count-mini': _ctx.mini }),
      disabled: _ctx.minusDisabled,
      color: "ham",
      mini: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_IconServices, {
          class: "services-icon",
          icon: "minus"
        })
      ]),
      _: 1
    }, 8, ["class", "disabled"]),
    (_ctx.mini)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.amount), 1))
      : (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.amount), 1)),
    _createVNode(_component_app_button, {
      onClick: _cache[1] || (_cache[1] = () => _ctx.setAmount(_ctx.amount + 1)),
      class: _normalizeClass({ 'count-mini': _ctx.mini }),
      disabled: _ctx.plusDisabled,
      color: "ham",
      mini: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_IconServices, {
          class: "services-icon",
          icon: "add"
        })
      ]),
      _: 1
    }, 8, ["class", "disabled"])
  ]))
}