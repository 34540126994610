import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 3,
  id: "wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationBar = _resolveComponent("NavigationBar")!
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_GroupOrderBanner = _resolveComponent("GroupOrderBanner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_TickerTape = _resolveComponent("TickerTape")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Cookies = _resolveComponent("Cookies")!
  const _component_Modals = _resolveComponent("Modals")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.withNavbar)
      ? (_openBlock(), _createBlock(_component_NavigationBar, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.$route.name === 'restaurants')
      ? (_openBlock(), _createBlock(_component_SearchBar, {
          key: 1,
          onSearching: _ctx.searching
        }, null, 8, ["onSearching"]))
      : _createCommentVNode("", true),
    (_ctx.$route.name === 'restaurant')
      ? (_openBlock(), _createBlock(_component_GroupOrderBanner, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.withWrapper)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_router_view)
        ]))
      : (_openBlock(), _createBlock(_component_router_view, { key: 4 })),
    (_ctx.withTickerTape)
      ? (_openBlock(), _createBlock(_component_TickerTape, { key: 5 }))
      : _createCommentVNode("", true),
    (_ctx.withFooter)
      ? (_openBlock(), _createBlock(_component_Footer, { key: 6 }))
      : _createCommentVNode("", true),
    (_ctx.cookies)
      ? (_openBlock(), _createBlock(_component_Cookies, {
          key: 7,
          onAccessCookies: _ctx.accessCookies
        }, null, 8, ["onAccessCookies"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Modals),
    _cache[0] || (_cache[0] = _createElementVNode("div", {
      id: "google-place",
      "aria-hidden": "true",
      style: {"display":"none"}
    }, null, -1))
  ], 64))
}