<template>
  <div class="modal-wrapper" :class="{ 'does-not-close': doesNotClose }" ref="modalWrapper">
    <div class="modal" v-click-outside="closeModalIfClickedOutside" :class="{ slim, noPadding }">
      <div class="modal-close" v-if="close && !doesNotClose">
        <IconServices close icon="add" class="custom-cursor-hover" @click.prevent="closeModal" />
      </div>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

import IconServices from '@/shared/icons/IconServices.vue';

class Props {
  doesNotClose = prop<boolean>({ required: true });
}

@Options({
  components: {
    IconServices,
  },
  props: {
    close: {
      type: Boolean,
      default: false,
    },
    slim: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    doesNotClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    close: [],
  },
})
export default class AppControllerModal extends Vue.with(Props) {
  declare $refs: {
    modalWrapper: HTMLDivElement;
  };

  private closeModalIfClickedOutside(e: Event) {
    try {
      // Don't close modal when accepting cookies or operating on other modal:
      if (e.target && !this.$refs.modalWrapper.isSameNode(e.target as HTMLElement)) return;
      this.closeModal(e);
    } catch (_) {}
  }

  private closeModal(e: Event) {
    if (!this.doesNotClose) {
      document.body.classList.remove('no-scroll');
      this.$store.commit('modals/close');
    }
    this.$emit('close');
  }
}

export interface ModalInterface {
  close?: boolean;
  slim?: boolean;
  doesNotClose?: boolean;
  noPadding?: boolean;
}
</script>

<style lang="scss" scoped>
.modal {
  position: relative;
  align-self: center;
  z-index: 1;
  background-color: $vanilla;
  border-radius: 15px;
  overflow: hidden;
  overflow-y: auto;
  max-height: 90vh;
  padding: {
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
  }
  &-close {
    height: 12px;
    text-align: right;
    margin-bottom: 5px;
    svg {
      width: 12px;
      height: 12px;
    }
  }

  &.slim {
    max-width: 345px;
    width: 345px;
  }

  &-wrapper {
    position: fixed;
    z-index: 500;
    left: 0;
    top: 0;
    width: 100%;
    min-width: 100vw;
    height: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    padding: {
      top: 65px;
      bottom: 65px;
      left: 20px;
      right: 20px;
    }
    &::before {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      background-color: $coal100;
      opacity: 0.6;
    }
    &.does-not-close {
      &::before {
        cursor: not-allowed;
      }
    }
  }

  &.noPadding {
    padding: 0;

    .modal-close {
      margin: 12px 12px 0 0;
    }
  }
}
</style>
