import account, { AccessibleCities } from '@/requests/account';

import store from '@/store';

class GoogleApi {
  public autocomplete(input: string): Promise<google.maps.places.AutocompletePrediction[]> {
    return new Promise((resolve, reject) => {
      const displaySuggestions = (
        predictions: google.maps.places.AutocompletePrediction[],
        status: google.maps.places.PlacesServiceStatus,
      ): void => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(predictions);
        }
        reject();
      };

      const options: google.maps.places.AutocompletionRequest = {
        input,
        componentRestrictions: {
          country: 'uk',
        },
      };

      const service: google.maps.places.AutocompleteService = new google.maps.places.AutocompleteService();
      service.getPlacePredictions(options, displaySuggestions);
    });
  }

  public getStreetNumber(addressComponents: google.maps.GeocoderAddressComponent[]) {
    const streetNumber = addressComponents.find((pred: google.maps.GeocoderAddressComponent) =>
      pred.types.find((type: string) => type === 'street_number'),
    );
    return streetNumber?.long_name;
  }

  public getStreetName(addressComponents: google.maps.GeocoderAddressComponent[]) {
    const streetName = addressComponents.find((pred: google.maps.GeocoderAddressComponent) =>
      pred.types.find((type: string) => type === 'route'),
    );
    return streetName?.long_name;
  }

  public getCityName(addressComponents: google.maps.GeocoderAddressComponent[]) {
    const cityName = addressComponents.find((pred: google.maps.GeocoderAddressComponent) =>
      pred.types.find((type: string) => type === 'postal_town'),
    );
    return cityName?.long_name;
  }

  public getPostalCode(addressComponents: google.maps.GeocoderAddressComponent[]) {
    const postalCode = addressComponents.find((pred: google.maps.GeocoderAddressComponent) =>
      pred.types.find((type: string) => type === 'postal_code'),
    );
    return postalCode?.long_name;
  }

  public openModalSearch(err: string, postcode: string) {
    if (err === ErrorCustomMsg.invalidAddress) {
      store.commit('modals/show', 'invalidAddress');
    } else if (err === ErrorCustomMsg.missingAreaGtm) {
      store.commit('modals/show', 'missingAreaGtm');
    } else {
      console.log(err);
    }
  }

  public setAddress(components: google.maps.places.PlaceResult | undefined) {
    if (components?.address_components) {
      const location = components?.geometry?.location;
      const lat: number | undefined = typeof location?.lat === 'number' ? (location?.lat as any) : location?.lat();
      const lng: number | undefined = typeof location?.lng === 'number' ? (location?.lng as any) : location?.lng();
      store.commit('address/set', {
        lat,
        lng,
        cityName: this.getCityName(components.address_components),
        postcode: this.getPostalCode(components.address_components),
        streetName: this.getStreetName(components.address_components),
        streetNumber: this.getStreetNumber(components.address_components),
        formattedAddress: components.formatted_address,
      });
    }
  }

  public validatePlaceType(place: google.maps.places.PlaceResult) {
    const addressTypes = place.types || [];
    const componentTypes = place.address_components
      ? place.address_components.map((el) => el.types).reduce((acc, cur) => [...acc, ...cur], [])
      : [];

    return (
      componentTypes.includes('route') || addressTypes.includes('premise') || addressTypes.includes('establishment')
    );
  }

  public validatePlace(
    place: google.maps.places.PlaceResult,
    acceptAllCities: boolean,
  ): Promise<google.maps.places.PlaceResult> {
    return new Promise((resolve, reject) => {
      // Ensure there is a route component in response:
      if (!this.validatePlaceType(place)) throw new Error(ErrorCustomMsg.invalidAddress);

      // Ignore other rules if accepting all cities (e.g. in GTM flow):
      if (acceptAllCities) resolve(place);

      // Check if address belongs to one of Foodstuff's cities:
      const cityName = place.address_components ? this.getCityName(place.address_components) : undefined;
      account
        .accessibleCities()
        .then((result: AccessibleCities[]) => {
          const town = result.find((city: AccessibleCities) => city.city === cityName);

          // Accept only few addresses in London (unless it's embedded view):
          if (!town || cityName?.toLowerCase() === 'london') {
            const isEmbeddedView = document.getElementsByTagName('body')[0].classList.contains('embeddedPage');
            const validLondonAddresses = [
              '1-10 Praed Mews, Tyburnia, London W2 1QY, UK',
              '24 Eversholt St, London NW1 1AD, UK',
              '80 Victoria St, London SW1E 5JL, UK',
            ];

            if (place.formatted_address && !validLondonAddresses.includes(place.formatted_address) && !isEmbeddedView) {
              throw new Error(ErrorCustomMsg.missingAreaGtm);
            }
          }

          resolve(place);
        })
        .catch((err: string) => {
          reject(err);
        });
    });
  }

  public getPlaceDetails(place: google.maps.places.AutocompletePrediction): Promise<google.maps.places.PlaceResult> {
    return new Promise((resolve, reject) => {
      const el: HTMLDivElement = document.getElementById('google-place') as HTMLDivElement;
      const service: google.maps.places.PlacesService = new google.maps.places.PlacesService(el);
      const result = (
        resultPredictions: google.maps.places.PlaceResult,
        status: google.maps.places.PlacesServiceStatus,
      ): void => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(resultPredictions);
        }
        reject();
      };

      service.getDetails(
        {
          placeId: place.place_id,
          fields: ['address_components', 'geometry', 'name', 'types', 'formatted_address'],
        },
        result,
      );
    });
  }
}

export enum ErrorCustomMsg {
  invalidAddress = 'Please enter the house number and street.',
  missingAreaGtm = 'We are not available there yet.',
}

export default new GoogleApi();
