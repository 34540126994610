<template>
  <div class="bgc" />

  <div class="account-wrapper">
    <div class="account-star">
      <IconServices icon="bigStar" />
      <h1>{{ title }}</h1>
    </div>

    <div class="account-view">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import IconServices from '@/shared/icons/IconServices.vue';

import methods from '@/utils';

@Options({ components: { IconServices } })
export default class AccountView extends Vue {
  get title() {
    const title: string = this.$route.name as string;
    return methods.capitalizeFirstLetter(title.split('-').join(' '));
  }
}
</script>

<style lang="scss" scoped>
.bgc {
  position: fixed;
  z-index: -5;
  content: '';
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: $grape;
  opacity: 0.95;
}

.account {
  &-star {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    max-width: 350px;
    max-height: 350px;
    width: 350px;
    min-width: 350px;
    height: 350px;
    min-height: 350px;
    position: sticky;
    top: 20px;
    @include for-smartphone {
      display: none;
    }
    h1 {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: rotate(-10deg);
    }
    svg {
      width: 100%;
      height: 100%;
      animation: rotate 20s linear infinite;
    }
  }
  &-wrapper {
    display: flex;
    margin-top: 75px;
    @include for-smartphone {
      margin-top: 0;
    }
  }
  &-view {
    width: 100%;
    background-color: $vanilla;
    border: 1px solid $coal20;
    margin-bottom: 80px;
    margin-top: 50px;
    padding: {
      top: 50px;
      left: 50px;
      bottom: 60px;
      right: 40px;
    }
    @include for-smartphone {
      padding: {
        top: 40px;
        left: 30px;
        bottom: 40px;
        right: 20px;
      }
    }
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
