<template>
  <div class="reset-password">
    <h1>Reset password</h1>
    <div class="divider ham full-width" />

    <app-input type="password" v-model:value="password.newPassword" id="new-password" placeholder="" :showError="show">
      <template v-slot:label>New password</template>
      <template v-slot:error v-if="yupErrMsg('newPassword')">
        {{ yupErrMsg('newPassword') }}
      </template>
    </app-input>

    <app-input
      type="password"
      v-model:value="password.newPasswordConfirm"
      id="repeat-new-password"
      placeholder=""
      :showError="show"
    >
      <template v-slot:label>Repeat new password</template>
      <template v-slot:error v-if="yupErrMsg('newPasswordConfirm')">
        {{ yupErrMsg('newPasswordConfirm') }}
      </template>
    </app-input>

    <div class="reset-password-button">
      <app-button :loader="loader" slim @click="update"><b>Reset</b></app-button>
      <span v-if="successful" class="successful">Successful!</span>
      <span v-if="errorMessage" class="invalid-code">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { confirmPasswordReset, getAuth } from 'firebase/auth';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import * as yup from 'yup';

import account, { DataResetPassword } from '@/requests/account';

import methods from '@/utils/methods';

@Options({
  computed: {
    ...mapState('yup', ['show']),
  },
})
export default class ResetPassword extends Vue {
  private password: DataResetPassword = {
    newPassword: '',
    newPasswordConfirm: '',
  };

  private successful = false;

  private errorMessage: string | null = null;

  private loader = false;

  private schema = yup.object({
    newPassword: yup.string().required('No password provided.'),
    newPasswordConfirm: yup.string().oneOf([yup.ref('newPassword'), undefined], 'Passwords must match'),
  });

  private update() {
    if (!this.successful && !this.errorMessage) {
      this.schema
        .validate(this.password, { abortEarly: false })
        .then(() => {
          this.loader = true;

          if (this.$route.query?.mode === 'resetPassword') {
            const auth = getAuth();
            const { oobCode } = this.$route.query;
            const { newPassword } = this.password;
            confirmPasswordReset(auth, oobCode as string, newPassword)
              .then(() => {
                this.successful = true;
              })
              .catch((e) => {
                if (e.code === 'auth/invalid-action-code') {
                  this.errorMessage = 'Invalid code.';
                } else if (e.code === 'auth/weak-password') {
                  this.errorMessage = 'Password should contain at least 6 characters.';
                } else {
                  this.errorMessage = 'Something went wrong.';
                }
              })
              .finally(() => {
                this.loader = false;
              });
          } else {
            account
              .resetPassword(this.$route.params.code as string, this.password)
              .then((res) => {
                if (!res.success) {
                  this.errorMessage = res;
                  return;
                }

                this.successful = true;
              })
              .finally(() => {
                this.loader = false;
              });
          }
        })
        .catch((err: { inner: yup.ValidationError[] }) => {
          methods.yupValidCatch(err);
        });
    }
  }

  private yupErrMsg(path: string) {
    return methods.yupErrMsg(path);
  }

  created() {
    this.$store.commit('yup/toggleShowError', false);
    this.$store.commit('yup/clear');
  }
}
</script>

<style lang="scss">
.reset-password {
  .divider {
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .controller-input-wrapper {
    margin-top: 10px;
  }
  .controller-button.slim {
    margin-top: 20px;
  }

  &-button {
    .successful {
      display: flex;
      margin-top: 10px;
      margin-left: 10px;
      color: $lime100;
    }
    .invalid-code {
      display: flex;
      margin-top: 10px;
      margin-left: 10px;
      color: $darkHam;
    }
  }
}
</style>
