import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "error-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Error", -1)),
      (_ctx.data)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            innerHTML: _ctx.data
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16))
}