import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "restaurant-basket" }
const _hoisted_2 = { class: "basket" }
const _hoisted_3 = { class: "basket-title" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "basket-wrapper"
}
const _hoisted_6 = { class: "scrollable-part" }
const _hoisted_7 = { class: "basket-title-items" }
const _hoisted_8 = {
  key: 0,
  class: "basket-title-group"
}
const _hoisted_9 = {
  key: 1,
  class: "basket-title-group"
}
const _hoisted_10 = { class: "basket-title-group-header" }
const _hoisted_11 = {
  key: 3,
  class: "divider ham full-width",
  style: {"margin-bottom":"15px"}
}
const _hoisted_12 = { key: 4 }
const _hoisted_13 = { class: "form-scoring" }
const _hoisted_14 = { class: "form-scoring-price" }
const _hoisted_15 = { class: "c-black" }
const _hoisted_16 = {
  key: 0,
  class: "c-black previous-price"
}
const _hoisted_17 = { class: "form-scoring" }
const _hoisted_18 = { class: "form-scoring-price" }
const _hoisted_19 = { class: "c-black" }
const _hoisted_20 = {
  key: 0,
  class: "form-scoring"
}
const _hoisted_21 = { class: "form-scoring-price" }
const _hoisted_22 = { class: "c-black" }
const _hoisted_23 = {
  key: 1,
  class: "form-scoring"
}
const _hoisted_24 = { class: "form-scoring-price" }
const _hoisted_25 = { class: "c-black" }
const _hoisted_26 = {
  key: 2,
  class: "form-scoring"
}
const _hoisted_27 = { class: "form-scoring-price" }
const _hoisted_28 = { class: "c-black" }
const _hoisted_29 = { key: 5 }
const _hoisted_30 = { class: "form-scoring" }
const _hoisted_31 = { class: "form-scoring-price" }
const _hoisted_32 = { class: "c-black" }
const _hoisted_33 = { class: "form-scoring" }
const _hoisted_34 = { class: "form-scoring-price" }
const _hoisted_35 = { class: "c-black" }
const _hoisted_36 = {
  key: 0,
  class: "c-black previous-price"
}
const _hoisted_37 = { key: 6 }
const _hoisted_38 = { class: "form-scoring" }
const _hoisted_39 = { class: "form-scoring-price" }
const _hoisted_40 = { class: "c-black" }
const _hoisted_41 = {
  key: 0,
  class: "c-black previous-price"
}
const _hoisted_42 = { class: "form-scoring" }
const _hoisted_43 = { class: "form-scoring-price" }
const _hoisted_44 = { class: "c-black" }
const _hoisted_45 = {
  key: 0,
  class: "form-scoring"
}
const _hoisted_46 = { class: "form-scoring-price" }
const _hoisted_47 = { class: "c-black" }
const _hoisted_48 = {
  key: 1,
  class: "delivery-fee-info"
}
const _hoisted_49 = { class: "form-scoring" }
const _hoisted_50 = { class: "form-scoring-price" }
const _hoisted_51 = { class: "c-black" }
const _hoisted_52 = {
  key: 0,
  class: "vehicle-type"
}
const _hoisted_53 = { class: "unscrollable-part" }
const _hoisted_54 = {
  key: 1,
  class: "min-cart-price-info-basket"
}
const _hoisted_55 = {
  class: "form-scoring total",
  style: {"margin-bottom":"5px","margin-top":"5px"}
}
const _hoisted_56 = { class: "form-scoring-price" }
const _hoisted_57 = { class: "c-ham" }
const _hoisted_58 = {
  class: "form-scoring total",
  style: {"margin-bottom":"15px"}
}
const _hoisted_59 = { class: "c-ham" }
const _hoisted_60 = { class: "form-scoring-price" }
const _hoisted_61 = { class: "c-ham" }
const _hoisted_62 = { class: "items-buttons-wrapper" }
const _hoisted_63 = { key: 0 }
const _hoisted_64 = { key: 1 }
const _hoisted_65 = {
  key: 0,
  class: "items-confirmed-info"
}
const _hoisted_66 = { class: "items-confirmed-info-description" }
const _hoisted_67 = { class: "items-buttons-wrapper" }
const _hoisted_68 = {
  class: "form-scoring total",
  style: {"margin-bottom":"15px","margin-top":"5px"}
}
const _hoisted_69 = { class: "form-scoring-price" }
const _hoisted_70 = { class: "c-ham" }
const _hoisted_71 = { class: "items-buttons-wrapper" }
const _hoisted_72 = { key: 0 }
const _hoisted_73 = { key: 1 }
const _hoisted_74 = { key: 2 }
const _hoisted_75 = { key: 3 }
const _hoisted_76 = { key: 4 }
const _hoisted_77 = { key: 1 }
const _hoisted_78 = { class: "basket-wrapper empty" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ZigZag = _resolveComponent("ZigZag")!
  const _component_UnavailableDeliveryBanner = _resolveComponent("UnavailableDeliveryBanner")!
  const _component_OrderSettingsButton = _resolveComponent("OrderSettingsButton")!
  const _component_AmendableOrderInfo = _resolveComponent("AmendableOrderInfo")!
  const _component_GroupOrderBaskets = _resolveComponent("GroupOrderBaskets")!
  const _component_BasketItem = _resolveComponent("BasketItem")!
  const _component_CouponField = _resolveComponent("CouponField")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_FormScoring = _resolveComponent("FormScoring")!
  const _component_IconEmptyBasket = _resolveComponent("IconEmptyBasket")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ZigZag),
      _createVNode(_component_ZigZag, { class: "rotate" }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.checkout ? 'Checkout' : 'Basket'), 1),
        (_ctx.restaurant?.logo && _ctx.$route.params.slug === _ctx.restaurant?.slug)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              width: "33",
              height: "33",
              src: _ctx.restaurant?.logo,
              alt: "restaurant logo"
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      (
          (!_ctx.groupData && _ctx.dishes.length > 0 && _ctx.$route.params.slug === _ctx.restaurant?.slug) ||
          (_ctx.groupData && _ctx.$route.params.slug === _ctx.groupData.restaurantSlug)
        )
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_ctx.restaurant && !_ctx.restaurant.isClosed && !_ctx.restaurant.isTemporarilyClose)
                ? (_openBlock(), _createBlock(_component_UnavailableDeliveryBanner, {
                    key: 0,
                    restaurant: _ctx.restaurant
                  }, null, 8, ["restaurant"]))
                : _createCommentVNode("", true),
              (_ctx.restaurant && !_ctx.amendableData && (!_ctx.groupData || _ctx.checkout))
                ? (_openBlock(), _createBlock(_component_OrderSettingsButton, {
                    key: 1,
                    restaurant: _ctx.restaurant,
                    basketTotal: +_ctx.cartPrice,
                    disabled: 
              _ctx.orderSettingsDisabled ||
              _ctx.checkout ||
              (_ctx.restaurant && !_ctx.restaurant.allowScheduledOrders && !_ctx.restaurant.clickAndCollect)
            
                  }, null, 8, ["restaurant", "basketTotal", "disabled"]))
                : _createCommentVNode("", true),
              (!_ctx.isMobile && _ctx.amendableData && _ctx.$route.params.slug === _ctx.amendableData.restaurantSlug)
                ? (_openBlock(), _createBlock(_component_AmendableOrderInfo, {
                    key: 2,
                    isMobile: false,
                    restaurant: _ctx.restaurant,
                    class: "basket-group-order-info"
                  }, null, 8, ["restaurant"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_7, [
                (!_ctx.groupData)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "basket-title-group-header" }, "Items", -1)),
                      (_ctx.isSuperAdmin && !_ctx.checkout && !_ctx.amendableData)
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 0,
                            class: "basket-title-link custom-cursor-hover",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.shareBasket()))
                          }, " Share basket "))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.groupData && _ctx.$route.params.slug === _ctx.groupData.restaurantSlug)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("h2", _hoisted_10, _toDisplayString(`${_ctx.groupRole === 'Owner' ? 'Your' : _ctx.groupOwnerWithApostrophe} group order`), 1)
                    ]))
                  : _createCommentVNode("", true),
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "divider ham full-width" }, null, -1))
              ]),
              _createElementVNode("div", {
                class: "basket-list",
                style: _normalizeStyle({ maxHeight: _ctx.checkout ? '67vh' : undefined })
              }, [
                (_ctx.groupData && _ctx.$route.params.slug === _ctx.groupData.restaurantSlug)
                  ? (_openBlock(), _createBlock(_component_GroupOrderBaskets, {
                      key: 0,
                      checkout: _ctx.checkout
                    }, null, 8, ["checkout"]))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.dishes, (i, index) => {
                      return (_openBlock(), _createBlock(_component_BasketItem, {
                        edit: _ctx.checkout ? false : true,
                        class: _normalizeClass({ 'remove-border': index === _ctx.dishes.length - 1 }),
                        key: index,
                        dish: i,
                        indexDish: index
                      }, null, 8, ["edit", "class", "dish", "indexDish"]))
                    }), 128))
              ], 4),
              (!_ctx.checkout)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                : _createCommentVNode("", true),
              (_ctx.amendableData && !_ctx.checkout)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "c-black" }, "Updated subtotal", -1)),
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("p", _hoisted_15, "£" + _toDisplayString(_ctx.discountedCartPrice.toFixed(2)), 1),
                        (_ctx.hasBigOrderDiscount || (_ctx.coupon && _ctx.coupon.id && _ctx.coupon.type !== 'free_delivery'))
                          ? (_openBlock(), _createElementBlock("p", _hoisted_16, " £" + _toDisplayString(_ctx.cartPrice.toFixed(2)), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "c-black" }, "Updated service fee", -1)),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("p", _hoisted_19, "£" + _toDisplayString(_ctx.serviceFee.toFixed(2)), 1)
                      ])
                    ]),
                    (_ctx.smallOrderFee > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _cache[7] || (_cache[7] = _createElementVNode("h3", { class: "c-black" }, "Updated small order fee", -1)),
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("p", _hoisted_22, "£" + _toDisplayString(_ctx.smallOrderFee.toFixed(2)), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.type === 'delivery')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                          _cache[8] || (_cache[8] = _createElementVNode("h3", { class: "c-black" }, "Updated delivery fee", -1)),
                          _createElementVNode("div", _hoisted_24, [
                            _createElementVNode("p", _hoisted_25, "£" + _toDisplayString(_ctx.deliveryFee.toFixed(2)), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.tip > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                          _cache[9] || (_cache[9] = _createElementVNode("h3", { class: "c-black" }, "Tips", -1)),
                          _createElementVNode("div", _hoisted_27, [
                            _createElementVNode("p", _hoisted_28, "£" + _toDisplayString(_ctx.tip.toFixed(2)), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("p", {
                      class: "c-black td-u fee-info custom-cursor-hover",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.showFeeDetails && _ctx.showFeeDetails(...args)), ["prevent"]))
                    }, "Our fees")
                  ]))
                : (_ctx.groupData && _ctx.groupRole === 'Participant' && !_ctx.checkout)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                      _createElementVNode("div", _hoisted_30, [
                        _cache[10] || (_cache[10] = _createElementVNode("h3", {
                          class: "c-black",
                          style: {"font-size":"20px"}
                        }, "Your Items", -1)),
                        _createElementVNode("div", _hoisted_31, [
                          _createElementVNode("p", _hoisted_32, "£" + _toDisplayString(_ctx.getParticipantCartPrice.toFixed(2)), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_33, [
                        _cache[11] || (_cache[11] = _createElementVNode("h3", { class: "c-black" }, "Order subtotal", -1)),
                        _createElementVNode("div", _hoisted_34, [
                          _createElementVNode("p", _hoisted_35, "£" + _toDisplayString(_ctx.cartPrice.toFixed(2)), 1),
                          (_ctx.coupon && _ctx.coupon.id && _ctx.coupon.type !== 'free_delivery')
                            ? (_openBlock(), _createElementBlock("p", _hoisted_36, " £" + _toDisplayString(_ctx.cartPrice.toFixed(2)), 1))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]))
                  : (!_ctx.checkout)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                        _createElementVNode("div", _hoisted_38, [
                          _cache[12] || (_cache[12] = _createElementVNode("h3", { class: "c-black" }, "Subtotal", -1)),
                          _createElementVNode("div", _hoisted_39, [
                            _createElementVNode("p", _hoisted_40, "£" + _toDisplayString(_ctx.discountedCartPrice.toFixed(2)), 1),
                            (_ctx.hasBigOrderDiscount || (_ctx.coupon && _ctx.coupon.id && _ctx.coupon.type !== 'free_delivery'))
                              ? (_openBlock(), _createElementBlock("p", _hoisted_41, " £" + _toDisplayString(_ctx.cartPrice.toFixed(2)), 1))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_42, [
                          _cache[13] || (_cache[13] = _createElementVNode("h3", { class: "c-black" }, "Service fee", -1)),
                          _createElementVNode("div", _hoisted_43, [
                            _createElementVNode("p", _hoisted_44, "£" + _toDisplayString(_ctx.serviceFee.toFixed(2)), 1)
                          ])
                        ]),
                        (_ctx.smallOrderFee > 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                              _cache[14] || (_cache[14] = _createElementVNode("h3", { class: "c-black" }, "Small order fee", -1)),
                              _createElementVNode("div", _hoisted_46, [
                                _createElementVNode("p", _hoisted_47, "£" + _toDisplayString(_ctx.smallOrderFee.toFixed(2)), 1)
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.type === 'delivery')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                              _createElementVNode("div", _hoisted_49, [
                                _cache[15] || (_cache[15] = _createElementVNode("h3", { class: "c-black" }, "Delivery fee", -1)),
                                _createElementVNode("div", _hoisted_50, [
                                  _createElementVNode("p", _hoisted_51, "£" + _toDisplayString(_ctx.deliveryFee.toFixed(2)), 1)
                                ])
                              ]),
                              (_ctx.isLargeVehicleType)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_52, "This delivery requires a large vehicle type"))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("p", {
                          class: "c-black td-u fee-info custom-cursor-hover",
                          onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.showFeeDetails && _ctx.showFeeDetails(...args)), ["prevent"]))
                        }, "Our fees")
                      ]))
                    : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_53, [
              (!_ctx.checkout && _ctx.groupRole !== 'Participant')
                ? (_openBlock(), _createBlock(_component_CouponField, {
                    key: 0,
                    class: "coupon-field"
                  }))
                : _createCommentVNode("", true),
              (_ctx.remainingCartPrice)
                ? (_openBlock(), _createElementBlock("div", _hoisted_54, _toDisplayString(_ctx.remainingCartPrice), 1))
                : _createCommentVNode("", true),
              (_ctx.amendableData && !_ctx.checkout)
                ? (_openBlock(), _createBlock(_component_FormScoring, { key: 2 }, {
                    footer: _withCtx(() => [
                      _createElementVNode("div", _hoisted_55, [
                        _cache[16] || (_cache[16] = _createElementVNode("h3", { class: "c-ham" }, "Updated total", -1)),
                        _createElementVNode("div", _hoisted_56, [
                          _createElementVNode("h3", _hoisted_57, "£" + _toDisplayString(_ctx.total), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_58, [
                        _createElementVNode("h3", _hoisted_59, _toDisplayString(_ctx.totalDiff >= 0 ? 'Amount to pay' : "We'll refund"), 1),
                        _createElementVNode("div", _hoisted_60, [
                          _createElementVNode("h3", _hoisted_61, "£" + _toDisplayString((_ctx.totalDiff > 0 ? _ctx.totalDiff : -_ctx.totalDiff).toFixed(2)), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_62, [
                        _createVNode(_component_app_button, {
                          onClick: _withModifiers(_ctx.goTo, ["prevent"]),
                          disabled: 
                    (_ctx.groupData && _ctx.groupData.baskets.length === 0 && _ctx.dishes.length === 0) || !!_ctx.remainingCartPrice
                  ,
                          id: "proceed_to_checkout"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.groupData && _ctx.groupData.baskets.length === 0 && _ctx.dishes.length === 0)
                              ? (_openBlock(), _createElementBlock("strong", _hoisted_63, "No items added"))
                              : (_openBlock(), _createElementBlock("strong", _hoisted_64, "Check out to update order"))
                          ]),
                          _: 1
                        }, 8, ["onClick", "disabled"]),
                        _createVNode(_component_app_button, {
                          onClick: _withModifiers(_ctx.revertAmendedChanges, ["prevent"]),
                          class: "revert-changes-button"
                        }, {
                          default: _withCtx(() => _cache[17] || (_cache[17] = [
                            _createElementVNode("strong", null, "Revert changes", -1)
                          ])),
                          _: 1
                        }, 8, ["onClick"])
                      ])
                    ]),
                    _: 1
                  }))
                : (_ctx.groupData && _ctx.groupRole === 'Participant' && !_ctx.checkout)
                  ? (_openBlock(), _createBlock(_component_FormScoring, {
                      key: 3,
                      disabled: _ctx.areItemsConfirmed
                    }, {
                      footer: _withCtx(() => [
                        (_ctx.areItemsConfirmed)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                              _cache[18] || (_cache[18] = _createElementVNode("p", { class: "items-confirmed-info-header" }, "What happens now?", -1)),
                              _createElementVNode("p", _hoisted_66, _toDisplayString(`Your items have been added to ${_ctx.groupOwnerWithApostrophe} order.`) + " We’ll let you know when the order has been placed. ", 1)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_67, [
                          _createVNode(_component_app_button, {
                            onClick: _withModifiers(_ctx.confirmBasketItems, ["prevent"]),
                            disabled: _ctx.areItemsConfirmed || !_ctx.dishes.length || _ctx.hasDeadlinePassed || _ctx.isOrderNotActive,
                            id: "proceed_to_checkout",
                            class: "items-confirmed-button",
                            loader: _ctx.isConfirming
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.confirmButtonText), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick", "disabled", "loader"])
                        ])
                      ]),
                      _: 1
                    }, 8, ["disabled"]))
                  : (!_ctx.checkout)
                    ? (_openBlock(), _createBlock(_component_FormScoring, { key: 4 }, {
                        footer: _withCtx(() => [
                          _createElementVNode("div", _hoisted_68, [
                            _cache[19] || (_cache[19] = _createElementVNode("h3", { class: "c-ham" }, "Total", -1)),
                            _createElementVNode("div", _hoisted_69, [
                              _createElementVNode("h3", _hoisted_70, "£" + _toDisplayString(_ctx.total), 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_71, [
                            (_ctx.isTooFar)
                              ? (_openBlock(), _createBlock(_component_app_button, {
                                  key: 0,
                                  class: "restaurant-too-far-button",
                                  onClick: _withModifiers(_ctx.showSetAddressModal, ["prevent"])
                                }, {
                                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                                    _createTextVNode(" Restaurant is too far ")
                                  ])),
                                  _: 1
                                }, 8, ["onClick"]))
                              : (_openBlock(), _createBlock(_component_app_button, {
                                  key: 1,
                                  onClick: _withModifiers(_ctx.goTo, ["prevent"]),
                                  disabled: 
                    (_ctx.groupData && _ctx.groupData.baskets.length === 0 && _ctx.dishes.length === 0) ||
                    _ctx.restaurant.isClosed ||
                    _ctx.restaurant.isTemporarilyClose ||
                    (_ctx.restaurant.deliveryLabel && _ctx.type === 'delivery') ||
                    !!_ctx.remainingCartPrice
                  ,
                                  id: "proceed_to_checkout"
                                }, {
                                  default: _withCtx(() => [
                                    (_ctx.groupData && _ctx.groupData.baskets.length === 0 && _ctx.dishes.length === 0)
                                      ? (_openBlock(), _createElementBlock("strong", _hoisted_72, " No items added "))
                                      : (_ctx.restaurant.isTemporarilyClose)
                                        ? (_openBlock(), _createElementBlock("strong", _hoisted_73, "Back soon"))
                                        : (_ctx.restaurant.isClosed)
                                          ? (_openBlock(), _createElementBlock("strong", _hoisted_74, "Restaurant closed"))
                                          : (_ctx.restaurant.deliveryLabel && _ctx.type === 'delivery')
                                            ? (_openBlock(), _createElementBlock("strong", _hoisted_75, _toDisplayString(_ctx.restaurant.deliveryLabel), 1))
                                            : (_openBlock(), _createElementBlock("strong", _hoisted_76, "Proceed to checkout"))
                                  ]),
                                  _: 1
                                }, 8, ["onClick", "disabled"]))
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_77, [
            (_ctx.restaurant && !_ctx.restaurant.isClosed && !_ctx.restaurant.isTemporarilyClose)
              ? (_openBlock(), _createBlock(_component_UnavailableDeliveryBanner, {
                  key: 0,
                  restaurant: _ctx.restaurant
                }, null, 8, ["restaurant"]))
              : _createCommentVNode("", true),
            (_ctx.restaurant && !_ctx.amendableData && (!_ctx.groupData || _ctx.checkout))
              ? (_openBlock(), _createBlock(_component_OrderSettingsButton, {
                  key: 1,
                  restaurant: _ctx.restaurant,
                  basketTotal: +_ctx.cartPrice,
                  disabled: 
            _ctx.orderSettingsDisabled ||
            _ctx.checkout ||
            (_ctx.restaurant && !_ctx.restaurant.allowScheduledOrders && !_ctx.restaurant.clickAndCollect)
          
                }, null, 8, ["restaurant", "basketTotal", "disabled"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_78, [
              _createVNode(_component_IconEmptyBasket),
              _cache[21] || (_cache[21] = _createElementVNode("h2", null, [
                _createTextVNode("Empty basket."),
                _createElementVNode("br"),
                _createTextVNode("Empty belly.")
              ], -1))
            ])
          ]))
    ])
  ]))
}