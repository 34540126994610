<template>
  <div class="controller-text-accordion-wrapper">
    <div class="controller-text-accordion-link custom-cursor-hover" :class="{ close, isGTM }" @click="close = !close">
      <IconServices icon="add" :color="isGTM ? 'black' : 'dark-ham'" :close="close" />
      <p class="td-u us-n">{{ title }}</p>
    </div>
    <slot v-if="close" />
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

import IconServices from '@/shared/icons/IconServices.vue';

class Props {
  initialCloseValue = prop<boolean>({ required: false, default: false });
}

@Options({
  components: { IconServices },
  props: { title: String, initialCloseValue: Boolean, isGTM: Boolean },
})
export default class AppControllerTextAccordion extends Vue.with(Props) {
  private close = false;

  mounted() {
    this.close = this.initialCloseValue;
  }
}
</script>

<style lang="scss" scoped>
.controller-text-accordion {
  &-wrapper {
    width: 100%;
    svg {
      width: 15px;
      height: 15px;
      margin-right: 8px;
    }
  }

  &-link {
    display: flex;
    align-items: center;

    p {
      color: $darkHam;
      font-weight: 600;
    }
  }

  &-link.isGTM {
    justify-content: flex-end;

    p {
      color: black;
    }
  }
}
</style>
