import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/order_tracking/chevron_right.png'


const _hoisted_1 = { class: "amend-order-init-modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "amend-order-init-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _createVNode(_component_app_button, {
        class: "amend-order-init-modal-close",
        mini: "",
        white: "",
        onClick: _withModifiers(_ctx.close, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconServices, {
            class: "services-icon",
            icon: "add",
            close: ""
          })
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Amending your basket?", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("u", null, [
          _createElementVNode("p", null, "A couple of bits to know")
        ], -1)),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "amend-order-init-bullet-point" }, [
          _createElementVNode("div", { class: "bullet-point-box" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            })
          ]),
          _createElementVNode("p", null, "You can edit your basket up to 2 hours from your delivery time")
        ], -1)),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "amend-order-init-bullet-point" }, [
          _createElementVNode("div", { class: "bullet-point-box" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            })
          ]),
          _createElementVNode("p", null, "For any additional items, you’ll need to proceed to checkout and pay")
        ], -1)),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "amend-order-init-bullet-point" }, [
          _createElementVNode("div", { class: "bullet-point-box" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            })
          ]),
          _createElementVNode("p", null, "We will refund any items you’ve removed within 3-5 days")
        ], -1)),
        _createVNode(_component_app_button, {
          class: "custom-button",
          onClick: _withModifiers(_ctx.initAmendingOrder, ["prevent"])
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Edit my basket")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 16))
}