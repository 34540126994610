<template>
  <div v-if="groupBaskets.length">
    <div v-for="(groupBasket, index) in groupBaskets" :key="index">
      <u>
        <p>
          {{ `${addApostrophe(groupBasket.userName)} items` }}
        </p>
      </u>
      <div v-for="(dish, index) in groupBasket.cart.dishes" :key="index">
        <div class="price-row">
          <div style="display: flex">
            <p style="width: 30px">
              <b>{{ dish.amount }}x</b>
            </p>
            <p>
              <b>{{ dish.name }}</b>
            </p>
          </div>
          <p>
            <b>£{{ (dish.amount * dish.price).toFixed(2) }}</b>
          </p>
        </div>
        <div v-for="(modificator, index) in dish.modificators" :key="index" class="price-row" style="margin-left: 30px">
          <div style="display: flex">
            <p style="width: 30px">{{ dish.amount * modificator.amount }}x</p>
            <p>{{ modificator.name }}</p>
          </div>
          <p>£{{ (modificator.amount * dish.amount * modificator.price).toFixed(2) }}</p>
        </div>
        <p class="item-note" v-if="dish.note">"{{ dish.note }}"</p>
        <div v-if="index < groupBasket.cart.dishes.length - 1" class="coal-border" />
      </div>
      <div v-if="index < groupBaskets.length - 1" style="height: 15px" />
    </div>
  </div>
  <div v-else v-for="(dish, index) in cart.dishes" :key="index">
    <div class="price-row">
      <div style="display: flex">
        <p style="width: 30px">
          <b>{{ dish.amount }}x</b>
        </p>
        <p>
          <b>{{ dish.name }}</b>
        </p>
      </div>
      <p>
        <b>£{{ (dish.amount * dish.price).toFixed(2) }}</b>
      </p>
    </div>
    <div v-for="(modificator, index) in dish.modificators" :key="index" class="price-row" style="margin-left: 30px">
      <div style="display: flex">
        <p style="width: 30px">{{ dish.amount * modificator.amount }}x</p>
        <p>{{ modificator.name }}</p>
      </div>
      <p>£{{ (modificator.amount * dish.amount * modificator.price).toFixed(2) }}</p>
    </div>
    <p class="item-note" v-if="dish.note">"{{ dish.note }}"</p>
    <div v-if="index < cart.dishes.length - 1" class="coal-border" />
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

import pipes from '@/utils/pipes';

class Props {
  cart = prop<OrderCart>({ required: true });
  groupBaskets = prop<GroupOrderBasket[]>({ required: false, default: [] });
}

@Options({ props: { cart: Object, groupBaskets: Array } })
export default class TrackedOrderItems extends Vue.with(Props) {
  private addApostrophe(name: string) {
    return pipes.addApostrophe(name);
  }
}
</script>

<style lang="scss" scoped>
p,
b {
  font-family:
    Sofia Pro,
    sans-serif;
}

.price-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;

  div b,
  b,
  p {
    font-size: 15px;
  }
}

.item-note {
  font-size: 12px;
  line-height: 15px;
  font-style: italic;
}

.coal-border {
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid $coal20;
}
</style>
