<template>
  <VueDatePicker
    class="calendarV1"
    :class="{ absolute_position: !intervalsByDays }"
    :model-value="dateTime"
    @update:model-value="setDateTime"
    @internal-model-change="(d) => (selectedDate = d)"
    :enable-time-picker="false"
    :min-date="minDateTime"
    :max-date="maxDateTime"
    :month-change-on-scroll="false"
    :config="{ onClickOutside }"
    inline
  >
    <template #month-year="{ month, year, handleMonthYearChange }">
      <div class="month_year_header">
        <div class="arrow custom-cursor-hover" @click="handleMonthYearChange(false)">
          <img src="../assets/calendar/calendar_left.png" alt="" />
        </div>
        <h3>{{ monthsList[month] }} {{ year }}</h3>
        <div class="arrow custom-cursor-hover" @click="handleMonthYearChange(true)">
          <img src="../assets/calendar/calendar_right.png" alt="" />
        </div>
      </div>
    </template>
    <template #action-row="{ selectDate }">
      <div class="time_picker_footer">
        <div v-if="!intervalsByDays" class="time_picker_hour_selector">
          <div class="arrow custom-cursor-hover" @click="handleTimeChange(false)">
            <img src="../assets/calendar/calendar_left.png" alt="" />
          </div>
          <div class="time_picker_core">
            <p>{{ formattedTime }}</p>
          </div>
          <div class="arrow custom-cursor-hover" @click="handleTimeChange(true)">
            <img src="../assets/calendar/calendar_right.png" alt="" />
          </div>
        </div>
        <div v-else class="time_picker_interval_selector">
          <app-select :options="selectedDateIntervals" v-model="selectedInterval" />
        </div>
        <app-button :disabled="selectedInterval === 'Unavailable'" class="confirm_button" slim @click="selectDate">
          Confirm
        </app-button>
      </div>
    </template>
  </VueDatePicker>
</template>

<script lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from 'moment-timezone';
import { watch } from 'vue';
import { Options, Vue, prop } from 'vue-class-component';

import { TypeMonths } from '@/utils/date';

class Props {
  dateTime = prop<moment.Moment>({ required: true });
  interval = prop<string>({ required: false });
  intervalsByDays = prop<string[][]>({ required: false });
  onConfirmClick = prop<Function>({ required: true });
  onClickOutside = prop<Function>({ required: false });
}

@Options({
  components: { VueDatePicker },
  props: {
    dateTime: Object,
    interval: String,
    intervalsByDays: Array,
    onConfirmClick: Function,
    onClickOutside: Function,
  },
})
export default class Calendar extends Vue.with(Props) {
  private minDateTime = moment()
    .tz('Europe/London')
    .set({
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })
    .toDate();
  private maxDateTime = this.intervalsByDays
    ? moment(this.minDateTime)
        .tz('Europe/London')
        .add(this.intervalsByDays.length - 1, 'days')
        .toDate()
    : null;

  private selectedDate = moment(this.dateTime).tz('Europe/London');
  private selectedInterval = this.interval?.toString();
  private selectedDateIntervals: string[] = [];

  private selectedHour = this.dateTime.hours();
  private selectedMinute = this.dateTime.minutes();

  private monthsList: TypeMonths[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // HOUR PICKER VARIANT: START
  get formattedTime() {
    return moment().tz('Europe/London').set({ hours: this.selectedHour, minutes: this.selectedMinute }).format('HH:mm');
  }

  private handleTimeChange(isForward: boolean) {
    const tmp = moment().tz('Europe/London').set({ hours: this.selectedHour, minutes: this.selectedMinute });
    if (isForward) tmp.add(30, 'minutes');
    else tmp.subtract(30, 'minutes');

    this.selectedHour = tmp.hours();
    this.selectedMinute = tmp.minutes();
  }
  // HOUR PICKER VARIANT: STOP

  // INTERVAL PICKER VARIANT: START
  private refreshSelectedDateIntervals() {
    if (!this.intervalsByDays) return;

    const diffInDays = moment(this.selectedDate).diff(this.minDateTime, 'days');
    this.selectedDateIntervals = this.intervalsByDays[diffInDays];
  }
  // INTERVAL PICKER VARIANT: STOP

  private setDateTime(value: Date) {
    const newDateTime = moment(value).tz('Europe/London').set({
      hours: this.selectedHour,
      minutes: this.selectedMinute,
      seconds: 0,
      milliseconds: 0,
    });

    if (!this.intervalsByDays) {
      this.onConfirmClick(newDateTime);
      return;
    }

    if (this.selectedInterval === 'Now') {
      this.onConfirmClick(this.minDateTime, 'Now');
    } else {
      this.onConfirmClick(newDateTime, this.selectedInterval);
    }
  }

  mounted() {
    if (!this.intervalsByDays) return;

    watch(
      () => this.selectedDate,
      () => {
        this.refreshSelectedDateIntervals();
        this.selectedInterval =
          this.selectedDateIntervals.find((e) => e === this.selectedInterval) || this.selectedDateIntervals[0];
      },
    );
  }
}
</script>

<style lang="scss">
.calendarV1 {
  &.absolute_position.dp__main {
    position: absolute;
    z-index: 110;
    top: 45px;
  }

  .dp__menu {
    box-shadow: 0px 4px 4px 0px #00000040;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 350px;
    max-width: 90vw;
    padding: 15px;
  }

  // Month and year: START
  .dp__month_year_row {
    height: unset;
    margin-bottom: 15px;
  }

  .month_year_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  // Month and year: STOP

  // Weekday names: START
  .dp__calendar_header_item {
    @include p3;
    color: black;
  }
  // Weekday names: STOP

  .dp__calendar_header_separator {
    display: none;
  }

  // Days: START
  .dp__cell_inner {
    @include p2;
    color: #4d4d4d;
  }

  .dp__active_date {
    background: $ham;
    color: black;
    border: none;
  }

  .dp__cell_disabled,
  .dp__cell_offset {
    color: var(--dp-secondary-color);
  }
  // Days: STOP

  // Footer with time picker: START
  .time_picker_footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .time_picker_hour_selector {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .time_picker_core {
        height: 40px;
        width: 70px;
        background-color: $ham;
        border: 0.9px solid #21212133;
        border-radius: 10px;

        display: flex;
        justify-content: center;
        align-items: center;

        p {
          @include p2;
        }
      }

      .arrow {
        width: 25px;
        height: 25px;
      }
    }

    .confirm_button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #00000033;
      border-radius: 7.5px;
      height: 35px;
      width: 95px;
      min-width: 95px;

      p {
        font-size: 15px;
        height: 18px;
      }
    }
  }
  // Footer with time picker: STOP
}
</style>
