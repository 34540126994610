<template>
  <small class="status" :class="title">{{ title }}</small>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  status = prop<string>({ required: true });
}

@Options({ props: { status: String } })
export default class StatusOrder extends Vue.with(Props) {
  get title() {
    switch (this.status) {
      case 'new':
        return 'new';
      case 'ready_for_delivery':
        return 'ready';
      case 'preparing':
        return 'preparing';
      default:
        return this.status;
    }
  }
}
</script>

<style lang="scss" scoped>
.status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: $lime100;
  text-transform: uppercase;
  border: 1px solid $lime80;
  background-color: $lime60;
  border-radius: 6px;
  font-size: 11px;
  line-height: 18px;
  padding: 4px 8px 0 8px;
  width: auto;
  min-width: 75px;
  &.refunded {
    border-color: $beetroot80;
    background-color: $beetroot60;
    color: $beetroot100;
  }
}
</style>
