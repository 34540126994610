import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "enter-phone"
}
const _hoisted_2 = {
  key: 1,
  class: "verification"
}
const _hoisted_3 = { class: "verification__inputs" }
const _hoisted_4 = { class: "error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "create-account-modal" }, _ctx.options), {
    default: _withCtx(() => [
      (_ctx.step === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[15] || (_cache[15] = _createElementVNode("h2", { class: "ta-c" }, "Enter phone number", -1)),
            _cache[16] || (_cache[16] = _createElementVNode("p", { class: "text" }, "Please enter your phone number. We'll then text you a code to verify your number.", -1)),
            _createVNode(_component_app_input, {
              class: "input",
              value: _ctx.phone,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone) = $event)),
              showError: _ctx.show,
              id: "phone_number"
            }, _createSlots({
              label: _withCtx(() => [
                _cache[13] || (_cache[13] = _createTextVNode("Telephone number"))
              ]),
              _: 2
            }, [
              (_ctx.yupErrMsg('phone'))
                ? {
                    name: "error",
                    fn: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.yupErrMsg('phone')), 1)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["value", "showError"]),
            _createVNode(_component_app_button, {
              class: "g-recaptcha",
              "data-sitekey": "6Lf7Qm4eAAAAAHsyhY_nPcKoSi1dUCQDAhblLx_j",
              "data-callback": "beginPhoneVerification",
              "data-action": "submit",
              loader: _ctx.loader,
              onClick: _ctx.beginPhoneVerification,
              id: "create_account"
            }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode(" Send verification code ")
              ])),
              _: 1
            }, 8, ["loader", "onClick"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.step === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[18] || (_cache[18] = _createElementVNode("h2", { class: "ta-c" }, "Submit verification", -1)),
            _createElementVNode("p", null, "We've sent a code to " + _toDisplayString(_ctx.phone) + ".", 1),
            _cache[19] || (_cache[19] = _createElementVNode("p", null, "Please enter the code below to verify your account.", -1)),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_app_input, {
                value: _ctx.code[0],
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.code[0]) = $event)),
                showError: _ctx.show,
                id: "code",
                class: "verification__input",
                maxlength: "1",
                onInput: _cache[2] || (_cache[2] = (e) => _ctx.focusOnInput(e, 'code-input-2'))
              }, null, 8, ["value", "showError"]),
              _createVNode(_component_app_input, {
                value: _ctx.code[1],
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.code[1]) = $event)),
                showError: _ctx.show,
                id: "code-input-2",
                class: "verification__input",
                maxlength: "1",
                onInput: _cache[4] || (_cache[4] = (e) => _ctx.focusOnInput(e, 'code-input-3'))
              }, null, 8, ["value", "showError"]),
              _createVNode(_component_app_input, {
                value: _ctx.code[2],
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.code[2]) = $event)),
                showError: _ctx.show,
                id: "code-input-3",
                class: "verification__input",
                maxlength: "1",
                onInput: _cache[6] || (_cache[6] = (e) => _ctx.focusOnInput(e, 'code-input-4'))
              }, null, 8, ["value", "showError"]),
              _createVNode(_component_app_input, {
                value: _ctx.code[3],
                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.code[3]) = $event)),
                showError: _ctx.show,
                id: "code-input-4",
                class: "verification__input",
                maxlength: "1",
                onInput: _cache[8] || (_cache[8] = (e) => _ctx.focusOnInput(e, 'code-input-5'))
              }, null, 8, ["value", "showError"]),
              _createVNode(_component_app_input, {
                value: _ctx.code[4],
                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.code[4]) = $event)),
                showError: _ctx.show,
                id: "code-input-5",
                class: "verification__input",
                maxlength: "1",
                onInput: _cache[10] || (_cache[10] = (e) => _ctx.focusOnInput(e, 'code-input-6'))
              }, null, 8, ["value", "showError"]),
              _createVNode(_component_app_input, {
                value: _ctx.code[5],
                "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.code[5]) = $event)),
                showError: _ctx.show,
                id: "code-input-6",
                class: "verification__input",
                maxlength: "1"
              }, null, 8, ["value", "showError"])
            ]),
            (!_ctx.signUpData?.phone && !_ctx.updatePhone)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
                }, "Go back"))
              : _createCommentVNode("", true),
            _createVNode(_component_app_button, {
              onClick: _ctx.checkCodeAndLink,
              loader: _ctx.loader,
              disabled: _ctx.loader
            }, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [
                _createTextVNode("Verify")
              ])),
              _: 1
            }, 8, ["onClick", "loader", "disabled"]),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.codeErrorMessage), 1)
          ]))
        : _createCommentVNode("", true),
      _cache[20] || (_cache[20] = _createElementVNode("div", {
        class: "recaptcha-container",
        id: "recaptcha-container"
      }, null, -1))
    ]),
    _: 1
  }, 16))
}