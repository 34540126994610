<template>
  <app-modal class="thanks-modal" v-bind="options">
    <h2>Thank you!</h2>
    <p v-if="data">{{ data }}</p>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

@Options({
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class AppModalThanks extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: false,
  };
}
</script>
