import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "19",
  height: "19",
  viewBox: "0 0 19 19",
  fill: "none"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M16.7501 13.4401V15.6901C16.7509 15.8989 16.7081 16.1057 16.6245 16.2971C16.5408 16.4885 16.418 16.6603 16.2641 16.8015C16.1102 16.9427 15.9285 17.0502 15.7306 17.1171C15.5328 17.184 15.3231 17.2089 15.1151 17.1901C12.8072 16.9393 10.5903 16.1507 8.64257 14.8876C6.83044 13.7361 5.29407 12.1997 4.14257 10.3876C2.87506 8.43098 2.08625 6.20332 1.84007 3.88507C1.82133 3.67767 1.84598 3.46864 1.91245 3.27129C1.97892 3.07394 2.08575 2.89259 2.22615 2.73879C2.36654 2.58499 2.53743 2.4621 2.72792 2.37796C2.9184 2.29382 3.12433 2.25027 3.33257 2.25007H5.58257C5.94655 2.24649 6.29942 2.37538 6.57539 2.61272C6.85137 2.85006 7.03163 3.17966 7.08257 3.54007C7.17754 4.26012 7.35366 4.96712 7.60757 5.64757C7.70848 5.91602 7.73032 6.20776 7.6705 6.48823C7.61069 6.76871 7.47172 7.02616 7.27007 7.23007L6.31757 8.18257C7.38524 10.0602 8.93991 11.6149 10.8176 12.6826L11.7701 11.7301C11.974 11.5284 12.2314 11.3895 12.5119 11.3296C12.7924 11.2698 13.0841 11.2917 13.3526 11.3926C14.033 11.6465 14.74 11.8226 15.4601 11.9176C15.8244 11.969 16.1571 12.1525 16.395 12.4332C16.6328 12.7139 16.7592 13.0723 16.7501 13.4401Z",
      stroke: "black",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}