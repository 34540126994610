import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../assets/simple_arrow_bottom.svg'


const _hoisted_1 = {
  key: 0,
  class: "order-settings-arrow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.cityName)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        type: "button",
        class: "enter-address-button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showSetAddressModal && _ctx.showSetAddressModal(...args)))
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("span", null, "Enter address for availability", -1),
        _createElementVNode("div", { class: "restaurant-header-address-button-arrow" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "arrow bottom"
          })
        ], -1)
      ])))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(`order-settings ${_ctx.orderSettingsColor} ${_ctx.disabled ? 'disabled' : ''}`),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showOrderSettings && _ctx.showOrderSettings(...args)))
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.orderSettingsText), 1),
        (!_ctx.disabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[3] || (_cache[3] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "arrow bottom"
              }, null, -1)
            ])))
          : _createCommentVNode("", true)
      ], 2))
}