<template>
  <button @click.prevent="goTo()" class="restaurant-btn-back td-n">
    <div class="icon-wrapper">
      <IconArrow width="21" />
    </div>
    <span v-if="!mini" :class="bold ? 'bold' : ''">{{ title }}</span>
  </button>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

import IconArrow from '@/shared/icons/IconArrow.vue';

class Props {
  link = prop<string>({ required: true });
}

@Options({
  components: {
    IconArrow,
  },
  props: {
    mini: Boolean,
    title: {
      type: String,
      default: 'Back',
    },
    link: String,
    bold: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AppBackLink extends Vue.with(Props) {
  private goTo() {
    if (this.link) this.$router.push(this.link);
    else this.$router.go(-1);
  }
}
</script>

<style lang="scss">
.restaurant-btn-back {
  .icon-wrapper {
    border-radius: 10px;
    background-color: $white;
    &:hover {
      background-color: $snow;
    }
  }
  span {
    color: $coal100;
  }
  .bold {
    font-weight: 600;
    font-size: 22px;

    @media (max-width: 1024px) {
      font-size: 18px;
    }
  }
}
</style>
