import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/oops.gif'


const _hoisted_1 = { class: "oops-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "empty" }, null, -1)),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "oops-info" }, [
      _createElementVNode("p", null, [
        _createElementVNode("b", null, "ERROR 404")
      ]),
      _createElementVNode("h1", null, [
        _createTextVNode(" Dang. Something’s "),
        _createElementVNode("br"),
        _createTextVNode(" gone very wrong... ")
      ]),
      _createElementVNode("div", { class: "oops-gif" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        })
      ])
    ], -1)),
    _createVNode(_component_app_button, {
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$router.push('/')), ["prevent"]))
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode(" Get me outta here ")
      ])),
      _: 1
    })
  ]))
}