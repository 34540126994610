import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loader restaurant-loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Mobile = _resolveComponent("Mobile")!
  const _component_Desktop = _resolveComponent("Desktop")!
  const _component_router_view = _resolveComponent("router-view")!

  return (!_ctx.restaurantData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Logo, { class: "logo-loader" })
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.isMobile)
          ? (_openBlock(), _createBlock(_component_Mobile, {
              key: 0,
              restaurantData: _ctx.restaurantData
            }, null, 8, ["restaurantData"]))
          : (_openBlock(), _createBlock(_component_Desktop, {
              key: 1,
              restaurant: _ctx.restaurantData.restaurant
            }, null, 8, ["restaurant"])),
        _createVNode(_component_router_view, {
          restaurantData: _ctx.restaurantData,
          updating: _ctx.updating
        }, null, 8, ["restaurantData", "updating"])
      ], 64))
}