<template>
  <div v-if="orders.length > 0" class="orders-list" :class="{ 'scroll-padding': orders.length > 10 }">
    <Order :order="order" v-for="order in orders" :key="order.id" />
  </div>

  <div class="no-orders" v-else>
    <h1>No orders</h1>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import Order from '@/components/Account/Order.vue';

import orders from '@/requests/orders';

@Options({
  components: {
    Order,
  },
})
export default class MyOrders extends Vue {
  private orders = [];

  created() {
    orders
      .getOrders()
      .then((res: any) => {
        this.orders = res;
      })
      .catch(() => this.$router.push('/oops'));
  }
}
</script>

<style lang="scss" scoped>
.orders-list {
  max-height: 730px;
  height: 100%;
  overflow-y: auto;
  &.scroll-padding {
    padding-right: 10px;
  }
  .order-item {
    padding-top: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid $coal20;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.no-orders {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  h1 {
    color: $coal40;
  }
}
</style>
