<template>
  <div class="restaurant-item-wrapper custom-cursor-hover" :class="{ sold: dish.sold | disabled }">
    <div class="restaurant-item-info">
      <p class="restaurant-item-title">{{ dish.name }}</p>
      <small
        v-if="dish.minHoursInAdvance && !isDeliveryLateEnough"
        class="restaurant-item-in-advance-time"
        :class="{ catererTime: isCaterer }"
      >
        {{ dish.minHoursInAdvance }} extra {{ dish.minHoursInAdvance === 1 ? 'hour' : 'hours ' }} required to order
      </small>
      <small v-if="dish.description" class="restaurant-item-descriptor">
        {{ dish.description }}
      </small>
      <div v-if="isCaterer" class="restaurant-item-price catererPrice">
        <p>Add for&nbsp;</p>
        <p v-if="dish.discountedPrice">£{{ dish.discountedPrice.toFixed(2) }}</p>
        <p v-if="dish.discountedPrice" class="no-underline">&nbsp;(</p>
        <p :class="{ 'td-lt': dish.discountedPrice }">£{{ dish.price.toFixed(2) }}</p>
        <p v-if="dish.discountedPrice" class="no-underline">)</p>
      </div>
      <div v-else class="restaurant-item-price">
        <p :class="{ 'td-lt': dish.discountedPrice }">£{{ dish.price.toFixed(2) }}</p>
        <p class="discounted" v-if="dish.discountedPrice">£{{ dish.discountedPrice.toFixed(2) }}</p>
      </div>
    </div>

    <div v-if="!isCaterer" class="restaurant-item-photo-wrapper">
      <div class="restaurant-item-photo">
        <p class="restaurant-item-count" v-if="count">
          <b>{{ count }}</b>
        </p>
        <img
          v-if="dish.photo && dish.photo !== 'https://storage.googleapis.com/foodstufff/default/stub.svg'"
          :src="dish.photo"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

import methods from '@/utils/methods';

class Props {
  dish = prop<Product>({ required: true });
  disabled = prop<boolean>({ required: true });
  categoryMinHoursInAdvance = prop<number>({ required: true });
}

@Options({
  props: {
    dish: Object,
    disabled: Boolean,
    count: {
      type: Number,
      default: 0,
    },
    categoryMinHoursInAdvance: Number,
    isCaterer: Boolean,
  },
})
export default class RestaurantItem extends Vue.with(Props) {
  get isDeliveryLateEnough() {
    const minHoursInAdvance = this.categoryMinHoursInAdvance + (this.dish.minHoursInAdvance || 0);
    return methods.isDeliveryTimeLateEnough(minHoursInAdvance);
  }
}
</script>

<style lang="scss">
.restaurant-item {
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $coal10;
    min-height: 125px;
    padding-bottom: 9px;
    &.remove-border {
      border-bottom: none;
    }

    &.sold {
      opacity: 0.5;
    }
  }

  &-info {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'title'
      'descriptor'
      'price';
    height: 100%;
    width: 100%;
    min-height: 110px;
    margin-right: 15px;
    &.vertical {
      width: 100%;
      margin-right: 0;
      grid-template-rows: 26px 1fr;
      grid-template-areas:
        'title price'
        'descriptor descriptor';
      .restaurant-item-price {
        text-align: right;
      }
    }
  }

  &-title {
    @include p1Bold;
    margin-bottom: 5px;
    grid-area: title;
  }

  &-descriptor {
    @include p3;
    grid-area: descriptor;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 5px;
    height: fit-content;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &-price {
    display: flex;
    align-items: center;
    grid-area: price;

    p {
      @include p1;
      color: $coal60;

      &.discounted {
        margin-left: 5px;
        color: $darkHam;
      }
    }

    &.catererPrice {
      justify-content: end;

      p {
        font-size: 13px;
        text-decoration-line: underline;

        &.no-underline {
          text-decoration-line: none;
        }

        &.td-lt {
          text-decoration-line: line-through;
        }
      }
    }
  }

  &-photo {
    position: relative;
    min-width: 109px;
    min-height: 109px;
    height: 109px;
    width: 109px;
    max-width: 109px;
    max-height: 109px;
    &.vertical {
      min-width: 345px;
      min-height: 345px;
      height: 345px;
      width: 345px;
      max-width: 345px;
      max-height: 345px;
    }
    &-wrapper {
      padding-top: 5px;
      height: 100%;
      &.vertical {
        height: 345px;
        padding: 0;
        margin-bottom: 10px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 15px;
    }
  }

  &-count {
    position: absolute;
    right: 4px;
    top: 4px;
    padding-top: 2px;
    background-color: $ham;
    border-radius: 25px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &-in-advance-time {
    white-space: initial;

    &.catererTime {
      text-align: right;
    }
  }

  &-edit-count {
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      height: 100%;
      .empty {
        height: 20px;
        min-height: 20px;
      }
    }
  }
}
</style>
