import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "navigation-buttons-auth" }
const _hoisted_2 = {
  key: 2,
  class: "nav-account"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_UserDropdown = _resolveComponent("UserDropdown")!
  const _component_NavigationDropdown = _resolveComponent("NavigationDropdown")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.user)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "ws-nw login",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.showAuth('register')), ["prevent"])),
          "aria-label": "Register"
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("p", null, "Create account", -1)
        ])))
      : _createCommentVNode("", true),
    (!_ctx.user)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "ws-nw login",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.showAuth('login')), ["prevent"])),
          "aria-label": "Log in"
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("p", null, "Log in", -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_app_button, {
            mini: "",
            square: "",
            white: "",
            onClick: _ctx.toggleDropdown,
            "aria-label": "account"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconServices, { icon: "account" })
            ]),
            _: 1
          }, 8, ["onClick"]),
          (_ctx.dropdown)
            ? _withDirectives((_openBlock(), _createBlock(_component_NavigationDropdown, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_UserDropdown, {
                    onPush: _ctx.push,
                    isMobileView: _ctx.isMobileView
                  }, null, 8, ["onPush", "isMobileView"])
                ]),
                _: 1
              })), [
                [_directive_click_outside, () => (_ctx.dropdown = false)]
              ])
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}