/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import moment from 'moment-timezone';

const state: StateOrder = {
  type: 'delivery',
  scheduledDeliveryInterval: null,
  scheduledDeliveryDate: null,
};

const mutations = {
  type(state: StateOrder, payload: 'delivery' | 'collection') {
    state.type = payload;
  },
  scheduledDeliveryInterval(state: StateOrder, payload: string | null) {
    state.scheduledDeliveryInterval = payload;
  },
  scheduledDeliveryDate(state: StateOrder, payload: Date | null) {
    state.scheduledDeliveryDate = payload;
  },
};

const actions = {
  //
};

const getters = {
  scheduledDeliveryTimestamp(state: StateOrder) {
    if (!state.scheduledDeliveryInterval || !state.scheduledDeliveryDate) return null;

    const intervalStart = state.scheduledDeliveryInterval.split('-')[0];
    const intervalHours = intervalStart.split(':')[0];
    const intervalMinutes = intervalStart.split(':')[1];

    return moment(state.scheduledDeliveryDate)
      .tz('Europe/London')
      .set({ hour: +intervalHours, minute: +intervalMinutes, second: 0 })
      .toISOString();
  },
  scheduledDeliveryTimestampWorst(state: StateOrder) {
    if (!state.scheduledDeliveryInterval || !state.scheduledDeliveryDate) return null;

    const intervalEnd = state.scheduledDeliveryInterval.split('-')[1];
    const intervalHours = intervalEnd.split(':')[0];
    const intervalMinutes = intervalEnd.split(':')[1];

    return moment(state.scheduledDeliveryDate)
      .tz('Europe/London')
      .set({ hour: +intervalHours, minute: +intervalMinutes, second: 0 })
      .toISOString();
  },
};

export interface StateOrder {
  type: 'delivery' | 'collection';
  scheduledDeliveryInterval: string | null;
  scheduledDeliveryDate: Date | null;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
