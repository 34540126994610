import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-bar-wrapper" }
const _hoisted_2 = {
  id: "search",
  class: "search-bar"
}
const _hoisted_3 = { class: "search-restaurants" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_input = _resolveComponent("app-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        class: "order-settings",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openOrderSettingsModal && _ctx.openOrderSettingsModal(...args)))
      }, [
        _createVNode(_component_IconServices, { icon: "locationLight" }),
        _createElementVNode("span", null, _toDisplayString(_ctx.orderDetailsTitle), 1),
        _createVNode(_component_IconServices, { icon: "pencil" })
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("hr", { class: "hide-desktop" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_app_input, {
          value: _ctx.search,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
          placeholder: "Search restaurants",
          vanilla: ""
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_IconServices, {
              class: "services-icon",
              icon: "search"
            })
          ]),
          _: 1
        }, 8, ["value"])
      ])
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1))
  ]))
}