import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "about-modal-wrapper" }
const _hoisted_3 = { class: "about-modal-info" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "about-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _createVNode(_component_app_button, {
        class: "about-modal-close",
        mini: "",
        white: "",
        onClick: _withModifiers(_ctx.close, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconServices, {
            class: "services-icon",
            icon: "add",
            close: ""
          })
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createElementVNode("img", {
        src: _ctx.data.cover,
        class: "about-modal-cover",
        alt: "restaurant cover"
      }, null, 8, _hoisted_1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, [
          _createVNode(_component_IconServices, { icon: "location" }),
          _createElementVNode("a", {
            href: _ctx.data.googleMapsUrl,
            target: "_blank",
            rel: "noopener"
          }, _toDisplayString(_ctx.data.location) + ", " + _toDisplayString(_ctx.data.nearestStation) + " " + _toDisplayString(_ctx.data.postcode), 9, _hoisted_4)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("h1", null, "Our story", -1)),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "divider ham full-width" }, null, -1)),
        _createElementVNode("p", {
          innerHTML: _ctx.data.description
        }, null, 8, _hoisted_5)
      ])
    ]),
    _: 1
  }, 16))
}