import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-element-item number" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "card-element-item cvc" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "card-element-item expiry" }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", {
          class: _normalizeClass({ invalid: _ctx.cardNumber && _ctx.cardNumber.error && _ctx.showError })
        }, "Card number", 2),
        _cache[0] || (_cache[0] = _createElementVNode("div", {
          id: "card-number",
          class: "card-element"
        }, null, -1)),
        (_ctx.cardNumber && _ctx.cardNumber.error && _ctx.showError)
          ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.cardNumber.error.message), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", {
          class: _normalizeClass({ invalid: _ctx.cardCvc && _ctx.cardCvc.error && _ctx.showError })
        }, "CVC", 2),
        _cache[1] || (_cache[1] = _createElementVNode("div", {
          id: "card-cvc",
          class: "card-element"
        }, null, -1)),
        (_ctx.cardCvc && _ctx.cardCvc.error && _ctx.showError)
          ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.cardCvc.error.message), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", {
          class: _normalizeClass({ invalid: _ctx.cardExpiry && _ctx.cardExpiry.error && _ctx.showError })
        }, "Expiry date", 2),
        _cache[2] || (_cache[2] = _createElementVNode("div", {
          id: "card-expiry",
          class: "card-element"
        }, null, -1)),
        (_ctx.cardExpiry && _ctx.cardExpiry.error && _ctx.showError)
          ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.cardExpiry.error.message), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}