<template>
  <app-modal class="active-group-order-warning" :close="true">
    <h2 class="active-group-order-warning__title">
      {{ groupRole === 'Owner' ? 'You have an active group order' : 'You’re part of a group order' }}
    </h2>
    <p class="active-group-order-warning__description">
      {{ groupRole === 'Owner' ? 'Whilst a group order is active' : 'Whilst part of a group order' }}
      you are unable to change the delivery time and address. You are also unable to view other restaurants and group
      orders.
    </p>

    <div class="active-group-order-warning__buttons-wrapper">
      <app-button class="active-group-order-warning__menu-button" @click.prevent="goToGroupOrderRestaurant">
        View group order menu
      </app-button>
      <app-button class="active-group-order-warning__cancel-button" @click.prevent="confirmCancelGroupOrder">
        {{ groupRole === 'Owner' ? 'Cancel' : 'Leave' }} group order
      </app-button>
    </div>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

@Options({
  computed: {
    ...mapState('groupOrder', ['groupRole']),
  },
})
export default class ActiveGroupOrderWarning extends Vue {
  private async goToGroupOrderRestaurant() {
    if (this.$store.state.groupOrder.groupData) {
      const {
        ownerAddress: { cityName },
        restaurantSlug,
      } = this.$store.state.groupOrder.groupData;
      this.$router.push(`/menu/${cityName.toLowerCase()}/${restaurantSlug}`);
    } else {
      this.$router.push('/');
    }
    this.$store.commit('modals/close');
  }

  private confirmCancelGroupOrder() {
    this.$store.commit('modals/show', 'cancelGroupOrder');
  }
}
</script>

<style lang="scss">
.active-group-order-warning {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    max-width: 425px;
    width: 425px;
  }

  &__title {
    text-align: center;
    margin: 0 50px;
    margin-top: 40px;
  }

  &__description {
    @include p2;
    text-align: center;
    margin-top: 15px;
  }

  &__buttons-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__menu-button {
    margin-top: 30px;
    width: 100%;
    max-width: unset;
  }

  &__cancel-button {
    width: unset;
    background-color: rgba(34, 17, 34, 0.05);
    margin: 0 auto;
    margin-top: 10px;
    min-height: unset;
    padding: 13px 18px;
  }
}
</style>
