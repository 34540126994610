class MethodsDate {
  public changeMonth(m: number): string {
    return m < 10 ? `0${m}` : `${m}`;
  }

  public timeToAMPM(h: number, m: number): string {
    const ampm = h >= 12 ? 'pm' : 'am';
    const hours = h % 12 || 12; // the hour '0' should be '12'
    const minutes = m < 10 ? `0${m}` : m;
    const strTime = `${hours}:${minutes}${ampm}`;
    return strTime;
  }

  public combineIntervalAndDayData(
    scheduledDeliveryInterval: string | null,
    day: Date,
  ): { start: Date; end: Date } | { start: null; end: null } {
    const [bestTime, worstTime] = scheduledDeliveryInterval?.split(' - ') || ['', ''];
    const [bestHour, bestMinute] = bestTime.split(':');
    const [worstHour, worstMinute] = worstTime.split(':');

    if (!bestHour || !worstMinute) return { start: null, end: null };

    const date = new Date(day);

    const hoursOffset = this.getTimezoneOffset() / 60;

    const bestDate = new Date(date?.setHours(Number(bestHour) + hoursOffset, Number(bestMinute), 0, 0));

    const worstDate = new Date(date?.setHours(Number(worstHour) + hoursOffset, Number(worstMinute), 0, 0));

    return { start: bestDate, end: worstDate };
  }

  public getDate(d?: string | Date): InterfaceGetDate {
    let date: string | Date = '';

    const monthsList: TypeMonths[] = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const monthsSmallList: TypeMonths[] = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const daysList: TypeDays[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    if (!d) {
      date = new Date();
    } else {
      date = new Date(d);
    }

    const obj: InterfaceGetDate = {
      day: this.changeMonth(date.getDate()),
      dayText: daysList[date.getDay()],
      month: this.changeMonth(date.getMonth() + 1),
      monthText: monthsList[date.getMonth()],
      monthSmallText: monthsSmallList[date.getMonth()],
      year: date.getFullYear(),

      hours: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds(),

      hoursMinutes: this.timeToAMPM(date.getHours(), date.getMinutes()),
    };

    return obj;
  }

  public getTimezoneOffset(date = new Date()) {
    const myDate = new Date(date.toLocaleString('en-US'));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone: 'Europe/London' }));
    return (myDate.getTime() - tzDate.getTime()) / 6e4;
  }
}

export type TypeMonths =
  | 'January'
  | 'February'
  | 'March'
  | 'April'
  | 'May'
  | 'June'
  | 'July'
  | 'August'
  | 'September'
  | 'October'
  | 'November'
  | 'December'
  | 'Jan'
  | 'Feb'
  | 'Mar'
  | 'Apr'
  | 'May'
  | 'Jun'
  | 'Jul'
  | 'Aug'
  | 'Sep'
  | 'Oct'
  | 'Nov'
  | 'Dec';

export type TypeDays = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';

export interface InterfaceGetDate {
  day: number | string;
  dayText: TypeDays;
  year: number;
  month: number | string;
  hours: number;
  minutes: number;
  seconds: number;
  monthText: string;
  monthSmallText: string;
  hoursMinutes: string;
}

export default new MethodsDate();
