<template>
  <app-modal class="join-group-order" :close="true" @close="cancelJoiningGroupOrder" :doesNotClose="true">
    <form @submit.prevent="joinGroupOrder" class="search_form">
      <h2 class="join-group-order__title">Join {{ groupOwnerWithApostrophe }} group order</h2>
      <p class="join-group-order__description">Add your favourite dishes to the basket then confirm the order.</p>

      <div class="join-group-order__details-wrapper">
        <div class="join-group-order__detail">
          <IconServices icon="locationLight" />
          <p>
            {{ collectDeliverText }}
            <strong>{{
              `${data.groupOrder.ownerAddress.formattedAddress.split(',')[0]}, ${data.groupOrder.ownerAddress.postcode}`
            }}</strong>
          </p>
        </div>
        <div class="join-group-order__detail">
          <IconServices icon="thickClock" />
          <p>
            {{ collectDeliverTimeText }} <br v-if="deliverTime !== 'ASAP'" />
            <strong>{{ deliverTime }}</strong>
          </p>
        </div>
        <div v-if="data.groupOrder.deadline" class="join-group-order__detail">
          <img src="../assets/check.svg" alt="" />
          <p>
            Confirm your items by <strong>{{ deadline }}</strong>
          </p>
        </div>
        <div class="join-group-order__detail">
          <IconServices icon="star" />
          <p>
            The restaurant is <strong>{{ data.groupOrder.restaurantName }}</strong>
          </p>
        </div>
      </div>
      <p v-if="data.groupOrder.instructions" class="join-group-order__instructions-label">
        {{ `Instructions from ${data.groupOrder.ownerName}` }}
      </p>
      <div v-if="data.groupOrder.instructions" class="join-group-order__instructions" id="instructions">
        {{ data.groupOrder.instructions }}
      </div>
      <app-input
        class="join-group-order__name-input"
        title="Add your name"
        v-model:value="participantName"
        id="instructions"
        placeholder="e.g Thomas"
        :required="true"
      >
        <template v-slot:label>Whats your name?</template>
      </app-input>
      <div class="join-group-order__button-wrapper">
        <app-button type="submit" class="join-group-order__button">View menu</app-button>
      </div>
    </form>
  </app-modal>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import groupOrdersHttp from '@/requests/groupOrders';

import IconServices from '@/shared/icons/IconServices.vue';

import methods from '@/utils/methods';
import pipes from '@/utils/pipes';

@Options({
  components: {
    IconServices,
  },
  computed: {
    ...mapState('modals', ['data']),
    ...mapState('groupOrder', ['groupData']),
  },
})
export default class ActiveGroupOrderWarning extends Vue {
  private participantName = '';

  get collectDeliverText() {
    if (this.$store.state.modals.data.groupOrder.orderType === 'delivery') {
      return 'Delivering to';
    }
    return 'Pick up from';
  }

  get collectDeliverTimeText() {
    const { orderType, bestDeliverToTime } = this.$store.state.modals.data.groupOrder;
    const isCollection = orderType === 'collect';
    const isScheduled = !!bestDeliverToTime;

    if (isScheduled) {
      if (isCollection) {
        return 'Scheduled collection';
      }
      return 'Scheduled delivery';
    }

    if (isCollection) {
      return 'Collection today';
    }
    return 'Arriving today';
  }

  get deliverTime() {
    const { bestDeliverToTime, worstDeliverToTime } = this.$store.state.modals.data.groupOrder;
    if (bestDeliverToTime && worstDeliverToTime) {
      const bestTime = moment(bestDeliverToTime).tz('Europe/London');
      const worstTime = moment(worstDeliverToTime).tz('Europe/London');
      return `${bestTime.format('ddd, Do MMM, HH:mm')} - ${worstTime.format('HH:mm')}`;
    }
    return 'ASAP';
  }

  get deadline() {
    const { deadline } = this.$store.state.modals.data.groupOrder;
    return deadline ? moment(deadline).tz('Europe/London').format('ddd Do, HH:mm') : '';
  }

  get groupOwnerWithApostrophe() {
    if (!this.$store.state.modals.data.groupOrder) return '';
    return pipes.addApostrophe(this.$store.state.modals.data.groupOrder.ownerName);
  }

  private async cancelJoiningGroupOrder() {
    this.$router.push('/');
  }

  private async joinGroupOrder() {
    const groupOrder = await groupOrdersHttp.getGroupOrder(this.$store.state.modals.data.groupOrder.id);
    if (!groupOrder) return;

    this.$store.commit('groupOrder/reset');
    this.$store.commit('groupOrder/update', groupOrder);
    this.$store.commit('groupOrder/setRole', 'Participant');
    this.$store.commit('groupOrder/setParticipantName', this.participantName);

    methods.overrideBasketAndOrderData(groupOrder);
    this.$store.commit('basket/clear');
    this.$store.commit('modals/close');

    if (this.$store.state.groupOrder.groupData) {
      const {
        ownerAddress: { cityName },
        restaurantSlug,
      } = this.$store.state.groupOrder.groupData;
      this.$router.push(`/menu/${cityName.toLowerCase()}/${restaurantSlug}`);
    }
  }

  private addApostrophe(name: string) {
    return pipes.addApostrophe(name);
  }
}
</script>

<style lang="scss">
.join-group-order {
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    p {
      font-weight: 400;
    }
  }

  .modal {
    width: 375px;
  }

  &__instructions-label {
    @include p2;
    margin: 0 20px;
    margin-bottom: 6px;
  }

  &__instructions {
    margin: 0 20px;
    padding: 12px 20px;
    margin-bottom: 20px;
    max-width: unset;

    color: rgba(33, 33, 33, 0.5);
    background-color: rgba($color: $coal100, $alpha: 0.05);
    border: 3px solid $coal20;
    border-radius: 15px;

    font-size: 16px;

    font-weight: 600;
    word-break: break-word;

    input {
      font-weight: 600;
    }
  }

  &__name-input {
    padding: 0 20px;
    max-width: unset;
  }

  &__button-wrapper {
    margin-top: 20px;
    padding: 0 20px;
  }

  &__details-wrapper {
    padding: 20px;
  }

  &__detail {
    padding: 10px 0;
    display: flex;
    gap: 12px;
    overflow: ellipsis;

    svg {
      width: 20px;
      height: 20px;
    }

    img {
      width: 20px;
      height: 20px;
      padding-left: 2px;
      padding-right: 2px;
      padding-bottom: 2px;
    }

    strong {
      font-size: 15px;
    }

    p {
      @include p2;
      border-bottom: 1px solid rgba(33, 33, 33, 0.1);
      width: 100%;
      min-height: 27px;
      text-indent: none;
    }
  }

  &__title {
    text-align: center;
    margin: 0 auto;
    margin-top: 25px;
    max-width: 175px;
  }

  &__description {
    @include p2;
    text-align: center;
    margin: 0 auto;
    margin-top: 15px;
    max-width: 266px;
  }
}
</style>
