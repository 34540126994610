<template>
  <app-modal
    :class="{
      'no-modification': dish.modificators.length === 0 && !dish.description,
    }"
    class="dish-modal"
    v-bind="options"
    v-if="dish"
  >
    <app-button class="dish-modal-close" mini white @click.prevent="close">
      <IconServices class="services-icon" icon="add" close />
    </app-button>

    <img
      :src="dish.photo"
      class="dish-modal-cover"
      v-if="dish.photo && dish.photo !== 'https://storage.googleapis.com/foodstufff/default/stub.svg'"
      alt="dish photo"
    />

    <div class="dish-modal-div" v-else />
    <div class="dish-modal-wrapper">
      <h2 class="dish-name">{{ dish.name }}</h2>
      <p>
        {{ truncate(dish.description) }}
      </p>

      <Modifications
        @selected="selected"
        :modification="modification"
        v-for="(modification, mIndex) in dish.modificators"
        :key="mIndex"
      />

      <app-text-accordion style="margin-top: 10px" title="Add a kitchen note?">
        <app-input v-model:value="itemNote" class="animated slideOutDown" />
      </app-text-accordion>

      <app-count :amount="dish?.amount" :setAmount="setAmount" :minusDisabled="!dish || dish.amount === 1" />
    </div>

    <div class="dish-added-wrapper" v-if="!cityName">
      <app-button class="enter-address" @click.prevent="showSetAddressModal">
        Enter address for availability
      </app-button>
    </div>

    <div class="dish-added-wrapper" v-else-if="isTooFar">
      <app-button class="enter-address" @click.prevent="showSetAddressModal">Restaurant is too far</app-button>
    </div>

    <div class="dish-added-wrapper" v-else-if="!isDeliveryLateEnough && amendableData">
      <app-button class="dish-added" disabled>
        <p>
          The restaurant needs<br />
          {{ minHoursInAdvance }} {{ minHoursInAdvance === 1 ? 'hour' : 'hours' }} notice for this item
        </p>
      </app-button>
    </div>

    <div class="dish-added-wrapper" v-else-if="!isDeliveryLateEnough">
      <app-button class="enter-time" @click.prevent="showOrderSettingsModal">
        <p>The restaurant needs {{ minHoursInAdvance }} {{ minHoursInAdvance === 1 ? 'hour' : 'hours' }} notice</p>
        <p>for this item. Please edit delivery date.</p>
      </app-button>
    </div>

    <div
      class="dish-added-wrapper"
      v-else-if="
        !areItemsConfirmed &&
        !data.restaurant.isClosed &&
        !data.restaurant.isTemporarilyClose &&
        !data.category.isClosed &&
        !dish.sold &&
        !data.deliveryLabel
      "
    >
      <app-button :disabled="disabled" class="dish-added" @click.prevent="add(dish)">
        <ItemAdded v-if="itemAdded" />
        Add to basket - £{{ ((getDishPrice + getPriceModifications) * dish.amount).toFixed(2) }}
      </app-button>
    </div>

    <div class="dish-added-wrapper" v-else>
      <app-button
        :disabled="
          areItemsConfirmed ||
          data.restaurant.isClosed ||
          data.restaurant.isTemporarilyClose ||
          data.category.isClosed ||
          dish.sold ||
          data.deliveryLabel
        "
        class="dish-added"
      >
        <span v-if="areItemsConfirmed">You already confirmed your items</span>
        <span v-else-if="data.restaurant.isClosed">Restaurant closed</span>
        <span v-else-if="data.category.isClosed">Item unavailable</span>
        <span v-else-if="dish.sold">Sold out</span>
        <span v-else-if="data.deliveryLabel">{{ data.deliveryLabel }}</span>
        <span v-else>Back soon</span>
      </app-button>
    </div>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import ItemAdded from '@/components/ItemAdded.vue';
import Modifications from '@/components/Restaurant/Modifications.vue';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';
import IconServices from '@/shared/icons/IconServices.vue';

import http from '@/utils/http';
import methods from '@/utils/methods';
import pipes from '@/utils/pipes';

@Options({
  components: {
    Modifications,
    IconServices,
    ItemAdded,
  },
  computed: {
    ...mapState('address', ['cityName']),
    ...mapState('basket', ['isTooFar']),
    ...mapState('modals', ['data']),
    ...mapState('groupOrder', ['groupData', 'groupRole', 'areItemsConfirmed']),
    ...mapState('amendableOrder', { amendableData: 'data' }),
  },
})
export default class ModalDishes extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: false,
  };

  private itemAdded = false;
  private itemNote = '';
  private dish: Product | null = null;

  get disabled() {
    if (!this.dish || !this.dish.modificators?.length) return false;

    // Get all mods that are required:
    const requiredMods = this.dish.modificators.filter((mod) => mod.required);
    if (!requiredMods.length) return false;

    // Ensure that all required mods have been checked:
    let isEnabled = true;
    requiredMods.forEach((modifier) => {
      const selections = modifier.options.reduce((sum, o) => sum + (o.amount || 0), 0);
      if (selections < modifier.limitMin) isEnabled = false;
    });
    return !isEnabled;
  }

  get getDishPrice() {
    if (!this.dish) return 0;
    return this.dish.discountedPrice || this.dish.price;
  }

  get minHoursInAdvance() {
    const categoryMinHoursInAdvance = this.$store.state.modals.data.category?.minHoursInAdvance || 0;
    const dishMinHoursInAdvance = this.dish?.minHoursInAdvance || 0;
    return categoryMinHoursInAdvance + dishMinHoursInAdvance;
  }

  get isDeliveryLateEnough() {
    if (!this.dish) return false;
    return methods.isDeliveryTimeLateEnough(this.minHoursInAdvance);
  }

  private truncate(text: string) {
    return pipes.truncate(text, 800);
  }

  private setAmount(count: number) {
    if (count < 1) return;
    this.dish!.amount = count;
  }

  private async add(dish: Product) {
    const { restaurant } = this.$store.state.modals.data;

    const categoryMinHoursInAdvance = this.$store.state.modals.data.category?.minHoursInAdvance || 0;
    const dishMinHoursInAdvance = this.dish?.minHoursInAdvance || 0;

    if (
      this.$store.state.basket.restaurant === null ||
      this.$route.params.slug === this.$store.state.basket.restaurant.slug
    ) {
      setTimeout(this.close, 500);
      this.itemAdded = true;

      this.$store.commit('basket/restaurant', methods.filterRestaurantInfo(restaurant));
      this.$store.commit('basket/add', {
        ...dish,
        minHoursInAdvance: categoryMinHoursInAdvance + dishMinHoursInAdvance,
        note: this.itemNote,
      });
    } else {
      this.close();
      this.$store.commit('modals/data', {
        dish: {
          ...dish,
          minHoursInAdvance: categoryMinHoursInAdvance + dishMinHoursInAdvance,
          note: this.itemNote,
        },
        newRestaurant: restaurant,
        oldRestaurantName: this.$store.state.basket.restaurant?.name,
      });
      this.$store.commit('modals/show', 'newBasket');
    }
  }

  private selected(modifier: ProductModifier) {
    if (!this.dish) return;

    const index = this.dish.modificators.findIndex((i) => i.id === modifier.id);
    if (index >= 0) this.dish.modificators[index] = modifier;
  }

  get getPriceModifications() {
    if (!this.dish) return 0;

    const selectedOptions = this.dish.modificators.flatMap((mod) => mod.options).filter((o) => o.amount > 0);
    return selectedOptions.reduce((sum, o) => sum + o.price * o.amount, 0);
  }

  private close() {
    document.body.classList.remove('no-scroll');
    this.$store.commit('modals/close');
  }

  private showSetAddressModal() {
    this.close();
    this.$store.commit('modals/data', {
      withOrderTypeSwitch: false,
      withAddressChange: true,
      withDeliveryTimeChange: true,
      restaurant: this.$store.state.modals.data.restaurant,
    });
    this.$store.commit('modals/show', 'orderSettings');
  }

  private showOrderSettingsModal() {
    this.close();
    this.$store.commit('modals/data', {
      withOrderTypeSwitch: true,
      withAddressChange: false,
      withDeliveryTimeChange: true,
      restaurant: this.$store.state.modals.data.restaurant,
    });
    this.$store.commit('modals/show', 'orderSettings');
  }

  created() {
    const data: { dish: Product; category: MenuCategory } = { ...this.$store.state.modals.data };
    document.body.classList.add('no-scroll');

    const { scheduledDeliveryDate } = this.$store.state.order;
    let path = `/menu/${data.category.slug}/dishes/${data.dish.slug}`;
    if (scheduledDeliveryDate) path += `?deliverToTime=${scheduledDeliveryDate}`;

    http.get(path).then((res: { dish: Product }) => {
      res.dish.amount = 1;
      this.dish = { ...res.dish, sold: data.dish.sold };
    });
  }
}

interface RequiredModifications {
  id: number | string;
  status: boolean;
}
</script>

<style lang="scss">
.modal-wrapper.dish-modal {
  padding-bottom: 0;

  @include for-custom-min(501) {
    @include row-centered;
  }

  @include for-custom(501) {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  @include for-old-mobile {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding: 0 !important;
  }

  .controller-count {
    margin-top: 20px;
  }

  @include for-old-mobile {
    padding-bottom: 100px;
  }
}
.dish {
  &-modal {
    &.no-modification {
      .modal {
        max-height: 70%;

        @include for-old-mobile {
          max-height: 100%;
        }
      }
    }
    .modal {
      padding: 0 0 15px 0;
      animation: $modal-show-bottom;

      &.slim {
        max-width: 375px;
        width: 375px;
        @include for-old-mobile {
          width: 100%;
          min-width: 100%;
        }
      }
    }
    .dish-modal-close {
      position: absolute;
      top: 15px;
      left: 15px;
      .close.services-icon {
        top: 9px;
        left: 9px;
        width: 21px;
        height: 21px;
      }
    }
    .dish-added {
      font-weight: 600;
      .item-added {
        position: absolute;
        top: -37px;
        left: calc(50% - (104px / 2));
        height: 27px;
      }
      &-wrapper {
        display: flex;
        width: 100%;
        position: sticky;
        align-items: center;
        justify-content: center;
        left: 0;
        bottom: 0;
        z-index: 10;
      }
    }
    .enter-address,
    .enter-time {
      color: $coal60;
      background-color: $snow;
      border: 1px solid $coal20;
      display: flex;
      flex-direction: column;
    }
    &-cover {
      height: 375px;
      min-height: 375px;
      border-radius: 15px;
      @include for-custom-min(501) {
        width: 375px;
        min-width: 375px;
      }
      @include for-old-mobile {
        width: 100%;
        min-width: 100%;
      }
    }
    &-div {
      height: 60px;
      min-height: 60px;
      border-radius: 15px;
      @include for-custom-min(501) {
        width: 375px;
        min-width: 375px;
      }
      @include for-old-mobile {
        width: 100%;
        min-width: 100%;
      }
    }
    &-wrapper {
      padding: 15px;
      .divider {
        margin-top: 5px;
      }
      .controller-count {
        margin-left: 10px;
      }
    }
    &-title-modifier {
      margin-top: 20px;
    }
  }
  &-name {
    margin-bottom: 10px;
  }
}
</style>
