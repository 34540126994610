<template>
  <Dishes v-if="dishes" />
  <About v-if="about" />
  <OpeningHours v-if="openingHours" />
  <NewBasket v-if="newBasket" />

  <Auth v-if="auth" />
  <CreateAccount v-if="createAccount" />
  <LoginAccount v-if="login" />
  <VerifyPhone v-if="verifyPhone" />
  <ForgotPassword v-if="forgotPassword" />

  <DeleteCard v-if="deleteCard" />
  <Basket v-if="basket" />

  <CreateGroupOrder v-if="createGroupOrder" />
  <ShareGroupOrderLink v-if="shareGroupOrderLink" />
  <ActiveGroupOrderWarning v-if="activeOrderWarning" />
  <CancelGroupOrder v-if="cancelGroupOrder" />
  <JoinGroupOrder v-if="joinGroupOrder" />
  <GroupOrderNotAvailable v-if="groupOrderNotAvailable" />

  <Thanks v-if="thanks" />
  <Error v-if="error" />
  <BasketUpdate v-if="basketUpdate" />
  <OrderSettings v-if="orderSettings" />
  <OrderSettingsDeadline v-if="orderSettingsDeadline" />

  <CheckYourMail v-if="checkYourMail" />
  <TooFar v-if="tooFar" />

  <InvalidAddress v-if="invalidAddress" />
  <MissingAreaGtm v-if="missingAreaGtm" />

  <FeeDetails v-if="feeDetails" />
  <CustomTip v-if="customTip" />
  <DeleteAccount v-if="deleteAccount" />

  <TrackedOrderDetails v-if="trackedOrderDetails" />

  <AmendOrderInit v-if="amendOrderInit" />
  <AmendOrderTooLate v-if="amendOrderTooLate" />
  <GroupOrderWhatNext v-if="groupOrderWhatNext" />
  <ActiveAmendableOrderWarning v-if="activeAmendableOrderWarning" />

  <BusinessUserBenefits v-if="buBenefits" />
  <SharePrefilledBasketLink v-if="sharePrefilledBasketLink" />
  <SetFilters v-if="setFilters" />
  <GtmNewEvent v-if="gtmNewEvent" />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import About from '@/modals/about.vue';
import ActiveAmendableOrderWarning from '@/modals/active-amendable-order-warning.vue';
import ActiveGroupOrderWarning from '@/modals/active-group-order-warning.vue';
import Auth from '@/modals/auth/auth.vue';
import BusinessUserBenefits from '@/modals/auth/business-user-benefits.vue';
import CreateAccount from '@/modals/auth/create-account.vue';
import DeleteAccount from '@/modals/auth/delete-account.vue';
import ForgotPassword from '@/modals/auth/forgot-password.vue';
import LoginAccount from '@/modals/auth/login-account.vue';
import VerifyPhone from '@/modals/auth/verify-phone.vue';
import BasketUpdate from '@/modals/basket-update.vue';
import Basket from '@/modals/basket.vue';
import CheckYourMail from '@/modals/check-your-mail.vue';
import CancelGroupOrder from '@/modals/confirm-cancel-group-order.vue';
import CreateGroupOrder from '@/modals/create-group-order.vue';
import CustomTip from '@/modals/custom-tip.vue';
import DeleteCard from '@/modals/delete-card.vue';
import Dishes from '@/modals/dishes.vue';
import Error from '@/modals/error.vue';
import FeeDetails from '@/modals/fee-details.vue';
import GroupOrderNotAvailable from '@/modals/group-order-not-available.vue';
import GtmNewEvent from '@/modals/gtm-new-event.vue';
import InvalidAddress from '@/modals/invalid-address.vue';
import JoinGroupOrder from '@/modals/join-group-order.vue';
import MissingAreaGtm from '@/modals/missing-area-gtm.vue';
import NewBasket from '@/modals/new-basket.vue';
import OpeningHours from '@/modals/opening-hours.vue';
import OrderSettingsDeadline from '@/modals/order-settings-deadline.vue';
import OrderSettings from '@/modals/order-settings.vue';
import SetFilters from '@/modals/set-filters.vue';
import ShareGroupOrderLink from '@/modals/share-group-order-link.vue';
import SharePrefilledBasketLink from '@/modals/share-prefilled-basket-link.vue';
import Thanks from '@/modals/thanks.vue';
import TooFar from '@/modals/too-far.vue';
import AmendOrderInit from '@/modals/tracking/amend-order-init.vue';
import AmendOrderTooLate from '@/modals/tracking/amend-order-too-late.vue';
import GroupOrderWhatNext from '@/modals/tracking/group-order-what-next.vue';
import TrackedOrderDetails from '@/modals/tracking/tracked-order-details.vue';

@Options({
  components: {
    About,
    ActiveAmendableOrderWarning,
    ActiveGroupOrderWarning,
    AmendOrderInit,
    AmendOrderTooLate,
    Auth,
    Basket,
    BasketUpdate,
    BusinessUserBenefits,
    CancelGroupOrder,
    CheckYourMail,
    CreateAccount,
    CreateGroupOrder,
    CustomTip,
    DeleteAccount,
    DeleteCard,
    Dishes,
    Error,
    FeeDetails,
    ForgotPassword,
    GtmNewEvent,
    GroupOrderNotAvailable,
    GroupOrderWhatNext,
    InvalidAddress,
    JoinGroupOrder,
    LoginAccount,
    MissingAreaGtm,
    NewBasket,
    OpeningHours,
    OrderSettings,
    OrderSettingsDeadline,
    ShareGroupOrderLink,
    SharePrefilledBasketLink,
    SetFilters,
    Thanks,
    TooFar,
    TrackedOrderDetails,
    VerifyPhone,
  },
  computed: {
    ...mapState('modals', [
      'amendOrderInit',
      'amendOrderTooLate',
      'activeAmendableOrderWarning',
      'activeOrderWarning',
      'about',
      'auth',
      'basket',
      'basketUpdate',
      'buBenefits',
      'cancelGroupOrder',
      'checkYourMail',
      'createAccount',
      'createGroupOrder',
      'customTip',
      'deleteAccount',
      'deleteCard',
      'dishes',
      'error',
      'feeDetails',
      'forgotPassword',
      'groupOrderNotAvailable',
      'groupOrderWhatNext',
      'gtmNewEvent',
      'invalidAddress',
      'itemAdded',
      'joinGroupOrder',
      'login',
      'missingAreaGtm',
      'newBasket',
      'orderSettings',
      'orderSettingsDeadline',
      'openingHours',
      'setFilters',
      'shareGroupOrderLink',
      'sharePrefilledBasketLink',
      'thanks',
      'tooFar',
      'trackedOrderDetails',
      'verifyPhone',
    ]),
  },
})
export default class ShowModal extends Vue {}
</script>
