<template>
  <div class="checkout-info">
    <!-- Provided address: START -->
    <div v-if="type === 'delivery'">
      <h2>Deliver to</h2>
      <div class="address-disabled">
        <app-input id="address" :value="formattedAddress">
          <template v-slot:icon>
            <IconServices class="services-icon" icon="location" />
          </template>
        </app-input>
      </div>

      <h2>Delivery options</h2>
      <app-input
        v-model:value="additionalAddressDetails"
        placeholder="i.e Flat 310, Bailey House"
        id="additionalDetails"
        maxlength="99"
        :disabled="amendableData"
      >
        <template v-slot:label> Flat number/building name </template>
      </app-input>
    </div>
    <div v-else>
      <h2>Collection address</h2>
      <div class="address-disabled">
        <app-input id="address" :value="getRestaurantAddress">
          <template v-slot:icon>
            <IconServices class="services-icon" icon="location" />
          </template>
        </app-input>
      </div>
    </div>
    <!-- Provided address: STOP -->

    <!-- Delivery and kitchen notes: START -->
    <app-text-accordion title="Add instructions for your rider" v-if="type === 'delivery'">
      <app-textarea v-model:value="notesDelivery" fullWidth style="margin-top: 10px" />
    </app-text-accordion>

    <app-text-accordion title="Add kitchen notes">
      <app-textarea v-model:value="notesKitchen" fullWidth style="margin-top: 10px" />
    </app-text-accordion>
    <!-- Delivery and kitchen notes: STOP -->

    <!-- Payment methods: START -->
    <div class="payment-header" v-if="!amendableData">
      <h2 class="title-payment-method">Payment method</h2>
      <button
        v-if="paymentMethods.length"
        class="td-u change-or-cancel-button"
        @click="
          () => {
            change = !change;
            add = !add;
            if (tour.isActive()) completeTour();
          }
        "
      >
        {{ change ? 'Cancel' : 'Change' }}
      </button>

      <button
        v-else
        class="td-u add-or-cancel-button"
        @click="
          () => {
            add = !add;
          }
        "
      >
        {{ add ? 'Cancel' : 'Add credit card' }}
      </button>
    </div>

    <PaymentMethod
      v-if="!amendableData"
      @selected="selectedPaymentMethod"
      :paymentMethods="paymentMethods"
      :change="change"
      :add="add"
      :closeWhenPayment="closeWhenPayment"
    />

    <app-text-accordion title="Add an invoice note" v-if="paymentMethod === 'invoice'" class="invoice-note">
      <app-input v-model:value="invoiceNote" class="animated slideOutDown" @blur="() => this.$emit('blur')" />
    </app-text-accordion>
    <!-- Payment methods: STOP -->

    <FormScoring>
      <!-- Tips: START -->
      <template v-slot:body v-if="type === 'delivery' && !amendableData">
        <div class="tips-wrapper">
          <h3 class="ta-c c-white">Tip the Foodstuff team?</h3>
          <div class="tip-buttons">
            <button
              class="tip-btn"
              :class="t === selectedTipPercentage ? 'tip-btn-active' : ''"
              @click="() => handleTipClick(t)"
              v-for="t in tips"
              :key="t"
            >
              <p class="tip-percent">{{ t }}%</p>
              <p class="tip-price">£{{ ((getCartPrice * t) / 100).toFixed(2) }}</p>
            </button>

            <button
              class="tip-btn tip-btn-custom"
              :class="isCustomTipSelected ? 'tip-btn-active' : ''"
              @click="() => handleCustomTipClick()"
            >
              <p class="tip-percent" v-if="isCustomTipSelected">£{{ tip }}</p>
              <p class="tip-price">Custom</p>
            </button>
          </div>
        </div>
      </template>
      <!-- Tips: STOP -->

      <!-- Amending order's summary: START -->
      <template v-slot:footer v-if="amendableData">
        <div class="form-scoring total" style="margin-bottom: 5px">
          <h3 class="c-ham">Updated total</h3>
          <div class="form-scoring-price">
            <h3 class="c-ham">£{{ getTotalPrice }}</h3>
          </div>
        </div>
        <div class="form-scoring total">
          <h3 class="c-ham">{{ getTotalPriceDifference >= 0 ? 'Amount to pay' : "We'll refund" }}</h3>
          <div class="form-scoring-price">
            <h3 class="c-ham">
              {{ (getTotalPriceDifference > 0 ? getTotalPriceDifference : -getTotalPriceDifference).toFixed(2) }}
            </h3>
          </div>
        </div>
        <h3 v-if="paymentMethod === 'invoice'" class="invoice-subtitle">
          You’ll receive updated invoice after delivery
        </h3>
        <div style="height: 15px" />

        <app-button
          @click="amendOrder"
          style="max-width: none"
          :loader="loading"
          :disabled="getTotalPriceDifference > 0 && !paymentMethod"
        >
          <strong v-if="amendableData.paymentMethod === 'invoice'">Update order via invoice</strong>
          <strong v-else-if="getTotalPriceDifference === 0">Update order</strong>
          <strong v-else-if="getTotalPriceDifference < 0">Request refund and update order</strong>
          <strong v-else-if="amendableData.paymentMethod === 'androidpay'">You can't use Google Pay here</strong>
          <strong v-else-if="amendableData.paymentMethod === 'applepay'">You can't use Apple Pay here</strong>
          <strong v-else-if="!paymentMethod">No payment method</strong>
          <strong v-else>Pay and update order</strong>
        </app-button>
      </template>
      <!-- Amending order's summary: STOP -->

      <!-- Placing order's summary: START -->
      <template v-slot:footer v-else>
        <div class="form-scoring total">
          <h3 class="c-ham">Total</h3>
          <div class="form-scoring-price">
            <h3 class="c-ham" v-if="this.paymentMethod !== 'invoice'">£{{ getTotalPrice }}</h3>
            <h3 class="c-ham" v-if="this.paymentMethod === 'invoice'">£0</h3>
          </div>
        </div>
        <h3 v-if="paymentMethod === 'invoice'" class="invoice-subtitle">You’ll receive your invoice after delivery</h3>
        <div style="height: 15px" />

        <app-button
          id="confirm_and_pay"
          @click="payForOrder"
          style="max-width: none"
          :disabled="getTotalPrice === '0.00' || restaurant.isClosed || restaurant.isTemporarilyClose"
          :loader="loading"
        >
          <strong v-if="restaurant.isTemporarilyClosed">Back soon!</strong>
          <strong v-else-if="restaurant.isClosed">Restaurant closed</strong>
          <strong v-else>Place your order</strong>
        </app-button>
      </template>
      <!-- Placing order's summary: STOP -->
    </FormScoring>
  </div>
</template>

<script lang="ts">
import { watch } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { useGtag } from 'vue-gtag-next';
import { useShepherd } from 'vue-shepherd';
import { mapState } from 'vuex';

import FormScoring from '@/components/FormScoring.vue';
import PaymentMethod from '@/components/PaymentMethod.vue';

import accountHttp from '@/requests/account';
import groupOrdersHttp from '@/requests/groupOrders';
import ordersHttp from '@/requests/orders';

import IconServices from '@/shared/icons/IconServices.vue';

import { GroupData } from '@/store/modules/groupOrder';

import methods from '@/utils/methods';

interface ValidatedProduct {
  id: string | number;
  name: string;
  price?: number | string;
  oldPrice?: number | string;
  reason: string;
}

@Options({
  components: {
    IconServices,
    PaymentMethod,
    FormScoring,
  },
  computed: {
    ...mapState('yup', ['show']),
    ...mapState('basket', ['restaurant', 'tip']),
    ...mapState('address', ['formattedAddress']),
    ...mapState('order', ['type']),
    ...mapState('amendableOrder', { amendableData: 'data' }),
  },
})
export default class CheckoutInfo extends Vue {
  private tour = useShepherd({ useModalOverlay: true });

  private paymentMethods: { id: string; invoice: boolean }[] = [];
  private paymentMethod: any = '';

  private tips: number[] = [10, 15, 20];
  private selectedTipPercentage = 0;

  private additionalAddressDetails = '';
  private notesDelivery = '';
  private notesKitchen = '';
  private invoiceNote = '';

  private add = false;
  private change = false;
  private loading = false;

  get getTotalPrice() {
    return methods.getTotalPrice();
  }

  get getTotalPriceDifference() {
    return methods.getTotalPriceDifference();
  }

  get getServiceFee() {
    return methods.getServiceFee(this.$store.state.basket.restaurant!.deliveryInfo.percentServiceFee);
  }

  get getSmallOrderFee() {
    const { deliveryInfo } = this.$store.state.basket.restaurant!;
    return methods.getSmallOrderFee(deliveryInfo.minSOFBasketPrice, deliveryInfo.perPoundSOFFactor);
  }

  get getCartPrice() {
    return methods.getCartPrice();
  }

  get getInitialDeliveryFee() {
    const { basket, order } = this.$store.state;
    if (order.type === 'collection') return 0;

    // NOTE: Delivering big orders takes place with usage of vans instead of bikes, so user needs to pay more:
    return this.getCartPrice > basket.restaurant!.deliveryInfo.deliveryFeeBreakpoint
      ? basket.restaurant!.deliveryInfo.bigOrderInitialFee
      : basket.restaurant!.deliveryInfo.initialFee;
  }

  get getDeliveryFee() {
    const { basket, order } = this.$store.state;
    if (order.type === 'collection' || basket.coupon?.type === 'free_delivery') return 0;

    // NOTE: Delivering big orders takes place with usage of vans instead of bikes, so user needs to pay more:
    return this.getCartPrice > basket.restaurant!.deliveryInfo.deliveryFeeBreakpoint
      ? basket.restaurant!.deliveryInfo.bigOrderFee
      : basket.restaurant!.deliveryInfo.fee;
  }

  get getRestaurantAddress() {
    const restaurant = this.$store.state.basket.restaurant!;
    return `${restaurant.location}, ${restaurant.nearestStation} ${restaurant.postcode}`;
  }

  get isCustomTipSelected() {
    return this.$store.state.basket.tip > 0 && !this.tips.includes(this.selectedTipPercentage);
  }

  private handleTipClick(tipPercentage: number) {
    if (this.selectedTipPercentage === tipPercentage) {
      this.selectedTipPercentage = 0;
      this.$store.commit('basket/tip', 0);
    } else {
      const tip = parseFloat(((this.getCartPrice * tipPercentage) / 100).toFixed(2));
      this.selectedTipPercentage = tipPercentage;
      this.$store.commit('basket/tip', tip);
    }
  }

  private handleCustomTipClick() {
    this.$store.commit('modals/data', {
      tipAmount: this.isCustomTipSelected ? this.$store.state.basket.tip : 0,
      basketValue: this.getCartPrice,
    });
    this.$store.commit('modals/show', 'customTip');
  }

  private selectedPaymentMethod(paymentMethod: any | string) {
    // if the paymentMethod === '' - it`s selection of the new card;
    this.paymentMethod = paymentMethod.id;
  }

  private closeWhenPayment() {
    this.change = false;
    this.add = false;
  }

  private async loadPaymentMethods() {
    const { paymentMethods } = await accountHttp.getPaymentMethods();

    const user = this.$store.state.service.user as User;
    if (user.isBusinessUser) paymentMethods.push({ id: 'invoice', invoice: true });

    this.paymentMethods = paymentMethods;

    const amendableData = this.$store.state.amendableOrder.data;
    if (amendableData) {
      if (amendableData.paymentMethod === 'invoice') {
        this.paymentMethod = paymentMethods.find((pm) => pm.invoice)?.id || '';
      } else if (amendableData.paymentMethod === 'creditcard') {
        this.paymentMethod = paymentMethods.find((pm) => !pm.invoice)?.id || '';
      } else {
        this.paymentMethod = '';
      }
      return;
    }

    if (this.paymentMethods.length) {
      this.paymentMethod = paymentMethods[0].id;
    }
  }

  private setupGtag(transactionID: string) {
    const { event } = useGtag();
    const track = () => {
      event('conversion', {
        send_to: 'AW-429869669/afFgCKnj7PkBEOWU_cwB', // eslint-disable-line
        event_category: 'Ordering food', // eslint-disable-line
        event_label: 'method', // eslint-disable-line
        value: this.getTotalPrice, // eslint-disable-line
      });
    };
    const { user } = this.$store.state.service;
    const { dataLayer } = window as any;

    let dishes: OrderDish[] = [...this.$store.state.basket.dishes];
    if (this.$store.state.groupOrder.groupData) {
      dishes = [...dishes, ...methods.groupOrderToOrderDishes(this.$store.state.groupOrder.groupData)];
    }

    const trackedDishes = dishes.map((dish) => ({
      id: dish.id,
      name: dish.name,
      quantity: dish.amount,
      price: dish.price,
      modificators: dish.modificators.map((mod: any) => ({
        id: mod.id,
        name: mod.name,
        price: mod.price,
        quantity: mod.amount,
      })),
    }));

    if (dataLayer) {
      dataLayer.push({
        event: 'purchase',
        userID: user ? user.email : null,
        restaurantID: this.$store.state.basket.restaurant?.id,
        restaurantSlug: this.$store.state.basket.restaurant?.slug,
        ecommerce: {
          purchase: {
            actionField: {
              id: transactionID,
              revenue: this.getTotalPrice, // <Total Price>
              shipping: this.$store.state.basket.restaurant?.deliveryInfo.fee, // <Delivery Price>,
              serviceFee: this.getServiceFee,
              coupon: this.$store.state.basket.coupon,
            },
            products: trackedDishes,
          },
        },
      });
    }

    track();
  }

  private async payForOrder() {
    const { basket, groupOrder, order, service } = this.$store.state;
    if (!basket.restaurant || basket.restaurant.isClosed) return;

    // Validate new card (if required):
    if (this.paymentMethod.length === 0 && !methods.validateStripe) {
      this.$store.commit('modals/data', 'Please add a payment method!');
      this.$store.commit('modals/show', 'error');
      this.$store.commit('stripe/error', true);
      this.loading = false;
      return;
    }

    // Get list of dishes:
    let dishes: OrderDish[] = [...basket.dishes];
    if (groupOrder.groupData) {
      dishes = [...dishes, ...methods.groupOrderToOrderDishes(groupOrder.groupData)];
    }

    // Get all prices:
    const cartPrice = this.getCartPrice;
    const deliveryPrice = this.getDeliveryFee;
    const initialDeliveryPrice = this.getInitialDeliveryFee;
    const serviceFee = this.getServiceFee;
    const smallOrderFee = this.getSmallOrderFee;
    const tipAmount = basket.tip;
    const totalPrice = this.getTotalPrice;

    // Combine required data into one object:
    const data: any = {
      address: {
        flatAndBuilding: this.additionalAddressDetails,
        street: this.$store.state.address.formattedAddress,
        postcode: this.$store.state.address.postcode,
        city: this.$store.state.address.cityName,
        lat: this.$store.state.address.lat,
        lng: this.$store.state.address.lng,
      },
      cart: {
        dishes,
        restaurantSlug: basket.restaurant.slug,
        restaurantId: basket.restaurant.id,
      },
      cartPrice,
      contactPhone: service.user?.phone || '',
      couponName: basket.coupon?.name || '',
      deliveryNotes: this.notesDelivery,
      deliveryPrice,
      groupId: groupOrder.groupData ? groupOrder.groupData.id : null,
      initialDeliveryPrice,
      kitchenNotes: this.notesKitchen,
      paymentMethod: this.paymentMethod === 'invoice' ? 'invoice' : 'creditcard',
      platform: 'web',
      serviceFee,
      smallOrderFee,
      tipAmount,
      totalPrice,
      type: order.type === 'collection' ? 'collect' : 'delivery',
    };

    // Add information about scheduled delivery:
    const { scheduledDeliveryInterval, scheduledDeliveryDate } = this.$store.state.order;
    data.isScheduled = !!scheduledDeliveryDate && !!scheduledDeliveryInterval;
    if (data.isScheduled) {
      data.deliverToTime = this.$store.getters['order/scheduledDeliveryTimestamp'];
      data.worstDeliverToTime = this.$store.getters['order/scheduledDeliveryTimestampWorst'];
    }

    this.loading = true;
    try {
      // Validate basket:
      await ordersHttp.validateBasket({
        order: {
          address: data.address,
          type: data.type,
          cart: data.cart,
          deliverToTime: data.deliverToTime,
        },
      });

      // Create order:
      const { id: orderId, friendlyId } = await ordersHttp.createOrder(data);

      // Make a payment:
      if (this.paymentMethod === 'invoice') {
        await ordersHttp.confirmInvoicePayment(orderId, this.invoiceNote);
      } else {
        const { clientSecret, transactionID } = await ordersHttp.createPayment(orderId, this.paymentMethod);
        await ordersHttp.confirmPayment(clientSecret, this.paymentMethod);
        this.setupGtag(transactionID);
      }

      this.proceedToOrderTracker(friendlyId, data.groupId);
    } catch (err: any) {
      if (err.response?.data?.error === 'The cart contains unavailable dishes or modifiers') {
        const validateBasketRes = this.processBasketValidationResponse(err.response?.data);
        const dishesThatWereSentToValidationEndpoint = [...data.cart.dishes];
        this.updateBasketAfterValidation(validateBasketRes, dishesThatWereSentToValidationEndpoint);
      } else {
        this.$store.commit('modals/data', err.response?.data?.error || err.message || err);
        this.$store.commit('modals/show', 'error');
      }
    } finally {
      this.loading = false;
    }
  }

  private async amendOrder() {
    const { amendableOrder, basket, groupOrder } = this.$store.state;
    if (!amendableOrder.data || !basket.restaurant) return;

    // Get list of dishes:
    let dishes: OrderDish[] = [...basket.dishes];
    if (groupOrder.groupData) {
      dishes = [...dishes, ...methods.groupOrderToOrderDishes(groupOrder.groupData)];
    }

    // Get all prices:
    const cartPrice = this.getCartPrice;
    const deliveryPrice = this.getDeliveryFee;
    const serviceFee = this.getServiceFee;
    const smallOrderFee = this.getSmallOrderFee;
    const tipAmount = basket.tip;
    const totalPrice = this.getTotalPrice;
    const totalPriceDifference = this.getTotalPriceDifference;

    // Combine required data into one object:
    const data: any = {
      cart: {
        dishes,
        restaurantSlug: basket.restaurant.slug,
        restaurantId: basket.restaurant.id,
      },
      cartPrice,
      couponName: basket.coupon?.name ?? '',
      deliveryNotes: this.notesDelivery,
      deliveryPrice,
      invoiceNote: this.invoiceNote,
      kitchenNotes: this.notesKitchen,
      serviceFee,
      smallOrderFee,
      totalPrice,
      tipAmount,
      totalPriceDifference,
    };

    this.loading = true;
    try {
      // Validate order owner's dishes:
      if (basket.dishes.length) {
        await ordersHttp.validateBasket({
          isAmending: true,
          order: {
            address: amendableOrder.data.address,
            cart: {
              dishes: basket.dishes,
              restaurantSlug: basket.restaurant.slug,
              restaurantId: basket.restaurant.id,
            },
            deliverToTime: amendableOrder.data.deliverToTime,
            type: amendableOrder.data.type,
          },
        });
      }

      // Update order, get client secret or ask for refund:
      const { clientSecret, paymentMethodId } = await ordersHttp.amendOrder(data, amendableOrder.data.id);

      // Pay if necessary:
      const shouldSendRequestToStripe = totalPriceDifference > 0 && this.paymentMethod !== 'invoice';
      if (shouldSendRequestToStripe) {
        if (!clientSecret) throw new Error('There is no payment method specified for this order');
        if (!paymentMethodId) throw new Error('There is no payment method specified for this order');
        await ordersHttp.confirmPayment(clientSecret, paymentMethodId);
        await new Promise((_) => setTimeout(_, 3000));
      }

      // Go to order tracker:
      await this.$router.push(`/orders-tracker?orderId=${amendableOrder.data.friendlyId}`);
      this.$store.commit('basket/clear');
      this.$store.commit('groupOrder/reset');
      this.$store.commit('amendableOrder/clear');
    } catch (err: any) {
      if (err.response?.data?.error === 'The cart contains unavailable dishes or modifiers') {
        const validateBasketRes = this.processBasketValidationResponse(err.response?.data);
        const dishesThatWereSentToValidationEndpoint = [...basket.dishes];
        this.updateBasketAfterValidation(validateBasketRes, dishesThatWereSentToValidationEndpoint, true);
      } else {
        this.$store.commit('modals/data', err.response?.data?.error || err.message || err);
        this.$store.commit('modals/show', 'error');
      }
    } finally {
      this.loading = false;
    }
  }

  private async proceedToOrderTracker(friendlyId: number, groupId: string) {
    await this.$router.push(`/orders-tracker?orderId=${friendlyId}`);
    if (groupId) setTimeout(() => this.$store.commit('modals/show', 'groupOrderWhatNext'), 5000);

    this.$store.commit('basket/clear');
    this.$store.commit('groupOrder/reset');
  }

  private updateBasketAfterValidation(
    validateBasketRes: any,
    dishesThatWereSentToValidationEndpoint: OrderDish[],
    isAmendingOrder = false,
  ) {
    const { basket, groupOrder } = this.$store.state;

    let ownerProducts: OrderDish[] = [...basket.dishes];
    let participantBaskets: GroupOrderBasket[] = [];
    if (groupOrder.groupData && !isAmendingOrder) participantBaskets = [...groupOrder.groupData.baskets];

    const dialogData: { restaurantName: string; removedProducts: Array<ValidatedProduct> } = {
      restaurantName: basket.restaurant?.name || '',
      removedProducts: [],
    };

    // Handle with closed menus:
    let unavailableProducts: Array<ValidatedProduct> = [];
    if (validateBasketRes.notAvailableDishes.length > 0) {
      const unavailableIds = validateBasketRes.notAvailableDishes.map((e) => e.id);
      unavailableProducts = dishesThatWereSentToValidationEndpoint
        .filter((dish: any) => unavailableIds.includes(dish.id))
        .map((dish: any) => ({ id: dish.id, name: dish.name, reason: 'Not available' }));
    }

    // Handle with soldOut products and modifiers:
    let soldOutProducts: Array<ValidatedProduct> = [];
    if (validateBasketRes.deletedDishes.length > 0 || validateBasketRes.deletedMods.length > 0) {
      const deletedDishIds = validateBasketRes.deletedDishes.map((e) => e.id);
      const deletedModIds = validateBasketRes.deletedMods.map((e) => e.id);
      soldOutProducts = dishesThatWereSentToValidationEndpoint
        .filter(
          (dish: any) =>
            deletedDishIds.includes(dish.id) ||
            deletedModIds.some((id) => dish.modificators.map((m: any) => m.id).includes(id)),
        )
        .map((dish: any) => ({ id: dish.id, name: dish.name, reason: 'Sold out' }));
    }

    // Remove redundant products from owner's basket for further processing:
    const ids = unavailableProducts.concat(soldOutProducts).map((e: any) => e.id);
    ownerProducts = ownerProducts.filter((e) => !ids.includes(e.id));

    // Remove redundant products from participants' baskets for further processing:
    const updatedGroupBasketIds = new Set();
    participantBaskets = participantBaskets.map((groupBasket) => {
      const newGroupBasket = JSON.parse(JSON.stringify(groupBasket));
      newGroupBasket.cart.dishes = newGroupBasket.cart.dishes.filter((e: any) => {
        const isUnavailable = ids.includes(e.id);
        if (isUnavailable) updatedGroupBasketIds.add(groupBasket.id);
        return !isUnavailable;
      });
      return newGroupBasket;
    });

    // Change prices locally in all baskets, if anything should be changed:
    const outdatedProducts: Array<ValidatedProduct> = [];
    if (
      validateBasketRes.dishesWithOutdatedPrices.length > 0 ||
      validateBasketRes.modifiersWithOutdatedPrices.length > 0
    ) {
      const updatedDishes = validateBasketRes.dishesWithOutdatedPrices;
      const updatedModifiers = validateBasketRes.modifiersWithOutdatedPrices;

      // Owner:
      ownerProducts = ownerProducts.map((dish: OrderDish) => {
        const newDish: OrderDish = JSON.parse(JSON.stringify(dish));

        // Calculate price before updating items:
        const oldPrice = newDish.modificators.reduce((sum, m) => sum + m.price * m.amount, 0) + newDish.price;

        // Check product:
        const updatedDish = updatedDishes.find((dish: any) => dish.id === newDish.id);
        if (updatedDish) newDish.price = updatedDish.discountedPrice || updatedDish.price;

        // Check extras:
        newDish.modificators = newDish.modificators.map((modifier) => {
          const newModifier: OrderModifier = JSON.parse(JSON.stringify(modifier));

          const updatedModifier = updatedModifiers.find((mod: any) => mod.id === modifier.id);
          if (updatedModifier) newModifier.price = updatedModifier.price;

          return newModifier;
        });

        // Calculate price after updating items:
        const newPrice = newDish.modificators.reduce((sum, m) => sum + m.price * m.amount, 0) + newDish.price;

        if (oldPrice !== newPrice) {
          outdatedProducts.push({
            id: newDish.id,
            name: newDish.name,
            price: newPrice,
            oldPrice,
            reason: 'Outdated price',
          });
        }

        return newDish;
      });

      // Participants:
      participantBaskets = participantBaskets.map((groupBasket: GroupOrderBasket) => {
        const newGroupBasket: GroupOrderBasket = JSON.parse(JSON.stringify(groupBasket));
        newGroupBasket.cart.dishes = newGroupBasket.cart.dishes.map((dish: OrderDish) => {
          const newDish: OrderDish = JSON.parse(JSON.stringify(dish));

          const updatedDish = updatedDishes.find((d: any) => d.id === newDish.id);
          if (updatedDish) newDish.price = updatedDish.discountedPrice || updatedDish.price;

          let oldModsPrice = 0;
          let newModsPrice = 0;
          newDish.modificators = newDish.modificators.map((modifier: OrderModifier) => {
            const newModifier: OrderModifier = JSON.parse(JSON.stringify(modifier));

            const updatedModifier = updatedModifiers.find((m: any) => m.id === newModifier.id);
            if (updatedModifier) newModifier.price = updatedModifier.price;

            oldModsPrice += modifier.price;
            newModsPrice += newModifier.price;

            return newModifier;
          });

          const oldPrice = (dish.price + oldModsPrice).toFixed(2);
          const newPrice = (newDish.price + newModsPrice).toFixed(2);

          if (oldPrice !== newPrice) {
            updatedGroupBasketIds.add(newGroupBasket.id);
            outdatedProducts.push({
              id: newDish.id,
              name: newDish.name,
              price: newPrice,
              oldPrice,
              reason: 'Outdated price',
            });
          }

          return newDish;
        });

        return newGroupBasket;
      });
    }

    // Update participants' baskets:
    if (updatedGroupBasketIds.size > 0) {
      const updatedGroupBaskets = participantBaskets.filter((gb) => updatedGroupBasketIds.has(gb.id));
      this.overrideBasketsInGroupOrder(updatedGroupBaskets);
    }

    // Update owner's basket:
    this.$store.commit('basket/setDishes', ownerProducts);

    // Show information about updated basket:
    dialogData.removedProducts = unavailableProducts.concat(soldOutProducts).concat(outdatedProducts);
    this.$store.commit('modals/data', dialogData);
    this.$store.commit('modals/show', 'basketUpdate');
  }

  private processBasketValidationResponse(json: any) {
    // Handling with deleted / sold-out dishes:
    const deletedDishes: { id: number; name: string }[] = [];
    if (json.deletedDishes) {
      json.deletedDishes.forEach((dish: any) =>
        deletedDishes.push({
          id: dish.id,
          name: dish.name,
        }),
      );
    }

    // Handling with deleted / sold-out modifiers:
    const deletedMods: { id: number; name: string }[] = [];
    if (json.deletedMods && json.deletedMods !== 0) {
      json.deletedMods.forEach((mod: any) =>
        deletedMods.push({
          id: mod.id,
          name: mod.name,
        }),
      );
    }

    // Handling with closed menus:
    const notAvailableDishes: { id: number; name: string }[] = [];
    if (json.notAvailableMenu) {
      json.notAvailableMenu.forEach((menu: any) => {
        menu.dishes.forEach((dish: any) =>
          notAvailableDishes.push({
            id: dish.id,
            name: dish.name,
          }),
        );
      });
    }

    // Handling with dishes with updated prices:
    const dishesWithOutdatedPrices: {
      id: number;
      name: string;
      price: number;
      discountedPrice: number | null;
    }[] = [];
    if (json.dishesWithOutdatedPrices) {
      json.dishesWithOutdatedPrices.forEach((dish: any) =>
        dishesWithOutdatedPrices.push({
          id: dish.id,
          name: dish.name,
          price: dish.price,
          discountedPrice: dish.discountedPrice,
        }),
      );
    }

    // Handling with modifiers with updated prices:
    const modifiersWithOutdatedPrices: {
      id: number;
      name: string;
      price: number;
      discountedPrice: number | null;
    }[] = [];
    if (json.modifiersWithOutdatedPrices) {
      json.modifiersWithOutdatedPrices.forEach((mod: any) =>
        modifiersWithOutdatedPrices.push({
          id: mod.id,
          name: mod.name,
          price: mod.price,
          discountedPrice: mod.discountedPrice,
        }),
      );
    }

    return {
      deletedDishes,
      deletedMods,
      notAvailableDishes,
      dishesWithOutdatedPrices,
      modifiersWithOutdatedPrices,
    };
  }

  private async overrideBasketsInGroupOrder(groupBaskets: GroupOrderBasket[]) {
    const groupOrderData = this.$store.state.groupOrder.groupData;
    if (!groupOrderData) return;

    let updatedGroupOrder: GroupData | null = null;
    for (let i = 0; i < groupBaskets.length; i += 1) {
      // eslint-disable-next-line
      updatedGroupOrder = await groupOrdersHttp.editBasketInGroupOrder(groupOrderData.id, {
        basketId: groupBaskets[i].id,
        cart: {
          ...groupBaskets[i].cart,
          dishes: groupBaskets[i].cart.dishes.map(({ basketId, menuType, ...otherProperties }: any) => ({
            ...otherProperties,
          })),
        },
      });
    }

    this.$store.commit('groupOrder/update', { ...updatedGroupOrder });
  }

  private setUpProductTour() {
    // Do nothing if the step has already been presented in the past (or if user is not a business user):
    const { productTour } = this.$store.state;
    if (!this.paymentMethods.some((p) => p.invoice) || productTour['pay-by-invoice']) return;

    // Do nothing in embedded views:
    if (this.$route.query.embedded) return;

    // Identify step's element:
    const element = document.getElementsByClassName('change-or-cancel-button')[0];
    if (!element) return;

    // Add step and start tour:
    if (this.paymentMethods[0].invoice) {
      this.tour.addStep({
        buttons: [{ text: '<p>Got it</p>', action: this.completeTour }],
        attachTo: { element, on: 'right' },
        text: "<p><strong>Want to pay by card?</strong> Click 'change' to add your payment details.</p>",
      });
    } else {
      this.tour.addStep({
        buttons: [{ text: '<p>Got it</p>', action: this.completeTour }],
        attachTo: { element, on: 'right' },
        text: "<p><strong>Pay by invoice?</strong> Click 'change' to select invoicing as a payment method.</p>",
      });
    }
    this.tour.start();

    // Add possibility to close pop-up by clicking overlay:
    const overlay = document.getElementsByClassName('shepherd-modal-overlay-container')[0] as HTMLElement;
    if (overlay) overlay.onclick = this.completeTour;

    if (window.innerWidth <= 1024) this.tour.steps[0].hide();
    window.addEventListener('resize', () => {
      // Don't restart tour:
      if (!this.tour.isActive()) return;

      // Show only on desktop:
      const step = this.tour.steps[0];
      if (window.innerWidth <= 1024 && step.isOpen()) step.hide();
      else if (window.innerWidth > 1024 && !step.isOpen()) step.show();
    });
  }

  private completeTour() {
    this.$store.commit('productTour/setAsCompleted', 'pay-by-invoice');
    this.tour.complete();
  }

  created() {
    this.loadPaymentMethods();
    this.$store.commit('yup/toggleShowError', false);
    this.$store.commit('yup/clear');

    const amendableData = this.$store.state.amendableOrder.data;
    if (amendableData) {
      this.additionalAddressDetails = amendableData.address.flatAndBuilding || '';
      this.notesDelivery = amendableData.deliveryNotes ?? '';
      this.notesKitchen = amendableData.kitchenNotes ?? '';
      this.invoiceNote = amendableData.invoiceNote ?? '';
    }

    if (this.$store.state.basket.tip) {
      this.selectedTipPercentage = Math.round((this.$store.state.basket.tip / this.getCartPrice) * 100);
    }

    watch(
      () => this.$store.state.service.user,
      (value: any) => {
        if (!value) this.$router.push('/');
      },
    );

    watch(
      () => this.$store.state.service.update,
      (value: string) => {
        if (value === 'Payment Method') {
          this.loadPaymentMethods();
          this.$store.commit('service/update', '');
        }
      },
    );

    watch(
      () => this.$store.state.basket.tip,
      (tip: number) => {
        this.selectedTipPercentage = Math.round((tip / this.getCartPrice) * 100);
      },
    );
  }

  mounted() {
    // Set up product tour AFTER rendering Change button:
    watch(
      () => this.paymentMethods.length,
      () => setTimeout(this.setUpProductTour, 500),
    );
  }

  unmounted() {
    const isAmendingOrder = !!this.$store.state.amendableOrder.data;
    if (!isAmendingOrder) this.$store.commit('basket/tip', 0);

    if (this.tour.isActive()) this.tour.complete();
  }
}
</script>

<style lang="scss">
.checkout-info {
  .address-disabled {
    position: relative;
    background-color: white;
    &::before {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 50;
      border-radius: 43px;
      cursor: not-allowed;
      padding: 10px;
      margin-top: -10px;
      margin-left: -10px;
      box-sizing: content-box;
    }

    .controller-input-wrapper {
      label .controller-input input {
        padding-right: 72px;
        color: $coal100;
        background-color: transparent;
        font-weight: 600;
        border: 1px solid $coal20;
        position: relative;
        &:hover {
          cursor: not-allowed;
        }

        &::placeholder {
          color: $coal40;
        }
      }
    }
  }

  .payment-header {
    display: flex;
    align-items: center;
    margin-top: 30px;
    gap: 1rem;

    h2 {
      margin: 0;
    }

    button {
      color: #e58b90;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .controller-input-wrapper {
    margin: 12px 0;
    max-width: none;
    position: relative;
    z-index: 1;

    &:has(.checkout-input)::after {
      content: '';
      position: absolute;
      width: 95%;
      height: 1px;
      background-color: black;
      bottom: 0.5rem;
      left: 1rem;

      @media (min-width: 1100px) {
        width: 50%;
      }
    }
  }

  .controller-text-accordion-wrapper {
    margin-bottom: 12px;
    p {
      font-weight: 600;
    }
    &.coupon {
      margin-bottom: 20px;
    }
  }

  .title-payment-method {
    margin-top: 30px;
  }

  .tips-wrapper {
    text-align: center;

    h3 {
      font-size: 22px;
      margin-bottom: 5px;
    }

    h4 {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 15px;
    }

    .controller-button {
      margin: 0 10px;
    }

    .tip-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
    }

    .tip-btn {
      margin-right: 15px;
      background-color: $vanilla;
      border-radius: 15px;
      height: 65px;
      width: 65px;

      .tip-percent {
        color: $coal100;
        font-weight: 600;
        font-size: 20px;
      }

      .tip-price {
        font-size: 16px;
        color: $coal100;
      }

      &-active {
        background-color: $ham;
      }

      &-custom {
        margin-right: 0;
      }

      @media (max-width: 360px) {
        height: 60px;
        width: 60px;

        .tip-percent {
          font-size: 18px;
        }

        .tip-price {
          font-size: 14px;
        }
      }
    }
  }

  .nohidden {
    overflow: auto !important;
  }

  .button-tool {
    z-index: 100;
  }

  .invoice-note {
    max-width: 314px;
  }

  .invoice-subtitle {
    color: white;
    font-size: 18px;
    margin-top: 5px;

    @include for-smartphone {
      font-size: 16px;
    }
  }
}
</style>
