<template>
  <div class="calendar_wrapper">
    <div class="calendar_row">
      <div class="navigation_button custom-cursor-hover hide_mobile" @click="decrease">
        <img class="left" src="../../assets/gtm/icon_left.png" alt="" />
      </div>
      <VueDatePicker
        class="calendarV2"
        ref="ref"
        :model-value="dateTime"
        @update:model-value="setDateTime"
        :enable-time-picker="false"
        :min-date="minDateTime"
        :month-change-on-scroll="false"
        :calendar="daysToDisplay"
        :day-names="['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']"
        :week-start="0"
        disable-month-year-select
        auto-apply
        inline
      >
        <template #day="{ day, date }">
          <p class="day_number">{{ day }}</p>
          <p class="month_name">{{ getMonth(date) }}</p>
        </template>
      </VueDatePicker>
      <div class="navigation_button custom-cursor-hover hide_mobile" @click="increase">
        <img class="right" src="../../assets/gtm/icon_right.png" alt="" />
      </div>
    </div>
    <div class="mobile_navigation_row hide_desktop">
      <div class="navigation_button custom-cursor-hover" @click="decrease">
        <img class="left" src="../../assets/gtm/icon_left.png" alt="" />
      </div>
      <div class="navigation_button custom-cursor-hover" @click="increase">
        <img class="right" src="../../assets/gtm/icon_right.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import VueDatePicker, { CalendarWeek } from '@vuepic/vue-datepicker';
import type { DatePickerInstance } from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from 'moment-timezone';
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  dateTime = prop<moment.Moment>({ required: true });
  onConfirmClick = prop<Function>({ required: true });
}

@Options({
  components: { VueDatePicker },
  props: {
    dateTime: Object,
    onConfirmClick: Function,
  },
})
export default class CalendarGTM extends Vue.with(Props) {
  private minDateTime = moment().tz('Europe/London').startOf('day').toDate();
  private firstVisibleDay = moment().tz('Europe/London').startOf('week').toDate();

  private getMonth = (date: Date) => moment(date).format('MMM');

  private daysToDisplay(weeks: CalendarWeek[]): CalendarWeek[] {
    const visibleWeek = moment(this.firstVisibleDay).week();
    for (let i = 0; i < weeks.length; i += 1) {
      const week = weeks[i];
      if (week.days.some((day) => moment(day.value).tz('Europe/London').week() === visibleWeek)) {
        return [week];
      }
    }
    return [];
  }

  private setDateTime(value: Date | moment.Moment) {
    const newDateTime = moment(value).tz('Europe/London').startOf('day');
    this.onConfirmClick(newDateTime);
  }

  private decrease() {
    const newFirstVisibleDay = moment(this.firstVisibleDay).tz('Europe/London').subtract(1, 'week');
    this.firstVisibleDay = newFirstVisibleDay.toDate();
    (this.$refs.ref as DatePickerInstance)!.setMonthYear({
      month: newFirstVisibleDay.month(),
      year: newFirstVisibleDay.year(),
    });
  }

  private increase() {
    const newFirstVisibleDay = moment(this.firstVisibleDay).tz('Europe/London').add(1, 'week');
    this.firstVisibleDay = newFirstVisibleDay.toDate();
    (this.$refs.ref as DatePickerInstance)!.setMonthYear({
      month: newFirstVisibleDay.month(),
      year: newFirstVisibleDay.year(),
    });
  }

  mounted() {
    // Set appropriate view if editing date after reaching preview step:
    if (this.dateTime) {
      const newFirstVisibleDay = moment(this.dateTime).tz('Europe/London').startOf('week');
      this.firstVisibleDay = newFirstVisibleDay.toDate();
      (this.$refs.ref as DatePickerInstance)!.setMonthYear({
        month: newFirstVisibleDay.month(),
        year: newFirstVisibleDay.year(),
      });
    }
  }
}
</script>

<style lang="scss">
.calendarV2 {
  width: fit-content;

  .dp__menu {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    width: 550px;

    @include for-smartphone {
      width: 460px;
      max-width: calc(100vw - 40px);
    }
  }

  .dp__menu_inner {
    padding: 0;
  }

  // Weekday names: START
  .dp__calendar_header {
    background-color: #eff2f5;
    border-top-left-radius: 12.5px;
    border-top-right-radius: 12.5px;
  }

  .dp__calendar_header_item {
    @include p1;
    color: #70787f;
    padding: 0;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .dp__calendar_header_separator {
    display: none;
  }
  // Weekday names: STOP

  // Days: START
  .dp__calendar_row {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .dp__calendar_item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .day_number {
    @include p2;
    font-weight: bold;
    font-size: 25px;

    @include for-smartphone {
      font-size: 20px;
    }
  }

  .month_name {
    @include p2;
  }

  .dp__cell_inner {
    height: 65px;
    width: 60px;
    padding: 0;
    border: none;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 2.5px;

    @include for-smartphone {
      width: 40px;
      height: 50px;
    }

    p {
      color: #91919f;
    }
  }

  .dp__active_date {
    background: $ham;

    p {
      color: black;
    }
  }
  // Days: STOP
}
</style>

<style lang="scss" scoped>
.calendar_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 25px;

  @include for-smartphone {
    gap: 0px;
    align-items: unset;
    justify-content: unset;
  }
}

.mobile_navigation_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  gap: 30px;
}

.navigation_button {
  width: 35px;
  min-width: 35px;
  height: 35px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: white;
  &:hover {
    background-color: $snow;
  }

  img {
    height: 15px;
  }

  .left {
    margin-right: 2px;
  }

  .right {
    margin-left: 2px;
  }
}

.hide_mobile {
  @include for-smartphone {
    display: none;
  }
}

.hide_desktop {
  @include for-desktop {
    display: none;
  }
}
</style>
