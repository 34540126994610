import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ws-nw" }
const _hoisted_2 = { class: "ws-nw" }
const _hoisted_3 = { class: "new-basket-modal-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "new-basket-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _cache[5] || (_cache[5] = _createElementVNode("h2", null, "Create new basket?", -1)),
      _createElementVNode("p", null, [
        _cache[0] || (_cache[0] = _createTextVNode(" This will clear your existing basket with ")),
        _createElementVNode("b", _hoisted_1, _toDisplayString(_ctx.data.oldRestaurantName), 1),
        _cache[1] || (_cache[1] = _createTextVNode(" and start a new basket with ")),
        _createElementVNode("b", _hoisted_2, _toDisplayString(_ctx.data.newRestaurant.name), 1),
        _cache[2] || (_cache[2] = _createTextVNode(". "))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_app_button, {
          onClick: _withModifiers(_ctx.close, ["prevent"])
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("No")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_app_button, {
          onClick: _withModifiers(_ctx.yes, ["prevent"])
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Yes")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 16))
}