import axios from 'axios';

import Environment from '@/environment';

class HttpFirebase {
  public async generateGroupOrderLink(
    groupId: string,
    restaurantSlug: string,
    restaurantCity: string,
  ): Promise<string> {
    const { baseUrl } = Environment;

    const webLink = `${baseUrl}/menu/${restaurantCity.toLowerCase()}/${restaurantSlug}?groupId=${groupId}`;
    const dynamicLinkInfo = {
      domainUriPrefix: 'https://foodstuff.page.link',
      link: webLink,
      androidInfo: {
        androidPackageName: 'com.foodstuff.foodstuff_app',
        androidFallbackLink: webLink,
      },
      iosInfo: {
        iosBundleId: 'com.foodstuff.foodstuffApp',
        iosFallbackLink: webLink,
      },
      navigationInfo: {
        enableForcedRedirect: true,
      },
    };

    const apiKey = Environment.firebaseApiKey;
    const body = {
      dynamicLinkInfo,
      suffix: { option: 'SHORT' },
    };

    const response = await axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`, body);
    return response.data.shortLink;
  }

  public async generatePrefilledBasketLink(
    basketId: string,
    restaurantSlug: string,
    restaurantCity: string,
  ): Promise<string> {
    const { baseUrl } = Environment;

    const webLink = `${baseUrl}/menu/${restaurantCity.toLowerCase()}/${restaurantSlug}?prefilledBasketId=${basketId}`;
    const dynamicLinkInfo = {
      domainUriPrefix: 'https://foodstuff.page.link',
      link: webLink,
      navigationInfo: {
        enableForcedRedirect: true,
      },
    };

    const apiKey = Environment.firebaseApiKey;
    const body = {
      dynamicLinkInfo,
      suffix: { option: 'SHORT' },
    };

    const response = await axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`, body);
    return response.data.shortLink;
  }
}

export default new HttpFirebase();
