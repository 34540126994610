<template>
  <div class="step_zero_plus">
    <div class="main_part">
      <div class="main_part_body">
        <Logo text />
        <h1>All the food your office needs. In one place.</h1>
        <p class="how_works">Steps</p>
        <div class="rows">
          <div class="step_zero_plus_row">
            <div class="circle"><img src="../../assets/gtm/edit.png" alt="" /></div>
            <p>Who are your existing suppliers?</p>
          </div>
          <div class="step_zero_plus_row">
            <div class="circle"><img src="../../assets/gtm/plus_square.png" alt="" /></div>
            <p>What are the gaps in your current roster?</p>
          </div>
          <div class="step_zero_plus_row">
            <div class="circle"><img src="../../assets/gtm/smile.png" alt="" /></div>
            <p>Tell us about upcoming food requirements</p>
          </div>
          <div class="step_zero_plus_row">
            <div class="circle"><img src="../../assets/gtm/settings.png" alt="" /></div>
            <p>Let us build your custom marketplace</p>
          </div>
          <div class="step_zero_plus_row">
            <div class="circle"><img src="../../assets/gtm/calendar.png" alt="" /></div>
            <p>Place your first order with us</p>
          </div>
        </div>
        <app-button square white @click="initPreFlow">Get started</app-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import Logo from '@/shared/icons/Logo.vue';

@Options({
  components: { Logo },
  props: { initPreFlow: Function },
})
export default class GtmStep0Plus extends Vue {}
</script>

<style lang="scss" scoped>
.step_zero_plus {
  width: 100vw;
  min-height: 100vh;
  background: url('../../assets/gtm/gtm_background1.png') no-repeat;
  background-size: cover;
  background-position: center;

  @include for-smartphone {
    background: none;
  }

  .main_part {
    width: fit-content;
    margin-left: 15%;
    min-height: 100vh;
    background-image: linear-gradient(to bottom right, #fff1b1, #ffdf4d);
    padding: 30px 60px;
    border-left: 2px solid rgba(0, 0, 0, 0.2);
    border-right: 2px solid rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;

    @include for-smartphone {
      width: 100%;
      margin-left: unset;
      padding: 20px 30px;
      border-right: unset;
      border-left: unset;
    }

    .main_part_body {
      display: flex;
      flex-direction: column;
    }

    .logo {
      transform: translateX(40px) scale(1.4);
      width: 220.5px;

      @include for-smartphone {
        transform: unset;
        width: unset;
      }
    }

    h1 {
      margin-top: 20px;
      margin-bottom: 30px;
      font-size: 50px;
      line-height: 50px;
      max-width: 525px;

      @include for-smartphone {
        font-size: 40px;
        line-height: 40px;
        margin-top: 10px;
        max-width: 450px;
      }
    }

    .how_works {
      @include p1;
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 20px;
      color: black;

      @include for-smartphone {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 10px;
      }
    }

    .rows {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @include for-smartphone {
        height: 35vh;
        min-height: 250px;
        justify-content: space-around;
      }

      .step_zero_plus_row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;

        @include for-smartphone {
          gap: 15px;
        }

        .circle {
          background-color: #ffdb37;
          opacity: 0.7;
          min-width: 40px;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: 22px;
          }

          @include for-smartphone {
            width: 35px;
            height: 35px;
            min-width: 35px;

            img {
              height: 20px;
            }
          }
        }

        p {
          @include p1;
          font-size: 20px;
          line-height: 20px;

          @include for-smartphone {
            font-size: 18px;
            line-height: 18px;
          }
        }
      }
    }

    button {
      margin-top: 40px;
      align-self: center;
    }
  }
}
</style>
