<template>
  <div v-if="brand !== 'all'" class="icon-card-brand" :class="{ bgc }">
    <component :is="brand" v-if="getBrand" />
    <Unknown v-else />
  </div>

  <div v-for="(icon, iIndex) in allBrand" :key="iIndex" class="icon-card-brand" :class="{ bgc }">
    <component :is="icon" />
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

import amex from './payment-method/AmericanExpress.vue';
import diners from './payment-method/DinersClub.vue';
import discover from './payment-method/DiscoveryNetwork.vue';
import Invoice from './payment-method/Invoice.vue';
import jcb from './payment-method/JCB.vue';
import mastercard from './payment-method/Mastercard.vue';
import unionpay from './payment-method/UnionPay.vue';
import Unknown from './payment-method/Unknown.vue';
import visa from './payment-method/Visa.vue';

class Props {
  brand = prop<string>({ required: true });
}

@Options({
  components: {
    amex,
    diners,
    discover,
    jcb,
    mastercard,
    unionpay,
    visa,
    Unknown,
    Invoice,
  },
  props: {
    brand: String,
    bgc: Boolean,
  },
})
export default class IconCardBrand extends Vue.with(Props) {
  get allBrand() {
    if (this.brand === 'all') return ['visa', 'mastercard', 'amex', 'discover'];
    return [];
  }

  get getBrand() {
    const array: string[] = Object.keys(this.$options.components as Record<string, object>);
    const item: string | undefined = array.find((i: string) => i === this.brand);

    return item === undefined ? false : item;
  }
}
</script>

<style lang="scss" scoped>
.icon-card-brand {
  display: inline-flex;
  width: 37px;
  height: 37px;
  border-radius: 100%;
  padding: 3px;
  svg {
    width: 100%;
    height: 100%;
  }
}
</style>
