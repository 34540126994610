import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "amendable-order-info" }
const _hoisted_2 = { class: "amendable-order-info__text-wrapper" }
const _hoisted_3 = { class: "amendable-order-info__name" }
const _hoisted_4 = {
  key: 0,
  class: "amendable-order-info__details"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_IconServices, {
      icon: "suitcase",
      width: "25",
      height: "25"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, "Order #" + _toDisplayString(_ctx.data.friendlyId), 1),
      (_ctx.itemsText)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.itemsText), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isMobile && !_ctx.$route.path.endsWith('checkout'))
      ? (_openBlock(), _createBlock(_component_app_button, {
          key: 0,
          class: "amendable-order-info__button",
          onClick: _ctx.showBasket
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Edit ")
          ])),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}