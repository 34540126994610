import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal"
}
const _hoisted_2 = { class: "modal-title" }
const _hoisted_3 = { class: "modal-products" }
const _hoisted_4 = { class: "modal-products-name" }
const _hoisted_5 = { class: "modal-products-price" }
const _hoisted_6 = {
  key: 0,
  class: "modal-products-old-price"
}
const _hoisted_7 = {
  key: 1,
  class: "modal-products-new-price"
}
const _hoisted_8 = {
  key: 0,
  class: "modal-products-new-price"
}
const _hoisted_9 = { class: "modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "error-modal" }, _ctx.options), {
    default: _withCtx(() => [
      (_ctx.data)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[6] || (_cache[6] = _createElementVNode("h2", { class: "modal-title" }, "Notification", -1)),
            _createElementVNode("p", _hoisted_2, [
              _cache[2] || (_cache[2] = _createTextVNode(" Your ")),
              _createElementVNode("strong", null, _toDisplayString(_ctx.data.restaurantName), 1),
              _cache[3] || (_cache[3] = _createTextVNode(" basket has been updated due to changes to the menu. "))
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "modal-line" }, null, -1)),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.removedProducts, (product, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "modal-products-row",
                  key: product.name
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("span", null, _toDisplayString(index + 1), 1),
                    _createElementVNode("span", null, _toDisplayString(product.name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    (product.oldPrice)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(product.oldPrice) + "£", 1))
                      : _createCommentVNode("", true),
                    (product.price)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(product.price) + "£", 1))
                      : _createCommentVNode("", true)
                  ]),
                  (!product.price && !product.oldPrice)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(product.reason), 1))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_app_button, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.gotoRestaurantPage()))
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createElementVNode("strong", null, "Back to menu", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_app_button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeDialog()))
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createElementVNode("strong", null, "Okay", -1)
                ])),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16))
}