<template>
  <!-- eslint-disable -->
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.6667 8.5V15.5833H3.33333V8.5"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.0833 4.95825H1.91667V8.49992H16.0833V4.95825Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M9 15.5833V4.95825" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M9 4.95841H5.8125C5.34285 4.95841 4.89243 4.77185 4.56033 4.43975C4.22824 4.10765 4.04167 3.65724 4.04167 3.18758C4.04167 2.71793 4.22824 2.26751 4.56033 1.93541C4.89243 1.60332 5.34285 1.41675 5.8125 1.41675C8.29167 1.41675 9 4.95841 9 4.95841Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 4.95841H12.1875C12.6572 4.95841 13.1076 4.77185 13.4397 4.43975C13.7718 4.10765 13.9583 3.65724 13.9583 3.18758C13.9583 2.71793 13.7718 2.26751 13.4397 1.93541C13.1076 1.60332 12.6572 1.41675 12.1875 1.41675C9.70833 1.41675 9 4.95841 9 4.95841Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <!-- eslint-enable -->
</template>
