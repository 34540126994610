import moment from 'moment-timezone';
import { State } from 'vue';
import { ValidationError } from 'yup';

import store from '@/store';
import { GroupData } from '@/store/modules/groupOrder';
import { StateStripe } from '@/store/modules/stripe';
import { StateYup, YupErrors } from '@/store/modules/yup';

const state: State = store.state as State;

class RestaurantMethods {
  // ERROR METHODS: START
  public yupErrMsg(path: string) {
    const state: State = store.state as State;
    const stateYup: StateYup = { ...state.yup };

    if (stateYup.show) {
      const yupErrors: YupErrors[] = stateYup.errors;

      const i = yupErrors.findIndex((e: YupErrors) => e.path === path);

      if (i >= 0) return yupErrors[i].msg;

      return false;
    }

    return false;
  }

  public yupValidCatch(err: { inner: ValidationError[] }) {
    store.commit('yup/clear');
    console.log('err: ', err);
    console.log('inner: ', err.inner);

    err.inner.forEach((error: any) => {
      store.commit('yup/errors', { path: error.path, msg: error.errors[0] });
    });

    store.commit('yup/toggleShowError', true);
  }
  // ERROR METHODS: STOP

  // RESTAURANT DETAILS METHODS: START
  public isDeliveryTimeLateEnough(minHoursInAdvance?: number) {
    if (!minHoursInAdvance) return true;

    const { scheduledDeliveryInterval, scheduledDeliveryDate } = (store.state as State).order;
    if (!scheduledDeliveryDate || !scheduledDeliveryInterval) return false;

    const amendableData = (store.state as State).amendableOrder.data;
    return moment(scheduledDeliveryDate).isAfter(moment(amendableData?.createdAt).add(minHoursInAdvance, 'hours'));
  }

  public filterRestaurantInfo(restaurant: FullInfoRestaurant): ShortInfoRestaurant {
    return {
      deliveryInfo: restaurant.deliveryInfo,
      discountMinCartPrice: restaurant.discountMinCartPrice,
      discountMinHoursInAdvance: restaurant.discountMinHoursInAdvance,
      discountPercentage: restaurant.discountPercentage,
      id: restaurant.id,
      isClosed: restaurant.isClosed,
      isTemporarilyClose: restaurant.isTemporarilyClose,
      location: restaurant.location,
      logo: restaurant.logo,
      minCartPrice: restaurant.minCartPrice,
      name: restaurant.name,
      nearestStation: restaurant.nearestStation,
      postcode: restaurant.postcode,
      restaurantCity: restaurant.restaurantCity,
      slug: restaurant.slug,
    };
  }
  // RESTAURANT DETAILS METHODS: STOP

  // BASKET METHODS: START
  public getItemPrice(dish: OrderDish) {
    let { price } = dish;

    if (dish.modificators.length > 0) {
      dish.modificators.forEach((mod: OrderModifier) => {
        price += mod.price * mod.amount;
      });
    }

    return +(price * dish.amount).toFixed(2);
  }

  public getParticipantCartPrice() {
    const state: State = store.state as State;

    let participantDishes: OrderDish[] = [];

    const confirmedBasketId = state.groupOrder.participantBasketId;
    if (confirmedBasketId) {
      participantDishes =
        state.groupOrder.groupData?.baskets.find((basket) => basket.id === confirmedBasketId)?.cart.dishes || [];
    } else {
      participantDishes = [...state.basket.dishes];
    }

    let price = 0;
    if (participantDishes.length > 0) {
      participantDishes.forEach((dish: OrderDish) => {
        price += this.getItemPrice(dish);
      });
    }

    return +price.toFixed(2);
  }

  public getActiveUserCartPrice() {
    const state: State = store.state as State;

    let price = 0;
    state.basket.dishes.forEach((dish: OrderDish) => {
      price += this.getItemPrice(dish);
    });

    return +price.toFixed(2);
  }

  public getCartPrice() {
    const state: State = store.state as State;

    const participantDishes = state.groupOrder.groupData?.baskets.flatMap(
      (basket: GroupOrderBasket) => basket.cart.dishes,
    ) as OrderDish[];

    let allDishes: OrderDish[] = [];
    if (state.groupOrder.areItemsConfirmed) {
      // count only group baskets if participant has confirmed items in store/basket (to avoid duplication from store)
      allDishes = [...participantDishes];
    } else if (!state.groupOrder.groupData || !participantDishes) {
      // count only store/basket items if not a group order or no group order items yet
      allDishes = [...state.basket.dishes];
    } else {
      allDishes = [...state.basket.dishes, ...participantDishes];
    }

    let price = 0;
    allDishes.forEach((dish: OrderDish) => {
      price += this.getItemPrice(dish);
    });

    return +price.toFixed(2);
  }

  public getDiscountedCartPrice() {
    const basketPrice = this.getCartPrice();
    const { coupon, restaurant } = (store.state as State).basket;

    let price = basketPrice;
    if (coupon) {
      const { type, discount, maxTotal } = coupon;

      if (type === 'specific_amount') {
        price -= discount;
      }

      if (type === 'percent') {
        if (maxTotal && price > maxTotal) {
          price -= Math.round(maxTotal * (discount / 100) * 100) / 100;
        } else {
          price -= Math.round(price * (discount / 100) * 100) / 100;
        }
      }
    }

    if (
      restaurant &&
      restaurant.discountPercentage &&
      restaurant.discountMinCartPrice <= basketPrice &&
      this.isDeliveryTimeLateEnough(restaurant.discountMinHoursInAdvance)
    ) {
      const discountMultiplier = restaurant.discountPercentage / 100;
      return +Math.max(price - basketPrice * discountMultiplier, 0).toFixed(2);
    }

    return +Math.max(price, 0).toFixed(2);
  }

  public getSmallOrderFee(minSOFBasketPrice: number, perPoundSOFFactor: number) {
    const price = this.getCartPrice();

    if (price < minSOFBasketPrice) {
      return +(Math.ceil(minSOFBasketPrice - price) * perPoundSOFFactor).toFixed(2);
    }

    return 0;
  }

  public getServiceFee(percentServiceFee: PercentServiceFee | null | undefined) {
    const state: State = store.state as State;
    if (state.service.user && state.service.user.hasFreeServiceFee) return 0.0;

    if (!percentServiceFee) return 0;

    const price = this.getCartPrice();

    let serviceFee = price * percentServiceFee.feePercentBelow;

    if (price > percentServiceFee.priceBreakpoint) {
      serviceFee =
        percentServiceFee.feePercentBelow * percentServiceFee.priceBreakpoint +
        (price - percentServiceFee.priceBreakpoint) * percentServiceFee.feePercentAbove;
    }

    return +serviceFee.toFixed(2);
  }

  public getTotalPrice() {
    const state: State = store.state as State;
    const { restaurant, coupon, tip } = state.basket;
    if (!restaurant) return 0;

    let price = this.getDiscountedCartPrice();

    const { deliveryInfo } = restaurant;
    price += this.getSmallOrderFee(deliveryInfo.minSOFBasketPrice, deliveryInfo.perPoundSOFFactor);
    price += this.getServiceFee(deliveryInfo.percentServiceFee);

    if (state.order.type === 'delivery' && (!coupon || coupon.type !== 'free_delivery')) {
      // NOTE: Delivering big orders takes place with usage of vans instead of bikes, so user needs to pay more:
      price += this.getCartPrice() > deliveryInfo.deliveryFeeBreakpoint ? deliveryInfo.bigOrderFee : deliveryInfo.fee;
    }

    if (price < deliveryInfo.minTotalPrice) price = deliveryInfo.minTotalPrice;

    if (tip) price += tip;

    return price.toFixed(2);
  }

  public getTotalPriceDifference() {
    const state: State = store.state as State;
    const oldTotal = state.amendableOrder.data?.totalPrice || 0;
    const newTotal = +this.getTotalPrice();
    return +(newTotal - oldTotal).toFixed(2);
  }

  public groupOrderToOrderDishes(groupOrder: GroupData): OrderDish[] {
    return groupOrder.baskets.flatMap((groupBasket: GroupOrderBasket) =>
      groupBasket.cart.dishes.map((dish) => ({
        ...dish,
        basketId: groupBasket.id,
      })),
    );
  }
  // BASKET METHODS: STOP

  // CHECKOUT METHODS: START
  get validateStripe() {
    const state = store.state as State;
    const stripe = state.stripe as StateStripe;
    const keys = Object.keys(stripe);

    if (keys.find((key: string) => stripe[key] === null)) return false;
    if (keys.find((key: string) => stripe[key].complete === false)) return false;

    return true;
  }

  public overrideBasketAndOrderData(groupOrder: GroupData) {
    const { bestDeliverToTime, worstDeliverToTime, orderType, ownerAddress } = groupOrder;

    let interval: string | undefined;
    if (bestDeliverToTime && worstDeliverToTime) {
      const bestHHmm = moment(bestDeliverToTime).tz('Europe/London').format('HH:mm');
      const worstHHmm = moment(worstDeliverToTime).tz('Europe/London').format('HH:mm');
      interval = `${bestHHmm} - ${worstHHmm}`;
    }

    // Override order details (type, dateTime):
    if (state.order.type !== orderType) store.commit('order/type', orderType);
    if (state.order.scheduledDeliveryDate !== bestDeliverToTime) {
      store.commit('order/scheduledDeliveryDate', bestDeliverToTime || null);
      store.commit('order/scheduledDeliveryInterval', interval || null);
    }

    // Override address:
    store.commit('basket/isTooFar', false);
    if (
      state.address.cityName?.toLowerCase() !== ownerAddress.cityName.toLowerCase() ||
      state.address.formattedAddress !== ownerAddress.formattedAddress
    ) {
      store.commit('address/set', {
        lat: ownerAddress.lat,
        lng: ownerAddress.lng,
        cityName: ownerAddress.cityName,
        postcode: ownerAddress.postcode,
        streetNumber: ownerAddress.streetNumber,
        streetName: ownerAddress.streetName,
        formattedAddress: ownerAddress.formattedAddress,
      });
    }
  }
  // CHECKOUT METHODS: STOP
}

export default new RestaurantMethods();
