import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/landing/main_list_of_logos.png'
import _imports_1 from '../assets/landing/variants/corporate-breakfast-delivery-header.png'
import _imports_2 from '../assets/landing/restaurant_cards/emmeline_logo.png'
import _imports_3 from '../assets/landing/restaurant_cards/eden_cafe_logo.png'
import _imports_4 from '../assets/landing/restaurant_cards/fed_logo.png'
import _imports_5 from '../assets/landing/restaurant_cards/hobbs_bakery_logo.png'
import _imports_6 from '../assets/landing/restaurant_cards/cafe_dream_logo.png'
import _imports_7 from '../assets/landing/restaurant_cards/vg_coffee_logo.png'
import _imports_8 from '../assets/landing/restaurant_cards/fitzbillies_logo.png'
import _imports_9 from '../assets/landing/restaurant_cards/bridges_logo.png'
import _imports_10 from '../assets/landing/restaurant_cards/scotts_all_day_brunch_logo.png'
import _imports_11 from '../assets/landing/restaurant_cards/hot_numbers_logo.png'
import _imports_12 from '../assets/landing/carousel_left.png'
import _imports_13 from '../assets/landing/carousel_right.png'
import _imports_14 from '../assets/landing/variants/corporate-breakfast-delivery-divider.png'
import _imports_15 from '../assets/landing/divider_list_of_logos.png'
import _imports_16 from '../assets/landing/divider_list_of_logos_mobile.png'
import _imports_17 from '../assets/order_tracking/message.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "header_section" }
const _hoisted_3 = { class: "header_section_body" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "order_buttons" }
const _hoisted_6 = { class: "breakfast_options_section" }
const _hoisted_7 = { class: "sub_title" }
const _hoisted_8 = { class: "divider_contact" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_TickerTape = _resolveComponent("TickerTape")!
  const _component_WeSupportLocal = _resolveComponent("WeSupportLocal")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Slide = _resolveComponent("Slide")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_ThePerfectFood = _resolveComponent("ThePerfectFood")!
  const _component_BuiltForEase = _resolveComponent("BuiltForEase")!
  const _component_SustainableAndEthical = _resolveComponent("SustainableAndEthical")!
  const _component_HowItWorks = _resolveComponent("HowItWorks")!
  const _component_EverybodyWins = _resolveComponent("EverybodyWins")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, "Corporate Breakfast Delivery in " + _toDisplayString(_ctx.cityName), 1),
        _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "sub_title" }, "• Amazing corporate breakfasts, delivered to the office", -1)),
        _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "sub_title" }, "• Breakfast bundles and buffets from independent restaurants", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "sub_title" }, "• A curated list of top rated breakfast and brunch spots", -1)),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_app_button, {
            slim: "",
            onClick: _ctx.orderNow
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconServices, { icon: "upload" }),
              _cache[1] || (_cache[1] = _createElementVNode("p", null, "Show me food", -1))
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_app_button, {
            slim: "",
            white: "",
            onClick: _ctx.goToEnquiryPage
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconServices, { icon: "gift" }),
              _cache[2] || (_cache[2] = _createElementVNode("p", null, "Plan something bigger", -1))
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _createElementVNode("p", {
          class: "how-does-it-work-question custom-cursor-hover",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollToHowItWorks && _ctx.scrollToHowItWorks(...args)))
        }, "How does it work?"),
        _cache[6] || (_cache[6] = _createElementVNode("p", { class: "quote" }, [
          _createTextVNode(" “"),
          _createElementVNode("b", null, "The toasties were incredible and their portions were huge. Everyone was happy with the breakfast, thank you so much!"),
          _createTextVNode("” - Ultraleap ")
        ], -1)),
        _cache[7] || (_cache[7] = _createElementVNode("img", {
          id: "main_list_of_logos",
          src: _imports_0,
          alt: ""
        }, null, -1))
      ]),
      _cache[8] || (_cache[8] = _createElementVNode("img", {
        class: "header_image",
        src: _imports_1,
        alt: ""
      }, null, -1))
    ]),
    _createVNode(_component_TickerTape),
    _createElementVNode("div", _hoisted_6, [
      _cache[22] || (_cache[22] = _createElementVNode("h1", { class: "section_title" }, "Popular corporate breakfast options", -1)),
      _createElementVNode("h2", _hoisted_7, [
        _cache[9] || (_cache[9] = _createTextVNode(" Corporate breakfasts and brunches, delivered to your office ")),
        _createVNode(_component_WeSupportLocal, {
          color: _ctx.ham,
          width: 100,
          height: 100
        }, null, 8, ["color"])
      ]),
      _createVNode(_component_Carousel, {
        class: "occasions_carousel",
        breakpoints: _ctx.breakpoints,
        "wrap-around": true
      }, {
        addons: _withCtx(() => [
          _createVNode(_component_Navigation, null, {
            prev: _withCtx(() => _cache[20] || (_cache[20] = [
              _createElementVNode("img", {
                class: "carousel_nav_arrow",
                src: _imports_12,
                alt: ""
              }, null, -1)
            ])),
            next: _withCtx(() => _cache[21] || (_cache[21] = [
              _createElementVNode("img", {
                class: "carousel_nav_arrow",
                src: _imports_13,
                alt: ""
              }, null, -1)
            ])),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          (_ctx.cityName === 'Bristol')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "emmeline" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Breakfasts",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createElementVNode("div", { class: "card_image emmeline" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_2,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Emmeline"),
                        _createElementVNode("p", null, "BANG tidy grilled cheese!"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Grilled Cheese"),
                          _createElementVNode("p", null, "Fresh juices")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Bristol')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "eden_cafe" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Breakfasts",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createElementVNode("div", { class: "card_image eden_cafe" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_3,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Eden Cafe"),
                        _createElementVNode("p", null, "Delicious plant-based food"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Breakfasts & Nourish bowls"),
                          _createElementVNode("p", null, "Vegan")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Bristol')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "fed" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Breakfasts",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createElementVNode("div", { class: "card_image fed" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_4,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Fed"),
                        _createElementVNode("p", null, "A plethora of flavour"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Seasonal salads"),
                          _createElementVNode("p", null, "Healthy")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Bristol')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "hobbs_bakery" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Breakfasts",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createElementVNode("div", { class: "card_image hobbs_bakery" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_5,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Hobbs Bakery"),
                        _createElementVNode("p", null, "B-Corp Bakery Baby"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Baked goods - sweet & savoury")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Bristol')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "cafe_dream" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Breakfasts",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createElementVNode("div", { class: "card_image cafe_dream" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_6,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Cafe Dream"),
                        _createElementVNode("p", null, "Quality, independent hearty food"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Omelettes"),
                          _createElementVNode("p", null, "Paninis & Wraps")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Cambridge')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "vg_coffee" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Breakfasts",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createElementVNode("div", { class: "card_image vg_coffee" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_7,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "VG Coffee"),
                        _createElementVNode("p", null, "Vegan cakes and treats"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Cakes, cookies & loafs"),
                          _createElementVNode("p", null, "Vegan")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Cambridge')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "fitzbillies" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Breakfasts",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[16] || (_cache[16] = [
                      _createElementVNode("div", { class: "card_image fitzbillies" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_8,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Fitzbillies"),
                        _createElementVNode("p", null, "Delicious baked goods in Cambridge"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Baked goods"),
                          _createElementVNode("p", null, "Perfect for sharing")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Cambridge')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "bridges" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Breakfasts",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[17] || (_cache[17] = [
                      _createElementVNode("div", { class: "card_image bridges" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_9,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Bridges"),
                        _createElementVNode("p", null, "Fresh, locally-sourced, home-cooked"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Seasonal salads"),
                          _createElementVNode("p", null, "Sandwiches")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Cambridge')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "scotts_all_day_brunch" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Breakfasts",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[18] || (_cache[18] = [
                      _createElementVNode("div", { class: "card_image scotts_all_day_brunch" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_10,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Scotts All Day Brunch"),
                        _createElementVNode("p", null, "Cafe by day and a pizzeria by night"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Brunch"),
                          _createElementVNode("p", null, "Salads and sandwiches")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Cambridge')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "hot_numbers" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Breakfasts",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[19] || (_cache[19] = [
                      _createElementVNode("div", { class: "card_image hot_numbers" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_11,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Hot Numbers"),
                        _createElementVNode("p", null, "Cambridge’s finest roastery"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Fresh baguettes"),
                          _createElementVNode("p", null, "Bundles")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["breakpoints"])
    ]),
    _cache[26] || (_cache[26] = _createElementVNode("img", {
      class: "divider_image",
      src: _imports_14,
      alt: ""
    }, null, -1)),
    _createVNode(_component_ThePerfectFood, {
      title: "The perfect breakfasts for your office",
      subtitle: `Breakfast deliveries made easy and delicious for your office. We've partnered with the best independent breakfast spots and corporate caterers to ensure we deliver a great variety of breakfasts fit for any diet, budget and preference in your office. Breakfast in the office has never been made simpler in ${_ctx.cityName}.`,
      onOrderNowClick: _ctx.orderNow,
      onPlanSomethingBiggerClick: _ctx.goToEnquiryPage
    }, null, 8, ["subtitle", "onOrderNowClick", "onPlanSomethingBiggerClick"]),
    _createVNode(_component_BuiltForEase, { onHereClick: _ctx.openFreshchat }, null, 8, ["onHereClick"]),
    _cache[27] || (_cache[27] = _createElementVNode("div", { class: "divider_list_of_logos" }, [
      _createElementVNode("img", {
        class: "hide_mobile",
        src: _imports_15,
        alt: ""
      }),
      _createElementVNode("img", {
        class: "hide_desktop",
        src: _imports_16,
        alt: ""
      })
    ], -1)),
    _createVNode(_component_SustainableAndEthical),
    _createVNode(_component_HowItWorks, { title: "Ordering breakfast to the office" }, {
      "pick-from": _withCtx(() => _cache[23] || (_cache[23] = [
        _createElementVNode("p", null, [
          _createTextVNode("Pick from our "),
          _createElementVNode("b", null, "curated list"),
          _createTextVNode(" of corporate breakfast restaurants in Cambs/Bristol")
        ], -1)
      ])),
      _: 1
    }),
    _createElementVNode("div", _hoisted_8, [
      _cache[25] || (_cache[25] = _createElementVNode("p", null, "Got a few questions? Give our team a shout", -1)),
      _createVNode(_component_app_button, {
        slim: "",
        white: "",
        onClick: _ctx.openFreshchat
      }, {
        default: _withCtx(() => _cache[24] || (_cache[24] = [
          _createElementVNode("img", {
            src: _imports_17,
            alt: ""
          }, null, -1),
          _createElementVNode("p", null, "Chat to the team", -1)
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_EverybodyWins)
  ]))
}