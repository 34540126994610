<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 83 43" height="42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M52.5251 24.1388L50.772 24.2062L49.0189 20.6326L45.3105 18.9469L41.2649 18.4749L37.3541 19.2166L33.4434 20.3629L31.3532 23.7342L30.4092 23.6668L32.0948 19.6212L36.4776 17.7332L41.13 16.9241L41.2649 17.1264L45.9847 17.9355L50.3001 19.756L52.5251 24.1388Z"
      fill="#212121"
    />
    <path
      d="M5.19138 28.3192L5.93308 28.9261L6.06793 28.9935H5.93308L8.56272 24.0713L10.181 22.5879L11.4621 22.4531L12.878 22.7228L14.2266 22.8577L18.6093 22.1834L22.3852 19.9583L25.352 16.7893L26.7005 13.7551L31.6901 10.0466L31.4878 10.2489V9.17004H30.881L30.2067 8.36092L29.2627 7.07981L29.3301 6.8101L28.1839 5.73128V5.66385L28.2513 5.86613L22.3178 9.30489L21.0367 12.4065L18.2722 15.9127L14.2266 17.6658L9.91125 18.3401L8.56272 18.0029L7.14676 18.3401L5.86565 18.2052L4.04513 19.5537L2.35946 23.1274L2.49432 22.9925L2.22461 23.1274L3.03373 25.2176L2.9663 25.285L3.77542 25.9593L3.84285 26.229L4.38226 27.1055L4.58454 26.9707L4.24741 27.2404L5.19138 27.9821V28.3192Z"
      fill="#FFFCF7"
    />
    <path
      d="M33.9156 10.9231L32.6345 9.77687L32.8368 9.64202L32.1625 8.69804L32.3648 8.22606L31.6906 7.21466L31.286 7.48436L30.9489 6.54039L30.6117 6.40554L30.4094 5.66384H30.0049L29.7352 4.92215L29.4655 4.85472L29.2632 4.45016L28.6564 4.0456L28.3866 4.31531L23.2622 1.21368L17.3961 0.471987V0L11.9345 0.741694L7.01238 3.30391L3.91075 6.47296L1.55081 10.1814L0 14.3619V18.812L0.674267 23.1948H0.741694L0.876547 23.5993H1.14625L1.41596 24.6782L1.61824 24.6107L1.95537 25.757L2.35993 25.6896L2.83192 26.4987L2.69707 26.9707L3.43876 27.7798L3.30391 27.9147L3.84332 29.0609L4.18046 29.2632L4.85472 29.8026L4.98958 30.0723L6.67524 31.6905V31.6231L11.7997 34.3202L17.4635 34.9945L22.9251 34.455L27.5775 31.6231L31.0163 28.5889L33.2414 24.5433L34.3876 20.1606L35.0619 15.5756L33.3762 11.1928L33.9156 10.9231ZM4.65244 26.9707L4.18046 27.3078L3.70847 26.2964L3.50619 26.1616L3.37134 25.0827V25.0153L2.0228 23.1948L2.29251 23.0599L2.15765 22.9925L4.45016 19.756L5.86612 18.2052L7.14723 18.3401H8.56319L9.91173 18.5423L14.2945 17.7332L18.0029 15.4407L20.9697 12.2717L22.1834 9.1026L28.2518 5.66384L27.9821 5.73127H28.0495L29.2632 6.67524L29.398 6.8101L30.2746 8.15863L31.0837 8.90032V9.23746L31.6231 9.97915L31.6906 10.114L26.7684 13.8225L25.1502 16.7218L22.4531 20.1606L18.4749 21.9811L14.1596 22.9925L12.8111 22.9251L11.4625 22.5205L10.2489 22.9251L8.76547 24.2062L6.0684 29.0609L6.27068 28.8586H6.0684L5.39414 28.2518L5.25928 27.9821L4.51759 27.1055L4.65244 26.9707Z"
      fill="#212121"
    />
    <path
      d="M81.0471 11.4625L81.4517 11.3277L80.4403 10.0466L80.8449 9.77686L79.9009 9.03517V8.69803L79.2266 7.75406L78.8895 6.74266L78.6872 6.47295L78.2152 5.93354L77.7432 6.00097L77.3387 5.39413H77.069L76.8667 4.98957L76.125 4.71986L76.3947 4.38273L71.2028 1.2811L65.2018 0.539404V0.202271L59.8751 1.68566L54.7507 3.64103L51.9188 7.01237L49.1543 10.4511L48.3452 14.7664L47.6709 19.0817L48.6149 23.3971L48.8846 23.3296L48.7497 23.9365H49.0194L49.3566 25.0153L49.0194 25.2176L50.0983 26.0267L49.6937 26.3638L50.3005 27.173L50.4354 27.3752L51.2445 28.1844L51.9188 29.1958V29.7352L52.5256 30.342H52.9302L54.4136 32.1625L54.6833 31.6905L59.6729 34.455L65.3367 35.1967L70.596 34.2528L75.5856 32.0951L79.0243 28.926L81.0471 24.7456L82.8002 20.4303L83.0025 15.7104L81.0471 11.4625ZM51.9188 27.5775L51.6491 26.4987H51.2445L51.1771 25.4199L50.7725 25.5547L50.0308 23.4645H49.9634L52.1211 20.228L53.6045 18.9469L54.7507 18.7446L56.2341 18.4749L57.5826 18.6098L61.8305 17.8006L65.7413 15.9801L68.3709 12.5414L70.2589 9.77686L75.9227 6.20325L75.8553 5.93354L75.7204 6.20325L77.2712 6.81009L76.9341 7.3495L78.3501 8.22605L77.9455 8.49575L78.4175 8.15862L78.6872 9.30488L78.9569 9.43973L79.6986 10.2488L79.4289 10.3837L79.5637 10.5186L74.5742 13.9573L72.7536 16.7218L70.3937 20.4303L66.2807 21.9811L61.9654 22.9251L60.6168 22.8576L59.1334 22.9925L57.9198 23.1273L56.0318 24.2736L53.4696 29.3306L53.7393 29.398L53.8067 29.1283L52.9302 28.7238L52.7953 28.4541L51.9188 27.5775Z"
      fill="#212121"
    />
    <path
      d="M53.5365 29.398L53.8062 29.3306L54.0759 29.4655L56.5707 24.6108L57.8518 22.8577L59.1329 23.1274L60.6163 22.9251L61.9648 23.1948L66.2127 22.2508L69.9212 20.0932L73.0903 17.1264L74.5736 14.227L79.3609 10.2489L79.4284 10.586L79.5632 10.5186L79.0238 9.64203L78.6867 9.50717L78.417 8.36092L78.3495 8.42835V8.36092L77.2033 7.21467V6.94496L76.1919 5.79871L75.9896 5.86613L75.9222 6.00099L69.9886 9.64203L68.5052 12.6088L65.6059 15.7104L61.8974 17.9355L57.6495 18.3401L56.2336 18.8795L54.8176 18.2052L53.6714 18.7446L51.7834 19.8909L49.9629 23.3971H50.0977L49.9629 23.4645L51.0417 25.3524L50.9743 25.4873L51.5137 26.2964L51.716 26.4987L52.2554 27.3078L52.5925 28.5889L52.9297 28.6564L53.5365 29.398Z"
      fill="#FFFCF7"
    />
    <path
      d="M27.1729 42.4114L27.1055 41.6697L31.6905 40.5234L36.4778 40.3212L41.1976 39.5795L45.9175 39.04L50.6374 38.2309L55.4247 38.0961L55.5595 39.2423L50.8397 39.7143L46.1198 40.6583L41.3325 40.8606L36.6801 41.872L31.8928 42.4788L27.1729 42.4114Z"
      fill="#212121"
    />
    <defs>
      <clipPath id="clip0">
        <rect width="82.8" height="42.4788" fill="#FFFCF7" />
      </clipPath>
    </defs>
  </svg>
  <!-- eslint-enable -->
</template>
