<template>
  <app-modal class="delivery-details-modal" v-bind="options">
    <h1>Our fees</h1>
    <div class="divider ham full-width" />
    <h3>Delivery fee</h3>
    <p>We calculate our delivery fee based on the delivery distance and vehicle type required for delivery.</p>
    <h3>Service fee</h3>
    <p>
      The service fee is {{ serviceFeePercentBelow }}% of your order (up to {{ serviceFeePriceBreakpoint }}) then
      {{ serviceFeePercentAbove }}% over {{ serviceFeePriceBreakpoint }}.
    </p>
    <h3>Small order fee</h3>
    <p>
      Orders up to {{ minSOF }} basket price will incur a small order fee. We add {{ sofFactor }} for each £1 under the
      threshold. To avoid the fee, please add more items to your basket.
    </p>
    <br />
    <app-button @click.prevent="close">Got it</app-button>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

@Options({
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class ModalDeliveryFee extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: false,
  };

  get serviceFeePercentBelow() {
    const { deliveryInfo } = this.$store.state.basket.restaurant!;
    return deliveryInfo.percentServiceFee.feePercentBelow * 100;
  }

  get serviceFeePercentAbove() {
    const { deliveryInfo } = this.$store.state.basket.restaurant!;
    return deliveryInfo.percentServiceFee.feePercentAbove * 100;
  }

  get serviceFeePriceBreakpoint() {
    const { deliveryInfo } = this.$store.state.basket.restaurant!;
    return this.toPounds(deliveryInfo.percentServiceFee.priceBreakpoint);
  }

  get minSOF() {
    const { deliveryInfo } = this.$store.state.basket.restaurant!;
    return this.toPounds(deliveryInfo.minSOFBasketPrice);
  }

  get sofFactor() {
    const { deliveryInfo } = this.$store.state.basket.restaurant!;
    return this.toPounds(deliveryInfo.perPoundSOFFactor);
  }

  private close() {
    this.$store.commit('modals/close');
  }

  private toPounds(value: number) {
    if (value > 0 && value < 1) {
      return `${Math.round(value * 100)}p`;
    }
    if (Math.round(value) === value) {
      return `£${Math.round(value)}`;
    }
    return `£${value}`;
  }
}
</script>

<style lang="scss">
.delivery-details-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  .modal {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }
  h1 {
    font-size: 35px;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  h3 {
    font-size: 20px;
    margin: 15px 0 4px;
  }
  p {
    @include p1;
    font-weight: 300;
    color: $coal60;
  }
  .controller-button {
    width: 100%;
    min-height: 50px;
  }
}
</style>
