import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "too-far-modal-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "too-far-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "too-far-modal-title" }, "Too far to deliver", -1)),
      _createElementVNode("p", _hoisted_1, " Unfortunately you're outside of our delivery radius for " + _toDisplayString(_ctx.data.restaurantName), 1),
      _createVNode(_component_app_button, {
        onClick: _withModifiers(_ctx.searchNearby, ["prevent"])
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Search nearby restaurants")
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 16))
}