<template>
  <app-modal class="tracked-order-details-modal" v-bind="options">
    <app-button class="tracked-order-details-modal-close" mini white @click.prevent="close">
      <IconServices class="services-icon" icon="add" close />
    </app-button>

    <div class="tracked-order-details-section additional-padding">
      <h2>Order details</h2>
    </div>

    <div class="separator" />

    <div class="tracked-order-details-section">
      <div class="row">
        <IconServices icon="hash" />
        <p>
          Your order ID is <b>{{ data.friendlyId }}</b>
        </p>
      </div>
      <div class="row">
        <IconServices icon="forkAndKnife" />
        <p>
          <b>
            {{ data.restaurantName }}
          </b>
        </p>
      </div>
      <p class="additional-info" v-if="data.kitchenNotes">“{{ data.kitchenNotes }}”</p>
    </div>

    <div class="separator" />

    <div class="tracked-order-details-section">
      <p class="section-title">Your items ({{ numberOfItems }})</p>
      <TrackedOrderItems :cart="data.cart" :groupBaskets="data.groupBaskets || []" />
    </div>

    <div class="separator" />

    <div class="tracked-order-details-section">
      <div class="price-row">
        <b>Total</b>
        <b>£{{ data.totalPrice.toFixed(2) }}</b>
      </div>
      <div class="coal-border" />
      <div v-if="data.type === 'delivery'" class="price-row">
        <p>Delivery fee & tips</p>
        <b>£{{ (data.deliveryPrice + (data.tipAmount ?? 0)).toFixed(2) }}</b>
      </div>
      <div v-if="data.serviceFee > 0" class="price-row">
        <p>Service fee</p>
        <b>£{{ data.serviceFee.toFixed(2) }}</b>
      </div>
      <div v-if="data.smallOrderFee > 0" class="price-row">
        <p>Small order fee</p>
        <b>£{{ data.smallOrderFee.toFixed(2) }}</b>
      </div>
      <div v-if="discount > 0" class="price-row">
        <p>Discount</p>
        <b>-£{{ discount.toFixed(2) }}</b>
      </div>
    </div>

    <div class="separator" />

    <div class="tracked-order-details-section additional-padding">
      <p class="section-title">{{ data.type === 'delivery' ? 'Delivery details' : 'Collection details ' }}</p>
      <div class="row">
        <IconServices icon="house" />
        <b>{{ address }}</b>
      </div>
      <p class="additional-info" v-if="data.deliveryNotes">“{{ data.deliveryNotes }}”</p>
    </div>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import TrackedOrderItems from '@/components/TrackedOrderItems.vue';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';
import IconServices from '@/shared/icons/IconServices.vue';

@Options({
  components: { IconServices, TrackedOrderItems },
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class ModalTrackedOrderDetails extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: false,
    noPadding: true,
  };

  private close() {
    this.$store.commit('modals/close');
  }

  get discount() {
    const { cartPrice, serviceFee, smallOrderFee, deliveryPrice, tipAmount, totalPrice } =
      this.$store.state.modals.data;

    return +(cartPrice + serviceFee + smallOrderFee + deliveryPrice + (tipAmount ?? 0.0) - totalPrice).toFixed(2);
  }

  get numberOfItems() {
    const { cart } = this.$store.state.modals.data;
    const result = cart.dishes.reduce((sum, dish) => sum + dish.amount, 0);
    return result;
  }

  get address() {
    const { address } = this.$store.state.modals.data;

    if (address.flatAndBuilding) return `${address.flatAndBuilding}, ${address.street}`;
    return address.street;
  }

  private getTotalOrderDishPrice(orderDish: any) {
    let totalPrice = orderDish.price;

    for (let i = 0; i < orderDish.modificators.length; i += 1) {
      totalPrice += orderDish.modificators[i].price;
    }

    return totalPrice.toFixed(2);
  }
}
</script>

<style lang="scss">
.tracked-order-details-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .modal {
    animation: $modal-show-bottom;
  }
}
</style>

<style lang="scss" scoped>
.tracked-order-details-modal-close {
  position: absolute;
  top: 7.5px;
  right: 7.5px;
  .close.services-icon {
    top: 9px;
    left: 9px;
    width: 21px;
    height: 21px;
  }
}

.tracked-order-details-section {
  padding: 15px 20px;
  background-color: white;

  .row {
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
      width: 20px;
    }

    p,
    b {
      line-height: 18px;
      padding-top: 4px;
    }
  }

  .price-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;

    div b,
    b,
    p {
      font-size: 15px;
    }
  }

  .item-note {
    font-size: 12px;
    line-height: 15px;
    font-style: italic;
  }

  .section-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .additional-info {
    font-style: italic;
    font-size: 14px;
    margin-left: 30px;
    margin-top: 5px;
  }

  .coal-border {
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid $coal20;
  }

  p,
  b {
    font-family:
      Sofia Pro,
      sans-serif;
  }
}

.additional-padding {
  padding-top: 20px;
  padding-bottom: 20px;
}

.separator {
  border-top: 1px solid $coal20;
  border-bottom: 1px solid $coal20;
  height: 20px;
}
</style>
