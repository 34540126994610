<template>
  <div class="restaurant-header-wrapper-desktop">
    <!-- Embedded views -->
    <template v-if="$route.query.embedded">
      <div class="action-buttons">
        <app-back-link v-if="isInCheckout" mini :link="`/menu/${restaurant?.restaurantCity}/${restaurant?.slug}`" />
        <div v-if="user">
          <ButtonOrderTracker />
          <app-button mini square white @click="goToSettings">
            <IconServices icon="account" />
          </app-button>
        </div>
      </div>
    </template>

    <!-- Standard views -->
    <template v-else>
      <app-back-link
        v-if="!(groupData && !isInCheckout)"
        mini
        :link="`${
          isInCheckout
            ? `/menu/${restaurant?.restaurantCity}/${restaurant?.slug}`
            : `/restaurants/${restaurant?.restaurantCity}`
        }`"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import ButtonOrderTracker from '@/components/Navigation/ButtonOrderTracker.vue';

import IconServices from '@/shared/icons/IconServices.vue';

@Options({
  components: {
    ButtonOrderTracker,
    IconServices,
  },
  props: {
    restaurant: [Object, Boolean],
  },
  computed: {
    ...mapState('groupOrder', ['groupData']),
    ...mapState('service', ['user']),
  },
})
export default class RestaurantHeaderDesktop extends Vue {
  get isInCheckout() {
    return this.$route.path.includes('checkout');
  }

  private goToSettings() {
    this.$router.push('/my-account');
  }
}
</script>

<style lang="scss">
.restaurant-header-wrapper-desktop {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  @include content-grid;
}

.icon-wrapper {
  @include row-centered;
  width: 38px;
  height: 38px;

  svg.icon-arrow.services-icon {
    height: 18px;
  }
}
</style>

<style lang="scss" scoped>
.action-buttons {
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;

  button {
    border: 1px solid #d3d3d3;
  }

  div {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
</style>
