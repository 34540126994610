<template>
  <div class="card" :class="{ const_dimensions: !$slots.body }">
    <div class="card_header">
      <img class="card_header_lines" src="../assets/landing/card_header_lines.png" alt="" />
      <img class="card_header_circle" src="../assets/landing/card_header_circle.png" alt="" />
      <p class="card_header_label">{{ title }}</p>
    </div>

    <slot name="body" />

    <div v-if="!$slots.body" class="card_image" :class="[imageName]" />
    <div v-if="!$slots.body" class="card_content">
      <h3>{{ title }}</h3>
      <p>{{ text }}</p>
    </div>
    <p v-if="!$slots.body" class="find_out_more custom-cursor-hover" @click="onFindOutMoreClick">Find out more</p>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    title: String,
    text: String,
    imageName: String,
    onFindOutMoreClick: Function,
  },
})
export default class Card extends Vue {}
</script>

<style lang="scss" scoped>
.card {
  box-shadow: 10px 10px 0px $coal100;
  border-radius: 15px;
  border: 2px solid $coal100;
  background-color: $vanilla;

  text-align: left;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;

  &.const_dimensions {
    height: 490px;
    width: 340px;
  }
}

.card_header {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  padding: 8.5px 10px;

  background-color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: 2px solid $coal100;

  .card_header_lines {
    width: calc(100% - 20px);
    height: 9px;
    object-fit: fill;
  }

  .card_header_circle {
    height: 12px;
  }
}

.card_header_label {
  position: absolute;
  font-size: 13px;
  height: 20px;
  font-weight: 400;
  font-family:
    Sofia Pro,
    sans-serif;

  background-color: white;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  padding-left: 10px;
  padding-right: 10px;
}

.card_image {
  height: 200px;
  width: 100%;
  border-bottom: 2px solid $coal100;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.office_lunches {
  background-image: url('../assets/landing/occasions/office_lunches.png');
}
.office_breakfasts {
  background-image: url('../assets/landing/occasions/office_breakfasts.png');
}
.corporate_catering {
  background-image: url('../assets/landing/occasions/corporate_catering.png');
}
.bespoke_events {
  background-image: url('../assets/landing/occasions/bespoke_events.png');
}
.daily_office_meals {
  background-image: url('../assets/landing/occasions/daily_office_meals.png');
}

.card_content {
  padding: 25px;

  h3 {
    font-family: Recoleta, sans-serif;
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 10px;
  }

  p {
    font-family:
      Sofia Pro,
      sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
  }
}

.find_out_more {
  position: absolute;
  bottom: 20px;
  right: 20px;

  font-size: 16px;
  font-family:
    Sofia Pro,
    sans-serif;
  font-weight: 300;
  text-decoration: underline;
}
</style>
