import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "search-input-wrapper" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "change-address" }
const _hoisted_4 = {
  key: 1,
  class: "search-input"
}
const _hoisted_5 = { class: "label" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = {
  key: 0,
  class: "search-list"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 2 }
const _hoisted_10 = {
  key: 3,
  style: {"height":"28px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isEditing)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "search-input",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleIsEditing && _ctx.toggleIsEditing(...args)))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_IconServices, { icon: "locationLight" }),
            _createElementVNode("p", {
              class: _normalizeClass({ 'basic-font': _ctx.isBasicFont })
            }, _toDisplayString(_ctx.search || _ctx.placeholder), 3)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_IconServices, { icon: "pencil" })
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_IconServices, { icon: "locationLight" }),
            _withDirectives(_createElementVNode("input", {
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.searching && _ctx.searching(...args))),
              onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.searching && _ctx.searching(...args))),
              onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args))),
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.search) = $event)),
              type: "text",
              placeholder: _ctx.placeholder,
              "aria-label": "Enter address",
              class: _normalizeClass({ 'basic-font': _ctx.isBasicFont, error: _ctx.$slots.error && _ctx.showError })
            }, null, 42, _hoisted_6), [
              [_vModelText, _ctx.search]
            ])
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "change-address" }, null, -1)),
          (_ctx.dropdown)
            ? _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, iIndex) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "search-list-item custom-cursor-hover",
                    key: iIndex,
                    onClick: ($event: any) => (_ctx.getPlace(item))
                  }, [
                    _createVNode(_component_IconServices, { icon: "locationLight" }),
                    _createElementVNode("span", null, _toDisplayString(item.description), 1)
                  ], 8, _hoisted_8))
                }), 128))
              ])), [
                [_directive_click_outside, _ctx.close]
              ])
            : _createCommentVNode("", true)
        ])),
    (_ctx.$slots.error && _ctx.showError)
      ? (_openBlock(), _createElementBlock("small", _hoisted_9, [
          _createElementVNode("b", null, [
            _renderSlot(_ctx.$slots, "error", {}, undefined, true)
          ])
        ]))
      : (_ctx.addErrorPlaceholderSpacing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10))
        : _createCommentVNode("", true)
  ]))
}