import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "thanks-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Thank you!", -1)),
      (_ctx.data)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.data), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16))
}