<template>
  <app-modal class="gtm-new-event-modal" v-bind="options">
    <h3 class="title">Adding a new event?</h3>
    <p class="subtitle">Do you want to use your existing event details or do you need to add new event information?</p>
    <div class="buttons">
      <app-button slim @click="usePrevious">
        <p>Use existing details</p>
      </app-button>
      <app-button slim white @click="addNew">
        <p>Add new details</p>
      </app-button>
    </div>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

@Options({})
export default class ModalGtmNewEvent extends Vue {
  private options: ModalInterface = { slim: false, close: false };

  private usePrevious() {
    this.$store.commit('modals/data', { gtmNew: false });
    this.$store.commit('modals/close');
  }

  private addNew() {
    this.$store.commit('modals/data', { gtmNew: true });
    this.$store.commit('modals/close');
  }
}
</script>

<style lang="scss">
.gtm-new-event-modal {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .modal {
    animation: $modal-show-bottom;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    background-color: white;

    @include for-smartphone {
      h3 {
        text-align: center;
      }
    }
  }

  .subtitle {
    @include p1;
    font-size: 15px;
    opacity: 0.5;
    max-width: 320px;
    margin-top: 15px;
    text-align: center;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 25px;

    button {
      width: fit-content;
      border: 1px solid black;
      border-radius: 7.5px;
      gap: 7.5px;
      padding: 12.5px 20px;
    }

    p {
      @include p1;
      font-size: 15px;
      height: 17px;
    }

    @include for-smartphone {
      flex-direction: column;
      gap: 10px;

      button {
        width: 180px;
      }
    }
  }
}
</style>
