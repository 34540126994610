<template>
  <div id="scroll-measure" />

  <CategoriesBar :class="{ transition }" :categories="restaurantData.menuCategories" @select="selectCategories" />

  <main
    class="restaurant-info"
    :class="{
      transition,
      'with-step-tracker':
        groupData && !amendableData && (!groupOrderIdFromUrl || groupOrderIdFromUrl === groupData.id),
    }"
  >
    <GroupOrderStepTracker
      v-if="groupData && !amendableData && (!groupOrderIdFromUrl || groupOrderIdFromUrl === groupData.id)"
    />
    <About :restaurant="restaurantData.restaurant" :wholeMenuMinHoursInAdvance="wholeMenuMinHoursInAdvance" />
    <Basket :restaurant="restaurantData.restaurant" />
    <div class="restaurant-info-loader" v-if="updating">
      <Logo class="logo-loader" />
    </div>
    <div v-else class="restaurant-menu">
      <div
        class="min-cart-price-info"
        v-if="
          (restaurantData.restaurant.minCartPrice || restaurantData.restaurant.discountPercentage) &&
          groupRole !== 'Participant'
        "
      >
        {{
          restaurantData.restaurant.minCartPrice
            ? // eslint-disable-next-line
              `A £${restaurantData.restaurant.minCartPrice} minimum is required to order from ${restaurantData.restaurant.name}.`
            : ''
        }}
        <br
          v-if="
            restaurantData.restaurant.minCartPrice &&
            restaurantData.restaurant.discountPercentage &&
            isDeliveryLateEnoughToGetDiscount
          "
        />
        {{
          restaurantData.restaurant.discountPercentage && isDeliveryLateEnoughToGetDiscount
            ? // eslint-disable-next-line
              `A ${restaurantData.restaurant.discountPercentage}% discount will be automatically applied to orders over £${restaurantData.restaurant.discountMinCartPrice}.`
            : ''
        }}
        {{
          restaurantData.restaurant.discountPercentage && !isDeliveryLateEnoughToGetDiscount
            ? // eslint-disable-next-line
              `Place a £${restaurantData.restaurant.discountMinCartPrice} order ${restaurantData.restaurant.discountMinHoursInAdvance} hours in advance and a ${restaurantData.restaurant.discountPercentage}% discount will be automatically applied to your basket.`
            : ''
        }}
      </div>
      <Menu
        :id="menu.slug"
        :menu="menu"
        :slug="restaurantData.restaurant.slug"
        v-for="(menu, mIndex) in restaurantData.menuCategories"
        :key="mIndex"
        :restaurant="restaurantData.restaurant"
      />
    </div>
  </main>

  <div
    class="show-basket-wrapper"
    v-if="!amendableData && (($route.params.slug === restaurant?.slug && cartPrice) || groupData)"
  >
    <app-button @click="openBasketModal" class="show-basket">
      <strong v-if="groupRole === 'Participant' && areItemsConfirmed">Items added</strong>
      <strong v-else-if="groupRole === 'Participant' && !areItemsConfirmed">
        Add your items - £{{ cartPrice.toFixed(2) }}
      </strong>
      <strong v-else>Checkout - £{{ cartPrice.toFixed(2) }}</strong>
    </app-button>
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';
import { mapMutations, mapState } from 'vuex';

import Basket from '@/components/Basket.vue';
import About from '@/components/Restaurant/About.vue';
import CategoriesBar from '@/components/Restaurant/CategoriesBar.vue';
import Menu from '@/components/Restaurant/Menu.vue';

import Logo from '@/shared/icons/Logo.vue';

import methods from '@/utils/methods';

import GroupOrderStepTracker from './Restaurant/GroupOrderStepTracker.vue';

class Props {
  restaurantData = prop<RequestGetRestaurant>({ required: true });
  updating = prop<boolean>({ required: true });
}

@Options({
  components: {
    Basket,
    About,
    Menu,
    CategoriesBar,
    Logo,
    GroupOrderStepTracker,
  },
  props: {
    restaurantData: {
      menuCategories: Object,
      restaurant: Object,
    },
    updating: Boolean,
  },
  computed: {
    ...mapState('service', ['transition']),
    ...mapState('basket', ['restaurant']),
    ...mapState('groupOrder', ['groupData', 'groupRole', 'areItemsConfirmed']),
    ...mapState('amendableOrder', { amendableData: 'data' }),
  },
  methods: {
    ...mapMutations('modals', ['show']),
  },
})
export default class RestaurantView extends Vue.with(Props) {
  private category: MenuCategory | false = false;

  get groupOrderIdFromUrl() {
    return this.$route.query.groupId as string;
  }

  get isDeliveryLateEnoughToGetDiscount() {
    return methods.isDeliveryTimeLateEnough(this.restaurantData.restaurant.discountMinHoursInAdvance);
  }

  get wholeMenuMinHoursInAdvance() {
    const minRequiredNotices = this.restaurantData.menuCategories.map((mc) => mc.minHoursInAdvance);
    if (minRequiredNotices.every((el) => el)) return minRequiredNotices.reduce((a, b) => Math.min(a, b), 0);
    return 0;
  }

  private selectCategories(category: MenuCategory) {
    this.category = category;
  }

  get cartPrice() {
    const { groupRole } = this.$store.state.groupOrder;
    if (groupRole === 'Participant') return methods.getActiveUserCartPrice();
    return methods.getCartPrice();
  }

  private openBasketModal() {
    this.$store.commit('modals/data', { ...this.restaurantData.restaurant });
    this.$store.commit('modals/show', 'basket');
  }
}
</script>

<style lang="scss">
.restaurant {
  &-back {
    max-width: 1212px;
    width: 100%;
    padding: 0 16px;

    &-link {
      margin-bottom: 15px;

      @include for-smartphone {
        display: none;
      }
    }
  }

  &-menu {
    .restaurant-menu-wrapper {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      @include for-smartphone {
        margin-bottom: 30px;
        background-color: $vanilla;
      }
    }
  }

  &-info {
    @include content-grid;
    height: 100%;
    padding: 16px 16px;
    display: grid;
    gap: 20px 25px;
    margin: 0 0 60px;
    grid-template-rows: max-content;
    grid-template-columns: minmax(0, 1fr) 375px;
    grid-template-areas:
      'about basket'
      'menu basket';

    @include for-smartphone {
      grid-template-columns: 1fr;
      grid-template-areas:
        'about'
        'menu';
    }

    &.with-step-tracker {
      grid-template-areas:
        'step-tracker basket'
        'about basket'
        'menu basket';

      @include for-smartphone {
        grid-template-columns: 1fr;
        grid-template-areas:
          'step-tracker'
          'about'
          'menu';
      }
    }

    .restaurant-about-wrapper {
      grid-area: about;
      max-height: 270px;
      @include for-smartphone {
        display: none;
      }
    }
    .restaurant-menu-wrapper {
      grid-area: menu;
    }
    .group-order-step-tracker {
      grid-area: step-tracker;
    }
    .restaurant-basket {
      grid-area: basket;
      @include for-smartphone {
        display: none;
      }
    }
    &-loader {
      display: flex;
      justify-content: center;
      margin: 50px 0 200px;
    }
  }
}

.show-basket {
  position: relative;
  display: none;
  @include for-smartphone {
    display: block;
  }
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 10px;
    z-index: 100;
  }
}

.min-cart-price-info {
  font-family:
    Sofia Pro,
    sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  background-color: $hamOp50;
  padding: 15px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  text-align: center;

  @include for-smartphone {
    font-size: 14px;
    br {
      display: none;
    }
  }
}
</style>
