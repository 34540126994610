import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/landing/sustainable_and_ethical.png'
import _imports_1 from '../../assets/landing/sustainable_delivery.png'
import _imports_2 from '../../assets/landing/sustainable_local.png'
import _imports_3 from '../../assets/landing/sustainable_welfare.png'
import _imports_4 from '../../assets/landing/sustainable_foodstuff.png'


const _hoisted_1 = { class: "sustainable_and_ethical_section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<img class=\"sustainable_image\" src=\"" + _imports_0 + "\" alt=\"\" data-v-181772e1><div class=\"sustainable_and_ethical_texts\" data-v-181772e1><h1 class=\"section_title\" data-v-181772e1>Sustainable and ethical</h1><h2 class=\"sub_title\" data-v-181772e1>We believe food tastes better when it&#39;s at the heart of doing good</h2><div class=\"sustainable_rows\" data-v-181772e1><div class=\"sustainable_row\" data-v-181772e1><img src=\"" + _imports_1 + "\" alt=\"\" data-v-181772e1><div class=\"sustainable_row_texts\" data-v-181772e1><b data-v-181772e1>Planet-friendly delivery</b><p data-v-181772e1>100% of our deliveries are emission-free, by riders on bikes or in e-vans</p></div></div><div class=\"sustainable_row\" data-v-181772e1><img src=\"" + _imports_2 + "\" alt=\"\" data-v-181772e1><div class=\"sustainable_row_texts\" data-v-181772e1><b data-v-181772e1>Supporting local</b><p data-v-181772e1>Only the best independent restaurants and caterers in each city</p></div></div><div class=\"sustainable_row\" data-v-181772e1><img src=\"" + _imports_3 + "\" alt=\"\" data-v-181772e1><div class=\"sustainable_row_texts\" data-v-181772e1><b data-v-181772e1>Rider welfare</b><p data-v-181772e1>Our riders are paid a guaranteed living wage so they deliver with a smile</p></div></div></div></div><img class=\"sustainable_corner_logo\" src=\"" + _imports_4 + "\" alt=\"\" data-v-181772e1>", 3)
  ])))
}