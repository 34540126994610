import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "account-wrapper" }
const _hoisted_2 = { class: "account-star" }
const _hoisted_3 = { class: "account-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "bgc" }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_IconServices, { icon: "bigStar" }),
        _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view)
      ])
    ])
  ], 64))
}