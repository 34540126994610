import { GroupData, StateGroupOrder } from '@/store/modules/groupOrder';

import http from '@/utils/http';

class HttpGroupOrders {
  public createGroupOrder(orderDetails: any): Promise<string> {
    return http
      .post('/group-orders', orderDetails)
      .then((res: any) => {
        if (res.id) return res.id;

        throw new Error(res);
      })
      .catch((err: any) => {
        throw new Error(err);
      });
  }

  public getGroupOrder(groupOrderId: string): Promise<StateGroupOrder['groupData']> {
    return http
      .get(`/group-orders/${groupOrderId}`)
      .then((res: any) => res)
      .catch((err: any) => {
        throw new Error(err);
      });
  }

  public getMyGroupOrder(): Promise<StateGroupOrder['groupData']> {
    return http.get('/group-orders/my-order').catch((err: any) => {
      if (err === 'Unauthorized' || err === 'Invalid credentials') return;
      if (err === 'You are not an owner of this group order') return;
      throw new Error(err);
    });
  }

  public cancelGroupOrder(groupOrderId: string): Promise<string> {
    return http
      .put(`/group-orders/${groupOrderId}/cancel`, {})
      .then((res: any) => {
        if (res) return res;

        throw new Error(res);
      })
      .catch((err: any) => {
        throw new Error(err);
      });
  }

  public leaveGroupOrder(groupOrderId: string, basketId: string): Promise<string> {
    return http
      .delete(`/group-orders/${groupOrderId}/baskets/${basketId}`)
      .then((res: any) => {
        if (res) return res;

        throw new Error(res);
      })
      .catch((err: any) => {
        throw new Error(err);
      });
  }

  public addBasketToGroupOrder(groupOrderId: string, basketData: any): Promise<{ id: string }> {
    return http
      .post(`/group-orders/${groupOrderId}/baskets`, basketData)
      .then((res: any) => {
        if (res) return res;

        throw new Error(res);
      })
      .catch((err: any) => {
        throw new Error(err);
      });
  }

  public editBasketInGroupOrder(groupOrderId: string, basketData: any): Promise<GroupData> {
    return http
      .put(`/group-orders/${groupOrderId}/baskets`, basketData)
      .then((res: any) => {
        if (res) return res;

        throw new Error(res);
      })
      .catch((err: any) => {
        throw new Error(err);
      });
  }
}

export default new HttpGroupOrders();
