import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/landing/the_perfect_food_logos.png'


const _hoisted_1 = { class: "the_perfect_food_section" }
const _hoisted_2 = { class: "the_perfect_food_texts" }
const _hoisted_3 = { class: "section_title" }
const _hoisted_4 = { class: "sub_title" }
const _hoisted_5 = { class: "order_buttons hide_mobile" }
const _hoisted_6 = { class: "order_buttons hide_desktop" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.subtitle), 1),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_app_button, {
          slim: "",
          onClick: _ctx.orderNow
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconServices, { icon: "upload" }),
            _cache[0] || (_cache[0] = _createElementVNode("p", null, "Show me food", -1))
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_app_button, {
          slim: "",
          white: "",
          onClick: _ctx.onPlanSomethingBiggerClick
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconServices, { icon: "gift" }),
            _cache[1] || (_cache[1] = _createElementVNode("p", null, "Plan something bigger", -1))
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _cache[4] || (_cache[4] = _createElementVNode("img", {
      src: _imports_0,
      alt: ""
    }, null, -1)),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_app_button, {
        slim: "",
        onClick: _ctx.orderNow
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconServices, { icon: "upload" }),
          _cache[2] || (_cache[2] = _createElementVNode("p", null, "Show me food", -1))
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_app_button, {
        slim: "",
        white: "",
        onClick: _ctx.onOrderNowClick
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconServices, { icon: "gift" }),
          _cache[3] || (_cache[3] = _createElementVNode("p", null, "Plan something bigger", -1))
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}