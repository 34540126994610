<template>
  <div class="restaurant-menu-wrapper" v-bind:class="{ closed: menu.isClosed }">
    <img v-if="restaurant.isCaterer && menu.photo" class="restaurant-menu-image hide-mobile" :src="menu.photo" alt="" />
    <div class="restaurant-menu-body">
      <div class="restaurant-menu-title">
        <h2>{{ menu.name }}</h2>
        <p v-if="!isDeliveryLateEnough && !isTooFar">
          {{ menu.minHoursInAdvance }} {{ menu.minHoursInAdvance === 1 ? 'hour' : 'hours' }} notice required to order
        </p>
        <p v-else-if="menu.isClosed">{{ menu.availability }}</p>
      </div>
      <div class="divider ham full-width" />

      <img
        v-if="restaurant.isCaterer && menu.photo"
        class="restaurant-menu-image hide-desktop"
        :src="menu.photo"
        alt=""
      />

      <div class="restaurant-menu-list" v-if="dishes">
        <RestaurantItem
          v-for="(dish, dIndex) in dishes"
          :dish="dish"
          :key="dIndex"
          :count="getCountDish(dish.id)"
          :isCaterer="restaurant.isCaterer"
          :disabled="menu.isClosed || (!isDeliveryLateEnough && !isTooFar)"
          :categoryMinHoursInAdvance="menu.minHoursInAdvance"
          @click.prevent="showDishes(dish)"
        />
      </div>
      <div class="restaurant-menu-list" v-else>
        <div class="restaurant-item-placeholder" v-for="index in menu.dishesQuantity" :key="index" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { Options, Vue, prop } from 'vue-class-component';
import { mapState } from 'vuex';

import RestaurantItem from '@/components/Restaurant/Item.vue';

import restaurant from '@/requests/restaurant';

import methods from '@/utils/methods';

class Props {
  restaurant = prop<FullInfoRestaurant>({ required: true });
  menu = prop<MenuCategory>({ required: true });
  slug = prop<string>({ required: true });
}

@Options({
  components: {
    RestaurantItem,
  },
  props: {
    menu: Object,
    slug: String,
    restaurant: Object,
  },
  computed: {
    ...mapState('basket', ['isTooFar']),
  },
})
export default class RestaurantMenu extends Vue.with(Props) {
  private dishes: Product[] | false = false;

  get isDeliveryLateEnough() {
    return methods.isDeliveryTimeLateEnough(this.menu.minHoursInAdvance);
  }

  private showDishes(dish: Product) {
    this.$store.commit('modals/data', {
      dish,
      category: { slug: this.menu.slug, minHoursInAdvance: this.menu.minHoursInAdvance, isClosed: this.menu.isClosed },
      restaurant: this.restaurant,
      deliveryLabel:
        this.$store.state.order.type === 'delivery' &&
        !this.$store.state.amendableOrder.data &&
        this.restaurant.deliveryLabel,
    });
    this.$store.commit('modals/show', 'dishes');
  }

  private getCountDish(id: number) {
    let count = 0;
    this.$store.state.basket.dishes.forEach((dish: OrderDish) => {
      if (dish.id === id) count += dish.amount;
    });

    return count;
  }

  created() {
    const { scheduledDeliveryDate } = this.$store.state.order;
    const deliverToTime = scheduledDeliveryDate
      ? moment(scheduledDeliveryDate).tz('Europe/London').toISOString()
      : undefined;
    restaurant
      .getCategory(this.$route.params.slug as string, this.menu.slug, deliverToTime)
      .then((res: { dishes: Product[] }) => {
        this.dishes = res.dishes;
      });
  }
}
</script>

<style lang="scss" scoped>
.restaurant-menu {
  &-wrapper {
    position: relative;
    background-color: white;
    border: 1px solid $coal20;
    border-radius: 15px;
    overflow: hidden;

    @include for-smartphone {
      border: none;
      border-radius: 0;
    }
  }

  &-image {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 200px;

    @include for-smartphone {
      border-radius: 15px;
      height: 150px;
      margin-top: 10px;
    }
  }

  &-body {
    padding: 30px 30px 10px 30px;
    @include for-smartphone {
      padding: 0;
    }
  }

  &-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    p {
      @include p2;
      line-height: 15px;
      color: $coal100;
      text-align: center;
    }

    @include for-smartphone {
      width: 100%;
      flex-direction: column;
      align-items: start;

      p {
        margin-top: 2px;
      }
    }
  }

  .divider {
    @include for-smartphone {
      width: 100%;
    }
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 325px);
    gap: 14px 24px;
    margin: 15px 0 40px 0;
    @include for-smartphone {
      display: block;
      margin-right: 0;
      margin-top: 5px;
      .restaurant-item-wrapper {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.restaurant-item-placeholder {
  border-bottom: 1px solid $coal10;
  height: 125px;
  width: 100%;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.hide-mobile {
  @include for-smartphone {
    display: none;
  }
}

.hide-desktop {
  @include for-desktop {
    display: none;
  }
}
</style>
