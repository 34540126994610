/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state: StateAddress = {
  cityName: '',
  postcode: '',
  streetName: '',
  streetNumber: '',
  formattedAddress: '',
};

const mutations = {
  set(state: StateAddress, payload: any) {
    state.lat = payload.lat;
    state.lng = payload.lng;
    state.cityName = payload.cityName;
    state.postcode = payload.postcode;
    state.streetName = payload.streetName;
    state.streetNumber = payload.streetNumber;
    state.formattedAddress = payload.formattedAddress;
  },
  clear(state: StateAddress) {
    delete state.lat;
    delete state.lng;
    state.cityName = '';
    state.postcode = '';
    state.streetName = '';
    state.streetNumber = '';
    state.formattedAddress = '';
  },
};

const actions = {
  //
};

const getters = {
  //
};

export interface StateAddress {
  lat?: number;
  lng?: number;
  cityName: string;
  postcode: string;
  streetName: string;
  streetNumber: string;
  formattedAddress: string;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
