<template>
  <app-modal class="confirm-cancel-group-order" :close="true">
    <h2 class="confirm-cancel-group-order__title">
      Are you sure you want to {{ groupRole === 'Owner' ? 'cancel' : 'leave' }} the group order?
    </h2>
    <p v-if="groupRole === 'Participant'" class="confirm-cancel-group-order__description">
      Your items will be removed from the basket and won’t be sent to the restaurant.
    </p>
    <div class="confirm-cancel-group-order__buttons-wrapper">
      <app-button class="confirm-cancel-group-order__cancel-button" @click.prevent="closeModal"> No </app-button>
      <app-button
        class="confirm-cancel-group-order__confirm-button"
        @click.prevent="cancelOrLeaveGroupOrder"
        :loader="loader"
      >
        Yes
      </app-button>
    </div>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import groupOrdersHttp from '@/requests/groupOrders';

@Options({
  computed: {
    ...mapState('groupOrder', ['groupRole']),
  },
})
export default class ActiveGroupOrderWarning extends Vue {
  private loader = false;

  private async cancelOrLeaveGroupOrder() {
    this.loader = true;

    const { groupData, groupRole, participantBasketId } = this.$store.state.groupOrder;
    if (groupData) {
      try {
        if (groupRole === 'Owner' && this.$store.state.service.user) {
          const myGroupOrder = await groupOrdersHttp.getMyGroupOrder();
          if (myGroupOrder !== null) await groupOrdersHttp.cancelGroupOrder(groupData.id);
        } else if (groupRole === 'Participant' && participantBasketId) {
          await groupOrdersHttp.leaveGroupOrder(groupData.id, participantBasketId);
          this.$router.push('/');
        }

        this.$store.commit('groupOrder/reset');
        this.$store.commit('basket/clear');

        this.$store.commit('modals/close');
        this.$store.commit('modals/close');
      } catch (err) {
        console.error(err);
      }
    }

    this.loader = false;
  }

  private closeModal() {
    this.$store.commit('modals/close');
  }
}
</script>

<style lang="scss">
.confirm-cancel-group-order {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    max-width: 345px;
    width: 345px;
  }

  &__title {
    text-indent: -4px;
    text-align: center;
  }

  &__description {
    @include p2;
    text-align: center;
    margin-top: 15px;
  }

  &__buttons-wrapper {
    margin-top: 20px;
    display: flex;
    gap: 50px;
    justify-content: center;
  }

  &__confirm-button {
    max-width: unset;
    min-height: unset;
    width: unset;
    border-radius: 45px;
    padding: 10px 25px;
    display: flex;

    .logo {
      justify-content: center;
    }

    svg {
      width: 50%;
      height: 50%;
    }
  }

  &__cancel-button {
    width: unset;
    background-color: rgba(34, 17, 34, 0.05);
    min-height: unset;
    border-radius: 45px;
    padding: 10px 25px;
  }
}
</style>
