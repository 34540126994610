import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/check.svg'


const _hoisted_1 = { class: "join-group-order__title" }
const _hoisted_2 = { class: "join-group-order__details-wrapper" }
const _hoisted_3 = { class: "join-group-order__detail" }
const _hoisted_4 = { class: "join-group-order__detail" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "join-group-order__detail"
}
const _hoisted_7 = { class: "join-group-order__detail" }
const _hoisted_8 = {
  key: 0,
  class: "join-group-order__instructions-label"
}
const _hoisted_9 = {
  key: 1,
  class: "join-group-order__instructions",
  id: "instructions"
}
const _hoisted_10 = { class: "join-group-order__button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, {
    class: "join-group-order",
    close: true,
    onClose: _ctx.cancelJoiningGroupOrder,
    doesNotClose: true
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.joinGroupOrder && _ctx.joinGroupOrder(...args)), ["prevent"])),
        class: "search_form"
      }, [
        _createElementVNode("h2", _hoisted_1, "Join " + _toDisplayString(_ctx.groupOwnerWithApostrophe) + " group order", 1),
        _cache[7] || (_cache[7] = _createElementVNode("p", { class: "join-group-order__description" }, "Add your favourite dishes to the basket then confirm the order.", -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_IconServices, { icon: "locationLight" }),
            _createElementVNode("p", null, [
              _createTextVNode(_toDisplayString(_ctx.collectDeliverText) + " ", 1),
              _createElementVNode("strong", null, _toDisplayString(`${_ctx.data.groupOrder.ownerAddress.formattedAddress.split(',')[0]}, ${_ctx.data.groupOrder.ownerAddress.postcode}`), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_IconServices, { icon: "thickClock" }),
            _createElementVNode("p", null, [
              _createTextVNode(_toDisplayString(_ctx.collectDeliverTimeText) + " ", 1),
              (_ctx.deliverTime !== 'ASAP')
                ? (_openBlock(), _createElementBlock("br", _hoisted_5))
                : _createCommentVNode("", true),
              _createElementVNode("strong", null, _toDisplayString(_ctx.deliverTime), 1)
            ])
          ]),
          (_ctx.data.groupOrder.deadline)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _cache[3] || (_cache[3] = _createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                }, null, -1)),
                _createElementVNode("p", null, [
                  _cache[2] || (_cache[2] = _createTextVNode(" Confirm your items by ")),
                  _createElementVNode("strong", null, _toDisplayString(_ctx.deadline), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_IconServices, { icon: "star" }),
            _createElementVNode("p", null, [
              _cache[4] || (_cache[4] = _createTextVNode(" The restaurant is ")),
              _createElementVNode("strong", null, _toDisplayString(_ctx.data.groupOrder.restaurantName), 1)
            ])
          ])
        ]),
        (_ctx.data.groupOrder.instructions)
          ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(`Instructions from ${_ctx.data.groupOrder.ownerName}`), 1))
          : _createCommentVNode("", true),
        (_ctx.data.groupOrder.instructions)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.data.groupOrder.instructions), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_app_input, {
          class: "join-group-order__name-input",
          title: "Add your name",
          value: _ctx.participantName,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.participantName) = $event)),
          id: "instructions",
          placeholder: "e.g Thomas",
          required: true
        }, {
          label: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("Whats your name?")
          ])),
          _: 1
        }, 8, ["value"]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_app_button, {
            type: "submit",
            class: "join-group-order__button"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("View menu")
            ])),
            _: 1
          })
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["onClose"]))
}