import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dishes = _resolveComponent("Dishes")!
  const _component_About = _resolveComponent("About")!
  const _component_OpeningHours = _resolveComponent("OpeningHours")!
  const _component_NewBasket = _resolveComponent("NewBasket")!
  const _component_Auth = _resolveComponent("Auth")!
  const _component_CreateAccount = _resolveComponent("CreateAccount")!
  const _component_LoginAccount = _resolveComponent("LoginAccount")!
  const _component_VerifyPhone = _resolveComponent("VerifyPhone")!
  const _component_ForgotPassword = _resolveComponent("ForgotPassword")!
  const _component_DeleteCard = _resolveComponent("DeleteCard")!
  const _component_Basket = _resolveComponent("Basket")!
  const _component_CreateGroupOrder = _resolveComponent("CreateGroupOrder")!
  const _component_ShareGroupOrderLink = _resolveComponent("ShareGroupOrderLink")!
  const _component_ActiveGroupOrderWarning = _resolveComponent("ActiveGroupOrderWarning")!
  const _component_CancelGroupOrder = _resolveComponent("CancelGroupOrder")!
  const _component_JoinGroupOrder = _resolveComponent("JoinGroupOrder")!
  const _component_GroupOrderNotAvailable = _resolveComponent("GroupOrderNotAvailable")!
  const _component_Thanks = _resolveComponent("Thanks")!
  const _component_Error = _resolveComponent("Error")!
  const _component_BasketUpdate = _resolveComponent("BasketUpdate")!
  const _component_OrderSettings = _resolveComponent("OrderSettings")!
  const _component_OrderSettingsDeadline = _resolveComponent("OrderSettingsDeadline")!
  const _component_CheckYourMail = _resolveComponent("CheckYourMail")!
  const _component_TooFar = _resolveComponent("TooFar")!
  const _component_InvalidAddress = _resolveComponent("InvalidAddress")!
  const _component_MissingAreaGtm = _resolveComponent("MissingAreaGtm")!
  const _component_FeeDetails = _resolveComponent("FeeDetails")!
  const _component_CustomTip = _resolveComponent("CustomTip")!
  const _component_DeleteAccount = _resolveComponent("DeleteAccount")!
  const _component_TrackedOrderDetails = _resolveComponent("TrackedOrderDetails")!
  const _component_AmendOrderInit = _resolveComponent("AmendOrderInit")!
  const _component_AmendOrderTooLate = _resolveComponent("AmendOrderTooLate")!
  const _component_GroupOrderWhatNext = _resolveComponent("GroupOrderWhatNext")!
  const _component_ActiveAmendableOrderWarning = _resolveComponent("ActiveAmendableOrderWarning")!
  const _component_BusinessUserBenefits = _resolveComponent("BusinessUserBenefits")!
  const _component_SharePrefilledBasketLink = _resolveComponent("SharePrefilledBasketLink")!
  const _component_SetFilters = _resolveComponent("SetFilters")!
  const _component_GtmNewEvent = _resolveComponent("GtmNewEvent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.dishes)
      ? (_openBlock(), _createBlock(_component_Dishes, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.about)
      ? (_openBlock(), _createBlock(_component_About, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.openingHours)
      ? (_openBlock(), _createBlock(_component_OpeningHours, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.newBasket)
      ? (_openBlock(), _createBlock(_component_NewBasket, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.auth)
      ? (_openBlock(), _createBlock(_component_Auth, { key: 4 }))
      : _createCommentVNode("", true),
    (_ctx.createAccount)
      ? (_openBlock(), _createBlock(_component_CreateAccount, { key: 5 }))
      : _createCommentVNode("", true),
    (_ctx.login)
      ? (_openBlock(), _createBlock(_component_LoginAccount, { key: 6 }))
      : _createCommentVNode("", true),
    (_ctx.verifyPhone)
      ? (_openBlock(), _createBlock(_component_VerifyPhone, { key: 7 }))
      : _createCommentVNode("", true),
    (_ctx.forgotPassword)
      ? (_openBlock(), _createBlock(_component_ForgotPassword, { key: 8 }))
      : _createCommentVNode("", true),
    (_ctx.deleteCard)
      ? (_openBlock(), _createBlock(_component_DeleteCard, { key: 9 }))
      : _createCommentVNode("", true),
    (_ctx.basket)
      ? (_openBlock(), _createBlock(_component_Basket, { key: 10 }))
      : _createCommentVNode("", true),
    (_ctx.createGroupOrder)
      ? (_openBlock(), _createBlock(_component_CreateGroupOrder, { key: 11 }))
      : _createCommentVNode("", true),
    (_ctx.shareGroupOrderLink)
      ? (_openBlock(), _createBlock(_component_ShareGroupOrderLink, { key: 12 }))
      : _createCommentVNode("", true),
    (_ctx.activeOrderWarning)
      ? (_openBlock(), _createBlock(_component_ActiveGroupOrderWarning, { key: 13 }))
      : _createCommentVNode("", true),
    (_ctx.cancelGroupOrder)
      ? (_openBlock(), _createBlock(_component_CancelGroupOrder, { key: 14 }))
      : _createCommentVNode("", true),
    (_ctx.joinGroupOrder)
      ? (_openBlock(), _createBlock(_component_JoinGroupOrder, { key: 15 }))
      : _createCommentVNode("", true),
    (_ctx.groupOrderNotAvailable)
      ? (_openBlock(), _createBlock(_component_GroupOrderNotAvailable, { key: 16 }))
      : _createCommentVNode("", true),
    (_ctx.thanks)
      ? (_openBlock(), _createBlock(_component_Thanks, { key: 17 }))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createBlock(_component_Error, { key: 18 }))
      : _createCommentVNode("", true),
    (_ctx.basketUpdate)
      ? (_openBlock(), _createBlock(_component_BasketUpdate, { key: 19 }))
      : _createCommentVNode("", true),
    (_ctx.orderSettings)
      ? (_openBlock(), _createBlock(_component_OrderSettings, { key: 20 }))
      : _createCommentVNode("", true),
    (_ctx.orderSettingsDeadline)
      ? (_openBlock(), _createBlock(_component_OrderSettingsDeadline, { key: 21 }))
      : _createCommentVNode("", true),
    (_ctx.checkYourMail)
      ? (_openBlock(), _createBlock(_component_CheckYourMail, { key: 22 }))
      : _createCommentVNode("", true),
    (_ctx.tooFar)
      ? (_openBlock(), _createBlock(_component_TooFar, { key: 23 }))
      : _createCommentVNode("", true),
    (_ctx.invalidAddress)
      ? (_openBlock(), _createBlock(_component_InvalidAddress, { key: 24 }))
      : _createCommentVNode("", true),
    (_ctx.missingAreaGtm)
      ? (_openBlock(), _createBlock(_component_MissingAreaGtm, { key: 25 }))
      : _createCommentVNode("", true),
    (_ctx.feeDetails)
      ? (_openBlock(), _createBlock(_component_FeeDetails, { key: 26 }))
      : _createCommentVNode("", true),
    (_ctx.customTip)
      ? (_openBlock(), _createBlock(_component_CustomTip, { key: 27 }))
      : _createCommentVNode("", true),
    (_ctx.deleteAccount)
      ? (_openBlock(), _createBlock(_component_DeleteAccount, { key: 28 }))
      : _createCommentVNode("", true),
    (_ctx.trackedOrderDetails)
      ? (_openBlock(), _createBlock(_component_TrackedOrderDetails, { key: 29 }))
      : _createCommentVNode("", true),
    (_ctx.amendOrderInit)
      ? (_openBlock(), _createBlock(_component_AmendOrderInit, { key: 30 }))
      : _createCommentVNode("", true),
    (_ctx.amendOrderTooLate)
      ? (_openBlock(), _createBlock(_component_AmendOrderTooLate, { key: 31 }))
      : _createCommentVNode("", true),
    (_ctx.groupOrderWhatNext)
      ? (_openBlock(), _createBlock(_component_GroupOrderWhatNext, { key: 32 }))
      : _createCommentVNode("", true),
    (_ctx.activeAmendableOrderWarning)
      ? (_openBlock(), _createBlock(_component_ActiveAmendableOrderWarning, { key: 33 }))
      : _createCommentVNode("", true),
    (_ctx.buBenefits)
      ? (_openBlock(), _createBlock(_component_BusinessUserBenefits, { key: 34 }))
      : _createCommentVNode("", true),
    (_ctx.sharePrefilledBasketLink)
      ? (_openBlock(), _createBlock(_component_SharePrefilledBasketLink, { key: 35 }))
      : _createCommentVNode("", true),
    (_ctx.setFilters)
      ? (_openBlock(), _createBlock(_component_SetFilters, { key: 36 }))
      : _createCommentVNode("", true),
    (_ctx.gtmNewEvent)
      ? (_openBlock(), _createBlock(_component_GtmNewEvent, { key: 37 }))
      : _createCommentVNode("", true)
  ], 64))
}