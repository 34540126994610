import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "no-restaurants-gtm" }, _ctx.options), {
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("h1", null, [
        _createTextVNode("Sign up to unlock"),
        _createElementVNode("br"),
        _createTextVNode("deliveries to your office!")
      ], -1)),
      _cache[2] || (_cache[2] = _createElementVNode("p", null, [
        _createTextVNode("Please fill out your details and in no"),
        _createElementVNode("br"),
        _createTextVNode("time, you'll be ordering for the team.")
      ], -1)),
      _createVNode(_component_app_button, {
        square: "",
        onClick: _withModifiers(_ctx.goToCustomMarketplace, ["prevent"])
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Get started here")
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 16))
}