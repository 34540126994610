<template>
  <!-- eslint-disable -->
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_197)">
      <path
        d="M9.00001 15.5834C12.912 15.5834 16.0833 12.4121 16.0833 8.50008C16.0833 4.58806 12.912 1.41675 9.00001 1.41675C5.08799 1.41675 1.91667 4.58806 1.91667 8.50008C1.91667 12.4121 5.08799 15.5834 9.00001 15.5834Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.8333 8.50008L9.00001 5.66675L6.16667 8.50008"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M9 11.3334V5.66675" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1_197">
        <rect width="17" height="17" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
  <!-- eslint-enable -->
</template>
