<template>
  <app-modal class="too-far-modal" v-bind="options">
    <h2 class="too-far-modal-title">Too far to deliver</h2>
    <p class="too-far-modal-description">
      Unfortunately you're outside of our delivery radius for {{ data.restaurantName }}
    </p>
    <app-button @click.prevent="searchNearby">Search nearby restaurants</app-button>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

@Options({
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class AppModalTooFar extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: false,
    doesNotClose: false,
  };

  private searchNearby() {
    this.$router.push(`/restaurants/${this.$store.state.modals.data.cityName}`);
    this.$store.commit('modals/close');
    this.$store.commit('modals/data', null);
  }
}
</script>

<style lang="scss">
.too-far-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &-title {
    margin-bottom: 10px;
  }

  &-description {
    margin: 15px 0;
  }

  .controller-button {
    min-height: 50px;
  }
}
</style>
