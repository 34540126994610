import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "restaurant-item-info" }
const _hoisted_2 = { class: "restaurant-item-title" }
const _hoisted_3 = {
  key: 1,
  class: "restaurant-item-descriptor"
}
const _hoisted_4 = {
  key: 2,
  class: "restaurant-item-price catererPrice"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "no-underline"
}
const _hoisted_7 = {
  key: 2,
  class: "no-underline"
}
const _hoisted_8 = {
  key: 3,
  class: "restaurant-item-price"
}
const _hoisted_9 = {
  key: 0,
  class: "discounted"
}
const _hoisted_10 = {
  key: 0,
  class: "restaurant-item-photo-wrapper"
}
const _hoisted_11 = { class: "restaurant-item-photo" }
const _hoisted_12 = {
  key: 0,
  class: "restaurant-item-count"
}
const _hoisted_13 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["restaurant-item-wrapper custom-cursor-hover", { sold: _ctx.dish.sold | _ctx.disabled }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.dish.name), 1),
      (_ctx.dish.minHoursInAdvance && !_ctx.isDeliveryLateEnough)
        ? (_openBlock(), _createElementBlock("small", {
            key: 0,
            class: _normalizeClass(["restaurant-item-in-advance-time", { catererTime: _ctx.isCaterer }])
          }, _toDisplayString(_ctx.dish.minHoursInAdvance) + " extra " + _toDisplayString(_ctx.dish.minHoursInAdvance === 1 ? 'hour' : 'hours ') + " required to order ", 3))
        : _createCommentVNode("", true),
      (_ctx.dish.description)
        ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.dish.description), 1))
        : _createCommentVNode("", true),
      (_ctx.isCaterer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _cache[0] || (_cache[0] = _createElementVNode("p", null, "Add for ", -1)),
            (_ctx.dish.discountedPrice)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, "£" + _toDisplayString(_ctx.dish.discountedPrice.toFixed(2)), 1))
              : _createCommentVNode("", true),
            (_ctx.dish.discountedPrice)
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, " ("))
              : _createCommentVNode("", true),
            _createElementVNode("p", {
              class: _normalizeClass({ 'td-lt': _ctx.dish.discountedPrice })
            }, "£" + _toDisplayString(_ctx.dish.price.toFixed(2)), 3),
            (_ctx.dish.discountedPrice)
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, ")"))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("p", {
              class: _normalizeClass({ 'td-lt': _ctx.dish.discountedPrice })
            }, "£" + _toDisplayString(_ctx.dish.price.toFixed(2)), 3),
            (_ctx.dish.discountedPrice)
              ? (_openBlock(), _createElementBlock("p", _hoisted_9, "£" + _toDisplayString(_ctx.dish.discountedPrice.toFixed(2)), 1))
              : _createCommentVNode("", true)
          ]))
    ]),
    (!_ctx.isCaterer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            (_ctx.count)
              ? (_openBlock(), _createElementBlock("p", _hoisted_12, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.count), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.dish.photo && _ctx.dish.photo !== 'https://storage.googleapis.com/foodstufff/default/stub.svg')
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: _ctx.dish.photo,
                  alt: ""
                }, null, 8, _hoisted_13))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}