import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!

  return (_ctx.show && _ctx.$route.name !== 'orders-tracker')
    ? (_openBlock(), _createBlock(_component_app_button, {
        key: 0,
        class: "order-tracker",
        onClick: _withModifiers(_ctx.goTo, ["prevent"]),
        white: ""
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("p", null, "Track order", -1)
        ])),
        _: 1
      }, 8, ["onClick"]))
    : _createCommentVNode("", true)
}