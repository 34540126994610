<template>
  <div class="everybody_wins_section">
    <h1 class="section_title">Everybody wins</h1>
    <h2 class="sub_title">We believe if everyone feels a positive impact, its a win-win</h2>
    <div class="everybody_wins_steps">
      <div class="everybody_wins_step">
        <img src="../../assets/landing/everybody_wins_clock.png" alt="" />
        <b>Win your time back</b>
        <p>We take everything off your plate - less stress and more smiles</p>
      </div>
      <div class="everybody_wins_step">
        <img src="../../assets/landing/everybody_wins_hero.png" alt="" />
        <b>Heroic food that wins the room</b>
        <p>Food that gets you office-hero status every time</p>
      </div>
      <div class="everybody_wins_step">
        <img src="../../assets/landing/everybody_wins_smile.png" alt="" />
        <b>Your local favourites win</b>
        <p>Every order makes a proper impact</p>
      </div>
    </div>
    <img class="everybody_wins_city" src="../../assets/foodstuff-city.png" alt="" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class EverybodyWins extends Vue {}
</script>

<style lang="scss" scoped>
.section_title {
  color: $coal100;
  font-family: Recoleta, sans-serif;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 10px;

  @include for-smartphone {
    font-size: 38px;
    line-height: 40px;
  }
}

.sub_title {
  font-family:
    Sofia Pro,
    sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 300;
  text-align: center;
  color: black;
  padding: 0px 30px;
}

.everybody_wins_section {
  padding-top: 50px;
  padding-bottom: 400px;
  display: flex;
  flex-direction: column;
  background-color: $ham;
  position: relative;

  @include for-smartphone {
    padding-bottom: 300px;
  }

  h1,
  h2 {
    padding-left: 100px;
    padding-right: 100px;
    z-index: 1;

    @include for-smartphone {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .everybody_wins_steps {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
    padding-left: 100px;
    padding-right: 100px;
    z-index: 1;

    @include for-smartphone {
      flex-direction: column;
      gap: 50px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .everybody_wins_step {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 300px;

    @include for-smartphone {
      width: 100%;
    }

    b {
      font-family:
        Sofia Pro,
        sans-serif;
      font-size: 19px;
      line-height: 25px;
      color: black;
      text-align: center;
      margin-top: 10px;
    }

    p {
      font-family:
        Sofia Pro,
        sans-serif;
      font-size: 19px;
      line-height: 25px;
      font-weight: 300;
      color: black;
      text-align: center;
    }

    img {
      height: 110px;
      object-fit: contain;
    }
  }

  .everybody_wins_city {
    position: absolute;
    bottom: 0;
    height: 400px;
    width: 100vw;
    object-position: bottom center;
  }
}
</style>
