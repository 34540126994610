<template>
  <div class="controller-option" :class="[{ fullWidth }]">
    <label
      class="custom-cursor-hover"
      :class="[
        {
          disabled,
          active,
          reversed,
          small,
          error: $slots.error,
        },
        type,
      ]"
    >
      <input :disabled="disabled" :type="getType()" :name="name" :id="id" />

      <div class="option" />

      <slot />
    </label>

    <small v-if="$slots.error">
      <slot name="error" />
    </small>
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  type = prop<string>({ required: true });
}

@Options({
  props: {
    id: String,
    name: String,
    type: String,
    error: Boolean,
    small: Boolean,
    active: Boolean,
    disabled: Boolean,
    reversed: Boolean,
    fullWidth: Boolean,
  },
})
export default class AppControllerOption extends Vue.with(Props) {
  private getType() {
    if (!this.type || this.type === 'toggle') return 'checkbox';
    return this.type;
  }
}
</script>

<style lang="scss" scoped>
.controller-option {
  display: inline-block;

  &.fullWidth {
    width: 100%;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 26px;

    .option {
      position: relative;
      $size-checkbox: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $coal40;
      border-radius: 7px;
      overflow: hidden;
      width: $size-checkbox;
      min-width: $size-checkbox;
      height: $size-checkbox;
      min-height: $size-checkbox;
      margin-right: 10px;
      background-color: white;

      &::before {
        transition: $trs;
        $size-before: 15px;
        content: '';
        display: block;
        border-radius: 4px;
        width: $size-before;
        min-width: $size-before;
        height: $size-before;
        min-height: $size-before;
      }
    }

    &.small {
      .option {
        $size-checkbox: 15px;
        width: $size-checkbox;
        min-width: $size-checkbox;
        height: $size-checkbox;
        min-height: $size-checkbox;
        border-radius: 4px;
        &::before {
          $size-before: 8px;
          border-radius: 2px;
          width: $size-before;
          min-width: $size-before;
          height: $size-before;
          min-height: $size-before;
        }
      }
    }

    &.radio {
      .option {
        &,
        &::before {
          border-radius: 100%;
        }
      }
    }

    &.toggle {
      flex-direction: row-reverse;
      .option {
        margin-right: 0;
        margin-left: 10px;
        min-width: 51px;
        min-height: 30px;
        border-radius: 60px;
        border: none;
        background-color: $coal40;
        &::before {
          position: absolute;
          left: 2px;
          top: 2px;
          min-width: 26px;
          min-height: 26px;
          background-color: $vanilla;
          border-radius: 100%;
        }
      }
      &.active {
        .option {
          background-color: $ham;
          &::before {
            left: 22px;
          }
        }
      }

      &.active {
        .option::before {
          background-color: $vanilla;
        }
      }
    }

    &.active {
      .option::before {
        background-color: $ham;
      }
    }

    &.disabled {
      .option {
        border-color: rgba($color: $coal100, $alpha: 0.1);
        &::before {
          content: none;
        }
      }
      &.active {
        .option {
          border: 1px solid $coal40;
          &::before {
            content: '';
            background-color: $ham;
          }
        }
      }
    }

    &.reversed {
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    &.error {
      .option {
        border-color: $roastHam;
        &::before {
          background-color: $error;
        }
      }
    }

    input {
      display: none;
      visibility: hidden;
    }
  }

  small {
    display: inline-block;
    margin-top: 5px;
    color: $error;
  }
}
</style>
