<template>
  <div class="main_steps">
    <Transition name="fade">
      <div v-if="areHeaderAndFooterLoaded" class="main_steps_header">
        <Logo />
        <app-button square white slim class="chat_with_us" @click.prevent="chatWithUs">
          <img src="../../assets/gtm/james.png" alt="" />
          <p>Chat with us</p>
        </app-button>
        <app-button square white slim class="exit" @click.prevent="exit">
          <p>Exit</p>
        </app-button>
      </div>
    </Transition>

    <Transition name="fade">
      <div v-if="isBodyLoaded" class="main_steps_body">
        <div v-if="step === 1" class="step_one">
          <p class="step_number">Step 1</p>
          <h1 class="step_title">Company details</h1>
          <p class="step_hint">We'll use this information to contact you about your marketplace</p>
          <div>
            <p class="input_title">Company email address?</p>
            <div class="input_field">
              <app-input
                id="emailAddress"
                placeholder="e.g. info@acme.com"
                v-model:value="emailAddress"
                :showError="show"
                addErrorPlaceholderSpacing
              >
                <template v-slot:error v-if="yupErrMsg('emailAddress')">
                  {{ yupErrMsg('emailAddress') }}
                </template>
              </app-input>
              <img v-if="emailAddress" src="../../assets/check.svg" alt="" />
            </div>
          </div>
          <div>
            <p class="input_title">Phone number?</p>
            <div class="input_field">
              <app-input
                id="phoneNumber"
                v-model:value="phoneNumber"
                :showError="show"
                addErrorPlaceholderSpacing
                isPhoneNumber
              >
                <template v-slot:error v-if="yupErrMsg('phoneNumber')">
                  {{ yupErrMsg('phoneNumber') }}
                </template>
              </app-input>
              <img v-if="phoneNumber" src="../../assets/check.svg" alt="" />
            </div>
          </div>
        </div>
        <div v-if="step === 2" class="step_two">
          <p class="step_number">Step 2</p>
          <h1 class="step_title">Supplier information</h1>
          <p class="step_hint">This gives us an idea of your current supplier numbers and gap to fill</p>
          <div>
            <p class="input_title">Name the suppliers you already order from</p>
            <div class="input_field">
              <app-input
                id="foodSuppliers"
                placeholder="Separate suppliers with a comma"
                v-model:value="foodSuppliers"
                :showError="show"
                addErrorPlaceholderSpacing
              >
              </app-input>
              <img v-if="numberOfChosenSuppliers" src="../../assets/check.svg" alt="" />
            </div>
          </div>
          <div>
            <p class="input_title">Happy for us to add them to your custom marketplace?</p>
            <div class="happy_for_us_row">
              <p
                class="custom-cursor-hover"
                :class="{ active: happyToContactSupplier }"
                @click="() => (happyToContactSupplier = true)"
              >
                Yes
              </p>
              <p
                class="custom-cursor-hover"
                :class="{ active: !happyToContactSupplier }"
                @click="() => (happyToContactSupplier = false)"
              >
                No
              </p>
            </div>
          </div>
          <div>
            <p class="input_title">What are the gaps in your supplier list?</p>
            <div class="supplier_gaps_row">
              <div
                class="supplier_gaps_button custom-cursor-hover"
                :class="{ active: isCuisinesDropdownShown }"
                @click.prevent="() => (isCuisinesDropdownShown = !isCuisinesDropdownShown)"
              >
                <img src="../../assets/gtm/award.png" alt="" />
                <p>Cuisines ({{ numberOfChosenCuisines }})</p>
                <img src="../../assets/simple_arrow_bottom.svg" alt="" />
              </div>
              <div class="dropdown_list_cuisines" v-if="isCuisinesDropdownShown">
                <div class="dropdown_list_body">
                  <div class="dropdown_list_item" v-for="(value, key) in supplierCuisines" :key="key">
                    <p>{{ key }}</p>
                    <app-option
                      type="checkbox"
                      :active="supplierCuisines[key]"
                      @click.prevent="() => (supplierCuisines[key] = !supplierCuisines[key])"
                    />
                  </div>
                </div>
              </div>
              <div
                class="supplier_gaps_button custom-cursor-hover"
                :class="{ active: isMomentsDropdownShown }"
                @click.prevent="() => (isMomentsDropdownShown = !isMomentsDropdownShown)"
              >
                <img src="../../assets/gtm/activity.png" alt="" />
                <p>Moments ({{ numberOfChosenMoments }})</p>
                <img src="../../assets/simple_arrow_bottom.svg" alt="" />
              </div>
              <div class="dropdown_list_moments" v-if="isMomentsDropdownShown">
                <div class="dropdown_list_body">
                  <div class="dropdown_list_item" v-for="(value, key) in supplierMoments" :key="key">
                    <p>{{ key }}</p>
                    <app-option
                      type="checkbox"
                      :active="supplierMoments[key]"
                      @click.prevent="() => (supplierMoments[key] = !supplierMoments[key])"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <!-- Placeholder for body -->
    <div v-if="!isBodyLoaded" class="main_steps_body" />

    <Transition name="fade">
      <div v-if="areHeaderAndFooterLoaded" class="main_steps_footer">
        <div class="progress_bar">
          <div><div :style="`width: ${progressBarWidth}%`" /></div>
          <div />
          <div />
          <div />
        </div>
        <div class="buttons">
          <app-button v-if="step === 2" class="back_button" square slim @click="goToStep1">Previous step</app-button>
          <app-button
            class="next_button"
            square
            slim
            @click="goToNextStep"
            :loader="isSendingRequest"
            :disabled="isNextStepDisabled || isSendingRequest"
          >
            Next step
          </app-button>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { watch } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import * as yup from 'yup';

import Logo from '@/shared/icons/Logo.vue';

import http from '@/utils/http';
import methods from '@/utils/methods';

@Options({
  components: {
    Logo,
  },
  props: {
    // Core methods: START
    exit: Function,
    chatWithUs: Function,
    // Core methods: STOP

    // Loader states: START
    areHeaderAndFooterLoaded: Boolean,
    isBodyLoaded: Boolean,
    // Loader states: STOP
  },
  computed: {
    ...mapState('yup', ['show']),
    ...mapState('gtm', ['step']),
  },
})
export default class GtmStep1To2 extends Vue {
  // STATES: START
  private progressBarWidth = 0;
  private isSendingRequest = false;
  private isCuisinesDropdownShown = false;
  private isMomentsDropdownShown = false;

  private emailAddress = '';
  private phoneNumber = '';
  private foodSuppliers = '';
  private happyToContactSupplier = true;

  private supplierCuisines = {
    Indian: false,
    British: false,
    'Street food': false,
    Pizza: false,
    Salads: false,
    Asian: false,
    Sushi: false,
    Seafood: false,
    Sandwiches: false,
    Wraps: false,
    Bakery: false,
    Jamaican: false,
    Breakfast: false,
    Gelato: false,
    Mexican: false,
  };

  private supplierMoments = {
    'Office breakfasts': false,
    'Team lunches': false,
    'Street food': false,
    'Bespoke events': false,
    'Company get togethers': false,
    'Internal or client meetings': false,
  };

  private schemaStep1 = yup.object({
    emailAddress: yup.string().required('No email provided.').email('Provided email is not valid.'),
    phoneNumber: yup
      .string()
      .required()
      .length(13, 'Phone number must have exactly 13 characters.')
      .matches(/^\+44/, 'Phone number must start with +44.')
      .matches(/^[+]*[0-9]*$/, 'Phone can only contain numbers.'),
  });
  // STATES: STOP

  // GETTERS (DISPLAYING INFO): START
  get isNextStepDisabled() {
    if (this.$store.state.gtm.step === 1) return !this.emailAddress || !this.phoneNumber;
    return false;
  }

  get numberOfChosenSuppliers() {
    return this.foodSuppliers
      .split(',')
      .map((e) => e?.trim())
      .filter((e) => e).length;
  }

  get numberOfChosenCuisines() {
    return Object.values(this.supplierCuisines).filter((e) => e).length;
  }

  get numberOfChosenMoments() {
    return Object.values(this.supplierMoments).filter((e) => e).length;
  }

  private yupErrMsg(path: string) {
    return methods.yupErrMsg(path);
  }
  // GETTERS (DISPLAYING INFO): STOP

  // NAVIGATION: START
  private async goToNextStep() {
    const { step } = this.$store.state.gtm;

    // Move to 'Step 2: Supplier information':
    if (step === 1) {
      // Frontend validation:
      this.$store.commit('yup/clear');
      try {
        await this.schemaStep1.validate(
          {
            emailAddress: this.emailAddress,
            phoneNumber: `+44${this.phoneNumber}`.replaceAll(' ', ''),
          },
          { abortEarly: false },
        );
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          methods.yupValidCatch(err);
          return;
        }
      }

      this.$store.commit('gtm/setStep', 2);
      this.$store.commit('gtm/setEmail', this.emailAddress);
      this.$store.commit('gtm/setPhoneNumber', `+44${this.phoneNumber}`.replaceAll(' ', ''));
      this.updateProgressBar(2);
    }

    // Move to 'Let's book in...' view:
    else if (step === 2) {
      const selectedSuppiers = this.foodSuppliers
        .split(',')
        .map((e) => e?.trim())
        .filter((e) => e);
      const selectedSupplierCuisines = Object.keys(this.supplierCuisines).filter((key) => this.supplierCuisines[key]);
      const selectedSupplierMoments = Object.keys(this.supplierMoments).filter((key) => this.supplierMoments[key]);
      this.$store.commit('gtm/setFoodSuppliers', selectedSuppiers);
      this.$store.commit('gtm/setHappyToContactSupplier', this.happyToContactSupplier);
      this.$store.commit('gtm/setSupplierCuisines', selectedSupplierCuisines);
      this.$store.commit('gtm/setSupplierMoments', selectedSupplierMoments);
      this.sendCompanyDetails();
    }
  }

  private async goToStep1() {
    this.$store.commit('gtm/setStep', 1);
    this.updateProgressBar(1);
  }

  private updateProgressBar(step: number) {
    let providedInfo = 0;

    switch (step) {
      case 1:
        if (this.emailAddress) providedInfo += 1;
        if (this.phoneNumber) providedInfo += 1;
        this.progressBarWidth = 20 * providedInfo;
        break;
      case 2:
        if (this.foodSuppliers.split(',').some((e) => e?.trim())) providedInfo += 1;
        if (Object.values(this.supplierCuisines).some((e) => e)) providedInfo += 1;
        if (Object.values(this.supplierMoments).some((e) => e)) providedInfo += 1;
        this.progressBarWidth = 40 + 20 * providedInfo;
        break;
      default:
        this.progressBarWidth = 100;
        break;
    }
  }
  // NAVIGATION: STOP

  private async sendCompanyDetails() {
    try {
      this.isSendingRequest = true;

      const { gtm } = this.$store.state;
      const supplierCuisines = gtm.supplierCuisines.join(',') || null;
      const supplierMoments = gtm.supplierMoments.join(',') || null;
      const foodSuppliers = gtm.foodSuppliers.join(',') || null;

      await http.post('/booked-slots/user', {
        email: gtm.email,
        phoneNumber: gtm.phoneNumber,
        happyToContactSupplier: gtm.happyToContactSupplier,
        foodSuppliers,
        supplierCuisines,
        supplierMoments,
      });

      this.progressBarWidth = 100;
      this.$store.commit('gtm/setStep', 2.5);
    } catch (err) {
      console.log(err);
    } finally {
      this.isSendingRequest = false;
    }
  }

  created() {
    // Load state from previous session:
    const { gtm } = this.$store.state;
    if (gtm.email) this.emailAddress = gtm.email;
    if (gtm.phoneNumber) this.phoneNumber = gtm.phoneNumber.replaceAll('+44', '');
    this.updateProgressBar(gtm.step);

    watch(
      () => [
        this.emailAddress,
        this.phoneNumber,
        this.foodSuppliers,
        Object.values(this.supplierCuisines),
        Object.values(this.supplierMoments),
      ],
      () => this.updateProgressBar(this.$store.state.gtm.step),
    );
  }
}
</script>

<style lang="scss" scoped>
.main_steps {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100vh;
  width: 100vw;

  .main_steps_header {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 10px 20px;

    .logo {
      margin-right: auto;

      @include for-desktop {
        transform: scale(1.4);
      }
    }

    button {
      border: 1px solid rgba(0, 0, 0, 0.2);
      height: 40px;
      padding: 0;

      img {
        height: 30px;
        margin-right: 10px;
      }

      p {
        font-size: 17px;
        height: 17px;
      }

      @include for-smartphone {
        height: 35px;
        min-height: unset;

        img {
          height: 25px;
        }

        p {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }

    .chat_with_us {
      width: 165px;
      padding-right: 5px;

      @include for-smartphone {
        width: 140px;
      }
    }

    .exit {
      width: 70px;

      @include for-smartphone {
        width: 60px;
      }
    }
  }

  .main_steps_body {
    flex: 1;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
  }

  .main_steps_footer {
    display: flex;
    flex-direction: column;
    padding: 22px 20px 10px 20px;

    .progress_bar {
      display: flex;
      flex-direction: row;
      gap: 15px;
      margin-bottom: 15px;

      div {
        height: 8px;
        flex: 2;
        background-color: #ebebeb;

        div {
          background-color: black;
          transition-property: all;
          transition-timing-function: linear;
          transition-duration: 0.25s;
        }
      }

      div:nth-child(1) {
        flex: 1;
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;

      button {
        border: 1px solid rgba(0, 0, 0, 0.2);
        height: 40px;
      }

      .back_button {
        width: 120px;
      }

      .next_button {
        width: 100px;
        margin-left: auto;
      }
    }
  }
}

.step_one,
.step_two {
  display: flex;
  flex-direction: column;
  padding: 20px;

  .step_number {
    @include p2;
    font-size: 20px;
    line-height: 20px;

    @include for-smartphone {
      font-size: 14px;
      line-height: 14px;
    }
  }

  .step_title {
    font-size: 40px;
    line-height: 40px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px;

    @include for-smartphone {
      font-size: 25px;
      line-height: 25px;
      margin-top: 2.5px;
      margin-bottom: 5px;
    }
  }

  .step_hint {
    @include p2;
    opacity: 0.5;
    margin-bottom: 20px;

    @include for-smartphone {
      font-size: 14px;
      line-height: 14px;
    }
  }

  .input_title {
    font-family:
      Sofia Pro,
      sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: $coal80;
    margin-bottom: 6px;
  }

  .input_field {
    display: flex;
    flex-direction: row;

    div:first-of-type {
      width: 315px;
    }

    img {
      width: 24px;
      height: 24px;
      padding: 6px 4px;
      margin-left: 10px;
      margin-top: 10px;
    }
  }
}

.step_one {
  width: 700px;

  @include for-smartphone {
    width: 500px;
    max-width: 100vw;
  }

  // Header + footer = 155px, step's content = about 340px
  @media (min-height: 495px) {
    padding-top: calc(20px + ((100vh - 495px) / 2));
  }
}

.step_two {
  width: 700px;

  @include for-smartphone {
    width: 500px;
    max-width: 100vw;

    .step_hint {
      max-width: 300px;
    }
  }

  // [MOBILE] Header + footer = 155px, step's content = about 600px
  @media (min-height: 755px) {
    padding-top: calc(20px + ((100vh - 755px) / 2));
  }

  // [DESKTOP] Header + footer = 155px, step's content = about 545px
  @media (min-height: 690px) and (min-width: 1025px) {
    padding-top: calc(20px + ((100vh - 690px) / 2));
  }

  .happy_for_us_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
    margin-bottom: 28px;
    width: 315px;
    max-width: calc(100vw - 40px);

    @include for-smartphone {
      margin-top: 10px;
      gap: 15px;
    }

    p {
      @include p1;
      font-weight: bold;
      width: 125px;
      height: 50px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 15px;
      text-align: center;
      line-height: 50px;
      flex: 1;

      @include for-smartphone {
        height: 40px;
        line-height: 40px;
        font-size: 15px;
        border-radius: 10px;
      }

      &:hover {
        background-color: $snow;
      }

      &.active {
        background-color: $ham;
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
  }

  .supplier_gaps_row {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 100%;

    @include for-smartphone {
      flex-direction: column;
      gap: 20px;
    }

    .supplier_gaps_button {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12.5px 15px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      gap: 15px;
      width: 315px;
      max-width: calc(100vw - 40px);

      background-color: white;
      &:hover {
        background-color: $snow;
      }

      img:first-child {
        height: 20px;
      }

      p {
        @include p1;
        height: 18px;
        width: 100%;
      }

      img:last-child {
        height: 10px;
      }

      &.active {
        border: 1px solid black;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        img:last-child {
          transform: scaleY(-1);
        }
      }
    }

    .dropdown_list_cuisines,
    .dropdown_list_moments {
      position: absolute;
      top: 47px;
      width: 315px;
      height: 120px;
      padding: 7.5px 0px;
      border: 1px solid black;
      border-top: 0px solid black;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: white;

      @include for-smartphone {
        width: 100%;
        max-width: 315px;
      }

      .dropdown_list_body {
        overflow-y: scroll;
        height: 100%;
      }

      .dropdown_list_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 5px 3.5px 5px 15px;

        p {
          @include p1;
          height: 18px;
          width: 100%;
        }
      }
    }

    .dropdown_list_moments {
      left: 345px;

      @include for-smartphone {
        left: 0px;
        top: 114px;
      }
    }
  }
}

// Prevent selecting text: START
p,
h1,
h2,
h3 {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
// Prevent selecting text: STOP

// ANIMATIONS: START
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
// ANIMATIONS: STOP
</style>
