<template>
  <app-modal class="error-modal" v-bind="options">
    <h2>Error</h2>

    <p v-if="data" v-html="data" />
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

@Options({
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class AppModalError extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: true,
  };
}
</script>

<style lang="scss">
.error-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-top: 15px;
  }
}
</style>
