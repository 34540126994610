import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/dietaries.png'
import _imports_1 from '../assets/simple_arrow_bottom.svg'
import _imports_2 from '../assets/perfect_for.png'
import _imports_3 from '../assets/budget.png'
import _imports_4 from '../assets/glasses-empty.png'


const _hoisted_1 = { class: "main-list-of-filters" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "other-filters" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 0,
  class: "info-banner"
}
const _hoisted_12 = {
  key: 1,
  id: "restaurants"
}
const _hoisted_13 = {
  key: 0,
  class: "banner"
}
const _hoisted_14 = { class: "restaurant-cards" }
const _hoisted_15 = {
  key: 2,
  class: "no-restaurants"
}
const _hoisted_16 = {
  key: 3,
  class: "loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_UnavailableDeliveryBanner = _resolveComponent("UnavailableDeliveryBanner")!
  const _component_RestaurantCard = _resolveComponent("RestaurantCard")!
  const _component_Logo = _resolveComponent("Logo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cuisines, (cuisine) => {
        return (_openBlock(), _createElementBlock("div", {
          key: cuisine.id,
          class: _normalizeClass(["cuisine custom-cursor-hover", { active: _ctx.selectedCuisineIds.includes(cuisine.id) }]),
          onClick: ($event: any) => (_ctx.selectCuisine(cuisine.id))
        }, [
          _createElementVNode("img", {
            src: cuisine.iconPath,
            alt: ""
          }, null, 8, _hoisted_3),
          _createElementVNode("p", null, _toDisplayString(cuisine.name), 1)
        ], 10, _hoisted_2))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_app_button, {
        class: _normalizeClass(["filter-button", { active: _ctx.selectedDietaryIds.length }]),
        onClick: _ctx.showDietariesDialog
      }, {
        default: _withCtx(() => [
          _cache[0] || (_cache[0] = _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1)),
          (_ctx.selectedDietaryIds.length)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Dietaries [" + _toDisplayString(_ctx.selectedDietaryIds.length) + "]", 1))
            : (_openBlock(), _createElementBlock("p", _hoisted_6, "Dietaries")),
          _cache[1] || (_cache[1] = _createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }, null, -1))
        ]),
        _: 1
      }, 8, ["class", "onClick"]),
      _createVNode(_component_app_button, {
        class: _normalizeClass(["filter-button", { active: _ctx.selectedPerfectForIds.length }]),
        onClick: _ctx.showPerfectForDialog
      }, {
        default: _withCtx(() => [
          _cache[2] || (_cache[2] = _createElementVNode("img", {
            src: _imports_2,
            alt: ""
          }, null, -1)),
          (_ctx.selectedPerfectForIds.length)
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, "Perfect for [" + _toDisplayString(_ctx.selectedPerfectForIds.length) + "]", 1))
            : (_openBlock(), _createElementBlock("p", _hoisted_8, "Perfect for")),
          _cache[3] || (_cache[3] = _createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }, null, -1))
        ]),
        _: 1
      }, 8, ["class", "onClick"]),
      _createVNode(_component_app_button, {
        class: _normalizeClass(["filter-button", { active: _ctx.selectedBudgetIds.length }]),
        onClick: _ctx.showBudgetDialog
      }, {
        default: _withCtx(() => [
          _cache[4] || (_cache[4] = _createElementVNode("img", {
            src: _imports_3,
            alt: ""
          }, null, -1)),
          (_ctx.selectedBudgetIds.length)
            ? (_openBlock(), _createElementBlock("p", _hoisted_9, "Budget [" + _toDisplayString(_ctx.selectedBudgetIds.length) + "]", 1))
            : (_openBlock(), _createElementBlock("p", _hoisted_10, "Budget")),
          _cache[5] || (_cache[5] = _createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }, null, -1))
        ]),
        _: 1
      }, 8, ["class", "onClick"])
    ]),
    _createVNode(_component_UnavailableDeliveryBanner),
    (_ctx.bannerMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("p", null, _toDisplayString(_ctx.bannerMessage), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.restaurants.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedRestaurants, (restaurantGroup, rGIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "restaurants-section",
              key: rGIndex
            }, [
              (rGIndex !== 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.banners[rGIndex - 1]), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_14, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(restaurantGroup, (restaurant, rIndex) => {
                  return (_openBlock(), _createBlock(_component_RestaurantCard, {
                    key: rIndex,
                    restaurant: restaurant,
                    onClick: _withModifiers(($event: any) => (_ctx.goTo(`/menu/${_ctx.selectedCity.city.toLowerCase()}/${restaurant.slug}`)), ["prevent"])
                  }, null, 8, ["restaurant", "onClick"]))
                }), 128))
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.restaurants.length === 0 && !_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, _cache[6] || (_cache[6] = [
          _createElementVNode("img", {
            src: _imports_4,
            alt: "glasses"
          }, null, -1),
          _createElementVNode("p", null, "No restaurants.", -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createVNode(_component_Logo, { class: "logo-loader" })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}