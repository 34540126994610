/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state: StateEmbeddedPage = {
  initialPath: '',
  currentPath: '',
};

const mutations = {
  setInitialPath(state: StateEmbeddedPage, path: string) {
    state.initialPath = path;
  },
  setCurrentPath(state: StateEmbeddedPage, path: string) {
    state.currentPath = path;
  },
};

const actions = {};

const getters = {};

export interface StateEmbeddedPage {
  initialPath: string;
  currentPath: string;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
