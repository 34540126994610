const baseUrl = window.location.origin;
const googleKey = 'AIzaSyCxWKFtc6Kdsx_8WZ7Msn_9Monms0LPNyo';
const firebaseApiKey = 'AIzaSyBWX8w1QxgHlaANip69i8MrRcCv9E_Yozc';
const stripe = {
  dev: 'pk_test_rq4QNJAOK1rRiWpnny8xzQfx00OAYfmtI5',
  prod: 'pk_live_iFMslfvLrQnfthXb074hogM200MOpQ2jES',
};
const sentryDsn = 'https://9c2876efee124a68a7b0f051b5373844@o330611.ingest.us.sentry.io/1852691';

const local: Environment = {
  googleKey,
  stripeKey: stripe.dev,
  stripeGtmUrl: 'https://buy.stripe.com/test_7sI5kRbcl5lQc2A146',
  baseApiUrl: 'http://localhost:3000',
  baseUrl,
  firebaseApiKey,
  usersCollection: 'users-dev',
  sentryDsn: '',
  sentryEnv: 'localhost',
};

const dev: Environment = {
  googleKey,
  stripeKey: stripe.dev,
  stripeGtmUrl: 'https://buy.stripe.com/test_00g6oVdkt4hMfeM000',
  baseApiUrl: 'https://api-dev.wearefoodstuff.co.uk',
  baseUrl,
  firebaseApiKey,
  usersCollection: 'users-dev',
  sentryDsn,
  sentryEnv: 'dev',
};

const stage: Environment = {
  googleKey,
  stripeKey: stripe.dev,
  stripeGtmUrl: 'https://buy.stripe.com/test_cN29B794d3dIfeMdQR',
  baseApiUrl: 'https://api-stage.wearefoodstuff.co.uk',
  baseUrl,
  firebaseApiKey,
  usersCollection: 'users-stage',
  sentryDsn,
  sentryEnv: 'stage',
};

const prod: Environment = {
  googleKey,
  stripeKey: stripe.prod,
  stripeGtmUrl: 'https://buy.stripe.com/7sIeXU9Ybd06dDGbJp',
  baseApiUrl: 'https://api.wearefoodstuff.co.uk',
  baseUrl,
  firebaseApiKey,
  usersCollection: 'users-prod',
  sentryDsn,
  sentryEnv: 'prod',
};

function configEnvironment(): Environment {
  switch (process.env.VUE_APP_ENV) {
    case 'dev':
      return dev;
    case 'stage':
      return stage;
    case 'master':
      return prod;
    default:
      return local;
  }
}

export default configEnvironment();

interface Environment {
  googleKey: string;
  stripeKey: string;
  stripeGtmUrl: string;
  baseApiUrl: string;
  baseUrl: string;
  firebaseApiKey: string;
  usersCollection: string;
  sentryDsn: string;
  sentryEnv: string;
}
