<template>
  <app-modal class="create-account-modal" v-bind="options">
    <!-- FIRST STEP - ENTER PHONE NUMBER -->
    <div class="enter-phone" v-if="step === 1">
      <h2 class="ta-c">Enter phone number</h2>
      <p class="text">Please enter your phone number. We'll then text you a code to verify your number.</p>
      <app-input class="input" v-model:value="phone" :showError="show" id="phone_number">
        <template v-slot:label>Telephone number</template>
        <template v-slot:error v-if="yupErrMsg('phone')">
          {{ yupErrMsg('phone') }}
        </template>
      </app-input>
      <app-button
        class="g-recaptcha"
        data-sitekey="6Lf7Qm4eAAAAAHsyhY_nPcKoSi1dUCQDAhblLx_j"
        data-callback="beginPhoneVerification"
        data-action="submit"
        :loader="loader"
        @click="beginPhoneVerification"
        id="create_account"
      >
        Send verification code
      </app-button>
    </div>

    <!-- SECOND STEP - PHONE NUMBER VERIFICATION -->
    <div v-if="step === 2" class="verification">
      <h2 class="ta-c">Submit verification</h2>
      <p>We've sent a code to {{ phone }}.</p>
      <p>Please enter the code below to verify your account.</p>
      <div class="verification__inputs">
        <app-input
          v-model:value="code[0]"
          :showError="show"
          id="code"
          class="verification__input"
          maxlength="1"
          v-on:input="(e) => focusOnInput(e, 'code-input-2')"
        />
        <app-input
          v-model:value="code[1]"
          :showError="show"
          id="code-input-2"
          class="verification__input"
          maxlength="1"
          v-on:input="(e) => focusOnInput(e, 'code-input-3')"
        />
        <app-input
          v-model:value="code[2]"
          :showError="show"
          id="code-input-3"
          class="verification__input"
          maxlength="1"
          v-on:input="(e) => focusOnInput(e, 'code-input-4')"
        />
        <app-input
          v-model:value="code[3]"
          :showError="show"
          id="code-input-4"
          class="verification__input"
          maxlength="1"
          v-on:input="(e) => focusOnInput(e, 'code-input-5')"
        />
        <app-input
          v-model:value="code[4]"
          :showError="show"
          id="code-input-5"
          class="verification__input"
          maxlength="1"
          v-on:input="(e) => focusOnInput(e, 'code-input-6')"
        />
        <app-input
          v-model:value="code[5]"
          :showError="show"
          id="code-input-6"
          class="verification__input"
          maxlength="1"
        />
      </div>
      <button v-if="!signUpData?.phone && !updatePhone" @click="goBack">Go back</button>
      <app-button @click="checkCodeAndLink" :loader="loader" :disabled="loader">Verify</app-button>
      <div class="error">{{ codeErrorMessage }}</div>
    </div>

    <div class="recaptcha-container" id="recaptcha-container" />
  </app-modal>
</template>

<script lang="ts">
import { FirebaseError } from '@firebase/util';
import {
  ConfirmationResult,
  EmailAuthProvider,
  PhoneAuthCredential,
  PhoneAuthProvider,
  RecaptchaVerifier,
  getAuth,
  linkWithCredential,
  signInWithCredential,
  updatePhoneNumber,
  updateProfile,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import * as yup from 'yup';

import account, { Login, SignUp } from '@/requests/account';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

import methods from '@/utils/methods';

@Options({
  computed: {
    ...mapState('yup', ['show']),
  },
})
export default class VerifyPhone extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: true,
    doesNotClose: false,
  };

  private db = getFirestore();
  private auth = getAuth();
  private phoneAuthProvider = new PhoneAuthProvider(this.auth);

  private phoneVerificationId = '';
  private phoneCredential?: PhoneAuthCredential;

  private signInMigrationData?: Login;
  private signUpData?: SignUp;
  private updatePhone = false;

  private loader = false;
  private phone = '';
  private step = 1;
  private codeErrorMessage = '';
  private confirmationResult?: ConfirmationResult;
  private appVerifier?: RecaptchaVerifier;
  private code = [];

  private phoneSchema = yup
    .string()
    .required()
    .matches(/^[+]*[0-9]*$/, 'Phone can only contain numbers.')
    .matches(/^\+44/, 'Phone number must start with +44');

  private async beginPhoneVerification() {
    this.$store.commit('yup/clear');
    this.codeErrorMessage = '';
    this.loader = true;

    this.phone = this.phone.replaceAll(' ', '');

    try {
      await this.phoneSchema.validate(this.phone, { abortEarly: false });
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        this.$store.commit('yup/errors', { path: 'phone', msg: err.errors[0] });
        this.$store.commit('yup/toggleShowError', true);
      }
      this.loader = false;
      return;
    }

    try {
      this.phoneVerificationId = await this.phoneAuthProvider.verifyPhoneNumber(this.phone, this.appVerifier!);
      this.step = 2;
    } catch (err) {
      if (err instanceof FirebaseError) {
        // FIREBASE ERRORS CUSTOM HANDLING - to refactor
        if (err.code === 'auth/too-many-requests') {
          this.$store.commit('yup/errors', { path: 'phone', msg: 'Too many attempts. Please try again later.' });
        } else if (/Invalid format/i.test(err.message)) {
          this.$store.commit('yup/errors', { path: 'phone', msg: 'Invalid phone format. Try +44 and your number.' });
        } else if (/too_long/i.test(err.message) || /too_long/i.test(err.message)) {
          this.$store.commit('yup/errors', { path: 'phone', msg: 'Phone number is too long.' });
        } else if (/too_short/i.test(err.message) || /too_short/i.test(err.message)) {
          this.$store.commit('yup/errors', { path: 'phone', msg: 'Phone number is too short.' });
        } else if (
          /invalid-phone-number/i.test(err.message) ||
          err.code === 'auth/invalid-phone-number' ||
          err.message === 'INVALID_PHONE_NUMBER'
        ) {
          this.$store.commit('yup/errors', { path: 'phone', msg: 'Invalid phone number.' });
        } else {
          console.error(err);
          this.$store.commit('yup/errors', {
            path: 'phone',
            msg: 'Something went wrong. Please reload the page and try again.',
          });
        }
        this.$store.commit('yup/toggleShowError', true);
      }
    } finally {
      this.loader = false;
    }
  }

  private async checkCodeAndLink() {
    this.loader = true;

    try {
      this.phoneCredential = PhoneAuthProvider.credential(this.phoneVerificationId, this.code.join(''));
    } catch (err) {
      console.error(err);
      if (err instanceof FirebaseError) {
        if (err.code === 'auth/account-exists-with-different-credential') {
          this.codeErrorMessage = 'Phone number already in use.';
        }
        this.codeErrorMessage = 'Something went wrong. Please contact support.';
      }
      this.loader = false;
      return;
    }

    // Update phone number in an existing account (via Account Page):
    if (this.updatePhone) {
      if (!this.auth.currentUser) {
        console.error('User not logged in to firebase.');
        this.codeErrorMessage = 'Something went wrong';
        this.loader = false;
        return;
      }

      try {
        await updatePhoneNumber(this.auth.currentUser, this.phoneCredential);
      } catch (err) {
        console.error(err);
        if (err instanceof FirebaseError) {
          if (err.code === 'auth/invalid-verification-code') {
            this.codeErrorMessage = 'Invalid Code';
          } else {
            this.codeErrorMessage = 'Something went wrong.';
          }
        }
        this.loader = false;
        return;
      }
    }

    // Link email-type account with provided phone number during signing up (via Order With An Expert page):
    else if (this.signUpData) {
      try {
        await signInWithCredential(this.auth, this.phoneCredential);
        await linkWithCredential(
          this.auth.currentUser!,
          EmailAuthProvider.credential(this.signUpData.email, this.signUpData.password),
        );
        await updateProfile(this.auth.currentUser!, { displayName: this.signUpData.firstName });
      } catch (err) {
        if (err instanceof FirebaseError) {
          if (err.code === 'auth/account-exists-with-different-credential') {
            this.codeErrorMessage = 'Phone number is already in use.';
          } else if (err.code === 'auth/invalid-verification-code') {
            this.codeErrorMessage = 'Invalid Code';
          } else {
            this.codeErrorMessage = err.message;
          }
        }
        console.error(err);
        this.loader = false;
        return;
      }
    }

    // Link third party account with provided phone number during signing up:
    else {
      if (!this.auth.currentUser) {
        console.error('User not logged in to firebase.');
        this.codeErrorMessage = 'Something went wrong';
        this.loader = false;
        return;
      }

      try {
        await linkWithCredential(this.auth.currentUser, this.phoneCredential);
      } catch (err) {
        if (err instanceof FirebaseError) {
          if (err.code === 'auth/account-exists-with-different-credential') {
            this.codeErrorMessage = 'Phone number is already in use.';
          } else if (err.code === 'auth/invalid-verification-code') {
            this.codeErrorMessage = 'Invalid Code';
          } else {
            this.codeErrorMessage = err.message;
          }
        }
        console.error(err);
        this.loader = false;
        return;
      }
    }

    let isBusinessUser = false;
    try {
      if (this.signUpData?.companyName) {
        try {
          isBusinessUser = (await account.verifyEmailDomain(this.signUpData.email)).success;
        } catch {}
        await account.saveUserToFirestore(this.signUpData.companyName, isBusinessUser);
      } else {
        await account.saveUserToFirestore();
      }
      // NOTE: Firebase listeners defined in App.vue will automatically update local storage after request to Firestore.
    } catch (error) {
      console.error(error);
      this.codeErrorMessage = 'Something went wrong.';
      this.loader = false;
      return;
    }

    this.loader = false;

    this.$store.commit('modals/close');
    if (isBusinessUser) this.$store.commit('modals/show', 'buBenefits');
  }

  private yupErrMsg(path: string) {
    return methods.yupErrMsg(path);
  }

  private focusOnInput(e: Event & { target: HTMLInputElement }, inputId: string) {
    const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    const input = document.getElementById(inputId);
    const inputValue = parseInt(e.target?.value, 10);

    if (numbers.includes(inputValue) && input) {
      input.focus();
    }
  }

  private goBack() {
    this.step = 1;
    this.code = [];
    this.codeErrorMessage = '';
  }

  async mounted() {
    this.appVerifier = new RecaptchaVerifier(this.auth, 'recaptcha-container', { size: 'invisible' });

    const { data } = this.$store.state.modals;
    this.signInMigrationData = data?.signInMigrationData;
    this.signUpData = data?.signUpData;
    this.updatePhone = data?.updatePhone;
    this.phone = data?.phone;

    if (this.phone) {
      this.step = 2;
      try {
        this.phoneVerificationId = await this.phoneAuthProvider.verifyPhoneNumber(this.phone, this.appVerifier!);
      } catch (err) {
        console.error(err);
      }
    }

    // Pre-fill input field to make it clearer for users:
    if (!this.phone) this.phone = '+44';

    this.$store.commit('modals/data', null);
    this.$store.commit('yup/toggleShowError', false);
    this.$store.commit('yup/clear');
  }
}
</script>

<style scoped lang="scss">
.create-account-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .controller-input-wrapper {
    margin-bottom: 20px;
  }

  .controller-button {
    margin-top: 10px;
  }

  .already-account {
    text-align: center;
    margin: 15px 0;
    @include p2;

    span {
      color: $coal100;
    }

    button {
      text-decoration: underline;
      margin-left: 10px;
    }
  }

  .error {
    color: red;
    font-weight: bold;
  }

  .close-btn {
    font-size: 1.2em;
  }
}

.enter-phone {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  h2 {
    margin-bottom: 21px;
    max-width: 170px;
    text-align: center;
  }

  p {
    margin-bottom: 45px;
  }

  .input {
    margin-bottom: 166px;
  }

  .text {
    max-width: 277px;
    text-align: center;
  }
}

.verification {
  text-align: center;

  h2 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    max-width: 170px;
  }

  p {
    margin-left: auto;
    margin-right: auto;
    max-width: 270px;
  }

  &__inputs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3em;
    margin-bottom: 138px;
  }

  &__input {
    margin: 0 2px;
    width: 50px;
    text-align: center;
  }

  input[type='text'] {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    padding: 0;
  }

  .error {
    margin-top: 1em;
  }
}
</style>
