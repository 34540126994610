<template>
  <div class="custom-select">
    <select :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
      <option v-for="option in options" :key="option">{{ option }}</option>
    </select>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
  props: {
    modelValue: String,
    options: Array,
  },
})
export default class AppControllerSelect extends Vue {}
</script>

<style lang="scss">
.custom-select {
  border: 1px solid $coal20;
  border-radius: 5px;

  select {
    @include p2;
    padding: 0 10px;
    border: none;
    height: 38px;
    border-radius: 10px;
    width: 150px;
  }
}
</style>
