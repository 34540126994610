<template>
  <app-modal class="group-order-not-available" :close="true">
    <h2 class="group-order-not-available__title">You used an invalid link or this group order is not active anymore</h2>
    <div class="group-order-not-available__buttons-wrapper">
      <app-button class="group-order-not-available__confirm-button" @click.prevent="closeModal">Okay</app-button>
    </div>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class ActiveGroupOrderWarning extends Vue {
  private closeModal() {
    this.$store.commit('modals/close');
  }
}
</script>

<style lang="scss">
.group-order-not-available {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    max-width: 345px;
    width: 395px;
  }

  &__title {
    text-indent: -4px;
    text-align: center;
  }

  &__buttons-wrapper {
    margin-top: 30px;
    display: flex;
    gap: 15px;
    justify-content: center;
  }

  &__confirm-button {
    max-width: unset;
    min-height: unset;
    width: unset;
    border-radius: 45px;
    padding: 10px 25px;
    display: flex;
  }
}
</style>
