import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createSlots as _createSlots, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/auth/email.png'
import _imports_1 from '../../assets/auth/google-logo.png'


const _hoisted_1 = {
  key: 0,
  class: "auth-wrapper"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "auth-buttons" }
const _hoisted_6 = { class: "terms" }
const _hoisted_7 = {
  key: 3,
  class: "error"
}
const _hoisted_8 = {
  key: 1,
  class: "connect-account-wrapper"
}
const _hoisted_9 = { class: "connect-account-inputs" }
const _hoisted_10 = { class: "forgot-password" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "auth-modal" }, _ctx.options), {
    default: _withCtx(() => [
      (!_ctx.connectAccount)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.data?.variant === 'register')
              ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Create account below"))
              : (_ctx.data?.variant === 'login')
                ? (_openBlock(), _createElementBlock("h2", _hoisted_3, "Log in below"))
                : (_openBlock(), _createElementBlock("h2", _hoisted_4, _cache[7] || (_cache[7] = [
                    _createTextVNode("Create account"),
                    _createElementVNode("br", null, null, -1),
                    _createTextVNode("or log in")
                  ]))),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_app_button, {
                class: "continue-with-btn",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.data?.variant === 'register' ? _ctx.openRegisterDialog() : _ctx.openLoginDialog()))
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createElementVNode("span", null, "Continue with Email", -1),
                  _createElementVNode("img", {
                    class: "email-icon",
                    src: _imports_0,
                    alt: "email-logo"
                  }, null, -1)
                ])),
                _: 1
              }),
              _createVNode(_component_app_button, {
                class: "continue-with-btn",
                loader: _ctx.googleLoader,
                onClick: _ctx.loginWithGoogle
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createElementVNode("span", null, "Continue with Google", -1),
                  _createElementVNode("img", {
                    class: "google-icon",
                    src: _imports_1,
                    alt: "google-logo"
                  }, null, -1)
                ])),
                _: 1
              }, 8, ["loader", "onClick"])
            ]),
            _cache[13] || (_cache[13] = _createElementVNode("hr", null, null, -1)),
            _createElementVNode("p", _hoisted_6, [
              _cache[10] || (_cache[10] = _createTextVNode(" By continuing you agree to our ")),
              _createElementVNode("a", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goTo('boring-stuff/terms-of-service')))
              }, "T&Cs"),
              _cache[11] || (_cache[11] = _createTextVNode(" and our ")),
              _createElementVNode("a", {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goTo('boring-stuff/privacy-policy')))
              }, "Privacy Policy."),
              _cache[12] || (_cache[12] = _createTextVNode(" We use your data to offer you a personalised experience and to better understand and improve our services. "))
            ]),
            (_ctx.loginError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.loginError), 1))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _cache[17] || (_cache[17] = _createElementVNode("h2", null, "Connect your account", -1)),
            _cache[18] || (_cache[18] = _createElementVNode("p", null, " It looks like this email already has an account with us. Enter your password to link the two accounts together. ", -1)),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_app_input, {
                class: "email-input",
                disabled: true,
                value: _ctx.email,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.email) = $event)),
                id: "email_signup"
              }, {
                label: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode("Email address")
                ])),
                _: 1
              }, 8, ["value"]),
              _createVNode(_component_app_input, {
                class: "password-input",
                value: _ctx.password,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.password) = $event)),
                showError: _ctx.passwordError,
                type: "password",
                id: "password"
              }, _createSlots({
                label: _withCtx(() => [
                  _cache[15] || (_cache[15] = _createTextVNode("Password"))
                ]),
                _: 2
              }, [
                (_ctx.passwordError)
                  ? {
                      name: "error",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.passwordError), 1)
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["value", "showError"]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("button", {
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.forgotPassword && _ctx.forgotPassword(...args)))
                }, "Forgot password?")
              ])
            ]),
            _createVNode(_component_app_button, {
              class: "connect-account-button",
              loader: _ctx.linkLoader,
              onClick: _ctx.linkAccount
            }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode("Link accounts")
              ])),
              _: 1
            }, 8, ["loader", "onClick"]),
            _createElementVNode("a", {
              class: "connect-account-dont-link",
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.registerWithThirdParty && _ctx.registerWithThirdParty(...args)))
            }, "Don't link accounts")
          ]))
    ]),
    _: 1
  }, 16))
}