<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 26 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.1904 7.36287H10.3162C10.442 7.36287 10.5259 7.32093 10.5678 7.23706L10.9452 6.6919H11.9097L11.7001 7.02738H12.8743L12.7065 7.57254H11.3226C11.1549 7.82416 10.9872 7.90803 10.7355 7.90803H10.0226L10.1904 7.36287ZM10.0226 8.11771H12.5388L12.371 8.7048H11.3646L11.1968 9.2919H12.2033L12.0355 9.879H11.0291L10.7775 10.7177C10.7355 10.8435 10.7775 10.9274 10.9872 10.8854H11.7839L11.6162 11.4306H10.0646C9.77103 11.4306 9.68716 11.2629 9.77103 10.9274L10.0646 9.83706H9.43555L9.60329 9.24996H10.2323L10.4001 8.66287H9.81297L10.0226 8.11771ZM14.0904 6.6919L14.0484 7.02738C14.0484 7.02738 14.5097 6.64996 14.971 6.64996H16.5646L15.9355 8.91448C15.8936 9.16609 15.6839 9.2919 15.2646 9.2919H13.4614L13.042 10.8435C13.0001 10.9274 13.042 10.9693 13.1259 10.9693H13.5033L13.3775 11.4306H12.4549C12.1194 11.4306 11.9517 11.3467 12.0355 11.0951L13.2517 6.64996H14.0904V6.6919ZM15.4323 7.32093H14.0065L13.8388 7.90803C13.8388 7.90803 14.0904 7.74029 14.4678 7.74029C14.8452 7.74029 15.3065 7.74029 15.3065 7.74029L15.4323 7.32093ZM14.9291 8.7048C15.0549 8.7048 15.0968 8.66287 15.0968 8.579L15.1807 8.24351H13.7549L13.6291 8.7048H14.9291ZM13.9646 9.41771H14.8033V9.79513H15.013C15.1388 9.79513 15.1807 9.75319 15.1807 9.66932L15.2646 9.41771H15.9355L15.8517 9.75319C15.7678 10.0467 15.5581 10.1725 15.2226 10.2145H14.7614V10.8435C14.7614 10.9274 14.8452 11.0113 15.013 11.0113H15.4323L15.3065 11.5145H14.3001C14.0065 11.5145 13.8807 11.3887 13.8807 11.0951L13.9646 9.41771Z"
      fill="#009DA5"
    />
    <path
      d="M11.1549 7.78227C11.1968 7.74034 11.2807 7.65647 11.3226 7.61453H12.7065L12.8742 7.06937H11.7L11.9097 6.73389H10.9452L10.5678 7.23711C10.5259 7.32098 10.442 7.36292 10.3162 7.36292H10.1904L10.1484 7.48873C10.4839 7.5726 10.8194 7.6984 11.1549 7.78227Z"
      fill="#02CCCC"
    />
    <path
      d="M14.0482 7.02732L14.0902 6.69183H13.2514L12.874 8.03377C13.0837 8.03377 13.3353 8.0757 13.545 8.0757C14.5095 8.0757 15.3902 7.9499 16.2708 7.69828L16.5643 6.69183H14.9708C14.5095 6.69183 14.0482 7.02732 14.0482 7.02732ZM15.3063 7.74022C15.3063 7.74022 14.845 7.74022 14.4676 7.74022C14.0482 7.74022 13.8385 7.90796 13.8385 7.90796L14.0063 7.32087H15.4321L15.3063 7.74022Z"
      fill="#02CCCC"
    />
    <path
      d="M17.4448 7.36287L17.6545 6.6919H18.619L18.5771 6.94351C18.5771 6.94351 19.0803 6.6919 19.4158 6.6919C19.7513 6.6919 20.6319 6.6919 20.6319 6.6919L20.4222 7.36287H20.2545L19.3319 10.55H19.4997L19.3319 11.179H19.1222L19.0384 11.4725H18.1158L18.1997 11.179H16.3545L16.5222 10.55H16.69L17.6126 7.36287H17.4448ZM18.4932 7.36287L18.2416 8.24351C18.2416 8.24351 18.6609 8.07577 19.0384 8.03383C19.1222 7.74029 19.2061 7.36287 19.2061 7.36287H18.4932ZM18.1577 8.66287L17.9061 9.58545C17.9061 9.58545 18.3674 9.37577 18.7029 9.33383C18.7867 8.99835 18.8706 8.66287 18.8706 8.66287H18.1577ZM18.3255 10.55L18.4932 9.87899H17.7803L17.6126 10.55H18.3255ZM20.6738 6.64996H21.5964L21.6384 6.98545C21.6384 7.06932 21.6803 7.11125 21.8061 7.11125H21.9738L21.8061 7.69835H21.1351C20.8835 7.69835 20.7577 7.61448 20.7158 7.4048L20.6738 6.64996ZM20.4222 7.90803H23.3577L23.19 8.49512H22.2674L22.0997 9.08222H23.0222L22.8545 9.71125H21.8061L21.5545 10.0887H22.0577L22.1835 10.8016C22.1835 10.8854 22.2674 10.9274 22.3513 10.9274H22.519L22.3513 11.5145H21.8061C21.5126 11.5145 21.3448 11.4306 21.3448 11.2209L21.219 10.55L20.7577 11.2629C20.6319 11.4725 20.4642 11.5564 20.2545 11.5564H19.3738L19.5416 10.9693H19.7932C19.919 10.9693 20.0029 10.9274 20.0868 10.8016L20.7997 9.75319H19.8771L20.0448 9.12416H21.0513L21.219 8.53706H20.2126L20.4222 7.90803Z"
      fill="#009DA5"
    />
    <path d="M18.6196 6.73383H17.655L17.4873 7.27899C17.8647 7.11125 18.2421 6.9435 18.6196 6.73383Z" fill="#02CCCC" />
    <path
      d="M3.9 3.71445C3.77419 4.25961 3.52258 4.67897 3.14516 4.97251C2.72581 5.26606 2.22258 5.4338 1.59355 5.4338C1.00645 5.4338 0.545161 5.26606 0.293548 4.97251C0.083871 4.76284 0 4.46929 0 4.1338C0 4.008 0 3.84026 0.0419355 3.67251L0.712903 0.443481H1.71935L1.04839 3.63058C1.04839 3.71445 1.00645 3.79832 1.00645 3.88219C1.00645 4.04993 1.04839 4.17574 1.13226 4.30155C1.25806 4.46929 1.46774 4.55316 1.71935 4.55316C2.0129 4.55316 2.30645 4.46929 2.47419 4.34348C2.68387 4.17574 2.80968 3.96606 2.85161 3.67251L3.52258 0.485417H4.6129L3.9 3.71445Z"
      fill="#FF7F29"
    />
    <path
      d="M3.9 3.71445C3.77419 4.25961 3.52258 4.67897 3.14516 4.97251C2.72581 5.26606 2.22258 5.4338 1.59355 5.4338C1.00645 5.4338 0.545161 5.26606 0.293548 4.97251C0.083871 4.76284 0 4.46929 0 4.1338C0 4.008 0 3.84026 0.0419355 3.67251L1.50968 0.443481H1.71935L1.04839 3.63058C1.04839 3.71445 1.00645 3.79832 1.00645 3.88219C1.00645 4.04993 1.04839 4.17574 1.13226 4.30155C1.25806 4.46929 1.46774 4.55316 1.71935 4.55316C2.0129 4.55316 2.30645 4.46929 2.47419 4.34348C2.68387 4.17574 2.80968 3.96606 2.85161 3.67251L4.31936 0.485417H4.6129L3.9 3.71445Z"
      fill="#EA2227"
    />
    <path
      d="M4.86438 2.45637H5.57729L5.49342 2.87572L5.57729 2.74992C5.8289 2.4983 6.08052 2.3725 6.416 2.3725C6.70955 2.3725 6.91923 2.45637 7.08697 2.62411C7.21278 2.79185 7.25471 3.04347 7.21278 3.33701L6.83536 5.30798H6.08052L6.416 3.50476C6.45794 3.33701 6.45794 3.16927 6.37406 3.0854C6.33213 3.00153 6.20632 2.95959 6.03858 2.95959C5.8289 2.95959 5.7031 3.00153 5.57729 3.12734C5.45148 3.25314 5.36761 3.42088 5.32568 3.63056L4.99019 5.30798H4.23535L4.86438 2.45637Z"
      fill="#EA2227"
    />
    <path
      d="M13.0842 2.45667H13.7971L13.7132 2.87603L13.7971 2.75022C14.0487 2.49861 14.3003 2.3728 14.6358 2.3728C14.9293 2.3728 15.139 2.45667 15.3067 2.62442C15.4325 2.79216 15.4745 3.04377 15.4325 3.33732L15.0551 5.30829H14.3003L14.6777 3.50506C14.7196 3.33732 14.7196 3.16958 14.6358 3.08571C14.5938 3.00183 14.468 2.9599 14.3003 2.9599C14.0906 2.9599 13.9648 3.00183 13.839 3.12764C13.7132 3.25345 13.6293 3.42119 13.5874 3.63087L13.2519 5.30829H12.4971L13.0842 2.45667Z"
      fill="#A5002F"
    />
    <path
      d="M8.17786 2.45641H8.97463L8.3456 5.34996H7.54883L8.17786 2.45641ZM8.42947 1.40802H9.22625L9.05851 2.12092H8.26173L8.42947 1.40802Z"
      fill="#EA2227"
    />
    <path
      d="M9.6871 5.1403C9.47743 4.93062 9.39355 4.67901 9.39355 4.34353C9.39355 4.30159 9.39355 4.21772 9.39355 4.13385C9.39355 4.04998 9.39355 4.00804 9.43549 3.92417C9.51936 3.46288 9.72904 3.08546 10.0226 2.79191C10.3161 2.49837 10.6936 2.37256 11.1548 2.37256C11.5323 2.37256 11.7839 2.45643 11.9936 2.66611C12.2032 2.87578 12.2871 3.1274 12.2871 3.50482C12.2871 3.54675 12.2871 3.63062 12.2871 3.71449C12.2871 3.79836 12.2871 3.8403 12.2452 3.92417C12.1613 4.38546 11.9516 4.76288 11.6581 5.01449C11.3645 5.26611 10.9871 5.43385 10.5258 5.43385C10.1903 5.43385 9.89678 5.30804 9.6871 5.1403ZM11.1968 4.5532C11.3226 4.38546 11.4484 4.17579 11.4903 3.88224C11.4903 3.8403 11.4903 3.79836 11.5323 3.71449C11.5323 3.67256 11.5323 3.63062 11.5323 3.58869C11.5323 3.42095 11.4903 3.2532 11.4065 3.16933C11.3226 3.08546 11.1968 3.00159 11.029 3.00159C10.8194 3.00159 10.6097 3.08546 10.4839 3.2532C10.3581 3.42095 10.2323 3.63062 10.1903 3.96611C10.1903 4.00804 10.1903 4.04998 10.1484 4.09191C10.1484 4.13385 10.1484 4.17578 10.1484 4.21772C10.1484 4.38546 10.1903 4.5532 10.2742 4.63708C10.3581 4.72095 10.4839 4.76288 10.6516 4.76288C10.9032 4.76288 11.071 4.72095 11.1968 4.5532Z"
      fill="#EA2227"
    />
    <path
      d="M16.6482 0.653137H18.7449C19.1643 0.653137 19.4578 0.737008 19.6675 0.90475C19.8772 1.07249 20.003 1.32411 20.003 1.70152C20.003 1.7854 20.003 1.82733 20.003 1.9112C20.003 1.99507 19.9611 2.07894 19.9611 2.16281C19.8772 2.62411 19.6675 2.95959 19.332 3.25314C18.9966 3.50475 18.6191 3.67249 18.1578 3.67249H17.0256L16.6901 5.34991H15.7256L16.6482 0.653137ZM17.1514 2.83378H18.074C18.3256 2.83378 18.4933 2.79185 18.6611 2.66604C18.7869 2.54023 18.8707 2.37249 18.9546 2.16281C18.9546 2.12088 18.9546 2.07894 18.9546 2.03701C18.9546 1.99507 18.9546 1.95314 18.9546 1.95314C18.9546 1.7854 18.9127 1.65959 18.7869 1.57572C18.6611 1.49185 18.4933 1.44991 18.2417 1.44991H17.4449L17.1514 2.83378Z"
      fill="#1398E2"
    />
    <path
      d="M17.4449 0.653137H18.7029C19.1223 0.653137 19.4159 0.737008 19.6255 0.90475C19.8352 1.07249 19.961 1.32411 19.961 1.70152C19.961 1.7854 19.961 1.82733 19.961 1.9112C19.961 1.99507 19.9191 2.07894 19.9191 2.16281C19.8352 2.62411 19.6255 2.95959 19.29 3.25314C18.9546 3.50475 18.5771 3.67249 18.1159 3.67249H16.9836L16.6481 5.34991H15.6836L17.4449 0.653137ZM17.1513 2.83378H18.0739C18.3255 2.83378 18.4933 2.79185 18.661 2.66604C18.7868 2.54023 18.8707 2.37249 18.9546 2.16281C18.9546 2.12088 18.9546 2.07894 18.9546 2.03701C18.9546 1.99507 18.9546 1.95314 18.9546 1.95314C18.9546 1.7854 18.9126 1.65959 18.7868 1.57572C18.661 1.49185 18.4933 1.44991 18.2417 1.44991H17.4449L17.1513 2.83378Z"
      fill="#0872B9"
    />
    <path
      d="M24.2804 5.89513C23.9868 6.56609 23.6933 6.94351 23.5255 7.11125C23.3578 7.279 23.0223 7.69835 22.1836 7.65641L22.2675 7.15319C22.9384 6.94351 23.3159 5.979 23.5255 5.55964L23.2739 2.45642H23.8191H24.2804L24.3223 4.42739L25.161 2.45642H25.9997L24.2804 5.89513Z"
      fill="#055093"
    />
    <path
      d="M21.9327 2.66614L21.5972 2.91775C21.2617 2.6242 20.9262 2.45646 20.2972 2.75001C19.4585 3.12743 18.7456 6.14678 21.052 5.14033L21.1778 5.30807L22.1004 5.35L22.6875 2.6242L21.9327 2.66614ZM21.3875 4.17581C21.2198 4.59517 20.9262 4.88871 20.6746 4.80484C20.423 4.72097 20.3391 4.30162 20.4649 3.88226C20.6327 3.46291 20.9262 3.16936 21.1778 3.25323C21.4714 3.3371 21.5553 3.75646 21.3875 4.17581Z"
      fill="#0872B9"
    />
    <path
      d="M20.4229 5.34995C20.5906 5.30801 20.8003 5.26608 21.0519 5.18221L21.1777 5.34995L22.1003 5.39188L22.6874 2.66608H22.4777C22.0583 3.71446 21.3035 4.63705 20.4229 5.34995Z"
      fill="#055093"
    />
    <path
      d="M11.9936 2.66635C11.7839 2.45667 11.4904 2.3728 11.1549 2.3728C11.071 2.3728 11.0291 2.3728 10.9452 2.3728C10.8614 2.62442 10.7355 2.87603 10.6097 3.12764C10.7355 3.04377 10.8614 3.00183 11.0291 3.00183C11.1968 3.00183 11.3226 3.04377 11.4065 3.16958C11.4904 3.25345 11.5323 3.42119 11.5323 3.58893C11.5323 3.63087 11.5323 3.6728 11.5323 3.71474C11.5323 3.75667 11.5323 3.79861 11.4904 3.88248C11.4485 4.17603 11.3226 4.42764 11.1968 4.55345C11.071 4.72119 10.8614 4.76312 10.6517 4.76312C10.4839 4.76312 10.3581 4.72119 10.2743 4.63732C10.1904 4.55345 10.1485 4.38571 10.1485 4.21796C10.1485 4.17603 10.1485 4.13409 10.1485 4.09216C10.1485 4.05022 10.1484 4.00829 10.1904 3.96635C10.1904 3.88248 10.2323 3.84054 10.2323 3.79861C10.0226 4.13409 9.7291 4.46958 9.43555 4.76312C9.47748 4.93087 9.56135 5.05667 9.68716 5.18248C9.89684 5.39216 10.1904 5.47603 10.5259 5.47603C10.9452 5.47603 11.3226 5.35022 11.6581 5.05667C11.9517 4.80506 12.1614 4.42764 12.2452 3.96635C12.2452 3.88248 12.2872 3.84054 12.2872 3.75667C12.2872 3.6728 12.2872 3.63087 12.2872 3.547C12.2872 3.12764 12.2033 2.87603 11.9936 2.66635Z"
      fill="#A5002F"
    />
  </svg>
  <!-- eslint-enable -->
</template>
