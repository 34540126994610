import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "restaurant-header-wrapper-desktop" }
const _hoisted_2 = {
  key: 0,
  class: "action-buttons"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_back_link = _resolveComponent("app-back-link")!
  const _component_ButtonOrderTracker = _resolveComponent("ButtonOrderTracker")!
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.query.embedded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isInCheckout)
            ? (_openBlock(), _createBlock(_component_app_back_link, {
                key: 0,
                mini: "",
                link: `/menu/${_ctx.restaurant?.restaurantCity}/${_ctx.restaurant?.slug}`
              }, null, 8, ["link"]))
            : _createCommentVNode("", true),
          (_ctx.user)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_ButtonOrderTracker),
                _createVNode(_component_app_button, {
                  mini: "",
                  square: "",
                  white: "",
                  onClick: _ctx.goToSettings
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_IconServices, { icon: "account" })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (!(_ctx.groupData && !_ctx.isInCheckout))
            ? (_openBlock(), _createBlock(_component_app_back_link, {
                key: 0,
                mini: "",
                link: `${
          _ctx.isInCheckout
            ? `/menu/${_ctx.restaurant?.restaurantCity}/${_ctx.restaurant?.slug}`
            : `/restaurants/${_ctx.restaurant?.restaurantCity}`
        }`
              }, null, 8, ["link"]))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}