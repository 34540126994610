import { FirebaseError } from '@firebase/util';
import { UserInfo, getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';

import Environment from '@/environment';

import http from '@/utils/http';

class HttpAccounts {
  public accessibleCities(): Promise<AccessibleCities[]> {
    return http
      .get('/accounts/accessible-cities')
      .then((res: AccessibleCities[]) => res)
      .catch((err: any) => err);
  }

  // eslint-disable-next-line consistent-return
  public async forgotPassword(email: string) {
    try {
      await sendPasswordResetEmail(getAuth(), email);
    } catch (_) {}
  }

  public resetPassword(code: string, data: any) {
    return http
      .post(`/accounts/forgot-password/${code}`, data)
      .then((res: any) => res)
      .catch((err: string) => err);
  }

  public getPaymentMethods(): Promise<{ paymentMethods: { id: string; invoice: boolean }[] }> {
    return http.get('/accounts/checkout-data').then((res: any) => ({ paymentMethods: res.paymentMethods || [] }));
  }

  public getInvoices(): Promise<XeroInvoicesItem[]> {
    return http.get('/accounts/invoices').then((res: any) => res.invoices || []);
  }

  public getInvoicePDF(invoiceId: string): Promise<string> {
    return http.get(`/accounts/invoice/${invoiceId}`);
  }

  async getIsSuperAdmin(): Promise<boolean> {
    return http.get(`/accounts/permissions-check`).then((res: any) => res.grantAccess);
  }

  public putProfileInfo(info: User): Promise<User> {
    return http
      .put('/accounts/profile/info', { ...info })
      .then((res: User) => res)
      .catch((err: any) => err);
  }

  public putChangePassword(pass: Password): Promise<Password> {
    return http
      .put('/accounts/profile/change-password', { ...pass })
      .then((res: Password) => res)
      .catch((err: any) => err);
  }

  public async verifyEmailAvailable(email: string) {
    return http.post('/accounts/email/verify', { email });
  }

  public async verifyEmailDomain(email: string): Promise<{ success: boolean }> {
    return http.post('/accounts/email/verify-domain', { email });
  }

  public async updateNameAndEmail(email: string, name: string) {
    return http.put('/v2/accounts/profile/info', { firstName: name, email });
  }

  // TODO: Improve
  public async saveUserToFirestore(companyName: string | null = null, isBusinessUser: boolean = false) {
    const providers = ['password', 'facebook.com', 'google.com'];

    const user = getAuth().currentUser;
    const db = getFirestore();

    if (!user) {
      throw new Error('User not signed in to firebase.');
    }

    const uid = user?.uid;

    const retrievedDoc = (await getDoc(doc(getFirestore(), Environment.usersCollection, uid))).data();

    const email = user.providerData.find((data) => providers.includes(data.providerId))?.email;
    const firstName = user.displayName;
    const phone = user.providerData.find((data) => data.providerId === 'phone')?.phoneNumber;
    const loginTypes = this.determineLoginTypes(user.providerData);

    if (!uid || !email || !firstName || !phone || !loginTypes) {
      throw new Error('Cannot save the user');
    }

    if (!retrievedDoc) {
      const userToSave = {
        addresses: [],
        allowPromotionalNotifications: true,
        companyName,
        creditBalance: 0,
        email,
        firstName,
        isBusinessUser,
        lastSearchedCity: null,
        loginTypes,
        phone,
        totalOrders: 0,
      };

      await setDoc(doc(db, Environment.usersCollection, uid), userToSave);
      return;
    }

    await updateDoc(doc(db, Environment.usersCollection, uid), {
      email,
      firstName,
      phone,
      loginTypes,
    });
  }

  private determineLoginTypes(providerData: UserInfo[]) {
    const loginTypes: string[] = [];

    const thirdParties = ['google.com', 'facebook.com', 'apple.com'];

    const thirdPartyUserInfo = providerData.find((data) => thirdParties.includes(data.providerId));

    if (thirdPartyUserInfo) {
      loginTypes.push(thirdPartyUserInfo.providerId.replace('.com', ''));
    }

    const emailUserInfo = providerData.find((data) => data.providerId === 'password');

    if (emailUserInfo) {
      loginTypes.push('email');
    }

    return loginTypes;
  }

  public async userExistsInFirestore(uid: string) {
    const retrievedUser = (await getDoc(doc(getFirestore(), Environment.usersCollection, uid))).data();

    if (!retrievedUser) {
      return false;
    }
    return true;
  }

  public signInPreCheck(email: string, type: string): Promise<PreCheckResponse> {
    return http.post('/v2/accounts/sign-in/pre-check', {
      email,
      type,
    });
  }

  public verifyPhone(phone: string): Promise<boolean> {
    return http.post('/accounts/phone/verify', {
      phone,
    });
  }
}

export interface Login {
  email: string;
  password: string;
  phone?: string;
}

export interface SignUp {
  firstName: string;
  phone: string;
  email: string;
  password: string;
  reEnterPassword?: string;
  companyName?: string;
}

export interface Password {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export interface DataResetPassword {
  newPassword: string;
  newPasswordConfirm: string;
}

export interface AccessibleCities {
  city: string;
  msg: string;
  lat: number;
  lng: number;
}

export interface PreCheckResponse {
  success: boolean;
  isLinkingAccountsPossible?: boolean;
}

export interface XeroInvoicesItem {
  date: string;
  dueDate: string;
  invoiceID: string;
  invoiceNumber: number;
  paidDate: string;
  paid: boolean;
  total: number;
  type: string;
}

export default new HttpAccounts();
