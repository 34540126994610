import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkout_info = _resolveComponent("checkout-info")!
  const _component_Basket = _resolveComponent("Basket")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["checkout-wrapper", { animation: _ctx.transition }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["checkout-info-wrapper", { login: !_ctx.user }])
    }, [
      _createVNode(_component_checkout_info)
    ], 2),
    _createVNode(_component_Basket, {
      restaurant: _ctx.restaurantData.restaurant,
      checkout: ""
    }, null, 8, ["restaurant"])
  ], 2))
}