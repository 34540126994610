import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/simple_arrow_bottom.svg'


const _hoisted_1 = {
  key: 0,
  class: "modal-close"
}
const _hoisted_2 = {
  key: 1,
  class: "calendar-title-wrapper"
}
const _hoisted_3 = {
  key: 2,
  class: "order-modal"
}
const _hoisted_4 = { class: "order-modal-delivery-time" }
const _hoisted_5 = { id: "buttons" }
const _hoisted_6 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "error-modal" }, _ctx.options), {
    default: _withCtx(() => [
      (!_ctx.isCalendarVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_IconServices, {
              close: "",
              icon: "add",
              class: "custom-cursor-hover",
              onClick: _ctx.closeDialog
            }, null, 8, ["onClick"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
            _createElementVNode("h2", { class: "order-modal-title" }, "Set deadline", -1)
          ]))),
      (!_ctx.isCalendarVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "order-modal-title" }, "Set deadline", -1)),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_app_button, {
                slim: "",
                white: "",
                onClick: _ctx.showCalendar
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.getFormattedDate), 1),
                  _cache[1] || (_cache[1] = _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }, null, -1))
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_app_button, {
                class: "order-modal-clear-btn square slim",
                onClick: _ctx.clear
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Clear")
                ])),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_app_button, {
                class: "order-modal-apply-btn square slim",
                onClick: _ctx.apply
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Apply")
                ])),
                _: 1
              }, 8, ["onClick"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_Calendar, {
              dateTime: _ctx.dateTime,
              interval: _ctx.interval,
              intervalsByDays: _ctx.timeIntervalsByDays,
              onConfirmClick: _ctx.onCalendarConfirmClick
            }, null, 8, ["dateTime", "interval", "intervalsByDays", "onConfirmClick"])
          ]))
    ]),
    _: 1
  }, 16))
}