<template>
  <div class="built_for_ease_section">
    <Card title="The bottom line">
      <template v-slot:body>
        <div class="built_for_ease_card_body">
          <h1 class="section_title">Built for ease</h1>
          <h2 class="sub_title">Foodstuff make it easy for the whole crew</h2>
          <div class="crew_buttons">
            <app-button
              slim
              @click="() => (activeBuiltForEaseOption = 'office_managers')"
              :white="activeBuiltForEaseOption !== 'office_managers'"
            >
              <p>Office managers</p>
            </app-button>
            <app-button
              slim
              @click="() => (activeBuiltForEaseOption = 'finance_teams')"
              :white="activeBuiltForEaseOption !== 'finance_teams'"
            >
              <p>Finance teams</p>
            </app-button>
            <app-button
              slim
              @click="() => (activeBuiltForEaseOption = 'directors')"
              :white="activeBuiltForEaseOption !== 'directors'"
            >
              <p>CEO/directors</p>
            </app-button>
          </div>

          <!-- Office manager bullet points -->
          <div v-if="activeBuiltForEaseOption === 'office_managers'" class="bullet_points">
            <div class="bullet_point_row">
              <img src="../../assets/landing/built_for_ease_bullet_point.png" alt="" />
              <div class="bullet_point_texts">
                <b>Variety and knock-out flavours</b>
                <p>One place to cover all dietary needs and preferences</p>
              </div>
            </div>
            <div class="bullet_point_row">
              <img src="../../assets/landing/built_for_ease_bullet_point.png" alt="" />
              <div class="bullet_point_texts">
                <b>Dependable and flexible</b>
                <p>Whether you’re booking ahead or spinning up last minute plans we’ll hit the mark</p>
              </div>
            </div>
            <div class="bullet_point_row">
              <img src="../../assets/landing/built_for_ease_bullet_point.png" alt="" />
              <div class="bullet_point_texts">
                <b>We’ll take the load off</b>
                <p>Our team are always on hand to take things off your desk</p>
              </div>
            </div>
          </div>

          <!-- Finance team bullet points -->
          <div v-if="activeBuiltForEaseOption === 'finance_teams'" class="bullet_points">
            <div class="bullet_point_row">
              <img src="../../assets/landing/built_for_ease_bullet_point.png" alt="" />
              <div class="bullet_point_texts">
                <b>Flexible payment methods</b>
                <p>We’ll fit with how you like teams to run food through the books</p>
              </div>
            </div>
            <div class="bullet_point_row">
              <img src="../../assets/landing/built_for_ease_bullet_point.png" alt="" />
              <div class="bullet_point_texts">
                <b>Speedy support from humans</b>
                <p>Our finance team is waiting to help</p>
              </div>
            </div>
            <div class="bullet_point_row">
              <img src="../../assets/landing/built_for_ease_bullet_point.png" alt="" />
              <div class="bullet_point_texts">
                <b>We’ll follow PO and procurement asks</b>
                <p>Let us know if you need us on your system</p>
              </div>
            </div>
          </div>

          <!-- CEO/Directors bullet points -->
          <div v-if="activeBuiltForEaseOption === 'directors'" class="bullet_points">
            <div class="bullet_point_row">
              <img src="../../assets/landing/built_for_ease_bullet_point.png" alt="" />
              <div class="bullet_point_texts">
                <b>Get teams back in the office</b>
                <p>Creativity sparks when food brings you together</p>
              </div>
            </div>
            <div class="bullet_point_row">
              <img src="../../assets/landing/built_for_ease_bullet_point.png" alt="" />
              <div class="bullet_point_texts">
                <b>Advanced perks are important</b>
                <p>Retains teams and keeps them happy</p>
              </div>
            </div>
            <div class="bullet_point_row">
              <img src="../../assets/landing/built_for_ease_bullet_point.png" alt="" />
              <div class="bullet_point_texts">
                <b>Clients are bored of sandwiches</b>
                <p>Food will get them coming back to see you again and again</p>
              </div>
            </div>
          </div>
        </div>
        <div class="hide_mobile want_to_learn_more custom-cursor-hover" @click="onHereClick">
          <p>Want to learn more? Chat to one of our team <ins>here</ins></p>
        </div>
        <div class="hide_desktop want_to_learn_more custom-cursor-hover" @click="onHereClick">
          <p>Chat to one of our team <ins>here</ins></p>
        </div>
      </template>
    </Card>
    <Carousel class="quotes_carousel" :wrap-around="true">
      <Slide key="spotify">
        <div class="quotes_carousel_slide">
          <p>“Food was amazing, great variety and spot on recommendations - you guys are professionals!”</p>
          <p>-Rosche</p>
          <img src="../../assets/landing/built_for_ease_apple_logo.png" alt="" style="height: 55px" />
        </div>
      </Slide>
      <Slide key="bango">
        <div class="quotes_carousel_slide">
          <p>
            “It arrived perfectly in time and it was delicious. We really enjoyed it! I am sure we will be ordering
            again!!”
          </p>
          <p>-Ellis</p>
          <img src="../../assets/landing/built_for_ease_bango_logo.png" alt="" style="height: 44px" />
        </div>
      </Slide>
      <Slide key="adder">
        <div class="quotes_carousel_slide">
          <p>
            “I had a number of people declare the food the best lunch they’ve ever had here so that’s a huge box tick
            for me!”
          </p>
          <p>-Janice</p>
          <img src="../../assets/landing/built_for_ease_adder_logo.png" alt="" style="height: 55px" />
        </div>
      </Slide>
      <template #addons>
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script lang="ts">
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import { Options, Vue } from 'vue-class-component';

import Card from '@/landing/Card.vue';

@Options({
  components: {
    Card,
    Carousel,
    Navigation,
    Pagination,
    Slide,
  },
  props: {
    onHereClick: Function,
  },
})
export default class BuiltForEase extends Vue {
  private activeBuiltForEaseOption = 'office_managers';
}
</script>

<style lang="scss">
.built_for_ease_section {
  .carousel__pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    line-height: 0;
    margin: 15px 0;
    padding: 0;
    gap: 15px;
  }

  .carousel__pagination-button {
    cursor: url('../../assets/cursor_hover.png'), auto;
    padding: 0;
  }

  .carousel__pagination-button::after {
    width: 13px;
    height: 13px;
    border-radius: 13px;
    background-color: white;
  }

  .carousel__pagination-button--active::after {
    background-color: $ham;
  }
}
</style>

<style lang="scss" scoped>
.section_title {
  color: $coal100;
  font-family: Recoleta, sans-serif;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 10px;

  @include for-smartphone {
    font-size: 38px;
    line-height: 40px;
  }
}

.sub_title {
  font-family:
    Sofia Pro,
    sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 300;
  text-align: center;
  color: black;
  padding: 0px 30px;
}

.built_for_ease_section {
  width: 100%;
  padding-top: 70px;
  padding-bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-image: url('../../assets/landing/built_for_ease_background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include for-smartphone {
    flex-direction: column;
    gap: 10px;
  }

  .built_for_ease_card_body {
    padding: 20px 40px 40px 40px;
    max-width: 600px;
    min-height: 512.5px;

    @include for-custom(1050) {
      min-height: 533.5px;
    }

    @include for-smartphone {
      width: calc(100vw - 40px);
      padding: 20px 20px 40px 20px;
      max-width: 620px;
      min-height: 431px;
    }

    @include for-custom(507) {
      min-height: 477px;
    }

    @include for-custom(476) {
      min-height: 498px;
    }

    @include for-custom(405) {
      min-height: 519px;
    }

    @include for-custom(345) {
      min-height: 544px;
    }
  }

  .crew_buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 40px;
    margin-left: 40px;

    @include for-smartphone {
      justify-content: center;
      gap: 7.5px;
      margin-top: 20px;
      margin-left: 0px;
    }

    button {
      border: 1px solid black;
      border-radius: 7.5px;
      padding: 10px;

      @include for-smartphone {
        padding: 0px 7.5px;
        min-height: 30px;
      }

      p {
        font-size: 15px;
        @include for-smartphone {
          font-size: 11px;
        }
      }
    }
  }

  .bullet_points {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 40px;
    margin-left: 40px;

    @include for-smartphone {
      margin-top: 30px;
      margin-left: 0px;
    }

    .bullet_point_row {
      display: flex;
      flex-direction: row;
      gap: 15px;
    }

    img {
      width: 39px;
      height: 39px;

      @include for-smartphone {
        height: 22px;
        width: 22px;
      }
    }

    .bullet_point_texts {
      display: flex;
      flex-direction: column;
      gap: 4px;

      b {
        font-family: Sofia Pro;
        font-size: 19px;

        @include for-smartphone {
          font-size: 15px;
          margin-top: 4.5px;
        }
      }

      p {
        font-family:
          Sofia Pro,
          sans-serif;
        font-size: 17px;
        font-weight: 300;
        color: black;

        @include for-smartphone {
          font-size: 15px;
        }
      }
    }
  }

  .want_to_learn_more {
    padding: 15px;
    background-color: $ham;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top: 2px solid $coal100;

    p,
    ins {
      font-family:
        Sofia Pro,
        sans-serif;
      font-size: 16px;
      font-weight: 400;

      @include for-smartphone {
        font-size: 15px;
      }
    }
  }

  .hide_mobile {
    @include for-smartphone {
      display: none;
    }
  }

  .hide_desktop {
    @include for-desktop {
      display: none;
    }
  }

  .quotes_carousel {
    width: 400px;
    max-width: 620px;
    background-color: #1d1b1bcc;
    border-radius: 15px;
    margin-right: 20px;

    @include for-smartphone {
      width: calc(100vw - 40px);
      margin-left: 20px;
    }

    .quotes_carousel_slide {
      width: 400px;
      padding: 40px;
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        font-family:
          Sofia Pro,
          sans-serif;
        font-size: 17px;
        line-height: 30px;
        font-weight: 300;
        color: white;
      }

      p:last-of-type {
        margin-top: 15px;
        margin-bottom: 5px;
        font-style: italic;
      }
    }
  }
}
</style>
