import {
  ConfirmCardPaymentData,
  PaymentIntent,
  PaymentMethod,
  Stripe,
  StripeCardNumberElement,
  StripeError,
  loadStripe,
} from '@stripe/stripe-js';

import instanced from '@/components/PaymentMethod/stripe-custom-elements';

import Environment from '@/environment';

import http from '@/utils/http';

class HttpOrders {
  public deleteCard(id: string): Promise<any> {
    return http
      .delete(`/orders/delete-card/${id}`)
      .then((res: any) => res)
      .catch((err: any) => err);
  }

  public validateBasket(basket: any): Promise<any> {
    return http.post(`${Environment.baseApiUrl}/checkout/validation`, basket).then((res: any) => res);
  }

  public getOrders(): Promise<any> {
    return http.get('/orders');
  }

  public getOrder(friendlyId?: string): Promise<any> {
    return http.get(friendlyId ? `/order/status?orderId=${friendlyId}` : '/order/status');
  }

  public createOrder(data: any): Promise<{ id: string; friendlyId: number }> {
    return http.post('/orders', data);
  }

  public amendOrder(data: any, orderId: string): Promise<{ clientSecret?: string; paymentMethodId?: string }> {
    return http.put(`/orders/${orderId}`, data);
  }

  public async confirmInvoicePayment(orderId: string, invoiceNote: string) {
    return http.post('/payments/invoice', { orderId, invoiceNote });
  }

  public createPayment(orderId: any, paymentMethod: any) {
    return http.post(`/orders/${orderId}/payments`, { paymentMethod });
  }

  public async confirmPayment(clientSecret: string, paymentMethod?: any): Promise<PaymentIntent> {
    let config: ConfirmCardPaymentData | undefined;
    let stripe: Stripe | null = null;

    if (instanced.stripe) stripe = instanced.stripe as Stripe;
    else stripe = await loadStripe(Environment.stripeKey);

    if (!paymentMethod) {
      // CHANGE CONFIG FOR NEW CARD
      if (stripe && instanced.elements) {
        await stripe
          .createPaymentMethod({
            type: 'card',
            card: instanced.elements.getElement('cardNumber') as StripeCardNumberElement,
          })
          .then((card: PaymentMethodThen) => {
            if (card.error) throw new Error(card.error.message);

            if (card.paymentMethod) {
              config = {
                // eslint-disable-next-line
                setup_future_usage: 'on_session',
                // eslint-disable-next-line
                payment_method: card.paymentMethod.id,
              };
            }
          })
          .catch((err: any) => err);
      }
    }

    return new Promise((resolve, reject) => {
      if (stripe) {
        stripe
          .confirmCardPayment(clientSecret, config)
          .then((value: PaymentIntentThen) => {
            if (value.error) throw new Error(value.error.message);

            resolve(value.paymentIntent as PaymentIntent);
          })
          .catch((err: any) => reject(err));
      }
    });
  }

  public async createPrefilledBasket(orderDetails: any) {
    return http.post('/prefilled-baskets', orderDetails);
  }

  public async getPrefilledBasket(prefilledBasketId: string) {
    return http.get(`/prefilled-baskets/${prefilledBasketId}`);
  }

  public async acknowledgeOrder(data: any) {
    return http.post('/admin/orders/acknowledge-order', data);
  }
}

interface PaymentMethodThen {
  paymentMethod?: PaymentMethod;
  error?: StripeError;
}

export interface PaymentIntentThen {
  paymentIntent?: PaymentIntent;
  error?: StripeError;
}

export default new HttpOrders();
