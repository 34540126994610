<template>
  <div class="modification-wrapper">
    <!-- Title -->
    <h2>
      {{ modification.name }}
      <span v-if="modification.required" class="modification-required">required</span>
    </h2>
    <div class="divider ham full-width" />

    <!-- Optional subtitle -->
    <small v-if="modification.limitMax > 1 && modification.required">
      select
      {{
        modification.limitMin === modification.limitMax ? modification.limitMin : `at least ${modification.limitMin}`
      }}
      {{ modification.limitMin === 1 ? 'modification' : 'modifications' }}
    </small>

    <!-- Options -->
    <div class="modification">
      <div
        class="modification-item"
        v-for="(option, oIndex) in getAvailableOptions(modification.options)"
        :key="oIndex"
      >
        <app-option
          :disabled="!isEnabled"
          :active="option.amount > 0"
          :type="isRadioButton ? 'radio' : 'checkbox'"
          @click.prevent="select(option)"
        >
          <p class="modification-title us-n">
            {{ option.name }}
            <span v-if="option.price > 0"> +{{ option.price.toFixed(2) }} </span>
          </p>
        </app-option>

        <app-count
          v-if="option.isMultiSelect && active(option)"
          :plusDisabled="!isEnabled"
          :amount="option.amount"
          :setAmount="(newAmount) => (newAmount === 0 ? select(option) : count(newAmount, option))"
          mini
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  modification = prop<ProductModifier>({ required: true });
}

@Options({
  props: { modification: Object },
  emits: { selected: Object },
})
export default class Modifications extends Vue.with(Props) {
  private selected: ProductOption[] = [];

  get isRadioButton() {
    return this.modification.limitMin === 1 && this.modification.limitMax === 1 && this.modification.required;
  }

  get isEnabled() {
    return (
      this.isRadioButton ||
      this.modification.options.reduce((sum, o) => sum + (o.amount || 0), 0) < this.modification.limitMax
    );
  }

  private active(option: ProductOption) {
    return !!this.selected.find((element: ProductOption) => element === option);
  }

  private getAvailableOptions(options: ProductOption[]) {
    return options.filter((o) => !o.soldOut);
  }

  private count(amount: number, option: ProductOption) {
    const index = this.selected.findIndex((s: ProductOption) => s.id === option.id);
    if (index >= 0) this.selected[index].amount = amount;

    this.return();
  }

  private select(option: ProductOption) {
    if (!this.isEnabled && !this.active(option)) return;

    if (this.isRadioButton) this.radio(option);
    else this.checkbox(option);
  }

  private checkbox(option: ProductOption) {
    const index = this.selected.findIndex((i: ProductOption) => i === option);
    if (index >= 0) {
      this.selected.splice(index, 1);
    } else {
      this.selected.push(option);
    }

    this.return();
  }

  private radio(option: ProductOption) {
    if (this.selected.length > 0) this.selected.splice(0, 1, option);
    else this.selected.push(option);

    this.return();
  }

  private return() {
    const updatedModifier: ProductModifier = { ...this.modification };
    updatedModifier.options.forEach((e) => {
      const matchingSelection = this.selected.find((s) => s.id === e.id);
      if (matchingSelection) e.amount = matchingSelection.amount || 1;
      else e.amount = 0;
    });

    this.$emit('selected', updatedModifier);
  }
}
</script>

<style lang="scss">
.modification {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  &-wrapper {
    margin: 15px 0;

    small {
      display: inline-block;
      margin-top: 10px;
    }
  }

  &-required {
    color: $darkHam;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .controller-option {
      width: 100%;
    }
    .controller-count {
      margin-top: 0 !important;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    span {
      margin-left: 10px;
    }
  }
}
</style>
