/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state: StateAmendableOrder = {};

const mutations = {
  set(state: StateAmendableOrder, payload: any) {
    state.data = payload;
  },
  clear(state: StateAmendableOrder) {
    delete state.data;
  },
};

const actions = {};

const getters = {};

export interface StateAmendableOrder {
  data?: {
    address: Address;
    cart: OrderCart;
    createdAt: string;
    coupon: Coupon;
    deliverToTime: string;
    deliveryNotes: string;
    deliveryPrice: number;
    friendlyId: number;
    id: string;
    invoiceNote: string;
    kitchenNotes: string;
    paymentMethod: string;
    restaurantSlug: string;
    tipAmount: number;
    totalPrice: number;
    type: 'delivery' | 'collect';
  };
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
