import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "dish-modal-div"
}
const _hoisted_3 = { class: "dish-modal-wrapper" }
const _hoisted_4 = { class: "dish-name" }
const _hoisted_5 = {
  key: 2,
  class: "dish-added-wrapper"
}
const _hoisted_6 = {
  key: 3,
  class: "dish-added-wrapper"
}
const _hoisted_7 = {
  key: 4,
  class: "dish-added-wrapper"
}
const _hoisted_8 = {
  key: 5,
  class: "dish-added-wrapper"
}
const _hoisted_9 = {
  key: 6,
  class: "dish-added-wrapper"
}
const _hoisted_10 = {
  key: 7,
  class: "dish-added-wrapper"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }
const _hoisted_14 = { key: 3 }
const _hoisted_15 = { key: 4 }
const _hoisted_16 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_Modifications = _resolveComponent("Modifications")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_text_accordion = _resolveComponent("app-text-accordion")!
  const _component_app_count = _resolveComponent("app-count")!
  const _component_ItemAdded = _resolveComponent("ItemAdded")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_ctx.dish)
    ? (_openBlock(), _createBlock(_component_app_modal, _mergeProps({
        key: 0,
        class: [{
      'no-modification': _ctx.dish.modificators.length === 0 && !_ctx.dish.description,
    }, "dish-modal"]
      }, _ctx.options), {
        default: _withCtx(() => [
          _createVNode(_component_app_button, {
            class: "dish-modal-close",
            mini: "",
            white: "",
            onClick: _withModifiers(_ctx.close, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconServices, {
                class: "services-icon",
                icon: "add",
                close: ""
              })
            ]),
            _: 1
          }, 8, ["onClick"]),
          (_ctx.dish.photo && _ctx.dish.photo !== 'https://storage.googleapis.com/foodstufff/default/stub.svg')
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.dish.photo,
                class: "dish-modal-cover",
                alt: "dish photo"
              }, null, 8, _hoisted_1))
            : (_openBlock(), _createElementBlock("div", _hoisted_2)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.dish.name), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.truncate(_ctx.dish.description)), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dish.modificators, (modification, mIndex) => {
              return (_openBlock(), _createBlock(_component_Modifications, {
                onSelected: _ctx.selected,
                modification: modification,
                key: mIndex
              }, null, 8, ["onSelected", "modification"]))
            }), 128)),
            _createVNode(_component_app_text_accordion, {
              style: {"margin-top":"10px"},
              title: "Add a kitchen note?"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_input, {
                  value: _ctx.itemNote,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.itemNote) = $event)),
                  class: "animated slideOutDown"
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_app_count, {
              amount: _ctx.dish?.amount,
              setAmount: _ctx.setAmount,
              minusDisabled: !_ctx.dish || _ctx.dish.amount === 1
            }, null, 8, ["amount", "setAmount", "minusDisabled"])
          ]),
          (!_ctx.cityName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_app_button, {
                  class: "enter-address",
                  onClick: _withModifiers(_ctx.showSetAddressModal, ["prevent"])
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Enter address for availability ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]))
            : (_ctx.isTooFar)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_app_button, {
                    class: "enter-address",
                    onClick: _withModifiers(_ctx.showSetAddressModal, ["prevent"])
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("Restaurant is too far")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]))
              : (!_ctx.isDeliveryLateEnough && _ctx.amendableData)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_app_button, {
                      class: "dish-added",
                      disabled: ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", null, [
                          _cache[4] || (_cache[4] = _createTextVNode(" The restaurant needs")),
                          _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(" " + _toDisplayString(_ctx.minHoursInAdvance) + " " + _toDisplayString(_ctx.minHoursInAdvance === 1 ? 'hour' : 'hours') + " notice for this item ", 1)
                        ])
                      ]),
                      _: 1
                    })
                  ]))
                : (!_ctx.isDeliveryLateEnough)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_app_button, {
                        class: "enter-time",
                        onClick: _withModifiers(_ctx.showOrderSettingsModal, ["prevent"])
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, "The restaurant needs " + _toDisplayString(_ctx.minHoursInAdvance) + " " + _toDisplayString(_ctx.minHoursInAdvance === 1 ? 'hour' : 'hours') + " notice", 1),
                          _cache[6] || (_cache[6] = _createElementVNode("p", null, "for this item. Please edit delivery date.", -1))
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]))
                  : (
        !_ctx.areItemsConfirmed &&
        !_ctx.data.restaurant.isClosed &&
        !_ctx.data.restaurant.isTemporarilyClose &&
        !_ctx.data.category.isClosed &&
        !_ctx.dish.sold &&
        !_ctx.data.deliveryLabel
      )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_component_app_button, {
                          disabled: _ctx.disabled,
                          class: "dish-added",
                          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.add(_ctx.dish)), ["prevent"]))
                        }, {
                          default: _withCtx(() => [
                            (_ctx.itemAdded)
                              ? (_openBlock(), _createBlock(_component_ItemAdded, { key: 0 }))
                              : _createCommentVNode("", true),
                            _createTextVNode(" Add to basket - £" + _toDisplayString(((_ctx.getDishPrice + _ctx.getPriceModifications) * _ctx.dish.amount).toFixed(2)), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createVNode(_component_app_button, {
                          disabled: 
          _ctx.areItemsConfirmed ||
          _ctx.data.restaurant.isClosed ||
          _ctx.data.restaurant.isTemporarilyClose ||
          _ctx.data.category.isClosed ||
          _ctx.dish.sold ||
          _ctx.data.deliveryLabel
        ,
                          class: "dish-added"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.areItemsConfirmed)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_11, "You already confirmed your items"))
                              : (_ctx.data.restaurant.isClosed)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_12, "Restaurant closed"))
                                : (_ctx.data.category.isClosed)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_13, "Item unavailable"))
                                  : (_ctx.dish.sold)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_14, "Sold out"))
                                    : (_ctx.data.deliveryLabel)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.data.deliveryLabel), 1))
                                      : (_openBlock(), _createElementBlock("span", _hoisted_16, "Back soon"))
                          ]),
                          _: 1
                        }, 8, ["disabled"])
                      ]))
        ]),
        _: 1
      }, 16, ["class"]))
    : _createCommentVNode("", true)
}