<template>
  <div class="dashboard">
    <div class="header">
      <Logo />
      <app-button square white slim class="add" @click.prevent="addEvent">
        <p>+ Add another event</p>
      </app-button>
      <app-button square white slim class="chat_with_us" @click.prevent="chatWithUs">
        <img src="../../assets/gtm/james.png" alt="" />
        <p>Chat with us</p>
      </app-button>
    </div>
    <div class="upper_part">
      <div class="texts">
        <h1>Event slot booked!</h1>
        <p>
          We're busy building your custom marketplace featuring existing office favourites and a host of new exciting
          eateries. All of your office food orders will soon be in one place! One supplier and maximum variety... That
          sounds pretty good eh?
        </p>
        <p>
          <ins class="custom-cursor-hover" @click="bookDemoCall"><strong>Schedule a call here</strong></ins> so that we
          can show you what we've built for you! We aim to have things ready within 72 hours.
        </p>
      </div>
      <div class="demo_call">
        <div class="demo_call_header">
          <img src="../../assets/gtm/dashboard/toby_demo.png" alt="" />
          <div>
            <h1>Schedule a call</h1>
            <p>with Toby</p>
          </div>
        </div>
        <app-button slim @click="bookDemoCall">+</app-button>
      </div>
    </div>
    <div class="lower_part">
      <div class="lower_part_body">
        <h1>Next steps</h1>
        <div class="steps">
          <div class="step">
            <img src="../../assets/gtm/dashboard/view.png" alt="" />
            <p class="step_title">View restaurant roster</p>
            <p class="step_description">Check out our curated list of restaurants based on your criteria</p>
          </div>
          <div class="step">
            <img src="../../assets/gtm/dashboard/choose.png" alt="" />
            <p class="step_title">Choose your items</p>
            <p class="step_description">Pick a menu and add your items to the basket</p>
          </div>
          <div class="step">
            <img src="../../assets/gtm/dashboard/proceed.png" alt="" />
            <p class="step_title">Proceed to checkout</p>
            <p class="step_description">Pay for your order and we'll handle the rest</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { watch } from 'vue';
import { Options, Vue } from 'vue-class-component';

import Logo from '@/shared/icons/Logo.vue';

@Options({
  components: {
    Logo,
  },
  props: {
    chatWithUs: Function,
  },
})
export default class GtmStep11 extends Vue {
  private addEvent() {
    this.$store.commit('modals/reset');
    this.$store.commit('modals/show', 'gtmNewEvent');
  }

  private bookDemoCall() {
    window.location.href = 'https://calendly.com/toby-fg4';
  }

  created() {
    watch(
      () => this.$store.state.modals.gtmNewEvent,
      () => {
        // Move to step 3 after clicking 'Add another event' and choosing criteria settings:
        const isModalOpen = this.$store.state.modals.gtmNewEvent;
        const modalData = this.$store.state.modals.data;
        if (!isModalOpen && modalData?.gtmNew !== null && modalData?.gtmNew !== undefined) {
          if (modalData.gtmNew) this.$store.commit('gtm/clear');
          else this.$store.commit('gtm/setIsUsingExistingDetails', true);
          this.$store.commit('gtm/setBookedSlotId', null);
          this.$store.commit('gtm/setStep', 3);
        }
      },
    );
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: white;

  .header {
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    @include for-smartphone {
      padding: 10px;
    }

    .logo {
      margin-right: auto;

      @include for-desktop {
        transform: scale(1.4);
      }
    }

    button {
      border: 1px solid rgba(0, 0, 0, 0.2);
      height: 40px;
      padding: 0;

      img {
        height: 30px;
        margin-right: 10px;
      }

      p {
        font-size: 17px;
        height: 17px;
      }

      @include for-smartphone {
        img {
          height: 25px;
        }

        p {
          font-size: 15px;
        }
      }
    }

    .chat_with_us {
      width: 160px;

      @include for-smartphone {
        width: 140px;
      }
    }

    .add {
      width: 185px;

      @include for-smartphone {
        width: 155px;
      }
    }
  }

  .upper_part {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px;
    gap: 70px;
    min-height: calc(50vh - 41px);

    @include for-smartphone {
      flex-direction: column;
      gap: 30px;
      min-height: unset;
    }

    @include for-desktop {
      @media (max-height: 640px) {
        min-height: unset;
      }
    }

    .texts {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: 640px;

      h1 {
        font-size: 32px;
        line-height: 32px;

        @include for-smartphone {
          font-size: 22px;
          line-height: 22px;
        }
      }

      p {
        @include p1;
        font-size: 19px;
        text-align: justify;

        @include for-smartphone {
          font-size: 16px;
          line-height: 20px;
        }
      }

      strong {
        font-size: 19px;
        @include for-smartphone {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    .demo_call {
      height: 175px;
      width: 300px;
      min-width: 300px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 15px;
      padding: 15px 20px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .demo_call_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;

        img {
          height: 50px;
          width: 50px;
          min-width: 50px;
          border-radius: 30px;
        }

        div {
          display: flex;
          flex-direction: column;
          gap: 2.5px;

          h1 {
            font-size: 28px;
            font-weight: 600;
          }

          p {
            @include p1;
            font-size: 18px;
            opacity: 0.5;
          }
        }
      }

      button {
        height: 45px;
        min-height: 45px;
        font-size: 30px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        margin-bottom: 5px;
      }
    }
  }

  .lower_part {
    background-image: url('../../assets/gtm/dashboard/background.png');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    @include for-desktop {
      @media (min-height: 550px) {
        height: 100%;
      }
    }

    .lower_part_body {
      display: flex;
      flex-direction: column;
      width: fit-content;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    h1 {
      font-size: 25px;
      margin-bottom: 10px;

      @include for-smartphone {
        font-size: 20px;
      }
    }

    .steps {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 30px;

      @include for-smartphone {
        flex-direction: column;
        gap: 20px;
      }

      .step {
        display: flex;
        flex-direction: column;
        background-color: white;
        width: 300px;
        padding: 25px;
        border-radius: 15px;

        @include for-smartphone {
          padding: 15px;
        }

        img {
          height: 30px;
          width: 30px;
          margin-bottom: 15px;
        }

        .step_title {
          @include p1;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 5px;

          @include for-smartphone {
            font-size: 18px;
          }
        }

        .step_description {
          @include p1;
          opacity: 0.5;
          width: 80%;

          @include for-smartphone {
            font-size: 14px;
            line-height: 16px;
            width: unset;
          }
        }
      }
    }
  }
}
</style>
