import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "restaurant-menu-body" }
const _hoisted_3 = { class: "restaurant-menu-title" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "restaurant-menu-list"
}
const _hoisted_8 = {
  key: 2,
  class: "restaurant-menu-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RestaurantItem = _resolveComponent("RestaurantItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["restaurant-menu-wrapper", { closed: _ctx.menu.isClosed }])
  }, [
    (_ctx.restaurant.isCaterer && _ctx.menu.photo)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "restaurant-menu-image hide-mobile",
          src: _ctx.menu.photo,
          alt: ""
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.menu.name), 1),
        (!_ctx.isDeliveryLateEnough && !_ctx.isTooFar)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.menu.minHoursInAdvance) + " " + _toDisplayString(_ctx.menu.minHoursInAdvance === 1 ? 'hour' : 'hours') + " notice required to order ", 1))
          : (_ctx.menu.isClosed)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.menu.availability), 1))
            : _createCommentVNode("", true)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "divider ham full-width" }, null, -1)),
      (_ctx.restaurant.isCaterer && _ctx.menu.photo)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "restaurant-menu-image hide-desktop",
            src: _ctx.menu.photo,
            alt: ""
          }, null, 8, _hoisted_6))
        : _createCommentVNode("", true),
      (_ctx.dishes)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dishes, (dish, dIndex) => {
              return (_openBlock(), _createBlock(_component_RestaurantItem, {
                dish: dish,
                key: dIndex,
                count: _ctx.getCountDish(dish.id),
                isCaterer: _ctx.restaurant.isCaterer,
                disabled: _ctx.menu.isClosed || (!_ctx.isDeliveryLateEnough && !_ctx.isTooFar),
                categoryMinHoursInAdvance: _ctx.menu.minHoursInAdvance,
                onClick: _withModifiers(($event: any) => (_ctx.showDishes(dish)), ["prevent"])
              }, null, 8, ["dish", "count", "isCaterer", "disabled", "categoryMinHoursInAdvance", "onClick"]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.dishesQuantity, (index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "restaurant-item-placeholder",
                key: index
              }))
            }), 128))
          ]))
    ])
  ], 2))
}