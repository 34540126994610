import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cookies" }
const _hoisted_2 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "title_cookies" }, " We use cookies to provide a tastier web experience. You can change your settings or proceed. ", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_button, {
        onClick: _ctx.accessCookies,
        slim: "",
        class: "mr_10"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("span", null, "Ok", -1)
        ])),
        _: 1
      }, 8, ["onClick"]),
      _cache[1] || (_cache[1] = _createElementVNode("a", { href: "/boring-stuff/cookie-policy" }, "Tell me more", -1))
    ])
  ]))
}