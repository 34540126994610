import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "icon-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconArrow = _resolveComponent("IconArrow")!

  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.goTo()), ["prevent"])),
    class: "restaurant-btn-back td-n"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_IconArrow, { width: "21" })
    ]),
    (!_ctx.mini)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.bold ? 'bold' : '')
        }, _toDisplayString(_ctx.title), 3))
      : _createCommentVNode("", true)
  ]))
}