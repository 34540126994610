<template>
  <app-modal class="about-modal" v-bind="options">
    <app-button class="about-modal-close" mini white @click.prevent="close">
      <IconServices class="services-icon" icon="add" close />
    </app-button>

    <img :src="data.cover" class="about-modal-cover" alt="restaurant cover" />

    <div class="about-modal-wrapper">
      <p class="about-modal-info">
        <IconServices icon="location" />
        <a :href="data.googleMapsUrl" target="_blank" rel="noopener">
          {{ data.location }},
          {{ data.nearestStation }}
          {{ data.postcode }}
        </a>
      </p>
      <h1>Our story</h1>
      <div class="divider ham full-width" />
      <p v-html="data.description" />
    </div>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';
import IconServices from '@/shared/icons/IconServices.vue';

@Options({
  components: { IconServices },
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class ModalAbout extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: false,
  };

  private close() {
    document.body.classList.remove('no-scroll');
    this.$store.commit('modals/close');
  }

  created() {
    document.body.classList.add('no-scroll');
  }
}
</script>

<style lang="scss">
.about-modal {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &.modal-wrapper {
    padding-bottom: 0;
    @include for-custom-min(501) {
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
    @include for-old-mobile {
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      padding: 0 !important;
    }
    > .modal {
      @include for-old-mobile {
        min-height: 100% !important;
      }
    }
  }

  .modal {
    max-height: 85%;
    padding: 0;
    padding-bottom: 60px;
    animation: $modal-show-bottom;
    &.slim {
      max-width: 375px;
      width: 375px;
      @include for-old-mobile {
        width: 100%;
        min-width: 100%;
      }
    }
  }

  &-wrapper {
    padding: 0 15px;
  }

  &-close {
    position: absolute;
    top: 15px;
    left: 15px;
    .close.services-icon {
      top: 9px;
      left: 9px;
      width: 21px;
      height: 21px;
    }
  }
  &-cover {
    height: 375px;
    min-height: 375px;
    width: 375px;
    min-width: 375px;
    border-radius: 15px;
    @include for-old-mobile {
      width: 100%;
      min-width: 100%;
    }
  }

  .divider {
    margin: 5px 0;
  }

  &-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 15px 0;
    svg {
      width: 18px;
      margin-right: 8px;
    }
  }
}
</style>
