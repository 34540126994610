import AppBackLink from '@/shared/controllers/app-back-link.vue';
import AppButton from '@/shared/controllers/app-button.vue';
import AppCount from '@/shared/controllers/app-count.vue';
import AppInput from '@/shared/controllers/app-input.vue';
import AppModal from '@/shared/controllers/app-modal.vue';
import AppOption from '@/shared/controllers/app-option.vue';
import AppSelect from '@/shared/controllers/app-select.vue';
import AppTextAccordion from '@/shared/controllers/app-text-accordion.vue';
import AppTextarea from '@/shared/controllers/app-textarea.vue';

function init(app: any) {
  app.component('app-input', AppInput);
  app.component('app-button', AppButton);
  app.component('app-modal', AppModal);
  app.component('app-count', AppCount);
  app.component('app-option', AppOption);
  app.component('app-textarea', AppTextarea);
  app.component('app-text-accordion', AppTextAccordion);
  app.component('app-back-link', AppBackLink);
  app.component('app-select', AppSelect);
}

export default init;
