<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 188.5 7.8">
    <path
      d="M 188 4.4 V 4.4 L 184.9 0.8 l -3.1 3.6 L 178.6 0.8 l -3.1 3.6 L 172.4 0.8 l -3.2 3.6 L 166.1 0.8 L 163 4.4 L 159.9 0.8 l -3.2 3.6 L 153.6 0.8 l -3.1 3.6 L 147.4 0.8 l -3.1 3.6 L 141.1 0.8 L 138 4.4 L 134.9 0.8 l -3.2 3.6 L 128.6 0.8 l -3.1 3.6 L 122.4 0.8 l -3.2 3.6 L 116.1 0.8 L 113 4.4 L 109.9 0.8 l -3.2 3.6 L 103.6 0.8 l -3.1 3.6 L 97.4 0.8 L 94.2 4.4 L 91.1 0.8 L 88 4.4 L 84.9 0.8 L 81.7 4.4 L 78.6 0.8 L 75.5 4.4 L 72.4 0.8 L 69.2 4.4 L 66.1 0.8 L 63 4.4 L 59.9 0.8 L 56.7 4.4 L 53.6 0.8 L 50.5 4.4 L 47.4 0.8 L 44.2 4.4 L 41.1 0.8 L 38 4.4 L 34.9 0.8 L 31.7 4.4 L 28.6 0.8 L 25.5 4.4 L 22.4 0.8 L 19.3 4.4 L 16.1 0.8 L 13 4.4 L 9.9 0.8 L 6.7 4.4 L 3.6 0.8 L 0.5 4.4"
      fill="none"
      stroke="#d3d3d3"
    />
  </svg>
  <!-- eslint-enable -->
</template>
