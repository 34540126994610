import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/gtm/icon_left.png'
import _imports_1 from '../../assets/gtm/icon_right.png'


const _hoisted_1 = { class: "calendar_wrapper" }
const _hoisted_2 = { class: "calendar_row" }
const _hoisted_3 = { class: "day_number" }
const _hoisted_4 = { class: "month_name" }
const _hoisted_5 = { class: "mobile_navigation_row hide_desktop" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "navigation_button custom-cursor-hover hide_mobile",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.decrease && _ctx.decrease(...args)))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("img", {
          class: "left",
          src: _imports_0,
          alt: ""
        }, null, -1)
      ])),
      _createVNode(_component_VueDatePicker, {
        class: "calendarV2",
        ref: "ref",
        "model-value": _ctx.dateTime,
        "onUpdate:modelValue": _ctx.setDateTime,
        "enable-time-picker": false,
        "min-date": _ctx.minDateTime,
        "month-change-on-scroll": false,
        calendar: _ctx.daysToDisplay,
        "day-names": ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        "week-start": 0,
        "disable-month-year-select": "",
        "auto-apply": "",
        inline: ""
      }, {
        day: _withCtx(({ day, date }) => [
          _createElementVNode("p", _hoisted_3, _toDisplayString(day), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.getMonth(date)), 1)
        ]),
        _: 1
      }, 8, ["model-value", "onUpdate:modelValue", "min-date", "calendar"]),
      _createElementVNode("div", {
        class: "navigation_button custom-cursor-hover hide_mobile",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.increase && _ctx.increase(...args)))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("img", {
          class: "right",
          src: _imports_1,
          alt: ""
        }, null, -1)
      ]))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: "navigation_button custom-cursor-hover",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.decrease && _ctx.decrease(...args)))
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("img", {
          class: "left",
          src: _imports_0,
          alt: ""
        }, null, -1)
      ])),
      _createElementVNode("div", {
        class: "navigation_button custom-cursor-hover",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.increase && _ctx.increase(...args)))
      }, _cache[7] || (_cache[7] = [
        _createElementVNode("img", {
          class: "right",
          src: _imports_1,
          alt: ""
        }, null, -1)
      ]))
    ])
  ]))
}