<template>
  <app-modal class="login-modal" v-bind="options">
    <h2>Log in</h2>
    <div class="wrapper">
      <app-input id="email" v-model:value="email" :showError="show">
        <template v-slot:label>Email address</template>
        <template v-slot:error v-if="yupErrMsg('email')">
          {{ yupErrMsg('email') }}
        </template>
      </app-input>
      <app-input id="password" type="password" @keypress.enter="login" v-model:value="password" :showError="show">
        <template v-slot:label>Password</template>
        <template v-slot:error v-if="yupErrMsg('password')">
          {{ yupErrMsg('password') }}
        </template>
      </app-input>
      <u class="forgot-password custom-cursor-hover" @click="forgotPassword">Forgot password?</u>
      <app-button class="login-button" :loader="loader" @click.prevent="login">Log in</app-button>
      <p class="no-account">
        Don't have an account?
        <u class="custom-cursor-hover" @click="register">Register</u>
      </p>
    </div>
  </app-modal>
</template>

<script lang="ts">
import { FirebaseError } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import * as yup from 'yup';

import account, { Login } from '@/requests/account';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

import methods from '@/utils/methods';

@Options({
  computed: {
    ...mapState('yup', ['show', 'errors']),
  },
})
export default class ModalLogin extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: true,
  };

  private email = '';

  private password = '';

  private loader = false;

  private auth = getAuth();

  private schema = yup.object({
    email: yup.string().required().email(),
    password: yup.string().required('No password provided.'),
  });

  private login() {
    const authData = {
      email: this.email,
      password: this.password,
    };

    this.schema
      .validate(authData, { abortEarly: false })
      .then(async (auth: any) => {
        this.loader = true;
        await this.loginWithPassword(auth);
        this.loader = false;
      })
      .catch((err) => {
        this.$store.commit('yup/clear');
        this.$store.commit('yup/errors', { path: 'password', msg: err });
        this.$store.commit('yup/toggleShowError', true);
        this.loader = false;
      });
  }

  private async loginWithPassword(auth: Login) {
    try {
      const userCredential = await signInWithEmailAndPassword(this.auth, auth.email, auth.password);

      if (!(await account.userExistsInFirestore(userCredential.user.uid))) {
        await account.saveUserToFirestore();
      }

      // NOTE: Firebase listeners defined in App.vue will automatically update local storage after signing in.

      this.$store.commit('modals/close');
    } catch (err) {
      console.error(err);
      if (err instanceof FirebaseError && (err.code === 'auth/wrong-password' || err.code === 'auth/user-not-found')) {
        this.$store.commit('yup/clear');
        this.$store.commit('yup/errors', { path: 'password', msg: 'Incorrect password.' });
        this.$store.commit('yup/toggleShowError', true);
      }
    }
  }

  private yupErrMsg(path: string) {
    return methods.yupErrMsg(path);
  }

  private register() {
    this.$store.commit('modals/close');
    this.$store.commit('modals/show', 'createAccount');
  }

  private forgotPassword() {
    this.$store.commit('modals/close');
    this.$store.commit('modals/show', 'forgotPassword');
  }

  created() {
    this.$store.commit('yup/toggleShowError', false);
    this.$store.commit('yup/clear');
  }
}
</script>

<style lang="scss" scoped>
.login-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 315px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

h2 {
  text-align: center;
  margin-bottom: 15px;
}

.controller-input-wrapper {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 15px;
  }
}

.forgot-password {
  @include p3;
  color: $coal80;
  margin-left: auto;
  margin-bottom: 30px;
}

.no-account {
  @include p2;
  margin: 15px 0;
  color: $coal100;
  text-align: center;

  u {
    @include p2;
    margin-left: 5px;
  }
}
</style>
