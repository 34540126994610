<template>
  <footer class="global-footer">
    <div class="mobile-ticker">
      <WeSupportLocal :color="'ham'" class="sticker_mobile" />
    </div>
    <div class="footer-info">
      <div class="row">
        <router-link to="/" aria-label="homepage">
          <Logo class="long_logo" text />
        </router-link>
        <!-- <ul class="download-desktop">
          <li class="mb-4">
            <small class="ws-nw footer-title">Download the app</small>
          </li>
          <div class="stores_container">
            <a
              class="store_link"
              href="https://play.google.com/store/apps/details?id=com.foodstuff.foodstuff_app"
              target="_blank"
              rel="noopener"
            >
              <img src="../assets/landing/play_store_badge.png" class="badge" alt="play store badge" />
            </a>
            <a
              href="https://apps.apple.com/us/app/foodstuff/id1591745985"
              target="_blank"
              class="store_link"
              rel="noopener"
            >
              <img src="../assets/landing/app_store_badge.png" class="badge" alt="app store badge" />
            </a>
          </div>
        </ul> -->
      </div>
      <div class="row">
        <ul class="mr-60">
          <li class="mb-4">
            <small class="ws-nw footer-title">Services</small>
          </li>
          <li class="mb-2">
            <router-link class="ws-nw td-n" to="/cambridge/corporate-lunch-delivery">
              Corporate Lunch Delivery in Cambridge
            </router-link>
          </li>
          <li class="mb-2">
            <router-link class="ws-nw td-n" to="/bristol/corporate-lunch-delivery">
              Corporate Lunch Delivery in Bristol
            </router-link>
          </li>
          <li class="mb-2">
            <router-link class="ws-nw td-n" to="/cambridge/corporate-breakfast-delivery">
              Corporate Breakfast Delivery in Cambridge
            </router-link>
          </li>
          <li class="mb-2">
            <router-link class="ws-nw td-n" to="/bristol/corporate-breakfast-delivery">
              Corporate Breakfast Delivery in Bristol
            </router-link>
          </li>
          <li class="mb-2">
            <router-link class="ws-nw td-n" to="/cambridge/corporate-event-catering">
              Corporate Event Catering in Cambridge
            </router-link>
          </li>
          <li class="mb-2">
            <router-link class="ws-nw td-n" to="/bristol/corporate-event-catering">
              Corporate Event Catering in Bristol
            </router-link>
          </li>
          <li class="mb-2">
            <router-link class="ws-nw td-n" to="/cambridge/office-catering">Office Catering in Cambridge</router-link>
          </li>
          <li class="mb-2">
            <router-link class="ws-nw td-n" to="/bristol/office-catering">Office Catering in Bristol</router-link>
          </li>
          <li class="mb-2">
            <router-link class="ws-nw td-n" to="/bespoke-events">Bespoke Events in Cambridge and Bristol</router-link>
          </li>
        </ul>
        <ul class="mr-60">
          <li class="mb-4">
            <small class="ws-nw td-n footer-title">Contact us</small>
          </li>
          <li class="mb-2">
            <a class="ws-nw td-n" target="_blank" :href="other.social.instagram" rel="noopener">Instagram</a>
          </li>
          <li class="mb-2">
            <a class="ws-nw td-n" target="_blank" :href="other.social.facebook" rel="noopener">Facebook</a>
          </li>
        </ul>
        <ul class="vendor-stuff mr-60">
          <li class="mb-4">
            <small class="ws-nw footer-title">Business stuff</small>
          </li>
          <li class="mb-2">
            <a class="ws-nw td-n" target="_blank" :href="other.vendor.login" rel="noopener">Vendor log in</a>
          </li>
          <li class="mb-2">
            <router-link class="ws-nw td-n" to="/vendor">List your restaurant</router-link>
          </li>
        </ul>
        <ul class="boring-stuff">
          <li class="mb-4">
            <small class="ws-nw footer-title">Boring stuff</small>
          </li>
          <li class="mb-2">
            <router-link class="ws-nw td-n" :to="`${baseUrl}/privacy-policy`"> Privacy policy </router-link>
          </li>
          <li class="mb-2">
            <router-link class="ws-nw td-n" :to="`${baseUrl}/terms-of-service`"> Terms of service</router-link>
          </li>
          <li class="mb-2">
            <router-link class="ws-nw td-n" :to="`${baseUrl}/cookie-policy`"> Cookie policy </router-link>
          </li>
          <!-- <li>
            <ul class="download">
              <li class="mb-4">
                <small class="ws-nw footer-title">Download the app</small>
              </li>
              <li>
                <div class="stores_container">
                  <a
                    class="store_link"
                    href="https://play.google.com/store/apps/details?id=com.foodstuff.foodstuff_app"
                    target="_blank"
                    rel="noopener"
                  >
                    <img src="../assets/landing/play_store_badge.png" class="badge" alt="play store badge" />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/foodstuff/id1591745985"
                    target="_blank"
                    class="store_link"
                    rel="noopener"
                  >
                    <img src="../assets/landing/app_store_badge.png" class="badge" alt="app store badge" />
                  </a>
                </div>
              </li>
            </ul>
          </li> -->
        </ul>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import other, { Other } from '@/other';

import Logo from '@/shared/icons/Logo.vue';
import WeSupportLocal from '@/shared/icons/WeSupportLocal.vue';

@Options({
  computed: { ...mapState('address', ['cityName']) },
  components: {
    Logo,
    WeSupportLocal,
  },
})
export default class Footer extends Vue {
  private other: Other = other;

  get dividerColor() {
    switch (this.$route.name) {
      case 'account':
      case 'my-account':
      case 'my-orders':
      case 'reset-password':
      case 'Reset-password':
      case 'landing':
      case 'vendor':
      case 'business':
        return 'yolk';
      default:
        return 'grape';
    }
  }

  get baseUrl() {
    return '/boring-stuff';
  }
}
</script>

<style lang="scss">
footer {
  margin-top: 60px;
  margin-bottom: 85px;
  @include content-grid;

  @media (max-width: 1024px) {
    margin: 10px 0 0;
  }

  .divider {
    margin-bottom: 20px;
  }

  .footer {
    &-info {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: 1024px) {
        justify-content: flex-start;
        margin-bottom: 80px;

        .logo {
          display: none;
        }

        .row {
          margin-top: 60px;
        }

        .download-desktop {
          display: none;
        }
      }

      .download-desktop {
        margin-left: 40px;
      }

      .logo {
        margin-bottom: 30px;
      }

      a {
        color: $coal100;
        font-size: 13px;
        line-height: 18px;
        font-family:
          Sofia Pro,
          sans-serif;
        font-weight: 200;
        margin-bottom: 4px;
      }
    }
    &-title {
      font-family:
        Sofia Pro,
        sans-serif;
      color: $coal80 !important;
      font-weight: 600 !important;
      margin-bottom: 10px;
    }
  }
  .row {
    display: flex;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .mr-60 {
    margin-right: 60px;

    @media (max-width: 1024px) {
      margin: 0 0 20px 0;
    }
  }

  .mb-4 {
    margin-bottom: 4px;
  }
  .mb-2 {
    margin-bottom: 2px;
  }

  .download {
    display: none;
    margin-top: 20px;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
    }
  }

  .footer-line {
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .mobile-ticker {
    position: relative;
    height: 0;

    @media (min-width: 1025px) {
      display: none;
    }
  }

  .sticker_mobile {
    position: absolute;
    z-index: 50;
    bottom: -35px;
    transform: rotate(-11deg) !important;
  }

  .stores_container {
    display: flex;
    width: 100%;
    .badge {
      max-height: 32px;
      margin-right: 6px;
    }

    @media (min-width: 1025px) {
      flex-direction: column;
      .badge {
        max-height: 36px;
      }
    }
  }
}
</style>
