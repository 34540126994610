import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "group-order-not-available__buttons-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, {
    class: "group-order-not-available",
    close: true
  }, {
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "group-order-not-available__title" }, "You used an invalid link or this group order is not active anymore", -1)),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_app_button, {
          class: "group-order-not-available__confirm-button",
          onClick: _withModifiers(_ctx.closeModal, ["prevent"])
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Okay")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }))
}