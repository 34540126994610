/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state: StateGTM = {
  step: 0,

  email: '',
  phoneNumber: '',
  foodSuppliers: [],
  happyToContactSupplier: false,
  supplierCuisines: [],
  supplierMoments: [],

  dateTime: null,
  selectedSlot: '',
  address: null,
  headcount: 0,
  budget: 0,
  typesOfFood: [],
  otherTypesOfFood: '',
  typesOfPresentationStyles: [],
  typesOfAllergens: [],
  otherTypesOfAllergens: '',

  bookedSlotId: null,
  isUsingExistingDetails: false,

  paidBookedSlotsAmount: 0,
  areTransitionsDisabled: false,
};

const mutations = {
  setStep(state: StateGTM, step: number) {
    state.step = step;
  },
  setEmail(state: StateGTM, email: string) {
    state.email = email;
  },
  setPhoneNumber(state: StateGTM, phoneNumber: string) {
    state.phoneNumber = phoneNumber;
  },
  setFoodSuppliers(state: StateGTM, foodSuppliers: string[]) {
    state.foodSuppliers = foodSuppliers;
  },
  setHappyToContactSupplier(state: StateGTM, happyToContactSupplier: boolean) {
    state.happyToContactSupplier = happyToContactSupplier;
  },
  setSupplierCuisines(state: StateGTM, supplierCuisines: string[]) {
    state.supplierCuisines = supplierCuisines;
  },
  setSupplierMoments(state: StateGTM, supplierMoments: string[]) {
    state.supplierMoments = supplierMoments;
  },
  setDateTime(state: StateGTM, dateTime: string) {
    state.dateTime = dateTime;
  },
  setSlot(state: StateGTM, slot: string) {
    state.selectedSlot = slot;
  },
  setAddress(state: StateGTM, address: any) {
    state.address = address;
  },
  setHeadCount(state: StateGTM, headcount: number) {
    state.headcount = headcount;
  },
  setBudget(state: StateGTM, budget: number) {
    state.budget = budget;
  },
  setTypesOfFood(state: StateGTM, typesOfFood: string[]) {
    state.typesOfFood = typesOfFood;
  },
  setOtherTypesOfFood(state: StateGTM, otherTypesOfFood: string) {
    state.otherTypesOfFood = otherTypesOfFood;
  },
  setTypesOfPresentationStyles(state: StateGTM, typesOfPresentationStyles: string[]) {
    state.typesOfPresentationStyles = typesOfPresentationStyles;
  },
  setTypesOfAllergens(state: StateGTM, typesOfAllergens: string[]) {
    state.typesOfAllergens = typesOfAllergens;
  },
  setOtherTypesOfAllergens(state: StateGTM, otherTypesOfAllergens: string) {
    state.otherTypesOfAllergens = otherTypesOfAllergens;
  },
  setBookedSlotId(state: StateGTM, bookedSlotId: number | null) {
    state.bookedSlotId = bookedSlotId;
  },
  setIsUsingExistingDetails(state: StateGTM, isUsingExistingDetails: boolean) {
    state.isUsingExistingDetails = isUsingExistingDetails;
  },
  setPaidBookedSlotsAmount(state: StateGTM, paidBookedSlotsAmount: number) {
    state.paidBookedSlotsAmount = paidBookedSlotsAmount;
  },
  disableTransitions(state: StateGTM) {
    state.areTransitionsDisabled = true;
  },
  clear(state: StateGTM) {
    if (state.step >= 3) {
      state.step = 2.5;
      state.dateTime = null;
      state.selectedSlot = '';
      state.address = null;
      state.headcount = 0;
      state.budget = 0;
      state.typesOfFood = [];
      state.otherTypesOfFood = '';
      state.typesOfPresentationStyles = [];
      state.typesOfAllergens = [];
      state.otherTypesOfAllergens = '';
    } else {
      state.step = 0;
      state.email = '';
      state.phoneNumber = '';
      state.foodSuppliers = [];
      state.happyToContactSupplier = false;
      state.supplierCuisines = [];
      state.supplierMoments = [];
    }

    state.bookedSlotId = null;
    state.isUsingExistingDetails = false;
  },
};

const actions = {};

const getters = {};

export interface StateGTM {
  step: number;

  email: string;
  phoneNumber: string;
  foodSuppliers: string[];
  happyToContactSupplier: boolean;
  supplierCuisines: string[];
  supplierMoments: string[];

  dateTime: string | null;
  selectedSlot: string;
  address: any;
  headcount: number;
  budget: number;
  typesOfFood: string[];
  otherTypesOfFood: string;
  typesOfPresentationStyles: string[];
  typesOfAllergens: string[];
  otherTypesOfAllergens: string;

  bookedSlotId: number | null;
  isUsingExistingDetails: boolean;

  paidBookedSlotsAmount: number;
  areTransitionsDisabled: boolean;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
