<template>
  <nav class="global-border-bottom global-navbar">
    <div class="nav-wrapper">
      <!-- Foodstuff logo with redirection to landing page -->
      <router-link to="/" name="homepage" aria-label="homepage">
        <Logo v-if="logoTextHidden" />
        <Logo text v-else />
      </router-link>

      <div class="nav-buttons">
        <!-- DESKTOP: Redirection to Vendor page -->
        <app-button v-if="!user && !isMobileView" class="work-with-us-btn" @click="goToVendorPage">
          <p>Work with us</p>
        </app-button>

        <!-- DESKTOP: Chat with support -->
        <app-button v-if="!isMobileView" class="chat-support-btn" @click="openFreshchat">
          <p>Chat to live support</p>
        </app-button>

        <!-- Redirection to Tracking Page -->
        <ButtonOrderTracker />

        <!-- Redirection to Restaurant Details Page / Basket Page -->
        <router-link :to="goTo()" custom v-slot="{ navigate }">
          <app-button v-if="dishes.length > 0 && user" @click="navigate" mini square white>
            <b>{{ numberOfBasketItems(dishes) }}</b>
          </app-button>
        </router-link>

        <!-- MOBILE: Chat with support -->
        <app-button v-if="isMobileView && user" @click="openFreshchat" mini square white>
          <img src="../assets/order_tracking/message.png" alt="" />
        </app-button>

        <!-- Redirection to /my-account and auth options -->
        <AuthButtons :isMobileView="isMobileView" />

        <!-- MOBILE: Redirection to Vendor page + chat with support -->
        <div v-if="!user && isMobileView" class="mobile-nav-partner" v-click-outside="() => (dropdownPartner = false)">
          <app-button
            class="work-with-us-btn-mobile"
            mini
            square
            white
            @click="dropdownPartner = !dropdownPartner"
            aria-label="dropdown"
          >
            <IconService icon="hamburger" />
          </app-button>

          <NavigationDropdown class="partner-dropdown" v-if="dropdownPartner">
            <PartnerDropdown @push="push" isMobileView />
          </NavigationDropdown>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import AuthButtons from '@/components/Navigation/AuthButtons.vue';
import ButtonOrderTracker from '@/components/Navigation/ButtonOrderTracker.vue';
import NavigationDropdown from '@/components/Navigation/dropdown.vue';
import PartnerDropdown from '@/components/Navigation/partner-dropdown.vue';

import IconArrow from '@/shared/icons/IconArrow.vue';
import IconService from '@/shared/icons/IconServices.vue';
import Logo from '@/shared/icons/Logo.vue';

@Options({
  components: {
    AuthButtons,
    Logo,
    IconService,
    IconArrow,
    ButtonOrderTracker,
    NavigationDropdown,
    PartnerDropdown,
  },
  computed: {
    ...mapState('basket', ['dishes']),
    ...mapState('service', ['user']),
  },
})
export default class NavigationBar extends Vue {
  private isMobileView = false;
  private logoTextHidden = false;
  private dropdownPartner = false;

  private numberOfBasketItems(dishes: OrderDish[]) {
    return dishes.reduce((sum, dish) => sum + dish.amount, 0);
  }

  private goTo() {
    const { basket } = this.$store.state;
    if (!basket.restaurant || !basket.restaurant.slug) return '/restaurants';

    const { slug, restaurantCity } = basket.restaurant;
    return `/menu/${restaurantCity.toLowerCase()}/${slug}`;
  }

  private goToVendorPage() {
    this.$router.push('/vendor');
  }

  private openFreshchat() {
    window.fcWidget?.setTags(['client-bot']);
    window.fcWidget?.open();
  }

  private push() {
    this.dropdownPartner = false;
  }

  private updateWidth() {
    this.isMobileView = window.innerWidth <= 1024;
    this.logoTextHidden = window.innerWidth <= 450;
  }

  mounted() {
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
  }
}
</script>

<style lang="scss">
nav {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  width: 100%;
  background-color: $ham;

  .nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include content-grid;

    @include for-smartphone {
      overflow: hidden;
    }
  }

  .nav-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .mobile-nav-partner {
    height: 40px;
    margin-right: 0px;
    position: static;

    .work-with-us-btn-mobile {
      height: 40px;
      svg {
        height: 24px;
      }
    }
  }

  .work-with-us-btn,
  .chat-support-btn {
    height: 40px;
    min-height: 0px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    padding: 0;

    &:hover {
      background-color: $ham;
    }

    p,
    strong {
      font-family:
        Sofia Pro,
        sans-serif;
      font-size: 14px;
      height: 14px;
    }

    svg {
      height: 17px;
      margin-top: 4px;
    }
  }

  .work-with-us-btn {
    width: 110px;
  }

  .chat-support-btn {
    width: 125px;
    text-decoration: underline;
    margin: 0px 12.5px;
  }
}

.unix .work-with-us-btn p {
  height: 16px;
}
</style>
