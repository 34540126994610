<template>
  <div class="controller-input-wrapper" :class="{ 'full-width': fullWidth }">
    <label>
      <p v-if="$slots.label && type !== 'radio'"><slot name="label" class="app-input-label" /></p>

      <div class="controller-input" :class="`controller-input--${type}`">
        <div class="controller-input-phone-prefix" v-if="isPhoneNumber">+44</div>
        <div class="controller-input-icon" v-if="$slots.icon">
          <slot name="icon" />
        </div>

        <input
          v-if="!isMultiplyLine"
          @blur="blur"
          @input="$emit('update:value', $event.target.value)"
          @focus="focus"
          :value="value"
          :min="minValue"
          :max="maxValue"
          :class="{
            'add-icon': $slots.icon,
            'add-phone-prefix': isPhoneNumber,
            error: $slots.error && showError,
            'dark-focus': darkFocus,
            vanilla,
          }"
          :id="id"
          :type="type"
          :name="name"
          :placeholder="placeholder"
          :disabled="disabled"
          :maxlength="maxlength"
          :checked="checked"
          :readonly="readonly"
          :required="required"
          ref="input"
        />

        <p v-if="$slots.label && type === 'radio'"><slot name="label" class="app-input-label" /></p>

        <textarea
          v-if="isMultiplyLine"
          @blur="blur"
          @input="$emit('update:value', $event.target.value)"
          :value="value"
          :class="{
            'add-icon': $slots.icon,
            error: $slots.error && showError,
            'dark-focus': darkFocus,
            vanilla,
          }"
          :id="id"
          :type="type"
          :name="name"
          :placeholder="placeholder"
          :disabled="disabled"
          :maxlength="maxlength"
          :rows="2"
        />

        <p v-if="$slots.overlaySlot">
          <b><slot name="overlaySlot" /></b>
        </p>
      </div>
    </label>

    <small v-if="$slots.error && (showError || showErrorWithoutHighlighting)">
      <b><slot name="error" /></b>
    </small>
    <div v-else-if="addErrorPlaceholderSpacing" style="height: 28px" />
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  id = prop<string>({ required: false });
}

@Options({
  props: {
    vanilla: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    minValue: {
      type: [String, Number],
      default: '',
    },
    maxValue: {
      type: [String, Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: String,
      default: '',
    },
    showError: {
      type: Boolean,
      default: false,
    },
    showErrorWithoutHighlighting: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    darkFocus: {
      type: Boolean,
      default: false,
    },
    isMultiplyLine: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    addErrorPlaceholderSpacing: {
      type: Boolean,
      default: false,
    },
    isPhoneNumber: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:value': [String, Number],
    blur: [String, Number],
  },
})
export default class AppControllerInput extends Vue.with(Props) {
  private focus() {
    if (!this.id) return;

    const isError = this.$store.state.yup.errors.some((e) => e.path === this.id);
    if (isError) this.$store.commit('yup/resetPath', this.id);
  }

  private blur() {
    this.$emit('blur');
  }
}
</script>

<style lang="scss">
.controller-input {
  position: relative;

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 315px;

    label {
      width: 100%;
      p {
        @include p2;
        color: $coal80;
        margin-bottom: 6px;
      }
    }

    small {
      color: $error;
      margin-top: 10px;
    }

    &.full-width {
      max-width: none;
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 15px;
    top: 0;
    width: 20px;
    height: 100%;
    .services-icon {
      $icon-size: 20px;
      max-width: $icon-size;
      width: $icon-size;
      min-width: $icon-size;
      max-height: $icon-size;
      height: $icon-size;
      min-height: $icon-size;
    }
  }

  &-phone-prefix {
    position: absolute;
    left: 20px;
    top: 0;
    width: 26.5px;
    height: 100%;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: unset;
    font-family: unset;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input,
  textarea {
    background-color: $white;
    resize: none;
    padding: {
      top: 12px;
      bottom: 12px;
      left: 20px;
      right: 12px;
    }
    width: 100%;
    max-height: 45px;
    min-height: 45px;
    border: 1px solid $coal20;
    border-radius: 10px;
    transition: $trs;

    &.add-icon {
      padding-left: 40px;
      &:focus,
      &:disabled,
      &.error {
        padding-left: 38px;
      }
    }

    &.add-phone-prefix {
      padding-left: 46.5px;
      &:focus,
      &:disabled,
      &.error {
        padding-left: 44.5px;
      }
    }

    &::placeholder {
      font-size: 15px;
      line-height: 20px;
      color: $coal60;
    }

    &:focus,
    &:disabled,
    &.error {
      padding: {
        top: 10px;
        bottom: 10px;
        left: 18px;
        right: 10px;
      }
    }

    &:focus {
      border: 3px solid $ham;
    }

    &.dark-focus:focus {
      border: 2px solid rgba(33, 33, 33, 1);
    }

    &:disabled {
      color: $coal40;
      background-color: rgba($color: $coal100, $alpha: 0.05);
      border: 3px solid $coal20;

      &:hover {
        cursor: not-allowed;
      }

      &::placeholder {
        color: $coal40;
      }
    }

    &.error {
      border: 3px solid $error;
    }

    &.vanilla {
      background-color: $vanilla;
    }
  }

  textarea {
    max-height: 85px;
    overflow: auto;

    @media (max-width: 390px) {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  // Different type of inputs
  &--radio {
    display: flex;
    align-items: center;
    margin: 10px 0;

    input {
      width: 40px;
      min-height: 25px;
      max-height: 25px;
      margin: 0;
      padding: 0;
    }

    p .app-input-label {
      font-size: 1em;
      line-height: normal;
    }

    p {
      margin-bottom: 0 !important;
    }
  }
}
</style>
