<template>
  <div class="vendor">
    <div class="banner-img" />
    <div class="form-container">
      <div class="column form-container--info">
        <h1>We champion the best independent restaurants.</h1>
        <p>
          Foodstuff is tackling the issues independent food brands face in the industry. We aim to level the playing
          field and give delivery a fresh look.
        </p>
        <img v-show="isScrollHintVisible" src="../assets/vendor/why_foodstuff.png" alt="" id="why_foodstuff" />
      </div>
      <div class="sign-up-form column" ref="signUpForm">
        <div v-if="formStep === 0" class="title">
          <h2>Sign up today!</h2>
          <p>One of our city managers will reach out to discuss next steps.</p>
        </div>
        <app-input
          type="text"
          v-model:value="form.restaurantName"
          id="restaurantName"
          placeholder=""
          :showError="show"
          :fullWidth="true"
          :darkFocus="true"
          v-if="formStep === 0"
        >
          <template v-slot:label
            ><p class="app-input-label">What is your restaurant called?<sup>*</sup></p></template
          >
          <template v-slot:error v-if="yupErrMsg('restaurantName')">
            {{ yupErrMsg('restaurantName') }}
          </template>
        </app-input>

        <div v-if="formStep === 0" class="row items-end w-100 form-row">
          <app-input
            class="mr-50"
            type="text"
            v-model:value="form.city"
            id="city"
            placeholder=""
            :showError="show"
            :darkFocus="true"
          >
            <template v-slot:label
              ><p class="app-input-label">What city are you based in?<sup>*</sup></p></template
            >
            <template v-slot:error v-if="yupErrMsg('city')">
              {{ yupErrMsg('city') }}
            </template>
          </app-input>
          <app-input
            type="text"
            v-model:value="form.userName"
            id="userName"
            placeholder=""
            :showError="show"
            :darkFocus="true"
          >
            <template v-slot:label
              ><p class="app-input-label">Whats your first name?<sup>*</sup></p></template
            >
            <template v-slot:error v-if="yupErrMsg('userName')">
              {{ yupErrMsg('userName') }}
            </template>
          </app-input>
        </div>

        <div v-if="formStep === 0" class="row items-end w-100 form-row">
          <app-input
            class="mr-50"
            type="text"
            v-model:value="form.phone"
            id="phone"
            placeholder=""
            :showError="show"
            :darkFocus="true"
          >
            <template v-slot:label
              ><p class="app-input-label">What is your contact number?<sup>*</sup></p></template
            >
            <template v-slot:error v-if="yupErrMsg('phone')">
              {{ yupErrMsg('phone') }}
            </template>
          </app-input>
          <app-input
            type="text"
            v-model:value="form.email"
            id="email"
            placeholder=""
            :showError="show"
            :darkFocus="true"
          >
            <template v-slot:label
              ><p class="app-input-label">What is your email?<sup>*</sup></p></template
            >
            <template v-slot:error v-if="yupErrMsg('email')">
              {{ yupErrMsg('email') }}
            </template>
          </app-input>
        </div>
        <div v-if="formStep === 0" class="accept-terms">
          <label class="container"
            ><p>
              I accept the <a href="/boring-stuff/terms-of-service">Terms and Conditions</a> of Foodstufff LTD and
              confirm receipt of the <a href="/boring-stuff/privacy-policy">Privacy Policy</a>.*
            </p>
            <input type="checkbox" v-model="form.acceptedForm" />
            <span class="checkmark" />
          </label>
          <small>
            <b v-if="yupErrMsg('acceptedForm')">{{ yupErrMsg('acceptedForm') }}</b></small
          >
        </div>
        <div v-if="formStep === 1" class="form-thank-you">
          <h2>Thank You!</h2>
          <p>One of our city managers will reach out to discuss next steps.</p>
        </div>

        <button v-if="formStep === 0" class="submit" @click="submitForm">Submit details</button>
      </div>
    </div>
    <div class="cards-container column">
      <Carousel :settings="settings" :breakpoints="breakpoints">
        <Slide key="transparent_pricing">
          <div class="card" id="transparent_pricing">
            <h3>Fair, transparent pricing</h3>
            <p>
              We believe in pricing that doesn't break the bank, helping restaurants to focus on what they do best. No
              hidden fees.
            </p>
          </div>
        </Slide>
        <Slide key="emission_free_delivery">
          <div class="card" id="emission_free_delivery">
            <h3>Emission-free deliveries</h3>
            <p>
              All of our riders are on bicycles or e-bikes, meaning every delivery is 100% emission free. We save 4kg
              CO₂ with each delivery.
            </p>
          </div>
        </Slide>
        <Slide key="for_indies">
          <div class="card" id="for_indies">
            <h3>A community of foodies</h3>
            <p>
              No large chains or big corporates here. Just a community of like-minded foodies sharing our love of all
              things tasty.
            </p>
          </div>
        </Slide>
      </Carousel>
      <img src="../assets/vendor/swipe_right.png" alt="" id="swipe_right" />
    </div>
    <TickerTape />
    <div class="brands-container column">
      <img class="brands" src="../assets/vendor/restaurants_logos.png" alt="restaurants logos" />
      <img class="brands-mobile" src="../assets/vendor/restaurants_logos_mobile.png" alt="restaurants logos" />
      <p>Trusted by <b>250+</b> of the UK’s best independent restaurants</p>
      <img id="line" src="../assets/vendor/line.png" alt="dotted line" />
    </div>
    <div class="deals">
      <div class="box black-box">
        <h3>Exclusive</h3>
        <div class="line-divider">
          <div class="half-circle half-circle-start" />
          <div class="half-circle half-circle-end" />
        </div>
        <div class="box-content column">
          <div class="column">
            <h2>20%</h2>
            <p>Commission/order*</p>
          </div>
          <app-button class="anchor-btn" @click="goto('signUpForm')"><span class="btn-text">Sign up!</span></app-button>
        </div>
      </div>
      <div class="box white-box">
        <h3>Multi-platform?</h3>
        <div class="line-divider">
          <div class="half-circle half-circle-start" />
          <div class="half-circle half-circle-end" />
        </div>
        <div class="box-content column">
          <div class="column">
            <h2>25%</h2>
            <p>Commission/order*</p>
          </div>
          <app-button class="anchor-btn" @click="goto('signUpForm')"><span class="btn-text">Sign up!</span></app-button>
        </div>
      </div>
    </div>
    <div class="footnote desktop-only">
      <p><sup>*</sup>Taxes apply</p>
      <p />
    </div>
    <img id="line" class="desktop-only" src="../assets/vendor/line.png" alt="dotted line" />
    <div class="reviews column">
      <img src="../assets/vendor/quote.png" alt="quote" class="quote" />
      <h2>Ask our restaurants</h2>
      <Carousel :itemsToShow="1" :wrapAround="true" :autoplay="10000" class="review-carousel">
        <Slide key="review1">
          <div class="review">
            <div class="column">
              <div class="row">
                <img src="../assets/vendor/quote.png" alt="quote" class="mobile-quote" width="55" />
                <h3>Amber, Aurora Kusina</h3>
              </div>
              <p>
                Foodstuff put the little guys (like us) first, we love that they support independents across the city,
                give vendors an eco-conscious option to deliver and offer their riders a living wage.
              </p>
              <p>The riders are super friendly and the team are just a great bunch to work with.</p>
              <app-button class="anchor-btn" @click="goto('signUpForm')"
                ><span class="btn-text">Sign up today!</span></app-button
              >
            </div>
            <img src="../assets/vendor/reviews/amber_aurora_kusina.png" alt="restaurant owner" />
          </div>
        </Slide>
        <Slide key="review2">
          <div class="review">
            <div class="column">
              <div class="row">
                <img src="../assets/vendor/quote.png" alt="quote" class="mobile-quote" width="55" />
                <h3>Kins, Big Nath's</h3>
              </div>
              <p>
                Foodstuff don't just talk about ethics, they do it! With other delivery businesses, you're just a number
                and the commissions are ridiculous.
              </p>
              <p>
                It's nice to actually know the person you're talking to and Foodstuff care about your small business.
              </p>
              <app-button class="anchor-btn" @click="goto('signUpForm')"
                ><span class="btn-text">Sign up today!</span></app-button
              >
            </div>
            <img src="../assets/vendor/reviews/kins_big_naths.png" alt="restaurant owner" />
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
// eslint-disable-next-line import/no-duplicates
import 'vue3-carousel/dist/carousel.css';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import * as yup from 'yup';

import Footer from '@/components/Footer.vue';
import TickerTape from '@/components/TickerTape.vue';

import IconServices from '@/shared/icons/IconServices.vue';

import methods from '@/utils/methods';

@Options({
  components: {
    Footer,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    TickerTape,
    IconServices,
  },
  data: () => ({
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    breakpoints: {
      0: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      1080: {
        itemsToShow: 2,
        snapAlign: 'start',
      },
      1290: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
    },
  }),
  computed: {
    ...mapState('yup', ['show']),
  },
})
export default class Vendor extends Vue {
  private form: any = {
    restaurantName: '',
    city: '',
    userName: '',
    phone: '',
    email: '',
  };

  private formStep = 0;

  private schema = yup.object({
    restaurantName: yup.string().required('No restaurant name provided.'),
    city: yup.string().required('No city name provided.'),
    userName: yup.string().required('No name provided.'),
    phone: yup.string().required('No phone provided.'),
    email: yup.string().required('No email provided.').email(),
  });

  private submitForm() {
    this.schema
      .validate(this.form, { abortEarly: false })
      .then(async () => {
        // Send data to google forms
        // eslint-disable-next-line
        const googleFormURL =
          'https://docs.google.com/forms/u/2/d/e/1FAIpQLScCv30yV1eeN2KK08l5_xbDgT4I7cG0gTnNhdZU3fn0ARyOyQ/formResponse';

        try {
          const params = new URLSearchParams();
          params.append('entry.286069589', this.form.restaurantName);
          params.append('entry.231570810', this.form.city);
          params.append('entry.1308265420', this.form.userName);
          params.append('entry.376295203', this.form.phone);
          params.append('entry.1369140395', this.form.email);

          const config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          };

          // TODO: Move this request to API (you shouldn't send request
          // from web app straight to Google Forms due to CORS policy):
          await axios.post(googleFormURL, params, config);
        } catch (e) {
          console.log(e);
        }
        this.nextStep();
        this.goto('signUpForm');
        this.form = {
          restaurantName: '',
          city: '',
          userName: '',
          phone: '',
          email: '',
        };
        this.$store.commit('yup/toggleShowError', false);
        this.$store.commit('yup/clear');
      })
      .catch((err: { inner: yup.ValidationError[] }) => {
        methods.yupValidCatch(err);
      });
  }

  private isScrollHintVisible = true;

  private scrollHandler() {
    this.isScrollHintVisible = window.scrollY < 240;
  }

  private yupErrMsg(path: string) {
    return methods.yupErrMsg(path);
  }

  private goto(refName: string) {
    const element: any = this.$refs[refName];
    if (element) {
      const top = element.offsetTop;
      window.scrollTo(0, top);
    }
  }

  private nextStep() {
    this.formStep += 1;
  }

  created() {
    this.$store.commit('yup/toggleShowError', false);
    this.$store.commit('yup/clear');
    window.addEventListener('scroll', this.scrollHandler);
  }

  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler);
  }
}
</script>

<style lang="scss" scoped>
.vendor {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  padding-bottom: 90px;
  overflow: hidden;

  @media (max-width: 950px) {
    padding-bottom: 30px;
  }
}

.row {
  display: flex;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.mr-50 {
  margin-right: 50px;

  @media (max-width: 950px) {
    margin-right: 0;
  }
}

.w-100 {
  width: 100%;
}

.banner-img {
  height: 24vw;
  min-height: 190px;
  width: 100%;
  background: url('../assets/vendor/top_banner.png') no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: top;
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 50px;
  min-height: 500px;
  width: 100vw;
  z-index: 2;

  &--info {
    flex-basis: 52%;
    padding: 28px 0px;
    padding-right: 40px;

    h1 {
      font-size: 45px;
      line-height: 60px;
    }

    p {
      font-family:
        Sofia Pro,
        sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-top: 20px;
    }

    #why_foodstuff {
      position: absolute;
      top: 90vh;
      height: 40px;
      object-fit: contain;
      left: -50%;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: 950px) {
        display: none;
      }
    }
  }

  .sign-up-form {
    background-color: $ham;
    height: auto;
    width: 714px;
    border-radius: 20px;
    margin-top: -85px;
    padding: 24px 34px;
    flex-basis: 48%;

    .title {
      margin-bottom: 30px;

      p {
        font-family:
          Sofia Pro,
          sans-serif;
        font-size: 22px;
        font-weight: 300;
        line-height: 20px;
      }
    }

    .form-thank-you {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .accept-terms {
      .container {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        border-radius: 7px;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #fff;
        border: 1px solid rgba(33, 33, 33, 0.4);
      }

      .container:hover input ~ .checkmark {
        background-color: #fff;
      }

      .container input:checked ~ .checkmark {
        background-color: #2196f3;
      }

      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }

      .container input:checked ~ .checkmark:after {
        display: block;
      }

      .container .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      p,
      a {
        font-family:
          Sofia Pro,
          sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: #4d4d4d80;
        margin: 11px 0 41px;
      }

      a {
        text-decoration: underline;
      }

      @media (max-width: 950px) {
        p,
        a {
          font-size: 12px;
          line-height: 15px;
          margin-top: 5px;
          margin-bottom: 24px;
        }
      }
    }

    h2 {
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 7px;
    }

    p,
    label {
      font-family:
        Sofia Pro,
        sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
      color: $coal80;
    }

    input {
      border-radius: 20px;
      font-size: 30px;
    }

    .controller-input-wrapper {
      margin-bottom: 36px;
    }

    .app-input-label {
      font-family:
        Sofia Pro,
        sans-serif;
      font-size: 18px;
      font-weight: 300;
      line-height: 18px;
    }

    .submit {
      font-size: 16px;
      font-weight: 100;
      line-height: 21px;
      text-align: center;
      background-color: $darkBrown;
      color: #ffffff !important;
      margin: auto;
      padding: 18px 120px;
      max-width: 100%;
      border-radius: 12px;
      font-family:
        Sofia Pro,
        sans-serif;
      font-weight: 400;
    }
  }

  @media (max-width: 950px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: 15px;

    &--info {
      padding: 0;
      text-align: center;

      h1 {
        font-size: 30px;
        line-height: 30px;
      }

      p {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
      }
    }

    .sign-up-form {
      margin-top: 0;
      width: 100%;
      padding: 15px 25px;

      .title {
        margin-bottom: 15px;

        h2 {
          font-size: 25px;
          margin-bottom: 5px;
        }
        p {
          font-size: 16px;
        }
      }

      .form-row {
        flex-direction: column;

        .controller-input-wrapper,
        .controller-input {
          width: 100%;
          max-width: 100%;
        }
      }

      .controller-input-wrapper {
        margin-bottom: 20px;
      }

      .app-input-label {
        font-family:
          Sofia Pro,
          sans-serif;
        font-size: 16px;
        font-weight: 300;
        line-height: 18px;
      }

      h2 {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 7px;
      }

      p,
      label {
        font-size: 14px;
        line-height: 20px;
      }

      .submit {
        margin: 0 auto 14px;
        padding: 18px 0;
        width: 80%;
        font-size: 14px;
      }
    }
  }
}

#line {
  width: 100%;
  max-width: 1120px;
  height: 1px;
  margin: 40px auto;

  @media (max-width: 950px) {
    width: 30%;
    margin: 20px auto;
  }
}

.player-mobile {
  display: none;

  @media (max-width: 450px) {
    display: block;
  }
}

.player-tablet {
  display: none;

  @media (min-width: 451px) and (max-width: 850px) {
    display: block;
  }
}

.player-desktop {
  display: none;

  @media (min-width: 851px) {
    display: block;
  }
}

.carousel__slide > .carousel__track {
  gap: 20px;
}

.cards-container {
  background: url('../assets/vendor/cards_background.png');
  background-position: top;
  margin-top: -50px;
  width: 100%;
  padding: 100px 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: top;
    max-height: 520px;
    height: 520px;
    max-width: 400px;
    width: 400px;
    text-align: left;

    padding: 0px 30px 0px 20px;
    margin: 0px 15px;

    h3 {
      font-family: Recoleta, sans-serif;
      margin: 300px 0px 20px 0px;
      font-weight: 400;
      font-size: 25px;
    }

    p {
      font-size: 20px;
      text-align: left;
      line-height: 30px;
      font-family:
        Sofia Pro,
        sans-serif;
      font-weight: 400;
    }
  }

  #transparent_pricing {
    background-image: url('../assets/vendor/transparent_pricing.png');
  }

  #emission_free_delivery {
    background-image: url('../assets/vendor/emission_free_delivery.png');
  }

  #for_indies {
    background-image: url('../assets/vendor/for_indies.png');
  }

  #swipe_right {
    height: 40px;
    margin: 20px 0;
    @media (min-width: 1290px) {
      display: none;
    }
  }

  @media (max-width: 950px) {
    padding: 65px 0 0 0;

    .card {
      max-width: 94vw;
      max-height: 470px;
      -webkit-background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      -o-background-size: 100% 100%;
      background-size: 100% 100%;

      h3 {
        margin: 272px 0px 17px 0px;
      }

      p {
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
}

.brands-container {
  margin-top: 90px;

  p,
  b {
    font-size: 25px;
    font-family:
      Sofia Pro,
      sans-serif;
    font-weight: 400;
    text-align: center;
    padding: 25px 0;
    color: $coal60;

    b {
      font-weight: 600;
      color: $coal100;
    }
  }

  .brands {
    margin: 0 auto;
    padding: 0 40px;
    max-height: 174px;
    max-width: 1120px;
    max-width: 100%;
  }

  .brands-mobile {
    display: none;
    max-height: 86px;
  }

  @media (max-width: 950px) {
    margin-top: 40px;
    padding: 0 15px;

    .brands-mobile {
      margin: 0 auto;
      display: block;
    }

    .brands {
      display: none;
    }

    p {
      font-size: 20px;

      b {
        font-size: 18px;
      }
    }
  }
}

.footnote {
  max-width: 1120px;
  margin: auto;
  width: 100%;

  p,
  sup {
    margin-bottom: -24px;
    text-align: left;
    font-family:
      Sofia Pro,
      sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: rgba(169, 168, 168, 1);
  }
}

.deals {
  display: flex;
  margin: 20px 0 20px 0;
  box-sizing: border-box;

  .box {
    height: 450px;
    width: 480px;
    border-radius: 15px;

    h3 {
      font-family: Recoleta, sans-serif;
      font-size: 50px;
      font-weight: 400;
      line-height: 50px;
      text-align: center;
      padding: 40px 0;
    }

    &-content {
      position: relative;
      height: 75%;
      justify-content: space-between;
      padding-bottom: 30px;

      h2 {
        font-family: Recoleta, sans-serif;
        font-size: 100px;
        font-weight: 400;
        line-height: 100px;
        letter-spacing: 0em;
        text-align: center;
      }

      .column {
        padding: 42px 0px;
      }

      p {
        font-family:
          Sofia Pro,
          sans-serif;
        font-size: 20px;
        font-weight: 300;
        text-align: center;
      }

      .anchor-btn {
        align-self: center;
        margin: 0;
        width: 90%;
        max-width: 480px;
        margin-bottom: 30px;
      }
    }

    .line-divider {
      position: relative;
      height: 2px;
      width: 100%;

      .half-circle {
        position: absolute;
        top: -5px;
        width: 24px;
        height: 12px;
        background-color: $vanilla;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        border-bottom: 0;

        &-start {
          left: -7px;
          transform: rotate(90deg);
        }
        &-end {
          right: -7px;
          transform: rotate(-90deg);
        }
      }
    }
  }

  .black-box {
    margin-right: 30px;

    background: $coal100;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    .line-divider {
      border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
    }

    h2,
    h3,
    p {
      color: #fff;
    }
  }

  .white-box {
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .line-divider {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
    }

    h2,
    h3,
    p {
      color: $coal100;
    }
  }

  @media (max-width: 950px) {
    flex-direction: column;
    margin: 0 0 50px 0;

    .box {
      height: 339px;
      width: 342px;

      h3 {
        font-family: Recoleta, sans-serif;
        font-size: 40px;
        line-height: 40px;
        padding: 28px 0;
      }

      &-content {
        position: relative;
        height: 75%;
        justify-content: space-between;
        padding-bottom: 30px;

        h2 {
          font-size: 80px;
          line-height: 80px;
        }

        .column {
          padding: 30px 0px;
        }

        p {
          font-size: 17px;
        }

        .anchor-btn {
          font-size: 16px;
          margin-bottom: 0;
          padding: 18px auto;
        }
      }
    }

    .black-box {
      margin: 0 0 30px 0;
    }
  }
}

.desktop-only {
  @media (max-width: 950px) {
    display: none !important;
  }
}

.review-carousel {
  width: 950px !important;

  @media (max-width: 950px) {
    width: 94vw !important;
  }
}

.reviews {
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 30px;
  padding: 0 15px;

  .quote {
    position: absolute;
    left: 20px;
    top: 0;
    max-width: 100px;
  }

  .mobile-quote {
    display: none;
  }

  .review {
    display: flex;
    justify-content: space-between;
    width: 950px;

    img {
      object-fit: contain;
      object-position: center;
      height: 480px;
      max-height: 480px;
      border-radius: 15px;
    }

    h3 {
      font-family:
        Sofia Pro,
        sans-serif;
      font-size: 25px;
      line-height: 60px;
      font-weight: 600;
      margin-left: 10px;
    }

    p {
      text-align: left;
      margin-left: 10px;
    }
  }

  h2 {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 60px;
  }

  p {
    color: rgba(0, 0, 0, 0.5);
    text-align: justify;
    margin-top: 10px;
    margin-bottom: 30px;
    padding-right: 40px;
    font-family:
      Sofia Pro,
      sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
  }

  @media (max-width: 950px) {
    p {
      padding: 0;
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 30px;
    }

    h2 {
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 10px;
    }

    .quote {
      display: none;
    }

    .review {
      padding: 0 15px;
      width: 100%;
      flex-direction: column-reverse;

      h3 {
        text-align: left;
        margin: auto 15px;
        font-size: 20px;
        line-height: 25px;
      }

      img {
        max-height: 340px;
      }

      .mobile-quote {
        object-fit: contain;
        display: inline-block;
        max-width: 55px;
        max-height: 55px;
        margin-top: 10px;
      }
    }
  }
}

.btn-text {
  font-family:
    Sofia Pro,
    sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  color: #000 !important;
}

.anchor-btn.controller-button {
  font-family:
    Sofia Pro,
    sans-serif;
  padding: 25px 120px;
  font-weight: 300;
  font-size: 20px;
  align-self: flex-end;
  margin-right: 40px;
  margin-top: auto;

  @media (max-width: 950px) {
    margin-right: 0;
    width: 100%;
    align-self: center;
    font-size: 16px;
    padding: 18px auto;
  }
}
</style>
