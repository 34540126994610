/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state: StateYup = {
  show: false,
  errors: [],
};

const mutations = {
  errors(state: StateYup, payload: YupErrors) {
    const array = state.errors;
    const i = array.findIndex((e: YupErrors) => e.path === payload.path);

    if (i >= 0) array.splice(i, 1);

    array.push(payload);
  },
  resetPath(state: StateYup, path: string) {
    state.errors = state.errors.filter((e) => e.path !== path);
  },
  clear(state: StateYup) {
    state.errors = [];
  },
  toggleShowError(state: StateYup, payload?: boolean) {
    if (typeof payload === 'boolean') state.show = payload;
    else state.show = !state.show;
  },
};

const actions = {
  //
};

const getters = {
  //
};

export interface StateYup {
  [x: string]: any;
  show: boolean;
  errors: YupErrors[];
}

export interface YupErrors {
  path: string;
  msg: string;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
