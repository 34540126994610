import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Unknown = _resolveComponent("Unknown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.brand !== 'all')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["icon-card-brand", { bgc: _ctx.bgc }])
        }, [
          (_ctx.getBrand)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.brand), { key: 0 }))
            : (_openBlock(), _createBlock(_component_Unknown, { key: 1 }))
        ], 2))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allBrand, (icon, iIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        key: iIndex,
        class: _normalizeClass(["icon-card-brand", { bgc: _ctx.bgc }])
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(icon)))
      ], 2))
    }), 128))
  ], 64))
}