import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "forgot-password-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Forgot password", -1)),
      _createVNode(_component_app_input, {
        value: _ctx.emailAddress,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailAddress) = $event)),
        showError: _ctx.show,
        id: "Email address"
      }, _createSlots({
        label: _withCtx(() => [
          _cache[1] || (_cache[1] = _createTextVNode(" Email address "))
        ]),
        _: 2
      }, [
        (_ctx.yupErrMsg('email'))
          ? {
              name: "error",
              fn: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.yupErrMsg('email')), 1)
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["value", "showError"]),
      _createVNode(_component_app_button, {
        loader: _ctx.loader,
        onClick: _ctx.recover
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Recover")
        ])),
        _: 1
      }, 8, ["loader", "onClick"])
    ]),
    _: 1
  }, 16))
}