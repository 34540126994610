import '@/scss/main.scss';
import * as Sentry from '@sentry/vue';
import { createHead } from '@unhead/vue';
import { createApp } from 'vue';
import VueGtag from 'vue-gtag-next';

import Environment from '@/environment';

import AppControllers from '@/shared/controllers/_global';

import AppDirective from '@/utils/directive/_global';

import App from './App.vue';
import router from './router';
import store from './store';

const app = createApp(App);

Sentry.init({
  app,
  dsn: Environment.sentryDsn,
  environment: Environment.sentryEnv,
  integrations: [Sentry.browserTracingIntegration()],
  tracePropagationTargets: [Environment.sentryDsn, Environment.baseApiUrl],
  tracesSampler: (_) => 0.25,
  beforeSendTransaction(event) {
    // Log only /restaurants and /menu transactions:
    if (event.transaction && (event.transaction.startsWith('/restaurants') || event.transaction.startsWith('/menu'))) {
      return event;
    }

    // Ignore other transactions:
    return null;
  },
});

AppControllers(app);
AppDirective(app);

app.use(VueGtag, { property: { id: 'AW-429869669/1GuGCNTgl_kBEOWU_cwB' } });
app.use(createHead());
app.use(store);
app.use(router);

app.mount('body');

export default app;
