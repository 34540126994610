<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9839 0.0692749C20.6871 0.0692749 22 1.16927 22 3.0854V8.54992C21.8581 9.22411 21.1484 9.72089 20.3677 9.72089C19.4806 9.72089 18.8419 9.08218 18.8419 8.30153V5.53379H16.429V8.26605C16.429 9.79186 18.3806 10.5725 19.8 10.5725C20.6161 10.5725 21.3968 10.3241 21.9645 9.89831V15.9306H18.1323C16.7839 15.9306 15.2226 14.6531 15.2226 13.0209V0.0692749H18.9839Z"
      fill="#1174CE"
    />
    <path
      d="M22 3.0854V8.54992C21.8581 9.22411 21.1484 9.72089 20.3677 9.72089C19.4806 9.72089 18.8419 9.08218 18.8419 8.30153V5.53379H16.429V8.26605C16.429 9.79186 18.3806 10.5725 19.8 10.5725C20.6161 10.5725 21.3968 10.3241 21.9645 9.89831V15.9306H18.1323C16.7839 15.9306 15.2226 14.6531 15.2226 13.0209V0.0692749"
      fill="#0F549D"
    />
    <path
      d="M16.4645 5.534V8.26626C16.4645 9.79206 18.4161 10.5727 19.8355 10.5727C20.6516 10.5727 21.4323 10.3243 22 9.89851V15.9308H18.1677C16.8194 15.9308 15.2581 14.6534 15.2581 13.0211"
      fill="#02375E"
    />
    <path
      d="M5.57129 9.65003H3.90355C3.47774 9.65003 3.08742 9.2597 3.08742 8.83389C3.08742 8.40809 3.47774 8.01776 3.90355 8.01776H5.57129V9.65003Z"
      fill="#146643"
    />
    <path
      d="M3.69057 0.0692749C5.42928 0.0692749 6.7067 1.16927 6.7067 3.04992V5.53379H2.23573C1.59702 5.53379 1.06476 6.06605 1.06476 6.70476C1.06476 7.34347 1.59702 7.87573 2.23573 7.87573C1.52605 7.87573 0.958313 8.3725 0.958313 9.01121C0.958313 9.64992 1.49057 10.1467 2.23573 10.1467H6.74219V15.9306H2.90993C1.56154 15.9306 0.000247478 14.6531 0.000247478 13.0209V0.0692749H3.69057Z"
      fill="#1BCC38"
    />
    <path
      d="M6.74219 3.0854V5.53379H2.27122C1.63251 5.53379 1.10025 6.06605 1.10025 6.70476C1.10025 7.34347 1.63251 7.87573 2.27122 7.87573C1.56154 7.87573 0.993796 8.3725 0.993796 9.01121C0.993796 9.64992 1.52606 10.1467 2.27122 10.1467H6.74219V15.9306H2.90993C1.56154 15.9306 0.000247478 14.6531 0.000247478 13.0209V0.0692749"
      fill="#329947"
    />
    <path
      d="M1.10025 6.70477C1.10025 7.34348 1.63251 7.87574 2.27122 7.87574C1.56154 7.87574 0.993796 8.37252 0.993796 9.01123C0.993796 9.64994 1.52606 10.1467 2.27122 10.1467H6.74219V15.9306H2.90993C1.56154 15.9306 0.000247478 14.6532 0.000247478 13.0209"
      fill="#146643"
    />
    <path
      d="M3.1229 6.84683C3.1229 6.42102 3.51323 6.0307 3.93903 6.0307H5.57129V7.62747H3.90355C3.47774 7.62747 3.1229 7.27263 3.1229 6.84683Z"
      fill="#146643"
    />
    <path
      d="M11.3901 0.0692749C13.1288 0.0692749 14.4062 1.16927 14.4062 3.04992V6.10153C13.9804 5.74669 13.4482 5.49831 12.8804 5.49831H8.83527V6.34992C9.79334 6.13702 10.7159 6.03057 11.1772 6.03057C12.1708 6.03057 12.9869 6.84669 12.9869 7.84024C12.9869 8.83379 12.1708 9.64992 11.1772 9.64992C10.7159 9.64992 9.79334 9.57895 8.83527 9.36605V10.1467H12.8804C13.4482 10.1467 14.0159 9.93379 14.4062 9.54347V15.9306H10.574C9.2256 15.9306 7.6643 14.6531 7.6643 13.0209V0.0692749H11.3901Z"
      fill="#E20E37"
    />
    <path
      d="M14.4062 3.0854V6.13702C13.9804 5.78218 13.4482 5.53379 12.8804 5.53379H8.83527V6.3854C9.79334 6.1725 10.7159 6.06605 11.1772 6.06605C12.1708 6.06605 12.9869 6.88218 12.9869 7.87573C12.9869 8.86928 12.1708 9.6854 11.1772 9.6854C10.7159 9.6854 9.79334 9.61444 8.83527 9.40153V10.1467H12.8804C13.4482 10.1467 14.0159 9.93379 14.4062 9.54347V15.9306H10.574C9.2256 15.9306 7.6643 14.6531 7.6643 13.0209V0.0692749"
      fill="#B41F36"
    />
    <path
      d="M8.83527 5.534V6.38561C9.79334 6.17271 10.7159 6.06625 11.1772 6.06625C12.1708 6.06625 12.9869 6.88238 12.9869 7.87593C12.9869 8.86948 12.1708 9.68561 11.1772 9.68561C10.7159 9.68561 9.79334 9.61464 8.83527 9.40174V10.1469H12.8804C13.4482 10.1469 14.0159 9.934 14.4062 9.54367V15.9308H10.574C9.2256 15.9308 7.6643 14.6534 7.6643 13.0211"
      fill="#720A1E"
    />
  </svg>
  <!-- eslint-enable -->
</template>
