<template>
  <div class="zigzag">
    <IconZigZag />
    <IconZigZag />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import IconZigZag from '@/shared/icons/IconZigZag.vue';

@Options({
  components: {
    IconZigZag,
  },
})
export default class BasketZigZag extends Vue {}
</script>

<style lang="scss" scoped>
.zigzag {
  margin-left: -1px;
  margin-right: -1px;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 1px;
    width: calc(100% - 2px);
    height: 2px;
    background-color: $white;
  }
  svg {
    width: 50%;
    margin-bottom: 1px;
    &:last-child {
      max-width: calc(50% + 1px);
    }
  }
}
</style>
