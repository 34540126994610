import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "hours-modal-wrapper" }
const _hoisted_2 = { class: "hours-modal-day-hours" }
const _hoisted_3 = { class: "hours-modal-day-hours--text" }
const _hoisted_4 = { class: "hours-modal-day-hours--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "hours-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _createVNode(_component_app_button, {
        class: "hours-modal-close",
        mini: "",
        white: "",
        onClick: _withModifiers(_ctx.close, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconServices, {
            class: "services-icon",
            icon: "add",
            close: ""
          })
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Opening hours", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "divider ham full-width" }, null, -1)),
        _createElementVNode("p", _hoisted_2, [
          _createElementVNode("b", null, _toDisplayString(_ctx.currentDayOpeningHours[0]), 1),
          _cache[0] || (_cache[0] = _createElementVNode("b", null, "-", -1)),
          _createElementVNode("b", null, _toDisplayString(_ctx.currentDayOpeningHours[1]), 1)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.restaurant.weekWorkingHours, (_, index) => {
          return (_openBlock(), _createElementBlock("p", {
            class: "hours-modal-day-hours",
            key: _ctx.data.restaurant.weekWorkingHours[index].day
          }, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.data.restaurant.weekWorkingHours[index].weekDay), 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.specificDayOpeningHours(_ctx.data.restaurant.weekWorkingHours[index])), 1)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 16))
}