import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "group-order-share__link" }
const _hoisted_2 = { class: "group-order-share__link-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, {
    class: "group-order-share",
    close: true
  }, {
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "group-order-share__title" }, "Share your link", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "group-order-share__description" }, " Share this link to add people to your order. Once participants have added to your order, proceed to checkout to place your order. ", -1)),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.data.link), 1)
      ]),
      _createVNode(_component_app_button, {
        class: "group-order-share__share-button",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.copyToClipboard()), ["prevent"]))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.copyText), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}