/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state: StateProductTour = {};

const mutations = {
  setAsCompleted(state: StateProductTour, payload: string) {
    state[payload] = true;
  },
};

const actions = {};

const getters = {};

export interface StateProductTour {
  [x: string]: boolean;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
