<template>
  <div class="restaurant-about-wrapper">
    <div class="restaurant-about">
      <div class="restaurant-about-title">
        <h1>{{ restaurant.name }}</h1>
        <div class="divider grape full-width" />
      </div>

      <p class="restaurant-about-text">
        {{ restaurant.description }}
      </p>
      <div class="restaurant-about-dialog-buttons">
        <button class="restaurant-about-button" @click="showRestaurantProfile">Restaurant profile</button>
        <button class="restaurant-about-button" @click="showOpeningHours">Opening hours</button>
      </div>
      <CreateGroupOrderButton
        v-if="cityName && !groupData && !amendableData && !restaurant?.isTemporarilyClose && !restaurant?.isClosed"
        :restaurant="restaurant"
        :wholeMenuMinHoursInAdvance="wholeMenuMinHoursInAdvance"
        :forceLongText="true"
      />
    </div>
    <img class="restaurant-about-cover-img" :src="restaurant.cover" :alt="`${restaurant.name}_cover`" />
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';
import { mapState } from 'vuex';

import CreateGroupOrderButton from '@/components/Restaurant/CreateGroupOrderButton.vue';

import pipes from '@/utils/pipes';

class Props {
  restaurant = prop<FullInfoRestaurant>({ required: true });
}

@Options({
  computed: {
    ...mapState('groupOrder', ['groupData']),
    ...mapState('amendableOrder', { amendableData: 'data' }),
    ...mapState('address', ['cityName']),
  },
  components: {
    CreateGroupOrderButton,
  },
  props: {
    restaurant: Object,
    wholeMenuMinHoursInAdvance: Number,
  },
})
export default class RestaurantAbout extends Vue.with(Props) {
  private truncate(text: string) {
    return pipes.truncate(text, 500);
  }

  private showRestaurantProfile() {
    this.$store.commit('modals/data', this.restaurant);
    this.$store.commit('modals/show', 'about');
  }

  private showOpeningHours() {
    this.$store.commit('modals/data', { restaurant: this.restaurant });
    this.$store.commit('modals/show', 'openingHours');
  }
}
</script>

<style lang="scss">
.restaurant-about {
  width: 66%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 25px 20px;

  &-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid $coal20;
    border-radius: 15px;
    background-color: $white;
    padding: 15px;
  }

  &-cover-img {
    width: 34%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }

  &-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: fit-content;

    .divider {
      margin-bottom: 15px;
    }

    h1 {
      @include h1;
    }
  }

  &-text {
    max-width: 100%;
    max-height: 123px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 20px;
  }

  &-dialog-buttons {
    display: flex;
    margin-bottom: 14px;
  }

  &-button {
    color: $coal100;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    text-decoration: underline;
    margin-right: 30px;
  }

  &-address-button {
    color: $coal100;
    font-weight: 600;
    background-color: $coal20;
    padding: 7px 12px;
    border-radius: 5px;
    display: flex;

    &-arrow {
      margin-top: -2px;
    }
  }

  @include for-smartphone {
    display: none;
  }
}
</style>
