import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/landing/how_it_works_slot.png'
import _imports_1 from '../../assets/landing/how_it_works_pear.png'
import _imports_2 from '../../assets/landing/how_it_works_globe.png'


const _hoisted_1 = { class: "how_it_works_section" }
const _hoisted_2 = { class: "section_title" }
const _hoisted_3 = { class: "how_it_works_steps" }
const _hoisted_4 = { class: "how_it_works_step" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "sub_title" }, "The easiest way to feed your team", -1)),
    _createElementVNode("div", _hoisted_3, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "how_it_works_step" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }),
        _createElementVNode("p", null, [
          _createTextVNode("Choose your city and delivery date - on the day or "),
          _createElementVNode("b", null, "up to 90 days in advance")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_4, [
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          src: _imports_1,
          alt: ""
        }, null, -1)),
        _renderSlot(_ctx.$slots, "pick-from", {}, undefined, true)
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "how_it_works_step" }, [
        _createElementVNode("img", {
          src: _imports_2,
          alt: ""
        }),
        _createElementVNode("p", null, [
          _createTextVNode("Place your order and we’ll do the rest. "),
          _createElementVNode("b", null, "Invoicing, order tracking and emission-free delivery")
        ])
      ], -1))
    ])
  ]))
}