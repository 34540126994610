import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "gtm-new-event-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "title" }, "Adding a new event?", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "subtitle" }, "Do you want to use your existing event details or do you need to add new event information?", -1)),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_app_button, {
          slim: "",
          onClick: _ctx.usePrevious
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("p", null, "Use existing details", -1)
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_app_button, {
          slim: "",
          white: "",
          onClick: _ctx.addNew
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("p", null, "Add new details", -1)
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 16))
}