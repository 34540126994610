<template>
  <!-- eslint-disable -->
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 11.9696L12.9083 19.5497C12.0396 20.4783 10.8612 21 9.6326 21C8.40396 21 7.22564 20.4783 6.35686 19.5497C5.48808 18.6211 5 17.3616 5 16.0483C5 14.7351 5.48808 13.4756 6.35686 12.547L13.4485 4.96687C14.0277 4.34779 14.8132 4 15.6323 4C16.4514 4 17.237 4.34779 17.8162 4.96687C18.3954 5.58595 18.7207 6.4256 18.7207 7.30111C18.7207 8.17662 18.3954 9.01628 17.8162 9.63535L10.7168 17.2154C10.4272 17.525 10.0344 17.6989 9.62488 17.6989C9.21534 17.6989 8.82256 17.525 8.53297 17.2154C8.24338 16.9059 8.08068 16.4861 8.08068 16.0483C8.08068 15.6106 8.24338 15.1907 8.53297 14.8812L15.0845 7.88674"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <!-- eslint-enable -->
</template>
