<template>
  <app-modal class="delete-modal" v-bind="options">
    <h2>Delete account</h2>
    <p v-if="signInType === 'email'">
      To permanently delete your account, you’ll need to enter your password. Once deleted,
      <strong>this action cannot be undone.</strong>
    </p>
    <p v-else>
      To permanently delete your account, confirm account deletion. Once deleted,
      <strong>this action cannot be undone.</strong>
    </p>
    <app-input v-if="signInType === 'email'" v-model:value="form.password" type="password" style="margin-bottom: 20px">
      Password
    </app-input>
    <app-button @click="confirm()">Confirm</app-button>
    <div class="error">{{ errorMsg }}</div>
  </app-modal>
</template>

<script lang="ts">
import { FirebaseError } from '@firebase/util';
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
} from 'firebase/auth';
import { Vue } from 'vue-class-component';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

export default class ModalDeleteAccount extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: true,
    noPadding: false,
  };

  private user?: User;
  private signInType = '';

  public form = { password: '' };
  private errorMsg = '';

  private async confirm() {
    const auth = getAuth();

    if (!auth.currentUser || !this.user || !this.user.email) {
      this.errorMsg = 'User is not logged in. Please refresh the page';
      return;
    }

    try {
      if (this.signInType === 'email') {
        if (this.form.password.length === 0) {
          this.errorMsg = 'Please provide password.';
          return;
        }

        await reauthenticateWithCredential(
          auth.currentUser,
          EmailAuthProvider.credential(this.user.email, this.form.password),
        );
        await auth.currentUser.delete();
      } else if (this.signInType === 'google') {
        await reauthenticateWithPopup(auth.currentUser, new GoogleAuthProvider());
        await auth.currentUser.delete();
      } else {
        this.errorMsg = 'Something went wrong. Please try again later';
        return;
      }
    } catch (err) {
      if (err instanceof FirebaseError && err.code === 'auth/wrong-password') {
        this.errorMsg = 'Invalid password.';
      } else {
        this.errorMsg = 'Something went wrong.';
      }
      return;
    }

    this.$router.push('/');
    this.close();
  }

  private close() {
    this.$store.commit('modals/close');
  }

  created() {
    this.user = this.$store.state.modals.data?.user;
    if (this.user?.types.includes('google')) {
      this.signInType = 'google';
    } else if (this.user?.types.includes('facebook')) {
      this.signInType = 'facebook';
    } else {
      this.signInType = 'email';
    }

    this.$store.commit('modals/data', null);
  }
}
</script>

<style lang="scss" scoped>
.delete-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-top: 15px;
    margin-bottom: 20px;
    white-space: normal;
  }
}

.error {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}
</style>
