import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "delete-modal" }, _ctx.options), {
    default: _withCtx(() => [
      _cache[6] || (_cache[6] = _createElementVNode("h2", null, "Delete account", -1)),
      (_ctx.signInType === 'email')
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _cache[2] || (_cache[2] = [
            _createTextVNode(" To permanently delete your account, you’ll need to enter your password. Once deleted, "),
            _createElementVNode("strong", null, "this action cannot be undone.", -1)
          ])))
        : (_openBlock(), _createElementBlock("p", _hoisted_2, _cache[3] || (_cache[3] = [
            _createTextVNode(" To permanently delete your account, confirm account deletion. Once deleted, "),
            _createElementVNode("strong", null, "this action cannot be undone.", -1)
          ]))),
      (_ctx.signInType === 'email')
        ? (_openBlock(), _createBlock(_component_app_input, {
            key: 2,
            value: _ctx.form.password,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.password) = $event)),
            type: "password",
            style: {"margin-bottom":"20px"}
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" Password ")
            ])),
            _: 1
          }, 8, ["value"]))
        : _createCommentVNode("", true),
      _createVNode(_component_app_button, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirm()))
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("Confirm")
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.errorMsg), 1)
    ]),
    _: 1
  }, 16))
}