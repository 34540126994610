import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "controller-input-phone-prefix"
}
const _hoisted_3 = {
  key: 1,
  class: "controller-input-icon"
}
const _hoisted_4 = ["value", "min", "max", "id", "type", "name", "placeholder", "disabled", "maxlength", "checked", "readonly", "required"]
const _hoisted_5 = { key: 3 }
const _hoisted_6 = ["value", "id", "type", "name", "placeholder", "disabled", "maxlength"]
const _hoisted_7 = { key: 5 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 1,
  style: {"height":"28px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["controller-input-wrapper", { 'full-width': _ctx.fullWidth }])
  }, [
    _createElementVNode("label", null, [
      (_ctx.$slots.label && _ctx.type !== 'radio')
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
            _renderSlot(_ctx.$slots, "label", { class: "app-input-label" })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["controller-input", `controller-input--${_ctx.type}`])
      }, [
        (_ctx.isPhoneNumber)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, "+44"))
          : _createCommentVNode("", true),
        (_ctx.$slots.icon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "icon")
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isMultiplyLine)
          ? (_openBlock(), _createElementBlock("input", {
              key: 2,
              onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args))),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:value', $event.target.value))),
              onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args))),
              value: _ctx.value,
              min: _ctx.minValue,
              max: _ctx.maxValue,
              class: _normalizeClass({
            'add-icon': _ctx.$slots.icon,
            'add-phone-prefix': _ctx.isPhoneNumber,
            error: _ctx.$slots.error && _ctx.showError,
            'dark-focus': _ctx.darkFocus,
            vanilla: _ctx.vanilla,
          }),
              id: _ctx.id,
              type: _ctx.type,
              name: _ctx.name,
              placeholder: _ctx.placeholder,
              disabled: _ctx.disabled,
              maxlength: _ctx.maxlength,
              checked: _ctx.checked,
              readonly: _ctx.readonly,
              required: _ctx.required,
              ref: "input"
            }, null, 42, _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.$slots.label && _ctx.type === 'radio')
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
              _renderSlot(_ctx.$slots, "label", { class: "app-input-label" })
            ]))
          : _createCommentVNode("", true),
        (_ctx.isMultiplyLine)
          ? (_openBlock(), _createElementBlock("textarea", {
              key: 4,
              onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args))),
              onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:value', $event.target.value))),
              value: _ctx.value,
              class: _normalizeClass({
            'add-icon': _ctx.$slots.icon,
            error: _ctx.$slots.error && _ctx.showError,
            'dark-focus': _ctx.darkFocus,
            vanilla: _ctx.vanilla,
          }),
              id: _ctx.id,
              type: _ctx.type,
              name: _ctx.name,
              placeholder: _ctx.placeholder,
              disabled: _ctx.disabled,
              maxlength: _ctx.maxlength,
              rows: 2
            }, null, 42, _hoisted_6))
          : _createCommentVNode("", true),
        (_ctx.$slots.overlaySlot)
          ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
              _createElementVNode("b", null, [
                _renderSlot(_ctx.$slots, "overlaySlot")
              ])
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    (_ctx.$slots.error && (_ctx.showError || _ctx.showErrorWithoutHighlighting))
      ? (_openBlock(), _createElementBlock("small", _hoisted_8, [
          _createElementVNode("b", null, [
            _renderSlot(_ctx.$slots, "error")
          ])
        ]))
      : (_ctx.addErrorPlaceholderSpacing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9))
        : _createCommentVNode("", true)
  ], 2))
}