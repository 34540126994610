<template>
  <button type="button" class="enter-address-button" v-if="!cityName" @click="showSetAddressModal">
    <span>Enter address for availability</span>
    <div class="restaurant-header-address-button-arrow">
      <img src="../../assets/simple_arrow_bottom.svg" alt="arrow bottom" />
    </div>
  </button>
  <div :class="`order-settings ${orderSettingsColor} ${disabled ? 'disabled' : ''}`" @click="showOrderSettings" v-else>
    <span>{{ orderSettingsText }}</span>
    <div class="order-settings-arrow" v-if="!disabled">
      <img src="../../assets/simple_arrow_bottom.svg" alt="arrow bottom" />
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  restaurant = prop<FullInfoRestaurant>({ required: true });
  disabled = prop<boolean>({ required: true });
  basketTotal = prop<number>({ required: true });
}

@Options({
  props: {
    restaurant: Object,
    basketTotal: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
export default class OrderSettingsButton extends Vue.with(Props) {
  declare $refs: {
    buttons: HTMLDivElement;
  };

  get cityName() {
    return this.$store.state.address.cityName;
  }

  get orderSettingsColor() {
    // Orange color if busy, snoozed or will open later,
    // Red color if closed for the rest of the day, green color if open:
    let color = 'grapeOp70';
    if (this.restaurant.isTemporarilyClose || this.restaurant.willBeOpenLaterToday) {
      color = 'lightOrange';
    } else if (this.restaurant.isClosed) {
      color = 'lightRed';
    }

    return color;
  }

  get extraPrepTime() {
    return this.$store.state.extraTime.prepTime.find((el) => this.basketTotal >= el.minCartPrice)?.additionalTime || 0;
  }

  get loadingTime() {
    return (
      this.$store.state.extraTime.loadingTime.find((el) => this.basketTotal >= el.minCartPrice)?.additionalTime || 0
    );
  }

  get orderSettingsText() {
    const isCollection = this.$store.state.order.type === 'collection';
    const { scheduledDeliveryInterval } = this.$store.state.order;
    const { scheduledDeliveryDate } = this.$store.state.order;

    if (scheduledDeliveryDate == null || scheduledDeliveryInterval == null) {
      if (this.restaurant.isTemporarilyClose) return 'Not accepting orders';
      if (this.restaurant.isClosed) return 'Now closed';
      if (isCollection) return `Pick up in ${Number(this.restaurant.preparationTime) + this.extraPrepTime} mins`;

      // NOTE: deliveryInfo.time is time between an order creation and an estimated
      // delivery time, so we don't need to add restaurant's preparation time again
      const bestTime = this.restaurant.deliveryInfo.time.best + this.extraPrepTime + this.loadingTime;
      const worstTime = this.restaurant.deliveryInfo.time.worst + this.extraPrepTime + this.loadingTime;

      return `Delivery in ${bestTime}-${worstTime} mins`;
    }

    const beginningOfToday = moment().tz('Europe/London').set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const dayDiff = moment(scheduledDeliveryDate).diff(beginningOfToday, 'days');

    function day() {
      switch (dayDiff) {
        case 0:
          return 'Today';
        case 1:
          return 'Tomorrow';
        default:
          return moment(scheduledDeliveryDate).format('ddd, Do MMM');
      }
    }

    const time = isCollection ? scheduledDeliveryInterval.split('-')[0].trim() : scheduledDeliveryInterval;
    return `${isCollection ? 'Collection' : 'Delivery'} · ${day()} · ${time}`;
  }

  private showOrderSettings() {
    if (this.disabled) return;

    this.$store.commit('modals/data', {
      withOrderTypeSwitch: this.restaurant.clickAndCollect,
      withAddressChange: false,
      withDeliveryTimeChange: this.restaurant.allowScheduledOrders,
      restaurant: this.restaurant,
    });
    this.$store.commit('modals/show', 'orderSettings');
  }

  private showSetAddressModal() {
    this.$store.commit('modals/data', {
      withOrderTypeSwitch: false,
      withAddressChange: true,
      withDeliveryTimeChange: true,
      restaurant: this.restaurant,
    });
    this.$store.commit('modals/show', 'orderSettings');
  }
}
</script>

<style lang="scss" scoped>
.order-settings {
  display: flex;
  align-items: center;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 15px;
  margin: 15px auto;
  width: fit-content;
  cursor: pointer;
  height: 35px;

  &.disabled {
    padding-right: 10px;
    cursor: default;
  }

  & .clock-img {
    max-height: 17px;
    margin: 0 7px 3px 8px;
  }

  &-arrow {
    @include row-centered;
    padding: 0 10px;
    margin-left: 8px;
    height: 100%;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  &.grapeOp70 {
    background-color: $ham;

    & .order-settings-arrow {
      background-color: #fdb8bc;
    }
  }
  &.lightOrange {
    background-color: #fac898;

    & .order-settings-arrow {
      background-color: #ff9a3c;
    }
  }
  &.lightRed {
    background-color: #ff9994;

    & .order-settings-arrow {
      background-color: #ff716a;
    }
  }
}

span {
  @include p2;
  color: $coal100;
}

.enter-address-button {
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-weight: 600;
  color: $coal100;
  background-color: $coal20;
  margin: 15px auto;
  padding: 7px 12px;
  gap: 7px;
  cursor: pointer;
}
</style>
