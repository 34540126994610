import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/gtm/edit.png'
import _imports_1 from '../../assets/gtm/plus_square.png'
import _imports_2 from '../../assets/gtm/smile.png'
import _imports_3 from '../../assets/gtm/settings.png'
import _imports_4 from '../../assets/gtm/calendar.png'


const _hoisted_1 = { class: "step_zero_plus" }
const _hoisted_2 = { class: "main_part" }
const _hoisted_3 = { class: "main_part_body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Logo, { text: "" }),
        _cache[1] || (_cache[1] = _createStaticVNode("<h1 data-v-9f014a2c>All the food your office needs. In one place.</h1><p class=\"how_works\" data-v-9f014a2c>Steps</p><div class=\"rows\" data-v-9f014a2c><div class=\"step_zero_plus_row\" data-v-9f014a2c><div class=\"circle\" data-v-9f014a2c><img src=\"" + _imports_0 + "\" alt=\"\" data-v-9f014a2c></div><p data-v-9f014a2c>Who are your existing suppliers?</p></div><div class=\"step_zero_plus_row\" data-v-9f014a2c><div class=\"circle\" data-v-9f014a2c><img src=\"" + _imports_1 + "\" alt=\"\" data-v-9f014a2c></div><p data-v-9f014a2c>What are the gaps in your current roster?</p></div><div class=\"step_zero_plus_row\" data-v-9f014a2c><div class=\"circle\" data-v-9f014a2c><img src=\"" + _imports_2 + "\" alt=\"\" data-v-9f014a2c></div><p data-v-9f014a2c>Tell us about upcoming food requirements</p></div><div class=\"step_zero_plus_row\" data-v-9f014a2c><div class=\"circle\" data-v-9f014a2c><img src=\"" + _imports_3 + "\" alt=\"\" data-v-9f014a2c></div><p data-v-9f014a2c>Let us build your custom marketplace</p></div><div class=\"step_zero_plus_row\" data-v-9f014a2c><div class=\"circle\" data-v-9f014a2c><img src=\"" + _imports_4 + "\" alt=\"\" data-v-9f014a2c></div><p data-v-9f014a2c>Place your first order with us</p></div></div>", 3)),
        _createVNode(_component_app_button, {
          square: "",
          white: "",
          onClick: _ctx.initPreFlow
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Get started")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ])
  ]))
}