import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/users.svg'


const _hoisted_1 = { class: "create-group-order-button__text create-group-order-button__text-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "create-group-order-button",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showGroupCreation && _ctx.showGroupCreation(...args))),
    type: "button"
  }, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "create-group-order-button__icon-wrapper" }, [
      _createElementVNode("img", {
        class: "create-group-order-button__icon",
        src: _imports_0,
        alt: ""
      })
    ], -1)),
    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "create-group-order-button__text create-group-order-button__text-large" }, "Start group order", -1)),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.forceLongText ? 'Start group order' : 'Group'), 1)
  ]))
}