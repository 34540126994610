import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "already-account" }
const _hoisted_3 = { class: "error" }
const _hoisted_4 = {
  key: 1,
  class: "verification"
}
const _hoisted_5 = { class: "verification__inputs" }
const _hoisted_6 = { class: "error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, _mergeProps({ class: "create-account-modal" }, _ctx.options), {
    default: _withCtx(() => [
      (_ctx.step === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[27] || (_cache[27] = _createElementVNode("h2", { class: "ta-c" }, "Create account", -1)),
            _createVNode(_component_app_input, {
              value: _ctx.firstName,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstName) = $event)),
              showError: _ctx.show,
              id: "first_name"
            }, _createSlots({
              label: _withCtx(() => [
                _cache[19] || (_cache[19] = _createTextVNode("First name"))
              ]),
              _: 2
            }, [
              (_ctx.yupErrMsg('firstName'))
                ? {
                    name: "error",
                    fn: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.yupErrMsg('firstName')), 1)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["value", "showError"]),
            _createVNode(_component_app_input, {
              value: _ctx.phone,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phone) = $event)),
              showError: _ctx.show,
              id: "phone_number"
            }, _createSlots({
              label: _withCtx(() => [
                _cache[20] || (_cache[20] = _createTextVNode("Telephone number"))
              ]),
              _: 2
            }, [
              (_ctx.yupErrMsg('phone'))
                ? {
                    name: "error",
                    fn: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.yupErrMsg('phone')), 1)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["value", "showError"]),
            _createVNode(_component_app_input, {
              value: _ctx.email,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
              showError: _ctx.show,
              id: "email_signup"
            }, _createSlots({
              label: _withCtx(() => [
                _cache[21] || (_cache[21] = _createTextVNode("Email address"))
              ]),
              _: 2
            }, [
              (_ctx.yupErrMsg('email'))
                ? {
                    name: "error",
                    fn: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.yupErrMsg('email')), 1)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["value", "showError"]),
            _createVNode(_component_app_input, {
              value: _ctx.password,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.password) = $event)),
              showError: _ctx.show,
              type: "password",
              id: "password"
            }, _createSlots({
              label: _withCtx(() => [
                _cache[22] || (_cache[22] = _createTextVNode("Password"))
              ]),
              _: 2
            }, [
              (_ctx.yupErrMsg('password'))
                ? {
                    name: "error",
                    fn: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.yupErrMsg('password')), 1)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["value", "showError"]),
            _createVNode(_component_app_input, {
              value: _ctx.reEnterPassword,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.reEnterPassword) = $event)),
              showError: _ctx.show,
              type: "password",
              id: "password_repeat"
            }, _createSlots({
              label: _withCtx(() => [
                _cache[23] || (_cache[23] = _createTextVNode("Re-enter password"))
              ]),
              _: 2
            }, [
              (_ctx.yupErrMsg('reEnterPassword'))
                ? {
                    name: "error",
                    fn: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.yupErrMsg('reEnterPassword')), 1)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["value", "showError"]),
            _createVNode(_component_app_input, {
              value: _ctx.companyName,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.companyName) = $event)),
              showError: _ctx.show,
              id: "company_name"
            }, {
              label: _withCtx(() => _cache[24] || (_cache[24] = [
                _createTextVNode("Company name (optional)")
              ])),
              _: 1
            }, 8, ["value", "showError"]),
            _createVNode(_component_app_button, {
              class: "g-recaptcha",
              "data-sitekey": "6Lf7Qm4eAAAAAHsyhY_nPcKoSi1dUCQDAhblLx_j",
              "data-callback": "signUp",
              "data-action": "submit",
              loader: _ctx.loader,
              onClick: _ctx.signUp,
              id: "create_account"
            }, {
              default: _withCtx(() => _cache[25] || (_cache[25] = [
                _createTextVNode(" Create account ")
              ])),
              _: 1
            }, 8, ["loader", "onClick"]),
            _createElementVNode("p", _hoisted_2, [
              _cache[26] || (_cache[26] = _createTextVNode(" Already have an account? ")),
              _createElementVNode("u", {
                class: "custom-cursor-hover",
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)))
              }, "Log in")
            ]),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1)
          ]))
        : (_ctx.step === 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[29] || (_cache[29] = _createElementVNode("h2", { class: "ta-c" }, "Submit verification", -1)),
              _createElementVNode("p", null, "We've sent a code to " + _toDisplayString(_ctx.phone) + ".", 1),
              _cache[30] || (_cache[30] = _createElementVNode("p", null, "Please enter the code below to verify your account.", -1)),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_app_input, {
                  value: _ctx.code[0],
                  "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.code[0]) = $event)),
                  showError: _ctx.show,
                  id: "code",
                  class: "verification__input",
                  maxlength: "1",
                  onInput: _cache[8] || (_cache[8] = (e) => _ctx.focusOnInput(e, 'code-input-2'))
                }, _createSlots({ _: 2 }, [
                  (_ctx.yupErrMsg('code'))
                    ? {
                        name: "error",
                        fn: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.yupErrMsg('code')), 1)
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["value", "showError"]),
                _createVNode(_component_app_input, {
                  value: _ctx.code[1],
                  "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.code[1]) = $event)),
                  showError: _ctx.show,
                  id: "code-input-2",
                  class: "verification__input",
                  maxlength: "1",
                  onInput: _cache[10] || (_cache[10] = (e) => _ctx.focusOnInput(e, 'code-input-3'))
                }, _createSlots({ _: 2 }, [
                  (_ctx.yupErrMsg('code'))
                    ? {
                        name: "error",
                        fn: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.yupErrMsg('code')), 1)
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["value", "showError"]),
                _createVNode(_component_app_input, {
                  value: _ctx.code[2],
                  "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.code[2]) = $event)),
                  showError: _ctx.show,
                  id: "code-input-3",
                  class: "verification__input",
                  maxlength: "1",
                  onInput: _cache[12] || (_cache[12] = (e) => _ctx.focusOnInput(e, 'code-input-4'))
                }, _createSlots({ _: 2 }, [
                  (_ctx.yupErrMsg('code'))
                    ? {
                        name: "error",
                        fn: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.yupErrMsg('code')), 1)
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["value", "showError"]),
                _createVNode(_component_app_input, {
                  value: _ctx.code[3],
                  "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.code[3]) = $event)),
                  showError: _ctx.show,
                  id: "code-input-4",
                  class: "verification__input",
                  maxlength: "1",
                  onInput: _cache[14] || (_cache[14] = (e) => _ctx.focusOnInput(e, 'code-input-5'))
                }, _createSlots({ _: 2 }, [
                  (_ctx.yupErrMsg('code'))
                    ? {
                        name: "error",
                        fn: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.yupErrMsg('code')), 1)
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["value", "showError"]),
                _createVNode(_component_app_input, {
                  value: _ctx.code[4],
                  "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.code[4]) = $event)),
                  showError: _ctx.show,
                  id: "code-input-5",
                  class: "verification__input",
                  maxlength: "1",
                  onInput: _cache[16] || (_cache[16] = (e) => _ctx.focusOnInput(e, 'code-input-6'))
                }, _createSlots({ _: 2 }, [
                  (_ctx.yupErrMsg('code'))
                    ? {
                        name: "error",
                        fn: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.yupErrMsg('code')), 1)
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["value", "showError"]),
                _createVNode(_component_app_input, {
                  value: _ctx.code[5],
                  "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.code[5]) = $event)),
                  showError: _ctx.show,
                  id: "code-input-6",
                  class: "verification__input",
                  maxlength: "1"
                }, _createSlots({ _: 2 }, [
                  (_ctx.yupErrMsg('code'))
                    ? {
                        name: "error",
                        fn: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.yupErrMsg('code')), 1)
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["value", "showError"])
              ]),
              _createElementVNode("button", {
                onClick: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
              }, "Go back"),
              _createVNode(_component_app_button, {
                onClick: _ctx.checkCodeAndCreateUser,
                loader: _ctx.loader,
                disabled: _ctx.loader
              }, {
                default: _withCtx(() => _cache[28] || (_cache[28] = [
                  _createTextVNode("Create account")
                ])),
                _: 1
              }, 8, ["onClick", "loader", "disabled"]),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.codeErrorMessage), 1)
            ]))
          : _createCommentVNode("", true),
      _cache[31] || (_cache[31] = _createElementVNode("div", {
        class: "recaptcha-container",
        id: "recaptcha-container"
      }, null, -1))
    ]),
    _: 1
  }, 16))
}