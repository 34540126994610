<template>
  <div class="search-input-wrapper">
    <a class="search-input" v-if="!isEditing" @click="toggleIsEditing">
      <div class="label">
        <IconServices icon="locationLight" />
        <p :class="{ 'basic-font': isBasicFont }">{{ search || placeholder }}</p>
      </div>
      <div class="change-address">
        <IconServices icon="pencil" />
      </div>
    </a>
    <div class="search-input" v-else>
      <div class="label">
        <IconServices icon="locationLight" />
        <input
          @click="searching"
          @input="searching"
          @focus="focus"
          v-model="search"
          type="text"
          :placeholder="placeholder"
          aria-label="Enter address"
          :class="{ 'basic-font': isBasicFont, error: $slots.error && showError }"
        />
      </div>
      <div class="change-address" />
      <ul class="search-list" v-if="dropdown" v-click-outside="close">
        <li
          class="search-list-item custom-cursor-hover"
          v-for="(item, iIndex) in list"
          :key="iIndex"
          @click="getPlace(item)"
        >
          <IconServices icon="locationLight" />
          <span>{{ item.description }}</span>
        </li>
      </ul>
    </div>
    <small v-if="$slots.error && showError">
      <b><slot name="error" /></b>
    </small>
    <div v-else-if="addErrorPlaceholderSpacing" style="height: 28px" />
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

import IconServices from '@/shared/icons/IconServices.vue';

import google from '@/utils/google';

class Props {
  initialAddress = prop<google.maps.places.PlaceResult>({ required: false });
  onChange = prop<Function>({ required: true });
  placeholder = prop<string>({ required: true });
  isAlwaysEditing = prop<boolean>({ required: false, default: false });
  acceptAllCities = prop<boolean>({ required: false, default: false });
}

@Options({
  components: { IconServices },
  props: {
    initialAddress: Object,
    onChange: Function,
    placeholder: String,
    isAlwaysEditing: {
      type: Boolean,
      default: false,
    },
    isBasicFont: {
      type: Boolean,
      default: false,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    addErrorPlaceholderSpacing: {
      type: Boolean,
      default: false,
    },
    acceptAllCities: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AddressChangeInput extends Vue.with(Props) {
  private isEditing = false;
  private search = '';
  private list: google.maps.places.AutocompletePrediction[] = [];

  get dropdown() {
    if (this.list.length > 0) return true;
    return false;
  }

  private close() {
    this.list = [];
  }

  private searching() {
    if (this.search) {
      google
        .autocomplete(this.search)
        .then((res: google.maps.places.AutocompletePrediction[]) => {
          this.list = res.slice(0, 4);
        })
        .catch(() => {
          this.list = [];
        });
    } else {
      this.list = [];
    }
  }

  private focus() {
    const isError = this.$store.state.yup.errors.some((e) => e.path === 'address');
    if (isError) this.$store.commit('yup/resetPath', 'address');
  }

  private setSearch() {
    const search = this.initialAddress?.formatted_address || this.$store.state.address.formattedAddress;
    if (search) this.search = search;
  }

  private toggleIsEditing() {
    this.isEditing = !this.isEditing;
  }

  private getPlace(place: google.maps.places.AutocompletePrediction) {
    google.getPlaceDetails(place).then((result: google.maps.places.PlaceResult) => {
      google
        .validatePlace(result, this.acceptAllCities)
        .then((_) => {
          this.setSearch();
          this.toggleIsEditing();

          if (result.formatted_address) this.search = result.formatted_address;
          this.onChange(result);
        })
        .catch((err: { message: string }) => {
          const postcode = google.getPostalCode(result.address_components || []) || '';
          google.openModalSearch(err.message, postcode);
        })
        .finally(() => this.close());
    });
  }

  created() {
    this.setSearch();
    if (this.isAlwaysEditing && !this.search) this.isEditing = true;
  }
}
</script>

<style lang="scss" scoped>
$vw-search-bar: 750;

.search-input-wrapper {
  display: flex;
  flex-direction: column;

  small {
    color: $error;
    margin-top: 10px;
  }
}

.search-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 42px;
  position: relative;

  &.disabled {
    &::after {
      box-sizing: content-box;
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 50;
      border-radius: 43px;
      cursor: not-allowed;
      padding: 10px;
    }
  }

  .change-address {
    position: absolute;
    right: 15px;
    top: 12px;
    z-index: 100;
  }

  .label {
    position: relative;
    z-index: 15;
    width: 100%;

    svg {
      position: absolute;
      left: 15px;
      top: 11px;
      width: 18px;
      height: 22px;
      z-index: 15;
    }

    input {
      font-family:
        Sofia Pro,
        sans-serif;
      width: 100%;
      color: $coal100;
      font-size: 15px;
      line-height: 20px;
      padding: 12px 12px 10px 40px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border: 1px solid $coal20;
      background-color: $white;
      border-radius: 5px;
      font-weight: 600;
      text-decoration: underline;

      &.basic-font {
        text-decoration: none;
        border-radius: 10px;
        font-weight: 300;

        &::placeholder {
          font-weight: 300;
        }

        &:focus {
          border: 3px solid $ham;
          padding: 10px 10px 8px 38px;
        }

        &.error {
          border: 3px solid $error;
          padding: 10px 10px 8px 38px;
        }
      }

      &.disabled {
        padding-right: 72px;
        color: $coal100;
        position: relative;
        &:hover {
          cursor: not-allowed;
        }

        &::placeholder {
          color: rgba($color: $coal100, $alpha: 0.7);
          text-decoration: underline;
        }
      }
    }

    p {
      font-family:
        Sofia Pro,
        sans-serif;
      width: 100%;
      color: $coal100;
      font-size: 15px;
      line-height: 20px;
      padding: 12px 40px 10px 40px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border: 1px solid $coal20;
      background-color: $white;
      border-radius: 5px;
      font-weight: 600;
      text-decoration: underline;
      margin: 0;

      &.basic-font {
        text-decoration: none;
        border-radius: 10px;
        font-weight: 300;
      }
    }
  }

  button {
    color: $darkHam;
    z-index: 55;
  }
}

.search-list {
  position: absolute;
  padding-top: 30px;
  z-index: 10;
  border: 1px solid $coal20;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  border-top: none;
  top: 11px;
  left: 0;
  width: 100%;
  background-color: $white;
  &::after {
    border-top: 1px solid $coal20;
    content: '';
    height: 30px;
    box-sizing: border-box;
    text-align: right;
    display: block;
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120px 14px;
  }

  &-item {
    display: flex;
    align-items: center;
    padding: 0px 15px;
    border-top: 1px solid $coal20;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 600;
      color: $coal100;
      margin-top: 10px;
      margin-bottom: 7.5px;
    }
    svg {
      height: 22px;
      min-width: 18px;
      margin-right: 5px;
    }
    &:hover {
      background-color: rgba(255, 202, 205, 0.2);
    }
  }
}
</style>
