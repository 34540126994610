import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "amendable-group-order-warning__buttons-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, {
    class: "amendable-group-order-warning",
    close: true
  }, {
    default: _withCtx(() => [
      _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "amendable-group-order-warning__title" }, "You’re amending an order", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "amendable-group-order-warning__description" }, " Whilst amending an order, you are unable to change the delivery time and address. You are also unable to view other restaurants. ", -1)),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_app_button, {
          class: "amendable-group-order-warning__menu-button",
          onClick: _withModifiers(_ctx.goToAmendableOrderRestaurant, ["prevent"])
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Amend order ")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_app_button, {
          class: "amendable-group-order-warning__cancel-button",
          onClick: _withModifiers(_ctx.revertAmendedChanges, ["prevent"])
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Revert changes ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }))
}