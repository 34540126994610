<template>
  <header class="restaurant-header-wrapper" v-if="restaurant">
    <!-- Embedded views -->
    <template v-if="$route.query.embedded">
      <div class="embedded-header">
        <app-back-link v-if="isInCheckout" mini :link="`/menu/${restaurant?.restaurantCity}/${restaurant?.slug}`" />
        <div v-else style="height: 40px; width: 40px" />
        <OrderSettingsButton
          :disabled="isInCheckout || (!restaurant.allowScheduledOrders && !restaurant.clickAndCollect)"
          :restaurant="restaurant"
          :basketTotal="+totalPrice"
        />
        <app-button v-if="user" mini square white @click="goToSettings">
          <IconServices icon="account" />
        </app-button>
        <div v-else style="height: 40px; width: 40px" />
      </div>
    </template>

    <!-- Standard views -->
    <template v-else>
      <!-- Back button -->
      <div
        v-if="
          !(
            groupData &&
            !amendableData &&
            (!groupOrderIdFromUrl || groupOrderIdFromUrl === groupData.id) &&
            !isInCheckout
          )
        "
        class="restaurant-header-back-btn"
      >
        <app-back-link
          mini
          :link="`${
            isInCheckout
              ? `/menu/${restaurant?.restaurantCity}/${restaurant?.slug}`
              : `/restaurants/${restaurant?.restaurantCity}`
          }`"
        />
      </div>

      <!-- Cover -->
      <img
        :src="restaurant.cover"
        class="restaurant-header-cover"
        :class="{
          shrinked:
            groupData &&
            !amendableData &&
            (!groupOrderIdFromUrl || groupOrderIdFromUrl === groupData.id) &&
            !isInCheckout,
        }"
        alt=""
      />

      <div class="restaurant-header-info" :class="{ checkout: isInCheckout }">
        <!-- Logo, name and description -->
        <img :src="restaurant.logo" alt="" class="vendor-logo extra" />
        <div class="restaurant-header-info-buttons" v-if="!isInCheckout">
          <button class="restaurant-header-button" @click="showRestaurantProfile">Restaurant profile</button>
          <button class="restaurant-header-button" @click="showOpeningHours">Opening hours</button>
        </div>
        <h2>{{ restaurant.name }}</h2>
        <div class="restaurant-header-description-wrapper" v-if="!isInCheckout">
          <p class="restaurant-header-description">
            {{ truncate(restaurant.description) }}
          </p>
        </div>

        <!-- Order type and date AND/OR possibility to create group order OR amendable order data -->
        <div class="restaurant-header-actions-wrapper">
          <OrderSettingsButton
            v-if="!(groupData && !isInCheckout) && !amendableData"
            :disabled="isInCheckout || (!restaurant.allowScheduledOrders && !restaurant.clickAndCollect)"
            :restaurant="restaurant"
            :basketTotal="+totalPrice"
          />
          <CreateGroupOrderButton
            v-if="
              cityName &&
              !groupData &&
              !amendableData &&
              !restaurant?.isTemporarilyClose &&
              !restaurant?.isClosed &&
              !isInCheckout
            "
            :restaurant="restaurant"
            :wholeMenuMinHoursInAdvance="wholeMenuMinHoursInAdvance"
          />
          <AmendableOrderInfo
            v-if="amendableData && cityName && $route.params.slug === amendableData.restaurantSlug"
            class="restaurant-header-group-order-info"
            :restaurant="restaurant"
            :isMobile="true"
          />
        </div>
      </div>
    </template>
  </header>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';
import { mapState } from 'vuex';

import AmendableOrderInfo from '@/components/Restaurant/AmendableOrderInfo.vue';
import CreateGroupOrderButton from '@/components/Restaurant/CreateGroupOrderButton.vue';
import OrderSettingsButton from '@/components/Restaurant/OrderSettingsButton.vue';

import IconServices from '@/shared/icons/IconServices.vue';

import methods from '@/utils/methods';
import pipes from '@/utils/pipes';

class Props {
  restaurantData = prop<RequestGetRestaurant>({ required: true });
}

@Options({
  components: {
    OrderSettingsButton,
    CreateGroupOrderButton,
    AmendableOrderInfo,
    IconServices,
  },
  props: {
    restaurantData: {
      menuCategories: Object,
      restaurant: Object,
    },
  },
  computed: {
    ...mapState('basket', ['vertical']),
    ...mapState('address', ['cityName']),
    ...mapState('groupOrder', ['groupData']),
    ...mapState('amendableOrder', { amendableData: 'data' }),
    ...mapState('service', ['user']),
  },
})
export default class RestaurantHeaderMobile extends Vue.with(Props) {
  get restaurant() {
    return this.restaurantData?.restaurant;
  }

  get groupOrderIdFromUrl() {
    return this.$route.query.groupId as string;
  }

  get totalPrice() {
    return methods.getTotalPrice();
  }

  get isInCheckout() {
    return this.$route.path.includes('checkout');
  }

  get wholeMenuMinHoursInAdvance() {
    const minRequiredNotices = this.restaurantData?.menuCategories.map((mc) => mc.minHoursInAdvance);
    if (minRequiredNotices && minRequiredNotices.every((el) => el))
      return minRequiredNotices.reduce((a, b) => Math.min(a, b), 0);
    return 0;
  }

  private changeVariant() {
    this.$store.commit('basket/vertical');
  }

  private truncate(text: string) {
    return pipes.truncate(text, 150);
  }

  private showRestaurantProfile() {
    this.$store.commit('modals/show', 'about');
    this.$store.commit('modals/data', this.restaurant);
  }

  private showOpeningHours() {
    this.$store.commit('modals/data', { restaurant: this.restaurant });
    this.$store.commit('modals/show', 'openingHours');
  }

  private goToSettings() {
    this.$router.push('/my-account');
  }
}
</script>

<style lang="scss" scoped>
.restaurant-header {
  &-wrapper {
    position: relative;
    width: 100%;
  }

  &-back-btn {
    position: absolute;
    z-index: 50;
    left: 0;
    top: 0;
    padding: 15px 16px;
    width: 100%;
  }

  &-cover {
    max-height: 213px;
    height: 213px;
    width: 100%;

    &.shrinked {
      height: 70px;
      max-height: 70px;
    }
  }

  &-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: -20px;
    background-color: $vanilla;
    position: relative;
    z-index: 1;

    .vendor-logo {
      border: 4px solid $vanilla;
      margin-right: 0;
      margin-top: -45px;
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: -35px;
      width: 100%;
      padding: 0 20px;
    }

    h2 {
      @include h2;
      margin-top: 30px;
    }

    &.checkout {
      h2 {
        margin-top: 10px;
      }
    }

    @media (max-width: 370px) {
      .vendor-logo {
        max-width: 80px;
        min-width: 80px;
        max-height: 80px;
        min-height: 80px;
        margin-top: -40px;
      }

      &-buttons {
        padding: 0 15px;

        & button {
          font-size: 0.9em;
        }
      }
    }
  }

  &-group-order-info {
    margin-top: 15px;
  }

  &-description {
    @include p2;
    color: $coal60;
    max-width: 300px;
    max-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;
    &-wrapper {
      text-align: center;
    }
  }

  &-button {
    @include p2;
    color: $coal100;
    text-decoration: underline;
  }

  &-actions-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 5px;

    @include for-old-mobile {
      flex-direction: column;
      gap: 0px;
    }
  }
}

.embedded-header {
  display: flex;
  flex-direction: row;
  height: 65px;
  margin: 0 15px;
  align-items: center;

  button {
    border: 1px solid #d3d3d3;
  }
}
</style>
