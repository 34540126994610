import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/check.svg'


const _hoisted_1 = { class: "step_nine" }
const _hoisted_2 = { class: "right_part" }
const _hoisted_3 = { class: "right_part_body" }
const _hoisted_4 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconArrow = _resolveComponent("IconArrow")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "left_part" }, [
      _createElementVNode("div", { class: "image_top" }),
      _createElementVNode("div", { class: "good_company" }, [
        _createElementVNode("h1", null, "Working with the best"),
        _createElementVNode("p", null, "Companies using Foodstuff already for workplace ordering")
      ]),
      _createElementVNode("div", { class: "image_bottom" })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_button, {
        white: "",
        class: "go_back_desktop",
        onClick: _withModifiers(_ctx.goBack, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconArrow, { width: "21" })
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "image_top" }, null, -1)),
        _createVNode(_component_app_button, {
          white: "",
          class: "go_back_mobile",
          onClick: _withModifiers(_ctx.goBack, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconArrow, { width: "21" })
          ]),
          _: 1
        }, 8, ["onClick"]),
        _cache[4] || (_cache[4] = _createStaticVNode("<h1 data-v-7117650e>Your slot deposit is £120. You get:</h1><div class=\"benefits\" data-v-7117650e><div class=\"benefit\" data-v-7117650e><img src=\"" + _imports_0 + "\" alt=\"\" data-v-7117650e><p data-v-7117650e><strong data-v-7117650e>Exclusivity</strong> on all chosen delivery slots</p></div><div class=\"benefit\" data-v-7117650e><img src=\"" + _imports_0 + "\" alt=\"\" data-v-7117650e><p data-v-7117650e>A digital marketplace with <strong data-v-7117650e>existing and new suppliers</strong> in one place</p></div><div class=\"benefit\" data-v-7117650e><img src=\"" + _imports_0 + "\" alt=\"\" data-v-7117650e><p data-v-7117650e><strong data-v-7117650e>A dedicated account manager</strong> for all catering needs</p></div><div class=\"benefit\" data-v-7117650e><img src=\"" + _imports_0 + "\" alt=\"\" data-v-7117650e><p data-v-7117650e>Invite more suppliers, place orders and <strong data-v-7117650e>request event recommendations</strong></p></div></div>", 2)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_app_button, {
            class: "chat_button",
            onClick: _ctx.chatWithUs
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Need to chat to our team?")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_app_button, { onClick: _ctx.goToPaymentPage }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("b", null, "Continue", -1)
            ])),
            _: 1
          }, 8, ["onClick"]),
          _cache[2] || (_cache[2] = _createElementVNode("p", null, [
            _createElementVNode("strong", null, "One off payment."),
            _createTextVNode(" Deposit refunded after your first order.")
          ], -1))
        ])
      ])
    ])
  ]))
}