<template>
  <div class="customer-support-wrapper">
    <div class="empty" />
    <div class="customer-support-info">
      <p><b>Need help?</b></p>
      <h1>Customer Support</h1>
      <div class="customer-support-button" @click.prevent="launchChat">Launch chat</div>
    </div>
  </div>
</template>

<script lang="ts">
import { useSeoMeta } from '@unhead/vue';
import { Vue } from 'vue-class-component';

export default class CustomerSupport extends Vue {
  created() {
    useSeoMeta({ robots: 'noindex' });
  }

  private launchChat() {
    window.fcWidget?.user.setProperties({ firstName: 'CUSTOMER_SUPPORT' });
    window.fcWidget?.setTags(['customer-support-bot']);
    window.fcWidget?.open();
  }
}
</script>

<style lang="scss" scoped>
.customer-support {
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: 100vh;
    padding: 30px;
    background: $coal100;
    overflow: hidden;
    p {
      color: $ham;
      margin-bottom: 15px;
    }
    h1 {
      color: $vanilla;
      margin-bottom: 40px;
    }
  }
  &-button {
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
    background: white;
    border-radius: 12px;
    font-family: Recoleta, sans-serif;
    font-weight: 600;
    cursor: pointer;
  }
}
</style>
