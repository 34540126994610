import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "white-space"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.restaurant && _ctx.message && _ctx.type === 'delivery')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.message && _ctx.type === 'delivery')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["delivery-banner", { withMargin: !_ctx.restaurant }])
        }, [
          _createElementVNode("p", null, _toDisplayString(_ctx.message), 1)
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}