<template>
  <app-modal class="error-modal" v-bind="options">
    <div v-if="data" class="modal">
      <h2 class="modal-title">Notification</h2>
      <p class="modal-title">
        Your <strong>{{ data.restaurantName }}</strong> basket has been updated due to changes to the menu.
      </p>
      <div class="modal-line" />
      <div class="modal-products">
        <div class="modal-products-row" v-for="(product, index) in data.removedProducts" :key="product.name">
          <div class="modal-products-name">
            <span>{{ index + 1 }}</span>
            <span>{{ product.name }}</span>
          </div>
          <div class="modal-products-price">
            <span class="modal-products-old-price" v-if="product.oldPrice">{{ product.oldPrice }}£</span>
            <span class="modal-products-new-price" v-if="product.price">{{ product.price }}£</span>
          </div>
          <span class="modal-products-new-price" v-if="!product.price && !product.oldPrice">{{ product.reason }}</span>
        </div>
      </div>
      <div class="modal-actions">
        <app-button @click="gotoRestaurantPage()">
          <strong>Back to menu</strong>
        </app-button>
        <app-button @click="closeDialog()">
          <strong>Okay</strong>
        </app-button>
      </div>
    </div>
  </app-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { ModalInterface } from '@/shared/controllers/app-modal.vue';

@Options({
  computed: {
    ...mapState('modals', ['data']),
  },
})
export default class AppModalBasketUpdate extends Vue {
  private options: ModalInterface = {
    slim: true,
    close: true,
  };

  private gotoRestaurantPage() {
    const restaurantRoute = this.$router.currentRoute.value.path.substring(
      0,
      this.$router.currentRoute.value.path.lastIndexOf('/'),
    );
    this.$router.push(restaurantRoute);
  }

  private closeDialog() {
    this.$store.commit('modals/close');
  }
}
</script>

<style lang="scss">
.error-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  padding: 10px;
}

.modal-title {
  text-align: center;
}

.modal-actions {
  display: flex;
  gap: 10px;
}

.modal-line {
  height: 1px;
  background-color: $coal60;
  width: 55%;
  margin: 20px auto;
}

.modal-products {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0px 5px;
  color: $coal80;
  margin-bottom: 20px;

  &-row {
    display: flex;
    justify-content: space-between;
  }

  &-name {
    display: flex;
    gap: 10px;
  }

  &-price {
    display: flex;
    justify-content: space-evenly;
    gap: 0.5rem;
    width: 20%;
  }
  &-new-price {
    font-weight: bold;
  }
  &-old-price {
    text-decoration: line-through;
  }
}
</style>
