const other: Other = {
  social: {
    facebook: 'https://www.facebook.com/foodstuffcambs',
    instagram: 'https://www.instagram.com/foodstuffcambs/',
  },
  vendor: {
    login: 'https://admin.wearefoodstuff.co.uk/',
    applyToJoin: 'https://www.fountain.com/urb-it/apply/foodstuff-cambridge?preview=true',
  },
  foodstuff: {
    careers: 'https://foodstuff.homerun.co/',
  },
};

export default other;

export interface Other {
  social: {
    facebook: string;
    instagram: string;
  };
  vendor: {
    login: string;
    applyToJoin: string;
  };
  foodstuff: {
    careers: string;
  };
}
