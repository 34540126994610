<template>
  <hr />

  <div class="card">
    <div class="card-element-item number">
      <span :class="{ invalid: cardNumber && cardNumber.error && showError }">Card number</span>
      <div id="card-number" class="card-element" />
      <small v-if="cardNumber && cardNumber.error && showError">{{ cardNumber.error.message }}</small>
    </div>

    <div class="card-element-item cvc">
      <span :class="{ invalid: cardCvc && cardCvc.error && showError }">CVC</span>
      <div id="card-cvc" class="card-element" />
      <small v-if="cardCvc && cardCvc.error && showError">{{ cardCvc.error.message }}</small>
    </div>

    <div class="card-element-item expiry">
      <span :class="{ invalid: cardExpiry && cardExpiry.error && showError }">Expiry date</span>
      <div id="card-expiry" class="card-element" />
      <small v-if="cardExpiry && cardExpiry.error && showError">{{ cardExpiry.error.message }}</small>
    </div>
  </div>
</template>

<script lang="ts">
import { StripeCardCvcElement, StripeCardExpiryElement, StripeCardNumberElement } from '@stripe/stripe-js';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import instanced from './stripe-custom-elements';

@Options({
  computed: {
    ...mapState('stripe', ['cardNumber', 'cardCvc', 'cardExpiry', 'showError']),
  },
  emits: {
    focus: Boolean,
  },
})
export default class AddPaymentMethod extends Vue {
  private focus() {
    this.$emit('focus', true);
  }

  private pushError(path: string, msg: string) {
    this.$store.commit('yup/clear');

    this.$store.commit('yup/errors', { path, msg });

    this.$store.commit('yup/toggleShowError', true);
  }

  mounted() {
    instanced.create().then(() => {
      if (instanced.elements) {
        const cardNumber: StripeCardNumberElement = instanced.elements.getElement(
          'cardNumber',
        ) as StripeCardNumberElement;
        cardNumber.on('focus', () => {
          this.focus();
        });
        cardNumber.on('change', (event: any) => {
          if (event.error) {
            this.pushError('cardNumber', event.error.message);
          }
        });

        const cardExpiry: StripeCardExpiryElement = instanced.elements.getElement(
          'cardExpiry',
        ) as StripeCardExpiryElement;
        cardExpiry.on('focus', () => {
          this.focus();
        });
        cardExpiry.on('change', (event: any) => {
          if (event.error) {
            this.pushError('cardExpiry', event.error.message);
          }
        });

        const cardCvc: StripeCardCvcElement = instanced.elements.getElement('cardCvc') as StripeCardCvcElement;
        cardCvc.on('focus', () => {
          this.focus();
        });
        cardCvc.on('change', (event: any) => {
          if (event.error) {
            this.pushError('cardCvc', event.error.message);
          }
        });
      }
    });
  }

  created() {
    this.$store.commit('stripe/error', false);
  }
}
</script>

<style lang="scss" scoped>
hr {
  margin-bottom: 15px;
}

.card {
  width: 100%;
  margin-bottom: 15px;
  display: grid;
  gap: 10px 15px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'card-number card-number'
    'card-cvc card-expiry';

  &-element {
    text-align: center;
    border: 15px;
    border: 1px solid $coal20;
    border-radius: 15px;
    padding: 10px;
    width: 100%;
    &.focus {
      border: 3px solid $ham;
    }

    &-item {
      span {
        display: inline-block;
        margin-bottom: 10px;
        &.invalid {
          color: $error;
        }
      }

      small {
        color: $error;
      }

      &.cvc {
        grid-area: card-cvc;
      }
      &.number {
        grid-area: card-number;
      }
      &.expiry {
        grid-area: card-expiry;
      }
    }
  }
}
</style>
