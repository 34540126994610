import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rider-support-wrapper" }
const _hoisted_2 = { class: "rider-support-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "empty" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("p", null, [
        _createElementVNode("b", null, "Need help?")
      ], -1)),
      _cache[2] || (_cache[2] = _createElementVNode("h1", null, "Rider Support", -1)),
      _createElementVNode("div", {
        class: "rider-support-button",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.launchChat && _ctx.launchChat(...args)), ["prevent"]))
      }, "Launch chat")
    ])
  ]))
}