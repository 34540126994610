import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/users.svg'


const _hoisted_1 = {
  key: 0,
  class: "group-order-banner"
}
const _hoisted_2 = { class: "group-order-banner__content" }
const _hoisted_3 = { class: "group-order-banner__icon-and-text-wrapper" }
const _hoisted_4 = { class: "group-order-banner__text-wrapper" }
const _hoisted_5 = { class: "group-order-banner__name" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "group-order-banner__buttons-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!

  return (_ctx.groupData && !_ctx.amendableData && (!_ctx.groupOrderIdFromUrl || _ctx.groupOrderIdFromUrl === _ctx.groupData.id))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "group-order-banner__icon-wrapper" }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: ""
              })
            ], -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(`${_ctx.addApostrophe(_ctx.groupData.ownerName)} group order`), 1),
              (_ctx.itemsText)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "group-order-banner__details",
                    innerHTML: _ctx.itemsText
                  }, null, 8, _hoisted_6))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            (_ctx.groupRole === 'Owner')
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 0,
                  class: "group-order-banner__button",
                  onClick: _withModifiers(_ctx.leaveGroupOrder, ["prevent"])
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" Cancel group order ")
                  ])),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.groupRole === 'Owner')
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 1,
                  class: "group-order-banner__button",
                  onClick: _withModifiers(_ctx.copyLinkToClipboard, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.copyText), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.groupRole === 'Participant')
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 2,
                  class: "group-order-banner__button",
                  onClick: _withModifiers(_ctx.leaveGroupOrder, ["prevent"])
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Remove your items ")
                  ])),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}