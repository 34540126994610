<template>
  <div v-if="!restaurantData" class="loader restaurant-loader">
    <Logo class="logo-loader" />
  </div>
  <template v-else>
    <!-- Header -->
    <Mobile v-if="isMobile" :restaurantData="restaurantData" />
    <Desktop v-else :restaurant="restaurantData.restaurant" />

    <!-- Body -->
    <router-view :restaurantData="restaurantData" :updating="updating" />
  </template>
</template>

<script lang="ts">
import { useSeoMeta } from '@unhead/vue';
import moment from 'moment-timezone';
import { watch } from 'vue';
import { Options, Vue } from 'vue-class-component';

import Desktop from '@/components/Restaurant/Header/Desktop.vue';
import Mobile from '@/components/Restaurant/Header/Mobile.vue';

import groupOrdersHttp from '@/requests/groupOrders';
import ordersHttp from '@/requests/orders';
import restaurantHttp from '@/requests/restaurant';
import restaurantsHttp from '@/requests/restaurants';

import Logo from '@/shared/icons/Logo.vue';

import { StateGroupOrder } from '@/store/modules/groupOrder';

import methods from '@/utils/methods';

@Options({
  components: {
    Desktop,
    Mobile,
    Logo,
  },
})
export default class RestaurantPage extends Vue {
  public restaurantData: RequestGetRestaurant | null = null;
  private updating = false; // fetching restaurant or refreshing menu
  private interval = -1; // timer for refreshing group orders

  private isMobile = false; // UI stuff
  private updateBasketAfterFirstFetch = false; // prefilled baskets' helper

  private async getRestaurant() {
    try {
      const restaurantSlug = this.$route.params.slug as string;
      this.updating = true;
      let query = '';

      // Building queryStrings: START
      const { lng, lat } = this.$store.state.address;
      if (lng && lat) query += `&lng=${lng}&lat=${lat}`;

      const deliverToTimestamp = this.$store.getters['order/scheduledDeliveryTimestamp'];
      if (deliverToTimestamp) query += `&deliverToTime=${deliverToTimestamp}`;

      const basketRestaurant = this.$store.state.basket.restaurant;
      if (basketRestaurant?.slug === restaurantSlug) {
        const restaurantId = basketRestaurant.id as number;
        query += `&restaurantId=${restaurantId}`;
      }

      const limitsQuery = query.slice(0);

      const { user } = this.$store.state.service;
      if (user && user.customId) query += `&userId=${user.customId}`;

      const isCollection = this.$store.state.order.type === 'collection';
      if (isCollection) query += `&collect=${isCollection}`;

      if (!lat && !lng) query += `&wholeCity=true`;
      // Building queryStrings: STOP

      // Fetching restaurant: START
      const [mainRes, limitsRes] = await Promise.all([
        restaurantHttp.getRestaurant(restaurantSlug, `?${query.slice(1)}`),
        restaurantHttp.getRestaurantCapacityLimits(restaurantSlug, `?${limitsQuery.slice(1)}`),
      ]);
      mainRes.restaurant.weekCapacityLimits = limitsRes.restaurant.weekCapacityLimits;
      // Fetching restaurant: STOP

      // Fixing fees: START
      const { deliveryInfo } = mainRes.restaurant;
      deliveryInfo.fee = +deliveryInfo.fee.toFixed(2);
      deliveryInfo.initialFee = +deliveryInfo.initialFee.toFixed(2);
      deliveryInfo.bigOrderFee = +deliveryInfo.bigOrderFee.toFixed(2);
      deliveryInfo.bigOrderInitialFee = +deliveryInfo.bigOrderInitialFee.toFixed(2);
      deliveryInfo.deliveryFeeBreakpoint = +deliveryInfo.deliveryFeeBreakpoint.toFixed(2);
      // Fixing fees: STOP

      // Validating location: START
      const { groupData, groupRole } = this.$store.state.groupOrder;
      if (groupData && groupRole === 'Participant') {
        this.$store.commit('basket/isTooFar', false);
      } else if (lng && lat) {
        const rangeRes = await restaurantsHttp.checkInRange(
          mainRes.restaurant.restaurantCity,
          { lng, lat },
          mainRes.restaurant.slug,
          isCollection,
        );
        this.$store.commit('basket/isTooFar', !rangeRes?.success);
      } else {
        this.$store.commit('basket/isTooFar', false);
      }
      // Validating location: STOP

      // Fixing url: START
      if (mainRes.restaurant.slug !== restaurantSlug) {
        let correctUrl = `/menu/${mainRes.restaurant.restaurantCity}/${mainRes.restaurant.slug}`;
        if (this.$route.query.groupId) correctUrl += `?groupId=${this.$route.query.groupId}`;

        this.$store.commit('basket/restaurant', methods.filterRestaurantInfo(mainRes.restaurant));
        this.$router.replace(correctUrl);
        return;
      }
      // Fixing url: STOP

      // Updating order type, chosen date and coupon: START
      let hasTypeOrDateBeenChanged = false;
      if (!mainRes.restaurant.clickAndCollect && this.$store.state.order.type === 'collection') {
        this.$store.commit('order/type', 'delivery');
        this.$store.commit('basket/coupon', null);
        hasTypeOrDateBeenChanged = true;
      }
      if (!mainRes.restaurant.allowScheduledOrders && this.$store.state.order.scheduledDeliveryDate) {
        this.$store.commit('order/scheduledDeliveryInterval', null);
        this.$store.commit('order/scheduledDeliveryDate', null);
        hasTypeOrDateBeenChanged = true;
      }
      if (hasTypeOrDateBeenChanged) return;
      // Updating order type, chosen date and coupon: STOP

      // Make sure restaurant is treated as open if amending an order:
      const isAmendingOrder = !!this.$store.state.amendableOrder.data;
      if (isAmendingOrder) {
        mainRes.restaurant.isClosed = false;
        mainRes.restaurant.isTemporarilyClose = false;
      }

      this.restaurantData = mainRes;
      this.updating = false;
    } catch (e) {
      this.$router.push('/oops');
    }
  }

  private async updateBasketRestaurantData() {
    const { restaurant, dishes } = this.$store.state.basket;
    const restaurantSlug = this.$route.params.slug as string;

    if (restaurant && restaurant.slug === restaurantSlug && dishes.length) {
      await this.getRestaurant();

      if (this.restaurantData) {
        this.$store.commit('basket/restaurant', methods.filterRestaurantInfo(this.restaurantData.restaurant));
      }
    }
  }

  private async handleGroupOrders() {
    const isAmendingOrder = !!this.$store.state.amendableOrder.data;
    if (isAmendingOrder) return;

    // GROUP OWNER SCENARIO: START
    if (this.$store.state.service.user) {
      const groupData = await groupOrdersHttp.getMyGroupOrder();
      if (groupData) {
        this.$store.commit('groupOrder/reset');
        this.$store.commit('groupOrder/update', { ...groupData });
        this.$store.commit('groupOrder/setRole', 'Owner');

        if (groupData.restaurantSlug !== this.$route.params.slug) {
          this.$router.push(`/restaurants/${groupData.ownerAddress.cityName.toLowerCase()}`);
          this.$store.commit('modals/show', 'activeOrderWarning');
        } else {
          methods.overrideBasketAndOrderData(groupData);
        }
        return;
      }
    }
    // GROUP OWNER SCENARIO: STOP

    // GROUP PARTICIPANT SCENARIO: START
    const groupOrderIdFromUrl = this.$route.query.groupId as string;
    const groupOrderState = this.$store.state.groupOrder;
    if (groupOrderState.groupData) {
      let groupOrder: StateGroupOrder['groupData'] | null = null;

      try {
        groupOrder = await groupOrdersHttp.getGroupOrder(groupOrderState.groupData.id);
      } catch (_) {}

      // Reset state if group order is deleted or inactive:
      if (!groupOrder) {
        this.$store.commit('groupOrder/reset');
      }

      // If opened URL while being in other group order, redirect to /restaurants,
      // BUT only if user hasn't confirmed the basket yet:
      else if (groupOrderIdFromUrl && groupOrderIdFromUrl !== groupOrder.id) {
        if (!groupOrderState.participantBasketId) {
          this.$router.push(`/restaurants/${groupOrder.ownerAddress.cityName.toLowerCase()}`);
          this.$store.commit('modals/show', 'activeOrderWarning');
          return;
        }

        const participantBasket = groupOrder.baskets.find((b) => b.id === groupOrderState.participantBasketId);
        if (!participantBasket) {
          // User's basket does not exist -> remove data from local state:
          this.$store.commit('groupOrder/reset');
          this.$store.commit('basket/clear');
        }
      }

      // If opened URL with the same group order id as active one, just update state:
      else {
        this.$store.commit('groupOrder/update', { ...groupOrder });
        methods.overrideBasketAndOrderData(groupOrder);
        return;
      }
    }

    // Basing on id from url, get group order data and give possibility to join:
    if (groupOrderIdFromUrl) {
      try {
        const newGroupOrder = await groupOrdersHttp.getGroupOrder(groupOrderIdFromUrl);
        if (!newGroupOrder) throw new Error();

        const confirmedBasketId = groupOrderState.participantBasketHistory.find(
          (rel) => rel.groupOrderId === groupOrderIdFromUrl,
        )?.groupBasketId;
        const confirmedBasket = confirmedBasketId
          ? newGroupOrder.baskets.find((gb) => gb.id === confirmedBasketId)
          : null;
        if (confirmedBasket) {
          this.$store.commit('groupOrder/reset');
          this.$store.commit('groupOrder/update', newGroupOrder);
          this.$store.commit('groupOrder/setRole', 'Participant');
          this.$store.commit('groupOrder/setParticipantName', confirmedBasket.userName);
          this.$store.commit('groupOrder/setParticipantBasketId', confirmedBasketId);
          this.$store.commit('groupOrder/itemsConfirmed');
          methods.overrideBasketAndOrderData(newGroupOrder);
        } else {
          this.$store.commit('modals/data', { groupOrder: newGroupOrder });
          this.$store.commit('modals/show', 'joinGroupOrder');
        }
      } catch (_) {
        this.$store.commit('groupOrder/reset');
        this.$store.commit('modals/show', 'groupOrderNotAvailable');
      }
    }
    // GROUP PARTICIPANT SCENARIO: STOP
  }

  private async refreshMyGroupOrder() {
    const isAmendingOrder = !!this.$store.state.amendableOrder.data;
    if (isAmendingOrder) return;

    const groupOrder = await groupOrdersHttp.getMyGroupOrder();
    if (groupOrder) {
      this.$store.commit('groupOrder/update', { ...groupOrder });
    } else {
      this.$store.commit('groupOrder/reset');
    }
  }

  private async handlePrefilledBaskets() {
    const { amendableOrder, groupOrder } = this.$store.state;
    if (amendableOrder.data || groupOrder.groupData) return;

    const prefilledBasketIdFromUrl = this.$route.query.prefilledBasketId as string;
    if (prefilledBasketIdFromUrl) {
      const { address, cart, orderType, bestDeliverToTime, worstDeliverToTime } =
        await ordersHttp.getPrefilledBasket(prefilledBasketIdFromUrl);
      this.$store.commit('address/set', address);
      this.$store.commit('basket/setDishes', cart.dishes);
      this.$store.commit('order/type', orderType);
      if (bestDeliverToTime && worstDeliverToTime) {
        const leftEnd = moment(bestDeliverToTime).tz('Europe/London').format('HH:mm');
        const rightEnd = moment(worstDeliverToTime).tz('Europe/London').format('HH:mm');
        this.$store.commit('order/scheduledDeliveryDate', bestDeliverToTime);
        this.$store.commit('order/scheduledDeliveryInterval', `${leftEnd} - ${rightEnd}`);
      } else {
        this.$store.commit('order/scheduledDeliveryDate', null);
        this.$store.commit('order/scheduledDeliveryInterval', null);
      }

      this.$router.replace(this.$route.path); // remove query param
      this.updateBasketAfterFirstFetch = true;
    }
  }

  private updateWidth() {
    this.isMobile = window.innerWidth <= 1024;
  }

  created() {
    // Set up metatags:
    const restaurantName = this.$route.params.nameFromResponse as string;
    const restaurantCity = this.$route.params.cityFromResponse as string;
    if (restaurantName && restaurantCity) {
      useSeoMeta({
        title: `${restaurantName} delivery in ${restaurantCity}`,
        ogTitle: `${restaurantName} delivery in ${restaurantCity}`,
        twitterTitle: `${restaurantName} delivery in ${restaurantCity}`,
        description:
          `Order ${restaurantName} delivery in ${restaurantCity}. ` +
          'Browse their menu, order your favourite dishes, and track the delivery to your door.',
        ogDescription:
          `Order ${restaurantName} delivery in ${restaurantCity}. ` +
          'Browse their menu, order your favourite dishes, and track the delivery to your door.',
        twitterDescription:
          `Order ${restaurantName} delivery in ${restaurantCity}. ` +
          'Browse their menu, order your favourite dishes, and track the delivery to your door.',
      });
    }

    (async () => {
      if (!this.$route.query.embedded) {
        await this.handleGroupOrders();
        await this.handlePrefilledBaskets();
      }

      await this.getRestaurant();
      await this.updateBasketRestaurantData();

      // NOTE: handlePrefilledBaskets is not setting restaurant data in local storage,
      // so updateBasketRestaurantData method is not executed for prefilled baskets
      // and the lines presented below are needed:
      if (this.updateBasketAfterFirstFetch && this.restaurantData) {
        this.$store.commit('basket/restaurant', methods.filterRestaurantInfo(this.restaurantData.restaurant));
        this.updateBasketAfterFirstFetch = false;
      }
    })();

    // Re-fetch restaurant if slug, order type, time or address has changed:
    watch(
      () => [
        this.$route.params.slug,
        this.$store.state.order.type,
        this.$store.state.order.scheduledDeliveryInterval,
        this.$store.state.order.scheduledDeliveryDate,
        this.$store.state.address.formattedAddress,
      ],
      () => {
        this.getRestaurant();
      },
    );

    watch(
      () => [
        this.$store.state.service.user,
        this.$store.state.order.type,
        this.$store.state.order.scheduledDeliveryInterval,
        this.$store.state.order.scheduledDeliveryDate,
      ],
      () => {
        this.updateBasketRestaurantData();
      },
    );
  }

  mounted() {
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);

    // Refresh group order baskets every 5s (as group owner): START
    watch(
      () => [this.$store.state.groupOrder.groupData],
      () => {
        if (this.$store.state.groupOrder.groupData && this.$store.state.groupOrder.groupRole === 'Owner') {
          if (this.interval === -1) {
            this.interval = setInterval(this.refreshMyGroupOrder, 5000);
          }
        } else {
          clearInterval(this.interval);
          this.interval = -1;
        }
      },
    );

    if (this.$store.state.groupOrder.groupData && this.$store.state.groupOrder.groupRole === 'Owner') {
      if (this.interval === -1) {
        this.interval = setInterval(this.refreshMyGroupOrder, 5000);
      }
    }
    // Refresh group order baskets every 5s (as group owner): STOP
  }

  unmounted() {
    if (this.interval !== -1) {
      clearInterval(this.interval);
      this.interval = -1;
    }
  }
}
</script>

<style lang="scss">
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 55px - 35px);
  width: 100%;

  .logo {
    transform: scale(2);
  }

  @include for-smartphone {
    height: calc(100vh - 35px);
  }
}
</style>
