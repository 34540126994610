<template>
  <component
    :is="tag"
    :href="href"
    class="controller-button"
    :class="{
      active,
      anchor,
      mini,
      slim,
      white,
      square,
    }"
    :disabled="disabled || loader"
  >
    <slot v-if="!loader" />
    <Logo class="logo-loader" v-if="loader" />
  </component>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import Logo from '@/shared/icons/Logo.vue';

@Options({
  components: {
    Logo,
  },
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    href: String,
    color: String,
    mini: Boolean,
    anchor: Boolean,
    active: Boolean,
    disabled: Boolean,
    white: Boolean,
    loader: Boolean,
    slim: Boolean,
    square: Boolean,
  },
})
export default class AppControllerButton extends Vue {}
</script>

<style lang="scss">
.controller-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 21px;
  min-height: 60px;
  background-color: $ham;
  max-width: 345px;
  width: 100%;
  border-radius: 15px;
  padding: 0 10px;
  white-space: nowrap;
  transition: $trs;
  position: relative;
  user-select: none;
  text-decoration: none;
  @include p2Bold;

  strong {
    @include p2Bold;
  }

  span,
  p,
  ins {
    @include p2;
    color: #000000;
  }

  &:hover {
    background-color: $roastHam;
  }

  &:disabled {
    color: $coal60;
    cursor: not-allowed;
    background-color: $snow;
    border: 1px solid $coal20;
  }

  &.white {
    background-color: $white;
    &:hover {
      background-color: $snow;
    }
  }

  &.slim {
    width: auto;
    min-height: 40px;
    border-radius: 45px;
    padding: 12.5px 25px;
    .logo {
      svg {
        max-height: 21px;
      }
    }
  }

  &.mini {
    min-height: 40px;
    max-height: 40px;
    max-width: 40px;
    min-width: 40px;
    border-radius: 10px;

    * {
      position: relative;
      z-index: 1;
    }

    svg {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &:disabled {
      border: none;
    }
  }

  &.square {
    border-radius: 5px;
  }

  &.anchor {
    padding: 0 15px;
    min-height: 30px;
    width: auto;
    background: none;
    &:hover {
      @include for-desktop {
        background-color: $ham;
      }
    }
    &.active {
      background-color: $roastHam;
    }
    &:disabled {
      border: none;
    }
  }
}

.logo-loader {
  margin-left: auto;
  margin-right: auto;
}
</style>
