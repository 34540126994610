import axios from 'axios';
import { getAuth } from 'firebase/auth';

import environment from '@/environment';

class Http {
  public service: any;
  public token: any;
  public isLoggedIn = true;

  constructor() {
    this.setAxios();
  }

  public async post(path: string, payload: any): Promise<any> {
    await this.setAxios();

    return this.service
      .post(path, payload)
      .then((res: Response) => this.handleSuccess(res))
      .catch((err: Error) => this.handleError(err));
  }

  public async get(path: string): Promise<any> {
    await this.setAxios();

    const config = path.startsWith('/accounts/invoice/') ? { responseType: 'arraybuffer' } : null;
    return this.service
      .get(path, config)
      .then((res: Response) => this.handleSuccess(res))
      .catch((err: Error) => this.handleError(err));
  }

  public async put(path: string, payload: any): Promise<any> {
    await this.setAxios();

    return this.service
      .put(path, payload)
      .then((res: Response) => this.handleSuccess(res))
      .catch((err: Error) => this.handleError(err));
  }

  public async delete(path: string): Promise<any> {
    await this.setAxios();

    return this.service
      .delete(path)
      .then((res: Response) => this.handleSuccess(res))
      .catch((err: any) => this.handleError(err));
  }

  private handleSuccess(res: any): any {
    return res.data;
  }

  private handleError(err: any): any {
    // Hotfix for checkoutValidation:
    if (err.response?.data?.error === 'The cart contains unavailable dishes or modifiers') throw err;

    throw (
      err.response?.data?.error ||
      err.response?.data?.errorCode ||
      err.response?.data ||
      'Something has gone wrong. Please refresh page or contact support'
    );
  }

  public async setAxios() {
    // HOTFIX for loading Firebase App too late:
    for (let i = 0; i < 4; i += 1) {
      try {
        this.token = (await getAuth().currentUser?.getIdToken()) ?? '';
      } catch (_) {
        this.token = '';
      }

      if (!this.isLoggedIn || this.token !== '') break;
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    this.service = axios.create({
      baseURL: environment.baseApiUrl,
      headers: {
        authorizationfirebase: `${this.token}`,
        platform: 'web',
      },
    });
  }
}

export default new Http();
