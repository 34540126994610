<template>
  <GtmStep0 v-if="step === 0" />

  <GtmStep0Plus v-else-if="step === 0.5" :initPreFlow="initPreFlow" />

  <GtmStep1To2
    v-else-if="step === 1 || step === 2"
    :exit="exit"
    :chatWithUs="chatWithUs"
    :areHeaderAndFooterLoaded="areHeaderAndFooterLoaded"
    :isBodyLoaded="isBodyLoaded"
  />

  <GtmStep2Plus v-else-if="step === 2.5" :initFlow="initFlow" />

  <GtmStep3To8
    v-else-if="step >= 3 && step <= 8"
    :exit="exit"
    :chatWithUs="chatWithUs"
    :bookSlot="bookSlot"
    :transitionToNextStep="transitionToNextStep"
    :isBookingSlot="isBookingSlot"
    :areHeaderAndFooterLoaded="areHeaderAndFooterLoaded"
    :isBodyLoaded="isBodyLoaded"
    :isTransitioning="isTransitioning"
  />

  <GtmStep9 v-else-if="step === 9" :chatWithUs="chatWithUs" />

  <!-- STEP 10: MAKING A PAYMENT - EXTERNAL URL --->

  <GtmStep11 v-else-if="step === 11" :chatWithUs="chatWithUs" />

  <Transition name="step_transition_slide">
    <div v-if="isTransitioning" class="step_transition" :class="{ yellow: step <= 1 }">
      <Transition name="fade">
        <h1 v-if="transitionLabel">{{ transitionLabel }}</h1>
      </Transition>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useSeoMeta } from '@unhead/vue';
import moment from 'moment-timezone';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import GtmStep0 from '@/components/GTM/GtmStep0.vue';
import GtmStep0Plus from '@/components/GTM/GtmStep0Plus.vue';
import GtmStep1To2 from '@/components/GTM/GtmStep1To2.vue';
import GtmStep2Plus from '@/components/GTM/GtmStep2Plus.vue';
import GtmStep3To8 from '@/components/GTM/GtmStep3To8.vue';
import GtmStep9 from '@/components/GTM/GtmStep9.vue';
import GtmStep11 from '@/components/GTM/GtmStep11.vue';

import http from '@/utils/http';

@Options({
  components: {
    GtmStep0,
    GtmStep0Plus,
    GtmStep1To2,
    GtmStep2Plus,
    GtmStep3To8,
    GtmStep9,
    GtmStep11,
  },
  computed: {
    ...mapState('gtm', ['step']),
  },
})
export default class GTM extends Vue {
  // Transitions: START
  private areHeaderAndFooterLoaded = false;
  private isBodyLoaded = false;
  private isTransitioning = false;
  private transitionLabel = '';

  private async initPreFlow() {
    this.transitionToNextStep(1, "Let's grab some\ncompany details.");
  }

  private async initFlow() {
    await this.refreshBookedSlotsCount();
    await this.transitionToNextStep(3, "Great, let's get started.");
  }

  private async transitionToNextStep(step: number, label: string) {
    if (step !== 3) {
      // Show transition page:
      this.isTransitioning = true;
      await this.wait(1000);
      this.transitionLabel = label;
      this.areHeaderAndFooterLoaded = false;
      this.isBodyLoaded = false;
      await this.wait(1500);

      // Hide transition page:
      this.isTransitioning = false;
      this.transitionLabel = '';
      this.$store.commit('gtm/setStep', step);
      await this.wait(1000);
    } else {
      // Ensure nothing is displayed for first 0.2s:
      this.areHeaderAndFooterLoaded = false;
      this.isBodyLoaded = false;
      this.isTransitioning = false;
      this.transitionLabel = '';
      this.$store.commit('gtm/setStep', step);
      await this.wait(200);
    }

    // Show navigation buttons:
    this.areHeaderAndFooterLoaded = true;
    await this.wait(1000);

    // Show step's content and animate progress bar:
    this.isBodyLoaded = true;
  }
  // Transitions: STOP

  // Core logic: START
  private isBookingSlot = false;

  private async refreshBookedSlotsCount() {
    const { email } = this.$store.state.gtm;

    // Reset counter if email was not provided:
    if (!email) {
      this.$store.commit('gtm/setPaidBookedSlotsAmount', 0);
      return;
    }

    // Fetch amount from API/DB if email was provided:
    try {
      const count = await http.get(encodeURI(`/booked-slots/count?email=${email}`));
      this.$store.commit('gtm/setPaidBookedSlotsAmount', count);
    } catch (err) {
      console.log(err);
    }
  }

  private async bookSlot() {
    try {
      this.isBookingSlot = true;

      const { gtm } = this.$store.state;
      const [hours, minutes] = moment(gtm.selectedSlot, 'h:mm A')
        .format('HH:mm')
        .split(':')
        .map((e) => parseInt(e, 10));
      const bestDeliverToTime = moment(gtm.dateTime).tz('Europe/London').set({ hours, minutes }).toISOString();
      const typesOfFood = [...gtm.typesOfFood, gtm.otherTypesOfFood].filter((e) => e).join(',') || null;
      const typesOfPresentationStyles = gtm.typesOfPresentationStyles.join(',') || null;
      const typesOfAllergens = [...gtm.typesOfAllergens, gtm.otherTypesOfAllergens].filter((e) => e).join(',') || null;

      const { id } = await http.post('/booked-slots', {
        address: gtm.address,
        bestDeliverToTime,
        budget: gtm.budget,
        email: gtm.email,
        existingBookedSlotId: gtm.bookedSlotId,
        headcount: gtm.headcount,
        typesOfAllergens,
        typesOfFood,
        typesOfPresentationStyles,
      });

      if (!gtm.paidBookedSlotsAmount) {
        this.$store.commit('gtm/setBookedSlotId', id);
        this.$store.commit('gtm/setStep', 9);
      } else {
        this.$store.commit('gtm/setStep', 11);
        this.$store.commit('gtm/setPaidBookedSlotsAmount', this.$store.state.gtm.paidBookedSlotsAmount + 1);
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isBookingSlot = false;
    }
  }

  private wait = (ms: number) => new Promise((_) => setTimeout(_, ms));
  // Core logic: STOP

  // Commong logic: START
  private chatWithUs() {
    // Identify user:
    const { user } = this.$store.state.service;
    if (user) {
      const { firstName, email, phone } = user;
      window.fcWidget?.user.setProperties({
        firstName,
        email,
        externalId: email,
        phone,
      });
    } else {
      window.fcWidget?.user.setProperties({ firstName: 'GTM Flow' });
    }

    // Open chat:
    window.fcWidget?.setTags(['client-bot']);
    window.fcWidget?.open();
  }

  private exit() {
    this.$store.commit('gtm/clear');
  }

  created() {
    useSeoMeta({ robots: 'noindex' });

    if (this.$store.state.gtm.step > 0.5) {
      this.isBodyLoaded = true;
      this.areHeaderAndFooterLoaded = true;
    }

    // Move to step 11 after making a payment and increase number of paid booked slots in local storage:
    if (this.$route.query.success) {
      this.$store.commit('gtm/setStep', 11);
      this.$store.commit('gtm/setPaidBookedSlotsAmount', this.$store.state.gtm.paidBookedSlotsAmount + 1);
      this.$router.replace(this.$route.path); // remove query param
      return;
    }

    // Set booked slots counter (only if not went back from payment step):
    this.refreshBookedSlotsCount();
  }
  // Common logic: STOP
}
</script>

<style lang="scss" scoped>
.step_transition {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: $ham;
  padding: 0;

  &.yellow {
    background-color: #ffdf4d;
  }

  h1 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 45px;
    line-height: 50px;
    text-align: center;
    white-space: pre-line;

    @include for-smartphone {
      font-size: 25px;
      line-height: 30px;
    }
  }
}

// ANIMATIONS: START
.step_transition_slide-enter-active,
.step_transition_slide-leave-active {
  transition: all 0.5s;
}

.step_transition_slide-enter-from,
.step_transition_slide-leave-to {
  transform: translateX(-100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
// ANIMATIONS: STOP
</style>
