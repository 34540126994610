import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "global-footer" }
const _hoisted_2 = { class: "mobile-ticker" }
const _hoisted_3 = { class: "footer-info" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "mr-60" }
const _hoisted_7 = { class: "mb-2" }
const _hoisted_8 = { class: "mb-2" }
const _hoisted_9 = { class: "mb-2" }
const _hoisted_10 = { class: "mb-2" }
const _hoisted_11 = { class: "mb-2" }
const _hoisted_12 = { class: "mb-2" }
const _hoisted_13 = { class: "mb-2" }
const _hoisted_14 = { class: "mb-2" }
const _hoisted_15 = { class: "mb-2" }
const _hoisted_16 = { class: "mr-60" }
const _hoisted_17 = { class: "mb-2" }
const _hoisted_18 = ["href"]
const _hoisted_19 = { class: "mb-2" }
const _hoisted_20 = ["href"]
const _hoisted_21 = { class: "vendor-stuff mr-60" }
const _hoisted_22 = { class: "mb-2" }
const _hoisted_23 = ["href"]
const _hoisted_24 = { class: "mb-2" }
const _hoisted_25 = { class: "boring-stuff" }
const _hoisted_26 = { class: "mb-2" }
const _hoisted_27 = { class: "mb-2" }
const _hoisted_28 = { class: "mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WeSupportLocal = _resolveComponent("WeSupportLocal")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_WeSupportLocal, {
        color: 'ham',
        class: "sticker_mobile"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_link, {
          to: "/",
          "aria-label": "homepage"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Logo, {
              class: "long_logo",
              text: ""
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("ul", _hoisted_6, [
          _cache[9] || (_cache[9] = _createElementVNode("li", { class: "mb-4" }, [
            _createElementVNode("small", { class: "ws-nw footer-title" }, "Services")
          ], -1)),
          _createElementVNode("li", _hoisted_7, [
            _createVNode(_component_router_link, {
              class: "ws-nw td-n",
              to: "/cambridge/corporate-lunch-delivery"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" Corporate Lunch Delivery in Cambridge ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_8, [
            _createVNode(_component_router_link, {
              class: "ws-nw td-n",
              to: "/bristol/corporate-lunch-delivery"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" Corporate Lunch Delivery in Bristol ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_9, [
            _createVNode(_component_router_link, {
              class: "ws-nw td-n",
              to: "/cambridge/corporate-breakfast-delivery"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Corporate Breakfast Delivery in Cambridge ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_10, [
            _createVNode(_component_router_link, {
              class: "ws-nw td-n",
              to: "/bristol/corporate-breakfast-delivery"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Corporate Breakfast Delivery in Bristol ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_11, [
            _createVNode(_component_router_link, {
              class: "ws-nw td-n",
              to: "/cambridge/corporate-event-catering"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Corporate Event Catering in Cambridge ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_12, [
            _createVNode(_component_router_link, {
              class: "ws-nw td-n",
              to: "/bristol/corporate-event-catering"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Corporate Event Catering in Bristol ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_13, [
            _createVNode(_component_router_link, {
              class: "ws-nw td-n",
              to: "/cambridge/office-catering"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("Office Catering in Cambridge")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_14, [
            _createVNode(_component_router_link, {
              class: "ws-nw td-n",
              to: "/bristol/office-catering"
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Office Catering in Bristol")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_15, [
            _createVNode(_component_router_link, {
              class: "ws-nw td-n",
              to: "/bespoke-events"
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("Bespoke Events in Cambridge and Bristol")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("ul", _hoisted_16, [
          _cache[10] || (_cache[10] = _createElementVNode("li", { class: "mb-4" }, [
            _createElementVNode("small", { class: "ws-nw td-n footer-title" }, "Contact us")
          ], -1)),
          _createElementVNode("li", _hoisted_17, [
            _createElementVNode("a", {
              class: "ws-nw td-n",
              target: "_blank",
              href: _ctx.other.social.instagram,
              rel: "noopener"
            }, "Instagram", 8, _hoisted_18)
          ]),
          _createElementVNode("li", _hoisted_19, [
            _createElementVNode("a", {
              class: "ws-nw td-n",
              target: "_blank",
              href: _ctx.other.social.facebook,
              rel: "noopener"
            }, "Facebook", 8, _hoisted_20)
          ])
        ]),
        _createElementVNode("ul", _hoisted_21, [
          _cache[12] || (_cache[12] = _createElementVNode("li", { class: "mb-4" }, [
            _createElementVNode("small", { class: "ws-nw footer-title" }, "Business stuff")
          ], -1)),
          _createElementVNode("li", _hoisted_22, [
            _createElementVNode("a", {
              class: "ws-nw td-n",
              target: "_blank",
              href: _ctx.other.vendor.login,
              rel: "noopener"
            }, "Vendor log in", 8, _hoisted_23)
          ]),
          _createElementVNode("li", _hoisted_24, [
            _createVNode(_component_router_link, {
              class: "ws-nw td-n",
              to: "/vendor"
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("List your restaurant")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("ul", _hoisted_25, [
          _cache[16] || (_cache[16] = _createElementVNode("li", { class: "mb-4" }, [
            _createElementVNode("small", { class: "ws-nw footer-title" }, "Boring stuff")
          ], -1)),
          _createElementVNode("li", _hoisted_26, [
            _createVNode(_component_router_link, {
              class: "ws-nw td-n",
              to: `${_ctx.baseUrl}/privacy-policy`
            }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode(" Privacy policy ")
              ])),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("li", _hoisted_27, [
            _createVNode(_component_router_link, {
              class: "ws-nw td-n",
              to: `${_ctx.baseUrl}/terms-of-service`
            }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode(" Terms of service")
              ])),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("li", _hoisted_28, [
            _createVNode(_component_router_link, {
              class: "ws-nw td-n",
              to: `${_ctx.baseUrl}/cookie-policy`
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode(" Cookie policy ")
              ])),
              _: 1
            }, 8, ["to"])
          ])
        ])
      ])
    ])
  ]))
}