<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.0004 8.00007C26.0004 12.2921 22.5337 15.8001 18.2004 15.8001C13.9083 15.8001 10.4004 12.2921 10.4004 8.00007C10.4004 3.70801 13.8671 0.200073 18.1591 0.200073C22.5337 0.200073 26.0004 3.70801 26.0004 8.00007Z"
      fill="#FFB600"
    />
    <path
      d="M18.2004 0.200073C22.4925 0.200073 26.0004 3.70801 26.0004 8.00007C26.0004 12.2921 22.5337 15.8001 18.2004 15.8001C13.9083 15.8001 10.4004 12.2921 10.4004 8.00007"
      fill="#F7981D"
    />
    <path
      d="M18.2002 0.200073C22.4923 0.200073 26.0002 3.70801 26.0002 8.00007C26.0002 12.2921 22.5335 15.8001 18.2002 15.8001"
      fill="#FF8500"
    />
    <path
      d="M7.71746 0.200073C3.46667 0.241343 0 3.70801 0 8.00007C0 12.2921 3.46667 15.8001 7.8 15.8001C9.82222 15.8001 11.6381 15.0159 13.0413 13.7779C13.3302 13.5302 13.5778 13.2413 13.8254 12.9525H12.2159C12.0095 12.7048 11.8032 12.4159 11.6381 12.1683H14.4032C14.5683 11.9207 14.7333 11.6318 14.8571 11.3429H11.1841C11.0603 11.0953 10.9365 10.8064 10.854 10.5175H15.146C15.3937 9.73341 15.5587 8.90801 15.5587 8.04134C15.5587 7.46357 15.4762 6.92706 15.3937 6.39055H10.6063C10.6476 6.10166 10.7302 5.85404 10.8127 5.56515H15.1048C15.0222 5.27626 14.8984 4.98738 14.7746 4.73976H11.1429C11.2667 4.45087 11.4317 4.20325 11.5968 3.91436H14.3619C14.1968 3.62547 13.9905 3.33658 13.7429 3.08896H12.2159C12.4635 2.80007 12.7111 2.55245 13 2.30484C11.6381 1.02547 9.78095 0.282613 7.75873 0.282613C7.75873 0.200073 7.75873 0.200073 7.71746 0.200073Z"
      fill="#FF5050"
    />
    <path
      d="M0 8.00017C0 12.2922 3.46667 15.8002 7.8 15.8002C9.82222 15.8002 11.6381 15.016 13.0413 13.778C13.3302 13.5303 13.5778 13.2414 13.8254 12.9526H12.2159C12.0095 12.7049 11.8032 12.416 11.6381 12.1684H14.4032C14.5683 11.9208 14.7333 11.6319 14.8571 11.343H11.1841C11.0603 11.0954 10.9365 10.8065 10.854 10.5176H15.146C15.3937 9.73351 15.5587 8.90811 15.5587 8.04144C15.5587 7.46367 15.4762 6.92716 15.3937 6.39065H10.6063C10.6476 6.10176 10.7302 5.85414 10.8127 5.56525H15.1048C15.0222 5.27637 14.8984 4.98748 14.7746 4.73986H11.1429C11.2667 4.45097 11.4317 4.20335 11.5968 3.91446H14.3619C14.1968 3.62557 13.9905 3.33668 13.7429 3.08906H12.2159C12.4635 2.80018 12.7111 2.55256 13 2.30494C11.6381 1.02557 9.78095 0.282715 7.75873 0.282715H7.71746"
      fill="#E52836"
    />
    <path
      d="M7.80031 15.8002C9.82253 15.8002 11.6384 15.016 13.0416 13.778C13.3305 13.5303 13.5781 13.2414 13.8257 12.9526H12.2162C12.0098 12.7049 11.8035 12.416 11.6384 12.1684H14.4035C14.5686 11.9208 14.7336 11.6319 14.8575 11.343H11.1844C11.0606 11.0954 10.9368 10.8065 10.8543 10.5176H15.1463C15.394 9.73351 15.559 8.90811 15.559 8.04144C15.559 7.46367 15.4765 6.92716 15.394 6.39065H10.6067C10.6479 6.10176 10.7305 5.85414 10.813 5.56525H15.1051C15.0225 5.27637 14.8987 4.98748 14.7749 4.73986H11.1432C11.267 4.45097 11.4321 4.20335 11.5971 3.91446H14.3622C14.1971 3.62557 13.9908 3.33668 13.7432 3.08906H12.2162C12.4638 2.80018 12.7114 2.55256 13.0003 2.30494C11.6384 1.02557 9.78126 0.282715 7.75904 0.282715H7.71777"
      fill="#CB2026"
    />
    <path
      d="M10.565 9.98099L10.6888 9.2794C10.6475 9.2794 10.565 9.32067 10.4824 9.32067C10.1936 9.32067 10.1523 9.15559 10.1936 9.07305L10.4412 7.62861H10.8951L11.019 6.84448H10.6063L10.6888 6.34924H9.8634C9.8634 6.34924 9.36816 9.07305 9.36816 9.40321C9.36816 9.89845 9.65705 10.1048 10.0285 10.1048C10.2761 10.1048 10.4824 10.0223 10.565 9.98099Z"
      fill="white"
    />
    <path
      d="M10.8545 8.6604C10.8545 9.81596 11.6386 10.1048 12.2989 10.1048C12.918 10.1048 13.1656 9.98104 13.1656 9.98104L13.3307 9.19691C13.3307 9.19691 12.8767 9.40326 12.464 9.40326C11.5561 9.40326 11.7212 8.74294 11.7212 8.74294H13.4132C13.4132 8.74294 13.537 8.20643 13.537 8.00009C13.537 7.46358 13.2481 6.80326 12.3402 6.80326C11.4735 6.72072 10.8545 7.62866 10.8545 8.6604ZM12.2989 7.46358C12.7529 7.46358 12.6704 8.00009 12.6704 8.04136H11.7624C11.7624 8.00009 11.845 7.46358 12.2989 7.46358Z"
      fill="white"
    />
    <path
      d="M17.5404 9.98113L17.7054 9.0732C17.7054 9.0732 17.2927 9.27954 17.0038 9.27954C16.4261 9.27954 16.1784 8.82558 16.1784 8.33034C16.1784 7.33987 16.6737 6.80336 17.2515 6.80336C17.6642 6.80336 17.9943 7.05098 17.9943 7.05098L18.1181 6.18431C18.1181 6.18431 17.6229 5.97797 17.1689 5.97797C16.2197 5.97797 15.2705 6.80336 15.2705 8.37161C15.2705 9.40335 15.7657 10.1049 16.7562 10.1049C17.0864 10.1049 17.5404 9.98113 17.5404 9.98113Z"
      fill="white"
    />
    <path
      d="M6.02528 6.72083C5.44751 6.72083 5.03481 6.88591 5.03481 6.88591L4.911 7.5875C4.911 7.5875 5.28243 7.42242 5.81893 7.42242C6.10782 7.42242 6.35544 7.46368 6.35544 7.7113C6.35544 7.87638 6.31417 7.91765 6.31417 7.91765C6.31417 7.91765 6.06655 7.91765 5.94274 7.91765C5.24116 7.91765 4.45703 8.20654 4.45703 9.15575C4.45703 9.89861 4.95227 10.0637 5.24116 10.0637C5.81893 10.0637 6.06655 9.69226 6.10782 9.69226L6.06655 10.0224H6.80941L7.13956 7.75258C7.13956 6.7621 6.31417 6.72083 6.02528 6.72083ZM6.19036 8.57798C6.19036 8.70179 6.10782 9.3621 5.61258 9.3621C5.36497 9.3621 5.28243 9.15576 5.28243 9.03194C5.28243 8.8256 5.40624 8.5367 6.02528 8.5367C6.14909 8.57797 6.19036 8.57798 6.19036 8.57798Z"
      fill="white"
    />
    <path
      d="M7.92382 10.0636C8.13017 10.0636 9.16191 10.1049 9.16191 8.99061C9.16191 7.95887 8.17144 8.16522 8.17144 7.75252C8.17144 7.54617 8.33651 7.46363 8.6254 7.46363C8.74921 7.46363 9.20318 7.5049 9.20318 7.5049L9.32699 6.76204C9.32699 6.76204 9.0381 6.6795 8.54286 6.6795C7.92382 6.6795 7.30477 6.92712 7.30477 7.75252C7.30477 8.70172 8.33652 8.61919 8.33652 8.99061C8.33652 9.23823 8.04763 9.2795 7.84128 9.2795C7.46985 9.2795 7.09842 9.15569 7.09842 9.15569L6.97461 9.89855C7.01588 9.98109 7.22223 10.0636 7.92382 10.0636Z"
      fill="white"
    />
    <path
      d="M24.3905 6.06036L24.2254 7.17465C24.2254 7.17465 23.8952 6.76195 23.4413 6.76195C22.6984 6.76195 22.0381 7.66989 22.0381 8.7429C22.0381 9.40322 22.3682 10.1048 23.0698 10.1048C23.5651 10.1048 23.854 9.77465 23.854 9.77465L23.8127 10.0635H24.6381L25.2571 6.10163L24.3905 6.06036ZM24.019 8.24767C24.019 8.70163 23.8127 9.27941 23.3587 9.27941C23.0698 9.27941 22.9048 9.03179 22.9048 8.61909C22.9048 7.95878 23.1936 7.54608 23.5651 7.54608C23.854 7.54608 24.019 7.75243 24.019 8.24767Z"
      fill="white"
    />
    <path
      d="M1.52729 10.0223L2.02253 7.05084L2.10507 10.0223H2.68285L3.75586 7.05084L3.30189 10.0223H4.16856L4.82888 6.06036H3.46697L2.64158 8.49528L2.60031 6.06036H1.40348L0.743164 10.0223H1.52729Z"
      fill="white"
    />
    <path
      d="M14.321 10.0223C14.5686 8.66043 14.6099 7.54614 15.1877 7.75249C15.2702 7.21598 15.394 7.00963 15.4766 6.80328C15.4766 6.80328 15.4353 6.80328 15.3115 6.80328C14.9401 6.80328 14.6512 7.29852 14.6512 7.29852L14.7337 6.84455H13.9496L13.4131 10.0636H14.321V10.0223Z"
      fill="white"
    />
    <path
      d="M19.4384 6.72083C18.8606 6.72083 18.4479 6.88591 18.4479 6.88591L18.3241 7.5875C18.3241 7.5875 18.6955 7.42242 19.232 7.42242C19.5209 7.42242 19.7685 7.46368 19.7685 7.7113C19.7685 7.87638 19.7273 7.91765 19.7273 7.91765C19.7273 7.91765 19.4796 7.91765 19.3558 7.91765C18.6542 7.91765 17.8701 8.20654 17.8701 9.15575C17.8701 9.89861 18.3654 10.0637 18.6542 10.0637C19.232 10.0637 19.4796 9.69226 19.5209 9.69226L19.4796 10.0224H20.2225L20.5527 7.75258C20.5939 6.7621 19.7273 6.72083 19.4384 6.72083ZM19.6447 8.57798C19.6447 8.70179 19.5622 9.3621 19.0669 9.3621C18.8193 9.3621 18.7368 9.15576 18.7368 9.03194C18.7368 8.8256 18.8606 8.5367 19.4796 8.5367C19.6035 8.57797 19.6035 8.57798 19.6447 8.57798Z"
      fill="white"
    />
    <path
      d="M21.2546 10.0223C21.5022 8.66043 21.5435 7.54614 22.1213 7.75249C22.2038 7.21598 22.3276 7.00963 22.4102 6.80328C22.4102 6.80328 22.3689 6.80328 22.2451 6.80328C21.8737 6.80328 21.5848 7.29852 21.5848 7.29852L21.6673 6.84455H20.8832L20.3467 10.0636H21.2546V10.0223Z"
      fill="white"
    />
    <path
      d="M9.28613 9.40321C9.28613 9.89845 9.57502 10.1048 9.94645 10.1048C10.2353 10.1048 10.483 10.0223 10.5655 9.98099L10.6893 9.2794C10.648 9.2794 10.5655 9.32067 10.483 9.32067C10.1941 9.32067 10.1528 9.15559 10.1941 9.07305L10.4417 7.62861H10.8957L11.0195 6.84448H10.6068L10.6893 6.34924"
      fill="#DCE5E5"
    />
    <path
      d="M11.2666 8.6604C11.2666 9.81596 11.638 10.1048 12.2983 10.1048C12.9174 10.1048 13.165 9.98104 13.165 9.98104L13.3301 9.19691C13.3301 9.19691 12.8761 9.40326 12.4634 9.40326C11.5555 9.40326 11.7206 8.74294 11.7206 8.74294H13.4126C13.4126 8.74294 13.5364 8.20643 13.5364 8.00009C13.5364 7.46358 13.2476 6.80326 12.3396 6.80326C11.473 6.72072 11.2666 7.62866 11.2666 8.6604ZM12.2983 7.46358C12.7523 7.46358 12.8349 8.00009 12.8349 8.04136H11.7618C11.7618 8.00009 11.8444 7.46358 12.2983 7.46358Z"
      fill="#DCE5E5"
    />
    <path
      d="M17.5398 9.98113L17.7048 9.0732C17.7048 9.0732 17.2921 9.27954 17.0033 9.27954C16.4255 9.27954 16.1779 8.82558 16.1779 8.33034C16.1779 7.33987 16.6731 6.80336 17.2509 6.80336C17.6636 6.80336 17.9937 7.05098 17.9937 7.05098L18.1175 6.18431C18.1175 6.18431 17.6223 5.97797 17.1683 5.97797C16.2191 5.97797 15.6826 6.80336 15.6826 8.37161C15.6826 9.40335 15.7652 10.1049 16.7556 10.1049C17.0858 10.1049 17.5398 9.98113 17.5398 9.98113Z"
      fill="#DCE5E5"
    />
    <path
      d="M4.911 7.62868C4.911 7.62868 5.28243 7.4636 5.81893 7.4636C6.10782 7.4636 6.35544 7.50487 6.35544 7.75249C6.35544 7.91757 6.31417 7.95883 6.31417 7.95883C6.31417 7.95883 6.06655 7.95883 5.94274 7.95883C5.24116 7.95883 4.45703 8.24772 4.45703 9.19693C4.45703 9.93978 4.95227 10.1049 5.24116 10.1049C5.81893 10.1049 6.06655 9.73343 6.10782 9.73343L6.06655 10.0636H6.80941L7.13956 7.79376C7.13956 6.84455 6.31417 6.80328 5.98401 6.80328L4.911 7.62868ZM6.60306 8.57788C6.60306 8.70169 6.10782 9.362 5.61258 9.362C5.36497 9.362 5.28243 9.15566 5.28243 9.03185C5.28243 8.8255 5.40624 8.53661 6.02528 8.53661C6.14909 8.57788 6.60306 8.57788 6.60306 8.57788Z"
      fill="#DCE5E5"
    />
    <path
      d="M7.0166 9.98109C7.0166 9.98109 7.26422 10.0636 7.96581 10.0636C8.17216 10.0636 9.2039 10.1049 9.2039 8.99061C9.2039 7.95887 8.21343 8.16522 8.21343 7.75252C8.21343 7.54617 8.37851 7.46363 8.6674 7.46363C8.79121 7.46363 9.24517 7.5049 9.24517 7.5049L9.36898 6.76204C9.36898 6.76204 9.08009 6.6795 8.58486 6.6795C7.96581 6.6795 7.75946 6.92712 7.75946 7.75252C7.75946 8.70172 8.37851 8.61919 8.37851 8.99061C8.37851 9.23823 8.08962 9.2795 7.88327 9.2795"
      fill="#DCE5E5"
    />
    <path
      d="M24.2258 7.17494C24.2258 7.17494 23.8957 6.76224 23.4417 6.76224C22.6989 6.76224 22.4512 7.67018 22.4512 8.74319C22.4512 9.40351 22.3687 10.1051 23.0703 10.1051C23.5655 10.1051 23.8544 9.77494 23.8544 9.77494L23.8131 10.0638H24.6385L25.2576 6.10193L24.2258 7.17494ZM24.1846 8.24795C24.1846 8.70192 23.8131 9.2797 23.3592 9.2797C23.0703 9.2797 22.9052 9.03208 22.9052 8.61938C22.9052 7.95907 23.1941 7.54637 23.5655 7.54637C23.8544 7.54637 24.1846 7.75272 24.1846 8.24795Z"
      fill="#DCE5E5"
    />
    <path
      d="M1.52729 10.0223L2.02253 7.05084L2.10507 10.0223H2.68285L3.75586 7.05084L3.30189 10.0223H4.16856L4.82888 6.06036H3.79713L2.64158 8.49528L2.60031 6.06036H2.14634L0.743164 10.0223H1.52729Z"
      fill="#DCE5E5"
    />
    <path
      d="M13.4541 10.0223H14.3208C14.5684 8.66043 14.6097 7.54614 15.1874 7.75249C15.27 7.21598 15.3938 7.00963 15.4763 6.80328C15.4763 6.80328 15.4351 6.80328 15.3112 6.80328C14.9398 6.80328 14.6509 7.29852 14.6509 7.29852L14.7335 6.84455"
      fill="#DCE5E5"
    />
    <path
      d="M18.3241 7.62868C18.3241 7.62868 18.6955 7.4636 19.232 7.4636C19.5209 7.4636 19.7685 7.50487 19.7685 7.75249C19.7685 7.91757 19.7273 7.95883 19.7273 7.95883C19.7273 7.95883 19.4796 7.95883 19.3558 7.95883C18.6542 7.95883 17.8701 8.24772 17.8701 9.19693C17.8701 9.93978 18.3654 10.1049 18.6542 10.1049C19.232 10.1049 19.4796 9.73343 19.5209 9.73343L19.4796 10.0636H20.2225L20.5527 7.79376C20.5527 6.84455 19.7273 6.80328 19.3971 6.80328L18.3241 7.62868ZM20.0162 8.57788C20.0162 8.70169 19.5209 9.362 19.0257 9.362C18.7781 9.362 18.6955 9.15566 18.6955 9.03185C18.6955 8.8255 18.8193 8.53661 19.4384 8.53661C19.6035 8.57788 20.0162 8.57788 20.0162 8.57788Z"
      fill="#DCE5E5"
    />
    <path
      d="M20.3877 10.0223H21.2544C21.502 8.66043 21.5432 7.54614 22.121 7.75249C22.2036 7.21598 22.3274 7.00963 22.4099 6.80328C22.4099 6.80328 22.3686 6.80328 22.2448 6.80328C21.8734 6.80328 21.5845 7.29852 21.5845 7.29852L21.6671 6.84455"
      fill="#DCE5E5"
    />
  </svg>
  <!-- eslint-enable -->
</template>
